import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context"

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByBonusName } from "../../data/utilities";
import { elixirsWithDice } from "../../data/elixirs";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const SelectStrongElixir: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const options = elixirsWithDice.map((e) => <option value={e} key={e}>{e}</option>);
    options.unshift(<option value="" key="selectStrongElixir">-- select elixir --</option>);


    const getCurrentValue = () => {
        let val = "";
        const theBonus = getBonusByBonusName(globalContext.bonuses, "StrongElixir", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource)
        if (theBonus) {
            val = theBonus.bonusTo;
        }
        return val;
    }

    const setStrongElixirBonus = (bonusTo: string) => {
        const theBonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: "StrongElixir",
            bonusName: "StrongElixir",
            bonusTo: bonusTo,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        }
        props.setBonus(theBonus);
    }

    const selectCss = getCurrentValue() === "" ? "form-select redBorder" : "form-select";

    return (
        <div>
            <div className="formNote">Select an elixir</div>
            <select className={selectCss} onChange={(e) => setStrongElixirBonus(e.target.value)} value={getCurrentValue()}>{options}</select>
        </div>
    )

}

export default SelectStrongElixir;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import app from "../../firebase/firebase";

import { isEmail } from "../../data/utilities";


interface IProps {
}

const ForgotPassword: React.FunctionComponent<IProps> = (props: IProps) => {

    const [email, setEmail] = useState("");
    const [signUpState, setSignUpState] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const auth = getAuth(app);

    const handleSignUp = async (e: any) => {
        e.preventDefault();

        let isValid = true;

        if (email.trim() !== "")

            if (isValid) {
                sendPasswordResetEmail(auth, email)
                    .then((userCredential) => {
                        // Send new user an email with a link where they can reset their password.   
                        setSignUpState("success");
                    })
                    .catch((error) => {
                        setSignUpState("fail");
                        if (error.message) {
                            if (error.code) {
                                switch (error.code) {
                                    case "auth/user-not-found":
                                        setError("The email address was not found.");
                                        break;
                                    case "auth/invalid-email":
                                        setError("The email address is not valid");
                                        break;
                                    default:
                                        setError(error.message);
                                        break;
                                }
                            } else {
                                setError(error.message);
                            }
                        }
                    });
            }
    }

    const selectEmailCss = email.trim() === "" ? "form-control redBorder" : "form-control";

    const getIsDisabled = () => {
        if (email.trim() === "") { return true; }
        if (!isEmail(email.trim())) { return true; }
        return false;
    }

    const reset = () => {
        setEmail("");
        setSignUpState("");
        setError("");
    }

    return (
        <div>
            <h1>Forgotten your password?</h1>


            {signUpState === "fail" &&
                <div>
                    <div className="alert alert-danger">
                        <div><b>Reset password email failed:</b> {error}</div>
                        <div className="mt-2"><button onClick={() => reset()} className="btn btn-dark me-2">Try Again</button></div>
                    </div>
                </div>
            }

            {signUpState === "success" &&
                <div className="alert alert-success">
                    <div><b>A password reset email has been sent to your email address.</b></div>
                    <div>An email has been sent to {email}. It contains a link to a form where you may reset your password.</div>
                    <div className="mt-2"><button onClick={() => navigate("/login")} className="btn btn-dark me-4">Log In</button></div>
                </div>
            }

            {signUpState === "" &&
                <>
                    <div className="pb-2">Complete this form to have an email sent to you with a link to where you can reset your password.</div>

                    <div className="section">

                        <form onSubmit={(e) => handleSignUp(e)}>
                            <div>
                                <div className="formNote">Email</div>
                                <div className="input-group">
                                    <input className={selectEmailCss} id="email" type="text" onChange={(e) => setEmail(e.currentTarget.value)} value={email}></input>
                                </div>
                                {email.trim() === "" &&
                                    <div className="valError">Please enter your email address</div>
                                }
                                {email.trim() !== "" && !isEmail(email.trim()) &&
                                    <div className="valError">A valid email address is required</div>
                                }
                            </div>
                        </form>

                        <div>
                            <button type="submit" className="btn btn-dark" onClick={(e) => handleSignUp(e)} disabled={getIsDisabled()}>Send Password Reset Email</button>
                        </div>

                    </div>
                </>
            }

        </div >
    )
}

export default ForgotPassword;

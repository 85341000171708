import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faDollarSign } from '@fortawesome/free-solid-svg-icons';

import { Armor } from "../../data/armor";
import { GearOwned } from "../../data/gear";
import Modal from "react-responsive-modal";
import DisplayGold from "../charGen/displayGold";
import { Bonus } from "../../data/bonus";
import { getPermittedArmorsByClassName } from "../../data/utilities";


interface IProps {
    openModal: boolean;
    onlyIncludeProficientArmor: boolean;
    gearOwned: GearOwned[];
    gearSlotsUsed: number;
    gearSlotsTotal: number;
    bonuses: Bonus[];
    onSetOnlyIncludeProficientArmor: (setOnlyIncludeProficientArmor: boolean) => void,
    onBuyItem: (id: string, type: string, isBuy: boolean, cost: number, currency: string) => void;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}

const BuyArmor: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getProperties = (a: Armor) => {
        const out: string[] = [];
        if (a.noSwim) {
            out.push("No swim")
            if (a.disadvStealth) {
                out.push("disadv on stealth");
            }
        } else {
            if (a.disadvStealth && a.disadvSwim) {
                out.push("Disadv on stealth & swim")
            } else {
                if (a.disadvStealth) {
                    out.push("Disadv on stealth")
                }
                if (a.disadvSwim) {
                    out.push("Disadv on swim")
                }
            }
        }
        if (a.oneHand) {
            out.push("Occupies one hand");
        }
        if (a.sundering) {
            out.push("Sunder to negate one melee hit's damage");
        }

        if (out.length === 0) { return "-" }
        return out.join(", ")
    }

    const theClass = globalContext.charClass;

    const getArmorRows2 = () => {

        let arms = globalContext.availableArmors;
        const theClass = globalContext.charClass;

        if (props.onlyIncludeProficientArmor && theClass?.armor !== "All armor and shields") {
            arms = getPermittedArmorsByClassName(globalContext.availableClasses, theClass?.name, globalContext.availableArmors, props.bonuses);
        }

        const rows: any = [];
        arms.forEach((w) => {

            const getAC = (a: Armor) => {
                if (w.ac_bonus) { return "+" + w.ac_bonus }
                if (w.allowsDEX) { return w.ac_base + " + DEX mod" }
                if (!w.allowsDEX) { return w.ac_base }
            }

            const owned = props.gearOwned.filter((g) => g.id === w.id).length;
            const ownedStr = owned > 0 ? " (" + owned + ")" : "";
            const totalGold = globalContext.gold + (globalContext.silver / 10) + (globalContext.copper / 100);

            const getCost = (a: Armor) => {
                if (a.currency === "gp") { return w.cost; }
                if (a.currency === "sp") { return w.cost / 10; }
                if (a.currency === "cp") { return w.cost / 100; }
                return 0;
            }

            const canBuy = getCost(w) <= totalGold && (props.gearSlotsUsed + w.slots) <= props.gearSlotsTotal;
            const canSell = owned > 0;

            rows.push(
                <div className="row buyRow" key={w.name}>
                    <div className="col-8 col-sm-4"><b>{w.name} {ownedStr}</b></div>
                    <div className="col-4 col-sm-2 text-end">{w.cost}&nbsp;{w.currency}</div>
                    <div className="col-8 col-sm-4">AC {getAC(w)}</div>
                    <div className="col-4 col-sm-2 text-end">{w.slots} slot{w.slots === 1 ? "" : "s"}</div>

                    <div className="col-8 col-sm-8">{getProperties(w)}</div>
                    <div className="col-4 col-sm-4 text-end">
                        <button className="btn btn-secondary btn-sm btnBuy me-1" onClick={() => props.onBuyItem(w.id, "armor", true, w.cost, w.currency)} disabled={!canBuy} title="Buy"><FontAwesomeIcon icon={faCartShopping} /></button>
                        <button className="btn btn-secondary btn-sm btnBuy" onClick={() => props.onBuyItem(w.id, "armor", false, w.cost, w.currency)} disabled={!canSell} title="Sell"><FontAwesomeIcon icon={faDollarSign} /></button>
                    </div>
                </div>
            );
        })

        if (rows.length === 0) { rows.push(<div className="row buyRow" key={"not permitted"}>None permitted</div>) }

        return rows;
    }

    const onCloseModal = () => {
        props.onCloseModal("armor");
    }

    return (
        <>
            <button onClick={() => props.onOpenModal("armor")} className="btn btn-dark btn-sm me-1">Armor</button>
            {props.openModal &&
                <Modal open={props.openModal} onClose={() => onCloseModal()} classNames={{ modal: 'customModal' }}>

                    <div className="minHalf">
                        <div><b>Coin:</b> <DisplayGold gold={globalContext.gold} silver={globalContext.silver} copper={globalContext.copper} /></div>
                        <div><b>Gear Slots:</b> {props.gearSlotsUsed} of {props.gearSlotsTotal}</div>

                        <div className="container text-left">
                            {getArmorRows2()}
                        </div>

                        {globalContext.className !== "" && theClass?.armor !== "All armor and shields" &&
                            <div><label><input type="checkbox" checked={props.onlyIncludeProficientArmor} onChange={() => props.onSetOnlyIncludeProficientArmor(!props.onlyIncludeProficientArmor)} /> Only {globalContext.className} armor</label></div>
                        }

                    </div >
                </Modal>
            }
        </>
    )

}

export default BuyArmor;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { getTotalHitPoints } from "../../data/utilities";
import { modNum, modStr } from "../../data/stats";

interface IProps {
    level: number
    hasStout: boolean;
    totalHitPoints: number;
    isCheatMode: boolean;
    rollHitDie: (level: number, hitDie: number) => void;
    setHitDie: (level: number, hitPoints: number) => void;
    maximiseHitDieRoll: (level: number, maximiseHitDieRoll: boolean) => void;
}

const RollHitPoints: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    let charClassDetails = globalContext.availableClasses.find((c) => c.name === globalContext.className);

    const thisLevel = globalContext.levels.find((l) => l.level === props.level);
    if (thisLevel === undefined) { return null; }

    let hitDieStr = "";
    let hitDie = 0;
    if (charClassDetails) {
        hitDieStr = "d" + charClassDetails.hitDie;
        hitDie = charClassDetails.hitDie;
    }

    const getTotalHPForLevel = (levelNum: number) => {
        const theClass = charClassDetails === undefined ? "" : charClassDetails?.name;
        return getTotalHitPoints(levelNum, globalContext.levels, props.hasStout ? "Dwarf" : "NotDwarf", theClass, globalContext.availableClasses, globalContext.finalStats, globalContext.bonuses);
    }

    let hpNote = "";
    if (thisLevel.HitPointRoll !== 0 || globalContext.className === "Level 0") {
        hpNote = ": " + getTotalHPForLevel(props.level) + " HP";
    }

    const getHPNoteForLevelOneOrHigher = (levelNumber: number, hitDie: number) => {

        const thisLevel = globalContext.levels.find((lev) => lev.level === levelNumber);
        if (thisLevel) {
            let out: string[] = [];
            let hp = 0;

            if (props.hasStout) {
                if (props.level === 1) {
                    out.push("Stout: +2 HP");
                    hp += 2;
                }
                if (thisLevel.rollIsMaximised && thisLevel.rollIsMaximised === true) {
                    out.push(`Stout: roll hit points with advantage (rolled ${hitDie} and ${thisLevel.stoutHitPointRoll} on 2${hitDieStr}) = ${Math.max(hitDie, thisLevel.stoutHitPointRoll)}`)
                    hp += Math.max(hitDie, thisLevel.stoutHitPointRoll);
                } else {
                    out.push(`Stout: roll hit points with advantage (rolled ${thisLevel.HitPointRoll} and ${thisLevel.stoutHitPointRoll} on 2${hitDieStr}) = ${Math.max(thisLevel.HitPointRoll, thisLevel.stoutHitPointRoll)}`)
                    hp += Math.max(thisLevel.HitPointRoll, thisLevel.stoutHitPointRoll);
                }

            } else {
                if (thisLevel.rollIsMaximised && thisLevel.rollIsMaximised === true) {
                    out.push(`Rolled ${hitDie} on 1${hitDieStr}`);
                    hp += hitDie;
                } else {
                    out.push(`Rolled ${thisLevel.HitPointRoll} on 1${hitDieStr}`);
                    hp += thisLevel.HitPointRoll;
                }
            }

            if (props.level === 1) {
                out.push(`CON mod ${modStr(globalContext.finalStats.Constitution)}`)
                hp += modNum(globalContext.finalStats.Constitution);
            }

            // Handle any bonus hit dice from bonuses at this level:
            const hitDieBonusesAtThisLevel = globalContext.bonuses.filter((b) => b.gainedAtLevel === props.level && b.name === "PlusOneHitDie");
            if (hitDieBonusesAtThisLevel.length > 0) {

                hitDieBonusesAtThisLevel.forEach((hpb) => {
                    const bonusHP = hpb.bonusTo.split("/");
                    if (props.hasStout) {
                        const hpInc = Math.max(parseInt(bonusHP[0], 10), parseInt(bonusHP[1], 10));
                        out.push(`Bonus Hit Die: Stout: roll hit points with advantage (rolled ${bonusHP[0]} and ${bonusHP[1]} on 2${hitDieStr}) = ${hpInc}`)
                        hp += hpInc;

                    } else {
                        out.push(`Bonus Hit Die: Rolled ${bonusHP[0]} on 1${hitDieStr}`);
                        hp += parseInt(bonusHP[0], 10);
                    }
                })
            }

            const hpAtPreviousLevel = getTotalHPForLevel(props.level - 1);
            if (props.level > 1) {
                hp += hpAtPreviousLevel;
            }

            let minNote = "";
            if (hp < 1) { minNote = " (min 1 HP)"; }

            let output = "";
            if (props.level > 1) {
                output = hpAtPreviousLevel + " HP at level " + (props.level - 1) + "; + " + out.join(", ") + minNote;
            } else {
                output = out.join(", ") + minNote;
            }
            return output;
        }
        return "?";
    }

    const getHPNoteForLevelZero = () => {
        let out: string[] = [];
        let hp = 0;

        if (props.hasStout) {
            out.push("Stout: +2 HP");
            hp += 2;
        }

        out.push(`CON mod ${modStr(globalContext.finalStats.Constitution)}`)
        hp += modNum(globalContext.finalStats.Constitution);

        let minNote = "";
        if (hp < 1) { minNote = " (min 1 HP)"; }

        return out.join(", ") + minNote;
    }

    const getOptions = () => {
        let out: any[] = [];

        out.push(<option value={0} key={"hp0"}>-- select hit points --</option>)

        for (let hp = 1; hp <= hitDie; hp++) {
            out.push(<option value={hp} key={"hp" + hp}>{hp} HP</option>)
        }

        return out;
    }

    const onRollHP = (e: any) => {
        const hp = parseInt(e.target.value);
        props.setHitDie(props.level, hp)
    }

    const getNumberMaximiseHitPointRollBonuses = () => {
        return globalContext.bonuses.filter((b) => b.bonusName === "MaximizeTwoHPRolls").length;
    }

    const getNumberHitDiceThatHaveBeenMaximised = () => {
        let numMaxed = 0;
        globalContext.levels.forEach((l) => {
            if (l.rollIsMaximised && l.rollIsMaximised === true) { numMaxed = numMaxed + 1; }
        })
        if (globalContext.ambitionTalentLevel.rollIsMaximised && globalContext.ambitionTalentLevel.rollIsMaximised) { numMaxed = numMaxed + 1; }
        return numMaxed;
    }

    const getNumberHitDiceThatCanStillBeMaximised = () => {
        return (getNumberMaximiseHitPointRollBonuses() * 2) - getNumberHitDiceThatHaveBeenMaximised();
    }

    const handleMaximiseHitDieRoll = (isMaximised: boolean) => {
        props.maximiseHitDieRoll(props.level, isMaximised);
    }



    const getIfMaximiseIsDisabled = () => {
        if (thisLevel.rollIsMaximised) { return false; }
        return getNumberHitDiceThatCanStillBeMaximised() < 1;
    }

    const getOpportunitiesText = () => {
        if (getNumberHitDiceThatCanStillBeMaximised() !== 1) { return "opportunities" }
        return "opportunity";
    }
    return (
        <div>
            <h3>Hit Points{hpNote}</h3>

            {props.isCheatMode && globalContext.className !== "Level 0" &&
                <div>
                    <div className="input-group">
                        <select className="hpDDL form-select" onChange={(e) => onRollHP(e)} value={thisLevel.HitPointRoll}>{getOptions()}</select>
                    </div>
                    {thisLevel.HitPointRoll === 0 &&
                        <div className="valError">Please select hit points rolled on a d{hitDie}</div>
                    }
                </div>
            }

            {!props.isCheatMode && thisLevel.HitPointRoll === 0 && globalContext.className !== "Level 0" &&
                <div>
                    <button className="btn btn-dark" onClick={() => props.rollHitDie(props.level, hitDie)}>Roll {hitDieStr} for Hit Points</button>
                    <div className="valError">Please roll for hit points</div>
                </div>
            }

            {thisLevel.HitPointRoll !== 0 && globalContext.className !== "Level 0" &&
                <>
                    <div>{getHPNoteForLevelOneOrHigher(props.level, hitDie)} = <b>{getTotalHPForLevel(props.level)} HP</b></div>
                    {getNumberMaximiseHitPointRollBonuses() > 0 &&
                        <div className="form-check form-switch form-check-inline mt-0 pt-0">
                            <input className="form-check-input" type="checkbox" role="switch" id={"maxRoll" + props.level} checked={thisLevel.rollIsMaximised === undefined ? false : thisLevel.rollIsMaximised} disabled={getIfMaximiseIsDisabled()} onChange={() => { handleMaximiseHitDieRoll(!thisLevel.rollIsMaximised) }} />
                            <label className="form-check-label small" htmlFor={"maxRoll" + props.level}>Maximize hit die roll ({getNumberHitDiceThatCanStillBeMaximised()} {getOpportunitiesText()} left)</label>
                        </div>
                    }
                </>
            }

            {globalContext.className === "Level 0" &&
                <div>{getHPNoteForLevelZero()} = <b>{getTotalHPForLevel(props.level)} HP</b></div>

            }



        </div>
    )

}

export default RollHitPoints;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { getRandomIntInclusive } from "../../data/random";

interface IProps {
    languageNumber?: number;
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    level: Level;
    label: string;
    minimal: boolean;
    setBonus: (bonus: Bonus) => void;
}

const ExtraLanguageManual: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);
    const allAnimals = globalContext.availableAnimals;

    let currentValue = "";
    let name = "ExtraLanguageManual: " + props.sourceName + " " + props.languageNumber;

    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.name === name && b.gainedAtLevel === props.level.level);
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    let allAnimalLanguages = allAnimals.map((a) => a.languages && a.languages[0] !== undefined ? a.languages[0] : "");
    allAnimalLanguages = allAnimalLanguages.filter(function(item, pos) {
        return allAnimalLanguages.indexOf(item) === pos;
    }).sort();

    const options = allAnimalLanguages.map((l) => <option value={l} key={l}>{l}</option>);
    options.unshift(<option value="" key="selectAnimalLang">-- select animal language --</option>);

    const setLanguageBonus = (language: string) => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: name,
            bonusTo: "ExtraLanguageManual",
            bonusName: language,
        };
        props.setBonus(bonus);
    }

    const rollLanguage = () => {
        const randAnimal = allAnimals[getRandomIntInclusive(0, allAnimals.length - 1)];
        if (randAnimal.languages && randAnimal.languages[0] !== undefined) {
            setLanguageBonus(randAnimal.languages[0]);
        }
    }

    const getLangDesc = () => {
        if (props.minimal) { return null; }
        if (currentValue.trim() !== "") {
            const speakers = allAnimals.filter((a) => a.languages !== undefined && a.languages.indexOf(currentValue) !== -1);
            if (speakers.length > 0) {
                const allSpeakerNames = speakers.map((a) => a.name).sort();
                return <div className="spell">Spoken by {allSpeakerNames.join(", ")}</div>
            } else {
                return <div className="spell">Spoken by {currentValue}</div>
            }
        }
        return null;
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    const req = currentValue.trim() === "";

    return (
        <div>
            <div className="formNote">{props.label}</div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setLanguageBonus(e.target.value)} value={currentValue}>{options}</select>
                {/* <input type="text" className={selectCss} value={currentValue} onChange={(e: any) => setLanguageBonus(e.target.value)} placeholder="Enter language" /> */}
                <button className="btn btn-dark" onClick={(e) => rollLanguage()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
            </div>
            {req &&
                <div className="valError">Please enter a language</div>
            }
            {getLangDesc()}
        </div>
    )

}

export default ExtraLanguageManual;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import LearnANewWeapon from "./LearnANewWeapon";
import { getBonusByNameAndBonusName } from "../../data/utilities";
import LearnANewArmor from "./LearnANewArmor";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
    parentBonusId?: string;
}

const LearnWeaponOrArmor: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setLearnMeleeWeaponBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: "LearnWeaponOrArmor",
            bonusName: "LearnWeapon",
            bonusTo: "",
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        };
        if (props.parentBonusId) { bonus.parentBonusId = props.parentBonusId; }
        props.setBonus(bonus);
    }

    const setLearnArmorBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: "LearnWeaponOrArmor",
            bonusName: "LearnArmor",
            bonusTo: "",
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        };
        if (props.parentBonusId) { bonus.parentBonusId = props.parentBonusId; }
        props.setBonus(bonus);
    }

    const getTheBonus = (bonusName: string) => {
        let theBonus: Bonus | undefined = undefined;
        if (props.parentBonusId) {
            theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId && b.bonusName === bonusName);
        } else {
            theBonus = getBonusByNameAndBonusName(globalContext.bonuses, "LearnWeaponOrArmor", bonusName, props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource)
        }
        return theBonus;
    }

    const learnWeaponSelected = getTheBonus("LearnWeapon") !== undefined; 
    const learnArmorSelected = getTheBonus("LearnArmor") !== undefined; 
    
    const req = !learnWeaponSelected && !learnArmorSelected;

    const weapons = globalContext.availableWeapons;

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setLearnMeleeWeaponBonus()} checked={learnWeaponSelected}></input>&nbsp;Learn a weapon</label>
            <label><input type="radio" onChange={() => setLearnArmorBonus()} checked={learnArmorSelected}></input>&nbsp;Learn a type of armor</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
            {learnWeaponSelected &&
                <LearnANewWeapon
                    weapons={weapons}
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    name={"LearnWeaponOrArmor"}
                    level={props.level}
                    setBonus={props.setBonus}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                    parentBonusId={props.parentBonusId}
                />
            }
            {learnArmorSelected &&
                <LearnANewArmor
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    name={"LearnWeaponOrArmor"}
                    level={props.level}
                    setBonus={props.setBonus}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                    parentBonusId={props.parentBonusId}
                />
            }
        </div>
    )

}

export default LearnWeaponOrArmor;
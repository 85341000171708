import { arcaneLibraryCursedScroll1_Diablerie_URL, arcaneLibraryCursedScroll2_RedSands_URL, arcaneLibraryCursedScroll3_MidnightSun_URL, arcaneLibraryShadowDarkURL, bardAndRangerPDFDropboxURL, roustaboutPDFDropboxURL, unnaturalSelectionURL } from "../special/links";

export interface ClassExtra {
    name: string;
    desc: string;
    printDesc?: string;
}

export interface Talent {
    min: number;
    max: number;
    desc: string;
    name: string;
    options?: string[];
    size?: number;
    rerollDuplicates?: boolean;
    hasId?: boolean;
}

export interface LevelNote {
    title: string;
    note: string;
}

export interface LevelNotes {
    level: number;
    notes: LevelNote[];
}

export interface CharClass {
    name: string;
    desc: string;
    sourceId: string;
    sourceNote: string;
    sourceURL?: string;
    weapons: string;
    armor: string;
    hitDie: number;
    extras: ClassExtra[];
    levelNotes: LevelNotes[];
    talents: Talent[];
    specialBonuses: SpecialBonus[];
    freeSpells: string[];
    spellCastingStat: string;
    freeToCarryItems: string[];
    extraLanguages: string[];
    classStatWeights: ClassStatWeight[];
    ancestryChances: AncestryChances[];
    spellCastingClassNum?: number;
    minRandomTalentRoll: number;
    maxRandomTalentRoll: number;
    imgName?: string;
    imgWidth?: number;
    imgHeight?: number;
}

export interface SpecialBonus {
    gainedAtLevel: number;
    specialName: string;
    specialComponentName: string;
    specialSourceType: string;
    specialSourceName: string;
    specialSourceCategory: string;
    specialLabel: string;
    specialTier?: number;
    specialNumber?: number;
    specialLanguages?: string[];
    specialLanguageNumber?: number;
}

interface ClassStatWeight {
    stat: string;
    weight: number;
}

interface AncestryChances {
    ancestry: string;
    chance: number;
}

const coreCharClasses: CharClass[] = [
    {
        name: "Level 0",
        desc: "Cringing townsfolk, inept peasants, and terrified vagabonds who dare the Shadowdark in hopes of gold and glory. Most die, but a few survive an adventure and gain a level 1 class.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        weapons: "All weapons",
        armor: "All armor and shields",
        hitDie: 0,
        extras: [
            { name: "Squishy", desc: "You die immediately when you reach 0 hit points.", printDesc: "Die immediately at 0 HP" },
            { name: "Beginner's Luck", desc: "You can wield all gear until you reach 1st level." },
            { name: "Potential", desc: "After surviving your first adventure you gain 1st level.", printDesc: "Gain level 1 after surviving one adventure" },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [],
        talents: [],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 1 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 0,
        maxRandomTalentRoll: 0,
        imgName: "LevelZero2.png",
        imgWidth: 176,
        imgHeight: 176,
    },
    {
        name: "Fighter",
        desc: "Blood-soaked gladiators in dented armor, acrobatic duelists with darting swords, or far-eyed elven archers who carve their legends with steel and grit.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        weapons: "All weapons",
        armor: "All armor and shields",
        hitDie: 8,
        extras: [
            { name: "Hauler", desc: "Add your Constitution modifier, if positive, to your gear slots.", printDesc: "Add CON mod to gear slots" },
            { name: "Weapon Mastery", desc: "Choose one type of weapon, such as longswords. You gain +1 to attack and damage with that weapon type. In addition, add half your level to these rolls (round down)." },
            { name: "Grit", desc: "Choose Strength or Dexterity. You have advantage on checks of that type to overcome an opposing force, such as kicking open a stuck door (Strength) or slipping free of rusty chains (Dexterity)." },
        ],
        extraLanguages: [],
        levelNotes: [
            { level: 2, notes: [{ title: "Weapon mastery", note: "Weapon Mastery bonus increases to +2 to attack and damage with mastered weapons" }] },
            { level: 4, notes: [{ title: "Weapon mastery", note: "Weapon Mastery bonus increases to +3 to attack and damage with mastered weapons" }] },
            { level: 6, notes: [{ title: "Weapon mastery", note: "Weapon Mastery bonus increases to +4 to attack and damage with mastered weapons" }] },
            { level: 8, notes: [{ title: "Weapon mastery", note: "Weapon Mastery bonus increases to +5 to attack and damage with mastered weapons" }] },
            { level: 10, notes: [{ title: "Weapon mastery", note: "Weapon Mastery bonus increases to +6 to attack and damage with mastered weapons" }] }
        ],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "Weapon Mastery", specialComponentName: "WeaponMastery", specialSourceType: "Class", specialSourceName: "Fighter", specialSourceCategory: "Ability", specialLabel: "" },
            { gainedAtLevel: 1, specialName: "Grit", specialComponentName: "Grit", specialSourceType: "Class", specialSourceName: "Fighter", specialSourceCategory: "Ability", specialLabel: "" },
        ],
        talents: [
            { min: 2, max: 2, desc: "Gain Weapon Mastery with one additional weapon", name: "WeaponMastery" },
            { min: 3, max: 6, desc: "+1 to melee and ranged attacks", name: "Plus1ToHit", options: ["Melee and ranged attacks"] },
            { min: 7, max: 9, desc: "+2 to Strength, Dexterity, or Constitution stat", name: "StatBonus", options: ["STR", "DEX", "CON"], size: 2 },
            { min: 10, max: 11, desc: "Choose one kind of armor. You get +1 AC from that armor", name: "ArmorMastery" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 6 },
            { stat: "DEX", weight: 3 },
            { stat: "CON", weight: 3 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 3 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 3 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 2 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 2 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Fighter2.png",
        imgWidth: 180,
        imgHeight: 175,
    },
    {
        name: "Priest",
        desc: "Crusading templars, prophetic shamans, or mad-eyed zealots who wield the power of their gods to cleanse the unholy.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        weapons: "Club, crossbow, dagger, mace, longsword, staff, warhammer",
        armor: "All armor and shields",
        hitDie: 6,
        extras: [
            { name: "Languages", desc: "You know either Celestial, Diabolic, or Primordial." },
            { name: "Turn Undead", desc: "You know the <i>turn undead</i> spell. It doesn't count toward your number of known spells." },
            { name: "Deity", desc: "Choose a god to serve who matches your alignment (see Deities, pg. 30). You have a holy symbol for your god (it takes up no gear slots)." },
            { name: "Spellcasting", desc: "You can cast priest spells you know.<p>You know two tier 1 spells of your choice from the priest spell list on pg. 51.<p/><p>Each time you gain a level, you choose new priest spells to learn according to the Priest Spells Known table.<p/><p>For casting priest spells, see Spellcasting on pg. 44.</p>", printDesc: "" },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialLanguages: ["Celestial", "Diabolic", "Primordial"], specialLanguageNumber: 1, specialLabel: "Priestly language" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "Tier 1 Priest spells" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 2, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 3, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 5, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Priest", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 2, specialLabel: "" }
        ],
        talents: [
            { min: 2, max: 2, desc: "Gain advantage on casting one spell you know", name: "AdvOnCastOneSpell" },
            { min: 3, max: 6, desc: "+1 to melee or ranged attacks", name: "Plus1ToHit", options: ["Melee attacks", "Ranged attacks"] },
            { min: 7, max: 9, desc: "+1 to priest spellcasting checks", name: "Plus1ToCastingSpells", options: ["Priest"], size: 1 },
            { min: 10, max: 11, desc: "+2 to Strength or Wisdom stat", name: "StatBonus", options: ["STR", "WIS"], size: 2 },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: ["Turn Undead"],
        spellCastingStat: "WIS",
        freeToCarryItems: ["Holy symbol"],
        classStatWeights: [
            { stat: "STR", weight: 2 },
            { stat: "DEX", weight: 1 },
            { stat: "CON", weight: 2 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 6 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 2 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 2 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 2 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 2 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 2 }
        ],
        spellCastingClassNum: 0,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Priest2.png",
        imgWidth: 178,
        imgHeight: 175,
    },
    {
        name: "Thief",
        desc: "Rooftop assassins, grinning con artists, or cloaked cat burglars who can pluck a gem from the claws of a sleeping demon and sell it for twice its worth.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        weapons: "Club, crossbow, dagger, shortbow, shortsword",
        armor: "Leather armor, mithral chainmail",
        hitDie: 4,
        extras: [
            { name: "Backstab", desc: "If you hit a creature who is unaware of your attack, you deal an extra weapon die of damage. Add additional weapon dice of damage equal to half your level (round down).", printDesc: "Extra 1 + half level (round down) weapon dice of damage with surprise attacks" },
            { name: "Thievery", desc: "You are adept at thieving skills and have the necessary tools of the trade secreted on your person (they take up no gear slots).<p>You are trained in the following tasks and have advantage on any associated checks:<ul><li>Climbing</li><li>Sneaking and hiding</li><li>Applying disguises</li><li>Finding and disabling traps</li><li>Delicate tasks such as picking pockets and opening locks</li></ul></p>", printDesc: "Trained in climbing, sneaking, hiding, disguise, finding & disabling traps, delicate tasks" }
        ],
        extraLanguages: [],
        levelNotes: [
            { level: 2, notes: [{ title: "Backstab", note: "Backstab increases to +2 extra weapon dice" }] },
            { level: 4, notes: [{ title: "Backstab", note: "Backstab increases to +3 extra weapon dice" }] },
            { level: 6, notes: [{ title: "Backstab", note: "Backstab increases to +4 extra weapon dice" }] },
            { level: 8, notes: [{ title: "Backstab", note: "Backstab increases to +5 extra weapon dice" }] },
            { level: 10, notes: [{ title: "Backstab", note: "Backstab increases to +6 extra weapon dice" }] }
        ],
        specialBonuses: [],
        talents: [
            { min: 2, max: 2, desc: "Gain advantage on initiative rolls", name: "AdvOnInitiative", rerollDuplicates: true },
            { min: 3, max: 5, desc: "Your Backstab deals +1 dice of damage", name: "BackstabIncrease" },
            { min: 6, max: 9, desc: "+2 to Strength, Dexterity, or Charisma stat", name: "StatBonus", options: ["STR", "DEX", "CHA"], size: 2 },
            { min: 10, max: 11, desc: "+1 to melee and ranged attacks", name: "Plus1ToHit", options: ["Melee and ranged attacks"] },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: ["Thieves' tools"],
        classStatWeights: [
            { stat: "STR", weight: 1 },
            { stat: "DEX", weight: 7 },
            { stat: "CON", weight: 2 },
            { stat: "INT", weight: 2 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 2 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 3 },
            { ancestry: "Halfling", chance: 3 },
            { ancestry: "Half-Orc", chance: 2 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 3 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 2 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 2 },
            { ancestry: "Skurrid", chance: 3 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Thief2.png",
        imgWidth: 175,
        imgHeight: 175,
    },
    {
        name: "Wizard",
        desc: "Rune-tattooed adepts, bespectacled magi, and flame conjuring witches who dare to manipulate the fell forces of magic.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        weapons: "Dagger, staff",
        armor: "None",
        hitDie: 4,
        extras: [
            { name: "Languages", desc: "You know two additional common languages and two rare languages (see pg. 32)." },
            { name: "Learning Spells", desc: "You can permanently learn a wizard spell from a spell scroll by studying it for a day and succeeding on a DC 15 Intelligence check.<p>Whether you succeed or fail, you expend the spell scroll.<p/><p>Spells you learn in this way don't count toward your known spells.</p>", printDesc: "You can learn a wizard spell from a scroll with DC 15 INT check" },
            { name: "Spellcasting", desc: "You can cast wizard spells you know.<p>You know three tier 1 spells of your choice from the wizard spell list (see pg. 52).<p/><p>Each time you gain a level, you choose new wizard spells to learn according to the Wizard Spells Known table.</p><p>For casting wizard spells, see Spellcasting on pg. 44.</p>", printDesc: "" },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialLanguages: ["ALL_COMMON"], specialLanguageNumber: 1, specialLabel: "Wizard languages (common)" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialLanguages: ["ALL_COMMON"], specialLanguageNumber: 2, specialLabel: "" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialLanguages: ["ALL_RARE"], specialLanguageNumber: 3, specialLabel: "Wizard languages (rare)" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialLanguages: ["ALL_RARE"], specialLanguageNumber: 4, specialLabel: "" },

            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "Tier 1 Wizard spells" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 2, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 3, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 5, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Wizard", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 2, specialLabel: "" }
        ],
        talents: [
            { min: 2, max: 2, desc: "Make 1 random magic item of a type you choose (pg. 282)", name: "MakeRandomMagicItem" },
            { min: 3, max: 7, desc: "+2 to Intelligence stat or +1 to wizard spellcasting checks", name: "Plus2INTOrPlus1Casting" },
            { min: 8, max: 9, desc: "Gain advantage on casting one spell you know", name: "AdvOnCastOneSpell" },
            { min: 10, max: 11, desc: "Learn one additional wizard spell of any tier you know", name: "LearnExtraSpell" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "INT",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 1 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 3 },
            { stat: "INT", weight: 6 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 3 },
            { ancestry: "Goblin", chance: 3 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 2 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 2 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 2 },
            { ancestry: "Mycellan", chance: 2 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        spellCastingClassNum: 1,
        minRandomTalentRoll: 3,
        maxRandomTalentRoll: 11,
        imgName: "Wizard2.png",
        imgWidth: 178,
        imgHeight: 175,
    },

    // Ranger and Bard

    {
        name: "Ranger",
        desc: "Skilled trackers, stealthy wanderers, and peerless warriors who call the wilds their home.",
        sourceId: "SG",
        sourceNote: "The Ranger class was designed by Kelsey as a stretch goal for the Kickstarter, and has been made freely available as an addition to the Core rules.",
        sourceURL: bardAndRangerPDFDropboxURL,
        weapons: "Dagger, longbow, longsword, shortbow, shortsword, spear, staff",
        armor: "Leather armor, chainmail, mithral chainmail",
        hitDie: 8,
        extras: [
            { name: "Wayfinder", desc: "You have advantage on checks associated with: <ul><li>Navigation</li><li>Tracking</li><li>Bushcraft</li><li>Stealth</li><li>Wild animals</li></ol>", printDesc: "Trained in navigation, tracking, bushcraft, stealth and wild animals" },
            { name: "Herbalism", desc: "Make an INT check to prepare an herbal remedy you choose. If you fail, you can't make that remedy again until you successfully rest. Unused remedies expire in 3 rounds. <table class='herbTable'> <tr class='herbTableHeader'> <th colspan='2'>Herbs</th> </tr> <tr> <th>DC</th> <th>Effect</th> </tr> <tr> <td>11</td> <td><b>Salve.</b> Heals 1 HP</td> </tr> <tr> <td>12</td> <td><b>Stimulant.</b> You can't be surprised for 10 rounds</td> </tr> <tr> <td>13</td> <td><b>Foebane.</b> ADV on attacks and damage against one creature type you choose for 1d6 rounds</td> </tr> <tr> <td>14</td> <td><b>Restorative.</b> Ends one poison or disease</td> </tr> <tr> <td>15</td> <td><b>Curative.</b> Equivalent to a <i>Potion of Healing</i></td> </tr> </table>", printDesc: "INT check to prepare a herbal remedy you choose. If fail can't try to prepare that remedy again until rested. HERBS: [DC 11] SALVE: Heal 1HP, [DC 12] STIMULANT: Can't be surprised for 5 rounds, [DC 13] FOEBANE: ADV on attacks and damage vs one creature type you choose for 1d6 rounds, [DC 14] RESTORATIVE: Ends a poison or disease, [DC 15] CURATIVE: as Potion of Healing" },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [],
        talents: [
            { min: 2, max: 2, desc: "You deal d12 damage with one weapon type you choose", name: "SetWeaponTypeDamage", size: 12 },
            { min: 3, max: 6, desc: "+1 to melee or ranged attacks and damage", name: "Plus1ToHitAndDamage", options: ["Melee attacks", "Ranged attacks"] },
            { min: 7, max: 9, desc: "+2 to Strength, Dexterity, or Intelligence", name: "StatBonus", options: ["STR", "DEX", "INT"], size: 2 },
            { min: 10, max: 11, desc: "You gain ADV on Herbalism checks for an herb you choose", name: "ReduceHerbalismDC" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 5 },
            { stat: "DEX", weight: 5 },
            { stat: "CON", weight: 3 },
            { stat: "INT", weight: 4 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 2 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 2 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 3 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 2 },
            { ancestry: "Mycellan", chance: 3 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 2 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Ranger2.png",
        imgWidth: 175,
        imgHeight: 175,
    },
    {
        name: "Bard",
        desc: "Bards are welcome wanderers and wise advisors; it is their task to remember, know, and learn the lessons of the ages.",
        sourceId: "SG",
        sourceNote: "The Bard class was designed by Kelsey as a stretch goal for the Kickstarter, and has been made freely available as an addition to the Core rules. (v1.3)",
        sourceURL: bardAndRangerPDFDropboxURL,
        weapons: "Crossbow, dagger, mace, shortbow, shortsword, spear, staff",
        armor: "Leather armor, chainmail, mithral chainmail, shields",
        hitDie: 6,
        extras: [
            { name: "Languages", desc: "You know four additional common languages and one rare language." },
            { name: "Bardic Arts", desc: "You're trained in oration, performing arts, lore, and diplomacy. You have advantage on related checks.", printDesc: "Trained in oration, performing arts, lore, and diplomacy" },
            { name: "Magical Dabbler", desc: "You can activate spell scrolls and wands using Charisma as your spellcasting stat. If you critically fail, roll a wizard mishap.", printDesc: "Activate scroll/wand using Charisma as spellcasting stat. On crit fail, roll a wizard mishap" },
            { name: "Presence", desc: "Make a DC 12 CHA check to enact one of the following effects. If you fail (excluding focus), you can't use  that effect again until you rest. <ul><li><b>Inspire.</b> One target in near gains a luck token.</li><li><b>Fascinate</b> (Focus). You transfix all chosen targets of level 4 or less within near.</li></ul>", printDesc: "Charisma check to enact these effects; on fail cannot use that effect until you rest. INSPIRE: DC 12. One target in near gains a luck token. FASCINATE: DC 15. You transfix all targets of level 4 or less within near for 1d4 rounds" },
            { name: "Prolific", desc: "Add 1d6 to your learning rolls. Groups carousing with 1 or more bards add 1d6 to their rolls.", printDesc: "Add 1d6 to your learning rolls. Groups carousing with 1 or more bards add 1d6 to their rolls." },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Bard", specialSourceCategory: "Ability", specialLanguages: ["ALL_COMMON"], specialLanguageNumber: 1, specialLabel: "Bard languages (common)" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Bard", specialSourceCategory: "Ability", specialLanguages: ["ALL_COMMON"], specialLanguageNumber: 2, specialLabel: "" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Bard", specialSourceCategory: "Ability", specialLanguages: ["ALL_COMMON"], specialLanguageNumber: 3, specialLabel: "" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Bard", specialSourceCategory: "Ability", specialLanguages: ["ALL_COMMON"], specialLanguageNumber: 4, specialLabel: "" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Bard", specialSourceCategory: "Ability", specialLanguages: ["ALL_RARE"], specialLanguageNumber: 5, specialLabel: "Bard language (rare)" }
        ],
        talents: [
            { min: 2, max: 2, desc: "You find a random priest or wizard wand (you choose)", name: "FindRandomWand" },
            { min: 3, max: 6, desc: "+1 to melee and ranged attacks or +1 to Magical Dabbler rolls", name: "Plus1ToAttacksOrPlus1ToMagicalDabbler", options: ["+1 to melee and ranged attacks", "+1 to Magical Dabbler rolls"] },
            { min: 7, max: 9, desc: "+2 points to distribute to any stats", name: "+2 Stat Points", options: ["STR", "DEX", "CON", "INT", "WIS", "CHA"], size: 1 },
            { min: 10, max: 11, desc: "Your Presence effects become DC 9 to enact", name: "ReducePerformDC", rerollDuplicates: true },
            { min: 12, max: 12, desc: "Choose a talent", name: "ChooseTalent" }
        ],
        freeSpells: [],
        spellCastingStat: "CHA",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 2 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 2 },
            { stat: "INT", weight: 2 },
            { stat: "WIS", weight: 2 },
            { stat: "CHA", weight: 5 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 2 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 2 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 2 },
            { ancestry: "Mycellan", chance: 2 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Bard2.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    // Cursed scroll #1

    {
        name: "Knight of St. Ydris",
        desc: "Cursed knights who walk the path of St. Ydris the Unholy, the Possessed. They embrace the darkness in order to fight it, cleansing evil with a flurry of steel and forbidden sorcery.",
        sourceId: "CS1",
        sourceNote: "From Cursed Scroll #1 : Diablerie!",
        sourceURL: arcaneLibraryCursedScroll1_Diablerie_URL,
        weapons: "All melee weapons, crossbow",
        armor: "All armor and shields",
        hitDie: 6,
        extras: [
            { name: "Languages", desc: "You know Diabolic." },
            { name: "Demonic Possession", desc: "3/day, gain a +1 bonus to your damage rolls that lasts 3 rounds. In addition, add half your level to the damage bonus (round down).", printDesc: "" },
            { name: "Spellcasting", desc: "You can cast witch spells you know.<p>Starting at level 3, each time you gain a level, you choose new witch spells to learn according to the Witch Spells Known table.<p/><p>You use your Charisma stat to cast witch spells. The DC is 10 + the spell's tier.</p><p>If you fail a spellcasting check, you can't cast that spell again until you complete a rest.</p><p>If you roll a natural 1 on a spellcasting check, you must also roll on the corresponding Diabolical Mishap table (see pg. 22) for the spell's tier.</p>", printDesc: "You begin learning Witch spells at level 3. " },
        ],
        extraLanguages: ["Diabolic"],
        levelNotes: [
            { level: 2, notes: [{ title: "Demonic Possession", note: "Demonic Possession bonus increases to +2 to damage, 3/day for 3 rounds" }] },
            { level: 4, notes: [{ title: "Demonic Possession", note: "Demonic Possession bonus increases to +3 to damage, 3/day for 3 rounds" }] },
            { level: 6, notes: [{ title: "Demonic Possession", note: "Demonic Possession bonus increases to +4 to damage, 3/day for 3 rounds" }] },
            { level: 8, notes: [{ title: "Demonic Possession", note: "Demonic Possession bonus increases to +5 to damage, 3/day for 3 rounds" }] },
            { level: 10, notes: [{ title: "Demonic Possession", note: "Demonic Possession bonus increases to +6 to damage, 3/day for 3 rounds" }] }
        ],
        specialBonuses: [
            { gainedAtLevel: 3, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 5, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 2, specialLabel: "" },
        ],
        talents: [
            { min: 2, max: 2, desc: "Your Demonic Possession bonus increases by 1 point", name: "Plus1DemonicPossession" },
            { min: 3, max: 6, desc: "+1 to melee or ranged attacks", name: "Plus1ToHit", options: ["Melee attacks", "Ranged attacks"] },
            { min: 7, max: 9, desc: "+2 to Strength, Dexterity, or Constitution stat", name: "StatBonus", options: ["STR", "DEX", "CON"], size: 2 },
            { min: 10, max: 11, desc: "+2 to Charisma stat or +1 to witch spellcasting checks", name: "Plus2CHAOrPlus1Casting" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "CHA",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 5 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 2 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 3 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 2 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        spellCastingClassNum: 2,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "KnightStYdris2.png",
        imgWidth: 179,
        imgHeight: 175,
    },
    {
        name: "Warlock",
        desc: "Howling warriors with sharpened teeth, wild-eyed doomspeakers preaching of The Dissolution, and cloaked lore-hunters bearing the hidden Mark of Shune.",
        sourceId: "CS1",
        sourceNote: "From Cursed Scroll #1 : Diablerie!",
        sourceURL: arcaneLibraryCursedScroll1_Diablerie_URL,
        weapons: "Club, crossbow, dagger, longsword, mace",
        armor: "Leather armor, chainmail, mithral chainmail, shields",
        hitDie: 6,
        extras: [
            { name: "Languages", desc: "You know either Celestial, Diabolic, Draconic, Primordial, or Sylvan." },
            { name: "Patron", desc: "Choose a patron to serve. Your patron is the source of your supernatural gifts.<p>Your patron can choose to grant or withhold its gifts at any time. You can gain new Patron Boons/talents (or lose them) as a result.</p>", printDesc: "Choose a patron to serve." },
            { name: "Patron Boon", desc: "At 1st level, you gain a random Patron Boon talent based on your chosen patron.<p>Whenever you gain a new talent roll, you may choose to roll on your Patron Boon table rather than the Warlock Talents table.</p>", printDesc: "Gain a random Boon from your Patron. When gain a talent, you can choose to roll for a Patron boon instead." },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "SelectPatron", specialComponentName: "SelectPatron", specialSourceType: "Class", specialSourceName: "Warlock", specialSourceCategory: "Patron", specialLabel: "Patron" },
            { gainedAtLevel: 1, specialName: "PatronBoon", specialComponentName: "SelectPatronBoonTalent", specialSourceType: "Class", specialSourceName: "Warlock", specialSourceCategory: "InitialPatronBoon", specialLabel: "Patron Boon" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Warlock", specialSourceCategory: "Ability", specialLanguages: ["Celestial", "Diabolic", "Draconic", "Primordial", "Sylvan"], specialLanguageNumber: 1, specialLabel: "Warlock language" },
        ],
        talents: [
            { min: 2, max: 2, desc: "Roll a Patron Boon from any patron; an unexplained gift", name: "GainRandomBoon" },
            { min: 3, max: 6, desc: "Add +1 point to two stats (they must be different)", name: "+2 Different Stat Points" },
            { min: 7, max: 9, desc: "+1 to melee or ranged attacks", name: "Plus1ToHit", options: ["Melee attacks", "Ranged attacks"] },
            { min: 10, max: 11, desc: "Roll two Patron Boons and choose one to keep", name: "RollTwoBoonsAndKeepOne" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "CHA",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 4 },
            { stat: "DEX", weight: 4 },
            { stat: "CON", weight: 3 },
            { stat: "INT", weight: 3 },
            { stat: "WIS", weight: 3 },
            { stat: "CHA", weight: 3 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 2 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        spellCastingClassNum: 0,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Warlock2.png",
        imgWidth: 175,
        imgHeight: 175,
    },
    {
        name: "Witch",
        desc: "Cackling crones stooped over cauldrons, chanting shamans smeared in blood and clay, and outcast maidens with milky eyes that see portents and secrets.",
        sourceId: "CS1",
        sourceNote: "From Cursed Scroll #1 : Diablerie!",
        sourceURL: arcaneLibraryCursedScroll1_Diablerie_URL,
        weapons: "Dagger, staff",
        armor: "Leather armor",
        hitDie: 4,
        extras: [
            { name: "Languages", desc: "You know Diabolic, Primordial, and Sylvan." },
            { name: "Familiar", desc: "You have a small animal such as a raven, rat, or frog who serves you loyally. It can speak Common. <p>Your familiar can be the source of spells you cast. Treat it as though it were you for determining spell ranges.</p> <p>If your familiar dies, you can restore it to life by permanently sacrificing 1d4 hit points.</p>", printDesc: "A small animal which speaks Common. Can be source of your spells." },
            { name: "Spellcasting", desc: "You can cast witch spells you know.<p>You know three tier 1 spells of your choice from the witch spell list (see pg. 24).</p> <p>Each time you gain a level, you choose new witch spells to learn according to the Witch Spells Known table.<p/><p>You use your Charisma stat to cast witch spells. The DC is 10 + the spell's tier.</p><p>If you fail a spellcasting check, you can't cast that spell again until you complete a rest.</p><p>If you roll a natural 1 on a spellcasting check, you must also roll on the corresponding Diabolical Mishap table for the spell's tier (see pg. 22).</p>", printDesc: "" },
        ],
        extraLanguages: ["Diabolic", "Primordial", "Sylvan"],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 3, specialLabel: "" },

            { gainedAtLevel: 2, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 3, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 5, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Witch", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 2, specialLabel: "" },
        ],
        talents: [
            { min: 2, max: 2, desc: "1/day, teleport to your familiar's location as a move", name: "TeleportToFamiliar" },
            { min: 3, max: 7, desc: "+2 to Charisma stat or +1 to witch spellcasting checks", name: "Plus2CHAOrPlus1Casting" },
            { min: 8, max: 9, desc: "Gain advantage on casting one spell you know", name: "AdvOnCastOneSpell" },
            { min: 10, max: 11, desc: "Learn an additional witch spell of any tier you can cast", name: "LearnExtraSpell" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "CHA",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 1 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 2 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 5 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 2 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 2 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 2 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 2 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        spellCastingClassNum: 2,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Witch2.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    // Cursed scroll #2

    {
        name: "Desert Rider",
        desc: "Howling barbarians thundering across the sand on wild horses, elven spies wielding curved blades atop silvery camels, or bandits wrapped in colorful silks racing on sleek, desert stallions.",
        sourceId: "CS2",
        sourceNote: "From Cursed Scroll #2 : Red Sands",
        sourceURL: arcaneLibraryCursedScroll2_RedSands_URL,
        weapons: "Club, dagger, javelin, longsword, pike, scimitar, shortbow, spear, whip",
        armor: "Leather armor, shields",
        hitDie: 8,
        extras: [
            { name: "Charge", desc: "3/day, you can charge into combat by moving at least near before attacking. Each time you do this, your melee attacks deal double damage that round.", printDesc: "3/day charge by moving near before attacking; your melee attacks do double damage that round" },
            { name: "Mount", desc: "You have a common camel or horse with a reliable or lovely demeanor (see pg. 29). It comes when you call and never spooks. You can only have one such mount at a time.<p>While riding your mount, you both get a bonus to AC equal to half your level (round down). Your mount has additional levels equal to half your level (round down). You can freely leap on or off your mount once per round.</p><p>If you lose your mount, you can use your downtime to acquire and train another. Pass a DC 15 CHA check for the new creature to become your mount. Lower the DC one step each attempt.</p>", printDesc: "You have a camel or horse with a reliable or lovely demeanor; while riding you both get a bonus to AC equal to half your level (round down); you can freely leap off or on your mount once per round." },
        ],
        extraLanguages: [],
        levelNotes: [
            { level: 2, notes: [{ title: "Expert Rider", note: "AC bonus when riding increases to +1. Mount's level increases by 1" }] },
            { level: 4, notes: [{ title: "Expert Rider", note: "AC bonus when riding increases to +2. Mount's level increases by 1" }] },
            { level: 6, notes: [{ title: "Expert Rider", note: "AC bonus when riding increases to +3. Mount's level increases by 1" }] },
            { level: 8, notes: [{ title: "Expert Rider", note: "AC bonus when riding increases to +4. Mount's level increases by 1" }] },
            { level: 10, notes: [{ title: "Expert Rider", note: "AC bonus when riding increases to +5. Mount's level increases by 1" }] },
        ],
        specialBonuses: [],
        talents: [
            { min: 2, max: 2, desc: "You can use any rider-bearing creature as your mount", name: "UseAnyMount", rerollDuplicates: true },
            { min: 3, max: 6, desc: "You gain +1 to attacks or damage", name: "Plus1ToAttacksOrDamage" },
            { min: 7, max: 9, desc: "+2 to Strength or Dexterity stat, or +1 to melee attacks", name: "Plus2STROrDEXOrPlus1MeleeAttacks", options: ["STR", "DEX"], size: 2 },
            { min: 10, max: 11, desc: "Gain an additional use of your Charge talent each day", name: "ImpCharge" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 5 },
            { stat: "DEX", weight: 5 },
            { stat: "CON", weight: 3 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 2 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 2 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "DesertRider2.png",
        imgWidth: 175,
        imgHeight: 175,
    },
    {
        name: "Pit Fighter",
        desc: "Blood-soaked warriors circling each other in a roaring arena, scarred desert bandits dueling for the right to lead their gang, or brash tavern brawlers who never turn down a challenge.",
        sourceId: "CS2",
        sourceNote: "From Cursed Scroll #2 : Red Sands",
        sourceURL: arcaneLibraryCursedScroll2_RedSands_URL,
        weapons: "All weapons",
        armor: "Leather armor, shields",
        hitDie: 8,
        extras: [
            { name: "Flourish", desc: "3/day, regain 1d6 hit points when you hit an enemy with a melee attack.", printDesc: "3/day, regain 1d6 hit points when you hit with a melee attack." },
            { name: "Implacable.", desc: "You have advantage on Constitution checks to resist injury, poison, or endure extreme environments.", printDesc: "ADV on CON checks to resist injury, poison, or endure extreme environments." },
            { name: "Last Stand", desc: "You get up from dying with 1 hit point on a natural d20 roll of 18-20.", printDesc: "You get up from dying with 1 hit point on a natural d20 roll of 18-20." },
            { name: "Relentless", desc: "3/day, when you are reduced to 0 HP, make a DC 18 Constitution check (the Implacable talent applies to this roll). On a success, you instead go to 1 HP.", printDesc: "3/day, when you are reduced to 0 HP, make a DC 18 Constitution check (Implacable talent applies). On a success, you instead go to 1 HP." },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [],
        talents: [
            { min: 2, max: 2, desc: "1/day, ignore all damage and effects from one attack", name: "IgnoreOneAttack" },
            { min: 3, max: 6, desc: "You gain +1 to melee weapon damage", name: "Plus1ToMeleeDamage" },
            { min: 7, max: 9, desc: "+2 to Strength or Constitution stat, or +1 to melee attacks", name: "Plus2STROrCONOrPlus1MeleeAttacks", options: ["STR", "CON"], size: 2 },
            { min: 10, max: 11, desc: "Increase the HP you gain from Flourish by 1d6", name: "ImpFlourish" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 3 },
            { stat: "DEX", weight: 3 },
            { stat: "CON", weight: 5 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 2 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 2 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 2 },
            { ancestry: "Dragonborn", chance: 2 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 3 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "PitFighter2.png",
        imgWidth: 175,
        imgHeight: 175,
    },
    {
        name: "Ras-Godai",
        desc: "Black-clad assassins who train from childhood inside a hidden desert monastery. They gain their sorcerous powers from a legendary black lotus flower that was given to them by a demon.",
        sourceId: "CS2",
        sourceNote: "From Cursed Scroll #2 : Red Sands",
        sourceURL: arcaneLibraryCursedScroll2_RedSands_URL,
        weapons: "Blowgun, bolas, dagger, razor chain, scimitar, shuriken, spear",
        armor: "Leather armor",
        hitDie: 6,
        extras: [
            { name: "Languages", desc: "You know Diabolic." },
            { name: "Assassin", desc: "You have advantage on checks to sneak and hide. Your attacks deal double damage against targets that are unaware of your presence.", printDesc: "ADV on checks to sneak and hide. Your attacks deal double damage against targets that are unaware of your presence." },
            { name: "Smoke Step", desc: "3/day, teleport to a location you can see within near. This does not use your action.", printDesc: "3/day, teleport to a location you can see within near. This does not use your action." },
            { name: "Black Lotus", desc: "You earned the right to eat a petal of the fabled black lotus flower, and you survived its sorcerous effects. Roll one talent on the Black Lotus Talents table.", printDesc: "You gain one Black Lotus Talent." },
        ],
        extraLanguages: ["Diabolic"],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "BlackLotusTalent", specialComponentName: "BlackLotusTalent_FreeAtClassLevel1", specialSourceType: "Class", specialSourceName: "Ras-Godai", specialSourceCategory: "Talent", specialLabel: "" },
        ],
        talents: [
            { min: 2, max: 2, desc: "You are trained in the use of poisons", name: "UsePoisons", rerollDuplicates: true },
            { min: 3, max: 6, desc: "Roll an additional talent on the Black Lotus Talents table", name: "GrantSpecialTalent:BlackLotusTalent_FreeAtClassLevel1" },
            { min: 7, max: 9, desc: "+2 to Strength or Dexterity stat, or +1 to melee attacks", name: "Plus2STROrDEXOrPlus1MeleeAttacks", options: ["STR", "DEX"], size: 2 },
            { min: 10, max: 11, desc: "Gain an additional use of your Smoke Step talent", name: "ImpSmokeStep" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 4 },
            { stat: "DEX", weight: 5 },
            { stat: "CON", weight: 4 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 2 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 2 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 2 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 2 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 2 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 2 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "RasGodai2.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    // Cursed Scroll #3

    {
        name: "Sea Wolf",
        desc: "Seafaring raiders who prowl the isles for plunder in dragonheaded longboats. When the warhorn sounds, they become fierce berserkers and shield maidens who hope to please their gods with a brave death.",
        sourceId: "CS3",
        sourceNote: "From Cursed Scroll #3 : Midnight Sun",
        sourceURL: arcaneLibraryCursedScroll3_MidnightSun_URL,
        weapons: "Dagger, greataxe, handaxe, longbow, longsword, spear",
        armor: "Leather armor, chainmail, mithral chainmail, shields",
        hitDie: 8,
        extras: [
            { name: "Seafarer", desc: "You have advantage on checks related to navigating and crewing boats.", printDesc: "Trained in navigating and crewing boats." },
            { name: "Old Gods", desc: "Each day, your purpose aligns with one of the Old Gods (pg. 17). Choose one of the below options after you complete a rest; you gain its benefits until you complete your next rest. <ul><li><b>Odin.</b> You regain 1d4 HP every time you kill an enemy.</li><li><b>Freya.</b> You gain a luck token if you don't have one. Each time you use a luck token, add 1d6 to your roll.</li><li><b>Loki.</b> You have advantage on checks to lie, sneak, and hide.</li></ul>", printDesc: "Choose an Old God after each rest: ODIN: You regain 1d4 HP every time you kill an enemy; FREYA: You gain a luck token if you don't have one. Each time you use a luck token, add 1d6 to your roll.; LOKI: You have advantage on checks to lie, sneak, and hide." },
            { name: "Shield Wall", desc: "If you wield a shield, you can use your action to take a defensive stance. Your AC becomes 20 during this time.", printDesc: "Use your action to increase your AC to 20." },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [],
        talents: [
            { min: 2, max: 2, desc: "1/day, go berserk: immune to damage for 3 rounds", name: "Berserk" },
            { min: 3, max: 6, desc: "Your attacks deal +1 damage", name: "Plus1ToDamage" },
            { min: 7, max: 9, desc: "+2 to Strength or Constitution stat, or +1 to attacks", name: "Plus2STROrCONOrPlus1Attacks", options: ["STR", "CON"], size: 2 },
            { min: 10, max: 11, desc: "Duality; choose two different Old Gods effects each day", name: "DualOldGods", rerollDuplicates: true },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 5 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 4 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 2 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 2 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 2 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 2 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 3 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "SeaWolf2.png",
        imgWidth: 175,
        imgHeight: 175,
    },
    {
        name: "Seer",
        desc: "Baleful diviners who reek of smoke and blood. They untangle the whispers of the gods by reading the runes, the bones, and the stars. Their knowledge of fate allows them to bend it.",
        sourceId: "CS3",
        sourceNote: "From Cursed Scroll #3 : Midnight Sun",
        sourceURL: arcaneLibraryCursedScroll3_MidnightSun_URL,
        weapons: "Dagger, stave, spear",
        armor: "Leather armor",
        hitDie: 6,
        extras: [
            { name: "Destined", desc: "Whenever you use a luck token, add 1d6 to the roll.", printDesc: "Whenever you use a luck token, add 1d6 to the roll." },
            { name: "Omen", desc: "3/day, you can make a DC 9 WIS check. On a success, gain a luck token (you can't have more than one luck token at a time).", printDesc: "3/day, you can make a DC 9 WIS check. On a success, gain a luck token (you can't have more than one luck token at a time)." },
            { name: "Spellcasting", desc: "You can cast seer spells you know. You know one tier 1 spell of your choice from the seer spell list.<p>Each time you gain a level, you choose a new seer spell to learn according to the Seer Spells Known table.</p><p>You use your Wisdom stat to cast seer spells. The DC is 10 + the spell's tier.</p><p> If you fail a spellcasting check, you can't cast that spell again until you complete a rest. If you roll a natural 1 on a spellcasting check, you can't cast that spell again until you complete Seer Penance.</p>", printDesc: "" },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "Tier 1 Seer spell" },
            { gainedAtLevel: 2, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 3, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 5, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Seer", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 2, specialLabel: "" },

        ],
        talents: [
            { min: 2, max: 2, desc: "Learn an additional seer spell from any tier you can cast", name: "LearnExtraSpell" },
            { min: 3, max: 6, desc: "Gain an additional use of your Omen talent each day", name: "GainExtraOmen" },
            { min: 7, max: 9, desc: "+2 to WIS or CHA stat, or +1 to spellcasting checks", name: "PlusTwoWISCHAOrPlus1SpellCasting", options: ["WIS", "CHA"], size: 2 },
            { min: 10, max: 11, desc: "Increase the die category of your Destined talent by one", name: "ImpDestined" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "WIS",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 1 },
            { stat: "DEX", weight: 1 },
            { stat: "CON", weight: 2 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 6 },
            { stat: "CHA", weight: 2 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 2 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 2 }
        ],
        spellCastingClassNum: 3,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Seer2.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    // Unnatural Selection classes

    {
        name: "Beastmaster",
        desc: "Limbs and mouths contort and crack as bones and jaws rearrange themselves.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL,
        weapons: "All missile weapons",
        armor: "None",
        hitDie: 8,
        extras: [
            { name: "Languages", desc: "You can speak to your chosen animal species.", printDesc: "You can speak to your chosen animal species." },
            { name: "Beast Attack", desc: "Choose one animal type to use for all melee attacks (see <b>Class Options</b> below). If your talents allow for multiple animals, you may choose which animal to use with every individual attack.", printDesc: "Choose one animal type to use for all melee attacks. (If have multiple animal types, pick one attack type each round)." },
            { name: "Beast Hide", desc: "Your skin is covered with a protective hide. If your CON modifier is positive, also add it to your AC.", printDesc: "Protective hide. Add CON bonus to your AC." },
            { name: "Beast Shape", desc: "Once per day, you and your possessions may transform into the natural form of your chosen beast for 3 rounds, taking on the animal's statistics, attacks, and abilities. You still need light to see.<p>If you take damage while in this form, do not transfer that damage to your own HP when you return to your humanoid form. If you reach 0 HP in your animal form, you transform back at 0 HP, starting your death timer as normal.</p>", printDesc: "1/day, you and your possessions may transform into your chosen beast for 3 rounds, taking on the animal's statistics, attacks, and abilities. You still need light to see. If you take damage while in this form, do not transfer that damage to your own HP when you return to your humanoid form. If you reach 0 HP in your animal form, you transform back at 0 HP, starting your death timer as normal." },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "Beast Master Animal Type", specialComponentName: "BeastMasterAnimalType", specialSourceType: "Class", specialSourceName: "Beastmaster", specialSourceCategory: "Ability", specialLabel: "" },
        ],
        talents: [
            { min: 2, max: 2, desc: "You may use Beast Shape 1 additional time per day", name: "ExtraBeastShapePerDay" },
            { min: 3, max: 5, desc: "Increase die category to chosen Beast Attack damage", name: "BeastAttackDamageIncreaseOneDie" },
            { min: 6, max: 9, desc: "+2 to Strength or Constitution stat or +1 to unarmored AC", name: "Plus2STROrCONOrPlus1AC", options: ["STR", "CON"], size: 2 },
            { min: 10, max: 11, desc: "Make an animal magic item (see p 135, 136, 144 of Un. Sel.).", name: "MakeAnimalMagicItem" },
            { min: 12, max: 12, desc: "Select and add a new beast attack to your arsenal.", name: "BeastMasterAnimalType" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 5 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 4 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 2 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 2 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Beastmaster.png",
        imgWidth: 175,
        imgHeight: 175,
    },


    {
        name: "Fiend",
        desc: "Disciplined inferno keepers, molten architects, or diabolical pyromancers harness the energy of arcane flame from the molten heart of the earth, the nightmarish abyss, or the searing forges of fallen stars.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL,
        weapons: "All weapons",
        armor: "All armor and shields",
        hitDie: 8,
        extras: [
            { name: "Languages", desc: "You can speak the Diabolic language." },
            { name: "Wicked Tongue", desc: "Your oratory prowess deceives others, granting you advantage on any negotiation, intimidation, persuasion, or misdirection checks.", printDesc: "Adv on any negotiation, intimidation, persuasion, or misdirection checks" },
            { name: "Firebond Weapon", desc: "Pulling from the eternal flames of the abyss, you can ignite your melee or ranged weapon as part of making an attack. It deals an additional d4 of fire damage on hit.<p>The weapon's flame is not enough to pierce the darkness.</p>", printDesc: "You can ignite your melee or ranged weapon as part of making an attack. It deals an additional d4 of fire damage on hit. The weapon's flame is not enough to pierce the darkness." },
            { name: "Pyromancy", desc: "You wield the relentless energy of the eternal flame in powerful and unique ways. Only one pyromancy talent can be active at a time.<p>Upon gaining level 2, roll one talent from the Pyromancy table, with additional rolls at every even level.</p><p>You are immune to your own pyromancy.</p>", printDesc: "You wield the relentless energy of the eternal flame in powerful and unique ways. Only one pyromancy talent can be active at a time. Upon gaining level 2, roll one talent from the Pyromancy table, with additional rolls at every even level. You are immune to your own pyromancy." },
        ],
        extraLanguages: ["Diabolic"],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 2, specialName: "PyromancyTalent", specialComponentName: "PyromancyTalent", specialSourceType: "Class", specialSourceName: "Fiend", specialSourceCategory: "Talent", specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PyromancyTalent", specialComponentName: "PyromancyTalent", specialSourceType: "Class", specialSourceName: "Fiend", specialSourceCategory: "Talent", specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PyromancyTalent", specialComponentName: "PyromancyTalent", specialSourceType: "Class", specialSourceName: "Fiend", specialSourceCategory: "Talent", specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PyromancyTalent", specialComponentName: "PyromancyTalent", specialSourceType: "Class", specialSourceName: "Fiend", specialSourceCategory: "Talent", specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PyromancyTalent", specialComponentName: "PyromancyTalent", specialSourceType: "Class", specialSourceName: "Fiend", specialSourceCategory: "Talent", specialLabel: "" },
        ],
        talents: [
            { min: 2, max: 2, desc: "1/day, know the desires or fears of a creature in near range", name: "KnowDesires" },
            { min: 3, max: 6, desc: "+1 to melee or ranged attacks", name: "Plus1ToHit", options: ["Melee attacks", "Ranged attacks"] },
            { min: 7, max: 9, desc: "+2 to Strength, Dexterity or Constitution stat", name: "Plus2STROrDEXOrCON", options: ["STR", "DEX"], size: 2 },
            { min: 10, max: 11, desc: "Roll an additional talent on the Pyromancy Talents table", name: "GrantSpecialTalent:PyromancyTalent" },
            { min: 12, max: 12, desc: "Choose one option or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 4 },
            { stat: "DEX", weight: 4 },
            { stat: "CON", weight: 4 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 3 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 2 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 2 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 2 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 2 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 2 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Fiend.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    {
        name: "Grave Warden",
        desc: "Reverent undertakers, eager bone-setters, or cold-hearted executioners who weild the power of the undead to do their bidding.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL,
        weapons: "Dagger, scythe",
        armor: "Leather armor",
        hitDie: 4,
        extras: [
            { name: "Languages", desc: "You know one rare language.", printDesc: "You know one rare language." },
            { name: "Necrotic Reap", desc: "You can cast touch spells through a scythe. Add +1 spellcasting bonus to touch spells when doing so.", printDesc: "You can cast touch spells through a scythe. Add +1 spellcasting bonus to touch spells when doing so." },
            { name: "Claim Undead", desc: "1/day you can seize control of any number of undead. Their total LV cannot exceed your own. Undead make a DC 12 CHA check to resist.", printDesc: "1/day you can seize control of any number of undead. Their total LV cannot exceed your own. Undead make a DC 12 CHA check to resist." },
            { name: "Spellcasting", desc: "You can cast grave warden spells you know.<p>You may choose three tier 1 spells of your choice from the grave warden spell list.<p/><p>Each time you gain a level, you choose new grave warden spells to learn according to the Grave Warden Spells Known table.</p><p>Add your constitution modifier when casting grave warden spells. The DC is 10 + the spell's tier.</p><p>If you fail a spellcasting check, you can't cast that spell again until you complete a rest.</p><p>If you roll a natural 1 on a spellcasting check, you must also roll on the corresponding Necrotic Mishap table for the spell's tier.<p>", printDesc: "" },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialLanguages: ["ALL_RARE"], specialLanguageNumber: 1, specialLabel: "Rare Language" },

            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "Tier 1 Grave Warden spells" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 2, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 3, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 5, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 4, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Grave Warden", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 2, specialLabel: "" }
        ],
        talents: [
            { min: 2, max: 2, desc: "Claim Undead gains one DC step for undead resistance (max DC 18)", name: "ImpClaimUndead" },
            { min: 3, max: 7, desc: "+2 to constitution stat or +1 to grave warden spellcasting checks", name: "PlusTwoCONOrPlusOneSpellCasting" },
            { min: 8, max: 9, desc: "Gain advantage on casting one spell you know", name: "AdvOnCastOneSpell" },
            { min: 10, max: 11, desc: "Increase the Necrotic Reap bonus by an additional +2", name: "ImpNecroticReap" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "CON",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 2 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 6 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 2 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 3 },
            { ancestry: "Goblin", chance: 3 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 2 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 2 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        spellCastingClassNum: 4,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "GraveWarden.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    {
        name: "Ovate",
        desc: "Custodians of nature's balance, wielders of flora's might, or omen-reading diviners who guide the primal forces to shape our destiny.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL,
        weapons: "Bolas, club, staff, sling, whip",
        armor: "Leather armor, wooden shields",
        hitDie: 6,
        extras: [
            { name: "Languages", desc: "You can speak to any two species of animal.", printDesc: "You can speak to any two species of animal." },
            { name: "Animal Companion", desc: "To start, you verbally control one 0-level non-insect animal companion such as a fox, owl, or iguana. (See <b>'Select Animal Companion'</b>) below.", printDesc: "To start, you verbally control one 0-level non-insect animal companion such as a fox, owl, or iguana." },
            { name: "Calm Animals", desc: "1/day, your soothing aura pacifies natural animals within near. Their total LV cannot exceed 2X your own. Giant animals attempt a DC 12 WIS check to resist. A calmed animal will regard you as a friend, but not a companion.", printDesc: "1/day, pacify natural animals within near. Their total LV cannot exceed 2X your own. Giant animals attempt a DC 12 WIS check to resist. A calmed animal will regard you as a friend, but not a companion." },
            { name: "Train Animal", desc: "You can train a calm or captive animal to become new Animal Companion. You can only have one animal companion at a time, and its LV cannot not exceed yours. For every 2 LV of the animal, training takes 1 day. You must know its langage to understand it. (See '<b>Select Animal Companion</b>') below.", printDesc: "You can train a calm or captive animal to become new Animal Companion. You can only have one animal companion at a time, and its LV cannot not exceed yours. For every 2 LV of the animal, training takes 1 day. You must know its langage to understand it." },
            { name: "Spellcasting", desc: "You can cast ovate spells you know. You may choose two tier 1 spells from the ovate spell list.<p/><p>Each time you gain a level, you choose new ovate spells based on the Ovate Spells Known table.</p><p>Add your wisdom modifier when casting ovate spells. The DC is 10 + the spell's tier.</p><p>If you fail a spellcasting check, you can't cast that spell again until you complete a rest.</p><p>If you roll a 1, you can't cast that spell again until you attend a ritualistic purging or ceremony.<p>", printDesc: "" },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "Tier 1 Ovate spells" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 2, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 3, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 5, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 2, specialLabel: "" },

            { gainedAtLevel: 1, specialName: "PickAnimalCompanions", specialComponentName: "PickAnimalCompanions", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "Select Animal Companion" },

            { gainedAtLevel: 1, specialName: "ExtraLanguageManual", specialComponentName: "ExtraLanguageManual", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialLanguageNumber: 1, specialLabel: "Animal Languages", },
            { gainedAtLevel: 1, specialName: "ExtraLanguageManual", specialComponentName: "ExtraLanguageManual", specialSourceType: "Class", specialSourceName: "Ovate", specialSourceCategory: "Ability", specialLabel: "", specialLanguageNumber: 2 },
        ],
        talents: [
            { min: 2, max: 2, desc: "The number of animal companions you can have increases by one", name: "ExtraAnimalCompanion" },
            { min: 3, max: 6, desc: "Gain advantage on casting one spell you know", name: "AdvOnCastOneSpell" },
            { min: 7, max: 9, desc: "+2 to wisdom or +1 to ovate spellcasting checks", name: "PlusTwoWISOrPlus1SpellCasting" },
            { min: 10, max: 11, desc: "You can speak to an additional species of animal", name: "ExtraLanguageManual" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "WIS",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 1 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 2 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 6 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 3 },
            { ancestry: "Goblin", chance: 3 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 2 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 2 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        spellCastingClassNum: 5,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Ovate.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    {
        name: "Plague Doctor",
        desc: "Shadow-veiled alchemical apothecaries, compassionate herbalist healers, or beak-masked disease cultists who can perform surgeries and harness the power of science.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL,
        weapons: "Censer flail, darts, needle whip, stiletto, rapier",
        armor: "Leather armor",
        hitDie: 6,
        extras: [
            { name: "Languages", desc: "You know two additional common languages." },
            { name: "Mask Infusion", desc: "Inhale one pre-made herbal elixir from your mask for immediate infusion. Lasts all day, expires on use.", printDesc: "Inhale one pre-made herbal elixir from your mask for immediate infusion. Lasts all day, expires on use." },
            { name: "Mesmerize Insect", desc: "1/day, you bewitch an insect to automatically deliver an <i>injure elixir</i> to a target within near range via sting or bite.", printDesc: "1/day, you bewitch an insect to automatically deliver an injure elixir to a target within near range via sting or bite." },
            { name: "Elixirs", desc: "You can craft elixirs by making an INT check. Apply the modifier for the chosen delivery method (See Delivery Method table) to this check. This does not cost an action.<p>If you fail, you can't make that elixir again until you rest. Except for mask infusions, elixirs expire in 5 rounds.</p><p>Critical success increases DC or die category by one or doubles duration. Critical fails self-inflict harmful elixirs.</p>", printDesc: "You can craft elixirs by making an INT check. Apply the modifier for the chosen delivery method to this check. This does not cost an action. If you fail, you can't make that elixir again until you rest. Except for mask infusions, elixirs expire in 5 rounds. Critical success increases DC or die category by one or doubles duration. Critical fails self-inflict harmful elixirs." },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Plague Doctor", specialSourceCategory: "Ability", specialLanguages: ["ALL_COMMON"], specialLanguageNumber: 1, specialLabel: "Plague Doctor languages (common)" },
            { gainedAtLevel: 1, specialName: "ExtraLanguage", specialComponentName: "ExtraLanguage", specialSourceType: "Class", specialSourceName: "Plague Doctor", specialSourceCategory: "Ability", specialLanguages: ["ALL_COMMON"], specialLanguageNumber: 2, specialLabel: "" }
        ],
        talents: [
            { min: 2, max: 2, desc: "Mesmerize one additional insect per day.", name: "ExtraInsect", rerollDuplicates: true },
            { min: 3, max: 6, desc: "Add an extra die rolled on an elixir you choose.", name: "StrongElixir" },
            { min: 7, max: 9, desc: "+2 to intelligence or dexterity stat, or +1 to elixir checks", name: "Plus2INTDEXOrPlus1Elixir", options: ["STR", "DEX"], size: 2 },
            { min: 10, max: 11, desc: "You gain ADV on elixir checks for an elixir you choose", name: "ADVToElixir" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 1 },
            { stat: "DEX", weight: 3 },
            { stat: "CON", weight: 3 },
            { stat: "INT", weight: 6 },
            { stat: "WIS", weight: 1 },
            { stat: "CHA", weight: 1 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 2 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 2 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 2 },
            { ancestry: "Tiefling", chance: 2 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 2 },
            { ancestry: "Risen", chance: 2 },
            { ancestry: "Slimefolk", chance: 2 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "PlagueMask.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    {
        name: "Shaman",
        desc: "Indispensible medicine men and oracles who serve as healers, guardians of ancient wisdom, and conduits between the tangible and ethereal realms.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL,
        weapons: "Club, dagger, hunting knife, javelin, longbow, shortbow, spear, staff",
        armor: "None",
        hitDie: 6,
        extras: [
            { name: "Languages", desc: "You know Primordial.", printDesc: "You know Primordial" },
            { name: "Healing Circle", desc: "1/day you can perform a 10-minute drumming and chanting meditation. All participants are healed for 1d4 HP if the performance is not interrupted. A spellcaster or herbalist can recover a lost spell or elixir instead. Random encounter chance is increased.", printDesc: "" },
            { name: "Chanting", desc: "You can perform shamanic chants you know using your drum and voice.<p>You know two tier 1 chants of your choice from the shamanic chants list.<p/><p>Each time you gain a level, you choose new shamanic chants to learn according to the Shamanic Chants Known Table.</p><p>Add your Wisdom modifier to perform shamanic chants. The DC is 9 + the chant's tier.</p>If you fail a chanting check, you can't perform that chant again until you complete a rest.<p></p><p>For performing shamanic chants, see Chanting on pg. 39 of Un. Sel. In brief, all chants are like focus spells; while maintaining a chant you can move but take no action.</p>", printDesc: "" },
        ],
        extraLanguages: ["Primordial"],
        levelNotes: [],
        specialBonuses: [
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 1, specialLabel: "Tier 1 Shaman chants" },
            { gainedAtLevel: 1, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 2, specialLabel: "" },

            { gainedAtLevel: 2, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 1, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 3, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 4, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 5, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 2, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 6, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 7, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 1, specialLabel: "" },
            { gainedAtLevel: 8, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 4, specialNumber: 2, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 3, specialNumber: 3, specialLabel: "" },
            { gainedAtLevel: 9, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 1, specialLabel: "" }, { gainedAtLevel: 10, specialName: "PickSpell", specialComponentName: "PickSpell", specialSourceType: "Class", specialSourceName: "Shaman", specialSourceCategory: "Ability", specialTier: 5, specialNumber: 2, specialLabel: "" }
        ],
        talents: [
            { min: 2, max: 2, desc: "Gain an additional use of your Healing Circle talent each day", name: "ImpHealingCircle" },
            { min: 3, max: 7, desc: "+2 to wisdom stat or +1 to shamanic chanting checks", name: "PlusTwoWISOrPlus1SpellCasting" },
            { min: 8, max: 9, desc: "Gain advantage on performing one chant you know", name: "AdvOnCastOneSpell" },
            { min: 10, max: 11, desc: "Learn one additional shamanic chant of any tier you know", name: "LearnExtraSpell" },
            { min: 12, max: 12, desc: "Choose a talent or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        freeSpells: [],
        spellCastingStat: "WIS",
        freeToCarryItems: ["Drum"],
        classStatWeights: [
            { stat: "STR", weight: 1 },
            { stat: "DEX", weight: 2 },
            { stat: "CON", weight: 2 },
            { stat: "INT", weight: 1 },
            { stat: "WIS", weight: 6 },
            { stat: "CHA", weight: 2 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 2 },
            { ancestry: "Goblin", chance: 2 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 2 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 2 },
            { ancestry: "Chelonian", chance: 2 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 2 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 2 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 2 },
            { ancestry: "Sylvaren", chance: 2 }
        ],
        spellCastingClassNum: 6,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 11,
        imgName: "Shaman.png",
        imgWidth: 175,
        imgHeight: 175,
    },

    // Roustabout April Fool's class

    {
        name: "Roustabout",
        desc: "Average laggards of no particular talent. They wield weapons and magic with the elegance of a hog-tied kobold. But if their back is against a wall, a glint of bravery may, on rare occasions, rise to the surface.",
        sourceId: "RB",
        sourceNote: "The Roustabout class was created by Michael Putlack & Kelsey and released on April 1st 2024",
        sourceURL: roustaboutPDFDropboxURL,
        weapons: "Club, dagger, hammer, staff",
        armor: "Leather armor",
        hitDie: 4,
        extras: [
            { name: "Knowaguy", desc: "You have advantage on checks related to interacting with commoners and sourcing favors.", printDesc: "You have advantage on checks related to interacting with commoners and sourcing favors." },
            { name: "Lucksmith", desc: "Whenever another player uses your luck token, they have advantage on the new roll.", printDesc: "Whenever another player uses your luck token, they have advantage on the new roll." },
            { name: "Surprising Guts", desc: "When reduced to half your HP or lower, make a DC 12 Wisdom check. If you succeed, you have advantage on your next roll.", printDesc: "When reduced to half your HP or lower, make a DC 12 Wisdom check. If you succeed, you have advantage on your next roll." },
        ],
        extraLanguages: [],
        levelNotes: [],
        specialBonuses: [],
        talents: [
            { min: 2, max: 2, desc: "+1 to any stat and roll another talent", name: "PlusOneStatAndRollAnotherTalent", hasId: true },
            { min: 3, max: 6, desc: "Gain the ability to wield a new weapon or armor", name: "LearnWeaponOrArmor", hasId: true },
            { min: 7, max: 9, desc: "+1 to any two stats (they can’t be the same)", name: "+2 Different Stat Points", hasId: true },
            { min: 10, max: 11, desc: "Roll an extra hit points die this level", name: "PlusOneHitDie", hasId: true },
            { min: 12, max: 12, desc: "Learn any spell of a tier equal to half your level rounded down (min. 1). Cast it using that class's spellcasting stat", name: "LearnExtraSpell", hasId: true }
        ],
        freeSpells: [],
        spellCastingStat: "",
        freeToCarryItems: [],
        classStatWeights: [
            { stat: "STR", weight: 4 },
            { stat: "DEX", weight: 4 },
            { stat: "CON", weight: 4 },
            { stat: "INT", weight: 2 },
            { stat: "WIS", weight: 3 },
            { stat: "CHA", weight: 2 },
        ],
        ancestryChances: [
            { ancestry: "Dwarf", chance: 1 },
            { ancestry: "Elf", chance: 1 },
            { ancestry: "Goblin", chance: 1 },
            { ancestry: "Halfling", chance: 1 },
            { ancestry: "Half-Orc", chance: 1 },
            { ancestry: "Human", chance: 3 },
            { ancestry: "Duck", chance: 1 },
            { ancestry: "Dog", chance: 1 },
            { ancestry: "Kobold", chance: 1 },
            { ancestry: "Chelonian", chance: 1 },
            { ancestry: "Dragonborn", chance: 1 },
            { ancestry: "Forest Elf", chance: 1 },
            { ancestry: "Half Troll", chance: 1 },
            { ancestry: "Shadow Elf", chance: 1 },
            { ancestry: "Tiefling", chance: 1 },
            { ancestry: "Gnome", chance: 1 },
            { ancestry: "Mycellan", chance: 1 },
            { ancestry: "Risen", chance: 1 },
            { ancestry: "Slimefolk", chance: 1 },
            { ancestry: "Skurrid", chance: 1 },
            { ancestry: "Sylvaren", chance: 1 }
        ],
        spellCastingClassNum: 7,
        minRandomTalentRoll: 2,
        maxRandomTalentRoll: 12,
        imgName: "Roustabout.png",
        imgWidth: 180,
        imgHeight: 180,
    },
]

export const getAllCharClasses = () => {
    return [...coreCharClasses];
}

export const BlackLotusTalents: Talent[] = [
    { min: 1, max: 1, desc: "Gain two Black Lotus talents (reroll further 1s this instance)", name: "GainTwoBlackLotusTalents" },
    { min: 2, max: 2, desc: "1/day, paralyze a target of LV 9 or less for 1d4 rounds when you damage it with a weapon", name: "ParalyseOnWeaponHit" },
    { min: 3, max: 3, desc: "You have advantage on Dexterity checks to avoid entrapment or injury", name: "ADVonDEXToAvoidEntrapment", rerollDuplicates: true },
    { min: 4, max: 4, desc: "You gain +1 to your AC when wielding a melee weapon in each hand", name: "Plus1ACWhenDualWield" },
    { min: 5, max: 5, desc: "You gain an additional hit points die", name: "PlusOneHitDie" },
    { min: 6, max: 6, desc: "You deal triple damage with your Assassinate talent", name: "TripleDamageAssassinate", rerollDuplicates: true },
    { min: 7, max: 7, desc: "When enemies who can see you make a morale check, the DC is 18 instead of 15", name: "Morale18", rerollDuplicates: true },
    { min: 8, max: 8, desc: "1/day, you can walk on water as if it were solid for 1d4 rounds", name: "WalkOnWater" },
    { min: 9, max: 9, desc: "1/day, choose a living creature of LV 5 or less you can see within near; it must pass a DC 15 CON check or fall asleep", name: "MakeAsleep" },
    { min: 10, max: 10, desc: "1/day, you can walk on sheer surfaces such as walls for 1d4 rounds", name: "WalkOnWalls" },
    { min: 11, max: 11, desc: "You deal +1 damage with melee weapons", name: "Plus1ToMeleeDamage" },
    { min: 12, max: 12, desc: "1/day, choose a creature of LV 9 or less you can see; it must pass a DC 15 WIS check or it can't see or hear you for 1d4 rounds", name: "Invisible" },
]

export const PyromancyTalents: Talent[] = [
    { min: 1, max: 1, desc: "<b>Improved Firebond.</b> Increase die category of Firebond Weapon's extra damage.", name: "Pyro_FirebondWeaponDieIncrease" },
    { min: 2, max: 2, desc: "<b>Flaming Impact.</b> A Firebond Weapon strike ignites target, doing 1 damage per round until extinguished. +1 on duplicate.", name: "Pyro_FlamingImpact" },
    { min: 3, max: 3, desc: "<b>Boiling Blood Spray.</b> When a Firebond Weapon strikes a creature, scorched blood does 1 damage to creatures in close range. Add +1 damage on duplicate.", name: "Pyro_BoilingBloodSpray" },
    { min: 4, max: 4, desc: "<b>Wall of Fire.</b> 1/day as an action, create a near-length wall of fire in a straight line from any point a near distance from you for 5 rounds. The wall deals 1d4 damage on touch. Increase damage die category on duplicate.", name: "Pyro_WallOfFire" },
    { min: 5, max: 5, desc: "<b>Magma Armor.</b> As an action, activate for 5 rounds, adding +1 to AC. Add +1 to AC on duplicate.", name: "Pyro_MagmaArmor" },
    { min: 6, max: 6, desc: "<b>Blast Furnace.</b> As an action, release your body's heat. Any creatures in close range take 1d4 of damage. Increase damage die category on duplicate", name: "Pyro_BlastFurnace" },
    { min: 7, max: 7, desc: "<b>Hot Flash.</b> As an action, create a white hot flash. Creatures in a near radius around you are blinded and stunned for 1 round if they fail a DC 12 CON check. Add a round of blindness on duplicate.", name: "Pyro_HotFlash" },
    { min: 8, max: 8, desc: "<b>Fireform.</b> 1/day as an action, transform yourself into flames for 3 rounds. Ranged attacks do half damage to you. Creatures using natural weapons take 1d4 damage if they hit. Emits light to close. Increase damage die category on duplicate.", name: "Pyro_Fireform" },
    { min: 9, max: 9, desc: "<b>Hot Hands.</b> When you hit an opponent with a Firebond weapon, they make DC 12 CON check or they drop their weapon. It takes one round to cool. Increase DC die category on duplicate.", name: "Pyro_HotHands" },
    { min: 10, max: 10, desc: "<b>Flaming Frenzy.</b> As an action, activate to gain 3 attacks on your next turn. Gain one more attack on duplicate.", name: "Pyro_FlamingFrenzy" },
    { min: 11, max: 11, desc: "<b>Flarephobia.</b> Creatures hit by a Firebond weapon must make a morale check at DC 9. Increase die category on duplicate.", name: "Pyro_Flarephobia" },
    { min: 12, max: 12, desc: "<b>Super Nova.</b> Detonate yourself in a superheated explosion, dealing 5d12 damage to all creatures within near. All objects and all of your possessions are destroyed. You go to 0 HP and permanently lose 1 CON.", name: "Pyro_Supernova", rerollDuplicates: true },
]
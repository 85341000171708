import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { duckAbilities } from "../../data/ducks";
import { Level } from "../../data/level";

interface IProps {
    setBonus: (bonus: Bonus) => void;
    level: Level;
}

const DuckAbility: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const options = duckAbilities.map((a, index) => <option value={a.name} key={"DuckAbility_" + a.name}>{a.name}</option>);
    options.unshift(<option value="" key={"selectDuckAbility"}>-- select Duck ability --</option>);

    let currentValue = "";
    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Duck" && b.name === "Duck ability");
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setDuckAbilityBonus = (e: any) => {

        let desc = "";
        const theAbility = duckAbilities.find((s) => s.name === e.target.value);
        if (theAbility) {
            desc = theAbility.desc;
        }

        const bonus: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Duck",
            sourceCategory: "DuckAbility",
            gainedAtLevel: props.level.level,
            name: "Duck ability",
            bonusName: e.target.value,
            bonusTo: desc
        };
        props.setBonus(bonus);
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    const getDesc = () => {
        if (currentValue !== "") {
            const theAbility = duckAbilities.find((s) => s.name === currentValue);
            if (theAbility) {
                return <div className="spell">{theAbility.desc}</div>
            }
        }
        return null;
    }

    return (
        <div>
            <div className="formNote">Duck ability</div>
            <select className={selectCss} onChange={(e) => setDuckAbilityBonus(e)} value={currentValue}>{options}</select>
            {currentValue === "" &&
                <div className="valError">Please select a duck ability</div>
            }
            {getDesc()}
        </div>
    )

}

export default DuckAbility;
export interface Background {
    index: number
    name: string;
    desc: string;
    sourceId: string;
}

const backgrounds: Background[] = [
    { index: 1, name: "Urchin", desc: "You grew up in the merciless streets of a large city", sourceId: "SD" },
    { index: 2, name: "Wanted", desc: "There's a price on your head, but you have allies", sourceId: "SD" },
    { index: 3, name: "Cult Initiate", desc: "You know blasphemous secrets and rituals", sourceId: "SD" },
    { index: 4, name: "Thieves' Guild", desc: "You have connections, contacts, and debts", sourceId: "SD" },
    { index: 5, name: "Banished", desc: "Your people cast you out for supposed crimes", sourceId: "SD" },
    { index: 6, name: "Orphaned", desc: "An unusual guardian rescued and raised you", sourceId: "SD" },
    { index: 7, name: "Wizard's Apprentice", desc: "You have a knack and eye for magic", sourceId: "SD" },
    { index: 8, name: "Jeweler", desc: "You can easily appraise value and authenticity", sourceId: "SD" },
    { index: 9, name: "Herbalist", desc: "You know plants, medicines, and poisons", sourceId: "SD" },
    { index: 10, name: "Barbarian", desc: "You left the horde, but it never quite left you", sourceId: "SD" },
    { index: 11, name: "Mercenary", desc: "You fought friend and foe alike for your coin", sourceId: "SD" },
    { index: 12, name: "Sailor", desc: "Pirate, privateer, or merchant — the seas are yours", sourceId: "SD" },
    { index: 13, name: "Acolyte", desc: "You're well trained in religious rites and doctrines", sourceId: "SD" },
    { index: 14, name: "Soldier", desc: "You served as a fighter in an organized army", sourceId: "SD" },
    { index: 15, name: "Ranger", desc: "The woods and wilds are your true home", sourceId: "SD" },
    { index: 16, name: "Scout", desc: "You survived on stealth, observation, and speed", sourceId: "SD" },
    { index: 17, name: "Minstrel", desc: "You've traveled far with your charm and talent", sourceId: "SD" },
    { index: 18, name: "Scholar", desc: "You know much about ancient history and lore", sourceId: "SD" },
    { index: 19, name: "Noble", desc: "A famous name has opened many doors for you", sourceId: "SD" },
    { index: 20, name: "Chirurgeon", desc: "You know anatomy, surgery, and first aid", sourceId: "SD" },

    { index: 21, name: "Hermit", "desc": "The wilds (and its creatures) are your family", sourceId: "CS1" },
    { index: 22, name: "Outcast", "desc": "You were thrown out for real or supposed crimes", sourceId: "CS1" },
    { index: 23, name: "Woodborn", "desc": "They found you in the hollow of an oak tree", sourceId: "CS1" },
    { index: 24, name: "Amnesiac", "desc": "Your past is a haze, but some memories return", sourceId: "CS1" },
    { index: 25, name: "Haunted", "desc": "A restless spirit wants something from you", sourceId: "CS1" },
    { index: 26, name: "Fugitive", "desc": "An anonymous savior helped you disappear", sourceId: "CS1" },
    { index: 27, name: "Feytouched", "desc": "A fairy befriended you in your childhood", sourceId: "CS1" },
    { index: 28, name: "Witchborn", "desc": "They burned your mother, but spared you", sourceId: "CS1" },
    { index: 29, name: "Forager", "desc": "You know how to find the edible and the deadly", sourceId: "CS1" },
    { index: 30, name: "Redeemer", "desc": "You must redeem the name of your kin", sourceId: "CS1" },
    { index: 31, name: "Marked", "desc": "You carry an eldritch mark. Is it a curse, or a gift?", sourceId: "CS1" },
    { index: 32, name: "Sacrifice", "desc": "You were to be ritually sacrificed, but escaped", sourceId: "CS1" },
    { index: 33, name: "Marooned", "desc": "They left you behind, but you refused to die", sourceId: "CS1" },
    { index: 34, name: "Fallen", "desc": "You fell from grace. Will you atone, or embrace it?", sourceId: "CS1" },
    { index: 35, name: "Drawn", "desc": "You hear a whispered call and follow it", sourceId: "CS1" },
    { index: 36, name: "Ascetic", "desc": "People fear you, but seek out your guidance", sourceId: "CS1" },
    { index: 37, name: "Wolfchild", "desc": "Long ago, you walked into town wearing pelts", sourceId: "CS1" },
    { index: 38, name: "Healer", "desc": "You understand how life and death intertwine", sourceId: "CS1" },
    { index: 39, name: "Chosen", "desc": "An eldritch being selected you for a purpose", sourceId: "CS1" },
    { index: 40, name: "Demonborn", "desc": "An ancestor of yours is a powerful demon", sourceId: "CS1" },

    { index: 41, name: "Freed", "desc": "You were a thrall, but escaped or won your freedom", sourceId: "CS3" },
    { index: 42, name: "Displaced", "desc": "You fled after a rival jarl attacked your village", sourceId: "CS3" },
    { index: 43, name: "Criminal", "desc": "You were exiled from your village for a crime", sourceId: "CS3" },
    { index: 44, name: "Drifter", "desc": "You have not yet found a jarl worthy of your loyalty", sourceId: "CS3" },
    { index: 45, name: "Crop Farmer", "desc": "You toil in the earth and know all plants", sourceId: "CS3" },
    { index: 46, name: "Livestock Farmer", "desc": "You have intuition about all animals", sourceId: "CS3" },
    { index: 47, name: "Hunter", "desc": "You know how to move quietly in the wilds", sourceId: "CS3" },
    { index: 48, name: "Fisher", "desc": "You know all the sea creatures and legends", sourceId: "CS3" },
    { index: 49, name: "Enforcer", "desc": "You enforce the jarl's law in your village", sourceId: "CS3" },
    { index: 50, name: "Trader", "desc": "You have mercantile connections in every village", sourceId: "CS3" },
    { index: 51, name: "Crafter", "desc": "You can make and fix any utilitarian item", sourceId: "CS3" },
    { index: 52, name: "Bowyer", "desc": "You can make and fix any bow or arrow", sourceId: "CS3" },
    { index: 53, name: "Seer's Apprentice", "desc": "You know some of the mystic arts", sourceId: "CS3" },
    { index: 54, name: "Shipwright", "desc": "You know how to build and repair longboats", sourceId: "CS3" },
    { index: 55, name: "Blacksmith", "desc": "Weapons, armor, horseshoes; you do it all", sourceId: "CS3" },
    { index: 56, name: "Far Traveler", "desc": "You know many distant people and customs", sourceId: "CS3" },
    { index: 57, name: "Skald", "desc": "You are a poet and know all the ancient ballads", sourceId: "CS3" },
    { index: 58, name: "Heroborn", "desc": "You are the descendant of a famous warrior", sourceId: "CS3" },
    { index: 59, name: "Nobleborn", "desc": "You are the child of a 1d6: 1-5. jarl, 6. king", sourceId: "CS3" },
    { index: 60, name: "God's Blood", "desc": "You are descended from a god; it marks you", sourceId: "CS3" },

]

export const getAllBackgrounds = () => {
    return [...backgrounds];
}
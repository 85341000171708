import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { spells } from "../../data/spells";
import { getAllSpellsKnown, getBonusByNameAndBonusTo } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const PickSpellToGainAdvantageCasting: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const allSpells = spells; 

    let spellsKnown = getAllSpellsKnown(globalContext.availableClasses, globalContext.className, allSpells, globalContext.bonuses);
    spellsKnown = spellsKnown.filter((s) => !s.castWithAdv);

    const options = spellsKnown.map((s) => <option value={s.name} key={s.name}>{s.name}</option>);
    options.unshift(<option value="" key="selectSpell">-- select spell cast with advantage --</option>);

    let currentValue = "";
    // const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.gainedAtLevel === props.level.level && b.name === "AdvOnCastOneSpell" && b.bonusTo === "AdvOnCastOneSpell");
    const existingBonus = getBonusByNameAndBonusTo(globalContext.bonuses,  "AdvOnCastOneSpell", "AdvOnCastOneSpell", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setSpellBonus = (e: any) => {

        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "AdvOnCastOneSpell",
            bonusTo: "AdvOnCastOneSpell",
            bonusName: e.target.value,
        };
        props.setBonus(bonus);
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    const getNote = () => {

        let note: string = "";

        // check if any of the character's selected spells are always cast with adv, and have been excluded from the options
        let spellsAlreadyKnownAlreadyCastWithAdv = getAllSpellsKnown(globalContext.availableClasses, globalContext.className, allSpells, globalContext.bonuses);
        spellsAlreadyKnownAlreadyCastWithAdv = spellsAlreadyKnownAlreadyCastWithAdv.filter((s) => s.castWithAdv);
        if (spellsAlreadyKnownAlreadyCastWithAdv.length > 0) {
            note = spellsAlreadyKnownAlreadyCastWithAdv.map((s) => s.name).join(", ") + " always cast with advantage";
        }

        if (note !== "") {
            return <div className="small">{note}</div>;
        }
        return null;
    }

    const getDuplicate = () => {

        let note = "";

        // check if the user has already selected this spell to be cast with adv
        const bons = globalContext.bonuses.filter((b) => b.bonusTo === "AdvOnCastOneSpell" && b.bonusName === currentValue);
        if (bons.length > 1) {
            note = "Duplicate";
        }

        if (note.length > 0) {
            return <span className="valError ms-3">{note}</span>;
        }
        return null;
    }

    return (
        <div className="indented">
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setSpellBonus(e)} value={currentValue}>{options}</select>{getDuplicate()}
            </div>
            {getNote()}
        </div>
    )

}

export default PickSpellToGainAdvantageCasting;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByNameAndBonusName, getBonusByNameAndBonusTo } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const PlusTwoSTRCONOrPlusOneMeleeDamage: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setPlusTwoSTRBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrCONOrPlus1MeleeDamage",
            bonusName: "StatBonus",
            bonusTo: "STR:+2",
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        };
        props.setBonus(bonus);
    }

    const setPlusTwoCONBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrCONOrPlus1MeleeDamage",
            bonusName: "StatBonus",
            bonusTo: "CON:+2",
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        };
        props.setBonus(bonus);
    }

    const setPlusOneToMeleeDamageBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrCONOrPlus1MeleeDamage",
            bonusName: "Plus1ToMeleeDamage",
            bonusTo: "",
            bonusAmount: 1,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        };
        props.setBonus(bonus);
    }

    const plusTwoSTRSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "Plus2STROrCONOrPlus1MeleeDamage", "STR:+2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plusTwoCONSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "Plus2STROrCONOrPlus1MeleeDamage", "CON:+2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plusOneMeleeDamageSelected = getBonusByNameAndBonusName(globalContext.bonuses, "Plus2STROrCONOrPlus1MeleeDamage", "Plus1ToMeleeDamage", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
 
    const req = !plusTwoSTRSelected && !plusTwoCONSelected && !plusOneMeleeDamageSelected;

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setPlusTwoSTRBonus()} checked={plusTwoSTRSelected}></input>&nbsp;STR {globalContext.finalStats.Strength}</label>
            <label><input type="radio" onChange={() => setPlusTwoCONBonus()} checked={plusTwoCONSelected}></input>&nbsp;CON {globalContext.finalStats.Constitution}</label>
            <label><input type="radio" onChange={() => setPlusOneToMeleeDamageBonus()} checked={plusOneMeleeDamageSelected}></input>&nbsp;+1 to melee damage</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default PlusTwoSTRCONOrPlusOneMeleeDamage;

import { arcaneLibraryShadowDarkURL, darcyPerryDogURL, darcyPerryDuckURL, koboldAncestryDiscordURL, unnaturalSelectionURL } from "../special/links";
import { ClassExtra, SpecialBonus } from "./classes";
import { Language, getAllLanguages } from "./languages";

export interface Ancestry {
    name: string;
    desc: string;
    sourceId: string;
    sourceNote: string;
    sourceURL?: string;
    languages: string[];
    choicesOfExtraLanguage: Language[];
    extras: ClassExtra[];
    specialBonuses: SpecialBonus[];
    isCore: boolean;
    imageURL: string;
    imageSize: [number, number];
    isUnnaturalSelection?: boolean; 
    zeroLevelAncestryNote: string;
}

const coreAncestries: Ancestry[] = [
    {
        name: "Dwarf",
        desc: "Brave, stalwart folk as sturdy as the stone kingdoms they carve inside mountains.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        languages: ["Common", "Dwarvish"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Stout", desc: "Start with +2 HP. Roll your hit point gains with advantage.", printDesc: "+2 HP, roll hit point gains with advantage" },
        ],
        specialBonuses: [],
        isCore: true,
        imageURL: "/Dwarf.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Stout: +2 HP (included above)"
    },
    {
        name: "Elf",
        desc: "Ethereal, graceful people who revere knowledge and beauty. Elves see far and live long.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        languages: ["Common", "Elvish", "Sylvan"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Farsight", desc: "You get a +1 bonus to attack rolls with ranged weapons or a +1 bonus to spellcasting checks.", printDesc: "" },
        ],
        specialBonuses: [
            { gainedAtLevel: 0, specialName: "Farsight", specialComponentName: "Plus1ToRangedOrSpellcasting", specialSourceType: "Ancestry", specialSourceCategory: "Ability", specialSourceName: "Elf", specialLabel: "" }
        ],
        isCore: true,
        imageURL: "/Elf.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Farsight: +1 to attack rolls with ranged weapons (included above)"
    },
    {
        name: "Goblin",
        desc: "Green, clever beings who thrive in dark, cramped places. As fierce as they are tiny.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        languages: ["Common", "Goblin"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Keen senses", desc: "You can't be surprised.", printDesc: "Can't be surprised." },
        ],
        specialBonuses: [],
        isCore: true,
        imageURL: "/Goblin.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Keen Senses: can't be surprised"
    },
    {
        name: "Halfling",
        desc: "Small, cheerful country folk with mischievous streaks. They enjoy life’s simple pleasures.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        languages: ["Common"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Stealthy", desc: "Once per day, you can become invisible for 3 rounds.", printDesc: "Once per day become invisible for 3 rounds." },
        ],
        specialBonuses: [],
        isCore: true,
        imageURL: "/Halfling.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Stealthy: once per day you can become invisible for 3 rounds"
    },
    {
        name: "Half-Orc",
        desc: "Towering, tusked warriors who are as daring as humans and as relentless as orcs.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        languages: ["Common", "Orcish"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Mighty", desc: "You have a +1 bonus to attack and damage rolls with melee weapons.", printDesc: "+1 to attack and damage with melee weapons." },
        ],
        specialBonuses: [],
        isCore: true,
        imageURL: "/HalfOrc.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Mighty: +1 to attack and damage rolls with melee weapons (included above)"
    },
    {
        name: "Human",
        desc: "Bold, adaptable, and diverse people who learn quickly and accomplish mighty deeds.",
        sourceId: "SD",
        sourceNote: "From the ShadowDark Core Book",
        sourceURL: arcaneLibraryShadowDarkURL,
        languages: ["Common", "one additional common"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Ambitious", desc: "You gain one additional talent roll at 1st level.", printDesc: "Extra Talent at level 1" },
        ],
        specialBonuses: [
            { gainedAtLevel: 0, specialName: "Language", specialComponentName: "ExtraLanguage", specialSourceType: "Ancestry", specialSourceName: "Human", specialSourceCategory: "Ability", specialLabel: "Language" },
        ],
        isCore: true,
        imageURL: "/Human.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Chatty: know an additional common language (included above)"
    },
    {
        name: "Kobold",
        desc: "Tiny, humanoid dog-lizards with a penchant for tinkering, trap building, and a hint of magic.",
        sourceId: "SG",
        sourceNote: "The Kobold ancestry was designed by Kelsey on the ShadowDark Discord, and has been made freely available as an addition to the Core rules.",
        sourceURL: koboldAncestryDiscordURL,
        languages: ["Common", "Draconic"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Knack", desc: "You get +1 to spellcasting checks or may begin each session with a luck token.", printDesc: "+1 spellcasting or begins session with a luck token" },
        ],
        specialBonuses: [
            { gainedAtLevel: 0, specialName: "Knack", specialComponentName: "PlusOneSpellCastingOrLuckToken", specialSourceType: "Ancestry", specialSourceName: "Kobold", specialSourceCategory: "Ability", specialLabel: "Knack" },
        ],
        isCore: true,
        imageURL: "/Kobold.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Knack: begin each session with a luck token"
    },
    {
        name: "Duck",
        desc: "Daring, valiant, adventure-seekers. Born to be wild, Ducks are calm in the face of Chaos.",
        sourceId: "DP",
        sourceNote: "From the ShadowDuck 3rd party supplement by Darcy Perry",
        sourceURL: darcyPerryDuckURL,
        languages: ["Common", "Avian"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Unflappable!", desc: "Immune to fear.", printDesc: "Immune to fear" },
            { name: "Duck Ability", desc: "Select a Duck ability below.", printDesc: "" },
            { name: "Quackstory", desc: "Roll a Quackstory ability below.", printDesc: "" },
        ],
        specialBonuses: [
            { gainedAtLevel: 0, specialName: "Duck ability", specialComponentName: "DuckAbility", specialSourceType: "Ancestry", specialSourceName: "DuckAbility", specialSourceCategory: "Ability", specialLabel: "" },
            { gainedAtLevel: 0, specialName: "Quackstory", specialComponentName: "Quackstory", specialSourceType: "Ancestry", specialSourceName: "DuckQuackstory", specialSourceCategory: "Ability", specialLabel: "" }
        ],
        isCore: false,
        imageURL: "/Duck.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Unflappable: Immune to fear"
    },
    {
        name: "Dog",
        desc: "Dogs are courageous, loyal, and fun comrades. They can be fierce protectors and faithful allies. ",
        sourceId: "DP",
        sourceNote: "From the ShadowBark 3rd party supplement by Darcy Perry",
        sourceURL: darcyPerryDogURL,
        languages: ["Common", "Canine"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Nose for Trouble", desc: "All Dogs have a heightened sense of smell.", printDesc: "Heightened sense of smell." },
            { name: "Dog Ability", desc: "Select a Dog ability below.", printDesc: "" },
            { name: "Dog's Life", desc: "Roll a Dog's Life ability below.", printDesc: "" },
        ],
        specialBonuses: [
            { gainedAtLevel: 0, specialName: "Dog ability", specialComponentName: "DogAbility", specialSourceType: "Ancestry", specialSourceName: "DogAbility", specialSourceCategory: "Ability", specialLabel: "" },
            { gainedAtLevel: 0, specialName: "Dog's Life", specialComponentName: "DogsLife", specialSourceType: "Ancestry", specialSourceName: "DogsLife", specialSourceCategory: "Ability", specialLabel: "" }
        ],
        isCore: false,
        imageURL: "/Dog.png",
        imageSize: [175, 175],
        zeroLevelAncestryNote: "Nose for Trouble: Heightened sense of smell"
    },
    {
        name: "Chelonian",
        desc: "Contemplative, wise turtle beings who act with caution and carry their homes on their backs.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL, 
        languages: ["Common", "Chelonian"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Armored Shell", desc: "You gain +1 to AC.", printDesc: "+1 to AC." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "/Chelonian.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Armored: +1 to AC"
    },
    {
        name: "Dragonborn",
        desc: "Proud descendants of dragons whose honor holds greater value than their own lives.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL, 
        languages: ["Common", "Draconic"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Breath Weapon", desc: "1/day, unleash a breath weapon based on your draconic heritage via ranged attack, dealing 1d8 damage to a near-sized cube in near range.", printDesc: "1/day, ranged attack 1d8 damage to a near-sized cube in near range" },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "/Dragonborn.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Breath Weapon: 1/day, ranged attack for 1d8 dmg to near-sized cube in near range"
    },
    {
        name: "Forest Elf",
        desc: "Slender, patient elves who are one with nature and uneasy amidst urban bustle.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL, 
        languages: ["Common", "Elvish", "Sylvan"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Forest Footwork", desc: "In a forest, you can move up to near again without skipping an action on your turn.", printDesc: "In a forest, you can move up to near again without skipping an action on your turn." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "/ForestElf.png",
        imageSize: [185, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "In a forest, move up to near again without skipping an action on your turn"
    },
    {
        name: "Half Troll",
        desc: "Ugly, burly beasts who easily anger and struggle to find their place within humanoid society.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL, 
        languages: ["Common", "Giant"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Regenerate", desc: "Regain 1HP per hour unless damaged by fire or acid.", printDesc: "Regain 1HP per hour unless damaged by fire or acid." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "/HalfTroll.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Regenerate: Regain 1HP per hour unless damaged by fire or acid."
    },
    {
        name: "Shadow Elf",
        desc: "Lithe, stealthy offspring of drow who have embraced the surface as their home.",
        sourceId: "US",
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        sourceURL: unnaturalSelectionURL, 
        languages: ["Common", "Elvish", "Drow"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Friend of the shadows", desc: "When shrouded in darkness, monsters attack your allies first.", printDesc: "When in darkness, monsters attack your allies first." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "/ShadowElf.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Friend of the Shadows: In darkness, monsters attack your allies first"
    },
    {
        name: "Tiefling",
        desc: "Devout, self-reliant outcasts who have diabolic ancestry and sport demonic horns.",
        sourceId: "US",
        sourceURL: unnaturalSelectionURL, 
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        languages: ["Common", "Diabolic"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Heatsight", desc: "1/day you can see the heat signatures of living creatures for 3 rounds.", printDesc: "1/day you can see the heat signatures of living creatures for 3 rounds." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "/Tiefling.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Heatsight: 1/day you can see the heat of living creatures for 3 rounds"
    },

    {
        name: "Gnome",
        desc: "Diminutive, elusive tricksters who delight in playful pranks. As clever as they are tiny.",
        sourceId: "US",
        sourceURL: unnaturalSelectionURL, 
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        languages: ["Common", "Sylvan"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Trickster", desc: "1/day, target one creature to become the object of your nettlesome pranks, you can force a re-roll on one attack or check.", printDesc: "1/day, target one creature, you can force a re-roll on one attack or check." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "Gnome.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Trickster: 1/day force one target to re-roll one attack or check"
    },
    {
        name: "Mycellan",
        desc: "Peaceful mushroom folk who connect with their natural environment through the mycelial network and telepathy.",
        sourceId: "US",
        sourceURL: unnaturalSelectionURL, 
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        languages: ["Common", "Sylvan"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Telepathic", desc: "Once per day, release rapport spores to gain telepathic communication with all in a near radius. Lasts for 10 rounds.", printDesc: "Once per day, release rapport spores to gain telepathic communication with all in a near radius. Lasts for 10 rounds." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "Mycellan.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Telepathic: 1/day use spores to communicate with all in near radius for 10 rounds"
    },
    {
        name: "Risen",
        desc: "Resurrected heroes, chosen to fulfill their purpose even in undeath.",
        sourceId: "US",
        sourceURL: unnaturalSelectionURL, 
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        languages: ["Common", "either the Celestial or Diabolic"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Corpse Walking", desc: "At 0 hp you remain active but are still dying. Someone else must stabilize you. You die early if you receive your full hp of damage again.", printDesc: "At 0 hp you remain active but are still dying. Someone else must stabilize you. You die early if you receive your full hp of damage again." },
        ],
        specialBonuses: [
            { gainedAtLevel: 0, specialName: "Language", specialComponentName: "ExtraLanguage", specialSourceType: "Ancestry", specialSourceName: "Risen", specialSourceCategory: "Ability", specialLabel: "Language" },
        ],
        isCore: false,
        imageURL: "Risen.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "At 0 hp active but still dying. Someone else must stabilize you. Die if hit 0 hp again."
    },
    {
        name: "Slimefolk",
        desc: "Oddly-shaped humanoids made of congealed slime who have chosen to coexist with solids.",
        sourceId: "US",
        sourceURL: unnaturalSelectionURL, 
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        languages: ["Common", "Primordial"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Slime Form", desc: "You can freely alter your shape to mimic other humanoids. 1/day you can also morph into a puddle of goo for 3 rounds. Puddle speed is halved and equipment does not morph.", printDesc: "You can freely alter your shape to mimic other humanoids. 1/day you can also morph into a puddle of goo for 3 rounds. Puddle speed is halved and equipment does not morph." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "Slimefolk.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Slime Form: Mimic humanoids. 1/day goo form for 3 rnds (speed 1/2, gear not morph)"
    },
    {
        name: "Skurrid",
        desc: "Furry ratfolk, as tall as elves, famed for their role in citywide subterranean vermin networks.",
        sourceId: "US",
        sourceURL: unnaturalSelectionURL, 
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        languages: ["Common", "Skurrid"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Vibrating Whiskers", desc: "If you focus, you sense living beings within near of you, even through doors.", printDesc: "If you focus, you sense living beings within near of you, even through doors." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "Skurrid.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Whiskers: Focus to sense living beings within near of you, even through doors."
    },
    {
        name: "Sylvaren",
        desc: "Botanical beings with leaves, vines and/or berries growing from their humanoid forms.",
        sourceId: "US",
        sourceURL: unnaturalSelectionURL, 
        sourceNote: "From the Unnatural Selection 3rd party supplement by Dungeon Damsel",
        languages: ["Common", "Sylvan"],
        choicesOfExtraLanguage: [],
        extras: [
            { name: "Plant Medicine", desc: "Take 2 damage to sprout a healing berry on your body. Eating it will heal 1 HP.", printDesc: "Take 2 damage to sprout a healing berry on your body. Eating it will heal 1 HP." },
        ],
        specialBonuses: [],
        isCore: false,
        imageURL: "Sylvaren.png",
        imageSize: [175, 175],
        isUnnaturalSelection: true,
        zeroLevelAncestryNote: "Take 2 damage to sprout a healing berry on your body. Eating it will heal 1 HP."
    }

]

export const getAllAncestries = () => {

    let allAncestries = [...coreAncestries];

    const human = allAncestries.find((a) => a.name === "Human");
    if (human) {
        const extraLanguageChoicesForHuman = getAllLanguages().filter((l) => l.category === "Common" && l.name !== "Common");
        human.choicesOfExtraLanguage = extraLanguageChoicesForHuman;
    }

    return allAncestries;
}
import { User } from "firebase/auth";
import { Navigate } from "react-router-dom";

interface IProps {
    user: User | null | undefined,
    isAdmin: boolean;
    children: any,
}

const AdminOnlyRoute: React.FunctionComponent<IProps> = (props: IProps) => {
    if (props.user == null || !props.isAdmin) {
        return <Navigate to="/" replace />;
    }
    return props.children;
};

export default AdminOnlyRoute;
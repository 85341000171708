import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Bonus } from "../../data/bonus";
import { Language, getAllLanguages } from "../../data/languages";
import { Level } from "../../data/level";
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { getRandomIntInclusive } from "../../data/random";

interface IProps {
    languages: Language[];
    languageNumber?: number;
    languagesKnown: Language[];
    sourceType: string;
    sourceName: string;
    level: Level;
    label: string;
    minimal: boolean;
    setBonus: (bonus: Bonus) => void;
}

const ExtraLanguage: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    let availableLanguages = props.languages.filter((a) => globalContext.activeSources.indexOf(a.sourceId) !== -1);
    
    // A quick hack to add the choices of language for the Risen ancestry
    if (props.sourceType === "Ancestry" && props.sourceName === "Risen") {
        availableLanguages = getAllLanguages().filter((a) => a.name === "Celestial" || a.name === "Diabolic");
    }


    const getOptions = () => {
        const options = availableLanguages.sort((l1: Language, l2: Language) => l1.name < l2.name ? -1 : 1).map((a) => <option value={a.name} key={props.sourceType + "_" + props.sourceName + "_" + a.name}>{a.name}</option>);
        options.unshift(<option value="" key="selectLang">-- select extra language --</option>);
        return options;
    }

    let currentValue = "";

    let name = "ExtraLanguage: " + props.sourceName + " " + props.languageNumber;

    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.name === name);
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setLanguageBonus = (language: string) => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: "Ability",
            gainedAtLevel: props.level.level,
            name: name,
            bonusTo: "Languages",
            bonusName: language,
        };
        props.setBonus(bonus);
    }

    const rollLanguage = () => {
        const randLang = availableLanguages[getRandomIntInclusive(0, availableLanguages.length - 1)].name;
        if (props.languagesKnown.map((l) => l.name).indexOf(randLang) !== -1) {
            rollLanguage();
        } else {
            setLanguageBonus(randLang);
        }
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    const getLangDesc = () => {
        if (props.minimal) { return null; }
        if (currentValue !== "") {
            const theLang = globalContext.availableLanguages.find((s) => s.name === currentValue);
            if (theLang) {
                return <div className="spell">Spoken by {theLang.spokenBy}</div>
            }
        }
        return null;
    }

    const getIsDuplicate = () => {
        let thisLang = globalContext.availableLanguages.filter((l) => l.name === currentValue);
        if (thisLang.length > 1) {
            return <span className="valError ms-3">Duplicate</span>
        }
        return null;
    }

    return (
        <div>
            <div className="formNote">{props.label}</div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setLanguageBonus(e.target.value)} value={currentValue}>{getOptions()}</select>
                <button className="btn btn-dark" onClick={(e) => rollLanguage()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
                {getIsDuplicate()}
            </div>
            {getLangDesc()}
        </div>
    )

}

export default ExtraLanguage;
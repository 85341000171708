import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Background } from "../../data/backgrounds";

interface IProps {
    backgrounds: Background[];
    selectedBackground: string;
    setBackground: (background: string) => void;
}


const SelectBackground: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getOptions = () => {
        const availableBackgrounds = props.backgrounds.filter((c) => globalContext.activeSources.indexOf(c.sourceId) !== -1);

        const formatSource = (bg: Background) => {
            if (bg.sourceId !== "SD") {
                return " (" + bg.sourceId + ")";
            }
        }

        const options = availableBackgrounds.map((bg) => <option value={bg.name} key={bg.name}>{bg.name}{formatSource(bg)}</option>);
        options.unshift(<option value="" key={"bg_none"}>-- select background --</option>);
        return options;
    }

    const onSelectBackground = (e: any) => {
        props.setBackground(e.target.value);
    }

    const getBgDesc = () => {
        const bgDesc = props.backgrounds.find((bg) => bg.name === props.selectedBackground);
        if (bgDesc) {
            return <div><b>{props.selectedBackground}</b>: {bgDesc.desc}.</div>
        }
        return null;
    }

    const selectCss = props.selectedBackground === "" ? "form-select redBorder" : "form-select";

    const bgName = props.selectedBackground !== "" ? ": " + props.selectedBackground : "";

    return (
        <div className="sectionBorder">
            <h2>Background{bgName}</h2>

            <div className="">
                <select className={selectCss} onChange={(e) => onSelectBackground(e)} value={props.selectedBackground}>{getOptions()}</select>
                {props.selectedBackground === "" &&
                    <div className="valError">Please select a background</div>
                }
                <div>{getBgDesc()}</div>
            </div>

        </div>
    )

}

export default SelectBackground;
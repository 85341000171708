import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Armor } from "../../data/armor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MagicItem } from "../../data/magicItem";
import { Weapon } from "../../data/weapons";
import { faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    magicItemsCarried: MagicItem[];
    onEditMagicItem: (id: string) => void;
    onDiscardMagicItem: (id: string) => void;
}

const ListMagicItems: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getMagicItems = () => {

        const magicItems = props.magicItemsCarried;

        if (magicItems.length === 0) {
            return (
                <div className="row buyRow" key={"noTreasure"}>
                    <div className="col-12">No magic items</div>
                </div>
            );
        }

        const getDesc = (theWeapon: Weapon | undefined, theArmor: Armor | undefined, mi: MagicItem) => {
            const noExtras = mi.features.trim() === "" && mi.benefits.trim() === "" && mi.curses.trim() === "" && !mi.personalityVirtue && !mi.personalityFlaws && !mi.personalityTraits;

            let nameAndBonus = "";
            if (theWeapon) { nameAndBonus = theWeapon.name + " +" + mi.bonus; }
            if (theArmor) { nameAndBonus = theArmor.name + " +" + mi.bonus; }

            let bonusNote = "";
            if (mi.bonusNote) { bonusNote = mi.bonusNote; }

            if (mi.name.trim() !== nameAndBonus || !noExtras) {
                return (
                    <div className="col-12 ms-2">
                        <div><i>{mi.features}</i></div>
                        {(nameAndBonus.trim() !== "" && bonusNote.trim() !== "") &&
                            <div><b>Bonus: </b>{nameAndBonus}. {bonusNote}</div>
                        }
                        {mi.benefits.trim() !== "" ? <div><b>Benefits.</b> {mi.benefits} </div> : ""}
                        {mi.curses.trim() !== "" ? <div><b>Curses.</b> {mi.curses} </div> : ""}
                        {mi.hasPersonality ? <div><b>Personality:</b> {mi.hasPersonality && mi.personalityVirtue?.trim() !== "" ? <span>{mi.personalityVirtue} </span> : ""}{mi.hasPersonality && mi.personalityFlaws?.trim() !== "" ? <span>{mi.personalityFlaws} </span> : ""}{mi.hasPersonality && mi.personalityTraits?.trim() !== "" ? <span>{mi.personalityTraits} </span> : ""}</div> : ""}
                    </div>
                )
            }
            return null;
        }

        const rows: any = [];

        magicItems.forEach((mi, index) => {
            let slots = 1;

            let theWeapon: Weapon | undefined = undefined;
            if (mi.itemType === "weapon") {
                theWeapon = globalContext.availableWeapons.find((w) => w.id === mi.itemTypeId);
                if (theWeapon) { slots = theWeapon.slots; }
            }

            let theArmor: Armor | undefined = undefined;
            if (mi.itemType === "armor") {
                theArmor = globalContext.availableArmors.find((w) => w.id === mi.itemTypeId);
                if (theArmor) { slots = theArmor.slots; }
            }

            rows.push(
                <div className="row buyRow" key={mi.name + index}>
                    <div className="col-8 col-sm-8 col-md-8"><b>{mi.name}</b></div>
                    <div className="col-6 col-sm-2 col-md-2">{slots} slot{slots === 1 ? "" : "s"}</div>
                    <div className="col-6 col-sm-2 col-md-2 text-end">
                        <button className="btn btn-secondary btn-sm btnBuy me-1" onClick={() => props.onEditMagicItem(mi.id)} title="Edit"><FontAwesomeIcon icon={faEdit} /></button>
                        <button className="btn btn-secondary btn-sm btnBuy me-1" onClick={() => discardMagicItem(mi.id)} title="Discard"><FontAwesomeIcon icon={faXmark} /></button>
                    </div>
                    {getDesc(theWeapon, theArmor, mi)}
                </div>
            );

        })
        return rows;
    }


    const discardMagicItem = (id: string) => {
        if (window.confirm("Are you sure you want to discard this magic item?")) {
            props.onDiscardMagicItem(id);
        }
    }

    return getMagicItems()
}

export default ListMagicItems;
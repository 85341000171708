import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByName } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    options?: string[];
    level: Level;
    size?: number;
    inputName: string;
    setBonus: (bonus: Bonus) => void;
    parentBonusId?: string;
    bonusId?: string;
}

const StatBonus: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getTheBonus = () => {
        let name = "StatBonus";
        if (props.inputName === "stat1") { name = "StatBonus-1"; }
        if (props.inputName === "stat2") { name = "StatBonus-2"; }

        let theBonus: Bonus | undefined = undefined;
        if (props.parentBonusId) {
            theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
        } else {
            theBonus = getBonusByName(globalContext.bonuses, name, props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
        }

        return theBonus;
    }

    const getCurrentValue = () => {
        let val = "";
        const theBonus = getTheBonus();
        if (theBonus) {
            val = theBonus.bonusTo;
        }
        return val;
    }

    const setStatBonus = (bonusTo: string) => {

        let theSize = props.size ? props.size : 0;
        const theBonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "StatBonus",
            bonusName: "StatBonus",
            bonusTo: bonusTo + ":+" + theSize
        }
        if (props.parentBonusId) { theBonus.parentBonusId = props.parentBonusId; }
        if (props.bonusId) { theBonus.bonusId = props.bonusId }
        props.setBonus(theBonus);
    }

    if (props.options) {
        if (props.options.length === 1) {
            setStatBonus(props.options[0]);
        }
    }

    const getOptions = () => {

        if (globalContext.stats.Strength === 0) {
            return null;
        }

        if (props.options) {
            let output: any = [];
            props.options.forEach((o) => {
                // let theSize = props.size ? props.size : 0;
                // const isSelected = getCurrentValue() === o + ":+" + theSize;

                let text = "stat";
                if (o === "STR") { text = "STR " + globalContext.finalStats.Strength }
                if (o === "DEX") { text = "DEX " + globalContext.finalStats.Dexterity }
                if (o === "CON") { text = "CON " + globalContext.finalStats.Constitution }
                if (o === "INT") { text = "INT " + globalContext.finalStats.Intelligence }
                if (o === "WIS") { text = "WIS " + globalContext.finalStats.Wisdom }
                if (o === "CHA") { text = "CHA " + globalContext.finalStats.Charisma }

                let theSize = props.size ? props.size : 0;
                const checked = getCurrentValue() === o + ":+" + theSize;

                output.push(<label key={props.sourceName + "_" + o} className="statBonus"><input type="radio" onChange={(e) => setStatBonus(e.target.value)} value={o} checked={checked}></input>&nbsp;{text}</label>);
            })
            return output;
        }
        return null;

    }

    let valMessage = "";
    if (globalContext.stats.Strength === 0) {
        valMessage = "Please roll for stats first";
    } else {
        if (getCurrentValue() === "") {
            valMessage = "Please select a stat bonus";
        }
    }

    return (
        <div key="attackBonus" className="indented">
            {/* <div><b>{prompt}</b></div> */}
            <>{getOptions()}</>
            {valMessage !== "" &&
                <div className="valError">{valMessage}</div>
            }
        </div>
    )

}

export default StatBonus;
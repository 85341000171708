export interface ArmorType {
    name: string;
}

export const armorTypes: ArmorType[] = [
    { name: "Leather armor" },
    { name: "Chainmail" },
    { name: "Plate mail" },
    { name: "Shield" }
]

// Leather armor 10 gp 1
// 11 + DEX
// mod
// -
// Chainmail 60 gp 2
// 13 + DEX
// mod
// Disadv on
// stealth, swim
// Plate mail 130 gp 3 15
// No swim,
// disadv stealth
// Shield

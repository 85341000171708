import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByNameAndBonusName, getBonusByNameAndBonusNameAndBonusTo } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const Plus1ToAttacksOrPlus1ToMagicalDabbler: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setPlus1ToAttacks = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus1ToAttacksOrPlus1ToMagicalDabbler",
            bonusName: "Plus1ToHit",
            bonusTo: "Melee and ranged attacks"
        };
        props.setBonus(bonus);
    }

    const setMagicalDabblerBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus1ToAttacksOrPlus1ToMagicalDabbler",
            bonusName: "Plus1MagicalDabbler",
            bonusTo: "MagicalDabbler",
            bonusAmount: 1
        };
        props.setBonus(bonus);
    }

    // const plus1ToHitSelected = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.gainedAtLevel === props.level.level && b.name === "Plus1ToAttacksOrPlus1ToMagicalDabbler" && b.bonusName === "Plus1ToHit" && b.bonusTo === "Melee and ranged attacks") !== undefined;
    // const plus1MagicalDabblerSelected = globalContext.bonuses.find((b) => props.sourceType && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.gainedAtLevel === props.level.level && b.name === "Plus1ToAttacksOrPlus1ToMagicalDabbler" && b.bonusName === "Plus1MagicalDabbler") !== undefined;

    const plus1ToHitSelected = getBonusByNameAndBonusNameAndBonusTo(globalContext.bonuses, "Plus1ToAttacksOrPlus1ToMagicalDabbler", "Plus1ToHit", "Melee and ranged attacks", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plus1MagicalDabblerSelected = getBonusByNameAndBonusName(globalContext.bonuses, "Plus1ToAttacksOrPlus1ToMagicalDabbler", "Plus1MagicalDabbler", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;

    const req = !plus1ToHitSelected && !plus1MagicalDabblerSelected;

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setPlus1ToAttacks()} checked={plus1ToHitSelected}></input>&nbsp;+1 to melee and ranged attacks</label>
            <label><input type="radio" onChange={() => setMagicalDabblerBonus()} checked={plus1MagicalDabblerSelected}></input>&nbsp;+1 to Magical Dabbler rolls</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default Plus1ToAttacksOrPlus1ToMagicalDabbler;
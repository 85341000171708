import { Ancestry } from "../data/ancestries";
import { Bonus } from "../data/bonus";
import { CharClass } from "../data/classes";
import { dogsLifes } from "../data/dogs";
import { duckQuackstories } from "../data/ducks";
import { plusMinus } from "../data/stats";

import { getClassNameForSpellCastingClassNum, getStatFullNameByShortName } from "../data/utilities";

export const getBonusesDesc = (
    ancestry: Ancestry | undefined,
    charClass: CharClass | undefined,
    demonicPossessionBonus: number,
    spellCastingBonus: number,
    bonuses: Bonus[],
    level: number
) => {
    const bonList: string[] = [];

    if (ancestry && ancestry.extras) {
        ancestry.extras.forEach((ex) => {
            if (ex.printDesc !== "") {
                bonList.push(`${ancestry.name}: ${ex.name.toUpperCase()}: ${ex.printDesc}`);
            }
        })
    }

    charClass?.extras.sort((e1, e2) => e1.name < e2.name ? -1 : 1).forEach((ex) => {
        if (ex.printDesc !== undefined) {
            if (ex.name === "Spellcasting") {
                const spellClassName = getClassNameForSpellCastingClassNum(charClass.spellCastingClassNum, undefined);
                bonList.push(`${charClass.name}: ${(spellClassName + " " + ex.name).toUpperCase()}: ${ex.printDesc}To cast a ${spellClassName} spell, roll 1d20${plusMinus(spellCastingBonus)} vs a DC equal to 10 + the spell's tier.`);
            } 
            else if (ex.name === "Chanting") {
                const spellClassName = getClassNameForSpellCastingClassNum(charClass.spellCastingClassNum, undefined);
                bonList.push(`${charClass.name}: ${(spellClassName + " " + ex.name).toUpperCase()}: ${ex.printDesc}To perform a shamanic chant, roll 1d20${plusMinus(spellCastingBonus)} vs a DC equal to 9 + the spell's tier. Treat chants like focus spells; while maintaining a chant you can move but take no action`);
            }
            else if (ex.name === "Demonic Possession") {
                bonList.push(`${charClass.name}: ${ex.name.toUpperCase()}: 3/day, +${demonicPossessionBonus} to damage for 3 rounds.`);
            }
            else if (ex.name === "Patron") {
                let patron = ex.printDesc;
                const patronBonus = bonuses.find((b) => b.sourceCategory === "Patron" && b.name === "Patron");
                if (patronBonus && patronBonus.bonusTo) { patron = "You serve " + patronBonus.bonusTo; }
                bonList.push(`${charClass.name}: ${ex.name.toUpperCase()}: ${patron}`);
            } 
            else if (ex.name === "Healing Circle") {
                bonList.push(`${charClass.name}: ${ex.name.toUpperCase()}: 1/day you can perform a 10-minute drumming and chanting meditation. All participants are healed for 1d4 HP if the performance is not interrupted. A spellcaster or herbalist can recover a lost spell or elixir instead. Random encounter chance is increased.`);
            }
            else {
                bonList.push(`${charClass.name}: ${ex.name.toUpperCase()}: ${ex.printDesc}`);
            }
        }
    })

    const bonusSort = (b1: Bonus, b2: Bonus) => {
        if (b1.sourceType === "Ancestry" && b2.sourceType !== "Ancestry") {
            return -1;
        } else {
            return b1.gainedAtLevel < b2.gainedAtLevel ? -1 : 1;
        }
    }

    bonuses.sort(bonusSort).forEach((bon) => {

        let source = bon.sourceName + "-" + bon.gainedAtLevel;

        // Add patron if this is a Patron Boon Talent
        let patron = "";
        if (bon.boonPatron && bon.boonPatron !== "" && bon.sourceCategory.indexOf("Boon") !== -1) { patron = bon.boonPatron + " - "; }
        if (bon.boonSource === "WarlockTalentRandomBoon") { patron = "Unexplained Gift: " + patron };

        if (bon.name === "FarSight") {

            if (bon.bonusName === "AttackBonus") {
                bonList.push(`${source}: ${patron}FAR SIGHT: +${bon.bonusAmount} to ranged weapon attack rolls`);
            }
            if (bon.bonusName === "Plus1ToCastingSpells") {
                bonList.push(`${source}: ${patron}FAR SIGHT: +${bon.bonusAmount} to spellcasting rolls (inc. above)`);
            }
        }

        if (bon.name === "WeaponMastery") {
            const mod = Math.trunc(level / 2) + 1;
            bonList.push(`${source}: ${patron}WEAPON MASTERY: +${mod} to attack and damage rolls with the ${bon.bonusTo}`);
        }

        if (bon.name === "SetWeaponTypeDamage") {
            const weaponAndDie = bon.bonusTo.split(":");
            bonList.push(`${source}: ${patron}IMPROVED DAMAGE: You deal 1d${weaponAndDie[1]} damage with the ${weaponAndDie[0]}`);
        }

        if (bon.name === "Grit") {
            bonList.push(`${source}: ${patron}GRIT: Advantage on checks to overcome opposing forces using ${bon.bonusName}`);
        }

        if (bon.bonusName === "StatBonus") {
            if (bon.name === "StatBonus-1") {
                // Combine two +1 stat bonuses into one line
                let statBonus1: string[] = [];
                let statBonus2: string[] = [];
                // if sourceCategory has '-1' or '-2' on the end, don't use that for matching
                const cleanedSourceCategory = bon.sourceCategory.replace("-1", "").replace("-2", "");
                const sb1 = bonuses.find((b) => b.name === "StatBonus-1" && b.sourceType === bon.sourceType && b.sourceCategory.replace("-1", "").replace("-2", "") === cleanedSourceCategory && b.sourceType === bon.sourceType && b.boonPatron === bon.boonPatron && b.boonSource === bon.boonSource && b.gainedAtLevel === bon.gainedAtLevel);
                if (sb1) { statBonus1 = sb1.bonusTo.split(":"); }
                const sb2 = bonuses.find((b) => b.name === "StatBonus-2" && b.sourceType === bon.sourceType && b.sourceCategory.replace("-1", "").replace("-2", "") === cleanedSourceCategory && b.sourceType === bon.sourceType && b.boonPatron === bon.boonPatron && b.boonSource === bon.boonSource && b.gainedAtLevel === bon.gainedAtLevel);
                if (sb2) { statBonus2 = sb2.bonusTo.split(":"); }
                if (statBonus1[0] === statBonus2[0]) {
                    const totalBonus = plusMinus(parseInt(statBonus1[1], 10) + parseInt(statBonus2[1], 10));
                    bonList.push(`${source}: ${patron}STAT BONUS: ${totalBonus} to ${getStatFullNameByShortName(statBonus1[0])}`);
                } else {
                    bonList.push(`${source}: ${patron}STAT BONUS: ${statBonus1[1]} to ${getStatFullNameByShortName(statBonus1[0])} and ${statBonus2[1]} to ${getStatFullNameByShortName(statBonus2[0])}`);
                }
            } else {
                // Single +2 stat bonus
                if (bon.name !== "StatBonus-2") { // avoid second bonus being displayed
                    const sb = bon.bonusTo.split(":");
                    bonList.push(`${source}: ${patron}STAT BONUS: ${sb[1]} to ${getStatFullNameByShortName(sb[0])}`);
                }
            }
        }

        if (bon.name === "Plus1ToHit" || (bon.name === "Plus1ToAttacksOrPlus1ToMagicalDabbler" && bon.bonusName === "Plus1ToHit") || (bon.name === "Plus1ToAttacksOrDamage" && bon.bonusName === "Plus1ToHit") || (bon.name === "LongbowOrPlus1Ranged" && bon.bonusName === "Plus1ToHit") || (bon.bonusTo === "Melee and ranged attacks" && bon.bonusName === "Plus1ToHit")) {
            if (bon.bonusTo === "Melee and ranged attacks") {
                bonList.push(`${source}: ${patron}ATTACKS: +1 to melee and ranged attack rolls`);
            }
            if (bon.bonusTo === "Melee attacks") {
                bonList.push(`${source}: ${patron}ATTACKS: +1 to melee attack rolls`);
            }
            if (bon.bonusTo === "Ranged attacks") {
                bonList.push(`${source}: ${patron}ATTACKS: +1 to ranged attack rolls`);
            }
        }

        if (bon.name === "Plus1ToHitAndDamage") {
            if (bon.bonusTo === "Melee and ranged attacks") {
                bonList.push(`${source}: ${patron}ATTACKS: +1 to attacks and damage with melee and ranged weapons`);
            }
            if (bon.bonusTo === "Melee attacks") {
                bonList.push(`${source}: ${patron}ATTACKS: +1 to attacks and damage with melee weapons`);
            }
            if (bon.bonusTo === "Ranged attacks") {
                bonList.push(`${source}: ${patron}ATTACKS: +1 to attacks and damage with ranged weapons`);
            }
        }

        if (bon.bonusName === "Plus1ToMeleeDamage") {
            bonList.push(`${source}: ${patron}ATTACKS: +1 to damage with melee weapons`);
        }

        if (bon.bonusName === "Plus1ToMeleeAttacks") {
            bonList.push(`${source}: ${patron}ATTACKS: +1 to attacks with melee weapons`);
        }

        if (bon.bonusName === "Plus1ToDamage") {
            bonList.push(`${source}: ${patron}ATTACKS: +1 to melee and ranged damage`);
        }

        if (bon.bonusName === "Plus1ToCastingSpells" && bon.name !== "FarSight" && bon.name !== "Knack") {
            const spellClassName = getClassNameForSpellCastingClassNum(charClass?.spellCastingClassNum, undefined);
            bonList.push(`${source}: ${patron}CASTING: +1 to ${spellClassName} spell casting rolls (inc. above)`);
        }

        if (bon.bonusName === "Plus1ToCastingSpells" && bon.name === "Knack") {
            bonList.push(`${source}: ${patron}KNACK: +1 to spell casting rolls (inc. above)`);
        }

        if (bon.bonusName === "LuckTokenAtStartOfSession") {
            bonList.push(`${source}: ${patron}KNACK: Begin each session with a luck token`);
        }

        if (bon.name === "ArmorMastery") {
            bonList.push(`${source}: ${patron}ARMOR MASTERY: +1 AC with ${bon.bonusTo}`);
        }

        if (bon.name === "LearnExtraSpell") {
            const spellOrChantLearn = charClass && charClass.name === "Shaman" ? "CHANT" : "SPELL";
            bonList.push(`${source}: ${patron}LEARN EXTRA ${spellOrChantLearn}: Learn ${bon.bonusName}`);
        }

        if (bon.name === "AdvOnCastOneSpell") {
            const spellOrChantMastery = charClass && charClass.name === "Shaman" ? "CHANT MASTERY" : "SPELL MASTERY";
            bonList.push(`${source}: ${patron}${spellOrChantMastery}: Gain advantage when casting ${bon.bonusName}`);
        }

        if (bon.name === "MakeRandomMagicItem") {
            bonList.push(`${source}: ${patron}MAGIC ITEM: Make one random magic item`);
        }

        if (bon.name === "AdvOnInitiative") {
            bonList.push(`${source}: ${patron}INITIATIVE: Gain advantage on initiative rolls`);
        }

        if (bon.name === "BackstabIncrease") {
            bonList.push(`${source}: ${patron}IMP. BACKSTAB: Your Backstab deals +1 dice of damage`);
        }

        if (bon.name === "Duck ability" && bon.bonusName !== "") {
            bonList.push(`${source}: ${bon.bonusName?.toUpperCase()}: ${bon.bonusTo}`);
        }

        if (bon.name === "Dog ability" && bon.bonusName !== "") {
            bonList.push(`${source}: ${bon.bonusName?.toUpperCase()}: ${bon.bonusTo}`);
        }

        if (bon.name === "ReduceHerbalismDC") {
            bonList.push(`${source}: ${patron}IMP. HERBALISM: You gain ADV on Herbalism checks for a remedy you choose`);
        }

        if (bon.name === "FindRandomWand") {
            bonList.push(`${source}: ${patron}FIND WAND: You find a random priest or wizard wand (you choose)`);
        }

        if (bon.bonusName === "Plus1MagicalDabbler") {
            bonList.push(`${source}: ${patron}IMP. DABBLING: +1 to Magical Dabbler rolls`);
        }

        if (bon.bonusName === "ReducePerformDC") {
            bonList.push(`${source}: ${patron}IMP. PERFORM: Reduce the DC of your Perform effects by 3 each`);
        }

        if (bon.bonusName === "Plus1DemonicPossession") {
            bonList.push(`${source}: ${patron}IMP. DEMONIC POSSESSION: +1 to Demonic Possession bonus (inc. above)`);
        }

        if (bon.bonusName === "TeleportToFamiliar") {
            bonList.push(`${source}: ${patron}TELEPORT: 1/day, teleport to your familiar's location as a move`);
        }

        if (bon.bonusName === "Berserk") {
            bonList.push(`${source}: ${patron}BESERKER: 1/day, go berserk: immune to damage for 3 rounds`);
        }

        if (bon.bonusName === "DualOldGods") {
            bonList.push(`${source}: ${patron}DUALITY: Duality; choose two different Old Gods effects each day`);
        }

        // Warlock Talents

        // if (bon.bonusName === "GainRandomBoon") {
        //     bonList.push(`${source}: ${patron}UNEXPLAINED GIFT: Roll random boon from any patron`);
        // }

        // if (bon.bonusName === "RollTwoBoonsAndKeepOne") {
        //     bonList.push(`${source}: ${patron}A CHOICE: Roll two Patron Boons and choose one to keep`);
        // }

        // Warlock Boon talents

        if (bon.bonusName === "ADVOnMelee3Rounds") {
            bonList.push(`${source}: ${patron}FRENZY: 1/day, gain advantage on melee attacks for 3 rounds`);
        }

        if (bon.bonusName === "LearnWeapon") {
            if (bon.bonusTo !== "") {
                bonList.push(`${source}: ${patron}WEAPON TRAINING: Learn to wield the ` + bon.bonusTo);
            } else {
                bonList.push(`${source}: ${patron}WEAPON TRAINING: Learn a melee weapon`);
            }
        }

        if (bon.bonusName === "ForceDMReroll") {
            bonList.push(`${source}: ${patron}CHANGE FATE: 1/day, force the GM to reroll a single roll`);
        }

        if (bon.bonusName === "ForesightPlus1AC") {
            bonList.push(`${source}: ${patron}FORSEE DANGER: Gain +1 to your AC through supernatural foresight`);
        }

        if (bon.bonusName === "AddWISToRolls") {
            bonList.push(`${source}: ${patron}INSIGHT: 3/day, add your WIS bonus to any roll`);
        }

        if (bon.bonusName === "ReadMindTouch") {
            bonList.push(`${source}: ${patron}READ MIND: 1/day, read the mind of a creature you touch for 3 rounds`);
        }

        if (bon.bonusName === "TurnToSlime") {
            bonList.push(`${source}: ${patron}DISSOLVE: 1/day, turn into a crawling puddle of slime for 3 rounds`);
        }

        if (bon.bonusName === "MaximizeTwoHPRolls") {
            bonList.push(`${source}: ${patron}TOUGH: Maximize 2 hit point die rolls (prior or future)`);
        }

        if (bon.bonusName === "ImmuneToEnergyType") {
            if (bon.bonusTo !== "") {
                bonList.push(`${source}: ${patron}IMMUNITY: Immune to ` + bon.bonusTo);
            } else {
                bonList.push(`${source}: ${patron}IMMUNITY: Become immune to 1: acid, cold, poison`);
            }
        }

        if (bon.name === "LearnSpellFromPatron") {
            if (bon.bonusName !== "") {
                bonList.push(`${source}: ${patron}WIZARD SPELL: Learn the ${bon.bonusName} spell, cast with INT`);
            } else {
                bonList.push(`${source}: ${patron}WIZARD SPELL: Learn a Wizard spell (tier = half your level), cast with INT`);
            }
        }

        if (bon.bonusName === "XPForSecret") {
            bonList.push(`${source}: ${patron}INQUISITOR: +1 XP whenever you learn a valuable or significant secret`);
        }

        if (bon.bonusName === "Hypnotize3Rounds") {
            bonList.push(`${source}: ${patron}HYPNOTISE: 1/day, hypnotize a LV 5 or less creature for 3 rounds`);
        }

        if (bon.bonusName === "LongbowOrPlus1Ranged") {
            if (bon.name === "LearnWeapon") {
                bonList.push(`${source}: ${patron}ARCHER: Learn to wield the Longbow`);
            } else {
                bonList.push(`${source}: ${patron}ARCHER: Learn to wield the Longbow or gain +1 to ranged attacks`);
            }
        }

        if (bon.bonusName === "HostileSpellsHard") {
            bonList.push(`${source}: ${patron}WARDING: Hostile spells that target you are always hard to cast`);
        }

        if (bon.bonusName === "TeleportToFar") {
            bonList.push(`${source}: ${patron}TELEPORT: 1/day, teleport to a far location you see as your move`);
        }

        if (bon.bonusName === "ForceMoraleCheck") {
            bonList.push(`${source}: ${patron}FEAR: 1/day, force a close being to check morale, even if immune`);
        }

        // Desert Rider

        if (bon.bonusName === "UseAnyMount") {
            bonList.push(`${source}: ${patron}MASTER RIDER: You can use any rider-bearing creature as your mount`);
        }

        if (bon.bonusName === "ImpCharge") {
            bonList.push(`${source}: ${patron}IMP CHARGE: Gain an additional use of your Charge talent each day`);
        }

        // Pit Fighter

        if (bon.bonusName === "IgnoreOneAttack") {
            bonList.push(`${source}: ${patron}WALK IT OFF: 1/day, ignore all damage and effects from one attack`);
        }

        if (bon.bonusName === "ImpFlourish") {
            bonList.push(`${source}: ${patron}IMP. FLOURISH: Increase the HP you gain from Flourish by 1d6`);
        }

        // Ras-Godai

        if (bon.bonusName === "UsePoisons") {
            bonList.push(`${source}: ${patron}POISONER: You are trained in the use of poisons`);
        }

        if (bon.bonusName === "ImpSmokeStep") {
            bonList.push(`${source}: ${patron}IMP SMOKE STEP: Gain an additional use of your Smoke Step talent`);
        }

        // Ras-Goda Black Lotus Talents

        if (bon.bonusName === "ParalyseOnWeaponHit") {
            bonList.push(`${source}: ${patron}PARALYSE: 1/day, paralyze a target of LV 9 or less for 1d4 rounds when you damage it with a weapon`);
        }

        if (bon.bonusName === "ADVonDEXToAvoidEntrapment") {
            bonList.push(`${source}: ${patron}SLIPPERY: ADV on Dexterity checks to avoid entrapment or injury`);
        }

        if (bon.bonusName === "Plus1ACWhenDualWield") {
            bonList.push(`${source}: ${patron}PARRY: You gain +1 to your AC when wielding a melee weapon in each hand`);
        }

        if (bon.bonusName === "PlusOneHitDie") {
            bonList.push(`${source}: ${patron}TOUGH: You gain an additional hit points die`);
        }

        if (bon.bonusName === "TripleDamageAssassinate") {
            bonList.push(`${source}: ${patron}DEADLY: You deal triple damage with your Assassinate talent`);
        }

        if (bon.bonusName === "Morale18") {
            bonList.push(`${source}: ${patron}SCARY: When enemies who can see you make a morale check, the DC is 18 instead of 15`);
        }

        if (bon.bonusName === "WalkOnWater") {
            bonList.push(`${source}: ${patron}WATER WALK: 1/day, you can walk on water as if it were solid for 1d4 rounds`);
        }

        if (bon.bonusName === "MakeAsleep") {
            bonList.push(`${source}: ${patron}STUPEFY: 1/day, choose a living creature of LV 5 or less you can see within near; it must pass a DC 15 CON check or fall asleep`);
        }

        if (bon.bonusName === "WalkOnWalls") {
            bonList.push(`${source}: ${patron}WALL WALK: 1/day, you can walk on sheer surfaces such as walls for 1d4 rounds`);
        }

        if (bon.bonusName === "Invisible") {
            bonList.push(`${source}: ${patron}UNSEEN: 1/day, choose a creature of LV 9 or less you can see; it must pass a DC 15 WIS check or it can't see or hear you for 1d4 rounds`);
        }

        // Seer

        if (bon.bonusName === "GainExtraOmen") {
            bonList.push(`${source}: ${patron}OMINOUS: Gain an additional use of your Omen talent each day`);
        }

        if (bon.bonusName === "ImpDestined") {
            bonList.push(`${source}: ${patron}DESTINED: Increase the die category of your Destined talent by one`);
        }

        // Beastmaster

        if (bon.bonusName === "ExtraBeastShapePerDay") {
            bonList.push(`${source}: ${patron}SHIFTER: You may use Beast Shape 1 additional time per day`);
        }

        if (bon.bonusName === "BeastAttackDamageIncreaseOneDie") {
            bonList.push(`${source}: ${patron}VICIOUS: Increase Beast Attack damage die category by one`);
        }

        if (bon.bonusName === "MakeAnimalMagicItem") {
            bonList.push(`${source}: ${patron}CRAFT: Make an animal magic item of your type (see pg. 34 of Un. Sel.)`);
        }

        if (bon.name === "BeastMasterAnimalType") {
            bonList.push(`${source}: ${patron}${bon.bonusTo.toUpperCase()} FORM: You gain attacks and can assume the shape of a ` + bon.bonusTo);
        }

        if (bon.bonusName === "BeastMasterPlus1AC") {
            bonList.push(`${source}: ${patron}TOUGH HYDE: Gain +1 to your unarmored AC`);
        }

        // Fiend

        if (bon.bonusName === "KnowDesires") {
            bonList.push(`${source}: ${patron}PLUNDER THE HEART: 1/day, know the desires or fears of a creature in near range`);
        }

        if (bon.bonusName === "Pyro_FirebondWeaponDieIncrease") {
            bonList.push(`${source}: ${patron}IMP. FIREBOND: Increase die category of Firebond Weapon’s extra damage.`);
        }

        if (bon.bonusName === "Pyro_FlamingImpact") {
            bonList.push(`${source}: ${patron}FLAMING IMPACT: A Firebond Weapon strike ignites target, doing 1 damage per round until extinguished. +1 on duplicate.`);
        }

        if (bon.bonusName === "Pyro_BoilingBloodSpray") {
            bonList.push(`${source}: ${patron}BOILING BLOOD SPRAY: When a Firebond Weapon strikes a creature, scorched blood does 1 damage to creatures in close range. Add +1 damage on duplicate.`);
        }

        if (bon.bonusName === "Pyro_WallOfFire") {
            bonList.push(`${source}: ${patron}WALL OF FIRE: 1/day as an action, create a near-length wall of fire in a straight line from any point a near distance from you for 5 rounds. The wall deals 1d4 damage on touch. Increase damage die category on duplicate.`);
        }

        if (bon.bonusName === "Pyro_MagmaArmor") {
            bonList.push(`${source}: ${patron}MAGMA ARMOR: As an action, activate for 5 rounds, adding +1 to AC. Add +1 to AC on duplicate.`);
        }

        if (bon.bonusName === "Pyro_BlastFurnace") {
            bonList.push(`${source}: ${patron}BLAST FURNACE: As an action, release your body’s heat. Any creatures in close range take 1d4 of damage. Increase damage die category on duplicate.`);
        }

        if (bon.bonusName === "Pyro_HotFlash") {
            bonList.push(`${source}: ${patron}HOT FLASH: As an action, create a white hot flash. Creatures in a near radius around you are blinded and stunned for 1 round if they fail a DC 12 CON check. Add a round of blindness on duplicate.`);
        }

        if (bon.bonusName === "Pyro_Fireform") {
            bonList.push(`${source}: ${patron}FIRE FORM: 1/day as an action, transform yourself into flames for 3 rounds. Ranged attacks do half damage to you. Creatures using natural weapons take 1d4 damage if they hit. Emits light to close. Increase damage die category on duplicate.`);
        }

        if (bon.bonusName === "Pyro_HotHands") {
            bonList.push(`${source}: ${patron}HOT HANDS: When you hit an opponent with a Firebond weapon, they make DC 12 CON check or they drop their weapon. It takes one round to cool. Increase DC die category on duplicate.`);
        }

        if (bon.bonusName === "Pyro_FlamingFrenzy") {
            bonList.push(`${source}: ${patron}FLAMING FRENZY: As an action, activate to gain 3 attacks on your next turn. Gain one more attack on duplicate.`);
        }

        if (bon.bonusName === "Pyro_Flarephobia") {
            bonList.push(`${source}: ${patron}FLAREPHOBIA: Creatures hit by a Firebond weapon must make a morale check at DC 9. Increase die category on duplicate.`);
        }

        if (bon.bonusName === "Pyro_Supernova") {
            bonList.push(`${source}: ${patron}SUPERNOVA: Detonate yourself as a superheated explosion in a near radius around you, dealing 5d12 damage to all creatures. Any objects and all of your possessions are destroyed. You are reduced to 0 hit points and lose 1 CON permanently.`);
        }

        // Grave Warden

        if (bon.bonusName === "ImpClaimUndead") {
            bonList.push(`${source}: ${patron}IMP. CLAIM UNDEAD: Claim Undead gains one DC step for undead resistance (max DC 18)`);
        }

        if (bon.bonusName === "ImpNecroticReap") {
            bonList.push(`${source}: ${patron}IMP. NECROTIC REAP: Increase the Necrotic Reap bonus by an additional +2`);
        }

        // Ovate

        if (bon.bonusTo === "AnimalCompanion") {
            bonList.push(`${source}: ${patron}: ANIMAL COMPANION: You have a ${bon.bonusName} as a companion`);
        }

        if (bon.bonusName === "ExtraAnimalCompanion") {
            bonList.push(`${source}: ${patron}: PACK LEADER: The number of animal companions you can have increases by one`);
        }

        if (bon.bonusName === "ExtraAnimalLanguage") {
            bonList.push(`${source}: ${patron}: BEAST SPEAKER: You can speak to an additional species of animal`);
        }

        // Shaman 

        if (bon.bonusName === "ImpHealingCircle") {
            bonList.push(`${source}: ${patron}IMP. HEALING CIRCLE: Gain an additional use of your Healing Circle talent each day`);
        }


        // Plague Doctor

        if (bon.bonusName === "ExtraInsect") {
            bonList.push(`${source}: ${patron}EX. INSECT: Mesmerize one additional insect per day.`);
        }

        if (bon.bonusName === "StrongElixir") {
            bonList.push(`${source}: ${patron}STRONG ELIXIR: Add an extra die rolled for ${bon.bonusTo !== "" ? bon.bonusTo : "(choose elixir)"} elixirs.`);
        }

        if (bon.bonusName === "Plus1ToElixirChecks") {
            bonList.push(`${source}: ${patron}ELIXIR EXPERT: +1 to all elixir checks`);
        }

        if (bon.bonusName === "ADVToElixir") {
            bonList.push(`${source}: ${patron}ELIXIR ADV: You gain ADV on elixir checks for ${bon.bonusTo !== "" ? bon.bonusTo : "(choose elixir)"} elixirs.`);
        }

        if (bon.sourceCategory === "DuckQuackStory") {
            let name = "";
            let desc = "";
            const theStory = duckQuackstories.find((qs) => bon.bonusTo.indexOf(qs.statBonus) !== -1);
            if (theStory) {
                name = theStory.name;
                desc = theStory.desc;
            }
            bonList.push(`${source}: ${name.toUpperCase()}: ${desc}`);
        }

        if (bon.sourceCategory === "DogsLife") {
            let name = "";
            let desc = "";
            const theStory = dogsLifes.find((qs) => bon.bonusTo.indexOf(qs.statBonus) !== -1);
            if (theStory) {
                name = theStory.name;
                desc = theStory.desc;
            }
            bonList.push(`${source}: ${name.toUpperCase()}: ${desc}`);
        }

        // Roustabout Talents

        if (bon.bonusName === "LearnArmor") {
            if (bon.bonusTo !== "") {
                bonList.push(`${source}: ${patron}ARMOR TRAINING: Learn to use ` + bon.bonusTo);
            } else {
                bonList.push(`${source}: ${patron}ARMOR TRAINING: Learn a type of armor`);
            }
        }

    })

    return bonList;
}
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByNameAndBonusTo } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const PlusTwoCONOrPlusOneSpellCasting: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setPlusTwoWISBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "PlusTwoCONOrPlusOneSpellCasting",
            bonusName: "StatBonus",
            bonusTo: "CON:+2"
        };
        props.setBonus(bonus);
    }

    const setSpellBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "PlusTwoCONOrPlusOneSpellCasting",
            bonusName: "Plus1ToCastingSpells",
            bonusTo: "Grave Warden",
            bonusAmount: 1
        };
        props.setBonus(bonus);
    }

    const plusTwoWISSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "PlusTwoCONOrPlusOneSpellCasting", "CON:+2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const spellcastingBonusSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "PlusTwoCONOrPlusOneSpellCasting", "Grave Warden", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;


    const req = !plusTwoWISSelected && !spellcastingBonusSelected;

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setPlusTwoWISBonus()} checked={plusTwoWISSelected}></input>&nbsp;CON {globalContext.finalStats.Constitution}</label>
            <label><input type="radio" onChange={() => setSpellBonus()} checked={spellcastingBonusSelected}></input>&nbsp;+1 Spellcasting</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default PlusTwoCONOrPlusOneSpellCasting;
import React, { createContext, useEffect, useState } from 'react';

import Advancement from './charGen/advancement';
import BuyCrawlingKit from './gear/buyCrawlingKit';
import BuyGear from './gear/buyGear';
import CharDesignMenu from './charGen/charDesignMenu';
import DisplayLedger from './gear/displayLedger';
import Export from './charGen/export';
import GainLoseCoins from './gear/gainLoseCoins';
import GearCarried from './gear/gearCarried';
import GenerateFourLevelZeroes from './charGen/generateFourLevelZeroes';
import RollBackground from '../components/charGen/rollBackground';
import RollGold from './charGen/rollGold';
import RollStats from '../components/charGen/rollStats';
import SaveCharacter from './charGen/saveCharacter';
import SelectAlignment from '../components/charGen/selectAlignment';
import SelectAncestry from '../components/charGen/selectAncestry';
import SelectBackground from './charGen/selectBackground';
import SelectClass from '../components/charGen/selectClass';
import SelectDeity from '../components/charGen/selectDeity';
import SelectName from '../components/charGen/selectName';
import SelectStats from './charGen/selectStats';
import Spinner from './charGen/spinner';
import app from '../firebase/firebase';
import download from 'downloadjs';
import uniqid from 'uniqid';
import { Alert } from 'react-bootstrap';
import { Background, getAllBackgrounds } from '../data/backgrounds';
import { Bonus } from '../data/bonus';
import { Cheat } from '../data/cheats';
import { GearOwned, GearRecord } from '../data/gear';
import { GlobalContextType } from './context';
import { Ledger } from '../data/ledger';
import { Level } from '../data/level';
import { MagicItem } from '../data/magicItem';
import { Treasure } from '../data/treasure';
import { User } from 'firebase/auth';
import { addDoc, collection, doc, getCountFromServer, getDoc, getFirestore, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { calculateTotalGearSlots, downloadFile, getAC, getAllSpellsKnown, getCharacterDesc, getCharacterFeatures, getDemonicPossessionBonus, getGearRecords, getPermittedArmorsByClassName, getPermittedWeaponsByClassName, getRandomLevelOneItems, getRoustaboutSpellList, getSpellCastingBonus, getTotalHitPoints, getWeaponAttacks, handleBuyCrawlingKit, handleBuyItem, ifChangeWarlockPatronRemoveOldPatronBoons, ifRemoveParentBonusAlsoRemoveChildBonuses, removeAnyCastWithAdvIfDoNotKnowSpell, removeAnyduplicateRollTwoAndPickOneBoons, renumberAnimalCompanions, renumberSpellsLearnedFromScrolls, utilAddTalentBonuses, whenSetWarlockTalentOrBoonRemoveExistingBoonsOrTalents } from '../data/utilities';
import { assignAncestryBonuses, getRandomCharacter_LevelOne, getRandomStats } from '../special/randomChar';
import { dogAbilities, dogsLifes } from '../data/dogs';
import { duckAbilities, duckQuackstories } from '../data/ducks';
import { getActiveSourcesFilteredAncestriesFromLocalStorage, getActiveSourcesFilteredClassesFromLocalStorage, getActiveSourcesFromLocalStorage, getCoreRulesOnlyFromLocalStorage, getDesignModeFromLocalStorage, getRandomTypeFromLocalStorage, setActiveSourcesFilteredAncestriesInLocalStorage, setActiveSourcesFilteredClassesInLocalStorage, setActiveSourcesInLocalStorage, setDesignModeInLocalStorage, setIsMinimalInLocalStorage, setRandomTypeInLocalStorage } from './localStorage/localStorage';
import { getAlignments } from '../data/alignments';
import { getAllAncestries } from '../data/ancestries';
import { getAllCharClasses } from '../data/classes';
import { getAllDeities } from '../data/deities';
import { getAllLanguages } from '../data/languages';
import { getAllLanguagesKnown } from './charGen/utilities/languages';
import { getAllPatronBoons } from '../data/warlockBoons';
import { getAllWarlockPatrons } from '../data/warlockPatrons';
import { Armor, getArmors } from '../data/armor';
import { getGearById, sundries } from '../data/sundries';
import { getPDF } from '../special/printChar';
import { getPDFFillable } from '../special/printCharFillable';
import { getRandomAlignment, getRandomAncestry, getRandomBackground, getRandomClass, getRandomDeity, getRandomName } from './charGen/utilities/roll';
import { getRandomIntInclusive } from '../data/random';
import { getStatsIncludingBonuses, Stats } from "../data/stats";
import { getTitle } from './charGen/utilities/titles';
import { magicItemCatalog } from '../data/magicItemCatalog';
import { activeSourceFilteredAncestry, activeSourceFilteredClass, sources } from '../data/sources';
import { spells } from '../data/spells';
import { useNavigate, useParams } from 'react-router-dom';
import { Weapon, weapons } from '../data/weapons';
import { Creature, animals } from '../data/animals';

interface IProps {
    user: User | null | undefined;
}

const CreateCharacter: React.FunctionComponent<IProps> = (props: IProps) => {

    // Testing hardcodes:
    let hardcodedAncestry: string = "";
    let hardcodedClass: string = "";

    const navigate = useNavigate();

    // Check for user
    let isLoggedIn = false;
    let userEmail = "";
    if (props.user && props.user.email) {
        userEmail = props.user?.email;
        isLoggedIn = true;
    }

    //#region Load Character

    // Load character, if id included in URL route
    let { id: IdInQuerystring } = useParams();

    const maxChars = parseInt(process.env.REACT_APP_MAX_CHARACTERS === undefined ? "0" : process.env.REACT_APP_MAX_CHARACTERS, 10);

    useEffect(() => {

        try {

            // load saved character if Id in querystring
            const asyncLoadCharacter = async () => {
                if (IdInQuerystring) {
                    const d = await loadCharacter(IdInQuerystring);
                    if (d) {
                        setLevel(d.traits.level);
                        setLevels(d.traits.levels);
                        if (d.traits.XP) {
                            setXP(d.traits.XP);
                            setRawXP(d.traits.XP.toString());
                        } else {
                            setXP(0);
                            setRawXP("0");
                        }
                        setAmbitionTalentLevel(d.traits.ambitionTalentLevel);
                        setBackground(d.traits.background);
                        if (d.traits.rolledStats) {
                            setStats({ Strength: d.traits.rolledStats.STR, Dexterity: d.traits.rolledStats.DEX, Constitution: d.traits.rolledStats.CON, Intelligence: d.traits.rolledStats.INT, Wisdom: d.traits.rolledStats.WIS, Charisma: d.traits.rolledStats.CHA })
                        } else {
                            setStats({ Strength: d.traits.stats.STR, Dexterity: d.traits.stats.DEX, Constitution: d.traits.stats.CON, Intelligence: d.traits.stats.INT, Wisdom: d.traits.stats.WIS, Charisma: d.traits.stats.CHA })
                        }
                        setAncestry(d.traits.ancestry);
                        setCharClass(d.traits.class);
                        setBonuses(d.traits.bonuses);
                        setAlignment(d.traits.alignment);
                        setDeity(d.traits.deity);

                        setGoldRolled(d.traits.goldRolled);
                        setGold(d.traits.gold);
                        setSilver(d.traits.silver);
                        setCopper(d.traits.copper);
                        setGearCarried(convertGearRecordsToGearOwned(d.traits.gear));
                        setShowBuyCrawlingKit(d.traits.goldRolled === -1);

                        if (d.traits.treasures) {
                            setTreasureCarried(d.traits.treasures);
                        } else {
                            setTreasureCarried([]);
                        }


                        if (d.traits.magicItems) {
                            setMagicItemsCarried(d.traits.magicItems);
                        } else {
                            setMagicItemsCarried([]);
                        }

                        if (d.traits.ledger) {
                            setLedger(d.traits.ledger);
                        } else {
                            // old character wthout a saved ledger; just add current coins to ledger
                            addAllGearToLedger(convertGearRecordsToGearOwned(d.traits.gear), false, d.traits.goldRolled);
                        }

                        setDateCreated(d.dateCreated);
                        setDateLastModified(d.dateLastModifed);

                        setName(d.name);

                        setShowForm(true);
                        setDesignMode("Design");
                        // setShowBuyGear(true);
                        setCharacterAlreadyExists(true);
                        setCoreRulesOnly(d.traits.coreRulesOnly);

                        if (d.traits.edits) { setCheats(d.traits.edits); }

                        if (d.traits.activeSources) {
                            // set the active sources that were used for the character;
                            setActiveSources(d.traits.activeSources);
                            setSourcesOrFiltersChanged(true);
                            // default all the ancestries for the sources to active
                            let filteredAncestries = [...ancestryFilter];
                            filteredAncestries.forEach((a) => {
                                if (d.traits.activeSources.indexOf(a.sourceId) !== -1) {
                                    a.isActive = true;
                                }
                            })
                            setAncestryFilter(filteredAncestries);
                            // default all the classes for the sources to active
                            let filteredClasses = [...classFilter];
                            filteredClasses.forEach((a) => {
                                if (d.traits.activeSources.indexOf(a.sourceId) !== -1) {
                                    a.isActive = true;
                                }
                            })
                        } else {
                            const theSources: string[] = ["SD"];
                            const theAncestry = getAllAncestries().find((a) => a.name === d.traits.class);
                            if (theAncestry && theAncestry.sourceId !== "SD") {
                                theSources.push(theAncestry.sourceId);
                            }
                            const theClass = getAllCharClasses().find((a) => a.name === d.traits.class);
                            if (theClass && theClass.sourceId !== "SD") {
                                theSources.push(theClass.sourceId);
                            }
                            // default all the ancestries for the sources to active
                            let filteredAncestries = [...ancestryFilter];
                            filteredAncestries.forEach((a) => {
                                if (theSources.indexOf(a.sourceId) !== -1) {
                                    a.isActive = true;
                                }
                            })
                            // default all the classes for the sources to active
                            let filteredClasses = [...classFilter];
                            filteredClasses.forEach((a) => {
                                if (theSources.indexOf(a.sourceId) !== -1) {
                                    a.isActive = true;
                                }
                            })
                            setActiveSources(theSources)
                            setSourcesOrFiltersChanged(true);
                        }
                    }
                }
            }
            asyncLoadCharacter();

            // load number of saved characters if id in querystring
            const asyncLoadCharacterCount = async () => {
                if (IdInQuerystring) {
                    const count = await loadNumCharacters(props.user);
                    setNumCharacters(count);
                }
            }
            asyncLoadCharacterCount();

        } catch (e: any) {
            setError(e.message)
        }

    }, [IdInQuerystring])

    useEffect(() => {

        try {
            // load number of saved characters if user is logged in
            const asyncLoadCharacterCount = async () => {
                if (props.user) {
                    const count = await loadNumCharacters(props.user);
                    setNumCharacters(count);
                }
            }
            asyncLoadCharacterCount();

        } catch (e: any) {
            setError(e.message)
        }

    }, [props.user])

    const loadCharacter = async (id: string) => {
        const db = getFirestore(app);
        const docRef = doc(db, "characterComplete", id);
        setIsLoading(true);
        const docSnap = await getDoc(docRef).catch((e) => { });
        setIsLoading(false);
        if (docSnap && docSnap.exists()) {
            return docSnap.data();
        } else {
            setError("Character was not found.");
        }

        return null;
    }

    const loadNumCharacters = async (user: any) => {
        try {
            const db = getFirestore(app);
            const coll = collection(db, "characterBasic");
            const q = query(coll, where("user_id", "==", user.uid));
            const snapshot = await getCountFromServer(q);
            return snapshot.data().count;
        } catch (e: any) {
            setError(e.message);
        }

        return 0;
    }

    const convertGearRecordsToGearOwned = (gearRecords: GearRecord[]): GearOwned[] => {
        const gearOwned: GearOwned[] = [];
        gearRecords.forEach((gr) => {
            if (gr.type !== "treasure") {
                let unitsPerQuantity = 1;
                if (gr.type === "sundry") {
                    const theItem = sundries.find((s) => s.id === gr.gearId);
                    if (theItem) {
                        unitsPerQuantity = theItem.quantityPerSlot;
                    }
                }
                for (let q = 1; q <= gr.quantity; q++) {
                    gearOwned.push({
                        instanceId: gr.instanceId,
                        id: gr.gearId,
                        type: gr.type,
                        totalUnits: unitsPerQuantity
                    });
                }
            } else {
                // treasures
                treasureCarried.push({
                    id: gr.instanceId,
                    name: gr.name,
                    desc: "",
                    cost: gr.cost,
                    currency: gr.currency,
                    slots: gr.slots
                })
            }

        })
        return gearOwned;
    }

    //#endregion

    //#region State Hooks

    const levelOne: Level = { level: 1, talentRolledDesc: "", talentRolledName: "", Rolled12TalentOrTwoStatPoints: "", Rolled12ChosenTalentDesc: "", Rolled12ChosenTalentName: "", HitPointRoll: 0, stoutHitPointRoll: 0 };

    const [isLoading, setIsLoading] = useState(false);
    const [isCheatMode, setIsCheatMode] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showForLevelZeroesForm, setShowForLevelZeroesForm] = useState(false);
    const [characterAlreadyExists, setCharacterAlreadyExists] = useState(false);
    const [numCharacters, setNumCharacters] = useState(0);
    const [error, setError] = useState("");

    const [level, setLevel] = useState(1);
    const [levels, setLevels] = useState([levelOne]);
    const [background, setBackground] = useState("");
    const [stats, setStats] = useState({ Strength: 0, Dexterity: 0, Constitution: 0, Intelligence: 0, Wisdom: 0, Charisma: 0 });
    const [ancestry, setAncestry] = useState("");
    const [charClass, setCharClass] = useState("");
    const [name, setName] = useState("");
    const [alignment, setAlignment] = useState("");
    const [deity, setDeity] = useState("");
    const [ambitionTalentLevel, setAmbitionTalentLevel] = useState({ ...levelOne });
    const [bonuses, setBonuses] = useState([] as Bonus[]);
    const [cheats, setCheats] = useState([] as Cheat[]);

    const [goldRolled, setGoldRolled] = useState(-1);
    const [gold, setGold] = useState(-1);
    const [silver, setSilver] = useState(0);
    const [copper, setCopper] = useState(0);
    const [ledger, setLedger] = useState([] as Ledger[])

    const [gearCarried, setGearCarried] = useState([] as GearOwned[]);
    const [treasureCarried, setTreasureCarried] = useState([] as Treasure[]);
    const [magicItemsCarried, setMagicItemsCarried] = useState([] as MagicItem[]);
    const [creationMethod, setCreationMethod] = useState("Design");
    const [dateCreated, setDateCreated] = useState(null as any);
    const [dateLastModified, setDateLastModified] = useState(null as any);

    const [onlyIncludeProficientWeapons, setOnlyIncludeProficientWeapons] = useState(true);
    const [onlyIncludeProficientArmor, setOnlyIncludeProficientArmor] = useState(true);

    const [openWeaponsModal, setOpenWeaponsModal] = useState(false);
    const [openArmorModal, setOpenArmorModal] = useState(false);
    const [openSundriesModal, setOpenSundriesModal] = useState(false);
    const [openTreasureModal, setOpenTreasureModal] = useState(false);
    const [openMagicItemsModal, setOpenMagicItemsModal] = useState(false);
    const [openSourcesModal, setOpenSourcesModal] = useState(false);
    const [openGainLoseCoinsModal, setOpenGainLoseCoinsModal] = useState(false);
    const [openLedgerModal, setOpenLedgerModal] = useState(false);
    const [openSpellbookModal, setOpenSpellbookModal] = useState(false);
    const [openAnimalModal, setOpenAnimalModal] = useState(false);
    const [openFilterAncestriesAndClasssesModal, setOpenFilterAncestriesAndClasssesModal] = useState(false);

    const [showBuyCrawlingKit, setShowBuyCrawlingKit] = useState(true);

    const [pdfType, setPDFType] = useState("Scribbly");
    const [jsonExportType, setJSONExportType] = useState("Clipboard");

    const [randomlySelectedClass, setRandomlySelectedClass] = useState("");
    const [randomlySelectedClassRoll, setRandomlySelectedClassRoll] = useState(-1);

    const [fourCharacters, setFourCharacters] = useState([] as any[]);

    const [rawXP, setRawXP] = useState("0");
    const [XP, setXP] = useState(0);
    const [advancementMode, setAdvancementMode] = useState("XP");

    const [coreRulesOnly, setCoreRulesOnly] = useState(getCoreRulesOnlyFromLocalStorage());
    const [designMode, setDesignMode] = useState(getDesignModeFromLocalStorage());
    const [randomType, setRandomType] = useState(getRandomTypeFromLocalStorage());
    const [minimal, setIsMinimal] = useState(false);
    const [activeSources, setActiveSources] = useState(getActiveSourcesFromLocalStorage());
    const [ancestryFilter, setAncestryFilter] = useState(getActiveSourcesFilteredAncestriesFromLocalStorage());
    const [classFilter, setClassFilter] = useState(getActiveSourcesFilteredClassesFromLocalStorage());
    const [sourcesOrFiltersChanged, setSourcesOrFiltersChanged] = useState(false);

    const setLevelByClass = (charClass: string) => {
        if (charClass === "Level 0") {
            setLevel(0);
        } else {
            setLevel(1);
        }
        setLevels([{ ...levels[0] }])
        setXP(0);
    }

    //#endregion 

    //#region Update State

    const resetCharacter = () => {
        setShowForm(true);
        setIsCheatMode(false);
        setBackground("");
        setStats({ Strength: 0, Dexterity: 0, Constitution: 0, Intelligence: 0, Wisdom: 0, Charisma: 0 });
        setLevels([levelOne]);
        setAncestry("");
        setCharClass("");
        setLevel(1);
        setName("");
        setAlignment("");
        setDeity("");
        setBonuses([] as Bonus[]);
        setGold(-1);
        setGoldRolled(-1);
        setSilver(0);
        setCopper(0);
        setGearCarried([]);
        setLedger([]);
        setRandomlySelectedClass("");
        setRandomlySelectedClassRoll(-1);
        setCheats([]);
        setRawXP("0");
        setXP(0);
    }

    const onSetOnlyIncludeProficientWeapons = (onlyIncludeProficientWeapons: boolean) => {
        setOnlyIncludeProficientWeapons(onlyIncludeProficientWeapons);
    }

    const onSetOnlyIncludeProficientArmor = (onlyIncludeProficientArmor: boolean) => {
        setOnlyIncludeProficientArmor(onlyIncludeProficientArmor);
    }

    // storage of the default sources and ancestry/class filters

    const flipShowSources = (showCoreRules: boolean) => {
        resetCharacter();
        setCoreRulesOnly(!showCoreRules);
        setShowForm(false);
    }

    const flipActiveSource = (sourceId: string) => {
        let updatedActiveSources = [...activeSources];
        if (updatedActiveSources.indexOf(sourceId) === -1) {
            updatedActiveSources.push(sourceId);
            // also reset all the ancestries in the source to unfiltered
            let filteredAncestries = [...ancestryFilter];
            filteredAncestries = filteredAncestries.filter((a) => a.sourceId !== sourceId);
            const sourceAncestries = getAllAncestries().filter((a) => a.sourceId === sourceId);
            sourceAncestries.forEach((sa) => {
                const newEntry: activeSourceFilteredAncestry = { sourceId: sa.sourceId, ancestry: sa.name, isActive: true };
                filteredAncestries.push(newEntry)
            })
            setAncestryFilter(filteredAncestries);
            // also reset all the classes in the source to unfiltered
            let filteredClasses = [...classFilter];
            filteredClasses = filteredClasses.filter((c) => c.sourceId !== sourceId);
            const sourceClasses = getAllCharClasses().filter((c) => c.sourceId === sourceId);
            sourceClasses.forEach((sc) => {
                const newEntry: activeSourceFilteredClass = { sourceId: sc.sourceId, charClass: sc.name, isActive: true };
                filteredClasses.push(newEntry)
            })
            setClassFilter(filteredClasses);
        } else {
            updatedActiveSources = updatedActiveSources.filter((s) => s !== sourceId);
            // also set all the ancestries in the source to filtered
            let filteredAncestries = [...ancestryFilter];
            filteredAncestries.forEach((a) => {
                if (a.sourceId === sourceId) { a.isActive = false; }
            })
            setAncestryFilter(filteredAncestries);
            // also set all the classes in the source to filtered
            let filteredClasses = [...classFilter];
            filteredClasses.forEach((a) => {
                if (a.sourceId === sourceId) { a.isActive = false; }
            })
            setClassFilter(filteredClasses);
        }
        updatedActiveSources.sort();
        setActiveSources(updatedActiveSources);
        setSourcesOrFiltersChanged(true);
    }

    const onSetAncestryFilter = (sourceId: string, ancestry: string) => {
        let filteredAncestries = [...ancestryFilter];
        const theAncestry = filteredAncestries.find((a) => a.sourceId === sourceId && a.ancestry === ancestry);
        if (theAncestry) {
            theAncestry.isActive = !theAncestry.isActive;
        }
        setAncestryFilter(filteredAncestries);
        setSourcesOrFiltersChanged(true);
    }

    const onSetClassFilter = (sourceId: string, charClass: string) => {
        let filteredClasses = [...classFilter];
        const theClass = filteredClasses.find((c) => c.sourceId === sourceId && c.charClass === charClass);
        if (theClass) {
            theClass.isActive = !theClass.isActive;
        }
        setClassFilter(filteredClasses);
        setSourcesOrFiltersChanged(true);
    }

    const onCheckAllAncestriesAndClasses = (checkAll: boolean) => {
        const allAvailAncestries = getAllAncestries().filter((a) => activeSources.indexOf(a.sourceId) !== -1);
        const allAvailClasses = getAllCharClasses().filter((a) => activeSources.indexOf(a.sourceId) !== -1 && a.name !== "Level 0");

        let filterAncestries = allAvailAncestries.map((a) => {
            return { sourceId: a.sourceId, ancestry: a.name, isActive: checkAll };
        })

        let filterClasses = allAvailClasses.map((c) => {
            return { sourceId: c.sourceId, charClass: c.name, isActive: checkAll };
        })

        setAncestryFilter(filterAncestries);
        setClassFilter(filterClasses);
        setSourcesOrFiltersChanged(true);
    }

    const onSaveSettingsAndFilters = () => {
        setActiveSourcesInLocalStorage(activeSources);
        setActiveSourcesFilteredAncestriesInLocalStorage(ancestryFilter);
        setActiveSourcesFilteredClassesInLocalStorage(classFilter);
        setSourcesOrFiltersChanged(false);
    }

    // END: storage of the default sources and ancestyry/class filters

    const flipMinimal = (isMinimal: boolean) => {
        setIsMinimal(!isMinimal);
        setIsMinimalInLocalStorage(isMinimal);
    }

    const updateOrAddBonus = (bonus: Bonus, updatedBonuses: Bonus[], updatedLevels: Level[], updatedAmbitionLevel: Level) => {

        updatedBonuses = ifChangeWarlockPatronRemoveOldPatronBoons(updatedBonuses, bonus);
        updatedBonuses = ifRemoveParentBonusAlsoRemoveChildBonuses(updatedBonuses, bonus);

        const result = whenSetWarlockTalentOrBoonRemoveExistingBoonsOrTalents(updatedBonuses, bonus, updatedLevels, updatedAmbitionLevel);
        updatedBonuses = result.bonuses;
        updatedLevels = result.levels;
        updatedAmbitionLevel = result.humanAmbitionTalentLevel;

        let existingBonus: Bonus | undefined = undefined;

        if (bonus.sourceCategory.indexOf("Boon") !== -1) {
            existingBonus = updatedBonuses.find((b) =>
                b.sourceName === bonus.sourceName &&
                b.sourceType === bonus.sourceType &&
                b.sourceCategory === bonus.sourceCategory &&
                b.gainedAtLevel === bonus.gainedAtLevel &&
                // b.name === bonus.name &&
                b.boonPatron === bonus.boonPatron &&
                b.boonSource === bonus.boonSource
            );
        } else {
            if (bonus.parentBonusId !== undefined) {
                existingBonus = updatedBonuses.find((b) => b.parentBonusId === bonus.parentBonusId);
            } else if (bonus.bonusId !== undefined) {
                existingBonus = updatedBonuses.find((b) => b.bonusId === bonus.bonusId);

            } else {
                existingBonus = updatedBonuses.find((b) =>
                    b.sourceName === bonus.sourceName &&
                    b.sourceType === bonus.sourceType &&
                    b.sourceCategory === bonus.sourceCategory &&
                    b.gainedAtLevel === bonus.gainedAtLevel &&
                    b.name === bonus.name
                );
            }
        }

        // update existing bonus or add new bonus
        if (existingBonus) {
            existingBonus.bonusTo = bonus.bonusTo;
            existingBonus.bonusAmount = bonus.bonusAmount;
            existingBonus.bonusName = bonus.bonusName;
            existingBonus.name = bonus.name;
            existingBonus.choiceOfOptionType = bonus.choiceOfOptionType;
            if (existingBonus.sourceCategory === "Boon") {
                existingBonus.boonPatron = bonus.boonPatron;
                existingBonus.boonSource = bonus.boonSource;
            }
        } else {
            let newBonus: Bonus;
            if (bonus.sourceCategory === "Boon") {
                newBonus = {
                    // includes boon-only members
                    sourceType: bonus.sourceType,
                    sourceName: bonus.sourceName,
                    sourceCategory: bonus.sourceCategory,
                    name: bonus.name,
                    bonusName: bonus.bonusName,
                    bonusTo: bonus.bonusTo,
                    gainedAtLevel: bonus.gainedAtLevel,
                    boonPatron: bonus.boonPatron,
                    boonSource: bonus.boonSource
                }

            } else {
                newBonus = {
                    // exceludes boon-only members
                    sourceType: bonus.sourceType,
                    sourceName: bonus.sourceName,
                    sourceCategory: bonus.sourceCategory,
                    name: bonus.name,
                    bonusName: bonus.bonusName,
                    bonusTo: bonus.bonusTo,
                    gainedAtLevel: bonus.gainedAtLevel
                }
                if (bonus.parentBonusId !== undefined) {
                    newBonus.parentBonusId = bonus.parentBonusId
                    newBonus.bonusId = uniqid();
                }
                if (bonus.bonusId !== undefined) { newBonus.bonusId = bonus.bonusId }
            }
            if (bonus.bonusAmount) { newBonus.bonusAmount = bonus.bonusAmount; }
            updatedBonuses.push(newBonus);
        }
        return { updatedBonuses, updatedLevels, updatedAmbitionLevel };
    }

    const onSetBonus = (bonus: Bonus) => {

        let updatedBonuses = [...bonuses];
        let updatedLevels = [...levels];
        let updatedAmbitionLevel = { ...ambitionTalentLevel };

        updatedBonuses = removeAnyduplicateRollTwoAndPickOneBoons(bonus, updatedBonuses);
        // updatedBonuses = removeBlackLotusTalentIfNoGrantSpecialTalent(updatedBonuses);

        const result = updateOrAddBonus(bonus, updatedBonuses, updatedLevels, updatedAmbitionLevel);
        updatedBonuses = result.updatedBonuses;
        updatedLevels = result.updatedLevels;
        updatedAmbitionLevel = result.updatedAmbitionLevel;

        updatedBonuses = removeAnyCastWithAdvIfDoNotKnowSpell(updatedBonuses, charClasses, charClass, spells);
        updatedBonuses = renumberSpellsLearnedFromScrolls(updatedBonuses);
        updatedBonuses = renumberAnimalCompanions(updatedBonuses);

        setBonuses(updatedBonuses);
        setLevels(updatedLevels);
        setAmbitionTalentLevel(updatedAmbitionLevel);
    }

    const onSetBonuses = (theNewBonuses: Bonus[]) => {

        let updatedBonuses = [...bonuses];
        let updatedLevels = [...levels];
        let updatedAmbitionLevel = { ...ambitionTalentLevel };

        theNewBonuses.forEach((bonus) => {

            updatedBonuses = removeAnyduplicateRollTwoAndPickOneBoons(bonus, updatedBonuses);
            // updatedBonuses = removeBlackLotusTalentIfNoGrantSpecialTalent(updatedBonuses);

            const result = updateOrAddBonus(bonus, updatedBonuses, updatedLevels, updatedAmbitionLevel);
            updatedBonuses = result.updatedBonuses;
            updatedLevels = result.updatedLevels;
            updatedAmbitionLevel = result.updatedAmbitionLevel;
        })

        updatedBonuses = removeAnyCastWithAdvIfDoNotKnowSpell(updatedBonuses, charClasses, charClass, spells);
        updatedBonuses = renumberSpellsLearnedFromScrolls(updatedBonuses);
        updatedBonuses = renumberAnimalCompanions(updatedBonuses);

        setBonuses(updatedBonuses);
        setLevels(updatedLevels);
        setAmbitionTalentLevel(updatedAmbitionLevel);
    }

    const updateDesignMode = (designMode: string) => {
        resetCharacter();
        setShowForm(false);
        setShowForLevelZeroesForm(false);
        setDesignMode(designMode);
        setCreationMethod(designMode);
        setDesignModeInLocalStorage(designMode);
    }

    const updateRandomType = (randomType: string) => {
        resetCharacter();
        setShowForm(false);
        setShowForLevelZeroesForm(false);
        setRandomType(randomType);
        setRandomTypeInLocalStorage(randomType);
    }

    //#endregion

    //#region Source data

    const getSources = () => {
        if (coreRulesOnly) { return sources.filter((s) => s.isOfficial === true) }
        return sources;
    }
    const ancestries = getAllAncestries();
    const availableAncestries = ancestries.filter((anc) => ancestryFilter.filter((a) => a.isActive).map((a) => a.ancestry).indexOf(anc.name) !== -1);

    const charClasses = getAllCharClasses();

    const armors = getArmors(getSources());
    const backgrounds = getAllBackgrounds();

    const deities = getAllDeities();
    const languages = getAllLanguages();

    const finalStats = getStatsIncludingBonuses(stats, bonuses, magicItemsCarried);
    const totalHitPoints = getTotalHitPoints(level, levels, ancestry, charClass, charClasses, finalStats, bonuses);


    //#endregion 

    //#region Randomly roll for Background, Stats, Ancestry, Class, Hit Points, Name, Alignment, Deity, Gold.

    const updateCheats = (theField: string, level: number, desc: string, order: number) => {
        let updatedCheats = [...cheats];

        const theCheat = updatedCheats.find((c) => c.theField === theField && c.level === level);
        if (theCheat) {
            theCheat.desc = desc;
        } else {
            const newCheat: Cheat = { theField: theField, level: level, desc: desc, order: order };
            updatedCheats.push(newCheat);
        }

        updatedCheats = updatedCheats.sort((a, b) => a.order < b.order ? -1 : 1);

        setCheats(updatedCheats);
    }

    const onRollBackground = (sourceId: string, setBackgroundInState: boolean = true) => {
        let availableBackgrounds: Background[] = [];
        if (sourceId !== "") {
            availableBackgrounds = backgrounds.filter((c) => c.sourceId === sourceId);
        } else {
            availableBackgrounds = backgrounds.filter((bg) => activeSources.indexOf(bg.sourceId) !== -1);
        }
        const background = getRandomBackground(availableBackgrounds).name;
        if (setBackgroundInState) { setBackground(background); }
        return background;
    }

    const onSelectBackground = (background: string) => {
        setBackground(background);
        updateCheats("Background", level, "Background set to " + (background !== "" ? background : "No Background"), 1);
        return background;
    }

    const onRollStats = (ensureAtLeastOneis14: boolean, setStatsInState: boolean = true): Stats => {
        let newStats = getRandomStats(ensureAtLeastOneis14, false);
        if (setStatsInState) { setStats(newStats); }
        return newStats;
    }

    const onSelectStats = (newStats: Stats): Stats => {
        setStats(newStats);
        updateCheats("Stats", level, "Stats set to STR " + newStats.Strength + ", DEX " + newStats.Dexterity + ", CON " + newStats.Constitution + ", INT " + newStats.Intelligence + ", WIS " + newStats.Wisdom + ", CHA " + newStats.Charisma, 2);
        return newStats;
    }

    const onRollAncestry = (alsoGenerateRandomName: boolean = false, theClass: string, coreOnly: boolean = false, setInState: boolean = true): any => {

        let randomAncestry = "";
        let randomName = "";
        if (availableAncestries.length === 1) {
            randomAncestry = availableAncestries[0].name; // only one ancestry use that
        } else {
            do {
                randomAncestry = getRandomAncestry(availableAncestries, availableClasses, theClass);
            } while (randomAncestry === ancestry);
        }

        if (hardcodedAncestry !== "") { randomAncestry = hardcodedAncestry; }

        if (alsoGenerateRandomName) {
            randomName = getRandomName(randomAncestry);
            if (setInState) { setName(randomName); }
        }

        deleteAllBonuses("Ancestry");

        if (setInState) { setAncestry(randomAncestry); }

        return { ancestry: randomAncestry, name: randomName };
    }

    const onRollClass = () => {
        deleteAllChosenTalents();
        deleteAllLevelHitPoints(ancestry, true);

        let randomClass = "";
        if (availableClasses.length === 1) {
            randomClass = availableClasses[0].name; // only one class avilable so use that
        } else {
            do {
                randomClass = getRandomClass(availableClasses);
            } while (randomClass === charClass);
        }

        if (hardcodedClass !== "") { randomClass = hardcodedClass; }

        setCharClass(randomClass);
        setLevelByClass(randomClass);
        setRandomlySelectedClass(randomClass);
        setRandomlySelectedClassRoll(-100);
    }

    const onRollHitDie = (level: number, hitDie: number) => {
        const updateLevels = [...levels];
        const theLevel = updateLevels.find((l) => l.level === level);
        if (theLevel) {
            const roll = getRandomIntInclusive(1, hitDie);
            theLevel.HitPointRoll = roll;
            if (ancestry === "Dwarf") {
                const stoutRoll = getRandomIntInclusive(1, hitDie);
                theLevel.stoutHitPointRoll = stoutRoll;
            } else {
                theLevel.stoutHitPointRoll = 0;
            }
            setLevels(updateLevels);
        }
    }

    const onSetHitDie = (level: number, hitPoints: number) => {
        const updateLevels = [...levels];
        const theLevel = updateLevels.find((l) => l.level === level);
        if (theLevel) {
            theLevel.HitPointRoll = hitPoints;
            if (ancestry === "Dwarf") {
                theLevel.stoutHitPointRoll = hitPoints;
            } else {
                theLevel.stoutHitPointRoll = 0;
            }
            setLevels(updateLevels);
            updateCheats("HitPointsLevel" + level, level, "Hit Points rolled at level " + level + " set to " + hitPoints, 5);
        }
    }

    const onMaximiseHitDieRoll = (level: number, maximiseHitDieRoll: boolean) => {
        const updateLevels = [...levels];
        const theLevel = updateLevels.find((l) => l.level === level);
        if (theLevel) {
            theLevel.rollIsMaximised = maximiseHitDieRoll;
            setLevels(updateLevels);
        }
    }

    const onRollName = () => {
        let name = getRandomName(ancestry);
        setName(name);
    }

    const onRollAlignment = (alsoSelectRandomDeity: boolean = false, setInState: boolean = true) => {
        let thisAlignment = "";
        let thisDeity = "";
        do {
            thisAlignment = getRandomAlignment();
        } while (alignment === thisAlignment);

        if (alsoSelectRandomDeity) {
            thisDeity = onRollDeity(thisAlignment, setInState);
        } else {
            if (setInState) { setAlignment(thisAlignment); }
        }

        return { alignment: thisAlignment, deity: thisDeity };
    }

    const onRollDeity = (alignmentToMatch: string, setInState: boolean = true) => {

        const availableDeities = deities.filter((a) => activeSources.indexOf(a.sourceId) !== -1);

        let theDeity: any = null;
        do {
            theDeity = getRandomDeity(availableDeities, alignmentToMatch, false);
        } while (theDeity.name === deity)

        if (theDeity.name === deity) {
            onRollDeity(alignmentToMatch, setInState);
        } else {
            if (alignmentToMatch) {
                if (setInState) { setAlignment(alignmentToMatch) };
            }
            if (setInState) { setDeity(theDeity.name); }
        }

        return theDeity.name;
    }

    const onRollGold = () => {
        const gold = (getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6)) * 5;
        setGold(gold);
        setGoldRolled(gold);

        const newLedgerRecord: Ledger = { goldChange: gold, silverChange: 0, copperChange: 0, desc: "Starting gold", notes: "" };
        let updatedLedger = [...ledger];
        updatedLedger.push(newLedgerRecord);
        setLedger(updatedLedger);

        return gold;
    }

    const onSetGold = (gold: number) => {
        setGoldRolled(gold);
        if (goldRolled === -1) {
            setGold(gold);
        }

        const newLedgerRecord: Ledger = { goldChange: gold, silverChange: 0, copperChange: 0, desc: "Starting gold", notes: "" };
        let updatedLedger = [...ledger];
        updatedLedger.push(newLedgerRecord);
        setLedger(updatedLedger);

        updateCheats("GoldRolled" + level, level, "Gold rolled set to " + gold + " gp", 10);

        return gold;
    }

    const onRollLevelOneItems = () => {
        setGold(0);
        setGoldRolled(0);
        const randomGear = getRandomLevelOneItems();
        setGearCarried(randomGear);

        addAllGearToLedger(randomGear, true);
    }

    //#endregion

    //#region Manually select Ancestry, Class, Name, Alignment and Deity.

    const onSelectAncestry = (thisAncestry: string) => {
        deleteAllBonuses("Ancestry");
        deleteAllAncestryCheats();
        deleteAmbitionTalentLevel();
        deleteAllLevelHitPoints(thisAncestry, false);
        setAncestry(thisAncestry);
    }

    const onSelectClass = (charClass: string) => {
        deleteAllBonuses("Class");
        deleteAllClassCheats();
        deleteAllChosenTalents();
        deleteAmbitionTalentLevel();
        deleteAllLevelHitPoints(ancestry, true);
        setCharClass(charClass);
        setLevelByClass(charClass);
    }

    const onSelectName = (name: string) => {
        setName(name);
    }

    const onSelectAlignment = (alignment: string) => {
        setAlignment(alignment);
    }

    const onSelectDeity = (deity: string) => {
        setDeity(deity);
    }

    //#endregion

    //#region Randomly generate characters

    const randomCharacter_LevelOne = (hardCodeINTForPlagueDoc: boolean) => {
        setShowForLevelZeroesForm(false);

        resetCharacter();
        deleteAllChosenTalents();
        deleteAllLevelHitPoints(ancestry, true);

        const roustaboutSpells = getRoustaboutSpellList(availableClasses, 1);

        const randChar = getRandomCharacter_LevelOne(
            activeSources,
            randomType,
            [levelOne],
            { ...levelOne },
            backgrounds,
            availableAncestries,
            languages,
            deities,
            weapons,
            armors,
            sundries,
            spells,
            availableClasses,
            availableWarlockPatrons,
            availablePatronBoons,
            animals,
            duckAbilities,
            dogAbilities,
            hardcodedAncestry,
            hardcodedClass,
            hardCodeINTForPlagueDoc,
            roustaboutSpells
        );

        setName(randChar.name);
        setBackground(randChar.background);
        setAncestry(randChar.ancestry);
        setCharClass(randChar.charClass);
        setLevelByClass(randChar.charClass);
        setStats(randChar.stats);
        setAlignment(randChar.alignment);
        setDeity(randChar.deity);

        setLevels(randChar.levels);
        setAmbitionTalentLevel(randChar.ambitionTalentLevel);
        setBonuses(randChar.bonuses);

        setGearCarried(randChar.gear);
        setGoldRolled(randChar.goldRolled);
        setGold(randChar.gold);
        setSilver(randChar.silver);
        setCopper(randChar.copper);
        setShowBuyCrawlingKit(false);

        setCreationMethod("Random 1");

        addAllGearToLedger(randChar.gear, false);
    }

    const randomCharacter_LevelZero = () => {
        setShowForLevelZeroesForm(false);

        resetCharacter();
        deleteAllChosenTalents();
        deleteAllLevelHitPoints(ancestry, true);

        onRollBackground("");
        onRollStats(true);
        const theAncestry = onRollAncestry(true, "Level 0");
        setCharClass("Level 0");
        setLevel(0);
        onRollAlignment(true);

        const availableLanguages = languages.filter((a) => activeSources.indexOf(a.sourceId) !== -1);
        const ancestryBonuses = assignAncestryBonuses(theAncestry.ancestry, "Level 0", availableLanguages, duckAbilities, duckQuackstories, dogAbilities, dogsLifes);
        setBonuses([...ancestryBonuses]);
        setCreationMethod("Random 0");

        onRollLevelOneItems();
        setShowBuyCrawlingKit(false);
    }

    const randomCharacter_FourLevelZeroes = () => {

        const fourCharacters: any[] = [];

        const availableLanguages = languages.filter((a) => activeSources.indexOf(a.sourceId) !== -1);

        for (let c = 0; c < 4; c++) {

            const theBackground = onRollBackground("", false);
            const theStats = onRollStats(true, false);
            const theAncestryAndName = onRollAncestry(true, "Level 0", true, false);
            const theAncestry = theAncestryAndName.ancestry;
            const theHP = getTotalHitPoints(0, [], theAncestry, charClass, charClasses, theStats, bonuses);
            const theName = theAncestryAndName.name;

            const getDeity = getRandomIntInclusive(1, 2) === 1 ? true : false
            const theAlignmentAndDeity = onRollAlignment(getDeity, false);

            const theAlignment = theAlignmentAndDeity.alignment;
            const theDeity = theAlignmentAndDeity.deity;

            const theRandomGear = getRandomLevelOneItems();
            const theAC = getAC(theRandomGear, armors, [], theStats, magicItemsCarried, ancestries, ancestry, charClasses, charClass);
            const ancestryBonuses = assignAncestryBonuses(theAncestry, "Level 0", languages, duckAbilities, duckQuackstories, dogAbilities, dogsLifes);
            const theAttacks = getWeaponAttacks(armors, theRandomGear, theStats, ancestries, theAncestry, ancestryBonuses, 0, "Level 0", charClasses, magicItemsCarried, getDemonicPossessionBonus(charClasses, charClass, level, bonuses));
            const theLanguagesKnown = getAllLanguagesKnown(availableLanguages, ancestries, theAncestry, ancestryBonuses, undefined);

            const character: any = {
                name: theName,
                background: theBackground,
                ancestry: theAncestry,
                stats: theStats,
                hp: theHP,
                ac: theAC,
                alignment: theAlignment,
                deity: theDeity,
                gear: theRandomGear,
                attacks: theAttacks,
                armors: armors,
                bonuses: ancestryBonuses,
                languagesKnown: theLanguagesKnown,
                gp: 0,
                sp: 0,
                cp: 0
            }
            fourCharacters.push(character);
        }

        setFourCharacters(fourCharacters);

        setShowForm(false);
        setAncestry("");
        setCharClass("");
        setShowForLevelZeroesForm(true);
    }


    //#endregion

    //#region Remove levels, bonuses, talents, hit points, etc. 

    const deleteAmbitionTalentLevel = () => {
        setAmbitionTalentLevel({ ...levelOne });
    }

    const deleteAllBonuses = (type: string) => {
        let updatedBonuses = [...bonuses];
        updatedBonuses = updatedBonuses.filter((b) => b.sourceType !== type);
        updatedBonuses = updatedBonuses.filter((b) => b.sourceName !== "Human Ambition");
        setBonuses(updatedBonuses);
    }

    const deleteAllAncestryCheats = () => {
        let updatedCheats = [...cheats];
        updatedCheats = updatedCheats.filter((b) => b.theField.indexOf("Human AmbitionTalent") === -1);
        setCheats(updatedCheats);
    }

    const deleteAllClassCheats = () => {
        let updatedCheats = [...cheats];
        updatedCheats = updatedCheats.filter((b) => b.theField.indexOf("ClassTalent") === -1);
        updatedCheats = updatedCheats.filter((b) => b.theField.indexOf("HitPointsLevel") === -1);
        setCheats(updatedCheats);
    }

    const deleteAllChosenTalents = () => {
        let theLevels = [...levels];
        theLevels.forEach((l) => {
            l.talentRolledDesc = "";
            l.talentRolledName = "";
            l.Rolled12ChosenTalentDesc = "";
            l.Rolled12ChosenTalentName = "";
            l.Rolled12TalentOrTwoStatPoints = "";
        })
        setLevels(theLevels);
    }

    const deleteAllLevelHitPoints = (theAncestry: string, deleteAllRolls: boolean) => {
        let theLevels = [...levels];
        theLevels.forEach((l) => {
            l.stoutHitPointRoll = 0;
            if (deleteAllRolls) {
                l.HitPointRoll = 0;
            }
        })
        // if changing to Dwarf, add the stoutHitPointRoll to every level
        if (theAncestry === "Dwarf" && !deleteAllRolls) {
            const thisClass = getAllCharClasses().find((c) => c.name === charClass);
            if (thisClass) {
                theLevels.forEach((l) => {
                    l.stoutHitPointRoll = getRandomIntInclusive(1, thisClass.hitDie);
                })
            }
        }
        setLevels(theLevels);
    }

    //#endregiontHPNoteForLevelOne

    //#region Languages

    const getAllLanguagesKnownByCharacter = () => {
        return getAllLanguagesKnown(languages, ancestries, ancestry, bonuses, charClass);
    }

    const formatLanguagesKnownAsList = () => {
        const langs = getAllLanguagesKnownByCharacter();
        if (langs.length === 0) { return "None"; }
        return langs.map((l) => l.name).join(", ")
    }

    const formatSpellsKnownAsList = () => {
        const langs = getAllSpellsKnown(charClasses, charClass, spells, bonuses);
        if (langs.length === 0) { return "None"; }
        return langs.map((l) => l.name).join(", ")
    }

    //#endregion

    //#region Set bonuses and talents

    const setRolledTalent = (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean, parentBonusId?: string) => {

        if (parentBonusId !== undefined) {
            const theClass = charClasses.find((c) => c.name === charClass);
            if (theClass) {
                const theTalent = theClass.talents.find((t) => t.name === talentRolledName);
                const theTalentOptions: string[] = theTalent && theTalent.options ? theTalent.options : [];

                let sourceType = "Class";
                let sourceName = charClass;
                if (isAmbitionTalent) {
                    sourceType = "Ancestry";
                    sourceName = "Human Ambition"
                }
                const updatedBonuses = utilAddTalentBonuses(talentRolledName, bonuses, sourceType, sourceName, "Talent", level, false, charClass, charClasses, theTalentOptions, parentBonusId);
                setBonuses(updatedBonuses);
                if (isCheatMode) {
                    // TODO fix these cheat notes for recursive Rousatbout talents
                    if (!isAmbitionTalent) {
                        updateCheats("ClassTalent" + level, level, charClass + " Talent for level " + level + " set to '" + talentRolledDesc + "'", 4);
                    } else {
                        updateCheats("Human AmbitionTalent" + level, level, "Human Ambition Talent for level " + level + " set to '" + talentRolledDesc + "'", 4);
                    }
                }
            }
        } else if (!isAmbitionTalent) {
            let theLevels = [...levels];
            let thisLevel = theLevels.find((l) => l.level === level);
            if (thisLevel) {
                thisLevel.talentRolledDesc = talentRolledDesc;
                thisLevel.talentRolledName = talentRolledName;
                setLevels(theLevels);

                const theClass = charClasses.find((c) => c.name === charClass);
                if (theClass) {
                    const theTalent = theClass.talents.find((t) => t.name === talentRolledName);
                    const theTalentOptions: string[] = theTalent && theTalent.options ? theTalent.options : [];
                    const updatedBonuses = utilAddTalentBonuses(talentRolledName, bonuses, "Class", charClass, "Talent", level, false, charClass, charClasses, theTalentOptions, parentBonusId);
                    setBonuses(updatedBonuses);
                    if (isCheatMode) {
                        updateCheats("ClassTalent" + level, level, charClass + " Talent for level " + level + " set to '" + talentRolledDesc + "'", 4);
                    }
                }
            }
        } else {
            const ambitionTalLev = { ...ambitionTalentLevel };
            ambitionTalLev.talentRolledName = talentRolledName;
            ambitionTalLev.talentRolledDesc = talentRolledDesc;
            setAmbitionTalentLevel(ambitionTalLev);

            const theClass = charClasses.find((c) => c.name === charClass);
            if (theClass) {
                const theTalent = theClass.talents.find((t) => t.name === talentRolledName);
                const theTalentOptions: string[] = theTalent && theTalent.options ? theTalent.options : [];
                const updatedBonuses = utilAddTalentBonuses(talentRolledName, bonuses, "Ancestry", "Human Ambition", "Talent", level, false, charClass, charClasses, theTalentOptions, parentBonusId);
                setBonuses(updatedBonuses);
                if (isCheatMode) {
                    updateCheats("Human AmbitionTalent" + level, level, "Human Ambition Talent for level " + level + " set to '" + talentRolledDesc + "'", 4);
                }
            }
        }
    }

    const setSpecialTalent = (level: number, talentName: string, specialTalentCategory: string) => {

        let updatedBonuses: Bonus[] = [...bonuses];

        if (specialTalentCategory.indexOf("HumanAmbition") !== -1) {
            updatedBonuses = utilAddTalentBonuses(talentName, updatedBonuses, "Ancestry", "Human Ambition", specialTalentCategory, level, false, charClass, charClasses);
        } else {
            updatedBonuses = utilAddTalentBonuses(talentName, updatedBonuses, "Class", charClass, specialTalentCategory, level, false, charClass, charClasses);
        }
        setBonuses(updatedBonuses);
    }

    const onSetRolled12TalentOrTwoStatPoints = (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => {
        if (isBoon) {
            // Boon
            let patron = getWarlockPatron();
            let updatedBonuses = [...bonuses];
            let theBoon: Bonus | undefined = undefined;
            if (isAmbitionTalent) {
                theBoon = updatedBonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Human Ambition" && b.sourceCategory === "Boon" && b.boonPatron === patron && b.boonSource === boonSource && b.gainedAtLevel === level);
            } else {
                theBoon = updatedBonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Boon" && b.boonPatron === patron && b.boonSource === boonSource && b.gainedAtLevel === level);
            }
            if (theBoon) {
                theBoon.bonusTo = choice;
            } else {
                console.log("choice boon not found")
            }
            setBonuses(updatedBonuses);
        } else if (!isAmbitionTalent) {
            // Standard talent
            let theLevels = [...levels];
            let thisLevel = theLevels.find((l) => l.level === level);
            if (thisLevel) {
                thisLevel.Rolled12TalentOrTwoStatPoints = choice;
                setLevels(theLevels);
            }
        } else {
            // Humna Ambition Talent
            const ambitionTalLev = { ...ambitionTalentLevel };
            ambitionTalLev.Rolled12TalentOrTwoStatPoints = choice;
            setAmbitionTalentLevel(ambitionTalLev);
        }

    }

    //#endregion

    //#region Gear slots

    const getTotalGearSlots = () => {
        return calculateTotalGearSlots(finalStats, charClass);
    }

    const getTotalGearSlotsUsed = () => {
        const allGearRecords = getGearRecords(gearCarried, treasureCarried, magicItemsCarried, gold, silver, copper, armors, true);
        return allGearRecords.reduce((accumulator, currentValue) => accumulator + currentValue.slots, 0);
    }

    const gearSlotsUsed = getTotalGearSlotsUsed();
    const gearSlotsTotal = getTotalGearSlots();

    //#endregion

    //#region Buying items

    const onBuyItem = (id: string, type: string, isBuy: boolean, cost: number, currency: string) => {

        const newLedgerRecord: Ledger = { goldChange: 0, silverChange: 0, copperChange: 0, desc: "", notes: "" };

        let buyResult = handleBuyItem(id, type, isBuy, cost, currency, gold, silver, copper, gearCarried);

        if (buyResult.gold !== gold) { setGold(buyResult.gold) }
        if (buyResult.silver !== silver) { setSilver(buyResult.silver) }
        if (buyResult.copper !== copper) { setCopper(buyResult.copper) }

        setGearCarried(buyResult.gear);

        let goldCost = currency === "gp" ? cost : 0;
        let silverCost = currency === "sp" ? cost : 0;
        let copperCost = currency === "cp" ? cost : 0;

        if (buyResult.gold !== gold) { newLedgerRecord.goldChange = goldCost * -1; }
        if (buyResult.silver !== silver) { newLedgerRecord.silverChange = silverCost * -1; }
        if (buyResult.copper !== copper) { newLedgerRecord.copperChange = copperCost * -1; }

        const gear = getGearById(id);
        if (gear) {
            let desc = "";
            if (isBuy) {
                desc = "Buy " + gear.name;
            } else {
                desc = "Sell " + gear.name;
            }
            newLedgerRecord.desc = desc;

            let updatedLedger = [...ledger];
            updatedLedger.push(newLedgerRecord);
            setLedger(updatedLedger);
        }

    }

    const onBuyCrawlingKit = (buyKit: boolean) => {
        // setShowBuyGear(true);

        if (buyKit) {
            const buyKitResult = handleBuyCrawlingKit(gold);
            setGold(buyKitResult.gold);
            setGearCarried(buyKitResult.gear);

            addAllGearToLedger(buyKitResult.gear, false);

        }
        setShowBuyCrawlingKit(false);
    }

    const addAllGearToLedger = (gearOwned: GearOwned[], stuffIsFree: boolean, startingGold?: number) => {
        let updatedLedger = [...ledger];

        if (startingGold && startingGold !== -1) {
            const newLedgerRecord: Ledger = { goldChange: startingGold, silverChange: 0, copperChange: 0, desc: "Starting gold", notes: "" };
            updatedLedger.push(newLedgerRecord);
        }

        gearOwned.forEach((g) => {
            const gear = getGearById(g.id);
            if (gear) {
                let gp = 0;
                let sp = 0;
                let cp = 0;
                let desc = "";
                if (!stuffIsFree) {
                    if (gear.currency === "gp") { gp = gear.cost * -1; }
                    if (gear.currency === "sp") { sp = gear.cost * -1; }
                    if (gear.currency === "cp") { cp = gear.cost * -1; }
                    desc = "Buy " + gear.name;
                } else {
                    desc = "Start with " + gear.name;
                }

                const newLedgerRecord: Ledger = { goldChange: gp, silverChange: sp, copperChange: cp, desc: desc, notes: "" };
                updatedLedger.push(newLedgerRecord);
            }
        });
        setLedger(updatedLedger);
        return updatedLedger;
    }

    const handleSetAdditionalCoins = (addedGold: number, addedSilver: number, addedCopper: number, notes: string) => {
        if (addedGold !== 0 || addedSilver !== 0 || addedCopper !== 0) {

            if (addedGold !== 0) { setGold(gold + addedGold); }
            if (addedSilver !== 0) { setSilver(silver + addedSilver); }
            if (addedCopper !== 0) { setCopper(copper + addedCopper); }

            let addCoins = addedGold > 0 || addedSilver > 0 || addedCopper > 0;

            let desc = "Gained coins";
            if (!addCoins) { desc = "Lost coins" }

            const newLedgerRecord: Ledger = { goldChange: addedGold, silverChange: addedSilver, copperChange: addedCopper, desc: desc, notes: notes.trim() };
            let updatedLedger = [...ledger];
            updatedLedger.push(newLedgerRecord);
            setLedger(updatedLedger);

        }
        onCloseModal("gainLoseCoins");
    }

    const onAddTreasure = (name: string, desc: string, cost: number, currency: string, slots: number) => {
        const newTreasure: Treasure = { id: uniqid(), name: name, desc: desc, cost: cost, currency: currency, slots: slots };
        const updatedTreasuredCarried = [...treasureCarried];
        updatedTreasuredCarried.push(newTreasure);
        updatedTreasuredCarried.sort((t1, t2) => t1.name < t2.name ? -1 : 1);
        setTreasureCarried(updatedTreasuredCarried);
    }

    const onEditTreasure = (id: string, name: string, desc: string, cost: number, currency: string, slots: number) => {
        const updatedTreasuredCarried = [...treasureCarried];
        const theTreasure = updatedTreasuredCarried.find((t) => t.id === id);
        if (theTreasure) {
            theTreasure.name = name;
            theTreasure.desc = desc;
            theTreasure.cost = cost;
            theTreasure.currency = currency;
            theTreasure.slots = slots;
            updatedTreasuredCarried.sort((t1, t2) => t1.name < t2.name ? -1 : 1);
            setTreasureCarried(updatedTreasuredCarried);
        }
    }

    const onDiscardTreasure = (id: string) => {
        let updatedTreasuredCarried = [...treasureCarried];
        updatedTreasuredCarried = updatedTreasuredCarried.filter((t) => t.id !== id);
        setTreasureCarried(updatedTreasuredCarried);
    }

    const onSellTreasure = (id: string) => {

        let updatedTreasuredCarried = [...treasureCarried];
        const theTreasure = updatedTreasuredCarried.find((t) => t.id === id);
        if (theTreasure) {

            const newLedgerRecord: Ledger = { goldChange: 0, silverChange: 0, copperChange: 0, desc: "", notes: "" };

            const cost = theTreasure.cost;
            const currency = theTreasure.currency;

            if (currency === "gp") {
                setGold(gold + cost);
                newLedgerRecord.goldChange = cost;
            }
            if (currency === "sp") {
                setSilver(silver + cost);
                newLedgerRecord.silverChange = cost;
            }
            if (currency === "cp") {
                setCopper(copper + cost);
                newLedgerRecord.copperChange = cost;
            }
            newLedgerRecord.desc = "Sell " + theTreasure.name;

            updatedTreasuredCarried = updatedTreasuredCarried.filter((t) => t.id !== id);

            setTreasureCarried(updatedTreasuredCarried);
            updatedTreasuredCarried.sort((t1, t2) => t1.name < t2.name ? -1 : 1);

            let updatedLedger = [...ledger];
            updatedLedger.push(newLedgerRecord);
            setLedger(updatedLedger);
        }
    }

    const onAddMagicItem = (itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string, properties: string[]) => {
        const newItem: MagicItem = { id: uniqid(), itemType, itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, personalityVirtue: virtue, personalityFlaws: flaw, personalityTraits: trait, properties: properties };
        const updatedMagicItemsCarried = [...magicItemsCarried];
        updatedMagicItemsCarried.push(newItem);
        updatedMagicItemsCarried.sort((t1, t2) => t1.name < t2.name ? -1 : 1);
        setMagicItemsCarried(updatedMagicItemsCarried);
    }

    const onEditMagicItem = (id: string, itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string) => {
        const updatedMagicItemsCarried = [...magicItemsCarried];
        const theItem = updatedMagicItemsCarried.find((t) => t.id === id);
        if (theItem) {
            theItem.itemType = itemType;
            theItem.itemTypeId = itemTypeId;
            theItem.magicItemType = magicItemType;
            theItem.name = name;
            theItem.bonus = bonus;
            theItem.bonusNote = bonusNote;
            theItem.attackNote = attackNote;
            theItem.features = features;
            theItem.benefits = benefits;
            theItem.curses = curses;
            theItem.hasPersonality = hasPersonality;
            if (theItem.hasPersonality) {
                theItem.personalityVirtue = virtue;
                theItem.personalityFlaws = flaw;
                theItem.personalityTraits = trait;
            } else {
                theItem.personalityVirtue = undefined;
                theItem.personalityFlaws = undefined;
                theItem.personalityTraits = undefined;
            }
            updatedMagicItemsCarried.sort((t1, t2) => t1.name < t2.name ? -1 : 1);
            setMagicItemsCarried(updatedMagicItemsCarried);
        }
    }

    const onDiscardMagicItem = (id: string) => {
        let updatedMagicItemsCarried = [...magicItemsCarried];
        updatedMagicItemsCarried = updatedMagicItemsCarried.filter((t) => t.id !== id);
        setMagicItemsCarried(updatedMagicItemsCarried);
    }

    //#endregion

    //#region Modals

    const onCloseModal = (modalName: string) => {
        if (modalName === "weapons") { setOpenWeaponsModal(false) }
        if (modalName === "armor") { setOpenArmorModal(false) }
        if (modalName === "sundries") { setOpenSundriesModal(false) }
        if (modalName === "treasure") { setOpenTreasureModal(false) }
        if (modalName === "magicItems") { setOpenMagicItemsModal(false) }
        if (modalName === "sources") { setOpenSourcesModal(false) }
        if (modalName === "gainLoseCoins") { setOpenGainLoseCoinsModal(false) }
        if (modalName === "ledger") { setOpenLedgerModal(false) }
        if (modalName === "spellbook") { setOpenSpellbookModal(false) }
        if (modalName === "animals") { setOpenAnimalModal(false) }
        if (modalName === "filterAncestriesAndClasses") { setOpenFilterAncestriesAndClasssesModal(false) }
    }

    const onOpenModal = (modalName: string) => {
        if (modalName === "weapons") { setOpenWeaponsModal(true) }
        if (modalName === "armor") { setOpenArmorModal(true) }
        if (modalName === "sundries") { setOpenSundriesModal(true) }
        if (modalName === "treasure") { setOpenTreasureModal(true) }
        if (modalName === "magicItems") { setOpenMagicItemsModal(true) }
        if (modalName === "sources") { setOpenSourcesModal(true) }
        if (modalName === "gainLoseCoins") { setOpenGainLoseCoinsModal(true) }
        if (modalName === "ledger") { setOpenLedgerModal(true) }
        if (modalName === "spellbook") { setOpenSpellbookModal(true) }
        if (modalName === "animals") { setOpenAnimalModal(true) }
        if (modalName === "filterAncestriesAndClasses") { setOpenFilterAncestriesAndClasssesModal(true) }
    }

    //#endregion

    //#region Print character

    const printCharacter = async () => {
        let theClass = charClasses.find((c) => c.name === charClass);
        let theAncestry = ancestries.find((c) => c.name === ancestry);

        const noName = (name: string) => { return name.trim() === "" ? "Nameless" : name.trim(); }
        const title = getTitle(charClass, alignment, level)

        const spellCastingBonus = getSpellCastingBonus(charClasses, charClass, finalStats, bonuses);
        const demonicPossessionBonus = getDemonicPossessionBonus(charClasses, charClass, level, bonuses);

        if (pdfType === "Scribbly" || pdfType === "High legibility") {
            const highLegibility = pdfType === "High legibility";
            const pdfBytes = await getPDF(name, theAncestry, finalStats, XP, level, title, alignment, background, deity, formatLanguagesKnownAsList(), getAllSpellsKnown(charClasses, charClass, spells, bonuses), theClass, bonuses, totalHitPoints, AC, gearCarried, treasureCarried, magicItemsCarried, gold, silver, copper, getTotalGearSlots(), getAllWeaponAttacks(), highLegibility, weapons, armors, getAnimalCompanions(), spellCastingBonus, demonicPossessionBonus, cheats, weaponsPermitted, armorPermitted);
            download(pdfBytes, noName(name) + ".pdf", "application/pdf");
        }

        if (pdfType === "Form fillable") {
            const pdfBytes = await getPDFFillable(name, theAncestry, finalStats, XP, level, title, alignment, background, deity, formatLanguagesKnownAsList(), getAllSpellsKnown(charClasses, charClass, spells, bonuses), theClass, bonuses, totalHitPoints, AC, gearCarried, treasureCarried, magicItemsCarried, gold, silver, copper, getTotalGearSlots(), getAllWeaponAttacks(), false, weapons, armors, getAnimalCompanions(), spellCastingBonus, demonicPossessionBonus, cheats, weaponsPermitted, armorPermitted);
            download(pdfBytes, noName(name) + ".pdf", "application/pdf");
        }

    }

    //#endregion

    //#region Export character as JSON

    const getCharacterAsObject = () => {
        const char: any = {
            name: name,
            stats: {
                STR: finalStats.Strength,
                DEX: finalStats.Dexterity,
                CON: finalStats.Constitution,
                INT: finalStats.Intelligence,
                WIS: finalStats.Wisdom,
                CHA: finalStats.Charisma
            },
            rolledStats: {
                STR: stats.Strength,
                DEX: stats.Dexterity,
                CON: stats.Constitution,
                INT: stats.Intelligence,
                WIS: stats.Wisdom,
                CHA: stats.Charisma
            },
            ancestry: ancestry,
            class: charClass,
            level: charClass !== "Level 0" ? level : 0,
            levels: levels,
            XP: XP,
            ambitionTalentLevel: ambitionTalentLevel,
            title: getTitle(charClass, alignment, level),
            alignment: alignment,
            background: background,
            deity: deity,
            maxHitPoints: totalHitPoints,
            armorClass: AC,
            gearSlotsTotal: getTotalGearSlots(),
            gearSlotsUsed: getTotalGearSlotsUsed(),
            bonuses: bonuses,
            goldRolled: goldRolled,
            gold: gold,
            silver: silver,
            copper: copper,
            gear: getGearRecords(gearCarried, treasureCarried, magicItemsCarried, gold, silver, copper, armors, false),
            treasures: treasureCarried,
            magicItems: magicItemsCarried,
            ledger: ledger,
            spellsKnown: formatSpellsKnownAsList(),
            languages: getAllLanguagesKnownByCharacter().map((l) => l.name).join(", "),
            creationMethod: creationMethod,
            coreRulesOnly: coreRulesOnly,
            activeSources: activeSources,
            edits: cheats
        }
        return char;
    }

    const exportToJson = (e: any) => {
        e.preventDefault();
        if (jsonExportType === "File") {
            downloadFile(
                JSON.stringify(getCharacterAsObject(), null, 2),
                name.trim() + '.json',
                'text/json',
            )
        }
        if (jsonExportType === "Clipboard") {
            navigator.clipboard.writeText(JSON.stringify(getCharacterAsObject(), null, 2));
            alert("Character has been copied to the clipboard. \nPlease paste into the Foundry import field.");
        }
    }

    //#endregion

    //#region Save character

    const saveCharacter = async () => {

        if (props.user) {
            const db = getFirestore(app);

            interface iCharacterBrief {
                name: string,
                name_lower: string,
                level: number,
                background: string,
                ancestry: string,
                className: string,
                alignment: string,
                deity: string,
                creationMethod: string,
                coreRulesOnly: boolean,
                activeSources: string[],
                wasEdited: boolean;
                user_email: string,
                user_id: string,
                dateCreated: any,
                dateLastModified: any
            }

            let characterBrief: iCharacterBrief = {
                name: name.trim(),
                name_lower: name.trim().toLowerCase(),
                level: level,
                background: background,
                ancestry: ancestry,
                className: charClass,
                alignment: alignment,
                deity: deity,
                creationMethod: creationMethod,
                coreRulesOnly: coreRulesOnly,
                activeSources: activeSources,
                wasEdited: cheats.length > 0,
                user_email: userEmail,
                user_id: props.user.uid,
                dateCreated: dateCreated,
                dateLastModified: dateLastModified
            }

            interface iCharacterComplete {
                name: string,
                traits: any,
                user_email: string,
                user_id: string,
                dateCreated: any,
                dateLastModified: any
            }

            const characterComplete: iCharacterComplete = {
                name: name.trim(),
                traits: getCharacterAsObject(),
                user_email: userEmail,
                user_id: props.user.uid,
                dateCreated: dateCreated,
                dateLastModified: dateLastModified
            }

            try {
                if (IdInQuerystring) {
                    // Update existing character:
                    // save character basic data.
                    setIsLoading(true);
                    characterBrief.dateLastModified = serverTimestamp();
                    characterComplete.dateLastModified = serverTimestamp();

                    try {
                        await setDoc(doc(db, "characterBasic", IdInQuerystring), characterBrief);
                        await setDoc(doc(db, "characterComplete", IdInQuerystring), characterComplete);
                    }
                    catch (e) {
                        console.log("save error: " + e);
                    }

                    setIsLoading(false);
                } else {
                    // Save new character:
                    // save character basic data.
                    setIsLoading(true);
                    characterBrief.dateCreated = serverTimestamp();
                    characterComplete.dateCreated = serverTimestamp();

                    const docRef = await addDoc(collection(db, "characterBasic"), characterBrief);
                    // save complete character
                    await setDoc(doc(db, "characterComplete", docRef.id), characterComplete);
                    setIsLoading(false);
                }
            } catch (e) {
                console.log("Unhandled error: " + JSON.stringify(e, null, 2));
                setIsLoading(false);
            }

            navigate("/characters")
        }
    }

    const getScreenTitle = () => {
        if (characterAlreadyExists) { return "Update Character"; }
        return "Create Character";
    }

    const AC = getAC(gearCarried, armors, bonuses, finalStats, magicItemsCarried, ancestries, ancestry, charClasses, charClass);
    const getAllWeaponAttacks = () => getWeaponAttacks(armors, gearCarried, finalStats, ancestries, ancestry, bonuses, level, charClass, charClasses, magicItemsCarried, getDemonicPossessionBonus(charClasses, charClass, level, bonuses));

    const getAnimalCompanions = () => {
        const animalBonuses = bonuses.filter((b) => b.bonusTo === "AnimalCompanion");
        let animals: Creature[] = [];
        animalBonuses.forEach((ab) => {
            const thisAnimal = availableAnimals.find((a) => a.name === ab.bonusName);
            if (thisAnimal) {
                animals.push(thisAnimal);
            }
        })

        animals.sort((a1: Creature, a2: Creature) => a1.name < a2.name ? -1 : 1);
        return animals;
    }

    const levelUp = () => {
        // reduce XP back to zero
        setRawXP("0");
        setXP(0);
        // add level
        const newLevel = level + 1;
        setLevel(newLevel);
        let newLevelObj: Level = { level: newLevel, talentRolledDesc: "", talentRolledName: "", Rolled12TalentOrTwoStatPoints: "", Rolled12ChosenTalentDesc: "", Rolled12ChosenTalentName: "", HitPointRoll: 0, stoutHitPointRoll: 0 };
        let updatedLevels = [...levels];
        updatedLevels.push(newLevelObj);
        setLevels(updatedLevels);
    }

    const levelDown = () => {
        // reduce XP back to zero
        setRawXP("0");
        setXP(0);
        // remove last level
        const newLevel = level - 1;
        setLevel(newLevel);
        let updatedLevels = [...levels];
        updatedLevels.pop();
        setLevels(updatedLevels);
        // remove all bonuses for level
        let updatedBonuses = [...bonuses];
        updatedBonuses = updatedBonuses.filter((b) => b.gainedAtLevel <= newLevel);
        setBonuses(updatedBonuses);
    }

    const handlSetIsCheatMode = (isCheatMode: boolean) => {
        if (isCheatMode) {
            if (stats.Strength === 0) {
                const updatedStats = { ...stats };
                updatedStats.Strength = 10;
                updatedStats.Dexterity = 10;
                updatedStats.Constitution = 10;
                updatedStats.Intelligence = 10;
                updatedStats.Wisdom = 10;
                updatedStats.Charisma = 10;
                setStats(updatedStats);
            }
        }
        setIsCheatMode(isCheatMode);
    }

    const getCharClass = () => {
        return charClasses.find((c) => c.name === charClass);
    }

    const availableArmors = armors.filter((a) => activeSources.indexOf(a.sourceId) !== -1);
    const availableWeapons = weapons.filter((w) => w.sourceIds.some((wsid: any) => activeSources.includes(wsid)));
    const availableClasses = charClasses.filter((anc) => classFilter.filter((a) => a.isActive).map((a) => a.charClass).indexOf(anc.name) !== -1);
    const availableLanguages = languages.filter((c) => activeSources.indexOf(c.sourceId) !== -1);
    const availableWarlockPatrons = getAllWarlockPatrons().filter((c) => activeSources.indexOf(c.sourceId) !== -1);
    const availablePatronBoons = getAllPatronBoons().filter((c) => activeSources.indexOf(c.sourceId) !== -1);
    const availableAnimals = animals.filter((w) => w.sourceIds.some((wsid: any) => activeSources.includes(wsid)));
    const availableSpells = spells.filter((s) => {
        // get the sources for the classes that can cast this spell
        let spellSources: string[] = [];
        s.classes.forEach((cls) => {
            const charClass = charClasses.find((cc) => cc.name === cls);
            if (charClass) {
                if (!spellSources.includes(charClass.sourceId)) {
                    spellSources.push(charClass.sourceId)
                }
            }
        })
        // limit the spells to those that come from the classes from the active sources
        let spellIsFromAnActiveSource = false;
        activeSources.forEach((as: string) => {
            spellSources.forEach((ss) => {
                if (ss === as) {
                    spellIsFromAnActiveSource = true;
                }
            })
        })

        return spellIsFromAnActiveSource;
    });

    const getWarlockPatron = () => {
        let patron = "";
        const existingBonus = bonuses.find((b) => b.sourceCategory === "Patron" && b.name === "Patron");
        if (existingBonus && existingBonus.bonusTo) { patron = existingBonus.bonusTo; }
        return patron;
    }

    const context: GlobalContextType = {
        activeSources: activeSources,
        ambitionTalentLevel: ambitionTalentLevel,
        ancestry: ancestry,
        availableArmors: availableArmors,
        availableClasses: availableClasses, // availableCharacterClasses,
        availableLanguages: availableLanguages,
        availablePatronBoons: availablePatronBoons,
        availableWarlockPatrons: availableWarlockPatrons,
        availableWeapons: availableWeapons,
        availableAnimals: availableAnimals,
        availableSpells: availableSpells,
        bonuses: bonuses,
        charClass: getCharClass(),
        className: charClass,
        copper: copper,
        finalStats: finalStats,
        gold: gold,
        levels: levels,
        patron: getWarlockPatron(),
        silver: silver,
        stats: stats,
    }

    const getIfCharacterIsSaved = () => {
        if (IdInQuerystring) { return true; }
        return false;
    }

    const hasAncestriesAndClasses = availableAncestries.length > 0 && availableClasses.length > 0;

    const weaponsPermitted: Weapon[] = getPermittedWeaponsByClassName(availableClasses, availableWeapons, bonuses, charClass).sort((w1: Weapon, w2: Weapon) => w1.name < w2.name ? -1 : 1);
    const armorPermitted: Armor[] = getPermittedArmorsByClassName(availableClasses, charClass, availableArmors, bonuses).sort((a1: Armor, a2: Armor) => a1.name < a2.name ? -1 : 1);


    return (

        <CreateCharacterContext.Provider value={context}>

            <div>

                <h1>{getScreenTitle()}</h1>

                {(hardcodedAncestry !== "" || hardcodedClass !== "") &&
                    <div><b>hardcodedAncestry:</b> '{hardcodedAncestry}', <b>hardcodedClass:</b> '{hardcodedClass}'</div>
                }

                <Spinner isLoading={isLoading} />

                {error !== "" &&
                    <Alert key="warning" variant="warning">Error: {error}</Alert>
                }

                <CharDesignMenu
                    user={props.user}
                    designMode={designMode}
                    coreRulesOnly={coreRulesOnly}
                    openSourcesModal={openSourcesModal}
                    openFilterAncestriesAndClasssesModal={openFilterAncestriesAndClasssesModal}
                    randomType={randomType}
                    sources={sources}
                    sourcesOrFiltersChanged={sourcesOrFiltersChanged}
                    ancestries={ancestries}
                    ancestry={ancestry}
                    charClass={charClass}
                    deities={deities}
                    deity={deity}
                    backgrounds={backgrounds}
                    background={background}
                    allLanguagesKnown={getAllLanguagesKnownByCharacter()}
                    activeSources={activeSources}
                    ancestryFilter={ancestryFilter}
                    classFilter={classFilter}
                    updateDesignMode={(designMode: string) => updateDesignMode(designMode)}
                    flipShowSources={(coreRulesOnly: boolean) => flipShowSources(coreRulesOnly)}
                    flipActiveSource={(sourceId: string) => flipActiveSource(sourceId)}
                    resetCharacter={() => resetCharacter()}
                    onCloseModal={(modalName: string) => onCloseModal(modalName)}
                    onOpenModal={(modalName: string) => onOpenModal(modalName)}
                    updateRandomType={(randomType: string) => updateRandomType(randomType)}
                    randomCharacter_LevelOne={() => randomCharacter_LevelOne(false)}
                    randomCharacter_LevelZero={() => randomCharacter_LevelZero()}
                    randomCharacter_FourLevelZeroes={() => randomCharacter_FourLevelZeroes()}
                    characterAlreadyExists={characterAlreadyExists}
                    setAncestryFilter={(sourceId: string, ancestry: string) => onSetAncestryFilter(sourceId, ancestry)}
                    setClassFilter={(sourceId: string, charClass: string) => onSetClassFilter(sourceId, charClass)}
                    checkAll={(checkAll: boolean) => onCheckAllAncestriesAndClasses(checkAll)}
                    saveSourcesAndFilters={() => onSaveSettingsAndFilters()}
                />

                {(showForm && hasAncestriesAndClasses) &&
                    <div>

                        <h1 className="noUnderline">{getCharacterDesc(name, ancestry, charClass)}</h1>

                        <div className="sectionBorder">

                            <div className="form-check form-switch form-check-inline mt-0 pt-0">
                                <input className="form-check-input" type="checkbox" role="switch" id="cheat1" checked={isCheatMode} onChange={() => handlSetIsCheatMode(!isCheatMode)} />
                                <label className="form-check-label small" htmlFor="cheat1">Enable edit mode</label>
                            </div>

                            <div className="form-check form-switch form-check-inline mt-0 pt-0">
                                <input className="form-check-input" type="checkbox" role="switch" id="minimal1" checked={minimal} onChange={() => flipMinimal(minimal)} />
                                <label className="form-check-label small" htmlFor="minimal1">Minimal details</label>
                            </div>

                            <div>
                                <SaveCharacter
                                    saveCharacter={() => saveCharacter()}
                                    cancel={() => navigate("/characters")}
                                    isLoggedIn={isLoggedIn}
                                    isLoading={isLoading}
                                    characterAlreadyExists={characterAlreadyExists}
                                    numCharacters={numCharacters}
                                    maxCharacters={maxChars}
                                    name={name}
                                />
                            </div>

                        </div>

                        {!isCheatMode &&
                            <RollBackground
                                sources={sources}
                                backgrounds={backgrounds}
                                selectedBackground={background}
                                rollBackground={(sourceId: string) => onRollBackground(sourceId)}
                            />
                        }

                        {isCheatMode &&
                            <SelectBackground
                                backgrounds={backgrounds}
                                selectedBackground={background}
                                setBackground={(background: string) => onSelectBackground(background)}
                            />
                        }

                        {!isCheatMode &&
                            <RollStats
                                randomlySelectedClassRoll={randomlySelectedClassRoll}
                                randomlySelectedClass={randomlySelectedClass}
                                randomType={randomType}
                                rollStats={() => onRollStats(false)}
                                charClasses={availableClasses}
                                minimal={minimal}
                            />
                        }

                        {isCheatMode &&
                            <SelectStats
                                setStats={(stats: Stats) => onSelectStats(stats)}
                                charClasses={availableClasses}
                                minimal={minimal}
                            />
                        }

                        <SelectAncestry
                            user={props.user}
                            bonuses={bonuses}
                            ancestries={availableAncestries}
                            selectedAncestry={ancestry}
                            activeSources={activeSources}
                            languagesKnown={getAllLanguagesKnownByCharacter()}
                            spellsKnown={getAllSpellsKnown(charClasses, charClass, spells, bonuses)}
                            setAncestry={(a) => onSelectAncestry(a)}
                            rollAncestry={() => onRollAncestry(false, "")}
                            onSetBonus={(bonus) => onSetBonus(bonus)}
                            onSetBonuses={(bonuses) => onSetBonuses(bonuses)}
                            onSetSpecialTalent={(level: number, talentName: string, specialCategoryName: string) => setSpecialTalent(level, talentName, specialCategoryName)}
                            onSetRolled12TalentOrTwoStatPoints={(level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => onSetRolled12TalentOrTwoStatPoints(level, choice, isAmbitionTalent, isBoon, boonSource)}
                            onSetRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => setRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                            minimal={minimal}
                            isCheatMode={isCheatMode}
                            characterIsSaved={getIfCharacterIsSaved()}
                            openAnimalModal={openAnimalModal}
                            onCloseModal={(modalName: string) => onCloseModal(modalName)}
                            onOpenModal={(modalName: string) => onOpenModal(modalName)}
                        />

                        <SelectClass
                            user={props.user}
                            charClasses={availableClasses}
                            level={level}
                            bonuses={bonuses}
                            characterIsSaved={getIfCharacterIsSaved()}
                            selectedAncestry={ancestry}
                            languages={languages}
                            languagesKnown={getAllLanguagesKnownByCharacter()}
                            spellsKnown={getAllSpellsKnown(charClasses, charClass, spells, bonuses)}
                            totalHitPoints={totalHitPoints}
                            minimal={minimal}
                            isCheatMode={isCheatMode}
                            setClass={(c) => onSelectClass(c)}
                            rollClass={() => onRollClass()}
                            rollHitDie={(level: number, hitDie: number) => onRollHitDie(level, hitDie)}
                            setHitDie={(level: number, hitPoints: number) => onSetHitDie(level, hitPoints)}
                            onSetBonus={(bonus) => onSetBonus(bonus)}
                            onSetBonuses={(bonuses: Bonus[]) => onSetBonuses(bonuses)}
                            onSetSpecialTalent={(level: number, talentName: string, specialTalentCategory: string) => setSpecialTalent(level, talentName, specialTalentCategory)}
                            setRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => setRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                            onSetRolled12TalentOrTwoStatPoints={(level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => onSetRolled12TalentOrTwoStatPoints(level, choice, isAmbitionTalent, isBoon, boonSource)}
                            onSetRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean, parentBonusId?: string) => setRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent, parentBonusId)}
                            maximiseHitDieRoll={(level: number, maximiseHitDieRoll: boolean) => onMaximiseHitDieRoll(level, maximiseHitDieRoll)}
                            openSpellbookModal={openSpellbookModal}
                            openAnimalModal={openAnimalModal}
                            onCloseModal={(modalName: string) => onCloseModal(modalName)}
                            onOpenModal={(modalName: string) => onOpenModal(modalName)}
                        />

                        <div className="sectionBorder">

                            <div className="">

                                <h2>Gold and Gear</h2>

                                <RollGold
                                    goldRolled={goldRolled}
                                    rollGold={() => onRollGold()}
                                    setGold={(gold: number) => onSetGold(gold)}
                                    rollLevelOneItems={() => onRollLevelOneItems()}
                                    isLevelZero={charClass === "Level 0"}
                                    isCheatMode={isCheatMode}
                                />

                                {goldRolled !== -1 &&
                                    <>
                                        {showBuyCrawlingKit &&
                                            <div>
                                                <BuyCrawlingKit onBuyCrawlingKit={(buyKit: boolean) => onBuyCrawlingKit(buyKit)} />
                                            </div>
                                        }

                                        {!showBuyCrawlingKit &&
                                            <div>

                                                <BuyGear
                                                    openWeaponsModal={openWeaponsModal}
                                                    openArmorModal={openArmorModal}
                                                    openSundriesModal={openSundriesModal}
                                                    openTreasureModal={openTreasureModal}
                                                    openMagicItemsModal={openMagicItemsModal}
                                                    onlyIncludeProficientWeapons={onlyIncludeProficientWeapons}
                                                    onlyIncludeProficientArmor={onlyIncludeProficientArmor}
                                                    gearOwned={gearCarried}
                                                    treasureCarried={treasureCarried}
                                                    magicItemsCarried={magicItemsCarried}
                                                    magicItemCatalog={magicItemCatalog}
                                                    gearSlotsUsed={gearSlotsUsed}
                                                    gearSlotsTotal={gearSlotsTotal}
                                                    bonuses={bonuses}
                                                    onSetOnlyIncludeProficientWeapons={(onlyIncludeProficientWeapons: boolean) => onSetOnlyIncludeProficientWeapons(onlyIncludeProficientWeapons)}
                                                    onSetOnlyIncludeProficientArmor={(onlyIncludeProficientArmor: boolean) => onSetOnlyIncludeProficientArmor(onlyIncludeProficientArmor)}
                                                    onBuyItem={(id: string, type: string, isBuy: boolean, cost: number, currency: string) => onBuyItem(id, type, isBuy, cost, currency)}
                                                    onAddTreasure={(name: string, desc: string, cost: number, currency: string, slots: number) => onAddTreasure(name, desc, cost, currency, slots)}
                                                    onEditTreasure={(id: string, name: string, desc: string, cost: number, currency: string, slots: number) => onEditTreasure(id, name, desc, cost, currency, slots)}
                                                    onDiscardTreasure={(id: string) => onDiscardTreasure(id)}
                                                    onSellTreasure={(id: string) => onSellTreasure(id)}

                                                    onAddMagicItem={(itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string, properties: string[]) => onAddMagicItem(itemType, itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, virtue, flaw, trait, properties)}
                                                    onEditMagicItem={(id: string, itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string) => onEditMagicItem(id, itemType, itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, virtue, flaw, trait)}
                                                    onDiscardMagicItem={(id: string) => onDiscardMagicItem(id)}

                                                    onCloseModal={(modalName: string) => onCloseModal(modalName)}
                                                    onOpenModal={(modalName: string) => onOpenModal(modalName)}
                                                />

                                                <GainLoseCoins
                                                    gold={gold}
                                                    silver={silver}
                                                    copper={copper}
                                                    openModal={openGainLoseCoinsModal}
                                                    onOpenModal={(modalName: string) => onOpenModal(modalName)}
                                                    onCloseModal={(modalName: string) => onCloseModal(modalName)}
                                                    onSetAdditionalCoins={(gold: number, silver: number, copper: number, notes: string) => handleSetAdditionalCoins(gold, silver, copper, notes)}
                                                />

                                                <DisplayLedger
                                                    openModal={openLedgerModal}
                                                    onOpenModal={(modalName: string) => onOpenModal(modalName)}
                                                    onCloseModal={(modalName: string) => onCloseModal(modalName)}
                                                    ledger={ledger}
                                                />

                                            </div>
                                        }

                                        {(gearCarried.length > 0 || treasureCarried.length > 0 || magicItemsCarried.length > 0) &&
                                            <>
                                                <GearCarried
                                                    gear={gearCarried}
                                                    treasure={treasureCarried}
                                                    magicItems={magicItemsCarried}
                                                />
                                            </>
                                        }
                                        <div className="pt-0 mt-0"><b>Gear slots:</b> {getTotalGearSlotsUsed()} of {getTotalGearSlots()}</div>

                                    </>

                                }

                            </div>

                        </div>

                        <div className="sectionBorder">
                            <h2>Advancement</h2>

                            <Advancement
                                ancestry={ancestry}
                                currentLevel={levels.length}
                                level={level}
                                bonuses={bonuses}
                                spellsKnown={getAllSpellsKnown(charClasses, charClass, spells, bonuses)}
                                XP={XP}
                                rawXP={rawXP}
                                advancementMode={advancementMode}
                                isCheatMode={isCheatMode}
                                isMinimal={minimal}
                                setXP={(XP: number) => setXP(XP)}
                                setRawXP={(rawXP: string) => setRawXP(rawXP)}
                                levelUp={() => levelUp()}
                                levelDown={() => levelDown()}
                                onRollHitDie={(level: number, hitDie: number) => onRollHitDie(level, hitDie)}
                                onSetHitDie={(level: number, hitDie: number) => onSetHitDie(level, hitDie)}
                                onSetBonus={(bonus: Bonus) => onSetBonus(bonus)}
                                onSetBonuses={(bonuses: Bonus[]) => onSetBonuses(bonuses)}
                                onSetRolled12TalentOrTwoStatPoints={(level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => onSetRolled12TalentOrTwoStatPoints(level, choice, isAmbitionTalent, isBoon, boonSource)}
                                onSetRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean, parentBonusId?: string) => setRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent, parentBonusId)}
                                onSetSpecialTalent={(level: number, talentName: string, specialTalentCategory: string) => setSpecialTalent(level, talentName, specialTalentCategory)}
                                maximiseHitDieRoll={(level: number, maximiseHitDieRoll: boolean) => onMaximiseHitDieRoll(level, maximiseHitDieRoll)}
                                setAdvancementMode={(mode: string) => setAdvancementMode(mode)}
                                openAnimalModal={openAnimalModal}
                                onCloseModal={(modalName: string) => onCloseModal(modalName)}
                                onOpenModal={(modalName: string) => onOpenModal(modalName)}
                            />

                        </div>


                        <div className="sectionBorder">

                            <h2>Other Features</h2>

                            <SelectName
                                selectedName={name} setName={(n) => onSelectName(n)}
                                rollName={() => onRollName()}
                            />

                            <SelectAlignment
                                alignments={getAlignments(getSources())}
                                selectedAlignment={alignment}
                                setAlignment={(a) => onSelectAlignment(a)}
                                rollAlignment={() => onRollAlignment()}
                            />

                            <SelectDeity
                                activeSources={activeSources}
                                deities={deities}
                                selectedDeity={deity}
                                setDeity={(d) => onSelectDeity(d)}
                                rollDeity={() => onRollDeity("")}
                                alignment={alignment}
                                minimal={minimal}
                            />

                            {getCharacterFeatures(charClass, level, finalStats, AC, getTotalHitPoints(level, levels, ancestry, charClass, charClasses, finalStats, bonuses), formatLanguagesKnownAsList(), formatSpellsKnownAsList(), getTitle(charClass, alignment, level), minimal, cheats, weaponsPermitted, armorPermitted)}

                        </div>

                        <div className="sectionBorder">
                            <h2>Save</h2>

                            <SaveCharacter saveCharacter={() => saveCharacter()} cancel={() => navigate("/characters")} isLoggedIn={isLoggedIn} isLoading={isLoading} characterAlreadyExists={characterAlreadyExists} numCharacters={numCharacters} maxCharacters={maxChars} name={name} />

                        </div>

                        <div className="sectionBorder">

                            <h2>Export</h2>

                            <Export
                                pdfType={pdfType}
                                ancestry={ancestry}
                                charClass={charClass}
                                treasures={treasureCarried}
                                magicItems={magicItemsCarried}
                                jsonExportType={jsonExportType}

                                setPDFType={(pdfType: string) => setPDFType(pdfType)}
                                printCharacter={() => printCharacter()}
                                exportToJson={(e: any) => exportToJson(e)}
                                setJSONExportType={(exportType: string) => setJSONExportType(exportType)}
                            />

                        </div>

                        {process.env.REACT_APP_ENV !== "PRODUCTION" &&
                            <div className="sectionBorder">
                                {/* <h2>Char Class Lottery</h2>
                    <div><pre>{JSON.stringify((randomChar as Character).stats, null, 2)}</pre></div>
                    <div><pre>{JSON.stringify((randomChar as Character).classLottery, null, 2)}</pre></div> */}


                                {/* <h2>Entire Character</h2>
                                <div><pre>{JSON.stringify(getCharacterAsObject(), null, 2)}</pre></div> */}

                                {/* <h2>Cheats</h2>
                            <div><pre>{JSON.stringify(cheats, null, 2)}</pre></div> */}

                                <div>Context.gold: {context.gold}</div>

                                <h2>Ambition Talent Level</h2>
                                <div><pre>{JSON.stringify(ambitionTalentLevel, null, 2)}</pre></div>

                                <h2>Levels</h2>
                                <div><pre>{JSON.stringify(levels, null, 2)}</pre></div>


                                <h2>Bonuses</h2>
                                <div><pre>{JSON.stringify(bonuses, null, 2)}</pre></div>

                                {/* <h2>Languages Known</h2>
                            <div><pre>{JSON.stringify(getAllLanguagesKnown(), null, 2)}</pre></div>  */}

                                {/* <h2>Gear Owned</h2>
                            <div><pre>{JSON.stringify(gearCarried, null, 2)}</pre></div> */}

                                {/* <h2>Gear Records</h2>
                            <div><pre>{JSON.stringify(getGearRecords(gearCarried, treasureCarried, magicItemsCarried, gold, silver, copper, armors, true), null, 2)}</pre></div> */}

                                {/* <h2>Treasure Carried</h2>
                            <div><pre>{JSON.stringify(treasureCarried, null, 2)}</pre></div>  */}

                                {/* <h2>Magic Items Carried</h2>
                            <div><pre>{JSON.stringify(magicItemsCarried, null, 2)}</pre></div> */}


                                {/* <h2>Attacks</h2>
                            <div><pre>{JSON.stringify(weaponAttacks, null, 2)}</pre></div> */}

                                {/* <h2>Spells</h2>
                                <div><pre>{JSON.stringify(spells, null, 2)}</pre></div> */}

                                {/* <h2>Available Spells</h2>
                                <div><pre>{JSON.stringify(availableSpells, null, 2)}</pre></div> */}

                            </div>
                        }

                    </div>
                }

                {(showForLevelZeroesForm && hasAncestriesAndClasses) &&
                    <GenerateFourLevelZeroes
                        fourCharacters={fourCharacters}
                    />
                }

            </div >

        </CreateCharacterContext.Provider>
    );

}

export const CreateCharacterContext = createContext<GlobalContextType>(null as unknown as GlobalContextType);

export default CreateCharacter;

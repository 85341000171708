import { useLocation, useNavigate } from "react-router-dom";

interface IProps {

}


const Footer: React.FunctionComponent<IProps> = (props: IProps) => {

  const navigate = useNavigate();
  const location = useLocation();

  const getNavLinkClassName = (pathName: string) => {
    let className = "nav-link";
    if (location.pathname === pathName) {
      className = className + " active";
    }
    return className;
  }

  return (

    <>

      <ul className="nav nav-fill border-bottom border-top mt-3">
        <li className="nav-item d-sm-none">
          <a className={getNavLinkClassName("/contact")} href="#!" onClick={() => navigate('/contact')}>Contact</a>
        </li>
        <li className="nav-item d-sm-none">
          <a className={getNavLinkClassName("/faq")} href="#!" onClick={() => navigate('/faq')}>FAQ</a>
        </li>
        <li className="nav-item">
          Copyright ©2023 The Arcane Library
        </li>
      </ul>

    </>
  )

}

export default Footer;
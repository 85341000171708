import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByNameAndBonusTo } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const PlusTwoSTRDEXCON: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setPlusTwoSTRBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrDEXOrCON",
            bonusName: "StatBonus",
            bonusTo: "STR:+2"
        };
        props.setBonus(bonus);
    }

    const setPlusTwoDEXBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrDEXOrCON",
            bonusName: "StatBonus",
            bonusTo: "DEX:+2"
        };
        props.setBonus(bonus);
    }

    const setPlusTwoCONBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrDEXOrCON",
            bonusName: "StatBonus",
            bonusTo: "CON:+2"
        };
        props.setBonus(bonus);
    }

    const plusTwoSTRSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "Plus2STROrDEXOrCON", "STR:+2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plusTwoDEXSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "Plus2STROrDEXOrCON", "DEX:+2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plusTwoCONSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "Plus2STROrDEXOrCON", "CON:+2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;

    const req = !plusTwoSTRSelected && !plusTwoDEXSelected && !plusTwoCONSelected;

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setPlusTwoSTRBonus()} checked={plusTwoSTRSelected}></input>&nbsp;STR {globalContext.finalStats.Strength}</label>
            <label><input type="radio" onChange={() => setPlusTwoDEXBonus()} checked={plusTwoDEXSelected}></input>&nbsp;DEX {globalContext.finalStats.Dexterity}</label>
            <label><input type="radio" onChange={() => setPlusTwoCONBonus()} checked={plusTwoCONSelected}></input>&nbsp;CON {globalContext.finalStats.Constitution}</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default PlusTwoSTRDEXCON;

import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Stats, modStr } from "../../data/stats";
import { getClassLottery, getPercentages } from "../../special/randomChar";
import { CharClass } from "../../data/classes";

interface IProps {
    setStats: (stats: Stats) => void;
    charClasses: CharClass[];
    minimal: boolean;
}

const SelectStats: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const stats = globalContext.stats;

    const getStatVal = (statName: string) => {
        switch (statName) {
            case "STR": return stats.Strength;
            case "DEX": return stats.Dexterity;
            case "CON": return stats.Constitution;
            case "INT": return stats.Intelligence;
            case "WIS": return stats.Wisdom;
            case "CHA": return stats.Charisma;
            default: return 3;
        }
    }

    const updateStatVal = (statName: string, statVal: number) => {

        const newStats = { ...stats };

        switch (statName) {
            case "STR": newStats.Strength = statVal; break;
            case "DEX": newStats.Dexterity = statVal; break;
            case "CON": newStats.Constitution = statVal; break;
            case "INT": newStats.Intelligence = statVal; break;
            case "WIS": newStats.Wisdom = statVal; break;
            case "CHA": newStats.Charisma = statVal; break;
            default: newStats.Strength = 11;
        }

        props.setStats(newStats);
    }

    const getDDL = (statName: string) => {
        let out: any[] = [];

        const statVal = getStatVal(statName);

        for (let s = 3; s <= 18; s++) {
            out.push(<option value={s} key={statName + "_" + s}>{statName} {s} ({modStr(s)})</option>)
        }

        return <select className="form-select statDDL" onChange={(e) => updateStatVal(statName, parseInt(e.target.value, 10))} value={statVal}>{out}</select>;
    }

    const displayClassRecommendations = () => {
        const lottery = getClassLottery(stats, props.charClasses);
        const percentages = getPercentages(lottery, props.charClasses);
        return percentages;
    }

    const cssClass = "col-6 col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4";

    return (
        <div className="sectionBorder">
            <h2>Stat Rolls</h2>

            <div className="">

                <div className="test"></div>

                <div className="container text-center">
                    <div className="row">
                        <div className={cssClass}>
                            {getDDL("STR")}
                        </div>
                        <div className={cssClass}>
                            {getDDL("DEX")}
                        </div>
                        <div className={cssClass}>
                            {getDDL("CON")}
                        </div>
                        <div className={cssClass}>
                            {getDDL("INT")}
                        </div>
                        <div className={cssClass}>
                            {getDDL("WIS")}
                        </div>
                        <div className={cssClass}>
                            {getDDL("CHA")}
                        </div>
                    </div>
                </div>


                {(globalContext.className !== "Level 0" && !props.minimal) &&
                    <div>
                        <div>Rankings: {displayClassRecommendations()}</div>
                    </div>
                }

            </div>
        </div>
    )

}

export default SelectStats;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";

interface IProps {
    setBonus: (bonus: Bonus) => void;
    level: Level;
}

const AttackBonus: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setRangedBonus = () => {
        const bonus: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Elf",
            sourceCategory: "Ability",
            gainedAtLevel: props.level.level,
            name: "FarSight",
            bonusName: "AttackBonus",
            bonusTo: "RangedWeapons",
            bonusAmount: 1
        };
        props.setBonus(bonus);
    }

    const setSpellBonus = () => {
        const bonus: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Elf",
            sourceCategory: "Ability",
            gainedAtLevel: props.level.level,
            name: "FarSight",
            bonusName: "Plus1ToCastingSpells",
            bonusTo: "Spellcasting",
            bonusAmount: 1
        };
        props.setBonus(bonus);
    }

    const rangedSelected = globalContext.bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Elf" && b.name === "FarSight" && b.bonusTo === "RangedWeapons" && b.bonusName === "AttackBonus") !== undefined;
    const spellcastingSelected = globalContext.bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Elf" && b.name === "FarSight" && b.bonusTo === "Spellcasting" && b.bonusName === "Plus1ToCastingSpells") !== undefined;

    const req = !rangedSelected && !spellcastingSelected;

    return (
        <div key="attackBonus">
            <div className="formNote">Farsight</div>
            <label><input type="radio" name="SpecAttackBonus" onChange={() => setRangedBonus()} checked={rangedSelected}></input>&nbsp;+1&nbsp;Ranged<span className="d-none d-sm-inline">&nbsp;Weapons</span></label>
            <label><input type="radio" name="SpecAttackBonus" onChange={() => setSpellBonus()} checked={spellcastingSelected}></input>&nbsp;+1&nbsp;Spellcasting</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default AttackBonus;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { dogsLifes } from "../../data/dogs";
import { Level } from "../../data/level";
import { getRandomIntInclusive } from "../../data/random";

interface IProps {
    setBonus: (bonus: Bonus) => void;
    level: Level;
}

const DogsLife: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    let currentValue = "";
    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Dog" && b.sourceCategory === "DogsLife");
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const rollForDogsLife = () => {
        const roll = getRandomIntInclusive(0, 5);

        const theDogsLife = dogsLifes[roll];
        if (theDogsLife) {
            const theBonus: Bonus = {
                sourceType: "Ancestry",
                sourceName: "Dog",
                sourceCategory: "DogsLife",
                gainedAtLevel: props.level.level,
                name: "StatBonus",
                bonusName: "StatBonus",
                bonusTo: theDogsLife.statBonus + ":+1"
            }
            props.setBonus(theBonus);
        }
    }

    const getDogsLifeDesc = () => {
        if (existingBonus) {
            const theStory = dogsLifes.find((qs) => existingBonus.bonusTo.indexOf(qs.statBonus) !== -1);
            if (theStory) {
                return <div className="spell"><b>{theStory.name}:</b> {theStory.desc}</div>
            }
            return "??";
        }
        return "???";
    }

    return (
        <div>
            <div className="formNote">Dog's Life</div>
            {currentValue === "" &&
                <div>
                    <button className="btn btn-dark" onClick={() => rollForDogsLife()}>Roll for Dog's Life</button>
                    <div className="valError">Please roll for dog's life</div>
                </div>
            }
            {currentValue !== "" &&
                <div>{getDogsLifeDesc()}</div>
            }
        </div>
    )

}

export default DogsLife;
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    isLoading: boolean;
}

const Spinner: React.FunctionComponent<IProps> = (props: IProps) => {
    if(!props.isLoading) { return null;}
    return <FontAwesomeIcon icon={faCircleNotch} className="fa-spin spinner ms-2 p-0" />
}

export default Spinner;

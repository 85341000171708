import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";

interface IProps {
    setBonus: (bonus: Bonus) => void;
    level: Level;
}

const PlusOneSpellCastingOrLuckToken: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setLuckBonus = () => {
        const bonus: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Kobold",
            sourceCategory: "Ability",
            gainedAtLevel: props.level.level,
            name: "Knack",
            bonusName: "LuckTokenAtStartOfSession",
            bonusTo: "LuckTokenAtStartOfSession",
            bonusAmount: 1
        };
        props.setBonus(bonus);
    }

    const setSpellBonus = () => {
        const bonus: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Kobold",
            sourceCategory: "Ability",
            gainedAtLevel: props.level.level,
            name: "Knack",
            bonusName: "Plus1ToCastingSpells",
            bonusTo: "Spellcasting",
            bonusAmount: 1
        };
        props.setBonus(bonus);
    }

    const luckSelected = globalContext.bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Kobold" && b.name === "Knack" && b.bonusTo === "LuckTokenAtStartOfSession" && b.bonusName === "LuckTokenAtStartOfSession") !== undefined;
    const spellcastingSelected = globalContext.bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Kobold" && b.name === "Knack" && b.bonusTo === "Spellcasting" && b.bonusName === "Plus1ToCastingSpells") !== undefined;

    const req = !luckSelected && !spellcastingSelected;

    return (
        <div key="attackBonus">
            <div className="formNote">Knack</div>
            <label><input type="radio" name="SpecAttackBonus" onChange={() => setSpellBonus()} checked={spellcastingSelected}></input>&nbsp;+1&nbsp;Spellcasting</label>
            <label><input type="radio" name="SpecAttackBonus" onChange={() => setLuckBonus()} checked={luckSelected}></input>&nbsp;Luck token at start of session</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default PlusOneSpellCastingOrLuckToken;
import { Ancestry } from './../data/ancestries';
import { PDFDocument } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit';

import { modStr } from '../data/stats';
import { getGearRecordsAsSlots } from '../data/utilities';
import { Armor } from '../data/armor';


export const getPDFFillableFourZero = async (fourCharacters: any[], armors: Armor[], ancestries: Ancestry[]) => {

    // Fetch the PDF with form fields
    const formUrl = 'https://www.shadowdarklings.net/Fixed_Funnel_Sheet_v5.pdf';
    const formBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

    // Load the PDF with form fields
    const pdfDoc = await PDFDocument.load(formBytes);
    const page = pdfDoc.getPage(0);

    // Embed the Ubuntu font
    pdfDoc.registerFontkit(fontkit);

    const form = pdfDoc.getForm();

    for (let c = 1; c <= 4; c++) {
        const char = fourCharacters[c - 1];

        const nameField = form.getTextField("Name" + c);
        const ancestryField = form.getTextField("Ancestry" + c);
        const bgField = form.getTextField("Background" + c);
        const alignmentField = form.getTextField("Alignment" + c);
        const deityField = form.getTextField("Deity" + c);

        const strField = form.getTextField("Str" + c);
        const dexField = form.getTextField("Dex" + c);
        const conField = form.getTextField("Con" + c);
        const intField = form.getTextField("Int" + c);
        const wisField = form.getTextField("Wis" + c);
        const charField = form.getTextField("Cha" + c);

        const strModField = form.getTextField("StrBonus" + c);
        const dexModField = form.getTextField("DexBonus" + c);
        const conModField = form.getTextField("ConBonus" + c);
        const intModField = form.getTextField("IntBonus" + c);
        const wisModField = form.getTextField("WisBonus" + c);
        const charModField = form.getTextField("ChaBonus" + c);

        const hpField = form.getTextField("HP" + c);
        const acField = form.getTextField("AC" + c);

        const gpField = form.getTextField("GP" + c);
        const spField = form.getTextField("SP" + c);
        const cpField = form.getTextField("CP" + c);

        const attack1Field = form.getTextField("Attack" + c + "_1");
        const attack2Field = form.getTextField("Attack" + c + "_2");

        let gearRecords = getGearRecordsAsSlots(armors, char.gear, [], [], 0, 0, 0);

        gearRecords = gearRecords.sort((g1, g2) => {
            if (g1.type === "armor" && g2.type === "weapon") {
                return -1;
            }
            if (g1.type === "armor" && g2.type === "sundry") {
                return -1;
            }
            if (g1.type === "armor" && g2.type === "armor") {
                return g1.name < g2.name ? -1 : 1;
            }

            if (g1.type === "weapon" && g2.type === "sundry") {
                return -1;
            }
            if (g1.type === "weapon" && g2.type === "weapon") {
                return g1.name < g2.name ? -1 : 1;
            }

            if (g1.type === "sundry" && g2.type === "sundry") {
                return g1.name < g2.name ? -1 : 1;
            }

            return 0;
        });

        nameField.setText(char.name);
        ancestryField.setText(char.ancestry);
        bgField.setText(char.background);
        alignmentField.setText(char.alignment);
        deityField.setText(char.deity);

        strField.setText(char.stats.Strength.toString())
        dexField.setText(char.stats.Dexterity.toString())
        conField.setText(char.stats.Constitution.toString())
        intField.setText(char.stats.Intelligence.toString())
        wisField.setText(char.stats.Wisdom.toString())
        charField.setText(char.stats.Charisma.toString())

        strModField.setText(modStr(char.stats.Strength).toString())
        dexModField.setText(modStr(char.stats.Dexterity).toString())
        conModField.setText(modStr(char.stats.Constitution).toString())
        intModField.setText(modStr(char.stats.Intelligence).toString())
        wisModField.setText(modStr(char.stats.Wisdom).toString())
        charModField.setText(modStr(char.stats.Charisma).toString())

        hpField.setText(char.hp.toString());
        acField.setText(char.ac.toString());

        gpField.setText(char.gp.toString());
        spField.setText(char.sp.toString());
        cpField.setText(char.cp.toString());

        if (gearRecords.length > 0) {
            for (let gs = 0; gs <= gearRecords.length; gs++) {
                const thisSlot = form.getTextField("Gear" + c + "_" + (gs + 1));
                if (gearRecords[gs] && gearRecords[gs].name) {
                    if (gearRecords[gs].totalUnits > 1) {
                        thisSlot.setText(gearRecords[gs].name + " (" + gearRecords[gs].totalUnits + ")");
                    } else {
                        thisSlot.setText(gearRecords[gs].name);
                    }
                }
            }
        }

        // Strike out unavailable gear slots
        let slotNum = 0;
        let rowCount = 0;
        for (let g = 0; g < 18; g = g + 1) {
            slotNum = slotNum + 1;
            // let gearSlots = char.
            if (rowCount >= Math.max(char.stats.Strength, 10)) {
                const gearField = form.getTextField("Gear" + c + "_" + (g + 1));
                gearField.setText('XXXXXXXXXXXXXXXX');
            }
            rowCount = rowCount + 1;
        }

        if (char.attacks[0]) { attack1Field.setText(char.attacks[0]); }
        if (char.attacks[1]) { attack2Field.setText(char.attacks[1]); }

        let ancestryNote = "Ancestry note.";
        const ancestryName = char.ancestry as String;
        const theAncestry = ancestries.find((a) => a.name.toLowerCase().trim() === ancestryName.toLowerCase().trim());
        if (theAncestry) {
            ancestryNote = theAncestry.zeroLevelAncestryNote;
        }

        const langsKnown = "Speaks " + char.languagesKnown.map((l: any) => l.name).join(", ");

        let x = 0;
        let y = 0;
        switch (c) {
            case 1: x = 40; y = 400; break;
            case 2: x = 312; y = 400; break;
            case 3: x = 40; y = 33; break;
            case 4: x = 312; y = 33; break;
            default: break;
        }

        page.moveTo(x, y);
        page.drawText(ancestryNote, { size: 7 });
        page.moveTo(x, y + 355);
        page.drawText(langsKnown, { size: 7 });

    }

    // page.moveTo(40, 400);
    // page.drawText("1 Dwarf: Stout gives +2 HP (included above)", { size: 8 });

    // page.moveTo(312, 400);
    // page.drawText("2 Dwarf: Stout gives +2 HP (included above)", { size: 8 });

    // page.moveTo(40, 33);
    // page.drawText("3 Dwarf: Stout gives +2 HP (included above)", { size: 8 });

    // page.moveTo(312, 33);
    // page.drawText("4 Dwarf: Stout gives +2 HP (included above)", { size: 8 });


    // Save the PDF with filled form fields
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;

}
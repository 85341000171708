import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (

        <div className="App container">

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,100&family=Tillana&display=swap" rel="stylesheet" />      <div className="main">

                <div>
                    <img src="/ShadowDarklingLogoSmall_Trans.png" className="logo" alt="Garlikk the Goblin" title="Garlikk the Goblin"></img>
                    <h1 className="mainHeader">ShadowDarklings</h1>
                </div>

                <div id="error-page" >
                    <h1 className="mb-3">You have been devoured by Stingbats</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>Error: <i>{error.statusText || error.message}</i></p>

                    <p>
                        <img src="/StingbatSmall.png" width="400px" height="294px" alt="Stingbat" title="Stingbat"></img>
                    </p>
                </div >

            </div>
        </div>
    )

}
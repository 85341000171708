import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";

interface IProps {
    setBonus: (bonus: Bonus) => void;
    level: Level;
}

const Grit: React.FunctionComponent<IProps> = (props: IProps) => {

    
    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const options = ["Strength", "Dexterity"].map((a, index) => <option value={a} key={"Grit_" + a}>{a}</option>);
    options.unshift(<option value="" key={"selectGrit"}>-- select Grit stat --</option>);

    let currentValue = "";
    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Fighter" && b.name === "Grit");
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setGritBonus = (e: any) => {
        const bonus: Bonus = {
            sourceType: "Class",
            sourceName: "Fighter",
            sourceCategory: "Ability",
            gainedAtLevel: props.level.level,
            name: "Grit",
            bonusName: e.target.value,
            bonusTo: "AdvantageOnStatChecks"
        };
        props.setBonus(bonus);
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    return (
        <div>
            <div className="formNote">Grit</div>
            <select className={selectCss} onChange={(e) => setGritBonus(e)} value={currentValue}>{options}</select>
        </div>
    )

}

export default Grit;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { GearOwned, GearRecord } from "../../data/gear";
import { MagicItem } from "../../data/magicItem";
import { Treasure } from "../../data/treasure";
import { getGearRecords } from "../../data/utilities";

interface IProps {
    gear: GearOwned[];
    treasure: Treasure[];
    magicItems: MagicItem[],
}

const GearCarried: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getRows = (allGearRecords: GearRecord[]) => {

        const out: any[] = [];

        allGearRecords = allGearRecords.sort((g1, g2) => {

            if (g1.type === "armor" && g2.type === "weapon") {
                return -1;
            }
            if (g1.type === "armor" && g2.type === "sundry") {
                return -1;
            }
            if (g1.type === "armor" && g2.type === "armor") {
                return g1.name < g2.name ? -1 : 1;
            }
            if (g1.type === "armor" && g2.type === "treasure") {
                return -1;
            }

            if (g1.type === "weapon" && g2.type === "sundry") {
                return -1;
            }
            if (g1.type === "weapon" && g2.type === "weapon") {
                return g1.name < g2.name ? -1 : 1;
            }
            if (g1.type === "weapon" && g2.type === "treasure") {
                return -1;
            }

            if (g1.type === "sundry" && g2.type === "sundry") {
                return g1.name < g2.name ? -1 : 1;
            }
            if (g1.type === "sundry" && g2.type === "treasure") {
                 return -1;
            }

            return 0;
        });

        allGearRecords.forEach((gr) => {
            const totalUnits = gr.totalUnits === gr.quantity ? "" : <span> ({gr.totalUnits})</span>;
            out.push(
                <tr key={gr.name}>
                    <td className="left">{gr.name}{totalUnits}</td>
                    <td>{gr.quantity}</td>
                    <td>{gr.slots}</td>
                    <td>{gr.cost} {gr.currency}</td>
                </tr>
            )
        })

        if (out.length === 0) {
            out.push(
                <tr key={"no gear"}>
                    <td colSpan={4}>no gear</td>
                </tr>
            )
        }
        return out;
    }

    let allGearRecords = getGearRecords(props.gear, props.treasure, props.magicItems, globalContext.gold, globalContext.silver, globalContext.copper, globalContext.availableArmors, true);
    const totalSlots = allGearRecords.reduce((accumulator, currentValue) => accumulator + currentValue.slots, 0);

    const allRows = getRows(allGearRecords);

    return (
        <>
            <table className="gearTable">
                <thead>
                    <tr>
                        <th className="left">Item</th>
                        <th>Quantity</th>
                        <th>Slots</th>
                        <th>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {allRows}
                </tbody>
                {allRows.length > 1 &&
                    <tfoot>
                        <tr>
                            <td className="left">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>{totalSlots}</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tfoot>
                }
            </table>
            {/* <div><pre>{JSON.stringify(props.gear, null, 2)}</pre></div>
            <div><pre>{JSON.stringify(allGearRecords, null, 2)}</pre></div> */}
        </>
    )

}

export default GearCarried;
export interface DogAbility {
    name: string;
    desc: string;
}

export interface DogsLife {
    name: string;
    desc: string;
    statBonus: string;
}

export const dogAbilities: DogAbility[] = [
    {
        name: "Play Dead",
        desc: "You can lay down, slow your heartbeat, and stay motionless. Enemies will let sleeping dogs lie… "
    },
    {
        name: "Gold Retriever",
        desc: "You can detect and recover gold where others fail, whether it is in water, or hidden from sight."
    },
    {
        name: "Fleet-Footed",
        desc: "You are built for speed and have lightning-quick reflexes. Whenever there is a call for initiative, you may go first."
    },
    {
        name: "Pack Leader",
        desc: "Your war cry can inspire comrades and strike fear in foes. A particularly haunting howl may even cause foes to flee!"
    },
    {
        name: "Lone Wolf",
        desc: "You have an advantage when working alone, especially in situations that involve stealth."
    },
    {
        name: "Shield Guardian",
        desc: "Your protective instinct kicks in whenever you guard or defend a companion. You may confer your shield bonus to a friend or ally standing by your side."
    }
];

export const dogsLifes: DogsLife[] = [
    {
        name: "Lap Landers",
        desc: "You are too cute for words. +1 Charisma.",
        statBonus: "CHA"
    },
    {
        name: "Pugnacious Pugilists",
        desc: "Your family name is synonymous with boxing. +1 Constitution.",
        statBonus: "CON"
    },
    {
        name: "Blood Hounds",
        desc: "You know to identify and track your quarry. +1 Intelligence.",
        statBonus: "INT"
    },
    {
        name: "Shaggy Herders",
        desc: "You come from a family of shepherds and can marshal forces in battle. +1 Wisdom.",
        statBonus: "WIS"
    },
    {
        name: "High-King Rangers",
        desc: "Part of the noble Grey Company, you have been groomed for greatness. +1 Dexterity.",
        statBonus: "DEX"
    },
    {
        name: "Sea Wolves",
        desc: "Once were wolves, your pack are now raging naturalists. +1 Strength.",
        statBonus: "STR"
    }
];

export const dogNames = [
    "Arfagorn, Heir to Gonedog",
    "Halle Beagle",
    "Pomeranian Anderson",
    "Sherlock Bones",
    "Doctor Frankenschauzser",
    "Beowuff Great-Dane",
    "Donnie Barko",
    "Gandalf the Greyhound",
    "Bark Twain",
    "Bichon Joel",
    "Pablo Pucasso",
    "Pug Marley",
    "Dolly Pawton",
    "Goldie Hound",
    "Brad Pitbull",
    "William Shakespaw",
    "Muttley Crue",
    "Edgar Allan Poodle",
    "Tim (the Enchanterrier)"
];

export const koboldNames = [
    "Squee",
    "Glimmer",
    "Snik",
    "Ember",
    "Zephyr",
    "Fizzle",
    "Nibbles",
    "Bristle",
    "Scrabble",
    "Whisker",
    "Pebble",
    "Spark",
    "Soot",
    "Rustle",
    "Tinder",
    "Scorch",
    "Glimpse",
    "Chomp",
    "Muddle",
    "Clinker",
    "Scales",
    "Wiggle",
    "Glow",
    "Blink",
    "Scamper",
    "Sticky",
    "Blaze",
    "Flint",
    "Shimmer",
    "Snicker",
    "Whistle",
    "Pickle",
    "Scuttle",
    "Kindle",
    "Rumble",
    "Gleam",
    "Wobble",
    "Sizzle",
    "Crackle",
    "Dust",
    "Nimble",
    "Snatch",
    "Glisten",
    "Cinder",
    "Rattle",
    "Twinkle",
    "Swizzle",
    "Sneak",
    "Pepper",
    "Scuttlebutt",
    "Sly",
    "Bramble",
    "Smolder",
    "Shiver",
    "Spindle",
    "Gurgle",
    "Flare",
    "Sparks",
    "Whisk",
    "Snickerdoodle",
    "Puddle",
    "Scorchy",
    "Grit",
    "Fizz",
    "Wobblebottom",
    "Scamperoo",
    "Swoop",
    "Crunch",
    "Flicker",
    "Sniffle",
    "Twitch",
    "Gurglepuss",
    "Boggle",
    "Splinter",
    "Zigzag",
    "Sting",
    "Wriggle",
    "Rusty",
    "Snaggletooth",
    "Peekaboo",
    "Blister",
    "Glint",
    "Flaretop",
    "Whimper",
    "Chisel",
    "Squirm",
    "Scorchkin",
    "Gobble",
    "Glitter",
    "Flutter",
    "Squelch",
    "Wobblekins",
    "Scuttlebug",
    "Sooty",
    "Scurry",
    "Bristlebrush",
    "Flick",
    "Tinderfoot",
    "Swish"
];
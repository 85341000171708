import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Deity } from "../../data/deities";

interface IProps {
    activeSources: string[],
    deities: Deity[];
    selectedDeity: string;
    setDeity: (deity: string) => void;
    rollDeity: () => void;
    alignment: string;
    minimal: boolean;
}

const SelectDeity: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getOptions = () => {

        const getPantheon = (d: Deity) => {
            if (d.pantheon.trim() !== "") { return " of the " + d.pantheon; }
            return null;
        }

        const availableDeities = props.deities.filter((d) => props.activeSources.indexOf(d.sourceId) !== -1);
        const options = availableDeities.map((d) => <option value={d.name} key={d.name}>{d.name} ({d.alignments.join(", ")}) {getPantheon(d)} </option>);
        options.unshift(<option value="" key="selectDeity">-- select deity --</option>);
        return options;
    }


    const onSelectDeity = (e: any) => {
        props.setDeity(e.target.value);
    }

    const selectedDeity = props.deities.find((d) => d.name === props.selectedDeity);

    const getPriestAlignmentWarning = () => {

        if (globalContext.className === "Priest") {
            const theDeity = props.deities.find((d) => d.name === props.selectedDeity);
            if (theDeity === undefined) {
                return <div className="invalid">A Priest must select a deity</div>
            } else {
                if (theDeity.alignments.indexOf(props.alignment) === -1 && theDeity.alignments[0] !== "Alignment unknown") {
                    return <div className="invalid">A Priest's alignment must match their deity's alignment</div>
                }
            }
        }

        return "";
    }

    let priestValidation = getPriestAlignmentWarning();

    const selectCss = priestValidation !== "" ? "form-select redBorder" : "form-select";

    return (
        <div>

            <div className="formNote">Deity</div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => onSelectDeity(e)} value={props.selectedDeity}>{getOptions()}</select>
                <button className="btn btn-dark" onClick={() => props.rollDeity()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
            </div>

            {priestValidation !== "" &&
                <div className="valError">{priestValidation}</div>
            }

            {(selectedDeity && !props.minimal) &&
                <div className="spell">{selectedDeity.desc}</div>
            }
        </div>
    )

}

export default SelectDeity;
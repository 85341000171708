import { PyromancyTalents } from "../../data/classes";

interface IProps { }


const PyromancyTables: React.FunctionComponent<IProps> = (props: IProps) => {

    const pyromancyTalents = PyromancyTalents;

    const getHTML = (desc: string) => {
        return ({ __html: desc });
    }

    const getRows = () => {
        const out: any = [];
        pyromancyTalents.forEach((b, index) => {
            let noDupe = b.rerollDuplicates ? " (reroll duplicates)" : "";
            out.push(
                <tr key={"blt_" + index}>
                    <td>{b.min}</td>
                    <td><span dangerouslySetInnerHTML={getHTML(b.desc)} />{noDupe}</td>
                </tr>
            )
        })
        return out;
    }

    return (
        <div>
            <table className='classTalentsTable mb-2'>
                <thead>
                    <tr>
                        <th colSpan={2} className='header'>Pyromancy Talents</th>
                    </tr>
                    <tr>
                        <th>d12</th>
                        <th>Effect (die max = d12, DC step max = 18)</th>
                    </tr>
                </thead>
                <tbody>
                    {getRows()}
                </tbody>
            </table>
        </div>
    )
}

export default PyromancyTables;

import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { Spell, spells } from "../../data/spells";
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { getRandomIntInclusive } from "../../data/random";
import { getBonusByNameAndBonusTo, getRoustaboutSpellList, getSpellTierForClass } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    spellsKnown: Spell[];
    isMinimal: boolean;
    setBonus: (bonus: Bonus) => void;
    parentBonusId?: string;
}

const PickExtraSpell: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    // get maximum Tier of spells that character can learn
    let maxTier = 0;
    let availSpells: Spell[] = [];
    const theClass = globalContext.availableClasses.find((c) => c.name === globalContext.className);
    if (theClass) {
        const pickSpellBonuses = theClass.specialBonuses.filter((sb) => sb.specialName === "PickSpell" && sb.gainedAtLevel <= props.level.level);
        pickSpellBonuses.forEach((psb) => {
            if (psb.specialTier && psb.specialTier > maxTier) { maxTier = psb.specialTier; }
        })
        availSpells = globalContext.availableSpells.filter((s) => s.classes.indexOf(globalContext.className) !== -1 && getSpellTierForClass(globalContext.className, s.name) <= maxTier).sort((s1, s2) => s1.name < s2.name ? -1 : 1);

        // special case for Roustabout: can learn any spell from the core sources via Talent 12, maxTier = 1/2 level rounded down.
        if (theClass.name === "Roustabout") {
            availSpells = getRoustaboutSpellList(globalContext.availableClasses, globalContext.levels.length);
        }
    }

    const options = availSpells.map((s) => <option value={s.name} key={s.name}>{s.name}</option>);
    options.unshift(<option value="" key="selectSpell">-- select additional spell --</option>);

    let currentValue = "";
    // const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.gainedAtLevel === props.level.level && b.name === name && b.bonusTo === "PickExtraSpell");    
    let existingBonus: Bonus | undefined = undefined;
    if(props.parentBonusId) {
        existingBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
    } else {
        existingBonus = getBonusByNameAndBonusTo(globalContext.bonuses, "LearnExtraSpell", "PickExtraSpell", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource, props.parentBonusId);
    }

    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setSpellBonus = (spell: string) => {

        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "LearnExtraSpell",
            bonusTo: "PickExtraSpell",
            bonusName: spell,
        };
        if (props.parentBonusId) { bonus.parentBonusId = props.parentBonusId; }
        props.setBonus(bonus);
    }

    const getSpellDesc = () => {
        if (props.isMinimal) { return null; }
        if (currentValue !== "") {
            const theSpell = spells.find((s) => s.name === currentValue);
            if (theSpell) {
                const tier = getSpellTierForClass(globalContext.className, theSpell.name);
                return <div className="spell">Tier: {tier}, Range: {theSpell.range}, Duration: {theSpell.duration}. {theSpell.desc}</div>
            }
        }
        return null;
    }

    const rollSpell = () => {
        const randSpell = availSpells[getRandomIntInclusive(0, availSpells.length - 1)].name;
        if (props.spellsKnown.map((l) => l.name).indexOf(randSpell) !== -1) {
            rollSpell();
        } else {
            setSpellBonus(randSpell);
        }
    }

    const getIsDuplicate = () => {
        let thisSpell = props.spellsKnown.filter((l) => l.name === currentValue);
        if (thisSpell.length > 1) {
            return <span className="valError ms-3">Duplicate</span>
        }
        return null;
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    return (
        <div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setSpellBonus(e.target.value)} value={currentValue}>{options}</select>
                <button className="btn btn-dark" onClick={(e) => rollSpell()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
                {getIsDuplicate()}
            </div>
            {getSpellDesc()}
        </div>
    )

}

export default PickExtraSpell;
import { Spell, spells } from '../../data/spells';

interface IProps {
}

const SpellsPage: React.FunctionComponent<IProps> = (props: IProps) => {

    const getSpells = () => {
        let s = [...spells];

        const convertSpell = (s: Spell) => {
            return {
                name: s.name,
                tierByClass: s.tierByClass,
                classes: s.classes,
                duration: s.duration,
                range: s.range,
                desc: s.desc,
                castWithAdv: s.castWithAdv
            }
        }

        let s2 = s.map((s) => convertSpell(s))

        s2 = s2.filter((s) => s.classes[0] !== "Witch");

        s2.forEach((s) => {
            const indexOfWitch = s.classes.indexOf("Witch");
            if(indexOfWitch !== -1) {
                s.classes = s.classes.splice(indexOfWitch, 1);
                s.tierByClass = s.tierByClass.splice(indexOfWitch, 1);
            }
        })

        s2.sort((s1: any, s2: any) => s1.name < s2.name ? -1 : 1);

        return s2;
    }


    return (
        <div>
            <h1>Spells</h1>
            <div>
                <pre>{JSON.stringify(getSpells(), null, 2)}</pre>
            </div>

        </div>
    )
}

export default SpellsPage;
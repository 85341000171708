import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByNameAndBonusName, getBonusByNameAndBonusTo } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const PlusTwoSTRCONOrPlusOneAC: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setPlusTwoSTRBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrCONOrPlus1AC",
            bonusName: "StatBonus",
            bonusTo: "STR:+2"
        };
        props.setBonus(bonus);
    }

    const setPlusTwoCONBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrCONOrPlus1AC",
            bonusName: "StatBonus",
            bonusTo: "CON:+2"
        };
        props.setBonus(bonus);
    }

    const setPlusOneToAC = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus2STROrCONOrPlus1AC",
            bonusName: "BeastMasterPlus1AC",
            bonusTo: ""
        };
        props.setBonus(bonus);
    }

    const plusTwoSTRSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "Plus2STROrCONOrPlus1AC", "STR:+2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plusTwoCONSelected = getBonusByNameAndBonusTo(globalContext.bonuses, "Plus2STROrCONOrPlus1AC", "CON:+2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plusOneACSelected = getBonusByNameAndBonusName(globalContext.bonuses, "Plus2STROrCONOrPlus1AC", "BeastMasterPlus1AC", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;

    const req = !plusTwoSTRSelected && !plusTwoCONSelected && !plusOneACSelected;

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setPlusTwoSTRBonus()} checked={plusTwoSTRSelected}></input>&nbsp;STR {globalContext.finalStats.Strength}</label>
            <label><input type="radio" onChange={() => setPlusTwoCONBonus()} checked={plusTwoCONSelected}></input>&nbsp;CON {globalContext.finalStats.Constitution}</label>
            <label><input type="radio" onChange={() => setPlusOneToAC()} checked={plusOneACSelected}></input>&nbsp;+1 unarmored AC</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default PlusTwoSTRCONOrPlusOneAC;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { displayStats } from "../../data/utilities";
import { getClassLottery, getPercentages } from "../../special/randomChar";
import { CharClass } from "../../data/classes";

interface IProps {
    randomlySelectedClassRoll: number;
    randomlySelectedClass: string;
    randomType: string;
    rollStats: () => void;
    charClasses: CharClass[];
    minimal: boolean;
}

const RollStats: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const stats = globalContext.stats;

    const checkIfNoneO14OrHigher = () => {
        return (stats.Strength < 14 && stats.Dexterity < 14 && stats.Constitution < 14 && stats.Intelligence < 14 && stats.Wisdom < 14 && stats.Charisma < 14);
    }

    const displayClassRecommendations = () => {

        const lottery = getClassLottery(stats, props.charClasses);
        const percentages = getPercentages(lottery, props.charClasses);
        return percentages;
    }

    const getRandomNote = () => {
        let out: string[] = [];
        if (props.randomlySelectedClassRoll !== -1) {

            if (props.randomType === "Mixed") {
                out.push(`Rolled ${props.randomlySelectedClassRoll}/100 for random class`);
                out.push(`so selected ${props.randomlySelectedClass}`)
            }

            if (props.randomType === "Best Fit") {
                out.push(`Selected ${props.randomlySelectedClass}`)
            }

        }

        return out.join(", ");
    }

    const roustaboutIsEnabled = props.charClasses.find((c) => c.name === "Roustabout") !== undefined;
    let roustaboutNote: any = null;
    if (roustaboutIsEnabled) {
        roustaboutNote = <>, or you can be a Roustabout</>;
    }


    return (
        <div className="sectionBorder">
            <h2>Stat Rolls</h2>
            <div className="">

                {stats.Strength === 0 &&
                    <div>
                        <button className="btn btn-dark" onClick={() => props.rollStats()}>Roll for Stats</button>
                        <div className="valError">Please roll for stats</div>
                    </div>
                }

                {stats.Strength !== 0 && checkIfNoneO14OrHigher() &&
                    <div><button className="btn btn-dark" onClick={() => props.rollStats()}>Re-Roll Stats</button> None of your stats are 14 or higher, so you may opt to re-roll{roustaboutNote}</div>
                }

                {stats.Strength > 0 &&
                    <div>
                        <div>{displayStats(stats)}</div>

                        {(globalContext.className !== "Level 0" && !props.minimal) &&
                            <div>Rankings: {displayClassRecommendations()}</div>
                        }

                        {(props.randomlySelectedClass !== "" && !props.minimal) &&
                            <div>{getRandomNote()}</div>
                        }

                    </div>
                }


            </div>
        </div>
    )

}

export default RollStats;
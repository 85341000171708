import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import StatBonus from "./StatBonus";
import { Spell } from "../../data/spells";
import RollForRoustaboutExtraTalentsOnRoll2 from "../charGen/rollForRoustaboutExtraTalentsOnRoll2";
import { getBonusByName } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
    spellsKnown: Spell[];
    isCheatMode: boolean;
    isMinimal: boolean;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean, parentBonusId?: string) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialCategoryName: string) => void;
    repeatNumber?: number;
    parentBonusId?: string;

}

const PlusOneStatAndRollAnotherTalent: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setPlusOneStatBonus = (bonus: Bonus) => {
        bonus.name = "StatBonus";
        props.setBonus(bonus);
    }

    const getTheBonus = () => {
        let theBonus: Bonus | undefined = undefined;
        if (props.parentBonusId !== undefined) {
            theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
        } else {
            let name = "PlusOneStatAndRollAnotherTalent";
            theBonus = getBonusByName(globalContext.bonuses, name, props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
        }
        if (theBonus) {
            return theBonus;
        }
        return undefined;
    }

    const getTheChildBonus = () => {
        const theParentBonus = getTheBonus();
        const theBonus = globalContext.bonuses.find((b) => b.parentBonusId === theParentBonus?.bonusId)
        if (theBonus) { return theBonus; }
        return undefined;
    }

    const theBonus = getTheBonus();
    const theChildBonus = getTheChildBonus();

    return (
        <>
            {/* <div>parentBonusId: {theBonus?.bonusId}</div>
            <div>bonusId: {theChildBonus?.bonusId}</div> */}
            <StatBonus
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                inputName={"statBonus"}
                options={["STR", "DEX", "CON", "INT", "WIS", "CHA"]}
                size={1}
                setBonus={(bonus: Bonus) => setPlusOneStatBonus(bonus)}
                level={props.level}
                parentBonusId={theBonus?.bonusId}
                bonusId={theChildBonus?.bonusId}
            />

            {theChildBonus?.bonusId !== undefined &&
                <RollForRoustaboutExtraTalentsOnRoll2
                    isSpecialTalent={false}
                    specialTalents={[]}
                    spellsKnown={props.spellsKnown}
                    isAmbitionTalent={props.sourceName === "Human Ambition"}
                    boonPatron={""}
                    boonSource={""}
                    isCheatMode={props.isCheatMode}
                    isMinimal={props.isMinimal}
                    theClass={globalContext.className}
                    setBonus={(bonus: Bonus) => props.setBonus(bonus)}
                    onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                    onSetRolledTalent={props.onSetRolledTalent}
                    onSetSpecialTalent={props.onSetSpecialTalent}
                    level={props.level}
                    setRolledTalent={props.onSetRolledTalent}
                    parentBonusId={theChildBonus?.bonusId}
                />
            }

            {theChildBonus?.bonusId === undefined &&
                <div className="indented"><div className="valError">Please select a stat bonus before selecting another Talent. </div></div>
            }
        </>

    )

}

export default PlusOneStatAndRollAnotherTalent;
import { Bonus } from "./bonus";
import { MagicItem } from "./magicItem";

export interface Stats {
    Strength: number,
    Dexterity: number;
    Constitution: number;
    Intelligence: number;
    Wisdom: number;
    Charisma: number;
}

export const modNum = (stat: number) => {
    if (stat >= 18) { return 4; }
    if (stat >= 16) { return 3; }
    if (stat >= 14) { return 2; }
    if (stat >= 12) { return 1; }
    if (stat >= 10) { return 0; }
    if (stat >= 8) { return -1; }
    if (stat >= 6) { return -2; }
    if (stat >= 4) { return -3; }
    if (stat >= 0) { return -4; }
    return -100;
}

export const modStr = (stat: number) => {
    const num = modNum(stat);
    if (num === -100) { return "-"; }
    if (num >= 0) { return "+" + num; }
    return num;
}

export const plusMinus = (num: number) => {
    if (num >= 0) {
        return "+" + num.toString();
    }
    return num;
}

export const getStatsIncludingBonuses = (theStats: Stats, theBonuses: Bonus[], magicItems: MagicItem[]) => {
    let newStats = { ...theStats };

    theBonuses.forEach((b) => {
        if (b.bonusName === "StatBonus") {
            const sb = b.bonusTo.split(":");
            const statAbr = sb[0];
            const statBon = sb[1];

            if (statAbr === "STR") { newStats.Strength = newStats.Strength + parseInt(statBon, 10); }
            if (statAbr === "DEX") { newStats.Dexterity = newStats.Dexterity + parseInt(statBon, 10); }
            if (statAbr === "CON") { newStats.Constitution = newStats.Constitution + parseInt(statBon, 10); }
            if (statAbr === "INT") { newStats.Intelligence = newStats.Intelligence + parseInt(statBon, 10); }
            if (statAbr === "WIS") { newStats.Wisdom = newStats.Wisdom + parseInt(statBon, 10); }
            if (statAbr === "CHA") { newStats.Charisma = newStats.Charisma + parseInt(statBon, 10); }
        }
    })

    magicItems.forEach((mi) => {
        if (mi.properties) {
            const setStatProp = mi.properties.find((p) => p.indexOf("SetStat") !== -1);
            if(setStatProp) {
                const statAndBonus = setStatProp.split(":")[1].split("-"); // example: "SetStat:CON-18"
                const statAbr = statAndBonus[0];
                const statVal = statAndBonus[1];

                if (statAbr === "STR") { newStats.Strength = parseInt(statVal, 10); }
                if (statAbr === "DEX") { newStats.Dexterity = parseInt(statVal, 10); }
                if (statAbr === "CON") { newStats.Constitution = parseInt(statVal, 10); }
                if (statAbr === "INT") { newStats.Intelligence = parseInt(statVal, 10); }
                if (statAbr === "WIS") { newStats.Wisdom = parseInt(statVal, 10); }
                if (statAbr === "CHA") { newStats.Charisma = parseInt(statVal, 10); }
            }
        }

    })

    return newStats;
}

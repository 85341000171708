import 'react-responsive-modal/styles.css';

import { Modal } from 'react-responsive-modal';
import { useContext, useState } from "react";
import { GlobalContextType } from "../context";
import { CreateCharacterContext } from "../createCharacter";
import { Creature } from "../../data/animals";
import AnimalDisplay from "./animalDisplay";

interface IProps {
    openModal: boolean;
    onOpenModal: (modal: string) => void;
    onCloseModal: (modal: string) => void;
}

const AnimalCompanionList: React.FunctionComponent<IProps> = (props: IProps) => {

    const [filter, setFilter] = useState("");

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);
    let allAnimals = [...globalContext.availableAnimals];

    const getAnimals = () => {
        let out: any[] = [];

        const animalSort = (a1: Creature, a2: Creature) => {
            if (a1.level < a2.level) { return -1; }
            if (a1.level === a2.level) {
                return a1.name < a2.name ? -1 : 1;
            }
            return 1;
        }

        if (filter.trim() !== "") {
            allAnimals = allAnimals.filter((s) => s.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1)
        }

        allAnimals = allAnimals.sort(animalSort);

        let theLevel = -1;
        allAnimals.forEach((a, index) => {
            const thisLevel = a.level;
            if (thisLevel > theLevel) {
                theLevel = thisLevel;
                out.push(<h2 className="mt-5" key={theLevel}>Level {theLevel}</h2>)
            }
            out.push(<AnimalDisplay key={"alist" + index} animalName={a.name} />);

        })

        if (out.length === 0) {
            out.push(<div key={"none"}>No animals match the filter</div>)
        }

        return out;
    }

    return (
        <>
            <button className="btn btn-dark btn-sm mb-2" onClick={() => props.onOpenModal("animals")}>Animal List</button>

            {props.openModal &&
                <Modal open={props.openModal} onClose={() => props.onCloseModal("animals")} classNames={{ modal: 'customModal' }}>
                    <h2>Animal Companion List</h2>

                    <input className="form-control mb-2" id="filter" type="text" placeholder="Filter by name" onChange={(e) => setFilter(e.currentTarget.value)} value={filter}></input>

                    {getAnimals()}
                </Modal>
            }
        </>
    )

}

export default AnimalCompanionList;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";


interface IProps {
    isHumanAmbitionTalentOrBoon: boolean;
    setBonus: (bonus: Bonus) => void;
    level: Level;
}

const ChooseWarlockTalentOrPatronBoon: React.FunctionComponent<IProps> = (props: IProps) => {

    let sourceType = "Class";
    let sourceName = "Warlock";
    if(props.isHumanAmbitionTalentOrBoon) {
        sourceType = "Ancestry";
        sourceName = "Human Ambition";
    }

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setSelectWarlockTalentBonus = () => {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: "TalentOrBoon",
            gainedAtLevel: props.level.level,
            name: "ChooseWarlockTalentOrPatronBoon",
            bonusName: "",
            bonusTo: "WarlockTalent"
        };
        props.setBonus(bonus);
    }

    const setSelectPatronBonus = () => {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: "TalentOrBoon",
            gainedAtLevel: props.level.level,
            name: "ChooseWarlockTalentOrPatronBoon",
            bonusName: "",
            bonusTo: "PatronBoon"
        };
        props.setBonus(bonus);
    }

    const warlockTalentSelected = globalContext.bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === "TalentOrBoon" && b.gainedAtLevel === props.level.level && b.name === "ChooseWarlockTalentOrPatronBoon" && b.bonusTo === "WarlockTalent") !== undefined;
    const patronBoonSelected = globalContext.bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === "TalentOrBoon" && b.gainedAtLevel === props.level.level && b.name === "ChooseWarlockTalentOrPatronBoon" && b.bonusTo === "PatronBoon") !== undefined;

    const req = !warlockTalentSelected && !patronBoonSelected;

    return (
        <>
            <div className="mb-2">
                <div className="formNote"><b>Warlock Talent or Patron Boon</b></div>
                <div>
                    <label className="small"><input type="radio" checked={warlockTalentSelected} onChange={() => setSelectWarlockTalentBonus()} /> Warlock Talent</label>
                    <label className="small"><input type="radio" checked={patronBoonSelected} onChange={() => setSelectPatronBonus()} /> Patron Boon</label>
                </div>
                {req &&
                    <div className="valError">Please select warlock talent or patron boon</div>
                }
                {!warlockTalentSelected && globalContext.patron === "" &&
                    <div className="valError">Please select a patron</div>
                }
            </div>
        </>
    )

}

export default ChooseWarlockTalentOrPatronBoon;
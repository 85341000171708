interface IProps { }


const PlagueDoctorTables: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <div>
            <table className='herbTable elixirTable mb-2'>
                <tbody>
                    <tr className='herbTableHeader'>
                        <th colSpan={2}>Elixir Crafting</th>
                    </tr>
                    <tr>
                        <th>DC</th>
                        <th>Effect</th>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td><b>Stenchwort.</b> Immune to smells and cures paralysis</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td><b>Panacea.</b> Ends one poison, disease, or plague</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td><b>Inoculant.</b> Receive ADV on a check vs. a specific hazard type you name. Examples: Fire, acid, necrosis</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td><b>Tonic.</b> Heals 1 HP. Stabilizes and revives</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td><b>Toxin.</b> DC 12 CON check or paralyzed for 1d4 rounds</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td><b>Acid.</b> Target takes d6 damage equal to half your level rounded down</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td><b>Plague.</b> DC 12 CON check or 1d6 permanent damage. Check daily to fully recover or take another 1d6</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td><b>Pheromone.</b> DC 12 WIS check or living creatures are calmed and consider you a reluctant friend for 5 rounds or until they are attacked by you or an ally</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td><b>Remedy.</b> Heals d6 equal to one plus half your level rounded down</td>
                    </tr>
                </tbody>
            </table>

            <p className="mb-4">You can use the table on pg. 27 of Cursed Scroll #2 to craft more elixirs. These poisons require a DC 14 INT check. Refer to the “use” column for the delivery method modifier.</p>

            <table className='herbTable elixirTable mb-4'>
                <tbody>
                    <tr className='herbTableHeader'>
                        <th colSpan={2}>Delivery Method</th>
                    </tr>
                    <tr>
                        <th>Bonus</th>
                        <th>Type</th>
                    </tr>
                    <tr>
                        <td>+3</td>
                        <td><b>Mask Infusion.</b> Lasts up to 1 day or until activated</td>
                    </tr>
                    <tr>
                        <td>+1</td>
                        <td><b>Injest.</b> Quaffed like a potion or otherwise eaten</td>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td><b>Injure.</b> Skin must be punctured after elixir is applied to a weapon like a stiletto, rapier, or dart</td>
                    </tr>
                    <tr>
                        <td>-1</td>
                        <td><b>Touch.</b> Skin must simply be touched by the elixir</td>
                    </tr>
                    <tr>
                        <td>-3</td>
                        <td><b>Inhaled.</b> Gas covers near radius</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default PlagueDoctorTables;

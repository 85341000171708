import EditMagicItemsDetails from "./editMagicItemsDetails";
import ListMagicItems from "./listMagicItems";
import Modal from "react-responsive-modal";
import { GearOwned } from "../../data/gear";
import { MagicItem } from "../../data/magicItem";
import { useState } from 'react';

interface IProps {
    openModal: boolean;
    magicItemsCarried: MagicItem[];
    magicItemCatalog: MagicItem[];
    gearOwned: GearOwned[];
    gearSlotsUsed: number;
    gearSlotsTotal: number;

    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;

    onAddMagicItem: (itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string, properties: string[]) => void;
    onEditMagicItem: (id: string, itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string) => void;
    onDiscardMagicItem: (id: string) => void;
}

const GetMagicItems: React.FunctionComponent<IProps> = (props: IProps) => {

    const [addOrEditItemFormIsOpen, setAddOrEditItemFormIsOpen] = useState(false);
    const [itemId, setItemId] = useState("");

    const onCloseModal = () => {
        props.onCloseModal("magicItems");
    }

    const editMagicItem = (id: string) => {
        setItemId(id);
        setAddOrEditItemFormIsOpen(true);
    }

    const handleOnAddMagicItem = (itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string, properties: string[]) => {
        setAddOrEditItemFormIsOpen(false);
        props.onAddMagicItem(itemType, itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, virtue, flaw, trait, properties);
    }

    const handleOnEditMagicItem = (id: string, itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string) => {
        setAddOrEditItemFormIsOpen(false);
        props.onEditMagicItem(id, itemType, itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, virtue, flaw, trait);
    }

    const onCancel = () => {
        setItemId("");
        setAddOrEditItemFormIsOpen(false);
    }

    return (
        <>
            <button onClick={() => props.onOpenModal("magicItems")} className="btn btn-dark btn-sm me-1">Magic Items</button>
            {props.openModal &&
                <Modal open={props.openModal} onClose={() => onCloseModal()} classNames={{ modal: 'customModal' }}>

                    <div>

                        {!addOrEditItemFormIsOpen &&
                            <>
                                <div className="mb-3"><b>Gear Slots:</b> {props.gearSlotsUsed} of {props.gearSlotsTotal}</div>

                                <div className="container">

                                    <button className="btn btn-dark mb-3" onClick={() => editMagicItem("")}>Add Magic Item</button>

                                    <h3>Magic Items</h3>

                                    <ListMagicItems
                                        magicItemsCarried={props.magicItemsCarried}
                                        onEditMagicItem={editMagicItem}
                                        onDiscardMagicItem={props.onDiscardMagicItem}
                                    />

                                </div>
                            </>
                        }

                        {addOrEditItemFormIsOpen &&
                            <div className="container">
                                <EditMagicItemsDetails
                                    itemId={itemId}
                                    magicItemCatalog={props.magicItemCatalog}
                                    magicItemsCarried={props.magicItemsCarried}
                                    onAddMagicItem={(itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string, properties: string[]) => handleOnAddMagicItem(itemType, itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, virtue, flaw, trait, properties)}
                                    onEditMagicItem={(id: string, itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string) => handleOnEditMagicItem(id, itemType, itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, virtue, flaw, trait)}
                                    onCancel={() => onCancel()}
                                />
                            </div>
                        }

                    </div>

                </Modal >
            }
        </>
    )

}

export default GetMagicItems;
import { useContext } from "react";
import { Bonus } from "../../data/bonus";

import { GlobalContextType } from "../context";
import { CreateCharacterContext } from "../createCharacter";
import { Level } from "../../data/level";
import { Weapon } from "../../data/weapons";
import { getBonusByBonusName } from "../../data/utilities";

interface IProps {
    weapons: Weapon[];
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    name: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
    parentBonusId?: string;
}

const LearnANewWeapon: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const options = props.weapons.sort((w1, w2) => w1.name < w2.name ? -1 : 1).map((w) => <option value={w.name} key={w.name}>{w.name}</option>);
    options.unshift(<option value="" key="selectWeaponMastery">-- select weapon --</option>);

    const getTheBonus = () => {
        let theBonus: Bonus | undefined = undefined;
        if (props.parentBonusId) {
            theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
        } else {
            theBonus = getBonusByBonusName(globalContext.bonuses, "LearnWeapon", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource)
        }
        return theBonus;
    }

    const getCurrentValue = () => {
        let val = "";
        const theBonus = getTheBonus();
        if (theBonus) {
            val = theBonus.bonusTo;
        }
        return val;
    }

    const setLearnWeaponBonus = (bonusTo: string) => {
        const theBonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: props.name, // "LearnMeleeWeaponOrPlusOneMeleeAtk",
            bonusName: "LearnWeapon",
            bonusTo: bonusTo,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        }
        if (props.parentBonusId) { theBonus.parentBonusId = props.parentBonusId; }
        props.setBonus(theBonus);
    }

    const selectCss = getCurrentValue() === "" ? "form-select redBorder" : "form-select";

    return (
        <div>
            <div className="formNote">Learn a weapon</div>
            <select className={selectCss} onChange={(e) => setLearnWeaponBonus(e.target.value)} value={getCurrentValue()}>{options}</select>
        </div>
    )

}

export default LearnANewWeapon;
import { useNavigate } from 'react-router-dom';
import { arcaneLibraryCursedScroll1_Diablerie_URL, arcaneLibraryCursedScroll2_RedSands_URL, arcaneLibraryCursedScroll3_MidnightSun_URL, arcaneLibraryShadowDarkURL, arcaneLibraryURL, bardAndRangerPDFDropboxURL, koboldAncestryDiscordURL } from '../../special/links';

interface IProps {
}

const FAQ: React.FunctionComponent<IProps> = (props: IProps) => {

    const navigate = useNavigate();

    return (
        <div>
            <h1>FAQ</h1>

            <div className="twoCols">

                <div>
                    <div className="faqHead">So what's this then?</div>
                    <div className="faqBody">This is a character generator based on the <a href={arcaneLibraryShadowDarkURL} target="_blank" rel="noreferrer">ShadowDark Roleplaying Game</a>, plus content from the Cursed Scrolls 'zines (

                        <a href={arcaneLibraryCursedScroll1_Diablerie_URL} target="_blank" rel="noreferrer">#1</a>,&nbsp;
                        <a href={arcaneLibraryCursedScroll2_RedSands_URL} target="_blank" rel="noreferrer">#2</a>,&nbsp;and&nbsp;
                        <a href={arcaneLibraryCursedScroll3_MidnightSun_URL} target="_blank" rel="noreferrer">#3</a>) and some of Kelsey's extras (
                        <a href={bardAndRangerPDFDropboxURL} target="_blank" rel="noreferrer">Bard and Ranger</a> and the&nbsp;
                        <a href={koboldAncestryDiscordURL} target="_blank" rel="noreferrer">Kobold ancestry</a>).

                    </div>
                    <div className="faqBody">This site also contains material from some 3rd party creators, with the kind support of the Arcane Library.</div>

                </div>
                <div>
                    <div className="faqHead">It this official?</div>
                    <div className="faqBody">Yes - this site is owned by the <a href={arcaneLibraryURL} target='_blank' rel="noreferrer">Arcane Library</a>, which publishes the Shadowdark RPG.</div>
                </div>
                <div>
                    <div className="faqHead">What other sites are out there that do this sort of stuff?</div>
                    <div className="faqBody">Check out <a href="https://www.shadowdork.com/" target="_blank" rel="noreferrer">www.shadowdork.com</a></div>
                </div>
                <div>
                    <div className="faqHead">How can I contact you with bug reports or feature requests?</div>
                    <div className="faqBody">Check out the <a href="#!" onClick={() => navigate('/contact')} rel="noreferrer">Contact page</a>.</div>
                </div>

            </div>
        </div>
    )
}

export default FAQ;
interface IProps {
    gold: number;
    silver: number;
    copper: number;
}

const DisplayGold: React.FunctionComponent<IProps> = (props: IProps) => {

    const getCoin = () => {

        let coins = "";

        if (props.gold !== 0) {
            coins += props.gold + " gp";
        };
        if (props.silver !== 0) {
            if (props.gold !== 0) {
                coins += ", ";
            }
            coins += props.silver + " sp";
        }
        if (props.copper !== 0) {
            if (props.silver !== 0 || props.gold !== 0) {
                coins += ", ";
            }
            coins += props.copper + " cp";
        }
        if (coins.length !== 0) {
            return <span>{coins}</span>
        } else {
            return <span>You have no coins.</span>
        }
    }

    return (
        <>{getCoin()}</>
    )

}

export default DisplayGold;
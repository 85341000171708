import { getArmorById } from "./armor";
import { getWeaponById } from "./weapons";

export interface Sundry {
    id: string;
    name: string;
    cost: number;
    currency: string;
    slots: number;
    quantityPerSlot: number;
    freeToCarry: number;
    isAmmo?: boolean; 
}

export const sundries: Sundry[] = [
    { id: "s1", name: "Arrows", cost: 1, currency: "gp", slots: 1, quantityPerSlot: 20, freeToCarry: 0, isAmmo: true },
    { id: "s2", name: "Backpack", cost: 2, currency: "gp", slots: 1, quantityPerSlot: 1, freeToCarry: 1 },
    { id: "s3", name: "Caltrops (one bag)", cost: 5, currency: "sp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    // { id: "s4", name: "Coin", cost: 2, currency: "gp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s5", name: "Crossbow bolts", cost: 1, currency: "gp", slots: 1, quantityPerSlot: 20, freeToCarry: 0, isAmmo: true },
    { id: "s6", name: "Crowbar", cost: 5, currency: "sp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s7", name: "Flask or bottle", cost: 3, currency: "sp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s8", name: "Flint and steel", cost: 5, currency: "sp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s9", name: "Grappling hook", cost: 1, currency: "gp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s10", name: "Iron spikes", cost: 1, currency: "gp", slots: 1, quantityPerSlot: 10, freeToCarry: 0 },
    { id: "s11", name: "Lantern", cost: 5, currency: "gp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s12", name: "Mirror", cost: 10, currency: "gp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s13", name: "Oil, flask", cost: 5, currency: "sp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s14", name: "Pole", cost: 5, currency: "sp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s15", name: "Rations", cost: 5, currency: "sp", slots: 1, quantityPerSlot: 3, freeToCarry: 0 },
    { id: "s16", name: "Rope, 60'", cost: 1, currency: "gp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 },
    { id: "s17", name: "Torch", cost: 5, currency: "sp", slots: 1, quantityPerSlot: 1, freeToCarry: 0 }
];

export const getSundryById = (id: string) => {
    return sundries.find((w) => w.id === id);
}

export const getGearById = (id: string) => {
    const weapon = getWeaponById(id);
    if(weapon) { return weapon; }
    const armor = getArmorById(id);
    if(armor) { return armor; }
    const sundry = getSundryById(id);
    if(sundry) { return sundry; }
    return undefined;
}
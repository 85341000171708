import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import {getWarlockTalentRandomBoonBonus } from "../../data/utilities";
import { getRandomIntInclusive } from "../../data/random";
import SelectTalentDetails from "./selectTalentDetails";
import { Spell } from "../../data/spells";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonSource: string;
    isAmbitionTalent: boolean;
    isMinimal: boolean;
    isCheatMode: boolean;
    spellsKnown: Spell[];
    setBonus: (bonus: Bonus) => void;
    level: Level;
    // setRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
}

const GainRandomBoon: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getSelectedRandomBoon = () => {
        const randomBoon = getWarlockTalentRandomBoonBonus(globalContext.bonuses, props.sourceType, props.sourceName, "Boon", props.level.level)
        return randomBoon;
    }

    const setRandomBoonBonus = () => {

        // select a random boon.
        const patrons = globalContext.availableWarlockPatrons;
        const randPatronNum = getRandomIntInclusive(0, patrons.length - 1);
        const theRandomPatron = patrons[randPatronNum]; 
        if (theRandomPatron) {
            const randomPatronBoons = globalContext.availablePatronBoons.find((pb) => pb.patronId === theRandomPatron.id);
            if (randomPatronBoons) {
                const roll = getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6); 
                const theTalent = randomPatronBoons.talents.find((t) => roll >= t.min && roll <= t.max);
                if (theTalent) {
                    const bonus: Bonus = {
                        sourceType: props.sourceType,
                        sourceName: props.sourceName,
                        sourceCategory: "Boon",
                        gainedAtLevel: props.level.level,
                        name: theTalent.name,
                        bonusName: theTalent.name,
                        boonPatron: theRandomPatron.name,
                        boonSource: "WarlockTalentRandomBoon",
                        bonusTo: ""
                    };
                    props.setBonus(bonus);
                }
            }
        }
    }

    const getBonusPatron = (bonus: Bonus | undefined) => {
        if (bonus) {
            const thePatron = globalContext.availableWarlockPatrons.find((p) => p.name === bonus.boonPatron);
            return thePatron;
        }
        return undefined;
    }

    const selectedRandomBoon = getSelectedRandomBoon();
    const selectedRandomBoonPatron = getBonusPatron(selectedRandomBoon);
    const patronTalents = globalContext.availablePatronBoons.find((pb) => pb.patronId === selectedRandomBoonPatron?.id);
    const selectedRandomBoonPatronName = selectedRandomBoonPatron === undefined ? "" : selectedRandomBoonPatron.name;
    const selectedBoonDesc = patronTalents === undefined ? "" : patronTalents.talents.find((p) => p.name === selectedRandomBoon?.bonusName)?.desc;


    return (
        <>
            {selectedRandomBoon === undefined &&
                <div >
                    <button className="btn btn-dark" onClick={() => setRandomBoonBonus()}>Roll for Random Boon</button>
                    <div className="valError">Please roll for a random boon</div>
                </div>
            }

            {selectedRandomBoon !== undefined &&
                <>
                    <div><b>Boon from {selectedRandomBoonPatronName}:</b> {selectedBoonDesc}</div>
                    <div>
                        <SelectTalentDetails
                            isSpecialTalent={true}
                            thisLevel={props.level}
                            specialTalents={patronTalents?.talents === undefined ? [] : patronTalents.talents}
                            sourceType={props.sourceType}
                            sourceName={props.sourceName}
                            boonPatron={selectedRandomBoonPatronName}
                            boonSource="WarlockTalentRandomBoon"
                            sourceCategory={"Boon"}
                            isAmbitionTalent={props.isAmbitionTalent}
                            isCheatMode={props.isCheatMode}
                            isMinimal={props.isMinimal}
                            theClass={"Warlock"}
                            spellsKnown={props.spellsKnown}
                            includeSelectTalentOption={true}
                            onSetBonus={(bonus: Bonus) => props.setBonus(bonus)}
                            onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                            onSetRolledTalent={props.onSetRolledTalent}
                            onSetSpecialTalent={props.onSetSpecialTalent}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default GainRandomBoon;
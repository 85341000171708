import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";


import PickTalent from '../talents/PickTalent';
import SelectTalentDetails from "./selectTalentDetails";
import { Bonus } from "../../data/bonus";
import { Level } from '../../data/level';
import { Spell } from '../../data/spells';
import { Talent } from "../../data/classes";
import { getAllWarlockPatrons } from '../../data/warlockPatrons';
import { getRandomIntInclusive } from '../../data/random';

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string,
    boonSource: string;
    thisLevel: Level;
    spellsKnown: Spell[];
    isAmbitionTalent: boolean;
    isCheatMode: boolean;
    isMinimal: boolean;
    showBoonTable: boolean;
    setBonus: (bonus: Bonus) => void;
    setRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
}

const RollForPatronBoonTalent: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    let patronName = "";
    const patronBonus = globalContext.bonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Patron" && b.name === "Patron");
    if (patronBonus && patronBonus.bonusTo) { patronName = patronBonus.bonusTo; }

    const allAvailablePatronTalents = globalContext.availablePatronBoons;
    const patron = getAllWarlockPatrons().find((p) => p.name === patronName);
    const patronTalents = allAvailablePatronTalents.find((t) => t.patronId === patron?.id);

    const rollForTalent = () => {
        const roll = getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6);

        if (patronTalents) {
            const theTalent = patronTalents.talents.find((t) => roll >= t.min && roll <= t.max);
            if (theTalent) {
                const bonus: Bonus = {
                    sourceType: props.sourceType,
                    sourceName: props.sourceName,
                    sourceCategory: "Boon",
                    gainedAtLevel: props.thisLevel.level,
                    name: theTalent.name,
                    bonusName: theTalent.name,
                    boonPatron: patronName,
                    boonSource: props.boonSource,
                    bonusTo: ""
                };
                props.setBonus(bonus);
            } else {
                rollForTalent();
            }
        }
    }

    const getTalentHasBeenSelected = () => {
        const theBonus = globalContext.bonuses.find((b =>
            b.sourceType === props.sourceType &&
            b.sourceName === props.sourceName &&
            b.sourceCategory === "Boon" &&
            b.gainedAtLevel === props.thisLevel.level &&
            b.boonPatron === patronName &&
            b.boonSource === props.boonSource
        ));
        if (theBonus) { return true; }
        return false;
    }

    const talentHasBeenSelected = getTalentHasBeenSelected();

    const getTheSelectedTalent = () => {
        let selectedTalent: Talent = { min: 0, max: 0, desc: '', name: "" };
        const theBonus = globalContext.bonuses.find((b =>
            b.sourceType === props.sourceType &&
            b.sourceName === props.sourceName &&
            b.sourceCategory === "Boon" &&
            b.gainedAtLevel === props.thisLevel.level &&
            b.boonPatron === patronName &&
            b.boonSource === props.boonSource
        ));
        if (theBonus) {
            if (patronTalents) {
                const theTalent = patronTalents.talents.find((t) => t.name === theBonus.name);
                if (theTalent) {
                    selectedTalent = theTalent;
                }
            }
        }
        return selectedTalent;
    }

    const getBoonTalents = () => {
        if (props.isMinimal || !props.showBoonTable) { return null; }

        if (patronTalents) {
            return patronTalents.talents.filter((b) => b.max <= 12).map((t, index) =>
                <tr key={"tal_" + index}>
                    {t.min === t.max &&
                        <td>{t.min}</td>
                    }
                    {t.min !== t.max &&
                        <td>{t.min}&#8209;{t.max}</td>
                    }
                    <td>{t.desc}{t.rerollDuplicates ? " (reroll duplicates)" : null}</td>
                </tr>
            )
        }
        return "";
    }

    if (patronName === "") { return null; }

    return (


        <div className="mb-1">

            {(!props.isMinimal && props.showBoonTable) &&
                <table className="classTalentsTable mb-2">
                    <thead>
                        <tr>
                            <th colSpan={2} className="header">{patron?.name} Boons</th>
                        </tr>
                        <tr>
                            <th>2d6</th>
                            <th>Effect</th>
                        </tr>
                        {getBoonTalents()}
                    </thead>
                </table>
            }

            {!props.isCheatMode && !talentHasBeenSelected &&
                <>
                    <button className="btn btn-dark" onClick={() => rollForTalent()} disabled={getTalentHasBeenSelected()}>Roll for Patron Boon</button>&nbsp;
                    {!getTalentHasBeenSelected() &&
                        <div className="valError">Please roll for a talent</div>
                    }
                </>
            }

            {props.isCheatMode &&
                <>
                    <PickTalent
                        isSpecialTalent={true}
                        thisLevel={props.thisLevel}
                        specialTalents={patronTalents?.talents === undefined ? [] : patronTalents.talents}
                        sourceType={props.sourceType}
                        sourceName={props.sourceName}
                        sourceCategory={"Boon"}
                        boonPatron={props.boonPatron}
                        boonSource={props.boonSource}
                        isAmbitionTalent={props.isAmbitionTalent}
                        isCheatMode={props.isCheatMode}
                        isMinimal={props.isMinimal}
                        theClass={"Warlock"}
                        talent={getTheSelectedTalent()}
                        setBonus={(bonus: Bonus) => props.setBonus(bonus)}
                        onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                        onSetRolledTalent={props.onSetRolledTalent}
                        onSetSpecialTalent={props.onSetSpecialTalent}
                        spellsKnown={props.spellsKnown}
                    />
                </>
            }

            {talentHasBeenSelected &&
                <>
                    <div><b>Boon:</b> {getTheSelectedTalent().desc}</div>
                    <div>
                        <SelectTalentDetails
                            isSpecialTalent={true}
                            thisLevel={props.thisLevel}
                            specialTalents={patronTalents?.talents === undefined ? [] : patronTalents.talents}
                            sourceType={props.sourceType}
                            sourceName={props.sourceName}
                            boonPatron={props.boonPatron}
                            boonSource={props.boonSource}
                            sourceCategory={"Boon"}
                            isAmbitionTalent={props.isAmbitionTalent}
                            isCheatMode={props.isCheatMode}
                            isMinimal={props.isMinimal}
                            theClass={"Warlock"}
                            spellsKnown={props.spellsKnown}
                            includeSelectTalentOption={true}
                            onSetBonus={(bonus: Bonus) => props.setBonus(bonus)}
                            onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                            onSetRolledTalent={props.onSetRolledTalent}
                            onSetSpecialTalent={props.onSetSpecialTalent}
                        />
                    </div>
                </>
            }
        </div>
    )

}

export default RollForPatronBoonTalent;
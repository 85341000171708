import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { Ledger } from '../../data/ledger';
import { plusMinus } from '../../data/stats';
import { simplifyCoins } from '../../data/utilities';

interface IProps {
    openModal: boolean;
    onOpenModal: (modal: string) => void;
    onCloseModal: (modal: string) => void;
    ledger: Ledger[];
}

const DisplayLedger: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getRows = () => {
        const out: any[] = [];

        props.ledger.forEach((l, index) => {

            const className = l.notes.trim() !== "" ? "noBottomBorder" : "";

            if (l.notes.trim() !== "") {
                out.push(
                    <tr key={"ledgerNote_" + index} className={className}>
                        <td colSpan={4}><i>{l.notes}</i></td>
                    </tr>
                )
            }

            out.push(
                <tr key={"ledger_" + index} >
                    <td className="left">{l.desc}</td>
                    <td>{plusMinus(l.goldChange)}</td>
                    <td>{plusMinus(l.silverChange)}</td>
                    <td>{plusMinus(l.copperChange)}</td>
                </tr>
            )
        })

        if (out.length === 0) {
            out.push(
                <tr key={"no transactions"}>
                    <td colSpan={4}>No transactions</td>
                </tr>
            )
        } else {
            const consolidatedCoins = simplifyCoins(globalContext.gold, globalContext.silver, globalContext.copper);
            out.push(
                <>
                    <tr key={"ledger_total"}>
                        <td className="left"><b>Total (raw)</b></td>
                        <td>{plusMinus(props.ledger.reduce((accumulator, currentValue) => accumulator + currentValue.goldChange, 0))}</td>
                        <td>{plusMinus(props.ledger.reduce((accumulator, currentValue) => accumulator + currentValue.silverChange, 0))}</td>
                        <td>{plusMinus(props.ledger.reduce((accumulator, currentValue) => accumulator + currentValue.copperChange, 0))}</td>
                    </tr>
                    <tr key={"ledger_total_consolidated"}>
                        <td className="left"><b>Total (consolidated)</b></td>
                        <td>{plusMinus(consolidatedCoins.gold)}</td>
                        <td>{plusMinus(consolidatedCoins.silver)}</td>
                        <td>{plusMinus(consolidatedCoins.copper)}</td>
                    </tr>
                </>
            );
        }
        return out;
    }


    return (
        <>
            <button className="btn btn-dark btn-sm" onClick={() => props.onOpenModal("ledger")}>View Ledger</button>

            {props.openModal &&
                <Modal open={props.openModal} onClose={() => props.onCloseModal("ledger")} classNames={{ modal: 'customModal' }}>
                    <table className="ledger">
                        <thead>
                            <tr>
                                <th className="left">Transaction</th>
                                <th>gp</th>
                                <th>sp</th>
                                <th>cp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getRows()}
                        </tbody>
                    </table>
                </Modal>
            }

        </>
    )

}

export default DisplayLedger;
import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from "react-router-dom";
import { useIdToken } from 'react-firebase-hooks/auth';

import './index.css';

import Contact from './components/pages/contact';
import CreateCharacter from './components/createCharacter';
import ErrorPage from "./roots/errorPage";
import FAQ from './components/pages/faq';
import Home from "./components/pages/home";
import Resources from './components/pages/resources';
import Root from "./roots/root";
import RegisterAccount from './components/auth/registerAccount';
import Login from './components/auth/login';
import ForgotPassword from './components/auth/forgotPassword';
import SpellsPage from './components/pages/spells';

import AdminAllCharacters from './components/pages/admin/allCharacters';
import AdminAllUsers from './components/pages/admin/allUsers';

import { User, getAuth } from "firebase/auth";
import app from "./firebase/firebase";
import AdminOnlyRoute from './roots/adminOnlyRoute';
import Characters from './components/pages/characters';
import AdminTestRandom from './components/pages/admin/testRandom';

interface IProps { }

const App: React.FunctionComponent<IProps> = (props: IProps) => {

  const auth = getAuth(app);

  const options = {
    onUserChanged: async (user: User | null) => {
      if (user) {
        await user.getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            let isAdmin = false;
            if (idTokenResult) {
              if (idTokenResult.claims) {
                if (idTokenResult.claims.Admin !== undefined && idTokenResult.claims.Admin === true) {
                  isAdmin = true;
                }
              }
            }
            setIsAdmin(isAdmin);
          })
          .catch((error) => {
            setIsAdmin(false);
          });
      }
    }
  }

  // This hook puts an observer on user changes (logins, logouts) and maintains the user in state. 
  // options is a function that then checks the user for admin token claims. 
  const [user] = useIdToken(auth, options);

  const [isAdmin, setIsAdmin] = useState(false);

  const layout = "VERSION 2";

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<Root user={user} isAdmin={isAdmin} layout={layout}/>} errorElement={<ErrorPage />}>

          <Route path="/" element={<Home user={user} isAdmin={isAdmin} />} />
          <Route path="/create" element={<CreateCharacter user={user} />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/registerAccount" element={<RegisterAccount />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />

          <Route path="/characters" element={<Characters user={user} />} />

          {user &&
            <>
              <Route path="/create/:id" element={<CreateCharacter user={user} />} />
            </>
          }

          {isAdmin &&
            <>
              <Route path="/admin/allCharacters" element={<AdminOnlyRoute user={user} isAdmin={isAdmin}><AdminAllCharacters /></AdminOnlyRoute>} />
              <Route path="/admin/allUsers" element={<AdminOnlyRoute user={user} isAdmin={isAdmin}><AdminAllUsers /></AdminOnlyRoute>} />
              <Route path="/admin/testRandom" element={<AdminOnlyRoute user={user} isAdmin={isAdmin}><AdminTestRandom /></AdminOnlyRoute>} />
            </>
          }

          <Route path="/spells" element={<SpellsPage />} />

        </Route>
      </>
    ));

  return <RouterProvider router={router} />;

};

export default App;

import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Bonus } from "../../data/bonus";
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { getRandomIntInclusive } from "../../data/random";
import { Level } from "../../data/level";

interface IProps {
    sourceType: string;
    sourceName: string;
    level: Level;
    label: string;
    minimal: boolean;
    setBonus: (bonus: Bonus) => void;
}

const SetWarlockPatron: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getOptions = () => {
        const options = globalContext.availableWarlockPatrons.map((a) => <option value={a.name} key={props.sourceType + "_" + props.sourceName + "_" + a.name}>{a.name}</option>);
        options.unshift(<option value="" key="selectWarlockPatron">-- select Patron --</option>);
        return options;
    }

    let currentValue = "";

    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.sourceCategory === "Patron" && b.name === "Patron");
    if (existingBonus && existingBonus.bonusTo) { currentValue = existingBonus.bonusTo; }

    const setPatronBonus = (patron: string) => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: "Patron",
            gainedAtLevel: props.level.level,
            name: "Patron",
            bonusTo: patron,
            bonusName: "Patron",
        };
        props.setBonus(bonus);
    }

    const rollPatron = () => {
        const randPatron = globalContext.availableWarlockPatrons[getRandomIntInclusive(0, globalContext.availableWarlockPatrons.length - 1)].name;
        setPatronBonus(randPatron);
    }

    const getPatronDesc = () => {
        if (props.minimal) { return null; }
        if (currentValue !== "") {
            const thePatron = globalContext.availableWarlockPatrons.find((s) => s.name === currentValue);
            if (thePatron) {
                return <div className="spell">{thePatron.desc}</div>
            }
        }
        return null;
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    return (
        <div className="mb-2">
            <div className="formNote">{props.label}</div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setPatronBonus(e.target.value)} value={currentValue}>{getOptions()}</select>
                <button className="btn btn-dark" onClick={(e) => rollPatron()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
            </div>
            {getPatronDesc()}
            {currentValue === "" &&
                <div className="valError">Please select a patron</div>
            }
        </div>
    )

}

export default SetWarlockPatron;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import RollForTalents from "./rollForTalents";
import RollHitPoints from "./rollHitPoints";
import SpecialComponents from "../special/specialComponents";
import { Bonus } from "../../data/bonus";
import { CharClass } from "../../data/classes";
import { Spell } from "../../data/spells";
import { getTotalHitPoints } from "../../data/utilities";
import ChooseWarlockTalentOrPatronBoon from "./chooseWarlockTalentOrPatronBoon";
import RollForPatronBoonTalent from "./rollForPatronBoonTalents";
import { weapons } from "../../data/weapons";

interface IProps {
    ancestry: string;
    currentLevel: number;
    level: number;
    bonuses: Bonus[];
    spellsKnown: Spell[];
    rawXP: string;
    XP: number;
    advancementMode: string;
    isCheatMode: boolean;
    isMinimal: boolean;
    setXP: (XP: number) => void;
    setRawXP: (rawXP: string) => void;
    levelUp: () => void;
    levelDown: () => void;
    onRollHitDie: (level: number, hitDie: number) => void;
    onSetHitDie: (level: number, hitDie: number) => void;
    onSetBonus: (bonus: Bonus) => void;
    onSetBonuses: (bonuses: Bonus[]) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialCategoryName: string) => void;
    maximiseHitDieRoll: (level: number, maximiseHitDieRoll: boolean) => void;
    setAdvancementMode: (mode: string) => void;
    openAnimalModal: boolean;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;

}

const Advancement: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getXPToAdvance = () => {
        if (globalContext.className !== "Level 0") {
            return props.level * 10
        }
        else {
            return "0"
        }
    }

    const updateXP = (e: any) => {
        props.setRawXP(e.target.value);
        let intXP = parseInt(e.target.value, 10);
        if (intXP !== undefined) {
            intXP = Math.min(intXP, props.level * 10);
            if (intXP < 0) { intXP = 0; }
            props.setXP(intXP);
            props.setRawXP(intXP.toString());
        } else {
            props.setXP(0);
        }
    }

    const plusOneXP = () => {
        if (props.XP < props.level * 10) {
            const newXP = props.XP + 1;
            props.setXP(newXP);
            props.setRawXP(newXP.toString());
        }
    }

    const getHasStout = (ancestry: string) => {
        return ancestry === "Dwarf";
    }

    const getSpecialComponents = (charClass: CharClass, thisLevel: number) => {

        const theLevel = globalContext.levels.find((l) => l.level === thisLevel);
        if (theLevel) {

            if (charClass.specialBonuses.length > 0) {
                let output: any[] = [];
                charClass.specialBonuses.forEach((sb, index) => {

                    if (sb.gainedAtLevel === thisLevel) {
                        const weaponsForMastery = [...weapons];

                        if (globalContext.charClass !== undefined) {
                            output.push(
                                <SpecialComponents
                                    componentName={sb.specialComponentName}
                                    bonuses={props.bonuses}
                                    currentLevel={props.currentLevel}
                                    level={theLevel}
                                    characterIsSaved={true}
                                    boonPatron={""}
                                    boonSource={""}
                                    onSetBonus={props.onSetBonus}
                                    onSetBonuses={props.onSetBonuses}
                                    onSetSpecialTalent={props.onSetSpecialTalent}
                                    languages={[]}
                                    languagesKnown={[]}
                                    weapons={weaponsForMastery}
                                    sourceType={sb.specialSourceType}
                                    sourceName={sb.specialSourceName}
                                    sourceCategory={sb.specialSourceCategory}
                                    label={sb.specialLabel}
                                    spellTier={sb.specialTier}
                                    spellInTier={sb.specialNumber}
                                    spellsKnown={props.spellsKnown}
                                    key={sb.specialComponentName}
                                    minimal={props.isMinimal}
                                    isCheatMode={props.isCheatMode}
                                    // Warlock Boon Talents only
                                    isAmbitionTalent={false}
                                    // theClass={globalContext.charClass}
                                    onSetRolledTalent={props.onSetRolledTalent}
                                    onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                                    openAnimalModal={props.openAnimalModal}
                                    onOpenModal={(modal: string) => props.onOpenModal(modal)}
                                    onCloseModal={(modal: string) => props.onCloseModal(modal)}
                                />
                            );
                        }
                    }
                })
                return <>{output}</>;
            } else {
                return null;
            }
        }
        return null;
    }

    const getLevelNotes = (level: number, charClass: CharClass) => {
        const out: any = [];
        if (charClass.levelNotes) {
            const theLevelNote = charClass.levelNotes.find((ln) => ln.level === level);
            if (theLevelNote) {
                theLevelNote.notes.forEach((ln, index) => {
                    out.push(
                        <div className="section" key={"levNote_" + index}>
                            <h3>{ln.title}</h3>
                            <div>{ln.note}</div>
                        </div>
                    );
                })
            }
        }
        return out;
    }

    const confirmRemoveLevel = () => {
        if (window.confirm("Are you sure you want to remove this level?")) {
            props.levelDown();
        }
    }

    const getLevels = () => {

        const theCharClass = globalContext.charClass;
        if (theCharClass) {
            if (props.level > 1) {
                let out: any[] = [];
                for (let thisLevel = 2; thisLevel <= props.level; thisLevel++) {

                    const specBonusesForLevel = theCharClass.specialBonuses.filter((g) => g.gainedAtLevel === thisLevel);

                    const thisFullLevel = globalContext.levels.find((l) => l.level === thisLevel);
                    if (thisFullLevel) {

                        const warlockTalentChosen = theCharClass.name === "Warlock" && globalContext.bonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "TalentOrBoon" && b.gainedAtLevel === thisLevel && b.name === "ChooseWarlockTalentOrPatronBoon" && b.bonusTo === "WarlockTalent") !== undefined;
                        const patronBoonChosen = theCharClass.name === "Warlock" && globalContext.bonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "TalentOrBoon" && b.gainedAtLevel === thisLevel && b.name === "ChooseWarlockTalentOrPatronBoon" && b.bonusTo === "PatronBoon") !== undefined;

                        out.push(
                            <div key={"lev" + thisLevel} className="">
                                <h3 className="level mb-3">Level {thisLevel}</h3>
                                <div className="section">
                                    <RollHitPoints
                                        level={thisLevel}
                                        hasStout={getHasStout(props.ancestry)}
                                        totalHitPoints={getTotalHitPoints(thisLevel, globalContext.levels, props.ancestry, globalContext.className, globalContext.availableClasses, globalContext.finalStats, props.bonuses)}
                                        isCheatMode={props.isCheatMode}
                                        rollHitDie={(level: number, hitDie: number) => props.onRollHitDie(level, hitDie)}
                                        setHitDie={(level: number, hitDie: number) => props.onSetHitDie(level, hitDie)}
                                        maximiseHitDieRoll={(level: number, maximiseHitDieRoll: boolean) => props.maximiseHitDieRoll(level, maximiseHitDieRoll)}
                                    />
                                </div>

                                {thisLevel % 2 === 1 &&
                                    <div className="section">
                                        <h3>Talent</h3>

                                        {theCharClass.name === "Warlock" &&
                                            <ChooseWarlockTalentOrPatronBoon
                                                isHumanAmbitionTalentOrBoon={false}
                                                setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                                level={thisFullLevel}
                                            />
                                        }

                                        {(theCharClass.name !== "Warlock" || (theCharClass.name === "Warlock" && warlockTalentChosen)) &&
                                            <RollForTalents
                                                isSpecialTalent={false}
                                                specialTalents={[]}
                                                spellsKnown={props.spellsKnown}
                                                isAmbitionTalent={false}
                                                boonPatron={globalContext.patron}
                                                boonSource={""}
                                                isCheatMode={props.isCheatMode}
                                                isMinimal={props.isMinimal}
                                                theClass={theCharClass.name}
                                                setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                                onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                                                onSetRolledTalent={props.onSetRolledTalent}
                                                onSetSpecialTalent={props.onSetSpecialTalent}
                                                level={thisFullLevel}
                                                setRolledTalent={props.onSetRolledTalent}
                                            />
                                        }

                                        {(theCharClass.name === "Warlock" && patronBoonChosen) &&
                                            <RollForPatronBoonTalent
                                                sourceType={"Class"}
                                                sourceName={"Warlock"}
                                                sourceCategory={"Boon"}
                                                boonPatron={globalContext.patron}
                                                boonSource={"StandardBoon"}
                                                thisLevel={thisFullLevel}
                                                spellsKnown={props.spellsKnown}
                                                isAmbitionTalent={false}
                                                isCheatMode={props.isCheatMode}
                                                isMinimal={props.isMinimal}
                                                showBoonTable={false}
                                                setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                                setRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => props.onSetRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                                                onSetRolled12TalentOrTwoStatPoints={(level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => props.onSetRolled12TalentOrTwoStatPoints(level, choice, isAmbitionTalent, isBoon, boonSource)}
                                                onSetRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => props.onSetRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                                                onSetSpecialTalent={(level: number, talentName: string, specialTalentCategory: string) => props.onSetSpecialTalent(level, talentName, specialTalentCategory)}
                                            />
                                        }
                                    </div>
                                }

                                {specBonusesForLevel.length > 0 &&
                                    <div className="section">
                                        <h3>Abilities Gained</h3>
                                        {getSpecialComponents(theCharClass, thisLevel)}
                                    </div>
                                }

                                {getLevelNotes(thisFullLevel.level, theCharClass)}


                                {thisLevel === props.level &&
                                    <div className="clearfix">
                                        <div className="float-end"><button className="btn btn-dark btn-sm" onClick={() => confirmRemoveLevel()}>Remove Level {props.level}</button></div>
                                    </div>
                                }
                            </div>
                        )
                    }
                }
                return out;
            }
        }



        return null;

    }


    if (props.level > 0) {

        const getLevelOk = () => {
            return (props.level * 10) === props.XP;
        }

        const getGainMessage = () => {
            const xpToAdvance = (props.level * 10) - props.XP;
            if (!isNaN(xpToAdvance)) {
                return (props.level * 10) - props.XP + " more XP required";
            }
            return null;
        }

        const btnXPMode = props.advancementMode === "XP" ? "btn btn-dark" : "btn btn-outline-dark";
        const btnMilestoneMode = props.advancementMode === "Milestones" ? "btn btn-dark" : "btn btn-outline-dark";

        const updateAdvancementMode = (e: any, mode: string) => {
            e.preventDefault();
            props.setAdvancementMode(mode);
        }

        return (
            <>
                <div className="">

                    <h3 className="level">Gain A Level</h3>

                    <div className="row">

                        <div className="col-12 col-sm-12">
                            <div className="btn-group mb-3" role="group">
                                <button type="button" className={btnXPMode} onClick={(e) => updateAdvancementMode(e, "XP")}>XP</button>
                                <button type="button" className={btnMilestoneMode} onClick={(e) => updateAdvancementMode(e, "Milestones")}>Milestones</button>
                            </div>
                        </div>


                        {props.advancementMode === "XP" &&
                            <>
                                <div className="col-4 col-sm-3"><b>Current Level</b></div>
                                <div className="col-8 col-sm-9">{globalContext.className !== "Level 0" ? props.level : "0"}</div>
                                <div className="col-4 col-sm-3"><b>XP to gain Level {props.level + 1}</b></div>
                                <div className="col-8 col-sm-9">{getXPToAdvance()}</div>

                                <div className="col-4 col-sm-3"><b>XP earned</b></div>
                                <div className="col-8 col-sm-9">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input type="number" className="form-control xp" value={props.rawXP} onChange={(e) => updateXP(e)}></input>
                                                </td>
                                                <td><button className="btn btn-dark btn-sm ms-1" onClick={() => plusOneXP()} disabled={props.XP >= props.level * 10}>+1 XP</button></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div>
                                    <button className="btn btn-dark" onClick={() => props.levelUp()} disabled={!getLevelOk()}>Gain Level {props.level + 1}</button> {getGainMessage()}
                                </div>
                            </>
                        }

                    </div>

                    {props.advancementMode === "Milestones" &&
                        <div>
                            <button className="btn btn-dark" onClick={() => props.levelUp()}>Gain Level {props.level + 1}</button>
                        </div>
                    }
                    <div>

                    </div>
                </div>

                {getLevels()}

            </>
        )
    }

    if (props.level <= 0) {
        return (
            <div className="section">To advance a level 0 character to level 1, select a class in the Class section (above).</div>
        )
    }

    return null;
}

export default Advancement; 
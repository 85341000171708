import { useContext } from "react";
import { Bonus } from "../../data/bonus";

import { GlobalContextType } from "../context";
import { CreateCharacterContext } from "../createCharacter";
import { Level } from "../../data/level";
import { animalTypes, getBonusByName } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const BeastMasterAnimalType: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const options = animalTypes.map((w) => <option value={w} key={w}>{w}</option>);
    options.unshift(<option value="" key="selectAnimalType">-- select animal --</option>);

    const getCurrentValue = () => {
        let val = "";
        const theBonus = getBonusByName(globalContext.bonuses, "BeastMasterAnimalType", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
        if (theBonus) {
            val = theBonus.bonusTo;
        }
        return val;
    }

    const setBeastMasterAnimalTypeBonus = (bonusTo: string) => {
        const theBonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "BeastMasterAnimalType",
            bonusName: "BeastMasterAnimalType",
            bonusTo: bonusTo
        }
        props.setBonus(theBonus);
    }

    const currentValue = getCurrentValue();

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select mb-2";

    return (
        <>
            <div>
                <div className="formNote">Beast Master Animal Type</div>
                <select className={selectCss} onChange={(e) => setBeastMasterAnimalTypeBonus(e.target.value)} value={getCurrentValue()}>{options}</select>
            </div>
            {currentValue === "Wolf" &&
                <table className='herbTable elixirTable leftAlign mb-4'>
                    <tbody>
                        <tr className='herbTableHeader'>
                            <th>Beast Attack - Wolf</th>
                        </tr>
                        <tr>
                            <td>Bite deals d6 damage equal to half your level rounded down. Minimum of 1d6</td>
                        </tr>
                        <tr className='herbTableHeader'>
                            <th>Beast Shape - Wolf</th>
                        </tr>
                        <tr>
                            <td><i>A giant canine with a gray pelt, yellow eyes, and dripping jaws.</i></td>
                        </tr>
                        <tr>
                            <td><b>AC</b> 12, <b>HP</b> 10, <b>ATK</b> 1 bite +2 (1d6), <b>MV</b> double near, <b>S</b> +2, <b>D</b> +2, <b>C</b> +1, <b>I</b> -2, <b>W</b> +1, <b>Ch</b> +0, <b>Language</b> Canine</td>
                        </tr>
                        <tr>
                            <td><b>Pack Hunter. </b>Deals +1 damage while an ally is close.</td>
                        </tr>
                    </tbody>
                </table>
            }
            {currentValue === "Bear" &&
                <table className='herbTable elixirTable leftAlign mb-4'>
                    <tbody>
                        <tr className='herbTableHeader'>
                            <th>Beast Attack - Bear</th>
                        </tr>
                        <tr>
                            <td>Claw deals 1d8 damage. Can attack with disadvantage to double damage dice from bear hug</td>
                        </tr>
                        <tr className='herbTableHeader'>
                            <th>Beast Shape - Bear</th>
                        </tr>
                        <tr>
                            <td><i>Hefty, black-furred beast that can smell food from miles away.</i></td>
                        </tr>
                        <tr>
                            <td><b>AC</b> 12, <b>HP</b> 19, <b>ATK</b> 2 claws +3 (1d6), <b>MV</b> near (climb), <b>S</b> +3, <b>D</b> +1, <b>C</b> +2, <b>I</b> -3, <b>W</b> +1, <b>Ch</b> -2, <b>Language</b> Bear</td>
                        </tr>
                    </tbody>
                </table>
            }
            {currentValue === "Snake" &&
                <table className='herbTable elixirTable leftAlign mb-4'>
                    <tbody>
                        <tr className='herbTableHeader'>
                            <th>Beast Attack - Snake</th>
                        </tr>
                        <tr>
                            <td>Bite attack deals 1d4 + poison. DC 9 CON check or paralyzed for 1d4 rounds</td>
                        </tr>
                        <tr className='herbTableHeader'>
                            <th>Beast Shape - Snake</th>
                        </tr>
                         <tr>
                            <td><i>A weaving serpent with a neck hood and lethal venom.</i></td>
                        </tr>
                        <tr>
                            <td><b>AC</b> 12, <b>HP</b> 4, <b>ATK</b> 1 bite +2 (1 + poison), <b>MV</b> near, <b>S</b> +2, <b>D</b> +0, <b>C</b> -3, <b>I</b> -3, <b>W</b> +0, <b>Ch</b> -3, <b>Language</b> Reptile</td>
                        </tr>
                        <tr>
                            <td><b>Poison. </b>DC 18 CON or go to 0 HP with a death timer of 1.</td>
                        </tr>
                    </tbody>
                </table>
            }
            {currentValue === "Eagle" &&
                <table className='herbTable elixirTable leftAlign mb-4'>
                    <tbody>
                        <tr className='herbTableHeader'>
                            <th>Beast Attack - Eagle</th>
                        </tr>
                        <tr>
                            <td>Talon grasp attack grapples and target is pecked for 1d6. Target must make a DC 9 STR check to escape. If grappled, do automatic 1d6 peck damage each round</td>
                        </tr>
                        <tr className='herbTableHeader'>
                            <th>Beast Shape - Eagle</th>
                        </tr>
                        <tr>
                            <td><i>Majestic king of the sky with keen eyes and a white head.</i></td>
                        </tr>
                        <tr>
                            <td><b>AC</b> 11, <b>HP</b> 4, <b>ATK</b> 1 bite +1 (1d2), <b>MV</b> near (fly), <b>S</b> +0, <b>D</b> +1, <b>C</b> +0, <b>I</b> -2, <b>W</b> +1, <b>Ch</b> -2, <b>Language</b> Raptor</td>
                        </tr>
                    </tbody>
                </table>
            }


        </>
    )

}

export default BeastMasterAnimalType;
import { arcaneLibraryDiscordURL, arcaneLibraryEmail, arcaneLibraryRedditURL, swooneRedditUserURL } from "../../special/links";

interface IProps {

}

const Contact: React.FunctionComponent<IProps> = (props: IProps) => {
    return (

        <div>
            <h1>Contact</h1>

            <div>
                {/* <div className="faqHead">How can I contact the site's author?</div> */}
                <h2>The Arcane Libary</h2>
                <div className="faqBody">You can contact the author of Shadowdark, Kelsey Dionne, at <a href={"mailto:" + arcaneLibraryEmail} target="_blank" rel="noreferrer">{arcaneLibraryEmail}</a>, or at the <a href={arcaneLibraryDiscordURL} target="_blank" rel="noreferrer">ShadowDark Discord</a>.</div>

                <h2 className="mt-3">Web Master</h2>
                <div className="faqBody">Hi! I'm Mike, the site's author, a.k.a SWooNe.</div>
                <div className="faqBody">I hang out at the <a href={arcaneLibraryDiscordURL} target="_blank" rel="noreferrer">ShadowDark Discord</a>, with the username SWooNe.</div>
                <div className="faqBody">You can also find me on the <a href={arcaneLibraryRedditURL} target="_blank" rel="noreferrer">ShadowDark Reddit</a>, or message me at user <a href={swooneRedditUserURL} target="_blank" rel="noreferrer">SWooNe</a></div>
                <div className="faqBody">Reach out to me with any bug reports or feature requests.</div>
            </div>

        </div>

        // <div className="row">
        //     <div className="col small">Discuss on </div>
        // </div>
    )
}

export default Contact;
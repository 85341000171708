export interface Language {
    name: string;
    spokenBy: string;
    category: string;
    sourceId: string;
}

const coreLanguages: Language[] = [
    { name: "Common", spokenBy: "Most humanoids", category: "Common", sourceId: "SD" },
    { name: "Dwarvish", spokenBy: "dwarves", category: "Common", sourceId: "SD" },
    { name: "Elvish", spokenBy: "elves", category: "Common", sourceId: "SD" },
    { name: "Giant", spokenBy: "giants, ogres, trolls", category: "Common", sourceId: "SD" },
    { name: "Goblin", spokenBy: "bugbears, goblins, hobgoblins", category: "Common", sourceId: "SD" },
    { name: "Merran", spokenBy: "merfolk, sahuagin, sirens", category: "Common", sourceId: "SD" },
    { name: "Orcish", spokenBy: "orcs", category: "Common", sourceId: "SD" },
    { name: "Reptilian", spokenBy: "lizardfolk, viperians", category: "Common", sourceId: "SD" },
    { name: "Sylvan", spokenBy: "centaurs, dryads, faeries", category: "Common", sourceId: "SD" },
    { name: "Thanian", spokenBy: "minotaurs, beastmen, manticores", category: "Common", sourceId: "SD" },
    { name: "Celestial", spokenBy: "angels", category: "Rare", sourceId: "SD" },
    { name: "Diabolic", spokenBy: "demons, devils", category: "Rare", sourceId: "SD" },
    { name: "Draconic", spokenBy: "dragons", category: "Rare", sourceId: "SD" },
    { name: "Primordial", spokenBy: "elder things, elementals", category: "Rare", sourceId: "SD" },
    { name: "Avian", spokenBy: "ducks", category: "Common", sourceId: "DP" },
    { name: "Canine", spokenBy: "dogs", category: "Common", sourceId: "DP" },
    { name: "Chelonian", spokenBy: "chelonians (Turtle People)", category: "Common", sourceId: "US" },
    { name: "Drow", spokenBy: "drow", category: "Common", sourceId: "US" },
    { name: "Skurrid", spokenBy: "skurrid", category: "Common", sourceId: "US" }
]

// export const getLanguages = (sources: Source[]) => {

//     let languages = [...coreLanguages]

//     if (sources.find((s) => s.name === "ShadowDuck")) {
//         languages.push({ name: "Avian", spokenBy: "Ducks", category: "Common" });
//     }

//     if (sources.find((s) => s.name === "ShadowDog")) {
//         languages.push({ name: "Canine", spokenBy: "Dogs", category: "Common" });
//     }

//     return languages;
// }

export const getAllLanguages = () => {
    return [...coreLanguages]
}

import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import app from "../../firebase/firebase";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { isEmail } from "../../data/utilities";
import { useNavigate } from "react-router-dom";
import Spinner from "../charGen/spinner";

interface IProps {
}

const RegisterAccount: React.FunctionComponent<IProps> = (props: IProps) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signUpState, setSignUpState] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const auth = getAuth(app);

    const handleSignUp = async (e: any) => {
        e.preventDefault();

        let isValid = true;

        if (email.trim() !== "")

            if (isValid) {
                setIsLoading(true);
                createUserWithEmailAndPassword(auth, email, password)
                    .then(async (userCredential) => {
                        // Signed in 
                        const user = userCredential.user;

                        if (user) {
                            // Send new user an email with a link which they can use to verify their email address.    

                            sendEmailVerification(user)
                                .then(() => {});

                            // Create a new user document in the users collection.
                            const db = getFirestore(app);
                            const userToSave = {
                                email: user.email,
                                emailVerified: false,
                                user_id: user.uid
                            }
                            await setDoc(doc(db, "users", user.uid), userToSave);

                            // // Log the new user out. 
                            auth.signOut();

                            setSignUpState("success");
                            setIsLoading(false);
                        }
                        else {
                            setSignUpState("success");
                        }

                    })
                    .catch((error) => {
                        setSignUpState("fail");
                        setIsLoading(false);
                        if (error.message) {
                            if (error.code) {
                                switch (error.code) {
                                    case "auth/email-already-in-use":
                                        setError("The email address is already in use");
                                        break;
                                    case "auth/invalid-email":
                                        setError("The email address is not valid");
                                        break;
                                    case "auth/weak-password":
                                        setError("The password is too weak");
                                        break;
                                    default:
                                        setError(error.message);
                                        break;
                                }
                            } else {
                                setError(error.message);
                            }
                        }
                    });
            }
    }

    const selectEmailCss = email.trim() === "" ? "form-control redBorder" : "form-control";
    const selectPasswordCss = password.trim() === "" ? "form-control redBorder" : "form-control";

    const getIsDisabled = () => {
        if (email.trim() === "") { return true; }
        if (!isEmail(email.trim())) { return true; }
        if (password.trim() === "") { return true; }
        if (password.trim().length < 8) { return true; }
        return false;
    }

    const reset = () => {
        setEmail("");
        setPassword("");
        setSignUpState("");
        setError("");
    }

    return (
        <div>
            <h1>Register for an account</h1>


            {signUpState === "fail" &&
                <div>
                    <div className="alert alert-danger">
                        <div><b>Account registration failed:</b> {error}</div>
                        <div className="mt-2"><button onClick={() => reset()} className="btn btn-dark me-2">Try Again</button></div>
                    </div>
                </div>
            }

            {signUpState === "success" &&
                <div className="alert alert-success">
                    <div><b>Your account has been created.</b></div>
                    <div>An email has been sent to {email}. It contains a link that you must click to activate your account.</div>
                    <div className="mt-2"><button onClick={() => navigate("/login")} className="btn btn-dark me-4">Log In</button></div>
                </div>
            }

            {signUpState === "" &&
                <>
                    <div className="pb-2">Register for an account so that you can save your characters.</div>

                    <div className="section">

                        <div>
                            <div className="formNote">Email</div>
                            <div className="input-group">
                                <input className={selectEmailCss} id="email" type="text" onChange={(e) => setEmail(e.currentTarget.value)} value={email}></input>
                            </div>
                            {email.trim() === "" &&
                                <div className="valError">Please enter your email address</div>
                            }
                            {email.trim() !== "" && !isEmail(email.trim()) &&
                                <div className="valError">A valid email address is required</div>
                            }
                        </div>

                        <form onSubmit={(e) => handleSignUp(e)}>
                            <div>
                                <div className="formNote">Password</div>
                                <div className="input-group">
                                    <input className={selectPasswordCss} id="password" type="password" onChange={(e) => setPassword(e.currentTarget.value)} value={password}></input>
                                </div>
                                {password.trim() === "" &&
                                    <div className="valError">Please enter a password</div>
                                }
                                {password.trim() !== "" && password.trim().length < 8 &&
                                    <div className="valError">Password must be at least eight characters</div>
                                }
                            </div>
                        </form>

                        <div>
                            <button type="submit" className="btn btn-dark" onClick={(e) => handleSignUp(e)} disabled={getIsDisabled()}>Register</button>
                            <Spinner isLoading={isLoading} />
                        </div>

                    </div>
                </>
            }

        </div>
    )
}

export default RegisterAccount;

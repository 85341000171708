import { useEffect, useState } from 'react';
import app from '../../../firebase/firebase';
import { DocumentData, Query, collection, getCountFromServer, getDocs, getFirestore, orderBy, query } from 'firebase/firestore';

import Spinner from '../../charGen/spinner';
import { Alert } from 'react-bootstrap';


interface IProps {
}

const AdminAllUsers: React.FunctionComponent<IProps> = (props: IProps) => {

    const [users, setUsers] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [numUsers, setNumUsers] = useState(0);
    const [error, setError] = useState("");
    const [orderedBy, setOrderedBy] = useState("name_lower");
    const [usersLoaded, setUsersLoaded] = useState(false);

    useEffect(() => {

        try {
            // load number of saved users
            const asyncLoadCharacterCount = async () => {
                const count = await loadNumUsers();
                setNumUsers(count);
            }
            asyncLoadCharacterCount();

        } catch (e: any) {
            setError(e.message)
        }

    }, [])

    const showUsers = () => {
        getUsers("email");
    }

    const loadNumUsers = async () => {
        try {
            const db = getFirestore(app);
            const coll = collection(db, "users");
            const q = query(coll);
            const snapshot = await getCountFromServer(q);
            return snapshot.data().count;
        } catch (e: any) {
            setError(e.message);
        }

        return 0;
    }

    const getUsers = async (orderByField: string) => {
        setOrderedBy(orderByField);
        try {

            const db = getFirestore(app);
            let q: Query<DocumentData> | null;
            q = query(collection(db, "users"), orderBy(orderByField));
            if (q) {
                setIsLoading(true);
                const querySnapshot = await getDocs(q);

                const users: any[] = [];
                querySnapshot.forEach((doc) => {
                    users.push({ ...doc.data(), id: doc.id });
                });
                setUsers(users);
                setUsersLoaded(true);
            }
            setIsLoading(false);

        }
        catch (error: any) {
            setIsLoading(false);
        }
    }

    const getUserRows = () => {
        const rows: any = [];
        users.forEach((c) => {
            rows.push(
                <tr key={c.id}>
                    <td>{c.email}</td>
                    <td>{c.emailVerified.toString()}</td>
                    <td>{c.user_id}</td>
                </tr>
            )
        })

        return rows;
    }

    return (
        <div>
            <h1>Admin: All Users</h1>

            {error !== "" &&
                <Alert key="warning" variant="warning">Error: {error}</Alert>
            }

            <div className="mb-2">{numUsers} users</div>

            <div className="mb-2">WARNING: Clicking the 'Show all users' button below queries the entire database. Please click the button as seldom as possible.</div>

            <div><button className="btn btn-dark" onClick={() => showUsers()}>Show all users</button></div>

            {usersLoaded &&
                <div>

                    {users.length > 0 &&
                        <div>
                            <table className="table table-hover w-auto characterTable">
                                <thead>
                                    <tr className="table-dark">
                                        <th scope="col"><a href="#!" onClick={() => getUsers("email")} className="whiteText">Email</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getUsers("emailVerified")} className="whiteText">Email Verified</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getUsers("user_id")} className="whiteText">User ID</a></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getUserRows()}
                                </tbody>
                            </table>
                            <div>Ordered by {orderedBy}</div>
                        </div>
                    }

                    {users.length === 0 &&
                        <div>There are currently no users.</div>
                    }

                </div>
            }


            <div className="mb-3">
                <Spinner isLoading={isLoading} />
            </div>

            {/* <pre>{JSON.stringify(characters, null, 2)};</pre> */}



        </div >
    )
}

export default AdminAllUsers;
import React from "react";

import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import cloneDeep from "lodash.clonedeep";

import { Bonus } from "../../data/bonus";
import { Talent } from "../../data/classes";
import { Level } from "../../data/level";
import { Spell } from "../../data/spells";

import SelectTalentDetails from "../charGen/selectTalentDetails";


interface IProps {
    isSpecialTalent: boolean;
    thisLevel: Level;
    specialTalents: Talent[];
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    isAmbitionTalent: boolean;
    isCheatMode: boolean;
    isMinimal: boolean;
    talent: Talent;
    theClass: string;
    spellsKnown: Spell[];
    setBonus: (bonus: Bonus) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean, parentBonusId?: string) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
    parentBonusId?: string;
}

const PickTalent: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    let availableTalents: Talent[] = [];
    if (props.sourceCategory === "Boon") {
        const thePatron = globalContext.availableWarlockPatrons.find((p) => p.name === globalContext.patron);
        if (thePatron) {
            const patronBoons = globalContext.availablePatronBoons.find((pb) => pb.patronId === thePatron.id);
            if (patronBoons) { availableTalents = patronBoons.talents; }
        }
        // special for hacking in Roustabout extra talents as Boons
        if (props.boonPatron === "RoustaboutExtraTalent") {
            availableTalents = props.specialTalents;
        }
    } else if (props.isSpecialTalent) {
        availableTalents = [...props.specialTalents];
    } else if (globalContext.charClass) {
        availableTalents = [...globalContext.charClass.talents];
    };

    if (!props.isCheatMode) {
        availableTalents = availableTalents.filter((t) => t.name !== "ChooseOrTwoStatPoints");
    }

    const getOptions = (availableTalents: Talent[]) => {

        let copyOfTalents = cloneDeep(availableTalents);

        if (!props.isCheatMode || props.sourceCategory === "Boon") {
            // omit the ChooseOrTwoStatPoints option when user is given a random talent to choose from
            copyOfTalents = availableTalents.filter((t) => t.name !== "ChooseOrTwoStatPoints");
        } else {
            // switch the ChooseOrTwoStatPoints option for the TwoStatPoints when user is in cheat mode and can pick any talent
            const hasChooseOrTwoStatPoints = copyOfTalents.find((t) => t.name === "ChooseOrTwoStatPoints");
            if (hasChooseOrTwoStatPoints) {
                hasChooseOrTwoStatPoints.name = "+2 Stat Points";
                hasChooseOrTwoStatPoints.desc = "+2 points to distribute to stats";
            }
            // omit the Choose Talent option
            copyOfTalents = copyOfTalents.filter((t) => t.name !== "ChooseTalent");
        }

        const options = copyOfTalents.filter((t) => t.max <= 12).map((t) => <option value={t.name} key={t.name}>{t.desc}</option>);
        options.unshift(<option value="" key="selectTalent">-- select talent --</option>);

        return options;

    }

    const setChosenTalentBonus = (e: any) => {
        const talentName = e.target.value;
        let talentDesc = "";
        let theTalent: Talent | undefined;
        if (talentName === "+2 Stat Points") {
            theTalent = { min: 7, max: 9, desc: "+2 points to distribute to any stats", name: "+2 Stat Points", options: ["STR", "DEX", "CON", "INT", "WIS", "CHA"], size: 1 };
        } else {
            theTalent = availableTalents.find((t) => t.name === talentName);
        }
        if (theTalent) {
            talentDesc = theTalent.desc;
            if (props.sourceCategory !== "Boon") {
                props.onSetRolledTalent(props.thisLevel.level, talentDesc, talentName, props.isAmbitionTalent, props.parentBonusId);
            } else {
                const bonus: Bonus = {
                    sourceType: props.sourceType,
                    sourceName: props.sourceName,
                    sourceCategory: "Boon",
                    gainedAtLevel: props.thisLevel.level,
                    name: theTalent.name,
                    bonusName: theTalent.name,
                    boonPatron: globalContext.patron,
                    boonSource: props.boonSource,
                    bonusTo: ""
                };
                props.setBonus(bonus);
            }
        }
    }

    const getTalentNameChosen = () => {
        if (props.parentBonusId !== undefined) {
            const theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
            if (theBonus) {
                return theBonus.name;
            }
        } else if (props.sourceCategory !== "Boon") {
            if (props.isAmbitionTalent) {
                return globalContext.ambitionTalentLevel.talentRolledName;
            }
            return props.thisLevel.talentRolledName;
        } else {
            const theBonus = globalContext.bonuses.find((b =>
                b.sourceType === props.sourceType &&
                b.sourceName === props.sourceName &&
                b.sourceCategory === "Boon" &&
                b.gainedAtLevel === props.thisLevel.level &&
                b.boonPatron === globalContext.patron &&
                b.boonSource === props.boonSource
            ));
            if (theBonus) {
                return theBonus.name;
            }
        }
        return "";
    }

    const talentNameChosen = getTalentNameChosen();

    const selectCss = talentNameChosen === "ChooseOrTwoStatPoints" ? "form-select redBorder" : "form-select";

    const getTitle = () => {
        if (props.isCheatMode) {
            if (props.isSpecialTalent) {
                return "Select Talent";
            }
            if (props.isAmbitionTalent) {
                return "Select Human Ambition Talent";
            } else {
                return "Select Level " + props.thisLevel.level + " Talent"
            }
        } else {
            return "Chosen Talent";
        }
    }

    return (
        <div>

            <div><b>{getTitle()}</b></div>
            <select className={selectCss} onChange={(e) => setChosenTalentBonus(e)} value={talentNameChosen}>{getOptions(availableTalents)}</select>

            {!props.isCheatMode && talentNameChosen !== "" &&
                <SelectTalentDetails
                    isSpecialTalent={props.isSpecialTalent}
                    thisLevel={props.thisLevel}
                    specialTalents={props.specialTalents}
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                    onSetBonus={(bonus: Bonus) => props.setBonus(bonus)}
                    onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                    onSetRolledTalent={props.onSetRolledTalent}
                    onSetSpecialTalent={props.onSetSpecialTalent}
                    isAmbitionTalent={props.isAmbitionTalent}
                    isCheatMode={props.isCheatMode}
                    isMinimal={props.isMinimal}
                    theClass={props.theClass}
                    spellsKnown={props.spellsKnown}
                    includeSelectTalentOption={false}
                    parentBonusId={props.parentBonusId}
                />
            }

        </div>
    )
}

export default PickTalent;
import React from "react";
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import ChooseTalentOrTwoStatPoints from "./../talents/ChooseTalentOrTwoStatPoints";
import GainArmorMastery from "./../talents/GainArmorMastery";
import GainWeaponMastery from "./../talents/GainWeaponMastery";
import BeastMasterAnimalType from "./../talents/BeastMasterAnimalType";
import PickExtraSpell from "./../talents/PickExtraSpell";
import PickSpellToGainAdvantageCasting from "./../talents/PickSpellToGainAdvCasting";
import PickTalent from "../talents/PickTalent";
import Plus1ToAttacksOrDamage from "../talents/Plus1ToAttacksOrDamage";
import Plus1ToAttacksOrPlus1ToMagicalDabbler from "../talents/Plus1ToAttacksOrPlus1ToMagicalDabbler";
import PlusOneToHit from "./../talents/PlusOneToHit";
import PlusOneToHitAndDamage from "../talents/PlusOneToHitAndDamage";
import PlusTwoChaOrPlusOneWitchCasting from "../talents/PlusTwoChaOrPlusOneWitchCasting";
import PlusTwoINTDEXOrPlusOneElixirs from "../talents/PlusTwoINTDEXOrPlusOneElixirs";
import PlusTwoIntOrPlusOneWizCasting from "./../talents/PlusTwoIntOrPlusOneWizCasting";
import PlusTwoSTRCONOrPlusOneMeleeAttacks from "../talents/PlusTwoSTRCONOrPlusOneMeleeAttacks";
import PlusTwoSTRorDEXOrPlusOneMeleeAttacks from "../talents/PlusTwoSTRDEXOrPlusOneMeleeAttacks";
import RollBlackLotusTalent from "../special/RollBlackLotusTalent";
import SetWeaponTypeDamage from "../talents/SetWeaponTypeDamage";
import StatBonus from "./../talents/StatBonus";
import StatBonusDouble from "../talents/StatBonusDouble";
import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { Spell } from "../../data/spells";
import { Talent } from "../../data/classes";
import LearnMeleeWeaponOrPlus1MeleeAttacks from "../talents/LearnMeleeWeaponOrPlus1MeleeAttacks";
import PlusTwoSTRCONOrPlusOneMeleeDamage from "../talents/PlusTwoSTRCONOrPlusOneMeleeDamage";
import ImmunityToEnergy from "../talents/ImmunityToEnergy";
import LearnSpellFromPatron from "./learnSpellFromPatron";
import LearnLongbowOrPlus1RangedAttacks from "../talents/LearnLongbowOrPlus1RangedAttacks";
import GainRandomBoon from "./gainRandomBoon";
import RollTwoRandomBoons from "./rollTwoRandomBoons";
import PlusTwoWISCHAOrPlusSpellCasting from "../talents/PlusTwoWISCHAOrPlusOneSpellcasting";
import PlusTwoSTRCONOrPlusOneAttacks from "../talents/PlusTwoSTRCONOrPlusOneAttacks";
import PlusTwoSTRCONOrPlusOneAC from "../talents/PlusTwoSTRCONOrPlusOneAC";
import PlusTwoSTRDEXCON from "../talents/PlusTwoSTRDEXCON";
import RollPyromancyTalent from "../special/RollPyromancyTalent";
import PlusTwoCONOrPlusOneSpellCasting from "../talents/PlusTwoCONOrPlusOneSpellcasting";
import ExtraLanguageManual from "../special/ExtraLanguageManual";
import PlusTwoWISOrPlusSpellCasting from "../talents/PlusTwoWISOrPlusOneSpellcasting";
import SelectStrongElixir from "../talents/SelectStrongElixir";
import SelectADVElixir from "../talents/SelectADVElixir";
import LearnWeaponOrArmor from "../talents/LearnWeaponOrArmor";
import PlusOneStatAndRollAnotherTalent from "../talents/PlusOneStatAndRollAnotherTalent";

interface IProps {
    isSpecialTalent: boolean;
    thisLevel: Level;
    specialTalents: Talent[];
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    isAmbitionTalent: boolean;
    isCheatMode: boolean;
    isMinimal: boolean;
    theClass: string;
    spellsKnown: Spell[];
    includeSelectTalentOption: boolean;
    onSetBonus: (bonus: Bonus) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean, parentBonusId?: string) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
    parentBonusId?: string;
}


const SelectTalentDetails: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    let isBoonTalent = false;
    let talentName = props.thisLevel.talentRolledName;
    if (props.isAmbitionTalent) { talentName = globalContext.ambitionTalentLevel.talentRolledName; }

    if (props.sourceCategory === "Boon") {
        isBoonTalent = true;

        let boonBonus = globalContext.bonuses.find((b) =>
            b.sourceType === props.sourceType &&
            b.sourceName === props.sourceName &&
            b.sourceCategory === props.sourceCategory &&
            b.gainedAtLevel === props.thisLevel.level &&
            b.boonPatron === props.boonPatron &&
            b.boonSource === props.boonSource
        );
        if (boonBonus) {
            talentName = boonBonus.name;
        }
    }

    if (props.parentBonusId !== undefined) {
        const theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
        if (theBonus) { talentName = theBonus.name; }
    }

    const onSetBonus = (bonus: Bonus) => {
        props.onSetBonus(bonus);
    }

    const getSelectedTalent = (talentName: string, isBoonTalent: boolean) => {
        let selectedTalent: Talent = { min: 0, max: 0, name: "", desc: "" };

        if (globalContext.charClass) {
            if (!isBoonTalent) {
                let theTalent = globalContext.charClass.talents.find((t) => t.name === talentName);
                if (theTalent) (selectedTalent = theTalent);
            } else {
                // check for warlock talent boons
                let patronObj = globalContext.availableWarlockPatrons.find((p) => p.name === props.boonPatron);
                if (patronObj !== undefined) {
                    let patronsBoons = globalContext.availablePatronBoons.find((p) => p.patronId === patronObj?.id)
                    if (patronsBoons) {
                        let theTalent = patronsBoons.talents.find((t) => t.name === talentName);
                        if (theTalent) (selectedTalent = theTalent);
                    }
                }

            }

        }
        return selectedTalent;
    }

    const selectedTalent = getSelectedTalent(talentName, isBoonTalent);

    if (talentName === "ArmorMastery") {
        return (
            <GainArmorMastery
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus1ToHit" && selectedTalent.options) {
        return (
            <PlusOneToHit
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                options={selectedTalent.options}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus1ToHitAndDamage" && selectedTalent.options) {
        return (
            <PlusOneToHitAndDamage
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                options={selectedTalent.options}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "WeaponMastery") {
        return (
            <GainWeaponMastery
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "SetWeaponTypeDamage" && selectedTalent.size !== undefined) {
        return (
            <SetWeaponTypeDamage
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                size={selectedTalent.size}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "StatBonus" && selectedTalent.options && selectedTalent.options.length > 1) {
        return (
            <StatBonus
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                inputName={"statBonus"}
                options={selectedTalent.options}
                size={selectedTalent.size}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
                parentBonusId={props.parentBonusId}
            />
        )
    }

    if (talentName === "AdvOnCastOneSpell") {
        return (
            <PickSpellToGainAdvantageCasting
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "ExtraLanguageManual") {
        return (
            <ExtraLanguageManual
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                languageNumber={1}
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                label={""}
                minimal={false}
                level={props.thisLevel}
            />
        )
    }


    if (talentName === "Plus2INTOrPlus1Casting") {
        return (
            <PlusTwoIntOrPlusOneWizCasting
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus1ToAttacksOrPlus1ToMagicalDabbler") {
        return (
            <Plus1ToAttacksOrPlus1ToMagicalDabbler
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "LearnExtraSpell") {
        return (
            <PickExtraSpell
                spellsKnown={props.spellsKnown}
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonSource={props.boonSource}
                boonPatron={props.boonPatron}
                isMinimal={props.isMinimal}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
                parentBonusId={props.parentBonusId}
            />)
    }

    if (talentName === "+2 Stat Points") {
        const allStats = ["STR", "DEX", "CON", "INT", "WIS", "CHA"];
        return (
            <StatBonusDouble
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                options={allStats}
                size={1}
                setBonus={(thisBonus: Bonus) => onSetBonus(thisBonus)}
                level={props.thisLevel}
                mustbeDifferentStats={false}
            />)
    }

    if (talentName === "+2 Different Stat Points") {
        const allStats = ["STR", "DEX", "CON", "INT", "WIS", "CHA"];
        return (
            <StatBonusDouble
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                options={allStats}
                size={1}
                setBonus={(thisBonus: Bonus) => onSetBonus(thisBonus)}
                level={props.thisLevel}
                mustbeDifferentStats={true}
            />)
    }

    if (talentName === "ChooseOrTwoStatPoints" && props.includeSelectTalentOption) {
        return (
            <div>
                <ChooseTalentOrTwoStatPoints
                    isSpecialTalent={props.isSpecialTalent}
                    thisLevel={props.thisLevel}
                    specialTalents={props.specialTalents}
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                    isAmbitionTalent={props.isAmbitionTalent}
                    isCheatMode={props.isCheatMode}
                    isMinimal={props.isMinimal}
                    theClass={props.theClass}
                    setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                    onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                    onSetRolledTalent={props.onSetRolledTalent}
                    onSetSpecialTalent={props.onSetSpecialTalent}
                    spellsKnown={props.spellsKnown}
                    level={props.thisLevel}
                />
            </div>
        )
    }

    if (talentName === "ChooseTalent" && props.includeSelectTalentOption) {
        return (
            <div>
                <PickTalent
                    specialTalents={props.specialTalents}
                    thisLevel={props.thisLevel}
                    isSpecialTalent={props.isSpecialTalent}
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                    isAmbitionTalent={props.isAmbitionTalent}
                    isCheatMode={props.isCheatMode}
                    isMinimal={props.isMinimal}
                    talent={selectedTalent}
                    theClass={props.theClass}
                    setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                    onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                    onSetRolledTalent={props.onSetRolledTalent}
                    onSetSpecialTalent={props.onSetSpecialTalent}
                    spellsKnown={props.spellsKnown}
                    parentBonusId={props.parentBonusId}
                />
            </div>
        )
    }

    if (talentName === "Plus2CHAOrPlus1Casting") {
        return (
            <PlusTwoChaOrPlusOneWitchCasting
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "PlusTwoWISCHAOrPlus1SpellCasting") {
        return (
            <PlusTwoWISCHAOrPlusSpellCasting
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "PlusTwoWISOrPlus1SpellCasting") {
        return (
            <PlusTwoWISOrPlusSpellCasting
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
                theClass={props.theClass}
            />
        )
    }

    if (talentName === "Plus2INTDEXOrPlus1Elixir") {
        return (
            <PlusTwoINTDEXOrPlusOneElixirs
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "StrongElixir") {
        return (
            <SelectStrongElixir
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "ADVToElixir") {
        return (
            <SelectADVElixir
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus2STROrCONOrPlus1MeleeDamage") {
        return (
            <PlusTwoSTRCONOrPlusOneMeleeDamage
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus1ToAttacksOrDamage") {
        return (
            <Plus1ToAttacksOrDamage
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus2STROrDEXOrPlus1MeleeAttacks") {
        return (
            <PlusTwoSTRorDEXOrPlusOneMeleeAttacks
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus2STROrCONOrPlus1MeleeAttacks") {
        return (
            <PlusTwoSTRCONOrPlusOneMeleeAttacks
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus2STROrCONOrPlus1Attacks") {
        return (
            <PlusTwoSTRCONOrPlusOneAttacks
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "GrantSpecialTalent:BlackLotusTalent_FreeAtClassLevel1") {
        let specTalentCat = "BlackLotusTalent_FromClassTalent";
        if (props.isAmbitionTalent) {
            specTalentCat = "BlackLotusTalent_FromHumanAmbitionTalent";
        }
        return (
            <RollBlackLotusTalent
                setSpecialTalent={props.onSetSpecialTalent}
                isCheatMode={props.isCheatMode}
                specialTalentCategory={specTalentCat}
                showTalentsTable={false}
                excludeGainTwoTalents={false}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "GrantSpecialTalent:PyromancyTalent") {
        let specTalentCat = "PyromancyTalent_FromClassTalent";
        if (props.isAmbitionTalent) {
            specTalentCat = "PyromancyTalent_FromHumanAmbitionTalent";
        }
        return (
            <RollPyromancyTalent
                setSpecialTalent={props.onSetSpecialTalent}
                isCheatMode={props.isCheatMode}
                specialTalentCategory={specTalentCat}
                showTalentsTable={true}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "LearnMeleeWeaponOrPlusOneMeleeAtk") {
        return (
            <LearnMeleeWeaponOrPlus1MeleeAttacks
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "LearnWeaponOrArmor") {
        return (
            <LearnWeaponOrArmor
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
                parentBonusId={props.parentBonusId}
            />
        )
    }

    if (talentName === "ImmuneToEnergyType") {
        return (
            <ImmunityToEnergy
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "LearnSpellFromPatron") {
        return (
            <LearnSpellFromPatron
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                isMinimal={props.isMinimal}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "LongbowOrPlus1Ranged") {
        return (
            <LearnLongbowOrPlus1RangedAttacks
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "GainRandomBoon") {
        return (
            <GainRandomBoon
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
                isMinimal={props.isMinimal}
                isAmbitionTalent={props.isAmbitionTalent}
                isCheatMode={props.isCheatMode}
                spellsKnown={props.spellsKnown}
                onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                onSetRolledTalent={props.onSetRolledTalent}
                onSetSpecialTalent={props.onSetSpecialTalent}
            />
        )
    }

    if (talentName === "RollTwoBoonsAndKeepOne") {
        return (
            <RollTwoRandomBoons
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
                isMinimal={props.isMinimal}
                isAmbitionTalent={props.isAmbitionTalent}
                isCheatMode={props.isCheatMode}
                spellsKnown={props.spellsKnown}
                onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                onSetRolledTalent={props.onSetRolledTalent}
                onSetSpecialTalent={props.onSetSpecialTalent}
            />
        )
    }

    if (talentName === "BeastMasterAnimalType") {
        return (
            <BeastMasterAnimalType
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus2STROrCONOrPlus1AC") {
        return (
            <PlusTwoSTRCONOrPlusOneAC
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "Plus2STROrDEXOrCON") {
        return (
            <PlusTwoSTRDEXCON
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "PlusTwoCONOrPlusOneSpellCasting") {
        return (
            <PlusTwoCONOrPlusOneSpellCasting
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
            />
        )
    }

    if (talentName === "PlusOneStatAndRollAnotherTalent") {
        return (
            <PlusOneStatAndRollAnotherTalent
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                level={props.thisLevel}
                spellsKnown={props.spellsKnown}
                isCheatMode={props.isCheatMode}
                isMinimal={props.isMinimal}
                onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                onSetRolledTalent={props.onSetRolledTalent}
                onSetSpecialTalent={props.onSetSpecialTalent}
                parentBonusId={props.parentBonusId}
            />
        )
    }

    return null;
}

export default SelectTalentDetails;
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface IProps {
    alignments: string[];
    selectedAlignment: string;
    setAlignment: (alignment: string) => void;
    rollAlignment: () => void;
}


const selectAlignment: React.FunctionComponent<IProps> = (props: IProps) => {

    const options = props.alignments.map((a) => <option value={a} key={a}>{a}</option>);
    options.unshift(<option value="" key="selectAlignment">-- select alignment --</option>);


    const onSelectAlignment = (e: any) => {
        props.setAlignment(e.target.value);
    }

    const selectCss = props.selectedAlignment === "" ? "form-select redBorder" : "form-select";

    return (
        <div>

            <div className="formNote">Alignment</div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => onSelectAlignment(e)} value={props.selectedAlignment}>{options}</select>
                <button className="btn btn-dark" onClick={() => props.rollAlignment()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
            </div>

            {props.selectedAlignment === "" &&
                <div className="valError">Please select an alignment</div>
            }

        </div>
    )

}

export default selectAlignment;
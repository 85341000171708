export interface DuckAbility {
    name: string;
    desc: string;
}

export interface DuckQuackstory {
    name: string;
    desc: string;
    statBonus: string;
}

export const duckAbilities: DuckAbility[] = [
    { name: "Camo-duck", desc: "Your plumage provides camouflage in natural environs, helping you avoid predators." },
    { name: "Decoy", desc: "You can attract an enemy, or draw them away from a more important target, just by being yourself. Quack!" },
    { name: "Direction Sense", desc: "Strangely, you always know which way South is, especially during the winter months." },
    { name: "Diving Duck", desc: "Your webbed feet are made for swimming and waddling on soft surfaces, such as mud or sand." },
    { name: "Feathered Forearms", desc: "When you flap your arms fast enough, you can almost take flight." },
    { name: "Opportunistic Feeder", desc: "You eat a variety of foods, including aquatic plants, seeds, insects, and small fish. This snacking means you don’t need to rely on rations, or tavern food." },
]

export const duckQuackstories: DuckQuackstory[] = [
    { name: "Cheese Makers", desc: "You are blessed. +1 Charisma", statBonus: "CHA" },
    { name: "Tavern Brawlers", desc: "You were hatched in a bar room brawl. You have a hard skull. +1 Constitution.", statBonus: "CON" },
    { name: "Card Hustlers", desc: "You know when to hold ‘em, and when to fold ‘em. You can also count cards. +1 Intelligence.", statBonus: "INT" },
    { name: "Court Bards", desc: "You can juggle, tell bad jokes, and dodge the slings and arrows of outrageous fortune. +1 Dexterity.", statBonus: "DEX" },
    { name: "Menhir Movers", desc: "Since the dawn of time… your ancestors have erected stone circles. +1 Strength.", statBonus: "STR" },
    { name: "Grave Diggers", desc: "You know life is short, and how to dig a proper hole. +1 Wisdom.", statBonus: "WIS" },
]

export const duckNames = [
    "Apple Quackenbush",
    "Aflac Grimduck",
    "Honeydew Aylesbury",
    "Bill Beakerson",
    "Kvakk Blekingeanka",
    "Bluebill Mallardy",
    "Sirius Canard",
    "Cobb Skywaddler",
    "Duclair Ponderson",
    "Moby Duck",
    "Flapper Featherstone",
    "Frank Wisequack",
    "Bryant Grape-Asker",
    "Webster Puddlefoot",
    "Eggbert Duckington",
    "Mallard Mallardson",
    "Mallory Reed",
    "Yolko Pintail",
    "Francesca Splasherton"
];
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import PickAnimalCompanion from "./PickAnimalCompanion";
import AnimalCompanionList from "./AnimalCompanionList";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    isMinimal: boolean;
    currentLevel: number;
    characterIsSaved: boolean;
    label: string;
    bonuses: Bonus[];
    openAnimalModal: boolean; 
    onSetBonus: (bonus: Bonus) => void;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}

const PickAnimalCompanions: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    if (globalContext.className !== "Ovate") { return null; }

    const numAnimalCompanionsAllowed = props.bonuses.filter((b) => b.name === "ExtraAnimalCompanion").length + 1;

    const getPickAnimalCompanionControls = () => {
        let out: any[] = [];
        for (let animalNum = 1; animalNum <= numAnimalCompanionsAllowed; animalNum++) {
            out.push(
                <PickAnimalCompanion
                    key={"pickAnimal" + animalNum}
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    isMinimal={props.isMinimal}
                    currentLevel={props.currentLevel}
                    animalNumber={animalNum}
                    characterIsSaved={props.characterIsSaved}
                    setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                />
            )
        }
        return out;
    }


    return (

        <div>
            <div className="formNote">{props.label}</div>
            {numAnimalCompanionsAllowed === 1 &&
                <div>You can have only one animal companion at a time. You begin the game with one level 0 animal companion. During play you can train a new animal companion whose level is not higher than yours (level {props.currentLevel}). </div>
            }
            {numAnimalCompanionsAllowed > 1 &&
                <div>You can have {numAnimalCompanionsAllowed} animal companions at a time. You begin the game with one level 0 animal companion. During play you can train new animal companions, each of whose levels is not higher than yours  (level {props.currentLevel}). </div>
            }
            {!props.characterIsSaved &&
                <div>After saving the character you will be able to select a level 1 animal companion.</div>
            }
            <div className="mb-2">
                {getPickAnimalCompanionControls()}

                <div>
                    <AnimalCompanionList
                        openModal={props.openAnimalModal}
                        onOpenModal={() => props.onOpenModal("animals")}
                        onCloseModal={() => props.onCloseModal("animals")}
                    />
                </div>
            </div>

        </div>
    )

}

export default PickAnimalCompanions;
import { Ancestry } from "../../../data/ancestries";
import { Bonus } from "../../../data/bonus";
import { Language } from "../../../data/languages";
import { getAllCharClasses } from "../../../data/classes";

export const getAllLanguagesKnown = (languages: Language[], ancestries: Ancestry[], ancestry: string, bonuses: Bonus[], charClass: string | undefined) => {
    let languagesKnown: Language[] = [];

    // Languages from ancestry:
    const ancestryDetails = ancestries.find((a) => a.name === ancestry);
    if (ancestryDetails) {
        ancestryDetails.languages.forEach((l) => {
            const thisLanguage = languages.find((tl) => tl.name === l);
            if (thisLanguage) {
                languagesKnown.push(thisLanguage);
            }
        })
    }

    // Any languages gained through bonuses:
    const bonusLanguages = bonuses.filter((b) => b.name.indexOf("ExtraLanguage") !== -1);
    bonusLanguages.forEach((l) => {
        const thisLanguage = languages.find((tl) => tl.name === l.bonusName);
        if (thisLanguage) {
            languagesKnown.push(thisLanguage);
        }
    })
    const bonusLanguagesManual = bonuses.filter((b) => b.name.indexOf("ExtraLanguageManual:") !== -1);
    bonusLanguagesManual.forEach((l) => {
        const thisLanguage: Language = { name: l.bonusName === undefined ? "?" : l.bonusName, spokenBy: "", category: "Animal", sourceId: "US" };
        languagesKnown.push(thisLanguage);
    })
    const bonusLanguagesBM = bonuses.filter((b) => b.name === "BeastMasterAnimalType");
    bonusLanguagesBM.forEach((a) => {
        let beastLang = "";
        if (a.bonusTo === "Wolf") { beastLang = "Canine"; }
        if (a.bonusTo === "Bear") { beastLang = "Bear"; }
        if (a.bonusTo === "Snake") { beastLang = "Reptile"; }
        if (a.bonusTo === "Eagle") { beastLang = "Raptor"; }
        const thisLanguage: Language = { name: beastLang, spokenBy: "", category: "Animal", sourceId: "US" };
        languagesKnown.push(thisLanguage);
    })

    //Any languages granted by class
    const theClass = getAllCharClasses().find((c) => c.name === charClass);
    if (theClass !== undefined) {
        theClass.extraLanguages.forEach((l) => {
            const thisLanguage = languages.find((tl) => tl.name === l);
            if (thisLanguage) {
                languagesKnown.push(thisLanguage);
            }
        })
    }

    languagesKnown = languagesKnown.sort((l1, l2) => l1.name < l2.name ? -1 : 1);

    return languagesKnown;

}
import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

import app from "../../firebase/firebase";
import { isEmail } from "../../data/utilities";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../charGen/spinner";

interface IProps {
}

const Login: React.FunctionComponent<IProps> = (props: IProps) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signUpState, setSignUpState] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const auth = getAuth(app);

    const handleLogin = async (e: any) => {
        e.preventDefault();

        let isValid = true;

        if (email.trim() !== "")

            if (isValid) {
                setIsLoading(true);
                signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        setIsLoading(false);
                        // Signed in 
                        const user = userCredential.user;
                        if (user) {
                            if (user.emailVerified) {
                                // Update user's email verification status.
                                const db = getFirestore(app);
                                const userRef = doc(db, "users", user?.uid);
                                updateDoc(userRef, { emailVerified: true });

                                navigate("/")
                            } else {
                                setSignUpState("emailVerificationRequired");
                                // auth.signOut();
                            }
                        }
                    })
                    .catch((error) => {
                        setSignUpState("fail");
                        setIsLoading(false);
                        if (error.message) {
                            if (error.code) {
                                switch (error.code) {
                                    case "auth/invalid-email":
                                        setError("The email address is invalid");
                                        break;
                                    case "auth/user-disabled":
                                        setError("The user account is disabled");
                                        break;
                                    case "auth/user-not-found":
                                        setError("The user is not found");
                                        break;
                                    case "auth/wrong-password":
                                        setError("The password is incorrect");
                                        break;
                                    case "auth/too-many-requests":
                                        setError("Too many failed log in attempts have been made. Wait ten minutes then try again.");
                                        break;
                                    default:
                                        setError(error.message);
                                        break;
                                }
                            } else {
                                setError(error.message);
                            }
                        }
                    });
            }
    }

    const selectEmailCss = email.trim() === "" ? "form-control redBorder" : "form-control";
    const selectPasswordCss = password.trim() === "" ? "form-control redBorder" : "form-control";

    const getIsDisabled = () => {
        if (email.trim() === "") { return true; }
        if (!isEmail(email.trim())) { return true; }
        if (password.trim() === "") { return true; }
        if (password.trim().length < 8) { return true; }
        return false;
    }

    const reset = () => {
        // setEmail("");
        setPassword("");
        setSignUpState("");
        setError("");
    }

    const resendEmailVerification = () => {
        if (auth && auth.currentUser) {
            sendEmailVerification(auth.currentUser)
                .then(() => {
                    setSignUpState("emailResent");
                })
                .catch((error) => {
                    setSignUpState("fail");
                    setError(error.message);
                });
        }

    }

    return (
        <div>
            <h1>Log In</h1>


            {signUpState === "fail" &&
                <div>
                    <div className="alert alert-danger">
                        <div><b>Log In failed:</b> {error}</div>
                        <div><button onClick={() => reset()} className="btn btn-dark me-2">Try Again</button></div>
                    </div>
                </div>
            }

            {signUpState === "emailVerificationRequired" &&
                <div className="alert alert-warning">
                    <div><b>You have not verified your email address.</b></div>
                    <div>Log in again after you have verified your email address by clicking on the link in the verification email.</div>
                    <div className="mt-2">
                        <button onClick={() => reset()} className="btn btn-dark me-4">Log In</button>
                        <button onClick={() => resendEmailVerification()} className="btn btn-dark me-2">Resend Verification Email</button>
                    </div>
                </div>
            }

            {signUpState === "emailResent" &&
                <div className="alert alert-warning">
                    <div><b>The verification email has been re-sent to your email address.</b></div>
                    <div>Log in again after you have verified your email address by clicking on the link in the email.</div>
                    <div className="mt-2"><button onClick={() => reset()} className="btn btn-dark me-2">Log In</button></div>
                </div>
            }

            {signUpState === "" &&
                <>
                    <div className="pb-2">Enter your username (email) and password to log into your account.</div>

                    <div className="section">

                        <div>
                            <div className="formNote">Email</div>
                            <div className="input-group">
                                <input className={selectEmailCss} id="email" type="text" onChange={(e) => setEmail(e.currentTarget.value)} value={email}></input>
                            </div>
                            {email.trim() === "" &&
                                <div className="valError">Please enter your email address</div>
                            }
                            {email.trim() !== "" && !isEmail(email.trim()) &&
                                <div className="valError">A valid email address is required</div>
                            }
                        </div>

                        <form onSubmit={(e) => handleLogin(e)}>
                            <div>
                                <div className="formNote">Password</div>
                                <div className="input-group">
                                    <input className={selectPasswordCss} id="password" type="password" onChange={(e) => setPassword(e.currentTarget.value)} value={password}></input>
                                </div>
                                {password.trim() === "" &&
                                    <div className="valError">Please enter a password</div>
                                }
                                {password.trim() !== "" && password.trim().length < 8 &&
                                    <div className="valError">Password must be at least eight characters</div>
                                }
                            </div>
                        </form>

                        <div>
                            <button type="submit" className="btn btn-dark" onClick={(e) => handleLogin(e)} disabled={getIsDisabled()}>Log In</button>
                            <Spinner isLoading={isLoading}/>
                        </div>

                        <div><Link to="/forgotPassword">Forgotten your password?</Link></div>

                    </div>
                </>
            }

        </div>
    )
}

export default Login;

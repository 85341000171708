interface IProps {
    onBuyCrawlingKit: (buyKit: boolean) => void;
}

const BuyCrawlingKit: React.FunctionComponent<IProps> = (props: IProps) => {
    return (
        <div><b>Buy Crawling Kit?</b> <button className="btn btn-dark btn-sm" onClick={() => props.onBuyCrawlingKit(true)}>Yes</button> <button className="btn btn-dark btn-sm" onClick={() => props.onBuyCrawlingKit(false)}>No</button></div>
    )
}

export default BuyCrawlingKit;
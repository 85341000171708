import { Source } from "./sources";

export interface Armor {
    id: string;
    name: string;
    cost: number;
    currency: string;
    slots: number,
    ac_base: number;
    ac_bonus: number;
    allowsDEX: boolean;
    mithril: boolean;
    disadvStealth: boolean;
    disadvSwim: boolean;
    noSwim: boolean;
    oneHand: boolean;
    sundering: boolean; 
    isShield: boolean;
    sourceId: string;
}

const armors: Armor[] = [
    { id: "a1", name: "Leather armor", cost: 10, currency: "gp", slots: 1, ac_base: 11, ac_bonus: 0, allowsDEX: true, mithril: false, disadvStealth: false, disadvSwim: false, noSwim: false, oneHand: false, sundering: false, isShield: false, sourceId: "SD" },
    { id: "a2", name: "Chainmail", cost: 60, currency: "gp", slots: 2, ac_base: 13, ac_bonus: 0, allowsDEX: true, mithril: false, disadvStealth: true, disadvSwim: true, noSwim: false, oneHand: false, sundering: false, isShield: false, sourceId: "SD" },
    { id: "a3", name: "Plate mail", cost: 130, currency: "gp", slots: 3, ac_base: 15, ac_bonus: 0, allowsDEX: false, mithril: false, disadvStealth: true, disadvSwim: true, noSwim: true, oneHand: false, sundering: false, isShield: false, sourceId: "SD" },
    { id: "a4", name: "Shield", cost: 10, currency: "gp", slots: 1, ac_base: 0, ac_bonus: 2, allowsDEX: true, mithril: false, disadvStealth: false, disadvSwim: false, noSwim: false, oneHand: true, sundering: false, isShield: true, sourceId: "SD" },
    { id: "a5", name: "Mithral chainmail", cost: 60 * 4, currency: "gp", slots: 1, ac_base: 13, ac_bonus: 0, allowsDEX: true, mithril: true, disadvStealth: false, disadvSwim: false, noSwim: false, oneHand: false, sundering: false, isShield: false, sourceId: "SD" },
    { id: "a6", name: "Mithral plate mail", cost: 130 * 4, currency: "gp", slots: 2, ac_base: 15, ac_bonus: 0, allowsDEX: false, mithril: true, disadvStealth: false, disadvSwim: true, noSwim: false, oneHand: false, sundering: false, isShield: false, sourceId: "SD" },
    { id: "a7", name: "Mithral shield", cost: 10 * 4, currency: "gp", slots: 0, ac_base: 0, ac_bonus: 2, allowsDEX: true, mithril: true, disadvStealth: false, disadvSwim: false, noSwim: false, oneHand: true, sundering: false, isShield: true, sourceId: "SD" },

    { id: "a8", name: "Round shield", cost: 15, currency: "gp", slots: 1, ac_base: 0, ac_bonus: 2, allowsDEX: true, mithril: false, disadvStealth: false, disadvSwim: false, noSwim: false, oneHand: true, sundering: true, isShield: true, sourceId: "CS3" },
 
]

export const getArmors = (sources: Source[]) => {

    // if (sources.find((s) => s.name === "ShadowDuck")) {
    //     return [...armors, { id: "a8", name: "Duck shield", cost: 10 * 4, currency: "gp", slots: 0, ac_base: 0, ac_bonus: 2, allowsDEX: true, mithril: true, disadvStealth: false, disadvSwim: false, noSwim: false, oneHand: true, sundering: false, isShield: true }];
    // }

    return [...armors];
}

export const getArmorById = (id: string) => {
    return armors.find((w) => w.id === id);
}
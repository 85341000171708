export interface Weapon {
    id: string;
    name: string;
    cost: number;
    currency: string;
    types: string[];
    ranges: string[];
    rangeMult: number;
    damage: number[]; // [6] for 1d6, [6, 8] for a versatile weapon that does 1d6/1d8
    damageMult?: number[]; // used to add die multipliers to damage die
    versatile: boolean;
    slots: number;
    loading: boolean;
    twoHands: boolean;
    thrown: boolean;
    finesse: boolean;
    sundering: boolean;
    isSniper: boolean,
    tangling: boolean;
    isLash: boolean;
    injectable: boolean;
    gas: boolean;
    weaponMasterPopularity: number;
    isBackupWeapon: boolean;
    ammunition?: string;
    sourceIds: string[];

}

export const weapons: Weapon[] = [
    { id: "w1", name: "Bastard sword", cost: 10, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [8, 10], versatile: true, slots: 2, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 23, isBackupWeapon: false, sourceIds: ["SD"] },
    { id: "w2", name: "Club", cost: 5, currency: "cp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 1, isBackupWeapon: true, sourceIds: ["SD"] },
    { id: "w3", name: "Crossbow", cost: 8, currency: "gp", types: ["R"], ranges: ["F"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: true, twoHands: true, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 2, isBackupWeapon: false, ammunition: "Crossbow bolts", sourceIds: ["SD"] },
    { id: "w4", name: "Dagger", cost: 1, currency: "gp", types: ["M", "R"], ranges: ["C", "N"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: true, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 2, isBackupWeapon: true, sourceIds: ["SD"] },
    { id: "w5", name: "Greataxe", cost: 10, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [8, 10], versatile: true, slots: 2, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 11, isBackupWeapon: false, sourceIds: ["SD"] },
    { id: "w6", name: "Greatsword", cost: 12, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [12], versatile: false, slots: 2, loading: false, twoHands: true, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 8, isBackupWeapon: false, sourceIds: ["SD"] },
    { id: "w7", name: "Javelin", cost: 5, currency: "sp", types: ["M", "R"], ranges: ["C", "F"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: true, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 1, isBackupWeapon: true, sourceIds: ["SD"] },
    { id: "w8", name: "Longbow", cost: 8, currency: "gp", types: ["R"], ranges: ["F"], rangeMult: 1, damage: [8], versatile: false, slots: 1, loading: false, twoHands: true, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 21, isBackupWeapon: false, ammunition: "Arrows", sourceIds: ["SD"] },
    { id: "w9", name: "Longsword", cost: 9, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [8], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 19, isBackupWeapon: false, sourceIds: ["SD"] },
    { id: "w10", name: "Mace", cost: 5, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 1, isBackupWeapon: false, sourceIds: ["SD"] },
    { id: "w11", name: "Shortbow", cost: 6, currency: "gp", types: ["R"], ranges: ["F"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: true, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 1, isBackupWeapon: false, ammunition: "Arrows", sourceIds: ["SD"] },
    { id: "w12", name: "Shortsword", cost: 7, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 1, isBackupWeapon: false, sourceIds: ["SD"] },
    { id: "w13", name: "Spear", cost: 5, currency: "sp", types: ["M", "R"], ranges: ["C", "N"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: false, thrown: true, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 6, isBackupWeapon: false, sourceIds: ["SD"] },
    { id: "w14", name: "Staff", cost: 5, currency: "sp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: true, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 1, isBackupWeapon: false, sourceIds: ["SD"] }, 
    { id: "w15", name: "Warhammer", cost: 10, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [10], versatile: false, slots: 1, loading: false, twoHands: true, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 4, isBackupWeapon: false, sourceIds: ["SD"] },

    // CS2

    { id: "w16", name: "Blowgun", cost: 5, currency: "gp", types: ["R"], ranges: ["N"], rangeMult: 1, damage: [1], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: true, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 0, isBackupWeapon: true, sourceIds: ["CS2"] },
    { id: "w17", name: "Bolas", cost: 2, currency: "gp", types: ["R"], ranges: ["N"], rangeMult: 1, damage: [0], versatile: false, slots: 1, loading: false, twoHands: false, thrown: true, finesse: false, sundering: false, isSniper: false, tangling: true, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 3, isBackupWeapon: true, sourceIds: ["CS2", "US"] },
    { id: "w18", name: "Morning Star", cost: 5, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [6, 8], versatile: true, slots: 1, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 8, isBackupWeapon: false, sourceIds: ["CS2"] },
    { id: "w20", name: "Razor Chain", cost: 12, currency: "gp", types: ["M", "R"], ranges: ["N"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: true, injectable: false, gas: false, weaponMasterPopularity: 10, isBackupWeapon: false, sourceIds: ["CS2"] },
    { id: "w19", name: "Pike", cost: 10, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 2, damage: [10], versatile: false, slots: 2, loading: false, twoHands: true, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 10, isBackupWeapon: false, sourceIds: ["CS2"] },
    { id: "w21", name: "Scimitar", cost: 8, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 10, isBackupWeapon: false, sourceIds: ["CS2"] },
    { id: "w22", name: "Shuriken", cost: 1, currency: "gp", types: ["R"], ranges: ["N"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: true, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 5, isBackupWeapon: true, sourceIds: ["CS2"] },
    { id: "w23", name: "Sling", cost: 5, currency: "sp", types: ["R"], ranges: ["F"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 5, isBackupWeapon: true, sourceIds: ["CS2", "US"] },
    { id: "w24", name: "Whip", cost: 10, currency: "gp", types: ["M", "R"], ranges: ["N"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: true, injectable: false, gas: false, weaponMasterPopularity: 3, isBackupWeapon: true, sourceIds: ["CS2", "US"] },

    // CS3

    { id: "w25", name: "Handaxe", cost: 2, currency: "gp", types: ["M", "R"], ranges: ["C", "N"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: false, thrown: true, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 4, isBackupWeapon: true, sourceIds: ["CS3"] },
    { id: "w26", name: "Stave", cost: 2, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: true, thrown: false, finesse: false, sundering: true, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 5, isBackupWeapon: false, sourceIds: ["CS3"] },

    // US

    { id: "w27", name: "Darts", cost: 1, currency: "gp", types: ["R"], ranges: ["N"], rangeMult: 1, damage: [2], versatile: false, slots: 1, loading: false, twoHands: false, thrown: true, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: true, gas: false, weaponMasterPopularity: 2, isBackupWeapon: true, sourceIds: ["US"] },
    { id: "w28", name: "Needle Whip", cost: 10, currency: "gp", types: ["M", "R"], ranges: ["C", "N"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: true, gas: false, injectable: true, weaponMasterPopularity: 2, isBackupWeapon: false, sourceIds: ["US"] },
    { id: "w29", name: "Rapier", cost: 8, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: true, gas: false, weaponMasterPopularity: 3, isBackupWeapon: false, sourceIds: ["US"] },
    { id: "w30", name: "Stiletto", cost: 5, currency: "sp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: true, gas: false, weaponMasterPopularity: 2, isBackupWeapon: true, sourceIds: ["US"] },
    { id: "w31", name: "Censer Flail", cost: 20, currency: "gp", types: ["M", "R"], ranges: ["N"], rangeMult: 1, damage: [8], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: true, injectable: false, gas: true, weaponMasterPopularity: 3, isBackupWeapon: false, sourceIds: ["US"] },
    { id: "w32", name: "Hunting Knife", cost: 2, currency: "sp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [4], versatile: false, slots: 1, loading: false, twoHands: false, thrown: false, finesse: true, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 2, isBackupWeapon: true, sourceIds: ["US"] },
    { id: "w33", name: "Scythe", cost: 2, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [6], versatile: false, slots: 1, loading: false, twoHands: true, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 2, isBackupWeapon: false, sourceIds: ["US"] },
]

export const getWeaponById = (id: string) => {
    return weapons.find((w) => w.id === id);
}

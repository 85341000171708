import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { spells } from "../../data/spells";
import { getSpellTierForClass } from "../../data/utilities";
import { Level } from "../../data/level";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    isMinimal: boolean;
    setBonus: (bonus: Bonus) => void;
    level: Level;
}

const LearnSpellFromPatron: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const allSpells = spells;

    const availSpells = allSpells.filter((s) => s.classes.indexOf("Wizard") !== -1 && s.tierByClass[0] <= Math.round(props.level.level / 2)).sort((s1, s2) => s1.name < s2.name ? -1 : 1);

    const options = availSpells.map((s) => <option value={s.name} key={s.name}>{s.name}</option>);
    options.unshift(<option value="" key="selectSpell">-- select additional spell --</option>);

    let currentValue = "";
    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.boonPatron === props.boonPatron && b.boonSource === props.boonSource && b.gainedAtLevel === props.level.level && b.name === "LearnSpellFromPatron");
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setSpellBonus = (spell: string) => {

        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "LearnSpellFromPatron",
            bonusTo: "",
            bonusName: spell,
        };
        props.setBonus(bonus);
    }

    const getSpellDesc = () => {
        if (props.isMinimal) { return null; }
        if (currentValue !== "") {
            const theSpell = allSpells.find((s) => s.name === currentValue);
            if (theSpell) {
                const tier = getSpellTierForClass(globalContext.className, theSpell.name);
                return <div className="spell">Tier: {tier}, Range: {theSpell.range}, Duration: {theSpell.duration}. {theSpell.desc}</div>
            }
        }
        return null;
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    return (
        <div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setSpellBonus(e.target.value)} value={currentValue}>{options}</select>
            </div>
            {getSpellDesc()}
        </div>
    )

}

export default LearnSpellFromPatron;
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    selectedName: string;
    setName: (name: string) => void;
    rollName: () => void;
}


const selectName: React.FunctionComponent<IProps> = (props: IProps) => {

    const onSetName = (e: any) => {
        props.setName(e.target.value);
    }

    const selectCss = props.selectedName === "" ? "form-control redBorder" : "form-control";

    return (
        <div>

            <div className="formNote">Name</div>
            <div className="input-group">
                <input className={selectCss} id="name" type="text" onChange={(e) => onSetName(e)} value={props.selectedName}></input>
                <button className="btn btn-dark" onClick={() => props.rollName()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
            </div>

            {props.selectedName.trim() === "" &&
                <div className="valError">Please enter a name</div>
            }

        </div>
    )

}

export default selectName;
import { useNavigate, useLocation } from 'react-router-dom';

import { User, getAuth, signOut } from 'firebase/auth';
import app from '../../firebase/firebase';

interface IProps {
    user: User | null | undefined;
    isAdmin: boolean;
}

const Menu: React.FunctionComponent<IProps> = (props: IProps) => {

    const navigate = useNavigate();
    const location = useLocation();

    const logOut = () => {
        const auth = getAuth(app);
        if (auth) {
            signOut(auth).then(() => {
                navigate("/");
            })
        }
    }

    const getNavLinkClassName = (pathName: string) => {
        let className = "nav-link";
        if (location.pathname === pathName) {
            className = className + " active";
        }
        return className;
    }

    return (
        <>
            <ul className="nav nav-fill border-bottom mb-3">
                <li className="nav-item">
                    <a className={getNavLinkClassName("/")} href="#!" onClick={() => navigate('/')}>Home</a>
                </li>
                {props.user &&
                    <li className="nav-item">
                        <a className={getNavLinkClassName("/characters")} href="#!" onClick={() => navigate('/characters')}>Characters</a>
                    </li>
                }
                {!props.user &&
                    <li className="nav-item">
                        <a className={getNavLinkClassName("/create")} href="#!" onClick={() => navigate('/create')}>Create</a>
                    </li>
                }
                <li className="nav-item">
                    <a className={getNavLinkClassName("/resources")} href="#!" onClick={() => navigate('/resources')}>Resources</a>
                </li>
                <li className="nav-item d-none d-sm-block">
                    <a className={getNavLinkClassName("/faq")} href="#!" onClick={() => navigate('/faq')}>FAQ</a>
                </li>
                <li className="nav-item d-none d-sm-block">
                    <a className={getNavLinkClassName("/contact")} href="#!" onClick={() => navigate('/contact')}>Contact</a>
                </li>
                {(props.user && props.isAdmin) &&
                    <>
                        <li className="nav-item">
                            <a className={getNavLinkClassName("/admin/allCharacters")} href="#!" onClick={() => navigate('/admin/allCharacters')}>All Chars</a>
                        </li>
                        <li className="nav-item">
                            <a className={getNavLinkClassName("/admin/allUsers")} href="#!" onClick={() => navigate('/admin/allUsers')}>All Users</a>
                        </li>
                        <li className="nav-item">
                            <a className={getNavLinkClassName("/admin/testRandom")} href="#!" onClick={() => navigate('/admin/testRandom')}>Test Random</a>
                        </li>
                    </>
                }
                {props.user &&
                    <li className="nav-item">
                        <a className={getNavLinkClassName("")} href="#!" onClick={() => logOut()}>Log Out</a>
                    </li>
                }
                {!props.user &&
                    <li className="nav-item">
                        <a className={getNavLinkClassName("")} href="#!" onClick={() => navigate("/login")}>Log In</a>
                    </li>
                }
            </ul>
        </>
    )

}

export default Menu;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Armor } from "../../data/armor";
import { GearOwned } from "../../data/gear";
import { Stats, modStr } from "../../data/stats";
import { getGearRecords } from "../../data/utilities";

import download from 'downloadjs';
import { getPDFFillableFourZero } from "../../special/printCharFillableFourZero";
import { getAllAncestries } from "../../data/ancestries";

interface IProps {
    fourCharacters: any[];
}

const GenerateFourLevelZeroes: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getStatsAsString = (stats: Stats, hp: number, alignment: string) => {
        let out: any[] = [];
        out.push(<span key="S"><b>S</b> {modStr(stats.Strength)}, </span>);
        out.push(<span key="D"><b>D</b> {modStr(stats.Dexterity)}, </span>);
        out.push(<span key="C"><b>C</b> {modStr(stats.Constitution)}, </span>);
        out.push(<span key="I"><b>I</b> {modStr(stats.Intelligence)}, </span>);
        out.push(<span key="W"><b>W</b> {modStr(stats.Wisdom)}, </span>);
        out.push(<span key="Ch"><b>Ch</b> {modStr(stats.Charisma)}, </span>);
        out.push(<span key="AL"><b>AL</b> {alignment.substring(0, 1)}, </span>);
        out.push(<span key="HP"><b>HP</b> {hp}</span>);
        return out;
    }

    const getGearAsString = (gear: GearOwned[], armors: Armor[]) => {
        const out: string[] = [];

        const gearRecords = getGearRecords(gear, [], [], 0, 0, 0, armors, false);

        gearRecords.forEach((g) => {
            if (g.quantity === 1) {
                if (g.totalUnits !== 1) {
                    out.push(g.name + " (" + g.totalUnits + ")");
                } else {
                    out.push(g.name);
                }

            } else {
                if (g.totalUnits !== g.quantity) {
                    out.push(g.name + " (" + g.totalUnits + ") x " + g.quantity);
                } else {
                    out.push(g.name + " x " + g.quantity);
                }
            }


        })

        return out.join(", ");
    }

    const getFourCharsDesc = () => {
        const out: any[] = [];
        props.fourCharacters.forEach((c, index) => {
            out.push(
                <div key={index}>
                    <div><b>{c.name} the {c.ancestry} ({c.background})</b></div>
                    <div className="indent">{getStatsAsString(c.stats, c.hp, c.alignment)}, <b>Gear:</b> {getGearAsString(c.gear, c.armors)}</div >
                </div>
            );
        });
        return out;
    }

    const printCharacters = async () => {
        const pdfBytes = await getPDFFillableFourZero(props.fourCharacters, globalContext.availableArmors, getAllAncestries());
        download(pdfBytes, "FourLevelZero.pdf", "application/pdf");
    }

    return (
        <div className="sectionBorder">
            <h3>Four Level 0 Characters</h3>

            <div className="sectionBorder">
                {getFourCharsDesc()}
            </div>

            <div className="sectionBorder">
                <h2>Export</h2>
                <button onClick={() => printCharacters()} className="btn btn-dark">PDF</button>
                <div className="small">Thanks to smathis (Scott) for creating this PDF, and to Kelsey for tweaking the fields. :)</div>
            </div>

        </div>
    )

}

export default GenerateFourLevelZeroes;
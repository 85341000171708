import { titles } from "../../../data/titles";

export const getTitle = (charClass: string, alignment: string, level: number) => {
    let title = "None";

    const titleLookup = titles.find((tl) => tl.class === charClass && tl.alignment === alignment);
    if (titleLookup) {
        const theTitle = titleLookup.titleForLevels.find((tfl) => level >= tfl.minLevel && level <= tfl.maxLevel);
        if (theTitle) {
            title = theTitle.title;
        }
    }

    return title;
}
export interface Deity {
    name: string;
    alignments: string[];
    pantheon: string;
    desc: string;
    sourceId: string;
}

const coreDeities: Deity[] = [
    {
        name: "Saint Terragnis",
        alignments: ["Lawful"],
        pantheon: "Four Lords", 
        desc: "A legendary knight who is the patron of most lawful humans. She ascended to godhood long ago and is the embodiment of righteousness and justice.",
        sourceId: "SD"
    },
    {
        name: "Gede",
        alignments: ["Neutral"],
        pantheon: "Four Lords", 
        desc: "The god of feasts, mirth, and the wilds. Gede is usually peaceful, but primal storms rage when her anger rises. Many elves and halflings worship her.",
        sourceId: "SD"
    },
    {
        name: "Madeera the Covenant",
        alignments: ["Lawful"],
        pantheon: "Four Lords", 
        desc: "Madeera was the first manifestation of Law. She carries every law of reality, a dictate called the Covenant, written on her skin in precise symbols.",
        sourceId: "SD"
    },
    {
        name: "Ord",
        alignments: ["Neutral"],
        pantheon: "Four Lords", 
        desc: "Ord the Unbending, the Wise, the Secret-Keeper. He is the god of magic, knowledge, secrets, and equilibrium.",
        sourceId: "SD"
    },
    {
        name: "Memnon",
        alignments: ["Chaotic"],
        pantheon: "Dark Trio", 
        desc: "Memnon was the first manifestation of Chaos. He is Madeera’s twin, a red-maned, leonine being whose ultimate ambition is to rend the cosmic laws of the Covenant from his sister’s skin.",
        sourceId: "SD"
    },
    {
        name: "Ramlaat",
        alignments: ["Chaotic"],
        pantheon: "Dark Trio", 
        desc: "Ramlaat is the Pillager, the Barbaric, the Horde. Many orcs worship him and live by the Blood Rite, a prophecy that says only the strongest will survive a coming doom.",
        sourceId: "SD"
    },
    {
        name: "Shune the Vile",
        alignments: ["Chaotic"],
        pantheon: "Dark Trio", 
        desc: "Shune whispers arcane secrets to sorcerers and witches who call to her in the dark hours. She schemes to displace Ord so she can control the vast flow of magic herself.",
        sourceId: "SD"
    },
    {
        name: "The Lost",
        alignments: ["Alignment unknown"],
        pantheon: "", 
        desc: "Two of The Nine are lost to the ages, their names expunged from history and memory. Yet their whispered legend lingers on in ancient, forbidden texts and secret, deep places...",
        sourceId: "SD"
    },

    // CS3 Gods

    {
        name: "All Nord Gods",
        alignments: ["Lawful", "Chaotic", "Neutral"],
        pantheon: "Nord Pantheon", 
        desc: "The faithful worship all of the Old Gods in turn, but many find they align with one in particular. Paying respect to all the gods is not a betrayal; it is honorable.",
        sourceId: "CS3"
    },
    {
        name: "Odin",
        alignments: ["Lawful"],
        pantheon: "Nord Pantheon", 
        desc: "The Allfather. Odin the One-Eyed values strength and cleverness. His twin ravens wing through the night, spying on dreams and memories. Worthy warriors are brought to the Great Hall of Valhalla when they die to feast and fight beside him eternally.",
        sourceId: "CS3"
    },
    {
        name: "Freya",
        alignments: ["Neutral"],
        pantheon: "Nord Pantheon",
        desc: "The goddess of love and hatred. The Queen of Shield Maidens whose valkyries carry worthy souls to the Great Feast. The First Seer who places her omens in bones, blood, and entrails. Freya inspires the poets and then torments them with lack.",
        sourceId: "CS3"
    },
    {
        name: "Loki",
        alignments: ["Chaotic"],
        pantheon: "Nord Pantheon", 
        desc: "The Deceiver who wins with wit and wile. The wolf who disguises himself as a sheep. Loki is glib and infuriating; his words cut like steel, and his laughter howls like a gale. His followers do not seek Valhalla, for they know it will fall when Ragnarok comes.",
        sourceId: "CS3"
    },
    
    // Duck Gods

    {
         name: "Saint Cuddy",
         alignments: ["Lawful", "Neutral"],
         pantheon: "", 
         desc: "For those inclined to pray, Saint Cuddy is the no-nonsense patron deity of Ducks, and orderly conduct. The hammer and the anvil of righteousness, Clerics of St. Cuddy are not the preening, shining Paladins of Poultus. Nay, these are fighting priests!",
         sourceId: "DP"
     },
     {
         name: "Cerberpug",
         alignments: ["Lawful"],
         pantheon: "", 
         desc: "This three-headed deity is the patron god of dogs, and has three aspects, one for each noggin. Chief, Dog God of Naps, is the central figurehead in the religion. Lucky, the Dog God of Chasing Vehicles, is represented by the left head. The third head is Zeke, the Dog God of Chewing Precious Things. Followers of Cerberpug are dogmatic, and do not stray from the path. This is the wag.",
         sourceId: "DP"
     },

    // CS3 Unnatural selection spirits and gods

    {
        name: "Aelorin",
        alignments: ["Neutral"],
        pantheon: "Nature Spirits", 
        desc: "The woodland guardian. She oversees the harmony and natural magic of the woodland realm. Aelorin urges followers to avoid upsetting the delicate balance of the forest and protect it from those who might.",
        sourceId: "US"
    },
    {
        name: "Aqualis",
        alignments: ["Neutral"],
        pantheon: "Nature Spirits", 
        desc: "Often referred to as the Water Spirits, Aqualis is the collective of every drop of water with its own distinctiveness, yet collectively they form a symphony of liquid life. Followers of Aqualis learn that there are always many paths around obstacles, and those paths rarely require effort to find.",
        sourceId: "US"
    },
    {
        name: "Caapi",
        alignments: ["Neutral"],
        pantheon: "Nature Spirits", 
        desc: "The sacred spirit of plant medicine, she guides seekers on a transformative journey of self-discovery and healing, occasionally using demanding methods to achieve her goals. Disciples are granted ancient wisdom into the healing properties of plants.",
        sourceId: "US"
    },
    {
        name: "Gaianathos",
        alignments: ["Neutral"],
        pantheon: "Nature Spirits", 
        desc: "The living island. He is an ancient and primordial entity. On his colossal shell, entire civilizations have risen and fallen. Those who revere Gaianthos seek his guidance to find patience, to cultivate their own resilience, and to support all of existence on their backs.",
        sourceId: "US"
    },
    {
        name: "Liora",
        alignments: ["Neutral"],
        pantheon: "Nature Spirits", 
        desc: "The regal lioness. She embodies courage, strength, and the prowess to protect and lead. Followers of Liora seek her guidance to develop their inner strength, embrace their leadership qualities, and fiercely protect what is dear to them.",
        sourceId: "US"
    },
    {
        name: "Verdantix",
        alignments: ["Neutral"],
        pantheon: "Nature Spirits", 
        desc: "The natural spirit of death and transformation, symbolized by thorny brambles. He guides souls across the bridge of what was and what will be, teaching that death is a vital step in the cycle of existence. His followers embrace the interconnectedness of both.",
        sourceId: "US"
    },

    {
        name: "Lythia",
        alignments: ["Neutral"],
        pantheon: "Death Pantheon", 
        desc: "The Shatterer of Chains, Lythia liberates souls from the cycle of reincarnation, allowing them to transcend into a final state as they cross over into the great expanse beyond. Followers of Lythia receive the gift of eternal rest.",
        sourceId: "US"
    },
    {
        name: "Dominus",
        alignments: ["Chaotic"],
        pantheon: "Death Pantheon", 
        desc: "The true Harbinger of Suffering, he is a malevolent god of death who uses manipulation and twisted charm to mislead those transitioning to the afterlife. With promises of fleeting pleasure and indulgence, he dupes souls to sign away their eternal peace to satiate his unending delight.",
        sourceId: "US"
    },
    {
        name: "Morthrax",
        alignments: ["Chaotic"],
        pantheon: "Death Pantheon", 
        desc: "The Devourer consumes souls, bringing an eternal nothingness for any he ingests. To feed his hunger, Morthrax stokes the fires of war among mortals. His disciples receive a relentless drive for conquest and a spot at his table or on his plate, depending on service.",
        sourceId: "US"
    },
    {
        name: "Peradine",
        alignments: ["Lawful"],
        pantheon: "Death Pantheon", 
        desc: "The Arbiter adminsters just and accurate judgement to every soul. He rewards or punishes based on piety, integrity, valor, justice, and empathy. His followers revere him as the epitome of righteousoness, seeking guidance to attain peace beyond mortality.",
        sourceId: "US"
    }
];

export const getAllDeities = () => {
    return [...coreDeities]
}
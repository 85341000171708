import { PDFDocument, PDFFont } from "pdf-lib";
import { getClassNameForSpellCastingClassNum, getSpellTierForClass } from "../data/utilities";
import { Spell } from "../data/spells";
import { CharClass } from "../data/classes";
import { Bonus } from "../data/bonus";
import { Treasure } from "../data/treasure";
import { MagicItem } from "../data/magicItem";
import { Weapon } from "../data/weapons";
import { Armor } from "../data/armor";
import { Creature } from "../data/animals";
import { plusMinus } from "../data/stats";

export const addExtraPages = async (
    pdfDoc: PDFDocument,
    customFont: PDFFont,
    charClass: CharClass | undefined,
    className: string,
    bonuses: Bonus[],
    spellsText: string,
    spells: Spell[],
    treasuresCarried: Treasure[],
    magicItemsCarried: MagicItem[],
    weapons: Weapon[],
    armors: Armor[],
    animalCompanions: Creature[]
) => {

    // Beastmaster animal forms page
    if (className === "Beastmaster") {

        // Get animal forms
        const animalFormBonuses = bonuses.filter((b) => b.name === "BeastMasterAnimalType" && b.bonusTo !== "");
        if (animalFormBonuses) {
            const pageBM = pdfDoc.addPage([841.89, 595.28]);

            let yAdjust = 0;
            let spacing = 25;
            let scale = Math.min((1 / animalFormBonuses.length) * 2.25, 1);

            // Wolf
            if (animalFormBonuses.find((b) => b.bonusTo === "Wolf")) {
                const pngUrl1 = 'https://i.imgur.com/FdIGyef.png';
                const pngImageBytes1 = await fetch(pngUrl1).then((res) => res.arrayBuffer());
                const pngImage1 = await pdfDoc.embedPng(pngImageBytes1);
                const pngDims1 = pngImage1.scale(scale);

                let y = pageBM.getHeight() - pngDims1.height - spacing;

                pageBM.drawImage(pngImage1, {
                    x: 50,
                    y: y - yAdjust,
                    width: pngDims1.width,
                    height: pngDims1.height,
                })
                yAdjust = yAdjust + pngDims1.height + spacing;
            }

            // Bear
            if (animalFormBonuses.find((b) => b.bonusTo === "Bear")) {
                const pngUrl2 = 'https://i.imgur.com/hBbR9BT.png';
                const pngImageBytes2 = await fetch(pngUrl2).then((res) => res.arrayBuffer());
                const pngImage2 = await pdfDoc.embedPng(pngImageBytes2);
                const pngDims2 = pngImage2.scale(scale);

                let y = pageBM.getHeight() - pngDims2.height - spacing;

                pageBM.drawImage(pngImage2, {
                    x: 50,
                    y: y - yAdjust,
                    width: pngDims2.width,
                    height: pngDims2.height,
                })
                yAdjust = yAdjust + pngDims2.height + spacing;
            }

            // Snake
            if (animalFormBonuses.find((b) => b.bonusTo === "Snake")) {
                const pngUrl3 = 'https://i.imgur.com/OEzERuP.png';
                const pngImageBytes3 = await fetch(pngUrl3).then((res) => res.arrayBuffer());
                const pngImage3 = await pdfDoc.embedPng(pngImageBytes3);
                const pngDims3 = pngImage3.scale(scale);

                let y = pageBM.getHeight() - pngDims3.height - spacing;

                pageBM.drawImage(pngImage3, {
                    x: 50,
                    y: y - yAdjust,
                    width: pngDims3.width,
                    height: pngDims3.height,
                })
                yAdjust = yAdjust + pngDims3.height + spacing;
            }

            // Eagle
            if (animalFormBonuses.find((b) => b.bonusTo === "Eagle")) {
                const pngUrl4 = 'https://i.imgur.com/X2XQ7US.png';
                const pngImageBytes4 = await fetch(pngUrl4).then((res) => res.arrayBuffer());
                const pngImage4 = await pdfDoc.embedPng(pngImageBytes4);
                const pngDims4 = pngImage4.scale(scale);

                let y = pageBM.getHeight() - (pngDims4.height * 0.75) - spacing;

                pageBM.drawImage(pngImage4, {
                    x: 50,
                    y: y - yAdjust,
                    width: pngDims4.width * 0.75,
                    height: pngDims4.height * 0.75,
                })
                yAdjust = yAdjust + pngDims4.height + spacing;
            }
        }

    }

    // Plague Doctor elixirs page
    if (className === "Plague Doctor") {
        const pagePlague = pdfDoc.addPage([841.89, 595.28]);

        const pngUrl = 'https://i.imgur.com/CEqVj1O.png';
        const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const pngDims = pngImage.scale(0.75);
        pagePlague.drawImage(pngImage, {
            x: 50,
            y: pagePlague.getHeight() - pngDims.height - 50,
            width: pngDims.width,
            height: pngDims.height,
        })
    }

    // Desert Rider mounts page
    if (className === "Desert Rider") {
        const pageRider = pdfDoc.addPage([841.89, 595.28]);

        const pngUrl = 'https://i.imgur.com/FJpSFQh.png';
        const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const pngDims = pngImage.scale(0.625);
        pageRider.drawImage(pngImage, {
            x: 50,
            y: pageRider.getHeight() - pngDims.height - 50,
            width: pngDims.width,
            height: pngDims.height,
        })
    }

    // spells pages
    if (spellsText.trim() !== "None") {

        const spellBaseDC = charClass && charClass.name === "Shaman" ? 9 : 10;

        const page2 = pdfDoc.addPage([841.89, 595.28]);

        if (spells.length <= 6) {
            // single column
            let thisSpellStr = "";
            spells.forEach((sp) => {

                const spellClassName = getClassNameForSpellCastingClassNum(charClass?.spellCastingClassNum, sp);
                const tier = getSpellTierForClass(className, sp.name);

                const castWithAdv = sp.castWithAdv ? " (always cast with advantage)" : "";

                let castWithMastery = "";
                const spellMastery = bonuses.find((b) => b.name === "AdvOnCastOneSpell" && b.bonusName === sp.name);
                if (spellMastery) { castWithMastery = " (cast with advantage due to Spell Mastery)" }

                let learnedFromScroll = "";
                const learnScroll = bonuses.find((b) => b.bonusTo === "LearnSpellFromScroll" && b.bonusName === sp.name);
                if (learnScroll) { learnedFromScroll = " (learned from a scroll)"; }

                thisSpellStr += `${sp.name.toUpperCase()} (Tier ${tier}, ${spellClassName}), DC ${spellBaseDC + tier} to cast${castWithAdv}${castWithMastery}${learnedFromScroll}\n`
                thisSpellStr += `Duration: ${sp.duration}\n`;
                thisSpellStr += `Range: ${sp.range}\n`;
                thisSpellStr += `${sp.desc}\n \n`;
            })

            page2.moveTo(58, 550);
            let spellsFontSize = 10;
            page2.drawText(thisSpellStr, { size: spellsFontSize, maxWidth: 730, lineHeight: spellsFontSize * 1.25, font: customFont });

        } else {
            //multiple pages
            let thisSpellStr = "";
            spells.forEach((sp, index) => {
                if (index + 1 <= 6) {

                    const spellClassName = getClassNameForSpellCastingClassNum(charClass?.spellCastingClassNum, sp);
                    const tier = getSpellTierForClass(className, sp.name);

                    const castWithAdv = sp.castWithAdv ? " (always cast with advantage)" : "";

                    let castWithMastery = "";
                    const spellMastery = bonuses.find((b) => b.name === "AdvOnCastOneSpell" && b.bonusName === sp.name);
                    if (spellMastery) { castWithMastery = " (cast with advantage due to Spell Mastery)" }

                    let learnedFromScroll = "";
                    const learnScroll = bonuses.find((b) => b.bonusTo === "LearnSpellFromScroll" && b.bonusName === sp.name);
                    if (learnScroll) { learnedFromScroll = " (learned from a scroll)"; }

                    thisSpellStr += `${sp.name.toUpperCase()} (Tier ${tier}, ${spellClassName}), DC ${spellBaseDC + tier} to cast${castWithAdv}${castWithMastery}${learnedFromScroll}\n`
                    thisSpellStr += `Duration: ${sp.duration}\n`;
                    thisSpellStr += `Range: ${sp.range}\n`;
                    thisSpellStr += `${sp.desc}\n \n`;
                }
            })

            page2.moveTo(58, 550);
            let spellsFontSize = 10;
            page2.drawText(thisSpellStr, { size: spellsFontSize, maxWidth: 730, lineHeight: spellsFontSize * 1.25, font: customFont });

            thisSpellStr = "";
            spells.forEach((sp, index) => {
                if (index + 1 > 6 && index + 1 <= 12) {

                    const spellClassName = getClassNameForSpellCastingClassNum(charClass?.spellCastingClassNum, sp);
                    const tier = getSpellTierForClass(className, sp.name);

                    const castWithAdv = sp.castWithAdv ? " (always cast with advantage)" : "";

                    let castWithMastery = "";
                    const spellMastery = bonuses.find((b) => b.name === "AdvOnCastOneSpell" && b.bonusName === sp.name);
                    if (spellMastery) { castWithMastery = " (cast with advantage due to Spell Mastery)" }

                    let learnedFromScroll = "";
                    const learnScroll = bonuses.find((b) => b.bonusTo === "LearnSpellFromScroll" && b.bonusName === sp.name);
                    if (learnScroll) { learnedFromScroll = " (learned from a scroll)"; }

                    thisSpellStr += `${sp.name.toUpperCase()} (Tier ${tier}, ${spellClassName}), DC ${spellBaseDC + tier} to cast${castWithAdv}${castWithMastery}${learnedFromScroll}\n`
                    thisSpellStr += `Duration: ${sp.duration}\n`;
                    thisSpellStr += `Range: ${sp.range}\n`;
                    thisSpellStr += `${sp.desc}\n \n`;
                }
            })

            const page3 = pdfDoc.addPage([841.89, 595.28]);
            page3.moveTo(58, 550);
            page3.drawText(thisSpellStr, { size: spellsFontSize, maxWidth: 730, lineHeight: spellsFontSize * 1.25, font: customFont });

            thisSpellStr = "";
            spells.forEach((sp, index) => {
                if (index + 1 > 12) {
                    const castWithAdv = sp.castWithAdv ? " (always cast with advantage)" : "";

                    const spellClassName = getClassNameForSpellCastingClassNum(charClass?.spellCastingClassNum, sp);
                    const tier = getSpellTierForClass(className, sp.name);

                    let castWithMastery = "";
                    const spellMastery = bonuses.find((b) => b.name === "AdvOnCastOneSpell" && b.bonusName === sp.name);
                    if (spellMastery) { castWithMastery = " (cast with advantage due to Spell Mastery)" }

                    let learnedFromScroll = "";
                    const learnScroll = bonuses.find((b) => b.bonusTo === "LearnSpellFromScroll" && b.bonusName === sp.name);
                    if (learnScroll) { learnedFromScroll = " (learned from a scroll)"; }

                    thisSpellStr += `${sp.name.toUpperCase()} (Tier ${tier}, ${spellClassName}), DC ${spellBaseDC + tier} to cast${castWithAdv}${castWithMastery}${learnedFromScroll}\n`
                    thisSpellStr += `Duration: ${sp.duration}\n`;
                    thisSpellStr += `Range: ${sp.range}\n`;
                    thisSpellStr += `${sp.desc}\n \n`;
                }
            })

            const page4 = pdfDoc.addPage([841.89, 595.28]);
            page4.moveTo(58, 550);
            page4.drawText(thisSpellStr, { size: spellsFontSize, maxWidth: 730, lineHeight: spellsFontSize * 1.25, font: customFont });

        }

    }

    // Ovate animal companions
    if (className === "Ovate") {
        let animalStr = "";
        animalStr += `ANIMAL COMPANIONS:\n \n`;
        animalCompanions.forEach((a, index) => {
            animalStr += `${a.name.toUpperCase()}\n`;
            animalStr += `${a.desc}\n`;
            animalStr += `AC ${a.AC}, `
            animalStr += `HP ${a.HP}, `
            animalStr += `ATK ${a.attacks}, `
            animalStr += `MV ${a.move}, `
            animalStr += `S ${plusMinus(a.stats.S)}, `
            animalStr += `D ${plusMinus(a.stats.D)}, `
            animalStr += `C ${plusMinus(a.stats.C)}, `
            animalStr += `I ${plusMinus(a.stats.I)}, `
            animalStr += `W ${plusMinus(a.stats.W)}, `
            animalStr += `Ch ${plusMinus(a.stats.Ch)}, `
            animalStr += `AL ${a.alignment}, `
            animalStr += `LV ${a.level}\n`
            a.specials?.forEach((sp) => {
                animalStr += ` - ${sp.name}. ${sp.effect}\n`
            })

            animalStr += `\n \n`
        })

        const pageAnimals = pdfDoc.addPage([841.89, 595.28]);
        pageAnimals.moveTo(58, 550);
        let animalFontSize = 10;
        pageAnimals.drawText(animalStr, { size: animalFontSize, maxWidth: 730, lineHeight: animalFontSize * 1.75, font: customFont });

    }

    // magic items page
    if (treasuresCarried.length > 0 || magicItemsCarried.length > 0) {
        const pageMagic = pdfDoc.addPage([841.89, 595.28]);

        if (magicItemsCarried.length > 0) {
            // single column
            let magicStr = "MAGIC ITEMS:\n \n";

            magicItemsCarried.forEach((mi) => {

                let theWeapon: Weapon | undefined = undefined;
                if (mi.itemType === "weapon") {
                    theWeapon = weapons.find((w) => w.id === mi.itemTypeId);
                }

                let theArmor: Armor | undefined = undefined;
                if (mi.itemType === "armor") {
                    theArmor = armors.find((w) => w.id === mi.itemTypeId);
                }

                let nameAndBonus = mi.name;
                if (theWeapon) { nameAndBonus = theWeapon.name + " +" + mi.bonus; }
                if (theArmor) { nameAndBonus = theArmor.name + " +" + mi.bonus; }

                let bonusNote = "";
                if (mi.bonusNote) { bonusNote = mi.bonusNote; }

                magicStr += `${mi.name.toUpperCase()}: ${mi.features}\n`;

                if (nameAndBonus.trim() !== "" && bonusNote.trim() !== "") {
                    magicStr += `  BONUS: ${nameAndBonus}. ${bonusNote}\n`;
                }

                if (mi.benefits.trim() !== "") {
                    magicStr += `  BENEFITS: ${mi.benefits}\n`;
                }

                if (mi.curses.trim() !== "") {
                    magicStr += `  CURSES: ${mi.curses}\n`;
                }

                if (mi.hasPersonality) {
                    let pers = "";
                    pers += `  PERSONALITY: `;
                    if (mi.hasPersonality && mi.personalityVirtue?.trim() !== "") {
                        pers += mi.personalityVirtue + " ";
                    }
                    if (mi.hasPersonality && mi.personalityFlaws?.trim() !== "") {
                        pers += mi.personalityFlaws + " ";
                    }
                    if (mi.hasPersonality && mi.personalityTraits?.trim() !== "") {
                        pers += mi.personalityTraits + " ";
                    }
                    magicStr += pers + `\n`;
                }

                magicStr += `\n \n`;
            })

            // Treasures

            magicStr += "TREASURES:\n \n";

            treasuresCarried.forEach((t) => {
                magicStr += `${t.name}: ${t.desc} (${t.cost}${t.currency})\n`;
                magicStr += `\n`;
            })

            pageMagic.moveTo(58, 550);
            let magicFontSize = 10;
            pageMagic.drawText(magicStr, { size: magicFontSize, maxWidth: 730, lineHeight: magicFontSize * 1.5, font: customFont });



        }

    }


}

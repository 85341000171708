import React from "react";
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import PickTalent from "./PickTalent";
import StatBonusDouble from "./StatBonusDouble";
import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { Spell } from "../../data/spells";
import { Talent } from "../../data/classes";

interface IProps {
    isSpecialTalent: boolean;
    thisLevel: Level;
    specialTalents: Talent[];
    level: Level;
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    isAmbitionTalent: boolean;
    isCheatMode: boolean;
    isMinimal: boolean;
    theClass: string;
    spellsKnown: Spell[];
    setBonus: (bonus: Bonus) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
}

const ChooseTalentOrTwoStatPoints: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const onSetBonus = (bonus: Bonus) => {
        props.setBonus(bonus);
    }

    const allStats = ["STR", "DEX", "CON", "INT", "WIS", "CHA"];

    const isBoon = props.boonSource !== "";

    const getTheSelectedTalent = () => {
        let selectedTalent: Talent = { min: 0, max: 0, desc: '', name: "" };
        let talentName = "";
        let isBoon = false;
        if (props.boonSource !== "") {
            // A Warlock Boon
            const theBoon = globalContext.bonuses.find((b) => b.sourceCategory === props.sourceCategory && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.boonPatron === props.boonPatron && b.boonSource === props.boonSource && b.gainedAtLevel === props.level.level)
            if (theBoon) {
                talentName = theBoon.name;
                isBoon = true;
            }
        } else if (props.isAmbitionTalent) {
            // Human Ambition Talent:
            talentName = globalContext.ambitionTalentLevel.talentRolledName;
        } else {
            // A Standard Talent
            talentName = props.thisLevel.talentRolledName;
        }

        if (talentName === "") { console.log("talent name not found"); }

        if (isBoon) {
            let thePatron = globalContext.availableWarlockPatrons.find((p) => p.name === props.boonPatron);
            if (thePatron !== undefined) {
                const patronId = thePatron.id;
                const patronBoons = globalContext.availablePatronBoons.find((pb) => pb.patronId === patronId);
                if (patronBoons !== undefined) {
                    const theTalent = patronBoons.talents.find((t) => t.name === talentName);
                    if (theTalent) {
                        selectedTalent = theTalent;
                    }
                }
            }
        } else {
            if (globalContext.charClass) {
                let theTalent = globalContext.charClass.talents.find((t) => t.name === talentName);
                if (theTalent) {
                    selectedTalent = theTalent;
                }
            }
        }

        return selectedTalent;
    }

    const selectedTalent = getTheSelectedTalent();

    const getReq = () => {
        let req = false;

        if (props.isAmbitionTalent) {
            // Human Ambition Talent:
            req = globalContext.ambitionTalentLevel.Rolled12TalentOrTwoStatPoints === "";
        } else {
            if (props.boonSource !== "") {
                // A Warlock Boon
                const theBoon = globalContext.bonuses.find((b) => b.sourceCategory === props.sourceCategory && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.boonPatron === props.boonPatron && b.boonSource === props.boonSource && b.gainedAtLevel === props.level.level)
                if (theBoon) {
                    req = theBoon.bonusTo === "";
                }
            } else {
                // A Standard Talent
                req = props.thisLevel.Rolled12TalentOrTwoStatPoints === "";
            }
        }

        return req;
    }

    const getChoice = () => {
        let choice = "";

        if (props.boonSource !== "") {
            // A Warlock Boon
            const theBoon = globalContext.bonuses.find((b) => b.sourceCategory === props.sourceCategory && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.boonPatron === props.boonPatron && b.boonSource === props.boonSource && b.gainedAtLevel === props.level.level)
            if (theBoon) {
                choice = theBoon.bonusTo;
            }
        } else if (props.isAmbitionTalent) {
            // Human Ambition Talent:
            choice = globalContext.ambitionTalentLevel.Rolled12TalentOrTwoStatPoints;
        } else {
            // A Standard Talent
            choice = props.thisLevel.Rolled12TalentOrTwoStatPoints;
        }

        return choice;
    }

    const choice = getChoice();

    let sourceType = props.sourceType;
    let sourceName = props.sourceName;
    let sourceCategory = props.sourceCategory;
    if (props.isAmbitionTalent) {
        sourceType = "Ancestry";
        sourceName = "Human Ambition";
    }

    return (
        <div className="indented">

            {getChoice() === "" &&
                <>
                    <div><b>Talent or 2 Stat points</b></div>
                    <label><input type="radio" onChange={() => props.onSetRolled12TalentOrTwoStatPoints(props.thisLevel.level, "Choose a Talent", props.isAmbitionTalent, isBoon, props.boonSource)} checked={choice === "Choose a Talent"}></input>&nbsp;Choose a Talent</label>
                    <label><input type="radio" onChange={() => props.onSetRolled12TalentOrTwoStatPoints(props.thisLevel.level, "+2 Stat Points", props.isAmbitionTalent, isBoon, props.boonSource)} checked={choice === "+2 Stat Points"}></input>&nbsp;+2 Stat Points</label>
                    {getReq() &&
                        <div className="valError">Please select an option</div>
                    }
                </>
            }

            {choice !== "" &&
                <div>You selected '{choice}':</div>
            }

            {choice === "+2 Stat Points" &&
                <StatBonusDouble
                    sourceType={sourceType}
                    sourceName={sourceName}
                    sourceCategory={sourceCategory}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                    level={props.level}
                    options={allStats}
                    size={1}
                    setBonus={(thisBonus: Bonus) => onSetBonus(thisBonus)}
                    mustbeDifferentStats={false}
                />
            }

            {choice === "Choose a Talent" &&
                <PickTalent
                    isSpecialTalent={props.isSpecialTalent}
                    thisLevel={props.thisLevel}
                    specialTalents={props.specialTalents}
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                    isAmbitionTalent={props.isAmbitionTalent}
                    isCheatMode={props.isCheatMode}
                    isMinimal={props.isMinimal}
                    talent={selectedTalent}
                    theClass={props.theClass}
                    setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                    onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                    onSetRolledTalent={props.onSetRolledTalent}
                    onSetSpecialTalent={props.onSetSpecialTalent}
                    spellsKnown={props.spellsKnown}
                />
            }

        </div>
    );

}

export default ChooseTalentOrTwoStatPoints;

import { useState } from 'react';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import DisplayGold from '../charGen/displayGold';


interface IProps {
    openModal: boolean;
    gold: number;
    silver: number;
    copper: number;
    onOpenModal: (modal: string) => void;
    onCloseModal: (modal: string) => void;
    onSetAdditionalCoins: (gold: number, silver: number, copper: number, notes: string) => void;
}

const GainLoseCoins: React.FunctionComponent<IProps> = (props: IProps) => {

    const [strAdditionalGold, setStrAdditionalGold] = useState("");
    const [strAdditionalSilver, setStrAdditionalSilver] = useState("");
    const [strAdditionalCopper, setStrAdditionalCopper] = useState("");
    const [notes, setNotes] = useState("");

    const [goldErrMsg, setGoldErrMsg] = useState("");
    const [silverErrMsg, setSilverErrMsg] = useState("");
    const [copperErrMsg, setCopperErrMsg] = useState("");

    const setAdditionalGold = (gold: string) => {
        if (parseInt(gold, 10)) {
            const addGold = Math.max(parseInt(gold, 10), 0);
            setStrAdditionalGold(addGold.toString());
        } else {
            setStrAdditionalGold("");
        }
        setGoldErrMsg("");
    }

    const setAdditionalSilver = (silver: string) => {
        if (parseInt(silver, 10)) {
            const addSilver = Math.max(parseInt(silver, 10), 0);
            setStrAdditionalSilver(addSilver.toString());
        } else {
            setStrAdditionalSilver("");
        }
        setSilverErrMsg("");
    }

    const setAdditionalCopper = (copper: string) => {
        if (parseInt(copper, 10)) {
            const addCopper = Math.max(parseInt(copper, 10), 0);
            setStrAdditionalCopper(addCopper.toString());
        } else {
            setStrAdditionalCopper("");
        }
        setCopperErrMsg("");
    }

    const adjustCoins = (e: any, isAdd: boolean) => {
        e.preventDefault();

        let gold = parseInt(strAdditionalGold, 10);
        let silver = parseInt(strAdditionalSilver, 10);
        let copper = parseInt(strAdditionalCopper, 10);

        if (isNaN(gold)) { gold = 0; }
        if (isNaN(silver)) { silver = 0; }
        if (isNaN(copper)) { copper = 0; }

        let goldError = false;
        let silverError = false;
        let copperError = false;

        if (!isAdd) {

            if (gold > props.gold) {
                goldError = true;
                setGoldErrMsg("Cannot lose more gold coins than you have (" + props.gold + "gp)");
            }
            if (silver > props.silver) {
                silverError = true;
                setSilverErrMsg("Cannot lose more silver coins than you have (" + props.silver + "sp)");
            }
            if (copper > props.copper) {
                copperError = true;
                setCopperErrMsg("Cannot lose more copper coins than you have (" + props.copper + "cp)");
            }

            gold = gold * -1;
            silver = silver * -1;
            copper = copper * -1;
        } else {
            setGoldErrMsg("");
            setSilverErrMsg("");
            setCopperErrMsg("");
        }

        if (!goldError && !silverError && !copperError) {
            setAdditionalGold("");
            setAdditionalSilver("");
            setAdditionalCopper("");
            setNotes("");
            setGoldErrMsg("");
            setSilverErrMsg("");
            setCopperErrMsg("");

            props.onSetAdditionalCoins(gold, silver, copper, notes.trim());
        }
    }

    return (
        <>
            <button className="btn btn-dark btn-sm me-1" onClick={() => props.onOpenModal("gainLoseCoins")}>Coins</button>

            {props.openModal &&
                <Modal open={props.openModal} onClose={() => props.onCloseModal("gainLoseCoins")} classNames={{ modal: 'customModal' }}>

                    <div>

                        <div><b>Gain/Lose Coins:</b></div>

                        <DisplayGold gold={props.gold} silver={props.silver} copper={props.copper} />

                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="number" className="form-control coin" placeholder="0" aria-label="gp" value={strAdditionalGold} onChange={(e) => setAdditionalGold(e.target.value)} />
                                    <span className="input-group-text smallCoinNote p-1 m-0" id="basic-addon2">gp</span>
                                </div>
                            </div>
                            {goldErrMsg !== "" &&
                                <div className="red">{goldErrMsg}</div>
                            }
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="number" className="form-control coin" placeholder="0" aria-label="sp" value={strAdditionalSilver} onChange={(e) => setAdditionalSilver(e.target.value)} />
                                    <span className="input-group-text smallCoinNote p-1 m-0" id="basic-addon2">sp</span>
                                </div>
                            </div>
                            {silverErrMsg !== "" &&
                                <div className="red">{silverErrMsg}</div>
                            }
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="number" className="form-control coin" placeholder="0" aria-label="cp" value={strAdditionalCopper} onChange={(e) => setAdditionalCopper(e.target.value)} />
                                    <span className="input-group-text smallCoinNote p-1 m-0" id="basic-addon2">cp</span>
                                </div>
                            </div>
                            {copperErrMsg !== "" &&
                                <div className="red">{copperErrMsg}</div>
                            }
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Notes</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows={2} value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-dark btn-sm me-2" onClick={(e) => adjustCoins(e, true)}>Gain</button>
                                <button className="btn btn-dark btn-sm" onClick={(e) => adjustCoins(e, false)}>Lose</button>
                            </div>
                        </form>

                    </div>
                </Modal>
            }
        </>
    )

}

export default GainLoseCoins;


import fontkit from '@pdf-lib/fontkit';
import { Ancestry } from './../data/ancestries';
import { Armor } from '../data/armor';
import { Bonus } from '../data/bonus';
import { CharClass } from './../data/classes';
import { Cheat } from '../data/cheats';
import { GearOwned } from './../data/gear';
import { MagicItem } from '../data/magicItem';
import { PDFDocument, rgb } from 'pdf-lib'
import { Spell } from '../data/spells';
import { Treasure } from '../data/treasure';
import { Weapon } from '../data/weapons';
import { addNone, getGearRecordsAsSlots } from '../data/utilities';
import { modStr, Stats } from '../data/stats';
import { getBonusesDesc } from './bonusText';
import { addExtraPages } from './extraPages';
import { Creature } from '../data/animals';

export const getPDF = async (name: string, ancestry: Ancestry | undefined, stats: Stats, xp: number, level: number, title: string, alignment: string, background: string, deity: string, languages: string, spells: Spell[], charClass: CharClass | undefined, bonuses: Bonus[], hitPoints: number, ac: number, gearCarried: GearOwned[], treasuresCarried: Treasure[], magicItemsCarried: MagicItem[], gold: number, silver: number, copper: number, gearSlots: number, attacks: string[], highLegibility: boolean, weapons: Weapon[], armors: Armor[], animalCompanions: Creature[], spellCastingBonus: number, demonicPossessionBonus: number, edits: Cheat[], weaponsPermitted: Weapon[], armorPermitted: Armor[]) => {

    // const pdfDoc = await PDFDocument.create();

    // const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
    // const url = 'http://damp-territory.surge.sh/Solitreo-Regular.ttf';
    // const fontBytes = await fetch(url, { mode: 'no-cors' }).then(res => res.arrayBuffer())

    // pdfDoc.registerFontkit(fontkit)
    // const customFont = await pdfDoc.embedFont(fontBytes)

    const pdfDoc = await PDFDocument.create()
    const page = pdfDoc.addPage([841.89, 595.28]);

    // const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
    // const url = 'http://damp-territory.surge.sh/Solitreo-Regular.ttf';
    // const url = 'http://damp-territory.surge.sh/EduNSWACTFoundation-Regular.ttf';
    // const url = 'http://damp-territory.surge.sh/IndieFlower-Regular.ttf';
    // const url = 'http://damp-territory.surge.sh/GloriaHallelujah-Regular.ttf';

    let url = "https://www.shadowdarklings.net/PermanentMarker-Regular.ttf";
    if (highLegibility) {
        url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf';
    }

    const fontBytes = await fetch(url).then(res => res.arrayBuffer())

    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)

    // const url2 = 'http://damp-territory.surge.sh/GloriaHallelujah-Regular.ttf';
    // const fontBytes2 = await fetch(url2).then(res => res.arrayBuffer());
    // const customFont2 = await pdfDoc.embedFont(fontBytes2)

    // // test font

    // const text = `This is text in an embedded font!\nMore stuff`;
    // const textSize = 35;
    // const textWidth = customFont.widthOfTextAtSize(text, textSize);
    // const textHeight = customFont.heightAtSize(textSize);


    // const font4 = await pdfDoc.embedFont(fs.readFileSync('../fonts/Solitreo-Regular.ttf'))



    // // end font

    let nameTextSize = 18;
    if (name.length >= 15) { nameTextSize = 14; }
    if (name.length >= 20) { nameTextSize = 12; }

    const statTextSize = 18;
    const levelTextSize = 20;
    const talentsTextSize = 11;
    const hpSize = 25;

    const jpgUrl = 'https://i.imgur.com/oD5M7IA.jpg';
    const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer());
    const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
    const jpgDims = jpgImage.scale(0.625);
    page.drawImage(jpgImage, {
        x: page.getWidth() / 2 - jpgDims.width / 2,
        y: page.getHeight() / 2 - jpgDims.height / 2,
        width: jpgDims.width,
        height: jpgDims.height,
    })

    // Link

    page.moveTo(58, 490);
    page.drawText("www.shadowdarklings.net", { size: 16, font: customFont });

    // Name

    page.moveTo(300, 508);
    page.drawText(name, { size: nameTextSize, font: customFont });

    // Ancestry

    page.moveTo(260, 438);
    if (ancestry) {
        page.drawText(ancestry.name, { size: 16, font: customFont });
    }

    // Class

    page.moveTo(260, 378);

    let className = "";
    let classWeapons = "Class not selected";
    let classArmor = "Class not selected";

    if (charClass) {
        className = charClass.name;
        classWeapons = weaponsPermitted.map((w) => w.name).join(", ");
        if (charClass.weapons === "All weapons") { classWeapons = "All weapons" };
        classArmor = armorPermitted.map((a) => a.name).join(", ");
        if (charClass.armor === "All armor and shields") { classArmor = "All armor and shields" };
    }
    classWeapons = addNone(classWeapons);
    classArmor = addNone(classArmor);

    page.drawText(className, { size: 16, font: customFont });

    // Stats

    page.moveTo(58, 441);
    page.drawText(stats.Strength.toString(), { size: statTextSize, font: customFont });

    page.moveTo(100, 441);
    page.drawText(modStr(stats.Strength).toString(), { size: statTextSize, font: customFont });

    page.moveTo(58, 379);
    page.drawText(stats.Dexterity.toString(), { size: statTextSize, font: customFont });

    page.moveTo(100, 379);
    page.drawText(modStr(stats.Dexterity).toString(), { size: statTextSize, font: customFont });

    page.moveTo(58, 317);
    page.drawText(stats.Constitution.toString(), { size: statTextSize, font: customFont });

    page.moveTo(100, 317);
    page.drawText(modStr(stats.Constitution).toString(), { size: statTextSize, font: customFont });


    page.moveTo(160, 441);
    page.drawText(stats.Intelligence.toString(), { size: statTextSize, font: customFont });

    page.moveTo(204, 441);
    page.drawText(modStr(stats.Intelligence).toString(), { size: statTextSize, font: customFont });

    page.moveTo(160, 379);
    page.drawText(stats.Wisdom.toString(), { size: statTextSize, font: customFont });

    page.moveTo(204, 379);
    page.drawText(modStr(stats.Wisdom).toString(), { size: statTextSize, font: customFont });

    page.moveTo(160, 317);
    page.drawText(stats.Charisma.toString(), { size: statTextSize, font: customFont });

    page.moveTo(204, 317);
    page.drawText(modStr(stats.Charisma).toString(), { size: statTextSize, font: customFont });

    // Level

    if (charClass?.name !== "Level 0") {
        // Level
        page.moveTo(278, 314);
        page.drawText(level.toString(), { size: levelTextSize, font: customFont });
        // current XP
        page.moveTo(353, 316);
        page.drawText(xp.toString(), { size: levelTextSize, font: customFont });
        // XP to gain level
        page.moveTo(413, 316);
        page.drawText((level * 10).toString(), { size: levelTextSize, font: customFont });
    }

    if (charClass?.name === "Level 0") {
        // Level
        page.moveTo(278, 314);
        page.drawText("0", { size: levelTextSize, font: customFont });
        // XP
        page.moveTo(413, 316);
        page.drawText("n/a", { size: levelTextSize, font: customFont });
    }


    // Title

    page.moveTo(260, 250);
    page.drawText(title, { size: 16, font: customFont });

    // Alignment

    page.moveTo(260, 183);
    page.drawText(alignment, { size: 16, font: customFont });

    // Background

    page.moveTo(260, 118);
    page.drawText(background, { size: 16, font: customFont });

    // Deity

    page.moveTo(260, 53);
    page.drawText(deity, { size: 16, font: customFont });

    // Talents/Spells/Languages

    const formatSpellsKnownAsList = () => {
        if (spells.length === 0) { return "None"; }

        let out: string[] = [];
        spells.forEach((sp) => {
            let desc = sp.name;

            const castWithAdv = sp.castWithAdv;

            let castWithMastery = false;
            const spellMastery = bonuses.find((b) => b.name === "AdvOnCastOneSpell" && b.bonusName === sp.name);
            if (spellMastery) { castWithMastery = true; }

            if (castWithAdv || castWithMastery) {
                desc = desc + " (ADV)";
            }

            let learnedFromScroll = false;
            const learnScroll = bonuses.find((b) => b.bonusTo === "LearnSpellFromScroll" && b.bonusName === sp.name);
            if (learnScroll) { learnedFromScroll = true; }

            if (learnedFromScroll) {
                desc = desc + " (scroll)";
            }

            out.push(desc);
        })
        return out.join(", ");
    }

    let spellsText = formatSpellsKnownAsList();
    const spellOrChant = charClass && charClass.name === "Shaman" ? "CHANTS" : "SPELLS";
    if (spellsText.trim() !== "None") { spellsText = spellOrChant + ": " + spellsText; }


    let talents: string[] = [];

    talents.push(`WEAPONS: ${classWeapons}; ARMOR: ${classArmor}`);
    // talents.push(`ARMOR: ${armor}`);

    talents.push(`LANGUAGES: ${languages}`);

    talents = [...talents, ...getBonusesDesc(ancestry, charClass, demonicPossessionBonus, spellCastingBonus, bonuses, level)];
    if (spellsText !== "None") {
        talents.push(`${spellsText}`);
    }

    //let talentsText = `WEAPONS: ${weapons}\nARMOR: ${armor}\n \n${getBonusesDesc()}\n \n${spellsText}\nLANGUAGES: ${languages}`;

    let talFontSize = Math.min(70 / talents.length, 7.5);
    page.moveTo(485, 508);
    page.drawText(talents.join(`\n \n`), { size: talFontSize, maxWidth: 300, lineHeight: talFontSize, font: customFont });
    // page.drawText(`<b>Languages:</b> ` + languages , { size: talentsTextSize, maxWidth: 300, lineHeight: talentsTextSize * 1.25 });


    // Hit points
    page.moveTo(80, 227);
    page.drawText(hitPoints.toString(), { size: hpSize, font: customFont });

    // AC
    page.moveTo(175, 227);
    page.drawText(ac.toString(), { size: hpSize, font: customFont });

    // Desert Rider AC note
    const acBonus = Math.trunc(level / 2);
    if (charClass?.name === "Desert Rider" && acBonus > 0) {
        page.moveTo(152, 212);
        page.drawText("+" + Math.trunc(level / 2) + " AC when mounted", { size: 8, font: customFont });
    }

    // Gear
    let gearRecords = getGearRecordsAsSlots(armors, gearCarried, treasuresCarried, magicItemsCarried, gold, silver, copper);

    gearRecords = gearRecords.sort((g1, g2) => {
        if (g1.type === "armor" && g2.type === "weapon") {
            return -1;
        }
        if (g1.type === "armor" && g2.type === "sundry") {
            return -1;
        }
        if (g1.type === "armor" && g2.type === "armor") {
            return g1.name < g2.name ? -1 : 1;
        }

        if (g1.type === "weapon" && g2.type === "sundry") {
            return -1;
        }
        if (g1.type === "weapon" && g2.type === "weapon") {
            return g1.name < g2.name ? -1 : 1;
        }

        if (g1.type === "sundry" && g2.type === "sundry") {
            return g1.name < g2.name ? -1 : 1;
        }

        return 0;
    });


    let x = 497;
    let y = 239;

    let rowCount = 0;

    const zeroSlotItems: string[] = [];

    for (let g = 0; g < gearRecords.length; g = g + 1) {

        const thisRecord = gearRecords[g];

        if (thisRecord.slots !== 0) {

            let slotNum = 0;
            for (let s = 0; s < thisRecord.slots; s++) {

                slotNum = slotNum + 1;
                const dispSlotNum = thisRecord.slots > 1 ? slotNum : 0;

                page.moveTo(x, y);
                if (rowCount < 20) {
                    let openPara = "";
                    let closePara = "";
                    if (dispSlotNum > 1) {
                        openPara = "(";
                        closePara = ")";
                    }

                    const numItems = thisRecord.totalUnits > 1 ? " (" + thisRecord.totalUnits + ")" : "";

                    let gearText = openPara + thisRecord.name + numItems + closePara;
                    if (gearText.length > 20) { gearText = gearText.substring(0, 20) + "..."; }

                    page.drawText(gearText, { size: 9, font: customFont });
                }
                rowCount = rowCount + 1;

                y = y - 19;
                if (rowCount === 10) {
                    x = x + 115;
                    y = y + (10 * 19);
                }
            }
        } else {
            zeroSlotItems.push(thisRecord.name);
        }

    }

    // Free to carry items in sidebar

    let freeToCarry: string[] = [];

    zeroSlotItems.forEach((f) => {
        freeToCarry.push(f);
    })

    if (charClass?.freeToCarryItems) {
        charClass?.freeToCarryItems.forEach((i) => {
            freeToCarry.push(i);
        })
    }

    // free to carry gold/silver/copper
    const totalCoins = gold + silver + copper;
    if (totalCoins > 0) {
        freeToCarry.push("Bag of coins (" + Math.min(totalCoins, 100) + ")");
    }

    y = 220;
    if (freeToCarry.length > 0) {
        for (let r = 0; r < freeToCarry.length; r++) {
            page.moveTo(713, y);
            page.drawText(freeToCarry[r], { size: 8, font: customFont });
            y = y - 15;
        }
    }

    // Strike out unavailable gear slots
    x = 497;
    y = 239;
    let slotNum = 0;
    rowCount = 0;
    for (let g = 0; g < 20; g = g + 1) {

        slotNum = slotNum + 1;

        page.moveTo(x, y);
        if (rowCount >= gearSlots) {
            page.drawLine({
                start: { x: x + 10, y: y + 10 },
                end: { x: x + 75, y: y + 0 },
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 1,
            })
        }
        rowCount = rowCount + 1;

        y = y - 19;
        if (rowCount === 10) {
            x = x + 115;
            y = y + (10 * 19);
        }

    }

    // Money
    if (gold > 0) {
        page.moveTo(620, 268);
        page.drawText(gold.toString(), { size: talentsTextSize, font: customFont });
    }

    if (silver > 0) {
        page.moveTo(673, 268);
        page.drawText(silver.toString(), { size: talentsTextSize, font: customFont });
    }

    if (copper > 0) {
        page.moveTo(730, 268);
        page.drawText(copper.toString(), { size: talentsTextSize, font: customFont });
    }

    // Attacks

    const formatAttacks = () => {
        const out: string[] = [];

        attacks.forEach((a) => {
            out.push(a)
        })

        return out.join(`\n \n`);
    }

    let atkFontSize = Math.min(50 / attacks.length, 8);
    page.moveTo(50, 140);
    page.drawText(formatAttacks(), { size: atkFontSize, maxWidth: 180, lineHeight: atkFontSize, font: customFont });

    if (edits.length > 0) {
        page.moveTo(40, 30);
        page.drawText("Edits: " + edits.map((c) => c.desc).join("; "), { size: 9, maxWidth: 700, lineHeight: 9, font: customFont });
    }

    await addExtraPages(
        pdfDoc,
        customFont,
        charClass,
        className,
        bonuses,
        spellsText,
        spells,
        treasuresCarried,
        magicItemsCarried,
        weapons,
        armors,
        animalCompanions
    )

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}
export interface Spell {
    name: string;
    tierByClass: number[];
    classes: string[];
    duration: string;
    range: string;
    desc: string;
    castWithAdv: boolean;
    popularityWithClass: number[];
}

let theSpells: Spell[] = [
    {
        name: "Acid Arrow",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Far",
        desc: "You conjure a corrosive bolt that hits one foe, dealing 1d6 damage a round. The bolt remains in the target for as long as you focus.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Alarm",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "1 day",
        range: "Close",
        desc: "You touch one object, such as a door threshold, setting a magical alarm on it. If any creature you do not designate while casting the spell touches or crosses past the object, a magical bell sounds in your head.",
        castWithAdv: false,
        popularityWithClass: [3]
    },
    {
        name: "Alter Self",
        tierByClass: [2, 2],
        classes: ["Wizard", "Witch"],
        duration: "5 rounds",
        range: "Self",
        desc: "You magically change your physical form, gaining one feature that modifies your existing anatomy.\nFor example, you can grow functional gills on your neck or bear claws on your fingers. This spell can't grow wings or limbs.",
        castWithAdv: false,
        popularityWithClass: [50, 1]
    },
    {
        name: "Anathema",
        tierByClass: [5],
        classes: ["Witch"],
        duration: "Instant",
        range: "Close",
        desc: "All allies revile and abandon the creature you touch for 1 day.\nEach time you or your allies harm the target, its former allies may pass a DC 15 Wisdom check to end the effects of the spell.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Animate Dead",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "1 day",
        range: "Close",
        desc: "You touch one humanoid's remains, and it rises as a zombie or skeleton under your control. The remains must have at least three limbs and its head intact.\nThe undead creature acts on your turn. After 1 day, the creature collapses into grave dust.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Antimagic Shell",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Self",
        desc: "An invisible, near-sized cube of null-magic appears centered on you.\nWithin the cube, no spells can be cast. Magic items and spells have no effect in the zone, and no magic can enter.\nThe cube moves with you. Spells such as dispel magic have no effect on it.\nAnother antimagic shell does not affect this one.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Arcane Eye",
        tierByClass: [4],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Near",
        desc: "You conjure an invisible, grape-sized eye within range.\nYou can see through the eye. It can see in the dark out to near range, fly near on your turn, and squeeze through openings as narrow as a keyhole.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Augury",
        tierByClass: [2, 2],
        classes: ["Priest", "Witch"],
        duration: "Instant",
        range: "Self",
        desc: "You interpret the meaning of supernatural portents and omens.\nAsk the GM one question about a specific course of action. The GM says whether the action will lead to 'weal' or 'woe.'",
        castWithAdv: false,
        popularityWithClass: [50, 1]
    },
    {
        name: "Beguile",
        tierByClass: [4],
        classes: ["Witch"],
        duration: "Focus",
        range: "Near",
        desc: "You conjure a convincing visible and audible illusion within range.\n Creatures who perceive the illusion react to it as though it were real, although it can't cause actual harm.\n Touching the illusion instantly reveals its false nature.\n You may force a creature who interacts with the illusion to make a DC 15 Wisdom check. If the creature fails, it is enchanted by the illusion for the spell's duration and seeks to protect it.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Bless",
        tierByClass: [2],
        classes: ["Priest"],
        duration: "Instant",
        range: "Close",
        desc: "One creature you touch gains a luck token.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Bogboil",
        tierByClass: [2],
        classes: ["Witch"],
        duration: "5 rounds",
        range: "Far",
        desc: "You turn a near-sized cube of ground within range into a muddy, boiling bog of quicksand.\n A creature stuck in the bog can't move and must succeed on a Dexterity check vs. your spellcasting check to free itself.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Broomstick",
        tierByClass: [3],
        classes: ["Witch"],
        duration: "Focus",
        range: "Self",
        desc: "You conjure a flying broomstick in your hand. The broomstick's rider can fly a near distance each round and can hover in place.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Blind/Deafen",
        tierByClass: [2],
        classes: ["Priest"],
        duration: "Focus",
        range: "Near",
        desc: "You utter a divine censure, blinding or deafening one creature you can see in range. The creature has disadvantage on tasks requiring the lost sense.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Burning Hands",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Close",
        desc: "You spread your fingers with thumbs touching, unleashing a circle of flame that fills a close area around where you stand. Creatures within the area of effect take 1d6 damage. Unattended flammable objects ignite.",
        castWithAdv: false,
        popularityWithClass: [4]
    },
    {
        name: "Cacklerot",
        tierByClass: [2],
        classes: ["Witch"],
        duration: "Focus",
        range: "Close",
        desc: "One target you touch of LV 4 or less collapses helplessly with disturbing, pained laughter for the spell's duration.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Cat's Eye",
        tierByClass: [2],
        classes: ["Witch"],
        duration: "Focus",
        range: "Self",
        desc: "Your irises grow to fill your eyes and your pupils turn into black, vertical slits.\n You can see invisible creatures and secret doors for the spell's duration.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Cauldron",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "1 round",
        range: "Close",
        desc: "You conjure a bubbling cauldron next to you. It can produce one of the following effects:\n• Any broken mundane item placed inside the cauldron is repaired.\n• A fat, croaking toad leaps out and follows your instructions for the next 3 rounds.\n• You can place up to 3 item slots of items inside the cauldron. The cauldron expels these items the next time you cast this spell (expelling items counts as the cauldron's single effect).",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Charm Person",
        tierByClass: [1, 1],
        classes: ["Wizard", "Witch"],
        duration: "1d8 days",
        range: "Near",
        desc: "You magically beguile one humanoid of level 2 or less within near range, who regards you as a friend for the duration.\nThe spell ends if you or your allies do anything to hurt it that it notices.\nThe target knows you magically enchanted it after the spell ends.",
        castWithAdv: false,
        popularityWithClass: [8, 1]
    },
    {
        name: "Cleansing Weapon",
        tierByClass: [2],
        classes: ["Priest"],
        duration: "5 rounds",
        range: "Close",
        desc: "One weapon you touch is wreathed in purifying flames. It deals an additional 1d4 damage (1d6 vs. undead) for the duration.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Cloak of Night",
        tierByClass: [4],
        classes: ["Witch"],
        duration: "8 rounds",
        range: "Self",
        desc: "Your wrap yourself in a swirling cloak of shadows. For the spell's duration, your armor class becomes 17 (20 on a critical spellcasting check).\n You have advantage on Dexterity checks to sneak and hide for the spell's duration.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Cloudkill",
        tierByClass: [4],
        classes: ["Wizard"],
        duration: "5 rounds",
        range: "Far",
        desc: "A putrid cloud of yellow poison fills a near-sized cube within range. It spreads around corners.\nCreatures inside the cloud are blinded and take 2d6 damage at the beginning of their turns.\nA creature of level 9 or less that ends its turn fully inside the cloud dies.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Command",
        tierByClass: [3],
        classes: ["Priest"],
        duration: "Focus",
        range: "Far",
        desc: "You issue a verbal command to one creature in range who can understand you. The command must be one word, such as 'kneel.' The target obeys the command for as long as you focus.\nIf your command is ever directly harmful to the creature, it may make a Charisma check vs. your last spellcasting check. On a success, the spell ends.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Commune",
        tierByClass: [4],
        classes: ["Priest"],
        duration: "Instant",
        range: "Self",
        desc: "You seek your god's counsel. Ask the GM up to three yes or no questions. The GM truthfully answers 'yes' or 'no' to each.\nIf you cast this spell more than once in 24 hours, treat a failed spellcasting check for it as a critical failure instead.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Confusion",
        tierByClass: [4],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Near",
        desc: "You mesmerize one creature you can see in range. The target can't take actions, and it moves in a random direction on its turn. If the target is LV 9+, it may make a WIS check vs. your last spellcasting check at the start of its turn to end the spell.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Control Water",
        tierByClass: [4, 4],
        classes: ["Priest", "Wizard"],
        duration: "Focus",
        range: "Far",
        desc: "You move and shape water. You can cause a section of water up to 100 feet in width and depth to change shape, defy gravity, or flow in a different direction.",
        castWithAdv: false,
        popularityWithClass: [50, 50]
    },
    {
        name: "Coven",
        tierByClass: [3],
        classes: ["Witch"],
        duration: "Instant",
        range: "Self",
        desc: "You call upon the magic you share with your fellow witches.\n You regain the use of one tier 3 spell or lower that you can no longer cast for the day.\n After successfully casting this spell, you can't do so again until you complete a rest.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Create Undead",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "1 day",
        range: "Close",
        desc: "You conjure a vengeful undead creature to do your bidding.\nWhen you cast this spell, you choose to summon either a wight or wraith. It appears next to you and is under your control.\nThe undead creature acts on your turn. After 1 day, it melts away into smoke.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Cure Wounds",
        tierByClass: [1],
        classes: ["Priest"],
        duration: "Instant",
        range: "Close",
        desc: "Your touch restores ebbing life.\nRoll a number of d6s equal to 1 + half your level (rounded down). One target you touch regains that many hit points.",
        castWithAdv: false,
        popularityWithClass: [46]
    },
    {
        name: "Curse",
        tierByClass: [4],
        classes: ["Witch"],
        duration: "Permanent",
        range: "Close",
        desc: "A creature you touch is afflicted by one of the following curses: • Hideous boils and warts • All food tastes of ash • Voice becomes shrill • Disturbing nightmares • Always lose at gambling • An ally turns into an enemy • Fear of something ordinary",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Detect Magic",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Near",
        desc: "You can sense the presence of magic within near range for the spell's duration. If you focus for two rounds, you discern its general properties. Full barriers block this spell.",
        castWithAdv: false,
        popularityWithClass: [8]
    },
    {
        name: "Detect Thoughts",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Near",
        desc: "You peer into the mind of one creature you can see within range. Each round, you learn the target's immediate thoughts.\nOn its turn, the target makes a Wisdom check vs. your last spellcasting check. If the target succeeds, it notices your presence in its mind and the spell ends.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Dimension Door",
        tierByClass: [4, 4],
        classes: ["Wizard", "Witch"],
        duration: "Instant",
        range: "Self",
        desc: "You teleport yourself and up to one other willing creature to any point you can see.",
        castWithAdv: false,
        popularityWithClass: [50, 1]
    },
    {
        name: "Disintegrate",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Far",
        desc: "A green ray shoots from your finger and turns a creature or object into ash.\nA target creature of LV 5 or less instantly dies. If it is LV 6+, it takes 3d8 damage instead.\nA non-magical object up to the size of a large tree is destroyed.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Dispel Magic",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Near",
        desc: "End one spell that affects one target you can see in range.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Divination",
        tierByClass: [4, 3],
        classes: ["Wizard", "Witch"],
        duration: "Instant",
        range: "Self",
        desc: "You throw the divining bones or peer into the blackness between the stars, seeking a portent.\nYou can ask the GM one yes or no question. The GM truthfully answers 'yes' or 'no'.\nIf you cast this spell more than once in 24 hours, treat a failed spellcasting check for it as a critical failure instead.",
        castWithAdv: false,
        popularityWithClass: [50, 1]
    },
    {
        name: "Divine Vengeance",
        tierByClass: [5],
        classes: ["Priest"],
        duration: "10 rounds",
        range: "Self",
        desc: "You become the divine avatar of your god's wrath, wreathed in holy flames or a black aura of smoldering corruption.\nFor the spell's duration, you can fly a near distance, your weapons are magical, and you have a +4 bonus to your weapon attacks and damage.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Dominion",
        tierByClass: [5],
        classes: ["Priest"],
        duration: "10 rounds",
        range: "Near",
        desc: "Mighty beings come to your aid.\nThe beings must have a combined total of 16 levels or less. Chaotic PCs summon demons/devils, and lawful or neutral PCs summon angels.\nThe beings act of free will to aid you on your turn. After 10 rounds, they return to their realms. You cannot cast this spell again until you complete penance.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Dreamwalk",
        tierByClass: [5],
        classes: ["Witch"],
        duration: "Instant",
        range: "Close",
        desc: "You and any willing creatures you choose within close range step into the dream of a sleeping creature you name that is on your same plane.\n You and anyone traveling with you can step out of the creature, appearing next to it as if having teleported there.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Enfeeble",
        tierByClass: [5],
        classes: ["Witch"],
        duration: "Instant",
        range: "Close",
        desc: "A creature you touch has a random stat reduced to 3 (-4) for one week. Roll a d6 to determine which stat:\n 1. Strength, 2. Dexterity, 3. Constitution, 4. Intelligence, 5. Wisdom, 6. Charisma.\n If you fail the spellcasting check, you have a random stat reduced to 3 for a week instead.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Eyebite",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "Instant",
        range: "Near",
        desc: "One creature you target takes 1d4 damage, and it can't see you until the end of its next turn.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Fabricate",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "10 rounds",
        range: "Near",
        desc: "This spell can't target creatures.\nYou turn a tree-sized collection of raw materials into a finished work. For example, you convert a pile of bricks or rocks into a bridge. The finished work converts back to raw materials when the spell ends.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Feather Fall",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Self",
        desc: "You may make an attempt to cast this spell when you fall.\nYour rate of descent slows so that you land safely on your feet.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Fireball",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Far",
        desc: "You hurl a small flame that erupts into a fiery blast. All creatures in a near-sized cube around where the flame lands take 4d6 damage.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Finger of Death",
        tierByClass: [5],
        classes: ["Witch"],
        duration: "Instant",
        range: "Close",
        desc: "One creature you touch of LV 9 or less dies.\n Treat a failed spellcasting check for this spell as a critical failure, and roll the mishap with disadvantage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Fixed Object",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "5 rounds",
        range: "Close",
        desc: "An object you touch that weighs no more than 5 pounds becomes fixed in its current location. It can support up to 5,000 pounds of weight for the duration of the spell.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Flame Strike",
        tierByClass: [4],
        classes: ["Priest"],
        duration: "Instant",
        range: "Far",
        desc: "You call down a holy pillar of fire, immolating one creature you can see within range. The target takes 2d6 damage.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Floating Disk",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "10 rounds",
        range: "Near",
        desc: "You create a floating, circular disk of force with a concave center. It can carry up to 20 gear slots. It hovers at waist level and automatically stays within near of you. It can't cross over dropoffs or pits taller than a human.",
        castWithAdv: false,
        popularityWithClass: [3]
    },
    {
        name: "Fly",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "5 rounds",
        range: "Self",
        desc: "Your feet lift from the ground, and you take to the air like a hummingbird. You can fly near for the spell's duration and are able to hover in place.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Fog",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "Focus",
        range: "Close",
        desc: "A thick cloud of fog blooms in a close area around you, making you hard to see. The cloud moves with you. Attacks against creatures in the cloud have disadvantage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Frog Rain",
        tierByClass: [2],
        classes: ["Witch"],
        duration: "Instant",
        range: "Far",
        desc: "A rain of indignant frogs pelts a near-sized cube around a point you can see within range.\n All creatures within the frog rain take 1d6 damage. Any surviving frogs hop away and disappear.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Gaseous Form",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "10 rounds",
        range: "Self",
        desc: "You and your gear turn into a cloud of smoke for the spell's duration.\nYou can fly and pass through any gap that smoke could. You can sense the terrain and any movement around you out to a near distance.\nYou can't cast spells while in this form.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Glassbones",
        tierByClass: [4],
        classes: ["Witch"],
        duration: "Focus",
        range: "Close",
        desc: "A creature you touch becomes fragile. It takes double damage for the spell's duration.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Heal",
        tierByClass: [5],
        classes: ["Priest"],
        duration: "Instant",
        range: "Close",
        desc: "One creature you touch is healed to full hit points.\nYou cannot cast this spell again until you complete a rest.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Hold Monster",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Near",
        desc: "You paralyze one creature you can see within range. If the target is LV 9+, it may make a STR check vs. your last spellcasting check at the start of its turn to end the spell.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Hold Person",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Near",
        desc: "You magically paralyze one humanoid creature of LV 4 or less you can see within range.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Hold Portal",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "10 rounds",
        range: "Near",
        desc: "You magically hold a portal closed for the duration. A creature must make a successful STR check vs. your spellcasting check to open the portal.\nThe knock spell ends this spell.",
        castWithAdv: false,
        popularityWithClass: [3]
    },
    {
        name: "Holy Weapon",
        tierByClass: [1],
        classes: ["Priest"],
        duration: "5 rounds",
        range: "Close",
        desc: "One weapon you touch is imbued with a sacred blessing. The weapon becomes magical and has +1 to attack and damage rolls for the duration.",
        castWithAdv: false,
        popularityWithClass: [13]
    },
    {
        name: "Howl",
        tierByClass: [3],
        classes: ["Witch"],
        duration: "Instant",
        range: "Near",
        desc: "All enemies within near range of you must immediately make a morale check. This spell does not affect creatures that are immune to morale checks.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Hypnotize",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "Focus",
        range: "Near",
        desc: "One creature of LV 3 or less that can see you is rendered stupefied. Breaking the creature's line of sight to you allows it to make a DC 15 Charisma check. On a success, the spell ends.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Illusion",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Far",
        desc: "You create a convincing visible and audible illusion that fills up to a near-sized cube in range.\nThe illusion cannot cause harm, but creatures who believe the illusion is real react to it as though it were.\nA creature who inspects the illusion from afar must pass a Wisdom check vs. your last spellcasting check to perceive the false nature of the illusion.\nTouching the illusion also reveals its false nature.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Invisibility",
        tierByClass: [2, 2],
        classes: ["Wizard", "Witch"],
        duration: "10 rounds",
        range: "Close",
        desc: "A creature you touch becomes invisible for the spell's duration.\nThe spell ends if the target attacks or casts a spell.",
        castWithAdv: false,
        popularityWithClass: [50, 1]
    },
    {
        name: "Judgment",
        tierByClass: [5],
        classes: ["Priest"],
        duration: "5 rounds",
        range: "Close",
        desc: "You instantly banish a creature you touch, sending it and all possessions it carries to face the judgment of your god.\nYou can banish an intelligent creature of LV 10 or less.\nWhen the creature returns in 5 rounds, it has been healed to full hit points if its deeds pleased your god. It has been reduced to 1 hit point if its deeds angered your god. If your god can't judge its actions, it is unchanged.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Knock",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Near",
        desc: "A door, window, gate, chest, or portal you can see within range instantly opens, defeating all mundane locks and barriers. This spell creates a loud knock audible to all within earshot.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Lay to Rest",
        tierByClass: [3, 3],
        classes: ["Priest", "Grave Warden"],
        duration: "Instant",
        range: "Close",
        desc: "You instantly send an undead creature you touch to its final afterlife, destroying it utterly.\nYou can target an undead creature of LV 9 or less.",
        castWithAdv: false,
        popularityWithClass: [50, 5]
    },
    {
        name: "Levitate",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Self",
        desc: "You can float a near distance vertically per round on your turn. You can also push against solid objects to move horizontally.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Light",
        tierByClass: [1, 1],
        classes: ["Priest", "Wizard"],
        duration: "1 hour real time",
        range: "Close",
        desc: "One object you touch glows with bright, heatless light, illuminating out to a near distance for 1 hour of real time.",
        castWithAdv: false,
        popularityWithClass: [24, 10]
    },
    {
        name: "Lightning Bolt",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Far",
        desc: "You shoot a blue-white ray of lightning from your hands, hitting all creatures in a straight line out to a far distance.\nCreatures struck by the lightning take 3d6 damage.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Mage Armor",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "10 rounds",
        range: "Self",
        desc: "An invisible layer of magical force protects your vitals. Your armor class becomes 14 (18 on a critical spellcasting check) for the spell's duration.",
        castWithAdv: false,
        popularityWithClass: [16]
    },
    {
        name: "Magic Circle",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Near",
        desc: "You conjure a circle of runes out to near-sized cube centered on yourself and name a type of creature (for example, demons).\nFor the spell's duration, creatures of the chosen type cannot attack or cast a hostile spell on anyone inside the circle. The chosen creatures also can't possess, compel, or beguile anyone inside the circle.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Magic Missile",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Far",
        desc: "You have advantage on your check to cast this spell.\nA glowing bolt of force streaks from your open hand, dealing 1d4 damage to one target.",
        castWithAdv: true,
        popularityWithClass: [50]
    },
    {
        name: "Mass Cure",
        tierByClass: [3],
        classes: ["Priest"],
        duration: "Instant",
        range: "Near",
        desc: "All allies within near range of you regain 2d6 hit points.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Mirror Image",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "5 rounds",
        range: "Self",
        desc: "You create a number of illusory duplicates of yourself equal to half your level rounded down (minimum 1). The duplicates surround you and mimic you.\nEach time a creature attacks you, the attack misses and causes one of the duplicates to evaporate. If all of the illusions have disappeared, the spell ends.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Mistletoe",
        tierByClass: [3],
        classes: ["Witch"],
        duration: "1d8 days",
        range: "Near",
        desc: "Two creatures you can see within near of you become enchanted with each other for 1d8 days.\n Each time one of the affected creatures takes damage, it may make a DC 15 Charisma check. On a success, the spell ends.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Misty Step",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Self",
        desc: "In a puff of smoke, you teleport a near distance to an area you can see.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Moonbeam",
        tierByClass: [4],
        classes: ["Witch"],
        duration: "Instant",
        range: "Far",
        desc: "A wavering ray of silvery moonlight strikes one creature within far. It takes 3d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Mother of Night",
        tierByClass: [5],
        classes: ["Witch"],
        duration: "Instant",
        range: "Self",
        desc: "You beseech the Mother of Night to lend you power.\n Make a single wish, stating it as exactly as possible. Your wish occurs, as interpreted by the GM.\n If you fail this spellcasting check, the Mother of Night pulls you into The Nightfall for judgment. You can't cast this spell again until you appease her demands.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Nightmare",
        tierByClass: [4],
        classes: ["Witch"],
        duration: "Focus",
        range: "On the same plane",
        desc: "You visit the dreams of one sleeping creature, sending it heart-stopping nightmares.\n You can target a creature whose level is less than or equal to half your level rounded down (minimum 1). The target must be sleeping, and you must have seen it before in person.\n If you successfully focus on this spell for 3 rounds in a row, the creature dies of fright.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Oak, Ash, Thorn",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "Focus",
        range: "Self",
        desc: "For the spell's duration, faeries, demons, and devils can't attack you. These beings also can't possess, compel, or beguile you.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Passwall",
        tierByClass: [4],
        classes: ["Wizard"],
        duration: "5 rounds",
        range: "Close",
        desc: "A tunnel of your height opens in a barrier you touch and lasts for the duration.\nThe passage can be up to near distance in length and must be in a straight line.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Pillar of Salt",
        tierByClass: [4],
        classes: ["Priest"],
        duration: "Focus",
        range: "Near",
        desc: "A creature you target turns into a statue made of hardened salt.\nYou can target a creature you can see of LV 5 or less.\nIf you successfully focus on this spell for 3 rounds in a row, the transformation becomes permanent.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Pin Doll",
        tierByClass: [3],
        classes: ["Witch"],
        duration: "Focus",
        range: "On the same plane",
        desc: "You pin a piece of hair or flesh taken from one creature to a small, burlap doll the spell conjures.\n On your turn while focusing on this spell, you can push a pin into the doll. Each time you do this, the creature who the hair or flesh belonged to takes 2d6 damage. After this spell ends, the piece of hair or flesh burns to ash.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Plane Shift",
        tierByClass: [5, 5],
        classes: ["Priest", "Wizard"],
        duration: "Instant",
        range: "Close",
        desc: "You fold space and time, transporting yourself and all willing creatures within close range to a location on another plane of your choice.\nUnless you have been to your intended location before, you appear in a random place on the destination plane.",
        castWithAdv: false,
        popularityWithClass: [50, 1]
    },
    {
        name: "Poison",
        tierByClass: [2],
        classes: ["Witch"],
        duration: "5 rounds",
        range: "Close",
        desc: "One worn or carried object you touch becomes toxic for the spell's duration. Any creature in contact with the object at the start of its turn takes 1d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Polymorph",
        tierByClass: [4, 4],
        classes: ["Wizard", "Witch"],
        duration: "10 rounds",
        range: "Close",
        desc: "You transform a creature you touch into another natural creature you choose of equal or smaller size. Any gear the target carries melds into its new form.\nThe target gains the creature's physical stats and features, but it retains its non-physical stats and features.\nIf the target goes to 0 hit points, it reverts to its true form at half its prior hit points.\nYou can target any willing creature with this spell, or an unwilling creature whose level is less than or equal to half your level rounded down (min. 1).",
        castWithAdv: false,
        popularityWithClass: [50, 1]
    },
    {
        name: "Power Word Kill",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Near",
        desc: "You utter the Word of Doom. One creature you target of LV 9 or less dies if it hears you.\nTreat a failed spellcasting check for this spell as a critical failure, and roll the mishap with disadvantage.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Prismatic Orb",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Far",
        desc: "You send a strobing orb of energy streaking toward a target within range.\nChoose an energy type from fire, cold, or electricity. The orb deals 3d8 damage and delivers a concussive blast of the chosen energy type.\nIf the energy type is anathema to the target's existence (for example, cold energy against a fire elemental), the orb deals double damage to it instead.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Prophecy",
        tierByClass: [5],
        classes: ["Priest"],
        duration: "Instant",
        range: "Self",
        desc: "You commune directly with your god for guidance.\nAsk the GM one question. The GM answers the question truthfully using the knowledge your god possesses. Deities are mighty, but not omniscient.\nYou cannot cast this spell again until you complete penance.",
        castWithAdv: false,
        popularityWithClass: [75]
    },
    {
        name: "Protection from Energy",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Close",
        desc: "One creature you touch becomes impervious to the wild fury of the elements.\nChoose fire, cold, or electricity. For the spell's duration, the target is immune to harm from energy of the chosen type.",
        castWithAdv: false,
        popularityWithClass: [60]
    },
    {
        name: "Protection from Evil",
        tierByClass: [1, 1],
        classes: ["Priest", "Wizard"],
        duration: "Focus",
        range: "Close",
        desc: "For the spell's duration, chaotic beings have disadvantage on attack rolls and hostile spellcasting checks against the target. These beings also can't possess, compel, or beguile it.\nWhen cast on an already possessed target, the possessing entity makes a CHA check vs. the last spellcasting check. On a failure, the entity is expelled.",
        castWithAdv: false,
        popularityWithClass: [11, 11]
    },
    {
        name: "Puppet",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "Focus",
        range: "Close",
        desc: "One humanoid creature of LV 2 or less you touch becomes ensnared by your movements. On your turn, the creature mimics all your movements.\n If mimicking you would cause the creature to directly harm itself or an ally, it can make a DC 15 Charisma check. On a success, it resists mimicking you.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Rebuke Unholy",
        tierByClass: [3],
        classes: ["Priest"],
        duration: "Instant",
        range: "Near",
        desc: "You rebuke creatures who oppose your alignment, forcing them to flee. You must present a holy symbol to cast this spell.\nIf you are lawful or neutral, this spell affects demons, devils, and outsiders. If you are chaotic, this spell affects angels and natural creatures of the wild.\nAffected creatures within near of you must make a CHA check vs. your spellcasting check. If a creature fails by 10+ points and is equal to or less than your level, it is destroyed. Otherwise, on a fail, it flees from you for 5 rounds.",
        castWithAdv: false,
        popularityWithClass: [40]
    },
    {
        name: "Regenerate",
        tierByClass: [4],
        classes: ["Priest"],
        duration: "Focus",
        range: "Close",
        desc: "A creature you touch regains 1d4 hit points on your turn for the duration. This spell also regrows lost body parts.",
        castWithAdv: false,
        popularityWithClass: [60]
    },
    {
        name: "Resilient Sphere",
        tierByClass: [4],
        classes: ["Wizard"],
        duration: "5 rounds",
        range: "Close",
        desc: "You conjure a weightless, glassy sphere around you that extends out to close range.\nFor the spell's duration, nothing can pass through or crush the sphere.\nYou can roll the sphere a near distance on your turn.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Restoration",
        tierByClass: [3],
        classes: ["Priest"],
        duration: "Instant",
        range: "Close",
        desc: "With the touch of your hands, you expunge curses and illnesses. One curse, illness, or affliction of your choice affecting the target creature ends.",
        castWithAdv: false,
        popularityWithClass: [80]
    },
    {
        name: "Scrying",
        tierByClass: [5, 5],
        classes: ["Wizard", "Witch"],
        duration: "Focus",
        range: "Self",
        desc: "You look into a crystal ball or reflecting pool, calling up images of a distant place.\nFor the spell's duration, you can see and hear a creature or location you choose that is on the same plane.\nThis spell is DC 18 to cast if you try to scry on a creature or location that is unfamiliar to you.\nEach round, creatures you view may make a Wisdom check vs. your last spellcasting check. On a success, they become aware of your magical observation.",
        castWithAdv: false,
        popularityWithClass: [90, 1]
    },
    {
        name: "Sending",
        tierByClass: [3],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Unlimited",
        desc: "You send a brief, mental message to any creature with whom you are familiar who is on the same plane.",
        castWithAdv: false,
        popularityWithClass: [60]
    },
    {
        name: "Shadowdance",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "3 rounds",
        range: "Near",
        desc: "You spin shadowstuff into a convincing visible and audible illusion at a point within near.\n The illusion can be as big as a person and can move within a near range of where it appeared.\n The illusion can't affect physical objects. Touching the illusion reveals its false nature.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Shapechange",
        tierByClass: [5, 5],
        classes: ["Wizard", "Witch"],
        duration: "Focus",
        range: "Self",
        desc: "You transform yourself and any gear you carry into another natural creature you've seen of level 10 or less. You assume the creature's physical stats and features, but you retain your non-physical stats and features (including INT, WIS, and CHA).\nIf you go to 0 HP while under the effects of this spell, you revert to your true form at 1 HP.",
        castWithAdv: false,
        popularityWithClass: [80, 20]
    },
    {
        name: "Shield of Faith",
        tierByClass: [1],
        classes: ["Priest"],
        duration: "5 rounds",
        range: "Self",
        desc: "A protective force wrought of your holy conviction surrounds you. You gain a +2 bonus to your armor class for the duration.",
        castWithAdv: false,
        popularityWithClass: [7]
    },
    {
        name: "Silence",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Far",
        desc: "You magically mute sound in a near cube within the spell's range. Creatures inside the area are deafened, and any sounds they create cannot be heard.",
        castWithAdv: false,
        popularityWithClass: [30]
    },
    {
        name: "Sleep",
        tierByClass: [1],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Near",
        desc: "You weave a lulling spell that fills a near-sized cube extending from you. Creatures in the area of effect fall into a deep sleep if they are LV 2 or less.\nVigorous shaking or being injured wakes them.",
        castWithAdv: false,
        popularityWithClass: [17]
    },
    {
        name: "Smite",
        tierByClass: [2],
        classes: ["Priest"],
        duration: "Instant",
        range: "Near",
        desc: "You call down punishing flames on a creature you can see within range. It takes 1d6 damage.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Soul Jar",
        tierByClass: [5],
        classes: ["Witch"],
        duration: "Permanent",
        range: "Close",
        desc: "You transfer the soul of one creature you touch of LV 9 or less into a vessel, such as a jar. The creature's body becomes comatose, but it doesn't die.\n If the vessel opens or breaks, the creature's soul returns to its body.\n You can possess the empty body with your own spirit, taking control of it. Your body becomes comatose during this time. If the body dies while you possess it, your soul returns to your body.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Speak with Dead",
        tierByClass: [3, 3, 3, 1],
        classes: ["Priest", "Wizard", "Witch", "Grave Warden"],
        duration: "Instant",
        range: "Close",
        desc: "A dead body you touch answers your questions in a distant, wheezing voice.\nYou can ask the dead body up to three yes or no questions (one at a time).\nThe GM truthfully answers 'yes' or 'no' to each. If you cast this spell more than once in 24 hours, treat a failed spellcasting check for it as a critical failure instead.",
        castWithAdv: false,
        popularityWithClass: [60, 40, 1, 5]
    },
    {
        name: "Spidersilk",
        tierByClass: [2],
        classes: ["Witch"],
        duration: "Focus",
        range: "Self",
        desc: "Sticky spidersilk covers your hands and feet.\n For the spell's duration, you can walk on vertical surfaces as easily as if it were flat ground.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Stoneskin",
        tierByClass: [4],
        classes: ["Wizard"],
        duration: "10 rounds",
        range: "Self",
        desc: "Your skin becomes like granite. For the spell's duration, your armor class becomes 17 (20 on a critical spellcasting check).",
        castWithAdv: false,
        popularityWithClass: [80]
    },
    {
        name: "Summon Extraplanar",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Near",
        desc: "You reach into the outer planes, summoning forth a creature.\nYou summon an elemental or outsider of LV 7 or less. The creature is under your control and acts on your turn.\nIf you lose focus on this spell, you lose control of the creature and it becomes hostile toward you and your allies.\nYou must pass a spellcasting check on your turn to return the creature to the outer planes.",
        castWithAdv: false,
        popularityWithClass: [70]
    },
    {
        name: "Swarm",
        tierByClass: [3],
        classes: ["Witch"],
        duration: "Focus",
        range: "Far",
        desc: "A dense swarm of biting bats, rats, or locusts appears in a nearsized cube around a point you can see within range.\n All creatures that start their turn within the swarm take 2d6 damage and are blinded.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Telekinesis",
        tierByClass: [4],
        classes: ["Wizard"],
        duration: "Focus",
        range: "Far",
        desc: "You lift a creature or object with your mind. Choose a target that weighs 1,000 pounds or less. You can move it a near distance in any direction and hold it in place.",
        castWithAdv: false,
        popularityWithClass: [80]
    },
    {
        name: "Teleport",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Close",
        desc: "You and any willing creatures you choose within close range teleport to a location you specify on your same plane.\nYou can travel to a known teleportation sigil or to a location you've been before. Otherwise, you have a 50% chance of arriving off-target.",
        castWithAdv: false,
        popularityWithClass: [85]
    },
    {
        name: "Toadstool",
        tierByClass: [2],
        classes: ["Witch"],
        duration: "Instant",
        range: "Self",
        desc: "You conjure a plump, speckled toadstool in your hand. It disappears at the end of your next turn.\n A creature that eats the toadstool regains 1d6 hit points.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Turn Undead",
        tierByClass: [1],
        classes: ["Priest"],
        duration: "Instant",
        range: "Near",
        desc: "You rebuke undead creatures, forcing them to flee. You must present a holy symbol to cast this spell.\nUndead creatures within near of you must make a CHA check opposed by your spellcasting check. If a creature fails by 10+ points and is equal to or less than your level, it is destroyed. Otherwise, on a fail, it flees from you for 5 rounds.",
        castWithAdv: false,
        popularityWithClass: [0]
    },
    {
        name: "Void Stare",
        tierByClass: [3],
        classes: ["Witch"],
        duration: "Focus",
        range: "Far",
        desc: "Your eyes turn black as you look into the dark between the stars.\n One creature of LV 6 or less you can see falls under your control. You decide its actions during its turn.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Wall of Force",
        tierByClass: [4],
        classes: ["Wizard"],
        duration: "5 rounds",
        range: "Near",
        desc: "You lift your hands, conjuring a transparent wall of force.\nThe thin wall must be contiguous and can cover a near-sized area in width and length. You choose its shape.\nNothing on the same plane can physically pass through the wall.",
        castWithAdv: false,
        popularityWithClass: [75]
    },
    {
        name: "Web",
        tierByClass: [2],
        classes: ["Wizard"],
        duration: "5 rounds",
        range: "Far",
        desc: "You create a near-sized cube of sticky, dense spider web within the spell's range. A creature stuck in the web can't move and must succeed on a Strength check vs. your spellcasting check to free itself.",
        castWithAdv: false,
        popularityWithClass: [85]
    },
    {
        name: "Whisper",
        tierByClass: [3],
        classes: ["Witch"],
        duration: "Instant",
        range: "Close",
        desc: "You whisper into another creature's ear, planting a false memory in its mind.\n You describe one brief, false memory that the target believes is true going forward.\n If you fail this spellcasting check, the GM chooses a short, false memory to plant in your mind instead.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Willowman",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "Instant",
        range: "Near",
        desc: "You call upon the Willowman to appear in one creature's mind, filling it with supernatural terror.\n Choose one creature of LV 2 or less within range. That creature must immediately make a morale check.\n Even creatures that are not normally subject to morale checks (such as undead) must do so.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Wish",
        tierByClass: [5],
        classes: ["Wizard"],
        duration: "Instant",
        range: "Self",
        desc: "This mighty spell alters reality.\nMake a single wish, stating it as exactly as possible. Your wish occurs, as interpreted by the GM.\nTreat a failed spellcasting check for this spell as a critical failure, and roll the mishap with disadvantage.",
        castWithAdv: false,
        popularityWithClass: [85]
    },
    {
        name: "Witchlight",
        tierByClass: [1],
        classes: ["Witch"],
        duration: "Focus",
        range: "Near",
        desc: "You summon a floating marsh light that bobs in the air and casts light out to a close radius around it.\n The light can change colors and take on vague shapes. It can float up to a near distance on your turn.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Wrath",
        tierByClass: [4],
        classes: ["Priest"],
        duration: "10 rounds",
        range: "Self",
        desc: " Your weapons become magical +2 and deal an additional d8 damage for the spell's duration.",
        castWithAdv: false,
        popularityWithClass: [50]
    },
    {
        name: "Zone of Truth",
        tierByClass: [2],
        classes: ["Priest"],
        duration: "Focus",
        range: "Near",
        desc: "You compel a creature you can see to speak truth. It can't utter a deliberate lie while within range.",
        castWithAdv: false,
        popularityWithClass: [50]
    },

    // SEER SPELLS:

    {
        name: "Cast Out",
        tierByClass: [3],
        classes: ["Seer"],
        duration: "Focus",
        range: "Far",
        desc: "You turn a creature aside, throwing it out of your presence.\nChoose a creature you can see. For the spell's duration, that creature can't come within near range of you. It can still attack you from outside of near range.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Chant",
        tierByClass: [1],
        classes: ["Seer"],
        duration: "Focus",
        range: "Self",
        desc: "You begin an unearthly chant that lifts your vision beyond its ordinary limitations.\nFor the spell's duration, you can see all invisible and hidden things as though they were plainly visible. This spell does not allow you to see in a way that you could not normally, such as in darkness or through walls.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Evoke Rage",
        tierByClass: [1],
        classes: ["Seer"],
        duration: "1d4 rounds",
        range: "Close",
        desc: "You call out the berserk rage locked inside someone.\nOne willing humanoid you touch enters a berserk state. The target is immune to morale checks, has ADV on STR checks and melee attacks, and deals +1d4 damage for the spell's duration.\nIf the target does not attack another creature on its turn, the spell ends.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Fate",
        tierByClass: [2],
        classes: ["Seer"],
        duration: "Instant",
        range: "Near",
        desc: "You painfully twist the golden threads of a creature's fate.\nOne creature you target in range takes 1d10 damage and loses any luck tokens it has.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Freya's Omen",
        tierByClass: [4],
        classes: ["Seer"],
        duration: "1d6 rounds",
        range: "Self",
        desc: "For the spell's duration, you do not lose the ability to cast a spell if you fail its spellcasting check.\nIf you critically fail a spellcasting check, you may reroll your check once. You must use the new result.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Hallucinate",
        tierByClass: [3],
        classes: ["Seer"],
        duration: "Focus",
        range: "Near",
        desc: "One creature you target in near whose level is less than or equal to your own is overcome by visions of what might yet come to pass.\nFor the spell's duration, the target cannot act on its turn unless it passes a Wisdom check equal to your last spellcasting check.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Loki's Trickery",
        tierByClass: [4],
        classes: ["Seer"],
        duration: "Instant",
        range: "Near",
        desc: "You are filled with Loki's hypnotic guile. Creatures who hear you speak will alter their own beliefs and memories to match your suggestion.\nTarget one creature who can hear and understand you within range. You make one plausible statement, true or not.\nThe target must make a Wisdom check vs. your spellcasting check. If it fails, it now believes what you stated as though it were fact, regardless of what it knows.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Odin's Wisdom",
        tierByClass: [4],
        classes: ["Seer"],
        duration: "1d6 rounds",
        range: "Self",
        desc: "For the spell's duration, add your level as an additional bonus to your Wisdom checks and spellcasting checks.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Potion",
        tierByClass: [1],
        classes: ["Seer"],
        duration: "Instant",
        range: "Close",
        desc: "As a part of casting this spell, you must bless a single drink of any liquid.\nThe liquid gains healing properties for 1 day. A creature who imbibes it may end the effects of one poison or may immediately stop dying (the creature remains at 0 HP).",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Ragnarok",
        tierByClass: [5],
        classes: ["Seer"],
        duration: "Instant",
        range: "Far",
        desc: "You look deep into the strands of fate, learning the final destiny of one soul after the battle of Ragnarok. Do they live, or die?\nChoose one creature in range. You can only target the same creature with this spell one time.\nThat creature must pass a CON check equal to your spellcasting check or die instantly.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Raven",
        tierByClass: [3],
        classes: ["Seer"],
        duration: "Instant",
        range: "Unlimited",
        desc: "You whisper a message to Odin's own ravens, and they carry it across all worlds to its recipient.\nSpeak a short sentence, and the name of its recipient, dead or alive. That creature hears your utterance whispered in its mind.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Read The Runes",
        tierByClass: [2],
        classes: ["Seer"],
        duration: "Instant",
        range: "Self",
        desc: "You ask the gods a question and cast the runestones, interpreting the meaning of the results.\nAsk the Game Master one yes or no question. The Game Master truthfully answers 'yes' or 'no.'",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Sacrifice",
        tierByClass: [2],
        classes: ["Seer"],
        duration: "Instant",
        range: "Close",
        desc: "As a part of casting this spell, you must ritually sacrifice a living creature of LV 2 or higher.\nThe target you touch gains a bonus to their next check or attack roll equal to the sacrificed creature's level.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Soulbind",
        tierByClass: [2],
        classes: ["Seer"],
        duration: "Focus",
        range: "Close",
        desc: "You seal the soul of a living creature, preventing magic from leeching into it.\nOne creature you touch becomes nearly impervious to all magic. For the spell's duration, all other spells targeting the creature (harmful or helpful) are DC 18 to cast.\nThis spell ends as soon as the target is affected by another spell.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Thor's Thunder",
        tierByClass: [4],
        classes: ["Seer"],
        duration: "Instant",
        range: "Far",
        desc: "Thor casts down a bolt of lightning to strike one target. The target takes 3d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Trance",
        tierByClass: [1],
        classes: ["Seer"],
        duration: "Instant",
        range: "Close",
        desc: "You enter a trance, catching small glimpses of a creature's fate. One humanoid creature you touch (you can't target yourself) gains a luck token. It can't have more than one luck token at once.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Valkyrie",
        tierByClass: [5],
        classes: ["Seer"],
        duration: "10 rounds",
        range: "Near",
        desc: "You summon a valkyrie to your aid. She appears in a location within near and acts of her own free will to help you. She returns to Valhalla when the spell ends.\nYou can't cast this again until you complete penance.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Wolfshape",
        tierByClass: [3],
        classes: ["Seer"],
        duration: "Focus",
        range: "Self",
        desc: "You and your gear transform into a wolf for the spell's duration. You assume the wolf's STR, DEX, CON, HP, AC, speed, attacks, and physical characteristics, but retain your INT, WIS, and CHA.\nYou can cast spells in this form. If you go to 0 HP, you revert to your true shape at 0 HP.\nIf you are level 5+, you can transform into a dire wolf or a winter wolf instead.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "World Serpent",
        tierByClass: [5],
        classes: ["Seer"],
        duration: "Focus",
        range: "Close",
        desc: "The torturous venom of the World Serpent drips from the weapons of a creature you touch.\nThe target deals x2 damage with each attack (x4 on a critical hit) for the spell's duration.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "World Tree",
        tierByClass: [5],
        classes: ["Seer"],
        duration: "Focus",
        range: "Close",
        desc: "The roots of the life-giving World Tree wrap around the soul of a creature you touch.\nFor the spell's duration, the target can't be brought below 1 HP.",
        castWithAdv: false,
        popularityWithClass: [1]
    },

    // Grave Warden spells

    {
        name: "Carrion Stench",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Self",
        desc: "Other living creatures within a near area centered on you make a CON check vs. your spellcasting check or suffer DISADV on attack rolls and spellcasting checks on their next turn.",
        castWithAdv: false,
        popularityWithClass: [5]
    },
    {
        name: "Corpse Companion",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "10 rounds",
        range: "Close",
        desc: "A mostly intact corpse you touch rises as an animated lesser skeleton. You control this undead minion and it acts on your turn.\nOnly one instance of this spell may be active at a time. A 2nd casting of this spell destroys the previous minion, even if the spell fails. This does not count against your minion LV limit.",
        castWithAdv: false,
        popularityWithClass: [5]
    },
    {
        name: "Deadlight",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "1 hour of real time",
        range: "Near",
        desc: "You use the soul of a fresh corpse to create a floating light that bobs in the air and casts illumination to a near distance around it. It can float up to a near distance on your turn.",
        castWithAdv: false,
        popularityWithClass: [2]
    },
    {
        name: "Locate Corpse",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Self",
        desc: "You know the direction and range of the closest non-animated corpse.",
        castWithAdv: false,
        popularityWithClass: [2]
    },
    {
        name: "Protection from Undead",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Close",
        desc: "Undead beings have DISADV on attack rolls against a target you touch. These beings also can't possess, compel, or beguile it. When cast on an already-possessed target, the possessing entity makes a CHA check vs. the last spellcasting check. On a failure, the entity is expelled.",
        castWithAdv: false,
        popularityWithClass: [3]
    },
    {
        name: "Spectral Scythe",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "10 turns",
        range: "Near",
        desc: "You manifest a ghostly image of a scythe floating within near.\nOn your turn, you can move it up to near distance away and cast touch spells through it. Apply the Necrotic Reap bonus to any spell cast in this way.\nOnly one instance of this spell may be active at a time.\nA second casting of this spell while another instance exists immediately de-activates the previous one, even if the spell fails.",
        castWithAdv: false,
        popularityWithClass: [5]
    },
    {
        name: "Thrall Offering",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Near",
        desc: "You magically beguile one humanoid of LV 2 or less within near range who is compelled to approach one of your undead minions and allow itself to be attacked with advantage.\nIf there are no undead minions, the affected humanoid follows you to the best of its ability until the spell ends or an undead minion appears.\nIf a minion kills it, that undead is healed to full HP. The spell ends if you or your allies hurt it, but stays beguiled if a minion attacks it. The target remembers you magically enchanted it.",
        castWithAdv: false,
        popularityWithClass: [3]
    },
    {
        name: "Touch of Fatigue",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "1 round",
        range: "Close",
        desc: "A target you touch becomes exhausted during its next turn, giving it disadvantage on attacks and spellcasting checks.",
        castWithAdv: false,
        popularityWithClass: [2]
    },
    {
        name: "Undeath Blessing",
        tierByClass: [1],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Close",
        desc: "Your touch infuses necrotic vitality to an undead or withers the living.\nThe undead target you touch regains 1d6 hit points, while a living target you touch loses that many hit points.",
        castWithAdv: false,
        popularityWithClass: [3]
    },
    {
        name: "Bone Fire",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "1 Day",
        range: "Close",
        desc: "You light a corpse into a magical campfire that cannot be moved, illuminating a near radius. There's a 50% chance that the necromantic energy from the fire will deter wandering monsters from attacking a resting party.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Bonebound Sentry",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "10 rounds",
        range: "Near",
        desc: "Using spectral energy, you double the size of one undead minion that you control and root it in place.\n The bonebound minion cannot move, but its max HP is doubled and its attack damage die is doubled.\nYou may dispel Bonebound Sentry at any time.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Boneskin",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "10 rounds",
        range: "Self",
        desc: "Your skin becomes covered in hardened bone armor. For the spell's duration, your armor class becomes 17 (20 on a critical spellcasting check.)",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Darkness",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Near",
        desc: "You magically create darkness in a near cube within the spell's range. Creatures inside the area are blinded and obscured from view from the outside. Only magical light can penetrate this magical darkness.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Dark Step",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Self",
        desc: "Starting from darkness, you teleport to a near distance that is also shrouded in darkness.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Fireskull Familiar",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "1 hour of real time",
        range: "Close",
        desc: "You animate a single skull as your familiar from a corpse or skull you touch. The skull is bathed in blue flames and gifted with levitation-based flight.\nIt projects light in a near radius, it can speak and understand, retrieve small items, perform a minor bite attack, and deliver messages.\nOn your turn, you can move it a near distance from you. Consider it an AC 10 creature with a 1d2 damage bite attack and 3 HP. Only one instance of this spell may be active at a time.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Ghoulish Claws",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Close",
        desc: "By your touch, a willing target's hands transform into ghastly claws. Your target may make a claw attack that deals 1d8 points of damage plus paralyze. Victims hit by this attack must make a DC 12 CON check or be paralyzed for 1d4 rounds.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Grasp From the Grave",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "Focus",
        range: "Far",
        desc: "You call forth spectral hands that erupt from the ground in a near-sized cube of effect. Any creature in the cube moves at half speed and takes 1d4 damage per round.\nOnce cast, the spectral hands cannot be moved to a new location without a new instance of the spell being cast.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Grub Geyser",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Close",
        desc: "You breathe a stream of flesh-eating maggots at a close target. Target must make a CON vs. your spellcasting check check or take 1d6 damage and suffer DISADV on attacks and spellcasting checks as long as they are covered in maggots. Target continues to take 1d6 damage each round until the effect ends or until they take an action to brush them off.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Prevent Decay",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "1 Day",
        range: "Close",
        desc: "Any corpse you touch is perfectly preserved for 1 day.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Blood Pact",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "10 rounds",
        range: "Near",
        desc: "You form a life-preserving contract among willing allies in near range.\nIf any target is reduced to 0 HP, an ally automatically drains 1 HP from all other allies who have at least 2 HPs in near range.\nBlood Pact can only be cast once per day.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Chilling Shriek",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Near",
        desc: "With a ghastly scream, you terrify living creatures. Living beings LV 9 or less within near range of you must make a CON check vs. your spellcasting check. Any who fail flee from you for 1d4 rounds.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Corpse Explosion",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Near",
        desc: "With a clench of your fist, you cause a non-animated corpse to explode. All creatures in close radius around the corpse take 5d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Create Corpse",
        tierByClass: [2],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Close",
        desc: "With the snap of your fingers, a fresh human corpse materializes at your feet.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Deathward Circle",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "Focus",
        range: "Near",
        desc: "You conjure a circle of bones and necrotic energy out to near-sized cube centered on yourself. For the spell’s duration, undead creatures LV 9 or less cannot attack or cast a hostile spell on anyone inside the circle. The undead also can’t possess, compel, or beguile anyone inside the circle.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Fossilize",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "Permanent",
        range: "Close",
        desc: "Your touch hardens the bones of an undead minion, granting it +2 AC and imposing half damage on all damage dealt to it.\nFossilized undead cannot be healed.\nThis spell has no effect on already fossilized undead.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Raise Dead",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "1 Day",
        range: "Near",
        desc: "You utter an incantation and all corpses within a near range rise as zombies or skeletons under your control, their condition dictating which form they take.\nThey maintain the shape, HP, and LV they had in life but lose all of their abilities. They gain the attacks, stats, and abilities of skeletons and zombies.\nThey act on your turn, then collapse to dust after 1 day.\nThe combined LV of controlled undead cannot exceed yours. Recasting adds to or replaces previous undead minions with new ones until the LV limit is reached.\nA critical fail causes all undead minions under your control to collapse into grave dust, including undead gained from other spells or the Claim Undead ability. Do not roll on the mishap table.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Summon Wraith",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Near",
        desc: "You summon forth a wraith from the incorporeal plane who will act on your turn and do your bidding. After 5 rounds, it melts away into smoke.\nA critical fail on casting this spell still summons the wraith, but it turns against you during its 5 round lifespan.\nDo not roll on the mishap table.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Wither",
        tierByClass: [3],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Near",
        desc: "You lift your hands, and all plants within near range quickly wither and die. This also dispels any spells that enhance or conjure plants.\nPlant creatures like Assassin Vines, Venus Flytraps, or Treants take 2d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Black Blood",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Close",
        desc: "With the touch of your hand, any living creature whose LV is half of yours or less must make a CON check vs. your spellcasting check or be turned into a zombie under your control. \nThe zombie retains the original shape, LV, and HP, but loses its abilities, using zombie stats and abilities.\nTotal undead LV under your control cannot exceed your level. If this new minion would exceed your LV limit, previous minions de-animate to make room for the fresh zombie.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Bone Ballista",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "Focus",
        range: "Close",
        desc: "You form a ballista made of bone from a corpse you touch.\nIt fires a bolt of bone shards during your turn, automatically hitting its target in near range, doing 4d6 damage.\nThe bone ballista has 10 HP, 11 AC, and can be damaged by physical or magical attacks.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Demonic Possession",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Near",
        desc: "Your soul willingly leaves your body and occupies a target in near range. An unwilling target can make a CON check vs. your spellcasting check. If they fail, you control the target on your turn, but your body stands in a helpless daze.\nWhen the target returns to its body, it becomes aware of the possession but retains no memories of what transpired. If the target dies, you return to your body as long as it is within near range, otherwise you return to your body and drop to 0 HP.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Domain of Fatigue",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "Focus",
        range: "Self",
        desc: "You cause a wilting of vitality for the living. All living creatures a near distance from you must make a CON check vs. your spellcasting check or have DISADV on all attacks and spellcasting checks.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Drainblade",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "Focus",
        range: "Close",
        desc: "A melee weapon you touch becomes thirsty for blood. It now heals the wielder for an amount of HP equal to the damage it deals, encouraging the wielder to keep fighting. ",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Mortal Rejuvenation",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Near",
        desc: "By reaping the last death energy from any fresh corpses within near, you and allies within near each regain 1d6 HP per corpse. These corpses turn to grave dust.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Remove Curse",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Close",
        desc: "With the touch of your hands, you expunge one curse of your choice affecting the target creature.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Spectral Drain",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Far",
        desc: "Partially transparent black tendrils erupt from your hands and engulf an undead creature, dealing 3d6 damage and healing you by the same amount.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Summon Mummy",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Near",
        desc: "You summon forth a mummy from its hidden crypt who will act on your turn and do your bidding. After 5 rounds, it melts away into smoke.\nA critical fail on casting this spell still summons the mummy, but it turns against you during its 5 round lifespan. Do not roll on the mishap table.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Wall of Bones",
        tierByClass: [4],
        classes: ["Grave Warden"],
        duration: "5 rounds",
        range: "Near",
        desc: "You summon a wall of writhing bones to rise from the ground. The 1-foot thick wall must be contiguous and can cover a near-sized area in length and 10 feet tall or less.\nFoes close to the wall take 2d4 damage from the grasping and clawing bones. A close-sized section of the wall can be attacked and destroyed when 15 HP damage is done to it.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Bone Storm",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "Focus",
        range: "Near",
        desc: "You call upon deathly winds to create a tornado of whirling bones that cover a near cube.\nAny creatures caught inside take 5d6 damage per round. You can forgo moving to move the storm a near distance.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Conjure Wraith",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "1 Day",
        range: "Near",
        desc: "You touch a fresh corpse to conjure a vengeful wraith to do your bidding. It acts on your turn. After 1 day it turns to dust.\nOnly one instance of this spell may be active at a time. A second casting while another instance exists immediately destroys the previous wraith, even if the spell fails. The wraith counts against the LV limit of other undead minions.\nA critical fail on casting this spell still conjures the wraith, but it turns against you. Do not roll on the mishap table.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Ghost Shift",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "Focus",
        range: "Self",
        desc: "You and your equipment become incorporeal, allowing you to move in any direction, including up or down at half movement speed.\nYou can move through solid objects and cannot attack with or be struck by physical weapons. You may cast spells, and hostile spells can affect you.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Killing Shriek",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Near",
        desc: "You let out a mind-shattering scream that instantly slays any living beings who hear it. Living beings within near range of you must make a CON check vs. your spellcasting check. If they fail, they die. You can target living creatures of LV 9 or less.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Lifeward Circle",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "Focus",
        range: "Near",
        desc: "You conjure a circle of bones and necrotic energy out to a near-sized cube centered on yourself. For the spell’s duration, enemy living creatures LV 9 or below cannot attack or cast a hostile spell on anyone inside the circle.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Mummify",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "Permanent",
        range: "Close",
        desc: "After a day of preparation and ceremony, you embalm a corpse as a mummy who acts on your turn. Mummies deteriorate when walking long distances but can be carried in a casket or left to guard a location. This minion does not count against the LV limit of other raised undead minions.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Soul Reap",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Close",
        desc: "Your hand goes incorporeal as you reach into the body of a LV 9 or lower target and pull out its soul, causing the target to die. Treat a failed spellcasting check as a critical failure.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Undeath to Dust",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "Instant",
        range: "Near",
        desc: "You wave your arm and all undead less than LV 9 in a near radius around you instantly turn to dust. Undead LV 9 or above take 4d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Unhallowed Ground",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "Permanent",
        range: "Far",
        desc: "You imbue a landmark with profane magic, permanently turning it into a necromantic beacon of undeath.\nAll the land within 6 miles of the beacon is cursed. Any burial grounds in the area are immediately overrun with the rising of the dead in the form of zombies or skeletons.\nThese undead are not under your control, but will savagely attack anyone they find. \n Anyone who dies in this cursed area immediately rises again as a zombie.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Veil of Life",
        tierByClass: [5],
        classes: ["Grave Warden"],
        duration: "1 day",
        range: "Close",
        desc: "With your touch, one of your undead minions appears to be alive.\nYou choose the ancestry and style of clothes worn by the undead creature. It can appear only slightly larger or smaller than the original undead minion.\nThe spell will also make the target's movements appear life-like. Even the stench will be fully disguised.\nAnyone suspicious can make an INT check vs. your last spellcasting check to disbelieve the illusion.",
        castWithAdv: false,
        popularityWithClass: [1]
    },

    // Ovate

    {
        name: "Arrow of Hornets",
        tierByClass: [1],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Near",
        desc: "A mass of hornets you summon coalesce into an arrow that hits a target, dealing 1d6 damage.",
        castWithAdv: false,
        popularityWithClass: [10]
    },
    {
        name: "Barkhide",
        tierByClass: [1],
        classes: ["Ovate"],
        duration: "5 rounds",
        range: "Close",
        desc: "An animal or giant animal's skin you touch hardens like tree bark. For the spell's duration, the animal's armor class is increased by 2. Can also be cast on Beastmasters.",
        castWithAdv: false,
        popularityWithClass: [3]
    },
    {
        name: "Create Tree",
        tierByClass: [1],
        classes: ["Ovate"],
        duration: "1 Day",
        range: "Close",
        desc: "You lift your hands and a climbable tree grows from any flat, horizontal surface, up to 50' high. It lasts for one day before dematerializing and returning to the forest.",
        castWithAdv: false,
        popularityWithClass: [6]
    },
    {
        name: "Fungal Cloud",
        tierByClass: [1],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Near",
        desc: "You lift your hands and a shrub-sized mushroom grows from any surface and explodes, causing a cloud of spores to fill a near radius.\nAll living creatures must make a CON check vs. your spellcasting check or have DISADV on attacks and checks for the next round.",
        castWithAdv: false,
        popularityWithClass: [5]
    },
    {
        name: "Know Intention",
        tierByClass: [1],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Near",
        desc: "You instantly know the intention of one creature a near distance from you.",
        castWithAdv: false,
        popularityWithClass: [4]
    },
    {
        name: "Speak with Animals",
        tierByClass: [1],
        classes: ["Ovate"],
        duration: "5 Rounds",
        range: "Near",
        desc: "You are able to understand any animals within a near radius, and you may ask them any question.",
        castWithAdv: false,
        popularityWithClass: [5]
    },
    {
        name: "Tree Guardian",
        tierByClass: [1],
        classes: ["Ovate"],
        duration: "5 rounds",
        range: "Near",
        desc: "You turn a tree in near into a guardian for you and your allies. Any ally within close will be protected by the tree's branches, gaining +2 to their AC.",
        castWithAdv: false,
        popularityWithClass: [6]
    },
    {
        name: "Torch of Fireflies",
        tierByClass: [1],
        classes: ["Ovate"],
        duration: "1 hour of real time",
        range: "Close",
        desc: "You summon a cloud of fireflies that illuminate out to a near distance for 1 hour of real time. The fireflies follow the ovate around. Treat them as a LV 0 animal with 1 hp. They are attracted to any invisible creatures, revealing their position if encountered.",
        castWithAdv: false,
        popularityWithClass: [4]
    },

    {
        name: "Charm Animals",
        tierByClass: [2],
        classes: ["Ovate"],
        duration: "1 Day",
        range: "Near",
        desc: "You magically beguile a number of natural animals whose combined levels equal your level. Two 0-level animals count as 1 LV.\nThey regard you as their master, but if they cannot understand you, they will take the most obvious, instinctual action to assist you.\nThe spell ends if you or your allies cause harm to any of them. The animals return to their wild form when the spell ends.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Foresee Good",
        tierByClass: [2],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Close",
        desc: "You call on nature spirits to give you insights into a being's future. One humanoid you touch gains a luck token.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Healing Sprout",
        tierByClass: [2],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Near",
        desc: "You lift one palm, and a restoring plant springs from any surface within a near distance from you. Consuming the plant restores hit points equal to d6s determined by half of your level (rounded down).",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Obscuring Spores",
        tierByClass: [2],
        classes: ["Ovate"],
        duration: "5 rounds",
        range: "Near",
        desc: "You create and cause a mushroom to explode, causing a dense cloud of obscuring spores to fill a near cube centered on a point within near. Creatures cannot see in or out of the cloud. It lasts for the full duration or until a wind disperses it.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Reduce Animal",
        tierByClass: [2],
        classes: ["Ovate"],
        duration: "5 rounds",
        range: "Close",
        desc: "Any animal you touch is reduced to a quarter of its normal size, and any giant animal is reduced to normal size. If the reduced animal is engaged in combat, make a morale check.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Tree Assault",
        tierByClass: [2],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Near",
        desc: "You cause a tree within a near radius to use its limbs to lash any close opponents. Use your own STR or DEX attack bonuses to calculate one melee slam attack per round, doing 2d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Vicious Bite",
        tierByClass: [2],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Close",
        desc: "The teeth on an animal you touch elongate and sharpen. The animal gains an extra die of damage on any bite attack. This spell can be applied to Beastmasters.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Whip of Fire Ants",
        tierByClass: [2],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Near",
        desc: "With the snap of your fingers, a colony of fire ants form into a living needle whip that hits a creature in near, causing 1d8 damage. The hit causes fire ants to remain on the target, biting for an additional 1d4 damage per round until they are actively removed.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Animal Totem",
        tierByClass: [3],
        classes: ["Ovate"],
        duration: "1 Day",
        range: "Close",
        desc: "A willing animal you touch is transformed into a small wooden statue in your inventory. You can return it to natural form at any time before the duration expires, and it returns on its own.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Arbor Vantage",
        tierByClass: [3],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Near",
        desc: "Any ally within close range of a tree you enchant makes attacks with advantage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Bloomquake",
        tierByClass: [3],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Far",
        desc: "You cause thousands of flowers to burst from the ground, creating an earthquake centered on a target a far distance from you. All creatures in a near area from target location must make a DEX check vs. your spellcasting check or fall and take 2d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Plantidote",
        tierByClass: [3],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Near",
        desc: "You cause a curative plant to spring from any surface within a near distance from you. Consuming the plant neutralizes poison, disease, or heals 1d4 stat damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Summon Animal",
        tierByClass: [3],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Near",
        desc: "You invoke your nature spirit, summoning a natural animal of LV 5 or less, arriving in one round. During that round, you do not have to make a focus check. The animal is under your control and acts on your turn. If you lose focus on this spell, you lose control of the animal and it acts instinctually based on the situation. It cannot communicate with you unless you know its language.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Sword of Scorpions",
        tierByClass: [3],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Close",
        desc: "You clap your hands, conjuring a swarm of scorpions that form into a living scimitar that hits a close opponent, dealing 1d12 damage. Damaged target must make a CON check vs. your spellcasting check or be paralyzed for 1d4 rounds.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Train Animal",
        tierByClass: [3],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Close",
        desc: "After 10 rounds of concentration, you train any calmed or captive animal to become your companion. This companionship mirrors the class talent of the same name.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Yesterday's Vision",
        tierByClass: [3],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Far",
        desc: "After one round of communing with your nature spirit, you can ask a single yes/no question to gain insight into an event that occurred within the last week.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Companion Sight",
        tierByClass: [4],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Far",
        desc: "You close your eyes and match your breath to that of your companion. You can see through the eyes of your animal companion.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Enlarge Animal",
        tierByClass: [4],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Near",
        desc: "An animal you touch grows into the giant version of itself. Replace its stat block with its giant version.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Forest Blend",
        tierByClass: [4],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Self",
        desc: "In a forest environment, you and your allies turn invisible as long as they remain a near distance from you. The spell ends if you or your hidden allies attack or cast a spell. You have advantage on focus checks for this spell. ",
        castWithAdv: true,
        popularityWithClass: [1]
    },
    {
        name: "Life Wolf",
        tierByClass: [4],
        classes: ["Ovate"],
        duration: "Until allies are stable",
        range: "Near",
        desc: "You summon a celestial life wolf (See pg. 132) that acts on your turn. It spends its movement and actions to save your dying and unconscious allies, using one action to magically heal them to 1 HP.  Once no more allies are dying, the wolf vanishes.  If you drop to 0 HP while the life wolf is active, it will turn its attention to you, and vanish after saving you, even if others still require aid.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Mass Breathe Water",
        tierByClass: [4],
        classes: ["Ovate"],
        duration: "1 Hour",
        range: "Near",
        desc: "You and your allies a near distance from you gain magical gills, allowing you to breathe underwater for the spell's duration.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Summon Giant Animal",
        tierByClass: [5],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Near",
        desc: "By invoking the earth spirits, you summon a giant animal of LV 9 or less, arriving in one round. During that round, you don’t have to make a focus check. The animal is under your control and acts on your turn. If you lose focus on this spell, you lose control of the animal and it acts instinctually based on the situation. It cannot communicate with you unless you know its language.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Tomorrow's Vision",
        tierByClass: [4],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Self",
        desc: "After one round of communing with your nature spirit, you can ask three yes/no questions to gain insight into an important event or events that are expected to happen in the next week. Your role in the event cannot be predicted, only a general sense of what is likely to occur. The question cannot pertain to you or your allies.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Tree Sanctuary",
        tierByClass: [4],
        classes: ["Ovate"],
        duration: "1 rest",
        range: "Close",
        desc: "After 2 rounds of concentration, a portal appears on a tree you touch. Stepping inside seals the portal, transporting you to a secure chamber within the tree. While inside, you are aware of, but protected from, external influences. You are expelled from the chamber if the tree is destroyed. You may complete a rest while safely in this chamber. Once your rest is complete, you re-emerge from the tree. No one else can pass through the tree portal.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Forest Altar",
        tierByClass: [5],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Close",
        desc: "You prepare a natural altar and commune with a nature spirit for 5 rounds. You gain answers to any question regarding an entire region, be they historical, navigational, or political.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Forest of Treants",
        tierByClass: [5],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Far",
        desc: "You call on the very essence of nature to bestow sentient life to up to 4 trees in a forest. These trees become fully realized oak treants (see pg. 127) who will act friendly toward you and assist you with any task that does not involve the destruction of nature. They return to their tree form when the spell ends.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Giant Antapult",
        tierByClass: [5],
        classes: ["Ovate"],
        duration: "Focus",
        range: "Close",
        desc: "You wring your hands, conjuring a group of giant ants that form into a makeshift catapult capable of launching magical boulders at a single visible target within far. Make a ranged attack that deals 8d6 damage on a successful hit.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Healing Feast",
        tierByClass: [5],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Close",
        desc: "You conjure a meal for all allies in near, healing 4d12 HP and curing poisoning and fatigue. It takes 5 rounds to eat and must be fully consumed. ",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Pass Tree",
        tierByClass: [5],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Close",
        desc: "You teleport from a tree you touch to any tree that you have seen before.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Resurrect Animal",
        tierByClass: [4],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Close",
        desc: "You bring a natural animal you touch back to life from the dead. The animal must have been dead for no more than the number of days equal to half your level rounded up.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Rock to Mud",
        tierByClass: [5],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Close",
        desc: "With your touch, you transmute a near-cube area of contiguous rock into mud.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Timber",
        tierByClass: [5],
        classes: ["Ovate"],
        duration: "Instant",
        range: "Far",
        desc: "With a simple hand motion, you topple a tree, dealing 4d8 damage to any creatures along a near-length line where the base of the tree falls.",
        castWithAdv: false,
        popularityWithClass: [1]
    },

    // Shaman

    {
        name: "Flora's Embrace",
        tierByClass: [1],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Far",
        desc: "You awaken the spirits of the plants, entwining foes in a near radius within the chant's range. Targets are limited to move at close range only, but they can still perform actions. Targets can escape if they pass a STR check vs. your last chanting check.",
        castWithAdv: false,
        popularityWithClass: [5]
    },
    {
        name: "Heart of the Lion",
        tierByClass: [1],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You bang your drum with ferocity, causing yourself and your allies within near range to gain unwavering determination. Those affected cannot be surprised and have advantage on initiative rolls.",
        castWithAdv: false,
        popularityWithClass: [7]
    },
    {
        name: "Hiss of the Viper",
        tierByClass: [1],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You hiss loudly and rattle your shakers, calling on the spirit of the serpent. Enemies within a near radius centered on you must make a DC 9 morale check at the beginning of their turn.",
        castWithAdv: false,
        popularityWithClass: [10]
    },
    {
        name: "Sacred Bounty",
        tierByClass: [1],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Far",
        desc: "You open yourself to the abundance of the universe. When you or your allies discover treasure worth at least 1 XP, you gain an additional +1 XP.",
        castWithAdv: false,
        popularityWithClass: [4]
    },
    {
        name: "Shamanic Purge",
        tierByClass: [1],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You compel your allies within a near range from you to undergo a violent upheaval, expelling possessing spirits, compulsions, or beguilements.",
        castWithAdv: false,
        popularityWithClass: [3]
    },
    {
        name: "Shell of the Turtle",
        tierByClass: [1],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You invoke the tortoise's protective energies, granting you and your allies within a near range a +1 bonus to their AC.",
        castWithAdv: false,
        popularityWithClass: [7]
    },

    {
        name: "Ancestral Guidance",
        tierByClass: [3],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Your voice carries the insight of ancient mystics, granting your allies in near distance advantage on all spellcasting and herbal checks.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Call to the Wild",
        tierByClass: [2],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Far",
        desc: "Your voice carries the ancient melody that invites animals in a far range to come to your aid. The GM decides how many are available, their levels adding up to 2x your LV. They begin arriving in one round and will take the most obvious, instinctual action that can assist you and your allies. This chant does not grant you the ability to speak to them or control them.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Dance of the Drum",
        tierByClass: [2],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Your captivating drumming compels enemies within a near distance from you to dance in revelry unless they pass a WIS check vs. your last chanting check. The effect on one target ends if you or your allies do anything the target notices to injure it.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Gift of Earth",
        tierByClass: [2],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Your resonating chant causes yourself and your allies in a near radius centered on you to become rooted in place and grow bark-like skin, giving a +2 AC bonus. You and your allies are rooted in place until the chant ends.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Grace of the Gazelle",
        tierByClass: [2],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You channel the spry spirit of the gazelle, granting you and your allies within a near distance from you advantage on all DEX checks and ranged attacks.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Voice of Verity",
        tierByClass: [2],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Far",
        desc: "Anyone who hears your chant will be compelled to reveal the unvarnished truth.",
        castWithAdv: false,
        popularityWithClass: [1]
    },

    {
        name: "Armaments of the Ancients",
        tierByClass: [3],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You invoke ancestral grace, imbuing your near allies' weapons with magic. They all become +1 magical weapons.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Bramble Bridge",
        tierByClass: [3],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Far",
        desc: "Your song stirs the dormant energy within the surrounding plants, prompting them to sprout and weave together into a bridge, stairway, or ramp, spanning a near-sized cube.\nAttempts to maintain this chant are made with advantage.",
        castWithAdv: true,
        popularityWithClass: [1]
    },
    {
        name: "Gift of Water",
        tierByClass: [3],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You call on the water spirits, who enchant all allies in a near radius centered around you. As an action, they can unleash a forceful spray of water from an extended hand, dousing flames and pushing enemies straight back from close to near with a successful ranged attack roll. Targets can withstand the water spray by making a STR check vs. the attack roll.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Leaves in the Breeze",
        tierByClass: [3],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Your chant invokes a gentle breeze that slows the descent of you and your falling allies a near distance from you, allowing you to float safely to the ground.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Lure of the Piper",
        tierByClass: [4],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Your enchanting performance compels enemies in a near distance from you to abandon their actions and follow you obediently wherever you lead.\n Beguiled creatures make a WIS check vs. your last chanting check on their turn to resist.\n If you stop moving, or if you or your allies actively harm a beguiled creature, the beguilement ends.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Song of Resistance",
        tierByClass: [3],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You name a type of hazard, and you and your allies within a near area from you receive half damage from that source. Examples include fire, electricity, acid, necrosis, poison, cold, and disease, but does not include general magic resistance or physical damage resistance.",
        castWithAdv: false,
        popularityWithClass: [1]
    },

    {
        name: "Bite of the Mantis",
        tierByClass: [4],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You invoke the fierce spirit of the mantis. Enemies a near distance from you who damage you or your allies inflict an additional half damage to themselves.\nTargets know this damage is coming from you.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Divine Intervention",
        tierByClass: [2],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Your revered ancestors intercede on your behalf. All allies within a near distance from you may reroll 1s, keeping the second result.\nThis applies to all rolls, including damage dice.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Gift of Fire",
        tierByClass: [4],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You cause the wielded weapons of your allies in a near radius centered on you to leap into flame. These weapons do an additional 1d4 fire damage on successful hits. Ranged weapons are set on fire for each round the chant continues.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Song of Immunity",
        tierByClass: [4],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You name a type of hazard, and you and your allies within a near area from you become immune to that source.\nExamples include fire, electricity, acid, necrosis, poison, cold, and disease, but does not include general magic immunity or physical damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "The Sky is Falling",
        tierByClass: [4],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Your spirit guides deem your enemies unworthy, releasing falling debris from the sky to strike them in a near area centered on you.\nAll enemies must make a DEX check vs. your last chanting check to dodge or each takes 2d6 damage.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Vault of the Flea",
        tierByClass: [4],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You bestow upon your allies within near range from you the agility of the flea, enabling them to effortlessly bound to any location within a near cube as their movement action.",
        castWithAdv: false,
        popularityWithClass: [1]
    },

    {
        name: "Fury of the Spirits",
        tierByClass: [5],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "The spirits favor your cause. Your allies roll double damage dice when successfully hitting enemies a near distance from you with physical attacks.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Gift of Air",
        tierByClass: [5],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Allies in a near area sprout majestic eagle wings, gifting them with flight. Allies can fly a near distance on their turn. You have ADV on maintenance checks for this chant.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Living Revelation",
        tierByClass: [5],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Far",
        desc: "Your rhythmic chant pierces through walls and doors, revealing the presence of living creatures beyond. You can determine their approximate size, shape, and location.\nThe chant reveals creatures behind walls up to a far distance, but the sound cannot penetrate through double walls.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Poseidon's Passage",
        tierByClass: [5],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Far",
        desc: "You call on the water spirits to part the sea. You create passages or hold back the tide in a double near cube within the chant's range.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Primal Purge",
        tierByClass: [5],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "You compel your allies within near range from you to expel any curses, enchantments, possessions, poison, disease, or parasites.",
        castWithAdv: false,
        popularityWithClass: [1]
    },
    {
        name: "Shaman's Shield",
        tierByClass: [5],
        classes: ["Shaman"],
        duration: "Focus",
        range: "Near",
        desc: "Harnessing the power of ancestral shamans, you conjure a shield of protection against hostile magic out to a near-sized cube centered on yourself. Such magic cannot affect or harm you or your allies inside the shield.",
        castWithAdv: false,
        popularityWithClass: [1]
    }
];

export const spells = theSpells;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import SpecialComponents from "./../special/specialComponents";
import { Ancestry } from "../../data/ancestries";
import { Bonus } from "../../data/bonus";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Language } from "../../data/languages";
import { Spell } from "../../data/spells";
import { User } from "firebase/auth";
import { faDiceSix } from '@fortawesome/free-solid-svg-icons';
import { weapons } from "../../data/weapons";

interface IProps {
    user: User | undefined | null;
    bonuses: Bonus[];
    ancestries: Ancestry[];
    selectedAncestry: string;
    activeSources: string[];
    spellsKnown: Spell[];
    languagesKnown: Language[];
    setAncestry: (ancestry: string) => void;
    rollAncestry: () => void;
    onSetBonus: (bonus: Bonus) => void;
    onSetBonuses: (bonuses: Bonus[]) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialCategoryName: string) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    minimal: boolean;
    isCheatMode: boolean;
    characterIsSaved: boolean;    
    openAnimalModal: boolean;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}


const SelectAncestry: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getOptions = () => {
        const availableAncestries = props.ancestries.filter((a) => props.activeSources.indexOf(a.sourceId) !== -1);
        const options = availableAncestries.map((a) => <option value={a.name} key={a.name}>{a.name}</option>);
        options.unshift(<option value="" key="selectAncestry">-- select ancestry --</option>);
        return options;
    }

    const onSelectAncestry = (e: any) => {
        props.setAncestry(e.target.value);
    }

    const getExtras = (ancestry: Ancestry) => {
        const out: any = [];
        ancestry.extras.forEach((a) => {
            out.push(<div key={a.name}><b>{a.name}:</b> {a.desc}</div>);
        })
        return <>{out}</>
    }

    const getSpecialComponents = (ancestry: Ancestry) => {
        if (ancestry.specialBonuses.length > 0) {
            let out: any[] = [];
            if (!props.minimal) { out.push(<h3 key={"ancestryOptions"} className="mt-2 clearfix">Ancestry Options</h3>); }
            ancestry.specialBonuses.forEach((sb) => {
                if (sb.gainedAtLevel === 0) {
                    const levelOne = globalContext.levels.find((l) => l.level === 1);
                    const weaponsForMastery = [...weapons];
                    if (levelOne !== undefined) {
                        out.push(
                            <SpecialComponents
                                componentName={sb.specialComponentName}
                                currentLevel={1}
                                bonuses={props.bonuses}
                                level={levelOne}
                                characterIsSaved={props.characterIsSaved}
                                boonPatron={""}
                                boonSource={""}
                                onSetBonus={props.onSetBonus}
                                onSetBonuses={props.onSetBonuses}
                                onSetSpecialTalent={props.onSetSpecialTalent}
                                languages={ancestry.choicesOfExtraLanguage}
                                languagesKnown={props.languagesKnown}
                                weapons={weaponsForMastery}
                                sourceType={sb.specialSourceType}
                                sourceName={sb.specialSourceName}
                                sourceCategory={sb.specialSourceCategory}
                                label={sb.specialLabel}
                                spellTier={sb.specialTier}
                                spellInTier={sb.specialNumber}
                                spellsKnown={props.spellsKnown}
                                key={sb.specialComponentName}
                                minimal={props.minimal}
                                isCheatMode={props.isCheatMode}
                                // Warlock Boon Talents only
                                isAmbitionTalent={false}
                                // theClass={globalContext.charClass}
                                onSetRolledTalent={props.onSetRolledTalent}
                                onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                                openAnimalModal={props.openAnimalModal}
                                onOpenModal={(modal: string) => props.onOpenModal(modal)}
                                onCloseModal={(modal: string) => props.onCloseModal(modal)}
                            />
                        );
                    }
                }
            })
            return <>{out}</>;
        }
        return null;
    }

    const getAncestryDesc = () => {

        const ancestry = props.ancestries.find((a) => a.name === props.selectedAncestry);
        if (ancestry) {

            const langPlural = ancestry.languages.length === 1 ? "language" : "languages";

            const toOxfordComma = (array: string[]) =>
                array.length === 2
                    ? array.join(' and ')
                    : array.length > 2
                        ? array
                            .slice(0, array.length - 1)
                            .concat(`and ${array.slice(-1)}`)
                            .join(', ')
                        : array.join(', ');


            const getMinHeight = () => {
                if (ancestry.imageURL) {
                    return "185px";
                }
                return "1rem";
            }

            let className = "";
            if (ancestry.imageURL) { className = "ancestryDiv" }

            let ancestryDivClassName = "twoCols";
            // if(ancestry.imageURL) {
            //     ancestryDivClassName = ancestryDivClassName + "ancestryImgMinHeight";
            // }


            return (
                <div className={className}>
                    {!props.minimal &&
                        <>
                            {/* {ancestry.isUnnaturalSelection &&
                                <div className="mb-2"><UnnaturalSelectionLink user={props.user} item={ancestry.name} /></div>
                            } */}

                            {(ancestry.sourceNote !== "" && ancestry.hasOwnProperty("sourceURL")) &&
                                <div className="small"><i><a href={ancestry.sourceURL} target="_blank" rel="noreferrer">{ancestry.sourceNote}</a></i></div>
                            }
                            {(ancestry.sourceNote !== "" && !ancestry.hasOwnProperty("sourceURL")) &&
                                <div className="small"><i>{ancestry.sourceNote}</i></div>
                            }

                            <div className={ancestryDivClassName} style={{ minHeight: getMinHeight() }}>
                                {ancestry.imageURL &&
                                    <img src={ancestry.imageURL} alt={ancestry.name} width={ancestry.imageSize[0] + "px"} height={ancestry.imageSize[1] + "px"} className="ancestryImg" />
                                }
                                <div>
                                    {ancestry.desc}
                                    {ancestry.name === "Gnome" &&
                                        <span className="gnomeComment"> This is under protest, dammit! SWoonNe </span>
                                    }
                                </div>
                                <div><b>Languages: </b>You know the {toOxfordComma(ancestry.languages)} {langPlural}.</div>
                                {getExtras(ancestry)}
                            </div>
                        </>
                    }
                    {getSpecialComponents(ancestry)}

                </div>
            )
        }
        return null;
    }

    const getAncestryTitle = () => {
        if (props.selectedAncestry !== "") {
            return ": " + props.selectedAncestry;
        }
        return null;
    }

    const selectCss = props.selectedAncestry === "" ? "form-select redBorder" : "form-select";

    return (
        <div className="sectionBorder">
            <h2>Ancestry{getAncestryTitle()}</h2>
            <div className="">

                <div className="input-group">
                    <select className={selectCss} onChange={(e) => onSelectAncestry(e)} value={props.selectedAncestry}>{getOptions()}</select>
                    <button className="btn btn-dark" onClick={() => props.rollAncestry()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
                </div>

                {props.selectedAncestry === "" &&
                    <div className="valError">Please select an ancestry</div>
                }

                <div>{getAncestryDesc()}</div>

            </div>
        </div>
    )

}

export default SelectAncestry;
import { Link } from "react-router-dom";
import Spinner from "./spinner";

interface IProps {
    isLoggedIn: boolean;
    saveCharacter: () => void;
    cancel: () => void;
    isLoading: boolean;
    characterAlreadyExists: boolean;
    numCharacters: number;
    maxCharacters: number;
    name: string;
}

const SaveCharacter: React.FunctionComponent<IProps> = (props: IProps) => {

    const saveCharacter = props.saveCharacter;

    const isDisabled = (props.name.trim() === "") || (!props.characterAlreadyExists && props.numCharacters >= props.maxCharacters);

    const getDisabledMessage = () => {
        if (isDisabled) {
            if (props.name.trim() === "") {
                return (<span className="ms-3">You must name the character before saving.</span>);
            } else {
                return (<span className="ms-3">You have reached the maximum of {props.maxCharacters} saved characters.</span>);
            }
        }

        return null;
    }

    // Logged in:
    if (props.isLoggedIn) {
        return (
            <div className="row">
                <div className="">
                    <button onClick={() => saveCharacter()} className="btn btn-dark" disabled={isDisabled}>Save Character</button>
                    {props.isLoggedIn &&
                        <button onClick={() => props.cancel()} className="btn btn-secondary ms-2">Cancel</button>
                    }
                    <Spinner isLoading={props.isLoading} />
                    {getDisabledMessage()}
                </div>
            </div>
        )
    }

    // Not logged in:
    return (
        <div className="row">
            <div className="mb-3">
                <button onClick={() => saveCharacter()} className="btn btn-dark" disabled={true}>Save Character</button>
                <Spinner isLoading={props.isLoading} />
                <span className="ms-3"><Link to="/registerAccount">Register for an account</Link> to save your characters.</span>
            </div>
        </div>
    )

}

export default SaveCharacter;

import fontkit from '@pdf-lib/fontkit';
import { Ancestry } from './../data/ancestries';
import { Armor } from '../data/armor';
import { Bonus } from '../data/bonus';
import { CharClass } from './../data/classes';
import { Cheat } from '../data/cheats';
import { GearOwned } from './../data/gear';
import { MagicItem } from '../data/magicItem';
import { PDFDocument } from 'pdf-lib'
import { Spell } from '../data/spells';
import { Treasure } from '../data/treasure';
import { Weapon } from '../data/weapons';
import { addNone, getGearRecordsAsSlots } from '../data/utilities';
import { modStr, Stats } from '../data/stats';
import { getBonusesDesc } from './bonusText';
import { addExtraPages } from './extraPages';
import { Creature } from '../data/animals';

export const getPDFFillable = async (name: string, ancestry: Ancestry | undefined, stats: Stats, xp: number, level: number, title: string, alignment: string, background: string, deity: string, languages: string, spells: Spell[], charClass: CharClass | undefined, bonuses: Bonus[], hitPoints: number, ac: number, gearCarried: GearOwned[], treasuresCarried: Treasure[], magicItemsCarried: MagicItem[], gold: number, silver: number, copper: number, gearSlots: number, attacks: string[], highLegibility: boolean, weapons: Weapon[], armors: Armor[], animalCompanions: Creature[], spellCastingBonus: number, demonicPossessionBonus: number, edits: Cheat[], weaponsPermitted: Weapon[], armorPermitted: Armor[]) => {

    // Fetch the PDF with form fields
    const formUrl = 'https://www.shadowdarklings.net/ShadowDarkFillable.pdf';
    const formBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

    // Fetch the Ubuntu font
    const fontUrl = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf';
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());

    // Load the PDF with form fields
    const pdfDoc = await PDFDocument.load(formBytes);

    // Embed the Ubuntu font
    pdfDoc.registerFontkit(fontkit);
    const ubuntuFont = await pdfDoc.embedFont(fontBytes);

    // Get fields
    const form = pdfDoc.getForm();
    const nameField = form.getTextField("Name");
    const ancestryField = form.getTextField("Race");
    const classField = form.getTextField("Class");
    const levelField = form.getTextField("Level");
    const xpCurrentField = form.getTextField("XP Current");
    const xpTargetField = form.getTextField("XP Target");
    const titleField = form.getTextField("Title");
    const alignmentField = form.getTextField("Alignment");
    const backgroundField = form.getTextField("Background");
    const deityField = form.getTextField("Deity");

    const strengthField = form.getTextField("Strength Total");
    const strengthModField = form.getTextField("Strength Modifier");

    const dexterityField = form.getTextField("Dexterity Total");
    const dexterityModField = form.getTextField("Dexterity Modifier");

    const constitutionField = form.getTextField("Constitution Total");
    const constitutionModField = form.getTextField("Constitution Modifier");

    const intelligenceField = form.getTextField("Intelligence Total");
    const intelligenceModField = form.getTextField("Intelligence Modifier");

    const wisdomField = form.getTextField("Wisdom Total");
    const wisdomModField = form.getTextField("Wisdom Modifier");

    const charismaField = form.getTextField("Charisma Total");
    const charismaModField = form.getTextField("Charisma Modifier");
    const hitPointsField = form.getTextField("Hit Points");
    const acField = form.getTextField("Armor Class");

    const attacksField = form.getTextField("Attacks");
    const talentsField = form.getTextField("Talents / Spells");

    // get values

    const charClassName = charClass ? charClass.name : "None";
    const ancestryName = ancestry ? ancestry.name : "None";
    let xpToLevel = "n/a";
    if (charClass?.name !== "Level 0") {
        xpToLevel = (level * 10).toString();
    }

    const strengthMod = modStr(stats.Strength).toString();
    const dexterityMod = modStr(stats.Dexterity).toString();
    const constitutionMod = modStr(stats.Constitution).toString();
    const intelligenceMod = modStr(stats.Intelligence).toString();
    const wisdomMod = modStr(stats.Wisdom).toString();
    const charismaMod = modStr(stats.Charisma).toString();


    const formatAttacks = () => {
        const out: string[] = [];
        attacks.forEach((a) => { out.push(a) })
        return out.join(`\n \n`);
    }

    // Talents

    let classWeapons = "Class not selected";
    let classArmor = "Class not selected";
    if (charClass) {
        classWeapons = weaponsPermitted.map((w) => w.name).join(", ");
        if (charClass.weapons === "All weapons") { classWeapons = "All weapons" };
        classArmor = armorPermitted.map((a) => a.name).join(", ");
        if (charClass.armor === "All armor and shields") { classArmor = "All armor and shields" };
    }
    classWeapons = addNone(classWeapons);
    classArmor = addNone(classArmor);

    const formatSpellsKnownAsList = () => {
        if (spells.length === 0) { return "None"; }

        let out: string[] = [];
        spells.forEach((sp) => {
            let desc = sp.name;

            const castWithAdv = sp.castWithAdv;

            let castWithMastery = false;
            const spellMastery = bonuses.find((b) => b.name === "AdvOnCastOneSpell" && b.bonusName === sp.name);
            if (spellMastery) { castWithMastery = true; }

            if (castWithAdv || castWithMastery) {
                desc = desc + " (ADV)";
            }

            let learnedFromScroll = false;
            const learnScroll = bonuses.find((b) => b.bonusTo === "LearnSpellFromScroll" && b.bonusName === sp.name);
            if (learnScroll) { learnedFromScroll = true; }

            if (learnedFromScroll) {
                desc = desc + " (scroll)";
            }

            out.push(desc);
        })
        return out.join(", ");
    }

    let spellsText = formatSpellsKnownAsList();
    const spellOrChant = charClass && charClass.name === "Shaman" ? "CHANTS" : "SPELLS";
    if (spellsText.trim() !== "None") { spellsText = spellOrChant + ": " + spellsText; }

    let talents: string[] = [];

    talents.push(`WEAPONS: ${classWeapons}; ARMOR: ${classArmor}`);

    talents.push(`LANGUAGES: ${languages}`);

    talents = [...talents, ...getBonusesDesc(ancestry, charClass, demonicPossessionBonus, spellCastingBonus, bonuses, level)];
    if (spellsText !== "None") {
        talents.push(`${spellsText}`);
    }

    // Gear
    let gearRecords = getGearRecordsAsSlots(armors, gearCarried, treasuresCarried, magicItemsCarried, gold, silver, copper);

    gearRecords = gearRecords.sort((g1, g2) => {
        if (g1.type === "armor" && g2.type === "weapon") {
            return -1;
        }
        if (g1.type === "armor" && g2.type === "sundry") {
            return -1;
        }
        if (g1.type === "armor" && g2.type === "armor") {
            return g1.name < g2.name ? -1 : 1;
        }

        if (g1.type === "weapon" && g2.type === "sundry") {
            return -1;
        }
        if (g1.type === "weapon" && g2.type === "weapon") {
            return g1.name < g2.name ? -1 : 1;
        }

        if (g1.type === "sundry" && g2.type === "sundry") {
            return g1.name < g2.name ? -1 : 1;
        }

        return 0;
    });

    let rowCount = 0;
    const zeroSlotItems: string[] = [];

    for (let g = 0; g < gearRecords.length; g = g + 1) {

        const thisRecord = gearRecords[g];

        if (thisRecord.slots !== 0) {

            let slotNum = 0;
            for (let s = 0; s < thisRecord.slots; s++) {

                slotNum = slotNum + 1;
                const dispSlotNum = thisRecord.slots > 1 ? slotNum : 0;

                if (rowCount < 20) {
                    let openPara = "";
                    let closePara = "";
                    if (dispSlotNum > 1) {
                        openPara = "(";
                        closePara = ")";
                    }
                    const numItems = thisRecord.totalUnits > 1 ? " (" + thisRecord.totalUnits + ")" : "";
                    const gearField = form.getTextField("Gear " + (rowCount + 1));

                    let gearText = openPara + thisRecord.name + numItems + closePara;
                    if (gearText.length > 20) { gearText = gearText.substring(0, 20) + "..."; }
                    gearField.setText(gearText);
                }
                rowCount = rowCount + 1;
            }
        } else {
            zeroSlotItems.push(thisRecord.name);
        }

    }

    // Free to carry items in sidebar

    let freeToCarry: string[] = [];

    zeroSlotItems.forEach((f) => {
        freeToCarry.push(f);
    })

    if (charClass?.freeToCarryItems) {
        charClass?.freeToCarryItems.forEach((i) => {
            freeToCarry.push(i);
        })
    }

    // free to carry gold/silver/copper
    const totalCoins = gold + silver + copper;
    if (totalCoins > 0) {
        freeToCarry.push("Bag of Coins (" + Math.min(totalCoins, 100) + ")");
    }


    if (freeToCarry.length > 0) {
        const freeToCarryField = form.getTextField("Free To Carry");
        freeToCarryField.setText(freeToCarry.join(`\n`));
    }

    // Strike out unavailable gear slots
    let slotNum = 0;
    rowCount = 0;
    for (let g = 0; g < 20; g = g + 1) {
        slotNum = slotNum + 1;
        if (rowCount >= gearSlots) {
            const gearField = form.getTextField("Gear " + (rowCount + 1));
            gearField.setText('XXXXXXXXXXXXXXXX');
        }
        rowCount = rowCount + 1;
    }

    // Money
    if (gold > 0) {
        const goldField = form.getTextField("Gold Pieces");
        goldField.setText(gold.toString());
    }
    if (silver > 0) {
        const silverField = form.getTextField("Silver Pieces");
        silverField.setText(silver.toString());
    }
    if (copper > 0) {
        const copperField = form.getTextField("Copper Pieces");
        copperField.setText(copper.toString());
    }

    // set fields

    nameField.setText(name);
    ancestryField.setText(ancestryName);
    classField.setText(charClassName);
    levelField.setText(level.toString());

    xpCurrentField.setText(xp.toString());
    xpTargetField.setText(xpToLevel);
    titleField.setText(title);
    alignmentField.setText(alignment);
    backgroundField.setText(background);
    deityField.setText(deity);

    strengthField.setText(stats.Strength.toString());
    strengthModField.setText(strengthMod);

    dexterityField.setText(stats.Dexterity.toString());
    dexterityModField.setText(dexterityMod);

    constitutionField.setText(stats.Constitution.toString());
    constitutionModField.setText(constitutionMod);

    intelligenceField.setText(stats.Intelligence.toString());
    intelligenceModField.setText(intelligenceMod);

    wisdomField.setText(stats.Wisdom.toString());
    wisdomModField.setText(wisdomMod);

    charismaField.setText(stats.Charisma.toString());
    charismaModField.setText(charismaMod);

    hitPointsField.setText(hitPoints.toString());
    acField.setText(ac.toString());

    attacksField.setText(formatAttacks());
    talentsField.setText(talents.join(`\n \n`));

    if (edits.length > 0) {
        const page = pdfDoc.getPage(0);
        page.moveTo(27, 15);
        page.drawText("Edits: " + edits.map((c) => c.desc).join("; "), { size: 7, maxWidth: 580, lineHeight: 7 });
    }

    let className = "";
    if (charClass?.name) { className = charClass?.name };

    await addExtraPages(
        pdfDoc,
        ubuntuFont,
        charClass,
        className,
        bonuses,
        spellsText,
        spells,
        treasuresCarried,
        magicItemsCarried,
        weapons,
        armors,
        animalCompanions
    )

    // **Key Step:** Update the field appearances with the Ubuntu font
    form.updateFieldAppearances(ubuntuFont);

    // Save the PDF with filled form fields
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}
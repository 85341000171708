import { useContext, useState } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getWarlockTalentRollTwoBoonsAndPickOneBoonBonus } from "../../data/utilities";
import { getRandomIntInclusive } from "../../data/random";
import SelectTalentDetails from "./selectTalentDetails";
import { Spell } from "../../data/spells";
import { Talent } from "../../data/classes";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonSource: string;
    isAmbitionTalent: boolean;
    isMinimal: boolean;
    isCheatMode: boolean;
    spellsKnown: Spell[];
    setBonus: (bonus: Bonus) => void;
    level: Level;
    // setRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;

}

const RollTwoRandomBoons: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getRandomBoon = () => {
        const thePatron = globalContext.availableWarlockPatrons.find((p) => p.name.toUpperCase() === globalContext.patron.toUpperCase())
        if (thePatron) {
            const randomPatronBoons = globalContext.availablePatronBoons.find((pb) => pb.patronId === thePatron.id);
            if (randomPatronBoons) {
                const roll = getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 5); // NOTE: only has range 2 - 11
                const theTalent = randomPatronBoons.talents.find((t) => roll >= t.min && roll <= t.max);
                if (theTalent) { return theTalent }
            }
        }
        return undefined;
    }

    const rollForRandomBoons = () => {
        const randomBoons: Talent[] = [];
        const randomBoon1 = getRandomBoon();
        if (randomBoon1) { randomBoons.push(randomBoon1); }
        const randomBoon2 = getRandomBoon();
        if (randomBoon2) { randomBoons.push(randomBoon2); }
        setRandomBoons(randomBoons);
    }

    const [randomBoons, setRandomBoons] = useState([] as Talent[]);

    const selectBoon = (theTalent: Talent) => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: "Boon",
            gainedAtLevel: props.level.level,
            name: theTalent.name,
            bonusName: theTalent.name,
            boonPatron: globalContext.patron,
            boonSource: "WarlockTalentRollTwoBoonsAndKeepOne",
            bonusTo: ""
        };
        props.setBonus(bonus);
    }

    const getSelectedBoon = () => {
        const randomBoon = getWarlockTalentRollTwoBoonsAndPickOneBoonBonus(globalContext.bonuses, props.sourceType, props.sourceName, "Boon", props.level.level)
        return randomBoon;
    }

    const selectedBoon = getSelectedBoon();

    let selectedBoonDesc: string | undefined;
    let patronTalents:Talent[] = [];
    const thePatron = globalContext.availableWarlockPatrons.find((p) => p.name.toUpperCase() === globalContext.patron.toUpperCase())
    if (thePatron) {
        const patronTalents = globalContext.availablePatronBoons.find((pb) => pb.patronId === thePatron.id);
        selectedBoonDesc = patronTalents === undefined ? "" : patronTalents.talents.find((p) => p.name === selectedBoon?.bonusName)?.desc;
    }

    return (
        <>

            {(randomBoons.length === 0 && globalContext.patron === "") &&
                <div className="valError">Please select a patron</div>
            }

            {(randomBoons.length === 0 && globalContext.patron !== "") &&
                <div >
                    <button className="btn btn-dark" onClick={() => rollForRandomBoons()}>Roll for Random Boons</button>
                    <div className="valError">Please roll for two random boons</div>
                </div>
            }

            {randomBoons.length > 0 &&
                <>
                    {randomBoons[0] !== undefined &&
                        <div>
                            <label><input type="radio" checked={selectedBoon?.name === randomBoons[0].name} onChange={() => selectBoon(randomBoons[0])} /> {randomBoons[0].desc}</label>
                        </div>
                    }
                    {randomBoons[1] !== undefined &&
                        <div>
                            <label><input type="radio" checked={selectedBoon?.name === randomBoons[1].name} onChange={() => selectBoon(randomBoons[1])} /> {randomBoons[1].desc}</label>
                        </div>
                    }
                </>
            }

            {selectedBoon !== undefined &&
                <>
                    <div><b>Boon from {globalContext.patron}:</b> {selectedBoonDesc}</div>
                    <div>
                        <SelectTalentDetails
                            isSpecialTalent={true}
                            thisLevel={props.level}
                            specialTalents={patronTalents}
                            sourceType={props.sourceType}
                            sourceName={props.sourceName}
                            boonPatron={globalContext.patron}
                            boonSource="WarlockTalentRollTwoBoonsAndKeepOne"
                            sourceCategory={"Boon"}
                            isAmbitionTalent={props.isAmbitionTalent}
                            isCheatMode={props.isCheatMode}
                            isMinimal={props.isMinimal}
                            theClass={"Warlock"}
                            spellsKnown={props.spellsKnown}
                            includeSelectTalentOption={true}
                            onSetBonus={(bonus: Bonus) => props.setBonus(bonus)}
                            onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                            onSetRolledTalent={props.onSetRolledTalent}
                            onSetSpecialTalent={props.onSetSpecialTalent}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default RollTwoRandomBoons;
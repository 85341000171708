import uniqid from 'uniqid';
import { Ancestry } from '../data/ancestries';
import { Armor } from '../data/armor';
import { Background } from '../data/backgrounds';
import { BlackLotusTalents, CharClass, PyromancyTalents, SpecialBonus, Talent } from '../data/classes';
import { Bonus } from '../data/bonus';
import { Creature } from '../data/animals';
import { Deity } from '../data/deities';
import { DogAbility } from '../data/dogs';
import { DogsLife, dogsLifes } from './../data/dogs';
import { DuckAbility, DuckQuackstory, duckQuackstories } from './../data/ducks';
import { GearOwned } from '../data/gear';
import { Language, getAllLanguages } from '../data/languages';
import { Level } from '../data/level';
import { PatronBoon } from '../data/warlockBoons';
import { Spell } from '../data/spells';
import { Stats, getStatsIncludingBonuses, modNum } from '../data/stats';
import { Sundry } from '../data/sundries';
import { WarlockPatron } from '../data/warlockPatrons';
import { Weapon } from '../data/weapons';
import { animalTypes, getAllSpellsKnown, getPermittedArmorsByClassName, getPermittedWeaponsByClassName, getSpellTierForClass, getStatByShortName, utilAddTalentBonuses } from '../data/utilities';
import { elixirs, elixirsWithDice } from './../data/elixirs';
import { getRandomAlignment, getRandomAncestry, getRandomDeity, getRandomName } from '../components/charGen/utilities/roll';
import { getRandomIntInclusive } from './../data/random';

const getRandomBackground = (backgrounds: Background[]) => {
    const numBackgrounds = backgrounds.length;
    const randBGRoll = getRandomIntInclusive(0, numBackgrounds - 1);
    return backgrounds[randBGRoll];
}

const roll3d6 = () => {
    return getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6);
}

export const getRandomStats = (ensureAtLeastOneis14L: boolean, hardCodeINTForPlagueDoc: boolean): Stats => {

    const stats: Stats = {
        Strength: roll3d6(),
        Dexterity: roll3d6(),
        Constitution: roll3d6(),
        Intelligence: roll3d6(),
        Wisdom: roll3d6(),
        Charisma: roll3d6(),
    };

    if (ensureAtLeastOneis14L && stats.Strength < 14 && stats.Dexterity < 14 && stats.Constitution < 14 && stats.Intelligence < 14 && stats.Wisdom < 14 && stats.Charisma < 14) {
        return getRandomStats(ensureAtLeastOneis14L, hardCodeINTForPlagueDoc);
    }

    if (hardCodeINTForPlagueDoc) {
        if (stats.Intelligence < 14) {
            stats.Intelligence = 14;
        }
    }

    return stats;
}

export const getClassLottery = (stats: Stats, charClasses: CharClass[]) => {

    let lottery: any = {};

    charClasses.forEach((theClass) => {
        // Fill the class lottery with classes based on the character's stats and stat weights for the class. 

        if (theClass.name !== "Level 0" && theClass.name !== "Roustabout") {

            lottery[theClass.name] = 0;

            const getClassStatWeight = (stat: string) => {
                let statWeight = 1;
                const classStatWeight = theClass.classStatWeights.find((csw) => csw.stat === stat);
                if (classStatWeight) {
                    statWeight = classStatWeight.weight;
                }
                return statWeight;
            }

            const effStat = (stat: number) => {
                return modNum(stat);
                // return stat;
            }

            lottery[theClass.name] = lottery[theClass.name] + effStat(stats.Strength) * getClassStatWeight("STR");
            lottery[theClass.name] = lottery[theClass.name] + effStat(stats.Dexterity) * getClassStatWeight("DEX");
            lottery[theClass.name] = lottery[theClass.name] + effStat(stats.Constitution) * getClassStatWeight("CON");
            lottery[theClass.name] = lottery[theClass.name] + effStat(stats.Intelligence) * getClassStatWeight("INT");
            lottery[theClass.name] = lottery[theClass.name] + effStat(stats.Wisdom) * getClassStatWeight("WIS");
            lottery[theClass.name] = lottery[theClass.name] + effStat(stats.Charisma) * getClassStatWeight("CHA");
        }
    })

    // return selectedClass;

    return lottery; // classLottery;
}

interface result { charClass: string, percent: number; }

export const getRawPercentages = (classLottery: any, classes: CharClass[]) => {

    let results: result[] = [];

    const getPercent = (x: any) => {
        const num = parseInt(x, 10);
        if (num === 0) { return 0 }
        return Math.round(num / total * 100)
    }

    let total = 0;
    if (classLottery) {

        const classNames = classes.map((c) => c.name);

        classNames.forEach((className) => {
            if (parseInt(classLottery[className], 10) > 0) { total = total + classLottery[className] };
        })

        classNames.forEach((className) => {
            if (parseInt(classLottery[className], 10) > 0) { results.push({ charClass: className, percent: getPercent(classLottery[className]) }) };
        })
    }

    // randomise the order of items in the results to remove alphabetical name bias that biases handling tied percentages
    results = fisherYatesShuffle(results);

    // then order by percent
    results = results.sort((r1, r2) => {
        if (r1.percent < r2.percent) {
            return 1
        } else if (r1.percent > r2.percent) {
            return -1
        }
        return 0;
    });
    return results;
}

const fisherYatesShuffle = (array: result[]): result[] => {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const getPercentages = (classLottery: any, classes: CharClass[]) => {

    const results = getRawPercentages(classLottery, classes);

    if (results.length === 0) { return "No clear recommendation"; }

    return results.map((r) => r.charClass + ": " + r.percent + "%").join(", ");
}

export const assignAncestryBonuses = (theAncestry: string, theClass: string, languages: Language[], duckAbilities: DuckAbility[], duckQuackstories: DuckQuackstory[], dogAbilities: DogAbility[], dogsLifes: DogsLife[]) => {

    const newBonuses: Bonus[] = [];

    if (theAncestry === "Elf") {
        // pick +1 spellcasting or +1 ranged weapons
        if (theClass === "Priest" || theClass === "Wizard" || theClass === "Witch" || theClass === "Seer" || theClass === "Grave Warden" || theClass === "Ovate" || theClass === "Shaman") {
            const bonus: Bonus = {
                sourceType: "Ancestry",
                sourceName: "Elf",
                sourceCategory: "Ability",
                gainedAtLevel: 1,
                name: "FarSight",
                bonusName: "Plus1ToCastingSpells",
                bonusTo: "Spellcasting",
                bonusAmount: 1
            };
            newBonuses.push(bonus);
        } else {
            const bonus: Bonus = {
                sourceType: "Ancestry",
                sourceName: "Elf",
                sourceCategory: "Ability",
                gainedAtLevel: 1,
                name: "FarSight",
                bonusName: "AttackBonus",
                bonusTo: "RangedWeapons",
                bonusAmount: 1
            };
            newBonuses.push(bonus);
        }
    }

    if (theAncestry === "Human") {

        const commonLanguages = languages.filter((l) => l.category === "Common" && l.name !== "Common");
        const roll = getRandomIntInclusive(0, commonLanguages.length - 1);

        let name = "ExtraLanguage: Human undefined";

        const bonus: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Human",
            sourceCategory: "Ability",
            gainedAtLevel: 1,
            name: name,
            bonusTo: "Languages",
            bonusName: commonLanguages[roll].name,
        };
        newBonuses.push(bonus);
    }

    if (theAncestry === "Duck") {

        const duckAbilityRoll = getRandomIntInclusive(0, duckAbilities.length - 1);
        const theDuckAbility = duckAbilities[duckAbilityRoll];
        const bonus1: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Duck",
            sourceCategory: "DuckAbility",
            gainedAtLevel: 1,
            name: "Duck ability",
            bonusTo: theDuckAbility.desc,
            bonusName: theDuckAbility.name
        };
        newBonuses.push(bonus1);

        const quankstoryRoll = getRandomIntInclusive(0, duckQuackstories.length - 1);
        const theQuackstory = duckQuackstories[quankstoryRoll];
        const bonus2: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Duck",
            sourceCategory: "DuckQuackStory",
            gainedAtLevel: 1,
            name: "StatBonus",
            bonusTo: theQuackstory.statBonus + ":+1",
            bonusName: "StatBonus",
        };
        newBonuses.push(bonus2);
    }

    if (theAncestry === "Dog") {

        const dogAbilityRoll = getRandomIntInclusive(0, dogAbilities.length - 1);
        const theDogAbility = dogAbilities[dogAbilityRoll];
        const bonus1: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Dog",
            sourceCategory: "DogAbility",
            gainedAtLevel: 1,
            name: "Dog ability",
            bonusTo: theDogAbility.desc,
            bonusName: theDogAbility.name
        };
        newBonuses.push(bonus1);

        const dogsLifeRoll = getRandomIntInclusive(0, dogsLifes.length - 1);
        const theDogsLife = dogsLifes[dogsLifeRoll];
        const bonus2: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Dog",
            sourceCategory: "DogsLife",
            gainedAtLevel: 1,
            name: "StatBonus",
            bonusTo: theDogsLife.statBonus + ":+1",
            bonusName: "StatBonus",
        };
        newBonuses.push(bonus2);
    }

    if (theAncestry === "Kobold") {
        // pick +1 spellcasting or luck token
        if (theClass === "Priest" || theClass === "Wizard" || theClass === "Witch" || theClass === "Seer" || theClass === "Grave Warden" || theClass === "Ovate" || theClass === "Shaman") {
            const bonus: Bonus = {
                sourceType: "Ancestry",
                sourceName: "Kobold",
                sourceCategory: "Ability",
                gainedAtLevel: 1,
                name: "Knack",
                bonusName: "Plus1ToCastingSpells",
                bonusTo: "Spellcasting",
                bonusAmount: 1
            };
            newBonuses.push(bonus);
        } else {
            const bonus: Bonus = {
                sourceType: "Ancestry",
                sourceName: "Kobold",
                sourceCategory: "Ability",
                gainedAtLevel: 1,
                name: "Knack",
                bonusName: "LuckTokenAtStartOfSession",
                bonusTo: "LuckTokenAtStartOfSession",
                bonusAmount: 1
            };
            newBonuses.push(bonus);
        }
    }

    if (theAncestry === "Risen") {

        const risenLanguages = getAllLanguages().filter((a) => a.name === "Celestial" || a.name === "Diabolic");
        const roll = getRandomIntInclusive(0, risenLanguages.length - 1);

        let name = "ExtraLanguage: Risen undefined";

        const bonus: Bonus = {
            sourceType: "Ancestry",
            sourceName: "Risen",
            sourceCategory: "Ability",
            gainedAtLevel: 1,
            name: name,
            bonusTo: "Languages",
            bonusName: risenLanguages[roll].name,
        };
        newBonuses.push(bonus);
    }

    return newBonuses;
}

export const assignClassBonuses = (theStats: Stats, languages: Language[], ancestries: Ancestry[], theAncestry: string, theClass: string, charClasses: CharClass[], weapons: Weapon[], spells: Spell[], spellsKnown: Spell[], thisLevel: number, bonuses: Bonus[], activeSources: string[], patrons: WarlockPatron[], patronBoons: PatronBoon[], animals: Creature[], roustaboutSpells: Spell[]) => {

    const newBonuses: Bonus[] = [...bonuses];

    const charClass = charClasses.find((c) => c.name === theClass);
    if (charClass) {
        charClass.specialBonuses.filter((sb) => sb.gainedAtLevel === 1).forEach((sb) => {
            if (sb.specialName === "Weapon Mastery") { newBonuses.push(getWeaponMasteryBonus(theStats, charClasses, weapons, newBonuses)) }
            if (sb.specialName === "Grit") { newBonuses.push(getGritBonus()) }
            if (sb.specialName === "ExtraLanguage") {
                const bonLang = getExtraLanguageBonus(sb, languages, ancestries, theAncestry, newBonuses);
                if (bonLang) { newBonuses.push(bonLang) }
            }
            if (sb.specialName === "PickSpell") {
                const bonSpell = getSpellBonus(sb, spells, charClasses, theClass, newBonuses, roustaboutSpells);
                if (bonSpell) { newBonuses.push(bonSpell); }
            }
            if (sb.specialName === "BlackLotusTalent") {
                const bonBlackLotus = getBlackLotusBonus("Class", "Ras-Godai", "BlackLotusTalent_FreeAtClassLevel1", newBonuses);
                if (bonBlackLotus) { newBonuses.push(bonBlackLotus); }
            }
            if (sb.specialName === "SelectPatron") {
                const bonPatron = getPatron(patrons);
                if (bonPatron) { newBonuses.push(bonPatron); }
            }
            if (sb.specialName === "PatronBoon") {
                const bonBoon = getPatronBoon(patrons, patronBoons, theStats, spells, spellsKnown, thisLevel, newBonuses, roustaboutSpells);
                if (bonBoon) { newBonuses.push(bonBoon); }
            }
            if (sb.specialName === "Beast Master Animal Type") {
                const bonAnimalType = getAnimalTypeBonus();
                if (bonAnimalType) { newBonuses.push(bonAnimalType); }
            }
            if (sb.specialName === "PickAnimalCompanions") {
                // handled in addAnimalCompanionAndAnimalLanguage();
            }
            if (sb.specialName === "ExtraLanguageManual") {
                // handled in addAnimalCompanionAndAnimalLanguage();
            }
        })
    }

    return newBonuses;
}

const getWeaponMasteryBonus = (theStats: Stats, charClasses: CharClass[], weapons: Weapon[], bonuses: Bonus[]): Bonus => {
    // Assign a weapon mastery:
    // if DEX is better than STR (or has + to ranged weapons), then assign a ranged weapon, otherwise a melee weapon
    let weaponMasteryName = "";
    const hasRangedAttackBonus = bonuses.find((b) => b.bonusName === "AttackBonus" && b.bonusTo === "RangedWeapons");
    const rangedAttackBonus = hasRangedAttackBonus ? 1 : 0;
    if (modNum(theStats.Dexterity) + rangedAttackBonus > modNum(theStats.Strength)) {
        // assign a ranged weapon
        weaponMasteryName = getRandomRangedWeapon(weapons, bonuses, "Fighter", charClasses, false, false);
    } else {
        // assign a melee weapon
        weaponMasteryName = getRandomMeleeWeapon(weapons, bonuses, "Fighter", charClasses, false);
    }

    const bonus: Bonus = {
        sourceType: "Class",
        sourceName: "Fighter",
        sourceCategory: "Ability",
        gainedAtLevel: 1,
        name: "WeaponMastery",
        bonusName: "Plus1AttackAndDamagePlusHalfLevel",
        bonusTo: weaponMasteryName,
        bonusAmount: 1
    };
    return bonus;
}

const getRandomRangedWeapon = (weapons: Weapon[], bonuses: Bonus[], charClass?: string, CharClasses?: CharClass[], isBackupWeapon?: boolean, isThrown?: boolean, includeAllWeapons: boolean = false, excludeLearnedWeapons: boolean = false): string => {
    let weap = "";

    let rangedOrThrownWeapons: Weapon[] = []; // weapons.filter((w) => (w.ranges.indexOf("N") !== -1 || w.ranges.indexOf("F") !== -1 || w.thrown) && !w.isLash);

    // Limit by class (if class provided)
    if (charClass && CharClasses) {
        let thisClass = CharClasses.find((c) => c.name === charClass);
        if (thisClass) {
            let permittedWeapons = getPermittedWeaponsByClassName(CharClasses, weapons, bonuses, charClass, includeAllWeapons);
            rangedOrThrownWeapons = permittedWeapons.filter((w) => (w.ranges.indexOf("N") !== -1 || w.ranges.indexOf("F") !== -1 || w.thrown) && !w.isLash);

        }
    }

    // limit to main or backup weapon (if isBackupWeapon provided)
    if (isBackupWeapon !== undefined) {
        rangedOrThrownWeapons = rangedOrThrownWeapons.filter((mw) => mw.isBackupWeapon === isBackupWeapon);
    }

    // limit to thrown or proper ranged
    if (isThrown !== undefined) {
        rangedOrThrownWeapons = rangedOrThrownWeapons.filter((mw) => mw.thrown === isThrown);
    }

    if (rangedOrThrownWeapons) {
        const totalChances = rangedOrThrownWeapons.reduce((accumulator, currentValue) => accumulator + currentValue.weaponMasterPopularity, 0);
        const roll = getRandomIntInclusive(1, totalChances);
        let total = 0;
        rangedOrThrownWeapons.forEach((w) => {
            total = total + w.weaponMasterPopularity;
            if (roll <= total && weap === "") {
                weap = w.name;
            }
        })
    }

    // check if already has this weapon for Weapon Mastery
    const weaponMasteries = bonuses.filter((b) => b.name === "WeaponMastery").map((b) => b.bonusTo);
    if (weaponMasteries.indexOf(weap) !== -1) { return getRandomRangedWeapon(weapons, bonuses) };

    // check if already has this weapon for Learn Weapon or class's allowed weapons.
    if (excludeLearnedWeapons) {
        if (charClass && CharClasses) {
            let thisClass = CharClasses.find((c) => c.name === charClass);
            if (thisClass) {
                let permittedWeapons = getPermittedWeaponsByClassName(CharClasses, weapons, bonuses, charClass, false);
                if (permittedWeapons.map((w) => w.name).indexOf(weap) !== -1) { return getRandomRangedWeapon(weapons, bonuses, charClass, CharClasses, isBackupWeapon, isThrown, includeAllWeapons, excludeLearnedWeapons) };
            }
        }
    }

    return weap;
}

const getRandomArmor = (gold: number, armors: Armor[], bonuses: Bonus[], gear: GearOwned[], charClass?: string, CharClasses?: CharClass[], shieldsOnly?: boolean): string => {
    let availArmors = [...armors];

    // Limit by class (if class provided)
    if (charClass && CharClasses) {
        availArmors = getPermittedArmorsByClassName(CharClasses, charClass, [...armors], bonuses)
    }

    // no mithril, not worth it at 1st level
    availArmors = availArmors.filter(((a) => a.mithril === false));

    // limit by affordability
    availArmors = availArmors.filter(((a) => a.cost <= gold));

    // remove any type already owned
    availArmors = availArmors.filter((a) => gear.filter((g) => g.type === "armor").map((a) => a.id).indexOf(a.id) === -1);

    // limit to suits or shields only
    if (shieldsOnly !== undefined) {
        availArmors = availArmors.filter((a) => a.isShield === shieldsOnly);
    }

    // select the armor type
    if (availArmors.length > 0) {
        const totalChances = availArmors.length;
        const roll = getRandomIntInclusive(0, totalChances - 1);
        let theArmor = availArmors[roll];
        return theArmor.name;
    } else {
        return "None suitable";
    }
}

const getRandomMeleeWeapon = (weapons: Weapon[], bonuses: Bonus[], charClass?: string, CharClasses?: CharClass[], isBackupWeapon?: boolean, includeAllWeapons: boolean = false, excludeLearnedWeapons: boolean = false): string => {
    let weap = "";

    try {

        let weaponsAllowedToClass: Weapon[] = [];
        if (CharClasses && charClass) {
            weaponsAllowedToClass = getPermittedWeaponsByClassName(CharClasses, weapons, bonuses, charClass);
        }

        if (includeAllWeapons) {
            weaponsAllowedToClass = weapons;
        }

        let meleeWeapons = weaponsAllowedToClass.filter((w) => w.ranges.indexOf("C") !== -1 || w.isLash);

        // limit to main or backup weapon (if isBackupWeapon provided)
        if (isBackupWeapon !== undefined) {
            if (isBackupWeapon === true) {
                meleeWeapons = meleeWeapons.filter((mw) => mw.isBackupWeapon === true);
            }
            if (isBackupWeapon === false) {
                meleeWeapons = meleeWeapons.filter((mw) => mw.isBackupWeapon === false);
            }
        }

        if (meleeWeapons) {
            const totalChances = meleeWeapons.reduce((accumulator, currentValue) => accumulator + currentValue.weaponMasterPopularity, 0);
            const roll = getRandomIntInclusive(1, totalChances);
            let total = 0;
            meleeWeapons.forEach((w) => {
                total = total + w.weaponMasterPopularity;
                if (roll <= total && weap === "") {
                    weap = w.name;
                }
            })
        }
        // check if already has this weapon for Weapon Mastery
        const weaponMasteries = bonuses.filter((b) => b.name === "WeaponMastery").map((b) => b.bonusTo);
        if (weaponMasteries.indexOf(weap) !== -1) { return getRandomMeleeWeapon(weapons, bonuses) };

        // check if already has this weapon for Learn Weapon
        if (excludeLearnedWeapons) {
            if (charClass && CharClasses) {
                let thisClass = CharClasses.find((c) => c.name === charClass);
                if (thisClass) {
                    let permittedWeapons = getPermittedWeaponsByClassName(CharClasses, weapons, bonuses, charClass, false);
                    if (permittedWeapons.map((w) => w.name).indexOf(weap) !== -1) { return getRandomMeleeWeapon(weapons, bonuses, charClass, CharClasses, isBackupWeapon, includeAllWeapons, excludeLearnedWeapons) };
                }
            }
        }

    } catch (error) {
        console.log("get random melee weapon failed, charClass: " + charClass)
        return "";
    }

    return weap;
}

const getGritBonus = () => {
    // Assign Grit:
    const gritRoll = getRandomIntInclusive(1, 2);
    const gritStat = gritRoll === 1 ? "Strength" : "Dexterity";
    const gritBonus: Bonus = {
        sourceType: "Class",
        sourceName: "Fighter",
        sourceCategory: "Ability",
        gainedAtLevel: 1,
        name: "Grit",
        bonusName: gritStat,
        bonusTo: "AdvantageOnStatChecks",
        bonusAmount: 1
    };
    return gritBonus;
}

const getExtraLanguageBonus = (specialBonus: SpecialBonus, languages: Language[], ancestries: Ancestry[], ancestry: string, bonuses: Bonus[]): Bonus | null => {
    // Get random priest language:

    if (specialBonus.specialLanguages && specialBonus.specialLanguageNumber) {

        let specLangOptions: Language[] = [];
        if (specialBonus.specialLanguages.length === 1 && specialBonus.specialLanguages[0] === "ALL_COMMON") {
            specLangOptions = languages.filter((l) => l.category === "Common" && l.name !== "Common")
        } else {
            if (specialBonus.specialLanguages.length === 1 && specialBonus.specialLanguages[0] === "ALL_RARE") {
                specLangOptions = languages.filter((l) => l.category === "Rare")
            } else {
                specLangOptions = languages.filter((l) => specialBonus.specialLanguages && specialBonus.specialLanguages.indexOf(l.name) !== -1)
            }
        }

        const specLangNum = specialBonus.specialLanguageNumber;
        const languagesKnown = getLanguagesKnown(bonuses, ancestries, ancestry, languages);
        const sourceType = specialBonus.specialSourceType;
        const sourceName = specialBonus.specialSourceName;
        const sourceCategory = "Ability";
        const priestLang = getRandomLanguageBonus(specLangOptions, languagesKnown, sourceType, sourceName, sourceCategory, specLangNum);
        return priestLang;
    }
    return null;
}

const getLanguagesKnown = (bonuses: Bonus[], ancestries: Ancestry[], ancestry: string, languages: Language[]) => {
    let languagesKnown: Language[] = [];

    // Languages from ancestry:
    const ancestryDetails = ancestries.find((a) => a.name === ancestry);
    if (ancestryDetails) {
        ancestryDetails.languages.forEach((l) => {
            const thisLanguage = languages.find((tl) => tl.name === l);
            if (thisLanguage) {
                languagesKnown.push(thisLanguage);
            }
        })
    }

    // Any languages gained through bonuses:
    const bonusLangauges = bonuses.filter((b) => b.name.indexOf("ExtraLanguage") !== -1);
    bonusLangauges.forEach((l) => {
        const thisLanguage = languages.find((tl) => tl.name === l.bonusName);
        if (thisLanguage) {
            languagesKnown.push(thisLanguage);
        }
    })

    languagesKnown = languagesKnown.sort((l1, l2) => l1.name < l2.name ? -1 : 1);

    return languagesKnown;
}

const getRandomLanguageBonus = (languages: Language[], languagesKnown: Language[], sourceType: string, sourceName: string, sourceCategory: string, num: number): Bonus => {
    const randLang = languages[getRandomIntInclusive(0, languages.length - 1)].name;
    if (languagesKnown.map((l) => l.name).indexOf(randLang) !== -1) {
        return getRandomLanguageBonus(languages, languagesKnown, sourceType, sourceName, sourceCategory, num);
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "ExtraLanguage: " + sourceName + " " + num,
            bonusTo: "Languages",
            bonusName: randLang,
        };
        return bonus;
    }
}

const getSpellBonus = (specialBonus: SpecialBonus, spells: Spell[], charClasses: CharClass[], charClass: string, bonuses: Bonus[], roustaboutSpells: Spell[]): Bonus | null => {
    if (specialBonus.specialSourceType && specialBonus.specialSourceName && specialBonus.specialTier && specialBonus.specialNumber) {
        const specSpellNum = specialBonus.specialNumber;
        const sourceType = specialBonus.specialSourceType;
        const sourceName = specialBonus.specialSourceName;
        const sourceCategory = "Ability";
        const spellsKnown = getAllSpellsKnown(charClasses, charClass, spells, bonuses);
        const randomSpellBonus = getRandomSpellBonus(charClass, spells, spellsKnown, sourceType, sourceName, sourceCategory, specSpellNum, 0, roustaboutSpells);
        return randomSpellBonus;
    }
    return null;
}

const getPatron = (patrons: WarlockPatron[]): Bonus | null => {
    const r = getRandomIntInclusive(0, patrons.length - 1);
    const randPatron = patrons[r].name;
    const bonus: Bonus = {
        sourceType: "Class",
        sourceName: "Warlock",
        sourceCategory: "Patron",
        gainedAtLevel: 1,
        name: "Patron",
        bonusTo: randPatron,
        bonusName: "Patron",
    };
    return bonus;
}

const getPatronBoon = (patrons: WarlockPatron[], patronBoons: PatronBoon[], stats: Stats, spells: Spell[], spellsKnown: Spell[], thisLevel: number, bonuses: Bonus[], roustaboutSpells: Spell[]): Bonus | null => {
    const patronBonus = bonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Patron");
    if (patronBonus) {
        const patronName = patronBonus.bonusTo;
        const patron = patrons.find((p) => p.name === patronName);
        if (patron) {
            const theBoons = patronBoons.find((p) => p.patronId === patron.id);
            if (theBoons) {
                const roll = getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 5); // NOTE: only has range 2 - 11 
                const theTalent = theBoons.talents.find((t) => roll >= t.min && roll <= t.max);
                if (theTalent) {
                    const bonus: Bonus = {
                        sourceType: "Class",
                        sourceName: "Warlock",
                        sourceCategory: "Boon",
                        gainedAtLevel: 1,
                        name: theTalent.name,
                        bonusName: theTalent.name,
                        boonPatron: patronName,
                        boonSource: "InitialPatronBoon",
                        bonusTo: ""
                    };
                    selectRandomBoonOptions(theTalent, bonus, stats, spells, spellsKnown, roustaboutSpells);
                    return bonus;
                }
            }
        }
    }
    return null;
}

const selectRandomBoonOptions = (theTalent: Talent, bonus: Bonus, stats: Stats, spells: Spell[], spellsKnown: Spell[], roustaboutSpells: Spell[]) => {

    if (theTalent.name === "LearnMeleeWeaponOrPlusOneMeleeAtk") {
        let r1 = getRandomIntInclusive(1, 2);
        if (r1 === 1) {
            const weapons = ["Bastard sword", "Greataxe", "Greatsword", "Warhammer"];
            const randWeapon = weapons[getRandomIntInclusive(0, weapons.length - 1)];
            bonus.bonusName = "LearnWeapon";
            bonus.bonusTo = randWeapon;
        } else {
            bonus.bonusName = "Plus1ToMeleeAttacks";
        }
    }

    if (theTalent.name === "Plus2STROrCONOrPlus1MeleeDamage") {
        if (stats.Strength < 18) {
            bonus.bonusName = "StatBonus";
            bonus.bonusTo = "STR:+2";
        } else if (stats.Constitution < 10) {
            bonus.bonusName = "StatBonus";
            bonus.bonusTo = "CON:+2";
        } else {
            bonus.bonusName = "Plus1ToMeleeDamage";
        }
    }

    if (theTalent.name === "StatBonus") {
        bonus.bonusName = "StatBonus";

        if (JSON.stringify(theTalent.options) === JSON.stringify(["STR", "DEX", "WIS"])) {
            if (stats.Strength < 18) {
                bonus.bonusTo = "STR:+2";
            } else if (stats.Dexterity < 18) {
                bonus.bonusTo = "CON:+2";
            } else {
                bonus.bonusTo = "WIS:+2";
            }
        }

        if (JSON.stringify(theTalent.options) === JSON.stringify(["DEX", "CON"])) {
            if (stats.Dexterity < 18) {
                bonus.bonusTo = "DEX:+2";
            } else {
                bonus.bonusTo = "CON:+2";
            }
        }

        if (JSON.stringify(theTalent.options) === JSON.stringify(["DEX", "INT"])) {
            if (stats.Dexterity < 18) {
                bonus.bonusTo = "DEX:+2";
            } else {
                bonus.bonusTo = "INT:+2";
            }
        }

        if (JSON.stringify(theTalent.options) === JSON.stringify(["DEX", "CHA"])) {
            if (stats.Dexterity < 18) {
                bonus.bonusTo = "DEX:+2";
            } else {
                bonus.bonusTo = "CHA:+2";
            }
        }

        if (JSON.stringify(theTalent.options) === JSON.stringify(["STR", "DEX"])) {
            if (stats.Dexterity < 18) {
                bonus.bonusTo = "STR:+2";
            } else {
                bonus.bonusTo = "DEX:+2";
            }
        }

    }

    if (theTalent.name === "LearnSpellFromPatron") {
        const availSpells = spells.filter((s) => s.classes.indexOf("Wizard") !== -1 && s.tierByClass[0] <= 1).sort((s1, s2) => s1.name < s2.name ? -1 : 1);
        const specSpellNum = 1;
        const sourceType = "";
        const sourceName = "";
        const sourceCategory = "";
        const randomSpellBonus = getRandomSpellBonus("Wizard", availSpells, spellsKnown, sourceType, sourceName, sourceCategory, specSpellNum, 0, roustaboutSpells);
        bonus.bonusTo = randomSpellBonus?.bonusTo === undefined ? "" : randomSpellBonus?.bonusTo;
        bonus.bonusName = randomSpellBonus?.bonusName;
    }

    if (theTalent.name === "LongbowOrPlus1Ranged") {
        let r1 = getRandomIntInclusive(1, 3);
        if (r1 <= 2) {
            bonus.bonusName = "LearnWeapon";
            bonus.bonusTo = "Longbow";
        } else {
            bonus.bonusName = "Plus1ToHit";
            bonus.bonusTo = "Ranged attacks";
        }
    }

    if (theTalent.name === "Plus1ToHit") {
        bonus.bonusName = "Plus1ToHit";
        if (stats.Dexterity > stats.Strength) {
            bonus.bonusTo = "Ranged attacks";
        } else {
            bonus.bonusTo = "Melee attacks";
        }
    }

    if (theTalent.name === "ImmuneToEnergyType") {
        bonus.bonusName = "ImmuneToEnergyType";
        let r1 = getRandomIntInclusive(1, 3);
        let energyType = "";
        switch (r1) {
            case 1: energyType = "acid"; break;
            case 2: energyType = "cold"; break;
            case 3: energyType = "poison"; break;
            default: energyType = "";
        }
        bonus.bonusTo = energyType;
    }
}

const getBlackLotusBonus = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[]): Bonus | undefined => {
    const blackLotusTalents = BlackLotusTalents;
    let min = 1; // I'm excluding the Gain Two Talents option.
    const roll = getRandomIntInclusive(min, 11);
    const theTalent = blackLotusTalents[roll];
    if (theTalent) {
        if (theTalent.rerollDuplicates) {
            // check if already have the talent
            const theBonus = bonuses.find((b) => b.name === theTalent.name);
            if (theBonus) {
                return getBlackLotusBonus(sourceType, sourceName, sourceCategory, bonuses);
            }
        }

        let actualSourceCategory = "";
        let actualSourceName = sourceName;
        if (sourceType === "Class" && sourceName === "Ras-Godai" && sourceCategory === "BlackLotusTalent_FreeAtClassLevel1") {
            actualSourceCategory = "BlackLotusTalent_FreeAtClassLevel1";
        }
        if (sourceType === "Class" && sourceName === "Ras-Godai" && sourceCategory === "Talent") {
            actualSourceCategory = "BlackLotusTalent_FromClassTalent";
        }
        if (sourceType === "Ancestry" && sourceName === "Human Ambition" && sourceCategory === "Talent") {
            actualSourceCategory = "BlackLotusTalent_FromHumanAmbitionTalent";
            actualSourceName = "Human";
        }

        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: actualSourceName,
            sourceCategory: actualSourceCategory,
            gainedAtLevel: 1,
            name: theTalent.name,
            bonusTo: "",
            bonusName: theTalent.name,
        };
        return bonus;
    }

}

const getPyromancyBonus = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[]): Bonus | undefined => {
    const pyromancyTalents = PyromancyTalents;
    const roll = getRandomIntInclusive(1, 11);
    const theTalent = pyromancyTalents[roll];
    if (theTalent) {
        if (theTalent.rerollDuplicates) {
            // check if already have the talent
            const theBonus = bonuses.find((b) => b.name === theTalent.name);
            if (theBonus) {
                return getPyromancyBonus(sourceType, sourceName, sourceCategory, bonuses);
            }
        }

        let actualSourceCategory = "";
        let actualSourceName = sourceName;
        if (sourceType === "Class" && sourceName === "Fiend" && sourceCategory === "Talent") {
            actualSourceCategory = "PyromancyTalent_FromClassTalent";
        }
        if (sourceType === "Ancestry" && sourceName === "Human Ambition" && sourceCategory === "Talent") {
            actualSourceCategory = "PyromancyTalent_FromHumanAmbitionTalent";
        }

        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: actualSourceName,
            sourceCategory: actualSourceCategory,
            gainedAtLevel: 1,
            name: theTalent.name,
            bonusTo: "",
            bonusName: theTalent.name,
        };
        return bonus;
    }

}

const getAnimalTypeBonus = (): Bonus => {

    const roll = getRandomIntInclusive(0, animalTypes.length - 1);

    const bonus: Bonus = {
        sourceType: "Class",
        sourceName: "Beastmaster",
        sourceCategory: "Ability",
        gainedAtLevel: 1,
        name: "BeastMasterAnimalType",
        bonusName: "",
        bonusTo: animalTypes[roll]
    };
    return bonus;
}

const gainRandomBoon = (patrons: WarlockPatron[], patronBoons: PatronBoon[], sourceType: string, sourceName: string, stats: Stats, spells: Spell[], spellsKnown: Spell[], roustaboutSpells: Spell[]): Bonus | undefined => {
    const randPatronNum = getRandomIntInclusive(0, patrons.length - 1);
    const theRandomPatron = patrons[randPatronNum];
    if (theRandomPatron) {
        const randomPatronBoons = patronBoons.find((pb) => pb.patronId === theRandomPatron.id);
        if (randomPatronBoons) {
            const roll = getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 5); // only 2 to 11 range
            const theTalent = randomPatronBoons.talents.find((t) => roll >= t.min && roll <= t.max);
            if (theTalent) {
                const bonus: Bonus = {
                    sourceType: sourceType,
                    sourceName: sourceName,
                    sourceCategory: "Boon",
                    gainedAtLevel: 1,
                    name: theTalent.name,
                    bonusName: theTalent.name,
                    boonPatron: theRandomPatron.name,
                    boonSource: "WarlockTalentRandomBoon",
                    bonusTo: ""
                };
                selectRandomBoonOptions(theTalent, bonus, stats, spells, spellsKnown, roustaboutSpells);
                return bonus;
            }
        }
    }
    return undefined;
}

const getRandomSpellBonus = (theClass: string, spells: Spell[], spellsKnown: Spell[], sourceType: string, sourceName: string, sourceCategory: string, num: number, loopCount: number, roustaboutSpells: Spell[]): Bonus | null => {

    let spell = "";

    let classSpells = spells.filter((s) => s.classes.indexOf(theClass) !== -1 && getSpellTierForClass(theClass, s.name) === 1);

    if (theClass === "Roustabout") {
        classSpells = roustaboutSpells;
    }

    if (classSpells.length > 0) {
        const totalChances = classSpells.reduce((accumulator, currentValue) => accumulator + (theClass !== "Roustabout" ? currentValue.popularityWithClass[currentValue.classes.indexOf(theClass)] : currentValue.popularityWithClass[0]), 0);

        const roll = getRandomIntInclusive(1, totalChances);
        let total = 0;
        classSpells.forEach((s) => {
            if (theClass !== "Roustabout") {
                const classNum = s.classes.indexOf(theClass);
                total = total + s.popularityWithClass[classNum];
            } else {
                total = total + s.popularityWithClass[0];
            }
            if (roll <= total && spell === "") {
                spell = s.name;
            }
        })

        if (spellsKnown.map((l) => l.name).indexOf(spell) !== -1) {
            if (loopCount < 20) {
                return getRandomSpellBonus(theClass, spells, spellsKnown, sourceType, sourceName, sourceCategory, num, loopCount + 1, roustaboutSpells);
            } else {
                return null;
            }
        } else {
            let name = "Spell: " + sourceName + ", Tier " + 1 + ", Spell " + num;
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: name,
                bonusTo: "Tier:" + 1 + ", Spell:" + num,
                bonusName: spell,
            };
            return bonus;
        }
    }
    return null;
}

interface LevelAndBonuses {
    level: Level;
    bonuses: Bonus[];
}

export const assignTalentBonuses = (level: Level, theClass: string, charClasses: CharClass[], bonuses: Bonus[], isHumanAmbition: boolean, weapons: Weapon[], theStats: Stats, allSpells: Spell[], spellsKnown: Spell[], roustaboutSpells: Spell[]): LevelAndBonuses => {
    const updatedLevel: Level = { ...level };
    let updatedBonuses = [...bonuses];
    const charClass = charClasses.find((c) => c.name === theClass);
    if (charClass) {

        let maxRandomTalentRoll = charClass.maxRandomTalentRoll;
        if (charClass.name === "Warlock") { maxRandomTalentRoll = 9; }

        let roll = 0;
        let count = 0;
        do {
            roll = getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6);
            count = count + 1;
            if (count > 20) { break; }
        } while (roll < charClass.minRandomTalentRoll || roll > maxRandomTalentRoll);

        const theTalent = charClass.talents.find((t) => roll >= t.min && roll <= t.max);
        if (theTalent) {
            if (theTalent.rerollDuplicates) {
                // check if already has talent, if so then reroll again
                const existingTalent = bonuses.find((b) => b.sourceCategory === "Talent" && b.bonusName === theTalent.name);
                if (existingTalent) {
                    return assignTalentBonuses(level, theClass, charClasses, bonuses, isHumanAmbition, weapons, theStats, allSpells, spellsKnown, roustaboutSpells);
                }
            }
            updatedLevel.talentRolledDesc = theTalent.desc;
            updatedLevel.talentRolledName = theTalent.name;
            updatedLevel.options = theTalent.options;

            if (isHumanAmbition) {
                updatedBonuses = utilAddTalentBonuses(theTalent.name, bonuses, "Ancestry", "Human Ambition", "Talent", level.level, true, theClass, charClasses, theTalent.options);
                updatedBonuses = handlePlusOneStatAndRollAnotherTalent(theTalent, updatedBonuses, bonuses, theClass, charClasses, level, isHumanAmbition, weapons, theStats, allSpells, spellsKnown, roustaboutSpells);
            } else {
                const charClass = charClasses.find((c) => c.name === theClass);
                if (charClass) {
                    updatedBonuses = utilAddTalentBonuses(theTalent.name, bonuses, "Class", theClass, "Talent", level.level, true, theClass, charClasses, theTalent.options);
                    updatedBonuses = handlePlusOneStatAndRollAnotherTalent(theTalent, updatedBonuses, bonuses, theClass, charClasses, level, isHumanAmbition, weapons, theStats, allSpells, spellsKnown, roustaboutSpells);
                }
            }

        }
    }
    return { level: updatedLevel, bonuses: updatedBonuses };
}

const handlePlusOneStatAndRollAnotherTalent = (theTalent: Talent, updatedBonuses: Bonus[], bonuses: Bonus[], theClass: string, charClasses: CharClass[], level: Level, isHumanAmbition: boolean, weapons: Weapon[], theStats: Stats, allSpells: Spell[], spellsKnown: Spell[], roustaboutSpells: Spell[]): Bonus[] => {
    if (theTalent.name === "PlusOneStatAndRollAnotherTalent") {

        let sourceType = "Class";
        let sourceName = theClass;
        if (isHumanAmbition) {
            sourceType = "Ancestry";
            sourceName = "Human Ambition"
        }

        // find the newly added PlusOneStatAndRollAnotherTalent bonus.
        const thePlusOneStatAndRollAnotherTalentBonus = updatedBonuses[updatedBonuses.length - 1];
        if (thePlusOneStatAndRollAnotherTalentBonus) {

            const parentBonusId = thePlusOneStatAndRollAnotherTalentBonus.bonusId;
            // add StatBonus bonus and set it as the child
            const newBonuses = utilAddTalentBonuses("StatBonus", bonuses, sourceType, sourceName, "Talent", level.level, true, theClass, charClasses, ["STR", "DEX", "CON", "INT", "WIS", "CHA"], parentBonusId);
            const newStatBonus = newBonuses[newBonuses.length - 1];
            if (newStatBonus) {
                let bonusTo = "";
                const roll = getRandomIntInclusive(1, 12);
                switch (roll) {
                    case 1:
                    case 2:
                    case 3: bonusTo = "STR:+1"; break;
                    case 4:
                    case 5:
                    case 6: bonusTo = "DEX:+1"; break;
                    case 7:
                    case 8:
                    case 9: bonusTo = "CON:+1"; break;
                    case 10: bonusTo = "INT:+1"; break;
                    case 11: bonusTo = "WIS:+1"; break;
                    case 12: bonusTo = "CHA:+1"; break;
                    default: bonusTo = "CHA:+1"; break;
                }
                newStatBonus.bonusTo = bonusTo;
                updatedBonuses.push(newStatBonus);
                // roll an addional talent and make it the child of the stat bonus
                const charClass = charClasses.find((c) => c.name === theClass);
                if (charClass) {

                    let maxRandomTalentRoll = charClass.maxRandomTalentRoll;
                    if (charClass.name === "Warlock") { maxRandomTalentRoll = 9; }

                    let roll = 0;
                    let count = 0;
                    do {
                        roll = getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6);
                        count = count + 1;
                        if (count > 20) { break; }
                    } while (roll < charClass.minRandomTalentRoll || roll > maxRandomTalentRoll);

                    const theTalent = charClass.talents.find((t) => roll >= t.min && roll <= t.max);
                    if (theTalent) {
                        if (theTalent.rerollDuplicates) {
                            // check if already has talent, if so then reroll again
                            const existingTalent = bonuses.find((b) => b.sourceCategory === "Talent" && b.bonusName === theTalent.name);
                            if (existingTalent) {
                                return handlePlusOneStatAndRollAnotherTalent(theTalent, updatedBonuses, bonuses, theClass, charClasses, level, isHumanAmbition, weapons, theStats, allSpells, spellsKnown, roustaboutSpells);
                            }
                        }

                        const charClass = charClasses.find((c) => c.name === theClass);
                        if (charClass) {
                            let newBonus: Bonus | undefined = undefined;
                            switch (theTalent.name) {
                                case "PlusOneStatAndRollAnotherTalent":
                                    const statBonusAndRollAgainBonusId = uniqid();
                                    const statBonusAndRollAgainBonus: Bonus = { sourceType: sourceType, sourceName: sourceName, sourceCategory: "Talent", gainedAtLevel: level.level, name: "PlusOneStatAndRollAnotherTalent", bonusName: "PlusOneStatAndRollAnotherTalent", bonusTo: "", parentBonusId: newStatBonus.bonusId, bonusId: statBonusAndRollAgainBonusId };
                                    updatedBonuses.push(statBonusAndRollAgainBonus);
                                    updatedBonuses = handlePlusOneStatAndRollAnotherTalent(theTalent, updatedBonuses, bonuses, charClass.name, charClasses, level, isHumanAmbition, weapons, theStats, allSpells, spellsKnown, roustaboutSpells);
                                    break;
                                case "LearnWeaponOrArmor":
                                    newBonus = learnWeaponOrArmor(sourceType, sourceName, "Talent", charClass.name, charClasses, weapons, bonuses);
                                    if (newBonus) {
                                        newBonus.parentBonusId = newStatBonus.bonusId;
                                        updatedBonuses.push(newBonus);
                                    }
                                    break;
                                case "+2 Different Stat Points":
                                    let statBonus1: Bonus;
                                    let statBonus2: Bonus;
                                    const plusTwoToTwoStatsBonusId = uniqid();
                                    const plusTwoToTwoStatsBonus: Bonus = { sourceType: sourceType, sourceName: sourceName, sourceCategory: "Talent", gainedAtLevel: level.level, name: "+2 Different Stat Points", bonusName: "+2 Different Stat Points", bonusTo: "", parentBonusId: newStatBonus.bonusId, bonusId: plusTwoToTwoStatsBonusId };
                                    updatedBonuses.push(plusTwoToTwoStatsBonus);
                                    do {
                                        statBonus1 = statBonus(sourceType, sourceName, "Talent", charClass.name, charClasses, getStatsIncludingBonuses(theStats, updatedBonuses, []), [], 1, "-1", true);
                                        statBonus2 = statBonus(sourceType, sourceName, "Talent", charClass.name, charClasses, getStatsIncludingBonuses(theStats, updatedBonuses, []), [], 1, "-2", true);
                                    } while (statBonus1.bonusTo === statBonus2.bonusTo);
                                    if (statBonus1) {
                                        statBonus1.parentBonusId = plusTwoToTwoStatsBonusId;
                                        statBonus1.bonusId = uniqid();
                                        updatedBonuses.push(statBonus1);
                                        if (statBonus2) {
                                            statBonus2.parentBonusId = statBonus1.bonusId;
                                            statBonus2.bonusId = uniqid();
                                            updatedBonuses.push(statBonus2);
                                        }
                                    }
                                    break;
                                case "PlusOneHitDie":
                                    const plusOneHitDieBonus: Bonus = { sourceType: sourceType, sourceName: sourceName, sourceCategory: "Talent", gainedAtLevel: level.level, name: "PlusOneHitDie", bonusName: "PlusOneHitDie", bonusTo: "", parentBonusId: newStatBonus.bonusId, bonusId: newStatBonus.bonusId };
                                    let hitDie = charClass.hitDie;
                                    const hpBonusTo = getRandomIntInclusive(1, hitDie) + "/" + getRandomIntInclusive(1, hitDie)
                                    plusOneHitDieBonus.bonusTo = hpBonusTo;
                                    updatedBonuses.push(plusOneHitDieBonus);
                                    break;
                                case "LearnExtraSpell":
                                    const spellCastingClassNum = charClass.spellCastingClassNum !== undefined ? charClass.spellCastingClassNum : 0;
                                    const extraSpellBonus = learnExtraSpell(sourceType, sourceName, "Talent", allSpells, spellsKnown, 0, spellCastingClassNum, charClass.name, roustaboutSpells);
                                    if(extraSpellBonus) {
                                        extraSpellBonus.parentBonusId = newStatBonus.bonusId; 
                                        updatedBonuses.push(extraSpellBonus);
                                    }
                                    break;
                                default: break;
                            }
                        }

                    }
                }
            }

        }
    }
    return updatedBonuses;
}



export const randomlySelectTalentBonusWithChoices = (sourceType: string, sourceName: string, sourceCategory: string, talentRolledName: string, talentRolledDesc: string, charClass: string, charClasses: CharClass[], stats: Stats, weapons: Weapon[], bonuses: Bonus[], options: string[] | undefined, bonusAmount: number, allSpells: Spell[], spellsKnown: Spell[], spellNumber: number, spellCastingClassNum: number, patrons: WarlockPatron[], patronBoons: PatronBoon[], roustaboutSpells: Spell[]) => {
    switch (talentRolledName) {
        case "WeaponMastery": return randomlySelectWeaponMasteryBonus(sourceType, sourceName, sourceCategory, weapons, bonuses);
        case "SetWeaponTypeDamage": return randomlySelectSetWeaponDamageBonus(sourceType, sourceName, sourceCategory, charClass, charClasses, weapons, bonuses);
        case "Plus1ToHit": return plusOneToHitBonus(sourceType, sourceName, sourceCategory, stats, talentRolledDesc);
        case "Plus1ToHitAndDamage": return plusOneToHitAndDamageBonus(sourceType, sourceName, sourceCategory, stats, talentRolledDesc);
        case "StatBonus": return statBonus(sourceType, sourceName, sourceCategory, charClass, charClasses, stats, options, bonusAmount, "", false);
        case "ArmorMastery": return armorMastery(sourceType, sourceName, sourceCategory, bonuses, weapons);
        case "AdvOnCastOneSpell": return advOnCastOneSpell(sourceType, sourceName, sourceCategory, bonuses, allSpells, spellCastingClassNum);
        case "Plus2INTOrPlus1Casting": return plus2INTOrPlus1Casting(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "Plus2CHAOrPlus1Casting": return plus2CHAOrPlus1Casting(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "PlusTwoWISCHAOrPlus1SpellCasting": return plus2WISCHAOrPlus1Casting(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "LearnExtraSpell": return learnExtraSpell(sourceType, sourceName, sourceCategory, allSpells, spellsKnown, spellNumber, spellCastingClassNum, charClass, roustaboutSpells);
        case "Plus1ToAttacksOrPlus1ToMagicalDabbler": return plus1ToAttacksOrPlus1ToMagicalDabbler(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "Plus2INTDEXOrPlus1Elixir": return plus2INTDEXOrPlus1Elixir(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "StrongElixir": return strongElixir(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "ADVToElixir": return advToElixir(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "Plus2STROrCONOrPlus1MeleeAttacks": return plus2STROrCONOrPlus1MeleeAttacks(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "Plus2STROrCONOrPlus1Attacks": return plus2STROrCONOrPlus1Attacks(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "Plus1ToAttacksOrDamage": return plus1ToAttacksOrDamage(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "Plus2STROrDEXOrPlus1MeleeAttacks": return plus2STROrDEXOrPlus1MeleeAttacks(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "Plus2STROrCONOrPlus1AC": return plus2STROrCONOrPlus1AC(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "GrantSpecialTalent:BlackLotusTalent_FreeAtClassLevel1": return getBlackLotusBonus(sourceType, sourceName, sourceCategory, bonuses);
        case "GainRandomBoon": return gainRandomBoon(patrons, patronBoons, sourceType, sourceName, stats, allSpells, spellsKnown, roustaboutSpells);
        case "BeastMasterAnimalType": return beastMasterAnimalType(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "GrantSpecialTalent:PyromancyTalent": return getPyromancyBonus(sourceType, sourceName, sourceCategory, bonuses);
        case "Plus2STROrDEXOrCON": return plus2STROrDEXorCON(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "PlusTwoCONOrPlusOneSpellCasting": return plus2CONOrPlus1Casting(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "PlusTwoWISOrPlus1SpellCasting": return plus2WISOrPlus1Casting(sourceType, sourceName, sourceCategory, bonuses, stats, charClass);
        case "LearnWeaponOrArmor": return learnWeaponOrArmor(sourceType, sourceName, sourceCategory, charClass, charClasses, weapons, bonuses);
    }
    return null;
}

const randomlySelectWeaponMasteryBonus = (sourceType: string, sourceName: string, sourceCategory: string, weapons: Weapon[], bonuses: Bonus[]) => {

    const existingWeaponMasteries = bonuses.filter((b) => b.name === "WeaponMastery");
    if (existingWeaponMasteries) {
        let alreadyHasMeleeWeaponMastery = false;
        let alreadyHasRangedWeaponMastery = false;
        existingWeaponMasteries.forEach((wm) => {
            const thisWeapon = weapons.find((w) => w.name === wm.bonusTo);
            if (thisWeapon) {
                if (thisWeapon.types.indexOf("M") !== -1) { alreadyHasMeleeWeaponMastery = true; }
                if (thisWeapon.types.indexOf("R") !== -1) { alreadyHasRangedWeaponMastery = true; }
            }
        })

        let theWeapon = "";
        if (alreadyHasMeleeWeaponMastery && !alreadyHasRangedWeaponMastery) {
            // assign a ranged weapon
            theWeapon = getRandomRangedWeapon(weapons, bonuses);
        } else if (!alreadyHasMeleeWeaponMastery && alreadyHasRangedWeaponMastery) {
            // assign a melee weapon
            theWeapon = getRandomMeleeWeapon(weapons, bonuses);
        } else {
            // assign a random weapon
            const roll = getRandomIntInclusive(1, 2);
            if (roll === 1) {
                theWeapon = getRandomRangedWeapon(weapons, bonuses);
            } else {
                theWeapon = getRandomMeleeWeapon(weapons, bonuses);
            }
        }

        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "WeaponMastery",
            bonusName: "Plus1AttackAndDamagePlusHalfLevel",
            bonusTo: theWeapon,
            bonusAmount: 1
        };
        return bonus;

    }
}

const randomlySelectSetWeaponDamageBonus = (sourceType: string, sourceName: string, sourceCategory: string, charClass: string, charClasses: CharClass[], weapons: Weapon[], bonuses: Bonus[]) => {

    const theClass = charClasses.find((c) => c.name === charClass);
    if (theClass) {
        const allowedWeapons = theClass.weapons.split(",").map((w) => w.trim().toUpperCase());
        const classWeapons = weapons.filter((w) => allowedWeapons.indexOf(w.name.toUpperCase()) !== -1);

        const existingWeaponMasteries = bonuses.filter((b) => b.name === "SetWeaponTypeDamage");
        if (existingWeaponMasteries) {
            let alreadyHasMeleeWeaponMastery = false;
            let alreadyHasRangedWeaponMastery = false;
            existingWeaponMasteries.forEach((wm) => {
                const thisWeapon = classWeapons.find((w) => w.name === wm.bonusTo.split(":")[0]);
                if (thisWeapon) {
                    if (thisWeapon.types.indexOf("M") !== -1) { alreadyHasMeleeWeaponMastery = true; }
                    if (thisWeapon.types.indexOf("R") !== -1) { alreadyHasRangedWeaponMastery = true; }
                }
            })

            let theWeapon = "";
            if (alreadyHasMeleeWeaponMastery && !alreadyHasRangedWeaponMastery) {
                // assign a ranged weapon
                theWeapon = getRandomRangedWeapon(classWeapons, bonuses, charClass, charClasses, false, false);
            } else if (!alreadyHasMeleeWeaponMastery && alreadyHasRangedWeaponMastery) {
                // assign a melee weapon
                theWeapon = getRandomMeleeWeapon(classWeapons, bonuses, charClass, charClasses, false);
            } else {
                // assign a random weapon
                const roll = getRandomIntInclusive(1, 2);
                if (roll === 1) {
                    theWeapon = getRandomRangedWeapon(classWeapons, bonuses, charClass, charClasses);
                } else {
                    theWeapon = getRandomMeleeWeapon(classWeapons, bonuses, charClass, charClasses);
                }
            }

            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "SetWeaponTypeDamage",
                bonusName: "SetWeaponTypeDamage",
                bonusTo: theWeapon + ":12",
            };
            return bonus;

        }
    }


}

const plusOneToHitBonus = (sourceType: string, sourceName: string, sourceCategory: string, stats: Stats, talentRolledDesc: string) => {

    let bonusTo = "";

    if (talentRolledDesc === "+1 to melee and ranged attacks") {
        bonusTo = "Melee and ranged attacks";
    }

    if (talentRolledDesc === "+1 to melee or ranged attacks") {
        if (modNum(stats.Dexterity) > modNum(stats.Strength)) {
            bonusTo = "Ranged attacks";
        } else {
            bonusTo = "Melee attacks";
        }
    }

    const bonus: Bonus = {
        sourceType: sourceType,
        sourceName: sourceName,
        sourceCategory: sourceCategory,
        gainedAtLevel: 1,
        name: "Plus1ToHit",
        bonusName: "Plus1ToHit",
        bonusTo: bonusTo
    };

    return bonus;
}

const plusOneToHitAndDamageBonus = (sourceType: string, sourceName: string, sourceCategory: string, stats: Stats, talentRolledDesc: string) => {

    let bonusTo = "";

    if (talentRolledDesc === "+1 to melee and ranged attacks and damage") {
        bonusTo = "Melee and ranged attacks";
    }

    if (talentRolledDesc === "+1 to melee or ranged attacks and damage") {
        if (modNum(stats.Dexterity) > modNum(stats.Strength)) {
            bonusTo = "Ranged attacks";
        } else {
            bonusTo = "Melee attacks";
        }
    }

    const bonus: Bonus = {
        sourceType: sourceType,
        sourceName: sourceName,
        sourceCategory: sourceCategory,
        gainedAtLevel: 1,
        name: "Plus1ToHitAndDamage",
        bonusName: "Plus1ToHitAndDamage",
        bonusTo: bonusTo
    };
    return bonus;
}


const statBonus = (sourceType: string, sourceName: string, sourceCategory: string, charClass: string, charClasses: CharClass[], stats: Stats, options: string[] | undefined, bonusAmount: number, suffix: string, pickRandomStat: Boolean) => {
    // Use classStatWeights to choose which stat to give the bonus to
    let theChosenStat = "";
    const theClass = charClasses.find((c) => c.name === charClass);
    if (theClass) {
        if (!pickRandomStat) {
            let statWeights = [...theClass.classStatWeights].sort((a, b) => a.weight < b.weight ? 1 : -1);
            statWeights = statWeights.filter((sw) => options?.indexOf(sw.stat) !== -1);
            statWeights.forEach((sw) => {
                if (theChosenStat === "") {
                    const thisStatValue = getStatByShortName(sw.stat, stats);
                    if ((thisStatValue + bonusAmount) <= 18) {
                        theChosenStat = sw.stat;
                    }
                }
            })
        } else {
            const effOptions = ["STR", "DEX", "CON", "INT", "WIS", "CHA"];
            const randStat = getRandomIntInclusive(0, effOptions.length - 1);
            theChosenStat = effOptions[randStat];
        }
    }

    const bonus: Bonus = {
        sourceType: sourceType,
        sourceName: sourceName,
        sourceCategory: sourceCategory,
        gainedAtLevel: 1,
        name: "StatBonus" + suffix,
        bonusName: "StatBonus",
        bonusTo: theChosenStat + ":+" + bonusAmount
    };
    return bonus;
}

const armorMastery = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], weapons: Weapon[]) => {
    // if has weaponMastery with a two handed weapon, always select Leather
    let hasTwoHandedWeaponMastery = false;
    const weaponMasteries = bonuses.filter((b) => b.name === "WeaponMastery");
    weaponMasteries.forEach((wm) => {
        const theWeapon = weapons.find((w) => w.name === wm.bonusTo);
        if (theWeapon) {
            if (theWeapon.twoHands || theWeapon.versatile) {
                hasTwoHandedWeaponMastery = true;
            }
        }
    });

    let armor = "";
    if (hasTwoHandedWeaponMastery) {
        armor = "Leather armor";
    } else {
        const rand = getRandomIntInclusive(1, 2);
        armor = rand === 1 ? "Leather armor" : "Shield";
    }

    const bonus: Bonus = {
        sourceType: sourceType,
        sourceName: sourceName,
        sourceCategory: sourceCategory,
        gainedAtLevel: 1,
        name: "ArmorMastery",
        bonusName: "ArmorMastery",
        bonusTo: armor
    };
    return bonus;
}

const advOnCastOneSpell = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], allSpells: Spell[], spellCastingClassNum: number) => {
    let spell = "";

    // get spells known already
    let spellBonuses = bonuses.filter((b) => b.name.indexOf("Spell:") !== -1).map((b) => b.bonusName);

    // remove any spells that already have adv on casting bonus
    const castAdvBonuses = bonuses.filter((b) => b.name === "AdvOnCastOneSpell").map((b) => b.bonusName);
    spellBonuses = spellBonuses.filter((b) => castAdvBonuses.indexOf(b) === -1);

    // get the spells' details, inc popularity and exclude spells that are always cast with adv
    let spellDetails = allSpells.filter((s) => spellBonuses.indexOf(s.name) !== -1 && !s.castWithAdv);
    spellDetails = spellDetails.sort((s1, s2) => s1.popularityWithClass[spellCastingClassNum] < s2.popularityWithClass[spellCastingClassNum] ? 1 : -1)
    if (spellDetails.length) {
        spell = spellDetails[0].name;
    }

    const bonus: Bonus = {
        sourceType: sourceType,
        sourceName: sourceName,
        sourceCategory: sourceCategory,
        gainedAtLevel: 1,
        name: "AdvOnCastOneSpell",
        bonusName: spell,
        bonusTo: "AdvOnCastOneSpell"
    };
    return bonus;
}

const plus2INTOrPlus1Casting = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    if (theStats.Intelligence < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "PlusTwoIntOrPlusOneWizCasting",
            bonusName: "StatBonus",
            bonusTo: "INT:+2"
        };
        return bonus;
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "PlusTwoIntOrPlusOneWizCasting",
            bonusName: "Plus1ToCastingSpells",
            bonusTo: theClass
        };
        return bonus;
    }
}

const plus2CHAOrPlus1Casting = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    if (theStats.Charisma < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2CHAOrPlus1Casting",
            bonusName: "StatBonus",
            bonusTo: "CHA:+2"
        };
        return bonus;
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2CHAOrPlus1Casting",
            bonusName: "Plus1ToCastingSpells",
            bonusTo: "Witch"
        };
        return bonus;
    }
}

const plus2WISCHAOrPlus1Casting = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {
    if (theStats.Wisdom < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2WISCHAOrPlus1Casting",
            bonusName: "StatBonus",
            bonusTo: "WIS:+2"
        };
        return bonus;
    } else if (theStats.Charisma < 14) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2WISCHAOrPlus1Casting",
            bonusName: "StatBonus",
            bonusTo: "CHA:+2"
        };
        return bonus;
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2WISCHAOrPlus1Casting",
            bonusName: "Plus1ToCastingSpells",
            bonusTo: "Seer",
            bonusAmount: 1
        };
        return bonus;
    }
}

const plus1ToAttacksOrPlus1ToMagicalDabbler = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    const r = getRandomIntInclusive(1, 2);

    if (r === 1) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus1ToAttacksOrPlus1ToMagicalDabbler",
            bonusName: "Plus1ToHit",
            bonusTo: "Melee and ranged attacks"
        };
        return bonus;
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus1ToAttacksOrPlus1ToMagicalDabbler",
            bonusName: "Plus1MagicalDabbler",
            bonusTo: "MagicalDabbler",
            bonusAmount: 1
        };
        return bonus;
    }
}

const plus2INTDEXOrPlus1Elixir = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    if (theStats.Intelligence < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "PlusTwoINTDEXOrPlusOneElixirs",
            bonusName: "StatBonus",
            bonusTo: "INT:+2"
        };
        return bonus;
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "PlusTwoINTDEXOrPlusOneElixirs",
            bonusName: "Plus1ToElixirChecks",
            bonusTo: "Plague Doctor",
            bonusAmount: 1
        };
        return bonus;
    }
}


const strongElixir = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    const randElixirNum = getRandomIntInclusive(0, elixirsWithDice.length - 1);
    const randEixir = elixirsWithDice[randElixirNum];

    const bonus: Bonus = {
        sourceType: sourceType,
        sourceName: sourceName,
        sourceCategory: sourceCategory,
        gainedAtLevel: 1,
        name: "StrongElixir",
        bonusName: "StrongElixir",
        bonusTo: randEixir,
    };
    return bonus;
}

const advToElixir = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    const randElixirNum = getRandomIntInclusive(0, elixirs.length - 1);
    const randEixir = elixirs[randElixirNum];

    const bonus: Bonus = {
        sourceType: sourceType,
        sourceName: sourceName,
        sourceCategory: sourceCategory,
        gainedAtLevel: 1,
        name: "ADVToElixir",
        bonusName: "ADVToElixir",
        bonusTo: randEixir,
    };
    return bonus;
}

const plus2STROrCONOrPlus1MeleeAttacks = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    if (theStats.Strength < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2STROrCONOrPlus1MeleeAttacks",
            bonusName: "StatBonus",
            bonusTo: "STR:+2"
        };
        return bonus;
    } else {
        if (theStats.Constitution < 10) {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrCONOrPlus1MeleeAttacks",
                bonusName: "StatBonus",
                bonusTo: "CON:+2"
            };
            return bonus;
        } else {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrCONOrPlus1MeleeAttacks",
                bonusName: "Plus1ToMeleeAttacks",
                bonusTo: "",
                bonusAmount: 1
            };
            return bonus;
        }
    }
}

const plus2STROrCONOrPlus1Attacks = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    if (theStats.Strength < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2STROrCONOrPlus1Attacks",
            bonusName: "StatBonus",
            bonusTo: "STR:+2"
        };
        return bonus;
    } else {
        if (theStats.Constitution < 10) {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrCONOrPlus1Attacks",
                bonusName: "StatBonus",
                bonusTo: "CON:+2"
            };
            return bonus;
        } else {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrCONOrPlus1Attacks",
                bonusName: "Plus1ToHit",
                bonusTo: "Melee and ranged attacks"
            };
            return bonus;
        }
    }
}

const plus2STROrDEXOrPlus1MeleeAttacks = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    if (theStats.Strength > theStats.Dexterity) {
        if (theStats.Strength < 18) {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrDEXOrPlus1MeleeAttacks",
                bonusName: "StatBonus",
                bonusTo: "STR:+2"
            };
            return bonus;
        } else {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrDEXOrPlus1MeleeAttacks",
                bonusName: "Plus1ToMeleeAttacks",
                bonusTo: "",
                bonusAmount: 1
            };
            return bonus;
        }
    } else {
        if (theStats.Dexterity < 18) {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrDEXOrPlus1MeleeAttacks",
                bonusName: "StatBonus",
                bonusTo: "DEX:+2"
            };
            return bonus;
        } else {
            if (theStats.Strength < 18) {
                const bonus: Bonus = {
                    sourceType: sourceType,
                    sourceName: sourceName,
                    sourceCategory: sourceCategory,
                    gainedAtLevel: 1,
                    name: "Plus2STROrDEXOrPlus1MeleeAttacks",
                    bonusName: "StatBonus",
                    bonusTo: "STR:+2"
                };
                return bonus;
            } else {
                const bonus: Bonus = {
                    sourceType: sourceType,
                    sourceName: sourceName,
                    sourceCategory: sourceCategory,
                    gainedAtLevel: 1,
                    name: "Plus2STROrDEXOrPlus1MeleeAttacks",
                    bonusName: "Plus1ToMeleeAttacks",
                    bonusTo: "",
                    bonusAmount: 1
                };
                return bonus;
            }
        }
    }
}

const plus2STROrCONOrPlus1AC = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    if (theStats.Constitution < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2STROrCONOrPlus1AC",
            bonusName: "StatBonus",
            bonusTo: "CON:+2"
        };
        return bonus;
    } else {
        if (theStats.Strength < 14) {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrCONOrPlus1AC",
                bonusName: "StatBonus",
                bonusTo: "STR:+2"
            };
            return bonus;
        } else {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrCONOrPlus1AC",
                bonusName: "BeastMasterPlus1AC",
                bonusTo: ""
            };
            return bonus;
        }
    }
}

const plus2STROrDEXorCON = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {
    if (theStats.Strength >= theStats.Dexterity && theStats.Strength < 18) {
        // STR higher than DEX
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus2STROrDEXOrCON",
            bonusName: "StatBonus",
            bonusTo: "STR:+2"
        };
        return bonus;
    } else {
        // DEX higher then STR
        if (theStats.Dexterity < 18) {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrDEXOrCON",
                bonusName: "StatBonus",
                bonusTo: "DEX:+2"
            };
            return bonus;
        } else {
            const bonus: Bonus = {
                sourceType: sourceType,
                sourceName: sourceName,
                sourceCategory: sourceCategory,
                gainedAtLevel: 1,
                name: "Plus2STROrDEXOrCON",
                bonusName: "StatBonus",
                bonusTo: "CON:+2"
            };
            return bonus;
        }
    }
}

const plus1ToAttacksOrDamage = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    let rand = getRandomIntInclusive(1, 2);
    if (rand === 1) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus1ToAttacksOrDamage",
            bonusName: "Plus1ToHit",
            bonusTo: "Melee and ranged attacks"
        };
        return bonus;
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "Plus1ToAttacksOrDamage",
            bonusName: "Plus1ToDamage",
            bonusTo: ""
        };
        return bonus;
    }
}

const plus2CONOrPlus1Casting = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {
    if (theStats.Constitution < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "PlusTwoCONOrPlusOneSpellCasting",
            bonusName: "StatBonus",
            bonusTo: "CON:+2"
        };
        return bonus;
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "PlusTwoCONOrPlusOneSpellCasting",
            bonusName: "Plus1ToCastingSpells",
            bonusTo: "Grave Warden",
            bonusAmount: 1
        };
        return bonus;
    }
}

const plus2WISOrPlus1Casting = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {
    if (theStats.Wisdom < 18) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "PlusTwoWISOrPlus1SpellCasting",
            bonusName: "StatBonus",
            bonusTo: "WIS:+2"
        };
        return bonus;
    } else {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "PlusTwoWISOrPlus1SpellCasting",
            bonusName: "Plus1ToCastingSpells",
            bonusTo: "Ovate",
            bonusAmount: 1
        };
        return bonus;
    }
}

const learnExtraSpell = (sourceType: string, sourceName: string, sourceCategory: string, allSpells: Spell[], spellsKnown: Spell[], spellNumber: number, spellCastingClassNum: number, theClass: string, roustaboutSpells: Spell[]) => {
    const bonus = getRandomSpellBonus(theClass, allSpells, spellsKnown, sourceType, sourceName, sourceCategory, spellNumber, 0, roustaboutSpells)
    if (bonus) {
        bonus.name = "LearnExtraSpell";
        bonus.bonusTo = "PickExtraSpell";
    }
    return bonus;
}

const learnWeaponOrArmor = (sourceType: string, sourceName: string, sourceCategory: string, charClass: string, charClasses: CharClass[], weapons: Weapon[], bonuses: Bonus[]) => {

    // Roll whether learn weapon or armor
    const rollWeaponOrArmor = getRandomIntInclusive(1, 3);

    if (rollWeaponOrArmor <= 2) {
        // learn a random weapon
        const roll = getRandomIntInclusive(1, 3);
        let theWeapon = "";
        if (roll === 1) {
            theWeapon = getRandomRangedWeapon(weapons, bonuses, charClass, charClasses, false, false, true);
        } else {
            theWeapon = getRandomMeleeWeapon(weapons, bonuses, charClass, charClasses, false, true, true);
        }

        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "LearnWeaponOrArmor",
            bonusName: "LearnWeapon",
            bonusTo: theWeapon
        };
        return bonus;

    } else {
        // learn a type of armor
        let armorType = "";
        const rollArmorType = getRandomIntInclusive(1, 5);
        if (rollArmorType <= 2) {
            armorType = "Shield";
        } else if (rollArmorType <= 4) {
            armorType = "Chainmail"
        } else {
            armorType = "Plate mail"
        }

        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "LearnWeaponOrArmor",
            bonusName: "LearnArmor",
            bonusTo: armorType
        };
        return bonus;
    }
}

const beastMasterAnimalType = (sourceType: string, sourceName: string, sourceCategory: string, bonuses: Bonus[], theStats: Stats, theClass: string) => {

    // get all current animal types
    let currentAnimalTypes: string[] = [];
    const animalBonuses = bonuses.filter((b) => b.name === "BeastMasterAnimalType" && b.bonusTo !== "");
    if (animalBonuses) {
        currentAnimalTypes = animalBonuses.map((b) => b.bonusTo);
    }

    const availableAnimalTypes = animalTypes.filter((a) => currentAnimalTypes.indexOf(a) === -1);

    if (availableAnimalTypes.length > 0) {
        let rand = getRandomIntInclusive(0, availableAnimalTypes.length - 1);
        const newAnimalType = availableAnimalTypes[rand];
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: 1,
            name: "BeastMasterAnimalType",
            bonusName: "BeastMasterAnimalType",
            bonusTo: newAnimalType
        };
        return bonus;
    } else {
        return undefined;
    }
}


const convertToGold = (cost: number, currency: string) => {
    switch (currency) {
        case "gp": return cost;
        case "sp": return cost / 10;
        case "cp": return cost / 100;
    }
    return 0;
}

const addWeaponAndAmmo = (weaponName: string, weapons: Weapon[], sundries: Sundry[], gold: number, slots: number, gear: GearOwned[]): GoldAndSlotsLeft => {

    const thisWeapon = weapons.find((w) => w.name === weaponName);
    if (thisWeapon) {

        let couldAffordRangedWeapon = false;
        let cost = convertToGold(thisWeapon.cost, thisWeapon.currency);
        if (cost <= gold && slots >= thisWeapon.slots) {
            couldAffordRangedWeapon = true;
            const newGear: GearOwned = {
                instanceId: uniqid(),
                id: thisWeapon.id,
                type: "weapon",
                totalUnits: 1
            }
            gear.push(newGear);
            gold = gold - cost;
            slots = slots - thisWeapon.slots;
        }

        // Buy Ammunition
        if (thisWeapon.ammunition && couldAffordRangedWeapon) {
            const thisAmmo = sundries.find((s) => s.name === thisWeapon.ammunition);
            if (thisAmmo) {
                let cost = convertToGold(thisAmmo.cost, thisAmmo.currency);
                if (cost <= gold && slots >= thisWeapon.slots) {
                    const newGear: GearOwned = {
                        instanceId: uniqid(),
                        id: thisAmmo.id,
                        type: "sundry",
                        totalUnits: thisAmmo.quantityPerSlot
                    }
                    gear.push(newGear);
                    gold = gold - cost;
                    slots = slots - thisWeapon.slots;
                }
            }
        }
    }
    return { gold: gold, slots: slots };
}

const addArmor = (armorName: string, armors: Armor[], gold: number, slots: number, gear: GearOwned[]): GoldAndSlotsLeft => {

    const thisArmor = armors.find((a) => a.name === armorName);
    if (thisArmor) {

        let cost = convertToGold(thisArmor.cost, thisArmor.currency);
        if (cost <= gold && slots >= thisArmor.slots) {
            const newGear: GearOwned = {
                instanceId: uniqid(),
                id: thisArmor.id,
                type: "armor",
                totalUnits: 1
            }
            gear.push(newGear);
            gold = gold - cost;
            slots = slots - thisArmor.slots;

        }
    }
    return { gold: gold, slots: slots };
}

const addSundry = (sundryName: string, sundries: Sundry[], gold: number, slots: number, gear: GearOwned[]): GoldAndSlotsLeft => {

    const thisSundry = sundries.find((a) => a.name === sundryName);
    if (thisSundry) {

        let cost = convertToGold(thisSundry.cost, thisSundry.currency);
        if (cost <= gold && slots >= thisSundry.slots) {
            const newGear: GearOwned = {
                instanceId: uniqid(),
                id: thisSundry.id,
                type: "sundry",
                totalUnits: 1
            }
            gear.push(newGear);
            gold = gold - cost;
            slots = slots - thisSundry.slots;
        }
    }
    return { gold: gold, slots: slots };
}

const getHasTwoHandedWeapons = (gear: GearOwned[], weapons: Weapon[], includeVersatile: boolean) => {
    let hasTwoHandedWeapons = false;
    gear.filter((g) => g.type === "weapon").forEach((g) => {
        const thisGear = weapons.find((w) => w.id === g.id);
        if (thisGear) {
            if (includeVersatile) {
                if (thisGear.twoHands || thisGear.versatile) { hasTwoHandedWeapons = true; }
            } else {
                if (thisGear.twoHands) { hasTwoHandedWeapons = true; }
            }
        }
    })
    return hasTwoHandedWeapons;
}

interface GoldAndSlotsLeft {
    gold: number;
    slots: number;
}

export interface RandomGear {
    gear: GearOwned[],
    goldRolled: number,
    gold: number,
    silver: number,
    copper: number,
}

export const assignRandomGear2 = (bonuses: Bonus[], theClass: string, CharClasses: CharClass[], stats: Stats, weapons: Weapon[], armors: Armor[], sundries: Sundry[], totalGearSlots: number) => {

    // roll for gold
    let gold = (getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6)) * 5;
    const goldRolled = gold;

    let slots = totalGearSlots;
    let gear: GearOwned[] = [];

    // Weapons

    // if a Fighter, buy weapons for Weapon Masteries first

    let hasRangedWeaponMastery = false;
    if (theClass === "Fighter") {
        // Buy weapon mastery weapons
        const weaponMasteries = bonuses.filter((b) => b.name === "WeaponMastery").map((b) => b.bonusTo);
        weaponMasteries.forEach((wm) => {
            const thisWeapon = weapons.find((w) => w.name === wm);
            if (thisWeapon) {
                if (thisWeapon.ranges.indexOf("F") !== -1) { hasRangedWeaponMastery = true; }
            }
            const out = addWeaponAndAmmo(wm, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;

        })
        // Buy armor mastery weapons
        const armorMasteries = bonuses.filter((b) => b.name === "ArmorMastery").map((b) => b.bonusTo);
        armorMasteries.forEach((am) => {
            const out = addArmor(am, armors, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        })
    }

    // Add main weapons for all classes (except Fighter, who already has their Weapon Mastery weapon)

    if (theClass === "Priest") {
        if (stats.Dexterity >= 10) {
            const rollRanged = getRandomIntInclusive(1, 3);
            if (rollRanged === 1 || rollRanged === 2) {
                // add a ranged weapon and ammo 2/2 of the time
                const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, true, false);
                const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
                gold = out.gold;
                slots = out.slots;
            }
        }
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
        const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
    }

    if (theClass === "Thief") {
        const rollRanged = getRandomIntInclusive(1, 3);
        if (rollRanged === 1 || rollRanged === 2 || stats.Dexterity >= 12) {
            // add a ranged weapon most of the time
            const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
            const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
        const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
    }

    if (theClass === "Wizard" || theClass === "Witch") {
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, undefined);
        const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
    }

    if (theClass === "Ranger") {

        // if has a SetWeaponDamage Talent, buy those weapons
        const setDamageBonuses = bonuses.filter((b) => b.name === "SetWeaponTypeDamage").map((b) => b.bonusTo);
        setDamageBonuses.forEach((wm) => {
            const weaponName = wm.split(":")[0];
            const thisWeapon = weapons.find((w) => w.name === weaponName);
            if (thisWeapon) {
                if (thisWeapon.ranges.indexOf("F") !== -1) { hasRangedWeaponMastery = true; }
            }
            const out = addWeaponAndAmmo(weaponName, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;

        })
        // Add a ranged weapon 
        const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
        const out1 = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
        gold = out1.gold;
        slots = out1.slots;
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, undefined);
        const out2 = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out2.gold;
        slots = out2.slots;
    }

    if (theClass === "Bard") {
        // add a ranged weapon most of the time
        const rollRanged = getRandomIntInclusive(1, 3);
        if (rollRanged === 1 || rollRanged === 2 || stats.Dexterity >= 12) {
            const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
            const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, undefined);
        const out2 = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out2.gold;
        slots = out2.slots;
    }

    if (theClass === "Knight of St. Ydris") {
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
        const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
        // add a crossbow half the time, or if has good DEX
        const rollRanged = getRandomIntInclusive(1, 2);
        if (rollRanged === 1 || stats.Dexterity >= 12) {
            const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
            const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
        // add a backup weapon half the time
        const rollBackup = getRandomIntInclusive(1, 2);
        if (rollBackup === 1) {
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    if (theClass === "Warlock") {
        // If learned a melee weapon, buy one
        let hasLongbow = false;
        const learnWeaponBonuses = bonuses.filter((b) => b.bonusName === "LearnWeapon").map((b) => b.bonusTo);
        if (learnWeaponBonuses.length > 0) {
            learnWeaponBonuses.forEach((wm) => {
                if (wm === "Longbow") { hasLongbow = true; }
                const out = addWeaponAndAmmo(wm, weapons, sundries, gold, slots, gear);
                gold = out.gold;
                slots = out.slots;
            })
        } else {
            // Add a main weapon
            const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
            const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }

        if (hasLongbow) {
            // Add a main weapon
            const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
            const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        } else {
            // add a crossbow half the time, or if has good DEX
            const rollRanged = getRandomIntInclusive(1, 2);
            if (rollRanged === 1 || stats.Dexterity >= 12) {
                const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
                const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
                gold = out.gold;
                slots = out.slots;
            }
        }

        // add a backup weapon half the time
        const rollBackup = getRandomIntInclusive(1, 2);
        if (rollBackup === 1) {
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    if (theClass === "Sea Wolf") {
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
        const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
        // add a backup weapon half the time
        const rollBackup = getRandomIntInclusive(1, 2);
        if (rollBackup === 1) {
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
        // add a ranged weapon half the time
        const rollRanged = getRandomIntInclusive(1, 2);
        if (rollRanged === 1) {
            const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
            const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    if (theClass === "Pit Fighter" || theClass === "Seer") {
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
        const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
        // add a backup weapon half of the time
        const rollBackup = getRandomIntInclusive(1, 2);
        if (rollBackup === 1) {
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    if (theClass === "Desert Rider") {

        // add a Pike most of the time
        const rollPike = getRandomIntInclusive(1, 2);
        if (rollPike === 1) {
            const randRangedWeapon = "Pike";
            const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        } else {
            // Add a main weapon
            const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, undefined);
            const out2 = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
            gold = out2.gold;
            slots = out2.slots;
        }
        // add a ranged weapon most of the time
        const rollRanged = getRandomIntInclusive(1, 2);
        if (rollRanged === 1) {
            const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
            const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    if (theClass === "Ras-Godai") {
        // Add a main weapon
        const rollMonk = getRandomIntInclusive(0, 1);
        if (rollMonk) {
            const randWeapon = "Razor Chain";
            const out = addWeaponAndAmmo(randWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        } else {
            const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, undefined);
            const out2 = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
            gold = out2.gold;
            slots = out2.slots;
        }

        const thownRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, true, true);
        const out = addWeaponAndAmmo(thownRangedWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
    }

    if (theClass === "Beastmaster") {
        // Add a ranged weapon
        const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
        const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
    }

    if (theClass === "Fiend") {
        if (stats.Dexterity >= stats.Strength) {
            // missile Fiend
            // add a ranged weapon
            const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
            const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
            // Add a main melee weapon half the time
            const rollExtra = getRandomIntInclusive(1, 2);
            if (rollExtra === 1) {
                const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
                const out2 = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
                gold = out2.gold;
                slots = out2.slots;
            }
        } else {
            // melee Fiend
            // Add a main melee weapon
            const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
            const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
            // add a ranged weapon half the time
            const rollExtra = getRandomIntInclusive(1, 2);
            if (rollExtra === 1) {
                const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
                const out2 = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
                gold = out2.gold;
                slots = out2.slots;
            }
        }
        // add a backup weapon half the time 
        const rollBackup = getRandomIntInclusive(1, 2);
        if (rollBackup === 1) {
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    if (theClass === "Ovate") {

        const rollOvate = getRandomIntInclusive(0, 1);
        if (rollOvate) {
            const randWeapon = "Staff";
            const out = addWeaponAndAmmo(randWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        } else {
            const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, undefined);
            const out2 = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
            gold = out2.gold;
            slots = out2.slots;
        }

        const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses);
        const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
    }

    if (theClass === "Grave Warden") {
        // Add a scythe and dagger
        const out = addWeaponAndAmmo("Scythe", weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;

        const out2 = addWeaponAndAmmo("Dagger", weapons, sundries, gold, slots, gear);
        gold = out2.gold;
        slots = out2.slots;
    }

    if (theClass === "Plague Doctor") {
        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
        const out = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
        // add a backup weapon most of the time
        const rollBackup = getRandomIntInclusive(1, 3);
        if (rollBackup !== 3) {
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    if (theClass === "Shaman") {

        const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
        const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;

        // Add a main weapon
        const randMainWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, undefined);
        const out2 = addWeaponAndAmmo(randMainWeapon, weapons, sundries, gold, slots, gear);
        gold = out2.gold;
        slots = out2.slots;
    }

    const alsoLearnedShield = bonuses.find((b) => b.bonusName === "LearnArmor" && b.bonusTo === "Shield");

    if (theClass === "Roustabout") {
        // Try to buy a ranged weapon, in case the Roustabout has learned to use them:
        const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false, false, false);
        const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
        // Add a main weapon
        if (!alsoLearnedShield) { // if learned shield, do not want to learn a main weapon, as that'll be a staff, and then they won't buy a shield
            const randMainWeapon1 = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
            const out = addWeaponAndAmmo(randMainWeapon1, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        } else {
            const randBackupWeapon1 = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon1, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
        // add a backup weapon most of the time
        const rollBackup = getRandomIntInclusive(1, 3);
        if (rollBackup > 1) {
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
        // add another backup weapon some of the time
        const rollBackup2 = getRandomIntInclusive(1, 2);
        if (rollBackup2 === 1) {
            const randBackupWeapon2 = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon2, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    // Buy main armor for all classes
    const hasTwoHandedWeapons = getHasTwoHandedWeapons(gear, weapons, false);
    let shieldsOnly = undefined;
    if (hasTwoHandedWeapons) { shieldsOnly = false; }
    if (theClass === "Thief") { shieldsOnly = false; }
    const randArmor = getRandomArmor(gold, armors, bonuses, gear, theClass, CharClasses, shieldsOnly);
    const out1 = addArmor(randArmor, armors, gold, slots, gear);
    gold = out1.gold;
    slots = out1.slots;

    // Buy possible additional armor for armored classes

    if (theClass === "Fighter" || theClass === "Priest" || theClass === "Knight of St. Ydris" || theClass === "Sea Wolf" || theClass === "Pit Fighter" || theClass === "Desert Rider" || theClass === "Warlock" || theClass === "Fiend" || theClass === "Ovate" || alsoLearnedShield) {
        const hasTwoHandedWeapons = getHasTwoHandedWeapons(gear, weapons, false);
        if (!hasTwoHandedWeapons) {
            const hasLeatherArmor = gear.find((g) => g.id === "a1");
            const hasShield = gear.find((g) => g.id === "a4");
            if (hasLeatherArmor && !hasShield) {
                const out = addArmor("Shield", armors, gold, slots, gear);
                gold = out.gold;
                slots = out.slots;
            }
            if (!hasLeatherArmor && hasShield) {
                const out = addArmor("Leather armor", armors, gold, slots, gear);
                gold = out.gold;
                slots = out.slots;
            }
        }
    }

    // Add secondary weapons for all classes

    if (theClass === "Fighter") {
        // if has a ranged weapon mastery, add a proper melee weapon and maybe a backup weapon
        if (hasRangedWeaponMastery) {
            // maybe add a backup weapon
            const rollRanged = getRandomIntInclusive(0, 1);
            if (rollRanged === 1) {
                const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
                const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
                gold = out.gold;
                slots = out.slots;
            }
        } else {
            // has melee weapon mastery, add a backup weapon and maybe a ranged weapon
            // add a backup weapon
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
            // maybe add a ranged or thrown weapon
            const rollRanged = getRandomIntInclusive(0, 1);
            if (rollRanged === 1) {
                if (stats.Dexterity > stats.Strength) {
                    const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, true, false);
                    const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
                    gold = out.gold;
                    slots = out.slots;
                } else {
                    const thownRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, true, true);
                    const out = addWeaponAndAmmo(thownRangedWeapon, weapons, sundries, gold, slots, gear);
                    gold = out.gold;
                    slots = out.slots;
                }
            }
        }
    }

    if (theClass === "Priest" || theClass === "Desert Rider" || theClass === "Shaman") {
        // maybe add a backup weapon
        const rollBackup = getRandomIntInclusive(0, 1);
        if (rollBackup === 1) {
            const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
            const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    if (theClass === "Thief" || theClass === "Bard" || theClass === "Ras-Godai") {
        // maybe add a thrown weapon or backup weapon
        const rollExtra = getRandomIntInclusive(1, 3);
        if (rollExtra === 1) {
            const rollThrownOrBackup = getRandomIntInclusive(0, 1);
            if (rollThrownOrBackup === 1) {
                const randThrownWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, undefined, true);
                const out = addWeaponAndAmmo(randThrownWeapon, weapons, sundries, gold, slots, gear);
                gold = out.gold;
                slots = out.slots;
            } else {
                const randBackupWeapon = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, true);
                const out = addWeaponAndAmmo(randBackupWeapon, weapons, sundries, gold, slots, gear);
                gold = out.gold;
                slots = out.slots;
            }
        }
    }

    if (theClass === "Pit Fighter") {
        // Add a second main weapon a quarter of the time
        const rollMain = getRandomIntInclusive(1, 4);
        if (rollMain === 1) {
            const randMainWeapon2 = getRandomMeleeWeapon(weapons, bonuses, theClass, CharClasses, false);
            const out2 = addWeaponAndAmmo(randMainWeapon2, weapons, sundries, gold, slots, gear);
            gold = out2.gold;
            slots = out2.slots;
        }
        // add a ranged weapon a quarter of the time
        const rollRanged = getRandomIntInclusive(1, 4);
        if (rollRanged === 1) {
            const randRangedWeapon = getRandomRangedWeapon(weapons, bonuses, theClass, CharClasses, false, false);
            const out = addWeaponAndAmmo(randRangedWeapon, weapons, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    // Sundries

    // add a backpack
    const out2 = addSundry("Backpack", sundries, gold, slots, gear);
    gold = out2.gold;
    // slots = out2.slots; // 1st backpack has no weight

    // light
    const rollHasLight = getRandomIntInclusive(1, 8);
    if (rollHasLight !== 8) {

        const rollLightType = getRandomIntInclusive(1, 3);
        if (rollLightType === 1) {
            // lantern and oil
            const out1 = addSundry("Lantern", sundries, gold, slots, gear);
            gold = out1.gold;
            slots = out1.slots;
            const rollOil = getRandomIntInclusive(1, 2);
            for (let i = 0; i < rollOil; i++) {
                const out2 = addSundry("Oil, flask", sundries, gold, slots, gear);
                gold = out2.gold;
                slots = out2.slots;
            }
        } else {
            // torches
            const rollTorches = getRandomIntInclusive(1, 3);
            for (let i = 0; i < rollTorches; i++) {
                const out2 = addSundry("Torch", sundries, gold, slots, gear);
                gold = out2.gold;
                slots = out2.slots;
            }
        }
        // and somethign to light it with
        const out = addSundry("Flint and steel", sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
    }


    // food
    // rations
    const rollHasRations = getRandomIntInclusive(1, 2);
    if (rollHasRations === 1) {
        const rollRations = getRandomIntInclusive(1, 2);
        for (let i = 0; i < rollRations; i++) {
            const out2 = addSundry("Rations", sundries, gold, slots, gear);
            gold = out2.gold;
            slots = out2.slots;
        }
    }
    // bottle
    const rollHasBottle = getRandomIntInclusive(1, 3);
    if (rollHasBottle === 1) {
        const out = addSundry("Flask or bottle", sundries, gold, slots, gear);
        gold = out.gold;
        slots = out.slots;
    }
    // several random things
    const rollExtras = getRandomIntInclusive(0, 4);
    for (let i = 0; i < rollExtras; i++) {
        const availSundries = sundries.filter((s) => s.name !== "Backpack" && s.isAmmo !== true)
        if (availSundries.length > 0) {
            const randSundryNum = getRandomIntInclusive(0, availSundries.length - 1);
            const randSund = availSundries[randSundryNum];
            const out = addSundry(randSund.name, sundries, gold, slots, gear);
            gold = out.gold;
            slots = out.slots;
        }
    }

    // remove gold/silver/copper spent
    const intGold = Math.trunc(gold);
    let silver = (gold - intGold) * 10;
    let intSilver = Math.trunc(silver);
    let intCopper = Math.trunc((silver - intSilver) * 10);

    const randGear: RandomGear = {
        gear: gear,
        goldRolled: goldRolled,
        gold: intGold,
        silver: intSilver,
        copper: intCopper,
    };

    return randGear;

}

export const getClassBasedOnStats = (theStats: Stats, randomType: string, charClasses: CharClass[], activeSources: string[], roustaboutIsEnabled: boolean): string => {

    if (randomType === "Random") {
        const allClasses = charClasses.filter((c) => c.name !== "Level 0");
        const roll = getRandomIntInclusive(0, allClasses.length - 1);
        const theClass = allClasses[roll];
        let theClassName = "";
        if (theClass) {
            theClassName = theClass.name;
        }
        return theClassName;
    } else {

        const availableClasses = charClasses.filter((c) => activeSources.indexOf(c.sourceId) !== -1);
        const lottery = getClassLottery(theStats, availableClasses);
        const percentages = getRawPercentages(lottery, availableClasses);

        if (roustaboutIsEnabled) {
            if (theStats.Strength < 14 && theStats.Constitution < 14 && theStats.Dexterity < 14 && theStats.Intelligence < 14 && theStats.Wisdom < 14 && theStats.Charisma < 14) {
                return "Roustabout";
            }
        }

        if (percentages.length === 0) {
            // no recommendation
            return "";
        } else {

            if (randomType === "Mixed") {
                const roll = getRandomIntInclusive(1, 100);
                let low = 0;
                let high = 0;
                let theClass = "";
                for (let c = 0; c < percentages.length; c++) {
                    low = high + 1;
                    high = Math.min(high + percentages[c].percent, 100);
                    if (roll <= high && roll >= low) { theClass = percentages[c].charClass; }
                }
                return theClass;
            }

            if (randomType === "Best Fit") {
                const theClass = percentages[0].charClass;
                return theClass;
            }
        }

        return "";
    }
}

const addAnimalCompanionsAndAnimalLanguages = (theClass: string, bonuses: Bonus[], animals: Creature[]) => {

    if (theClass === "Ovate") {

        const numAnimalCompanionsAllowed = bonuses.filter((b) => b.name === "ExtraAnimalCompanion").length + 1;

        // get all the animl languages
        const allAnimalLangsNonUnique = animals.map((a) => a.languages && a.languages[0] !== undefined ? a.languages[0] : "");
        let allAnimalLangs = allAnimalLangsNonUnique.filter((item, pos) => {
            return allAnimalLangsNonUnique.indexOf(item) === pos;
        }).sort();

        const availAnimals: Creature[] = animals.filter((a) => a.level === 0);

        // add all animal companions
        let animalCompanionBonuses: Bonus[] = [];
        for (let ac = 1; ac <= numAnimalCompanionsAllowed; ac++) {
            const randAnimal = availAnimals[getRandomIntInclusive(0, availAnimals.length - 1)];
            const name = "AnimalCompanion-" + ac;
            const bonusTo = "AnimalCompanion";
            const bonusName = randAnimal.name;
            const bonusAnimalCompanion: Bonus = {
                sourceType: "Class",
                sourceName: "Ovate",
                sourceCategory: "Ability",
                gainedAtLevel: 0,
                name: name,
                bonusTo: bonusTo,
                bonusName: bonusName,
            };
            animalCompanionBonuses.push(bonusAnimalCompanion)
        }

        // get all the languages spoken by the animal companions, skipping duplicates
        let animalLanguages: string[] = [];
        for (let ac = 1; ac <= numAnimalCompanionsAllowed; ac++) {
            const animalCompName = animalCompanionBonuses[ac - 1].bonusName;
            const animalComp = animals.find((a) => a.name === animalCompName);
            if (animalComp) {
                const animalLang = animalComp.languages && animalComp.languages[0] !== undefined ? animalComp.languages[0] : "?";
                if (animalLanguages.indexOf(animalLang) === -1) {
                    animalLanguages.push(animalLang);
                }
            }
        }

        // add all those languages as bonuses
        let languagesAdded = 0;
        let animalCompanionLanguageBonuses: Bonus[] = [];
        animalLanguages.forEach((al, index) => {
            // if ((index + 1) <= numAnimalLanguagesAllowed) {
            if ((index + 1) <= 2) {

                let name = "ExtraLanguageManual: Ovate " + (index + 1);
                const bonusTo = "ExtraLanguageManual";
                const bonusName = al;

                const bonus: Bonus = {
                    sourceType: "Class",
                    sourceName: "Ovate",
                    sourceCategory: "Ability",
                    gainedAtLevel: 1,
                    name: name,
                    bonusTo: bonusTo,
                    bonusName: bonusName,
                };
                animalCompanionLanguageBonuses.push(bonus);
                languagesAdded = languagesAdded + 1;
                // remove this language so it cannot be used again. 
                allAnimalLangs = allAnimalLangs.filter((l) => l !== al);
            }
        })

        // add a second random Ovate language if only first was set
        if (languagesAdded === 1) {
            let name = "ExtraLanguageManual: Ovate 2";
            const bonusTo = "ExtraLanguageManual";

            const randLang = allAnimalLangs[getRandomIntInclusive(0, allAnimalLangs.length - 1)];
            // remove this language so it cannot be used again. 
            allAnimalLangs = allAnimalLangs.filter((l) => l !== randLang);

            const bonusName = randLang;

            const bonus: Bonus = {
                sourceType: "Class",
                sourceName: "Ovate",
                sourceCategory: "Ability",
                gainedAtLevel: 1,
                name: name,
                bonusTo: bonusTo,
                bonusName: bonusName,
            };
            animalCompanionLanguageBonuses.push(bonus);
            languagesAdded = languagesAdded + 1;
        }

        // Populate any remaining blank language bonuses with random languages
        const blankLangBonuses = [...bonuses, ...animalCompanionBonuses, ...animalCompanionLanguageBonuses].filter((b) => b.name === "ExtraLanguageManual" && b.bonusTo === "");
        blankLangBonuses.forEach((blb) => {
            const randLang = allAnimalLangs[getRandomIntInclusive(0, allAnimalLangs.length - 1)];
            // remove this language so it cannot be used again. 
            allAnimalLangs = allAnimalLangs.filter((l) => l !== randLang);
            blb.bonusName = randLang;
            blb.name = "ExtraLanguageManual: " + blb.sourceName + " 1";
            blb.bonusTo = "ExtraLanguageManual";
            languagesAdded = languagesAdded + 1;
        })

        return [...bonuses, ...animalCompanionBonuses, ...animalCompanionLanguageBonuses];
    }

    return bonuses;
}

export interface RandomCharacter {
    name: string;
    background: string,
    stats: Stats,
    charClass: string,
    ancestry: string,
    alignment: string,
    deity: string;
    levels: Level[],
    ambitionTalentLevel: Level,
    bonuses: Bonus[],
    gear: GearOwned[],
    goldRolled: number,
    gold: number,
    silver: number,
    copper: number
}

export const getRandomCharacter_LevelOne = (
    activeSources: string[],
    randomType: string,
    levels: Level[],
    ambitionTalentLevel: Level,
    backgrounds: Background[],
    ancestries: Ancestry[],
    languages: Language[],
    deities: Deity[],
    weapons: Weapon[],
    armors: Armor[],
    sundries: Sundry[],
    spells: Spell[],
    charClasses: CharClass[],
    patrons: WarlockPatron[],
    patronBoons: PatronBoon[],
    animals: Creature[],
    duckAbilities: DuckAbility[],
    dogAbilities: DogAbility[],
    hardcodedAncestry: string,
    hardcodedClass: string,
    hardCodeINTForPlagueDoc: boolean,
    roustaboutSpells: Spell[]
): RandomCharacter => {

    const availableClasses = charClasses.filter((c) => activeSources.indexOf(c.sourceId) !== -1);
    const availableAncestries = ancestries.filter((a) => activeSources.indexOf(a.sourceId) !== -1);
    const availableDeities = deities.filter((d) => activeSources.indexOf(d.sourceId) !== -1);
    const availableLanguages = languages.filter((a) => activeSources.indexOf(a.sourceId) !== -1);
    const availableBackgrounds = backgrounds.filter((d) => activeSources.indexOf(d.sourceId) !== -1);
    const availableWeapons = weapons.filter((w) => w.sourceIds.some((wsid: any) => activeSources.includes(wsid)));
    const availableArmors = armors.filter((d) => activeSources.indexOf(d.sourceId) !== -1);
    const availablePatrons = patrons.filter((d) => activeSources.indexOf(d.sourceId) !== -1);
    const availablePatronBoons = patronBoons.filter((d) => activeSources.indexOf(d.sourceId) !== -1);

    const theBackground = getRandomBackground(availableBackgrounds);

    let theStats: Stats = { Strength: 10, Dexterity: 10, Constitution: 10, Intelligence: 10, Wisdom: 10, Charisma: 10 };
    let theClass = "";
    let classAttempts = 0;
    do {
        const roustaboutIsEnabled = availableClasses.find((c) => c.name === "Roustabout") !== undefined;
        if (roustaboutIsEnabled) {
            theStats = getRandomStats(false, hardCodeINTForPlagueDoc);
            theClass = getClassBasedOnStats(theStats, randomType, availableClasses, activeSources, true);
        } else {
            theStats = getRandomStats(true, hardCodeINTForPlagueDoc);
            theClass = getClassBasedOnStats(theStats, randomType, availableClasses, activeSources, false);
        }
        if (hardcodedClass !== "") { theClass = hardcodedClass; }
        classAttempts = classAttempts + 1;
    }
    while (theClass === "" && classAttempts <= 100);
    if (theClass === "") { theClass = availableClasses[0].name; }

    // theClass = "Roustabout";

    let theAncestry = getRandomAncestry(availableAncestries, availableClasses, theClass);
    if (hardcodedAncestry !== "") { theAncestry = hardcodedAncestry; }
    const theName = getRandomName(theAncestry);
    const theAlignment = getRandomAlignment();

    const alignmentToMatch = theAlignment;
    let theDeity = "";
    if (theClass === "Priest") {
        theDeity = getRandomDeity(availableDeities, alignmentToMatch, true).name;
    } else if (theClass === "Ovate" || theClass === "Shaman" || theClass === "Beastmaster") {
        theDeity = getRandomDeity(availableDeities, alignmentToMatch, false, "Nature Spirits").name;
    } else {
        const worship = getRandomIntInclusive(1, 2);
        if (worship) {
            theDeity = getRandomDeity(availableDeities, alignmentToMatch, false).name;
        }
    }

    const ancestryBonuses = assignAncestryBonuses(theAncestry, theClass, availableLanguages, duckAbilities, duckQuackstories, dogAbilities, dogsLifes);
    let classBonuses = assignClassBonuses(theStats, availableLanguages, availableAncestries, theAncestry, theClass, availableClasses, availableWeapons, spells, [], 1, ancestryBonuses, activeSources, availablePatrons, availablePatronBoons, animals, roustaboutSpells);

    let spellCastingClassNum = 0;
    let hitDie = 0;
    let charClass = availableClasses.find((c) => c.name === theClass);
    if (charClass) {
        if (charClass.spellCastingClassNum) { spellCastingClassNum = charClass.spellCastingClassNum; }
        hitDie = charClass.hitDie;
    }
    let spellsKnown = getAllSpellsKnown(availableClasses, theClass, spells, classBonuses);
    let spellNumber = 0;

    // if Warlock, opt for a Talent at level 1 (instead of a Boon)
    if (theClass === "Warlock") {
        const warlockTalentBonus: Bonus = {
            sourceType: "Class",
            sourceName: "Warlock",
            sourceCategory: "TalentOrBoon",
            name: "ChooseWarlockTalentOrPatronBoon",
            bonusName: "",
            bonusTo: "WarlockTalent",
            gainedAtLevel: 1
        }
        classBonuses.push(warlockTalentBonus);
    }

    // Roll for talent for level 1, also roll for hit die
    let theLevels = [...levels];
    let thisLevel = theLevels.find((l) => l.level === 1);
    if (thisLevel) {
        // assign talent bonus.
        const levelWithTalentsAdded = assignTalentBonuses(thisLevel, theClass, availableClasses, classBonuses, false, availableWeapons, theStats, spells, spellsKnown, roustaboutSpells);
        thisLevel.talentRolledDesc = levelWithTalentsAdded.level.talentRolledDesc;
        thisLevel.talentRolledName = levelWithTalentsAdded.level.talentRolledName;
        classBonuses = levelWithTalentsAdded.bonuses;
        const options = levelWithTalentsAdded.level.options;
        const bonusAmount = 2;

        // assign choices (if required) for talent bonus.
        if (levelWithTalentsAdded.level.talentRolledName !== "+2 Stat Points" && levelWithTalentsAdded.level.talentRolledName !== "+2 Different Stat Points") {
            const talentBonus = randomlySelectTalentBonusWithChoices("Class", theClass, "Talent", levelWithTalentsAdded.level.talentRolledName, levelWithTalentsAdded.level.talentRolledDesc, theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), availableWeapons, classBonuses, options, bonusAmount, spells, spellsKnown, spellNumber, spellCastingClassNum, availablePatrons, availablePatronBoons, roustaboutSpells);
            if (talentBonus) {
                // const existingBonus = classBonuses.find((b) => b.sourceCategory === talentBonus.sourceCategory && b.sourceType === talentBonus.sourceType && b.sourceName === talentBonus.sourceName && b.name === talentBonus.name && b.bonusName === talentBonus.bonusName);
                const existingBonus = classBonuses.find((b) => b.sourceCategory === talentBonus.sourceCategory && b.sourceType === talentBonus.sourceType && b.sourceName === talentBonus.sourceName && b.name === talentBonus.name);
                if (existingBonus) {
                    existingBonus.bonusTo = talentBonus.bonusTo;
                    existingBonus.bonusName = talentBonus.bonusName;
                } else {
                    classBonuses.push(talentBonus);
                }
            }
        } else {
            // have to add two bonuses for +2 Stat Points
            const mustBeDifferent = levelWithTalentsAdded.level.talentRolledName === "+2 Different Stat Points";
            const pickRandomStat = mustBeDifferent;

            let statBonus1: Bonus;
            let statBonus2: Bonus;
            if (!mustBeDifferent) {
                statBonus1 = statBonus("Class", theClass, "Talent", theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), options, 1, "-1", pickRandomStat);
                statBonus2 = statBonus("Class", theClass, "Talent", theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), options, 1, "-2", pickRandomStat);
            } else {
                do {
                    statBonus1 = statBonus("Class", theClass, "Talent", theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), options, 1, "-1", pickRandomStat);
                    statBonus2 = statBonus("Class", theClass, "Talent", theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), options, 1, "-2", pickRandomStat);
                } while (statBonus1.bonusTo === statBonus2.bonusTo);
            }

            if (statBonus1) {
                classBonuses.push(statBonus1);
            }
            if (statBonus2) {
                classBonuses.push(statBonus2);
            }
        }

        // hit die
        const roll = getRandomIntInclusive(1, hitDie);
        thisLevel.HitPointRoll = roll;
        if (theAncestry === "Dwarf") {
            const stoutRoll = getRandomIntInclusive(1, hitDie);
            thisLevel.stoutHitPointRoll = stoutRoll;
        } else {
            thisLevel.stoutHitPointRoll = 0;
        }

        // maximise first hit die if rolled the Mugdulblub 3-7 talent
        const maxHPBonus = classBonuses.find((b) => b.name === "MaximizeTwoHPRolls");
        if (maxHPBonus) {
            thisLevel.rollIsMaximised = true;
        }
    }

    // if a human, roll for human ambition talent at level 1:
    const theAmbitionTalLev = { ...ambitionTalentLevel };
    if (theAncestry === "Human") {

        // if Warlock, opt for a Talent for Human Ambition (instead of a Boon)
        if (theClass === "Warlock") {
            const warlockTalentBonus: Bonus = {
                sourceType: "Ancestry",
                sourceName: "Human Ambition",
                sourceCategory: "TalentOrBoon",
                name: "ChooseWarlockTalentOrPatronBoon",
                bonusName: "",
                bonusTo: "WarlockTalent",
                gainedAtLevel: 1
            }
            classBonuses.push(warlockTalentBonus);
        }

        let spellsKnown = getAllSpellsKnown(availableClasses, theClass, spells, classBonuses);
        spellNumber = spellNumber + 1;

        // assign talent bonus.
        const levelWithTalentsAdded = assignTalentBonuses(theAmbitionTalLev, theClass, availableClasses, classBonuses, true, availableWeapons, theStats, spells, spellsKnown, roustaboutSpells);
        theAmbitionTalLev.talentRolledDesc = levelWithTalentsAdded.level.talentRolledDesc;
        theAmbitionTalLev.talentRolledName = levelWithTalentsAdded.level.talentRolledName;
        classBonuses = levelWithTalentsAdded.bonuses;
        const options = levelWithTalentsAdded.level.options;
        const bonusAmount = 2;

        // assign choices (if required) for talent bonus.
        if (levelWithTalentsAdded.level.talentRolledName !== "+2 Stat Points" && levelWithTalentsAdded.level.talentRolledName !== "+2 Different Stat Points") {
            const talentBonus = randomlySelectTalentBonusWithChoices("Ancestry", "Human Ambition", "Talent", theAmbitionTalLev.talentRolledName, levelWithTalentsAdded.level.talentRolledDesc, theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), availableWeapons, classBonuses, options, bonusAmount, spells, spellsKnown, spellNumber, spellCastingClassNum, availablePatrons, availablePatronBoons, roustaboutSpells);
            if (talentBonus) {
                // const existingBonus = classBonuses.find((b) => b.sourceCategory === talentBonus.sourceCategory && b.sourceType === talentBonus.sourceType && b.sourceName === talentBonus.sourceName && b.name === talentBonus.name && b.bonusName === talentBonus.bonusName);
                const existingBonus = classBonuses.find((b) => b.sourceCategory === talentBonus.sourceCategory && b.sourceType === talentBonus.sourceType && b.sourceName === talentBonus.sourceName && b.name === talentBonus.name);
                if (existingBonus) {
                    existingBonus.bonusTo = talentBonus.bonusTo;
                    existingBonus.bonusName = talentBonus.bonusName;
                } else {
                    classBonuses.push(talentBonus);
                }
            }
        } else {
            // have to add two bonuses for +2 Stat Points
            const mustBeDifferent = levelWithTalentsAdded.level.talentRolledName === "+2 Different Stat Points";
            const pickRandomStat = mustBeDifferent;

            let statBonus1: Bonus;
            let statBonus2: Bonus;
            if (!mustBeDifferent) {
                statBonus1 = statBonus("Ancestry", "Human Ambition", "Talent", theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), options, 1, "-1", pickRandomStat);
                statBonus2 = statBonus("Ancestry", "Human Ambition", "Talent", theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), options, 1, "-2", pickRandomStat);
            } else {
                do {
                    statBonus1 = statBonus("Ancestry", "Human Ambition", "Talent", theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), options, 1, "-1", pickRandomStat);
                    statBonus2 = statBonus("Ancestry", "Human Ambition", "Talent", theClass, availableClasses, getStatsIncludingBonuses(theStats, classBonuses, []), options, 1, "-2", pickRandomStat);
                } while (statBonus1.bonusTo === statBonus2.bonusTo);
            }

            if (statBonus1) {
                classBonuses.push(statBonus1);
            }
            if (statBonus2) {
                classBonuses.push(statBonus2);
            }
        }
    }

    // add talent bonuses to stats
    const theFinalStats = getStatsIncludingBonuses(theStats, classBonuses, []);

    // add animal companions and animal languages
    classBonuses = addAnimalCompanionsAndAnimalLanguages(theClass, classBonuses, animals);

    let slots = Math.max(theFinalStats.Strength, 10);
    if (theClass === "Fighter") {
        slots = slots + Math.max(modNum(theFinalStats.Constitution), 0);
    }

    const randGear = assignRandomGear2(classBonuses, theClass, availableClasses, theFinalStats, availableWeapons, availableArmors, sundries, slots);
    // const randGear = { gear: [], gold: 0, goldRolled: 0, silver: 0, copper: 0 };

    const randChar: RandomCharacter = {
        name: theName,
        background: theBackground.name,
        stats: theStats,
        charClass: theClass,
        ancestry: theAncestry,
        alignment: theAlignment,
        deity: theDeity,
        levels: theLevels,
        ambitionTalentLevel: theAmbitionTalLev,
        bonuses: classBonuses,
        gear: randGear.gear,
        goldRolled: randGear.goldRolled,
        gold: randGear.gold,
        silver: randGear.silver,
        copper: randGear.copper
    }

    return randChar;

}


import AttackBonus from "./AttackBonus";
import DogAbility from "./DogAbility";
import DogsLife from "./DogsLife";
import DuckAbility from "./DuckAbility";
import DuckQuackstory from "./DuckQuackstory";
import ExtraLanguage from "./ExtraLanguage";
import ExtraLanguageManual from "./ExtraLanguageManual";
import GainWeaponMastery from "../talents/GainWeaponMastery";
import Grit from "./Grit";
import PickSpell from "./PickSpell";
import PlusOneSpellCastingOrLuckToken from "./PlusOneSpellcastingOrLuckToken";
import { Bonus } from "../../data/bonus";
import { Language } from "../../data/languages";
import { Level } from "../../data/level";
import { Spell } from "../../data/spells";
import { Weapon } from "../../data/weapons";
import RollBlackLotusTalent from "./RollBlackLotusTalent";
import SetWarlockPatron from "./SetWarlockPatron";
import RollForPatronBoonTalent from "../charGen/rollForPatronBoonTalents";
import BeastMasterAnimalType from "../talents/BeastMasterAnimalType";
import RollPyromancyTalent from "./RollPyromancyTalent";
import PickAnimalCompanions from "./PickAnimalCompanions";


interface IProps {
    componentName: string;
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    bonuses: Bonus[];
    boonPatron: string;
    boonSource: string;
    currentLevel: number;
    level: Level;
    characterIsSaved: boolean;
    label: string;
    languages: Language[];
    languageNumber?: number;
    languagesKnown: Language[];
    weapons: Weapon[];
    spellTier?: number;
    spellInTier?: number;
    spellsKnown: Spell[];
    onSetBonus: (bonus: Bonus) => void;
    onSetBonuses: (bonuses: Bonus[]) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
    minimal: boolean;
    isCheatMode: boolean;
    // for Warlock Boon Talents only
    isAmbitionTalent: boolean;
    // theClass: CharClass;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    openAnimalModal: boolean;
    onOpenModal: (modal: string) => void;
    onCloseModal: (modal: string) => void;
}

const SpecialComponents: React.FunctionComponent<IProps> = (props: IProps) => {

    const onSetBonus = (bonus: Bonus) => {
        props.onSetBonus(bonus);
    }

    if (props.componentName === "Plus1ToRangedOrSpellcasting") {
        return <AttackBonus setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} />
    }

    if (props.componentName === "ExtraLanguage") {
        return <ExtraLanguage setBonus={(bonus: Bonus) => onSetBonus(bonus)} languages={props.languages} languageNumber={props.languageNumber} languagesKnown={props.languagesKnown} sourceType={props.sourceType} sourceName={props.sourceName} label={props.label} minimal={props.minimal} level={props.level} />
    }

    if (props.componentName === "ExtraLanguageManual") {
        return <ExtraLanguageManual setBonus={(bonus: Bonus) => onSetBonus(bonus)} languageNumber={props.languageNumber} sourceType={props.sourceType} sourceName={props.sourceName} sourceCategory={props.sourceCategory} label={props.label} minimal={props.minimal} level={props.level} />
    }

    if (props.componentName === "WeaponMastery") {
        return <GainWeaponMastery sourceType={props.sourceType} sourceName={props.sourceName} sourceCategory={props.sourceCategory} setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} boonPatron={props.boonPatron} boonSource={props.boonSource} />
    }

    if (props.componentName === "Grit") {
        return <Grit setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} />
    }

    if (props.componentName === "PickSpell") {
        return <PickSpell setBonus={(bonus: Bonus) => onSetBonus(bonus)} spellTier={props.spellTier} spellInTier={props.spellInTier} spellsKnown={props.spellsKnown} sourceType={props.sourceType} sourceName={props.sourceName} sourceCategory="Ability" label={props.label} minimal={props.minimal} level={props.level} />
    }

    if (props.componentName === "PlusOneSpellCastingOrLuckToken") {
        return <PlusOneSpellCastingOrLuckToken setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} />
    }

    if (props.componentName === "BlackLotusTalent_FreeAtClassLevel1") {
        return <RollBlackLotusTalent setSpecialTalent={props.onSetSpecialTalent} isCheatMode={props.isCheatMode} specialTalentCategory="BlackLotusTalent_FreeAtClassLevel1" showTalentsTable={true} excludeGainTwoTalents={false} level={props.level} />
    }

    if (props.componentName === "PyromancyTalent") {
        return <RollPyromancyTalent setSpecialTalent={props.onSetSpecialTalent} isCheatMode={props.isCheatMode} specialTalentCategory="PyromancyTalent" showTalentsTable={true} level={props.level} />
    }

    if (props.componentName === "SelectPatron") {
        return <SetWarlockPatron setBonus={(bonus: Bonus) => onSetBonus(bonus)} sourceType={props.sourceType} sourceName={props.sourceName} label={props.label} minimal={props.minimal} level={props.level} />
    }

    if (props.componentName === "SelectPatronBoonTalent") {
        return (
            <>
                <div className="formNote">Patron Boon</div>
                <RollForPatronBoonTalent
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                    thisLevel={props.level}
                    spellsKnown={props.spellsKnown}
                    isAmbitionTalent={props.isAmbitionTalent}
                    isCheatMode={props.isCheatMode}
                    isMinimal={props.minimal}
                    showBoonTable={true}
                    setBonus={(bonus: Bonus) => onSetBonus(bonus)}
                    setRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => props.onSetRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                    onSetRolled12TalentOrTwoStatPoints={(level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => props.onSetRolled12TalentOrTwoStatPoints(level, choice, isAmbitionTalent, isBoon, boonSource)}
                    onSetRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => props.onSetRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                    onSetSpecialTalent={(level: number, talentName: string, specialTalentCategory: string) => props.onSetSpecialTalent(level, talentName, specialTalentCategory)}
                />
            </>
        );
    }

    if (props.componentName === "DuckAbility") {
        return <DuckAbility setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} />
    }

    if (props.componentName === "Quackstory") {
        return <DuckQuackstory setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} />
    }

    if (props.componentName === "DogAbility") {
        return <DogAbility setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} />
    }

    if (props.componentName === "DogsLife") {
        return <DogsLife setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} />
    }

    if (props.componentName === "BeastMasterAnimalType") {
        return <BeastMasterAnimalType sourceType={props.sourceType} sourceName={props.sourceName} sourceCategory={props.sourceCategory} setBonus={(bonus: Bonus) => onSetBonus(bonus)} level={props.level} boonPatron={props.boonPatron} boonSource={props.boonSource} />
    }

    if (props.componentName === "PickAnimalCompanions") {
        return <PickAnimalCompanions 
            bonuses={props.bonuses} 
            onSetBonus={(bonus: Bonus) => onSetBonus(bonus)} 
            sourceType={props.sourceType} 
            sourceName={props.sourceName} 
            sourceCategory="Ability" 
            isMinimal={props.minimal} 
            label={props.label} 
            currentLevel={props.currentLevel} 
            characterIsSaved={props.characterIsSaved} 
            openAnimalModal={props.openAnimalModal}
            onOpenModal={(modal: string) => props.onOpenModal(modal)}
            onCloseModal={(modal: string) => props.onCloseModal(modal)}
        />
    }

    return null;

}

export default SpecialComponents;
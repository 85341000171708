import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import LearnANewWeapon from "./LearnANewWeapon";
import { getBonusByNameAndBonusName } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const LearnMeleeWeaponOrPlus1MeleeAttacks: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setLearnMeleeWeaponBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: "LearnMeleeWeaponOrPlusOneMeleeAtk",
            bonusName: "LearnWeapon",
            bonusTo: "",
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        };
        props.setBonus(bonus);
    }

    const setPlusOneMeleeDamageBonus = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: "LearnMeleeWeaponOrPlusOneMeleeAtk",
            bonusName:  "Plus1ToMeleeAttacks",
            bonusTo: "",
            boonPatron: props.boonPatron,
            boonSource: props.boonSource
        };
        props.setBonus(bonus);
    }

     const learnMeleeWeaponSelected = getBonusByNameAndBonusName(globalContext.bonuses, "LearnMeleeWeaponOrPlusOneMeleeAtk", "LearnWeapon", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plusOneMeleeAttacks = getBonusByNameAndBonusName(globalContext.bonuses, "LearnMeleeWeaponOrPlusOneMeleeAtk", "Plus1ToMeleeAttacks", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;

    const req = !learnMeleeWeaponSelected && !plusOneMeleeAttacks;

    const meleeWeapons = globalContext.availableWeapons.filter((w) => w.types.indexOf("M") !== -1);

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setLearnMeleeWeaponBonus()} checked={learnMeleeWeaponSelected}></input>&nbsp;Learn melee weapon</label>
            <label><input type="radio" onChange={() => setPlusOneMeleeDamageBonus()} checked={plusOneMeleeAttacks}></input>&nbsp;+1 to melee attacks</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
            {learnMeleeWeaponSelected &&
                <LearnANewWeapon 
                    weapons={meleeWeapons}
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    name={"LearnMeleeWeaponOrPlusOneMeleeAtk"}
                    level={props.level}
                    setBonus={props.setBonus}
                    boonPatron={props.boonPatron}
                    boonSource={props.boonSource}
                />
            }
        </div>
    )

}

export default LearnMeleeWeaponOrPlus1MeleeAttacks;
import { Creature, animals } from "../../data/animals";
import { plusMinus } from "../../data/stats";

interface IProps {
    animalName: string;
}

const AnimalDisplay: React.FunctionComponent<IProps> = (props: IProps) => {

    const getSpecials = (a: Creature) => {
        if (a.specials === undefined) { return null; }
        let out: any[] = [];
        a.specials.forEach((s, index) => {
            out.push(
                <tr key={"spec" + index}>
                    <td><b>{s.name}. </b>{s.effect}</td>
                </tr>
            )
        })
        return out;
    }

    const getLang = (a: Creature) => {
        if(a.languages && a.languages[0] !== undefined) {
            return a.languages[0];
        }
        return "?";
    }

    const a = animals.find((s) => s.name === props.animalName);
    if (a) {
        return (
            <table className='herbTable elixirTable leftAlign mb-4 w-100' key={a.name}>
                <tbody>
                    <tr className='herbTableHeader'>
                        <th>{a.name}</th>
                    </tr>
                    <tr>
                        <td><i>{a.desc}</i></td>
                    </tr>
                    <tr>
                        <td><b>AC</b> {a.AC}, <b>HP</b> {a.HP}, <b>ATK</b> {a.attacks}, <b>MV</b> {a.move}, <b>S</b> {plusMinus(a.stats.S)}, <b>D</b> {plusMinus(a.stats.D)}, <b>C</b> {plusMinus(a.stats.C)}, <b>I</b> {plusMinus(a.stats.I)}, <b>W</b> {plusMinus(a.stats.W)}, <b>Ch</b> {plusMinus(a.stats.Ch)}, <b>AL</b> {a.alignment}, <b>LV</b> {a.level}, <b>Language</b> {getLang(a)}</td>
                    </tr>
                    <>
                        {getSpecials(a)}
                    </>
                </tbody>
            </table>
        )
    }
    return null;

}

export default AnimalDisplay;
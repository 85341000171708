import React from "react";

import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByName } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    options?: string[];
    setBonus: (bonus: Bonus) => void;
}

const PlusOneToHitAndDamage: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setPlusOneToHitBonus = (bonusTo: string) => {
        const theBonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus1ToHitAndDamage",
            bonusName: "Plus1ToHitAndDamage",
            bonusTo: bonusTo
        }
        props.setBonus(theBonus);
    }

    const getCurrentValue = () => {
        let val = "";
        //const theBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.gainedAtLevel === props.level.level && b.name === "Plus1ToHitAndDamage");
        const theBonus = getBonusByName(globalContext.bonuses, "Plus1ToHitAndDamage", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
        if (theBonus) {
            val = theBonus.bonusTo;
        }
        return val;
    }

    const getOptions = () => {
        if (props.options) {
            let output: any = [];
            props.options.forEach((o) => {
                const isSelected = getCurrentValue() === o;
                output.push(<label key={o}><input type="radio" onChange={(e) => setPlusOneToHitBonus(e.target.value)} checked={isSelected} value={o}></input>&nbsp;{o}</label>);
            })
            return output;
        }
        return null;

    }


    const req = getCurrentValue() === "";

    if (props.options && props.options.length === 1) {
        return null;
    }

    return (
        <div key="attackBonusPlus1" className="indented">
            <>{getOptions()}</>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default PlusOneToHitAndDamage;
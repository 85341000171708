export const chelonianNames = [
    "Alaqua",
    "Bizloro",
    "Damuti",
    "Gadra",
    "Garluk",
    "Jappala",
    "Jig't",
    "Likari",
    "Lopani",
    "Orlynn",
    "Nortle",
    "Nulkara",
    "Oloren",
    "Quinn",
    "Qoldulla",
    "Sarl",
    "Sunnara",
    "Ubota",
    "Wunto",
    "Yinful"
];

export const dragonbornNames = [
    "Belvrae",
    "Dizzkel",
    "Drizz'tol",
    "Eilistraee",
    "Illyndra",
    "Ilmrae",
    "K'lder",
    "Liothryn",
    "Nelynna",
    "Phaeris",
    "Qu'ellar",
    "Syrindra",
    "Tyrvir",
    "Ulvrith",
    "Vaelindra",
    "Vornilya",
    "Xyndrith",
    "Zaraevra",
    "Z'ekzil",
    "Zilryn"
];

export const forestElfNames = [
    "Caelara",
    "Draelith",
    "Elira",
    "Erania",
    "Faelon",
    "Granith",
    "Ilyndor",
    "Joraelis",
    "Lirael",
    "Miril",
    "Nareth",
    "Raedan",
    "Sarelon",
    "Sirmell",
    "Sylvaris",
    "Tarelin",
    "Thaelin",
    "Varis",
    "Xandril",
    "Zyranel"
]

export const halfTrollNames = [
    "Brugmok",
    "Crashma",
    "Darkjaw",
    "Draktum",
    "Graktha",
    "Grimnok",
    "Gruul",
    "Haggrot",
    "Harkoth",
    "Krugnok",
    "Krugul",
    "Maula",
    "Skarlog",
    "Shurnk",
    "Tarmula",
    "Thraggar",
    "Thramk",
    "Thwakk",
    "Truggly",
    "Vonkna"
];

export const shadowElfNames = [
    "Belvrae",
    "Dizzkel",
    "Drizz'tol",
    "Eilistraee",
    "Illyndra",
    "Ilmrae",
    "K'lder",
    "Liothryn",
    "Nelynna",
    "Phaeris",
    "Qu'ellar",
    "Syrindra",
    "Tyrvir",
    "Ulvrith",
    "Vaelindra",
    "Vornilya",
    "Xyndrith",
    "Zaraevra",
    "Z'ekzil",
    "Zilryn"
];

export const tieflingNames = [
    "Azura",
    "Baelgrim",
    "Belial",
    "Ignatius",
    "Isolde",
    "Kaldrek",
    "Lilithia",
    "Malachite",
    "Morganna",
    "Nyxandra",
    "Sablethorn",
    "Selenea",
    "Seraphina",
    "Thalazar",
    "Veridian",
    "Vespera",
    "Vorenthar",
    "Xarzul",
    "Zalara",
    "Zephyrion"
];

export const gnomeNames = [
    "Alkaster",
    "Alrigg",
    "Belitrix",
    "Briesella",
    "Carnoa",
    "Folphine",
    "Gaxim",
    "Jolyssa",
    "Jorpo",
    "Kriphlix",
    "Mypindo",
    "Nymyra",
    "Posy",
    "Rosibella",
    "Grimble",
    "Tolendril",
    "Vivphina",
    "Welmyra",
    "Yofin",
    "Zanibys"
];


export const mycellanNames = [
    "Boblib",
    "Diosyl",
    "Fyshibell",
    "Hirobrelk",
    "Hylotus",
    "Napispor",
    "Nysbel",
    "Oshol",
    "Ougie",
    "Phosblia",
    "Phosbu",
    "Psyfilg",
    "Psooglas",
    "Psyglim",
    "Snurb",
    "Stubrub",
    "Vilumin",
    "Vygrer",
    "Vylappi",
    "Yrob"
];

export const silvariNames = [
    "Caraern",
    "Carya",
    "Clethra",
    "Elmshell",
    "Eugara",
    "Hamien",
    "Ingar",
    "Khaya",
    "Kigelia",
    "Maloss",
    "Mayhern",
    "Myrith",
    "Physan",
    "Pinefang",
    "Salian",
    "Shreed",
    "Triadica",
    "Vernica",
    "Wildoak",
    "Yucca"
];

export const skurridNames = [
    "Beezle",
    "Blibbiny",
    "Brabbio",
    "Broozle",
    "Fiffpoot",
    "Fizzlips",
    "Griblick",
    "Lissnib",
    "Mizzle",
    "Mummikin",
    "Quibber",
    "Rabbnick",
    "Rumbish",
    "Skrabbins",
    "Skreezle",
    "Snarkle",
    "Snarv",
    "Tizzlort",
    "Tubbegrit",
    "Zizzia"
]

export const slimeFolkNames = [
    "Adiozod",
    "Brex'mort",
    "Clyze",
    "D'zhus",
    "Glimearix",
    "Jellivot",
    "Jemarix",
    "Krystaldra",
    "Mirethul",
    "M'liox",
    "Myrathix",
    "Oozewrak",
    "Plasmicor",
    "Quixledra",
    "Shukolb",
    "Taal'krotl",
    "Visceroze",
    "Yitognas",
    "Zildoxr",
    "Zyloxith"
];





export const arcaneLibraryEmail = "contact@thearcanelibrary.com";

export const arcaneLibraryURL = "https://www.thearcanelibrary.com";
export const arcaneLibraryShadowDarkURL = "https://www.thearcanelibrary.com/collections/shadowdark-rpg";
export const arcaneLibraryShadowDarkQuickstartURL = "https://www.thearcanelibrary.com/collections/shadowdark-rpg/products/shadowdark-rpg-quickstart-set";
export const arcaneLibraryCursedScroll1_Diablerie_URL = "https://www.thearcanelibrary.com/collections/shadowdark-rpg/products/cursed-scroll-zine-vol-1-diablerie-print";
export const arcaneLibraryCursedScroll2_RedSands_URL = "https://www.thearcanelibrary.com/collections/shadowdark-rpg/products/cursed-scroll-zine-vol-2-red-sands";
export const arcaneLibraryCursedScroll3_MidnightSun_URL = "https://www.thearcanelibrary.com/collections/shadowdark-rpg/products/cursed-scroll-zine-vol-3-midnight-sun";

export const arcaneLibraryMailingListURL = "https://www.thearcanelibrary.com/pages/shadowdark-rpg-newsletter";

export const arcaneLibraryDiscordURL = "https://discord.gg/thearcanelibrary";
export const arcaneLibraryRedditURL = "https://www.reddit.com/r/shadowdark";
export const arcaneLibraryFacebookURL = "https://www.facebook.com/groups/263931878532652";
export const swooneRedditUserURL = "https://www.reddit.com/user/SWooNe";

export const fantasyGroundsVTTURL = "https://www.fantasygrounds.com/store/product.php?id=IPFGSDTALCORE";
export const foundryVTTURL = "https://foundryvtt.com/packages/shadowdark";
export const owlbearCharacterSheetVTTURL = "https://extensions.owlbear.rodeo/sd-character-sheet";
export const roll20CharacterSheetVTTURL = "https://github.com/joejames38/Shadowdark_CharacterSheet";
export const letsRoleCharacterSheetVTTURL = "https://lets-role.com/system/shadowdark-18729";

export const bardAndRangerPDFDropboxURL = "https://www.dropbox.com/scl/fi/z58yei3n055mblyzp6p4n/Ranger-and-Bard-Classes.pdf?rlkey=ify5hw1owq5p40rhsd3pi47ld&e=1&dl=0";
export const roustaboutPDFDropboxURL = "https://www.dropbox.com/scl/fi/mq28912ro6edyr3vs980j/Roustabout.pdf?rlkey=nmslgv0ghzd5rpq3wa349i6p3&dl=0";
export const koboldAncestryDiscordURL = "https://discord.com/channels/558029475837902851/821568382952407070/1123000094078271508";

export const unnaturalSelectionURL = "https://dungeondamsel.com";
export const darcyPerryDuckURL = "https://starhatminiatures.com/products/shadowdark-duck-ancestry-dungeoneer-class";
export const darcyPerryDogURL = "https://starhatminiatures.com/products/shadowdog-dog-ancestry-rover-class";

import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { PyromancyTalents } from "../../data/classes";
import { Level } from "../../data/level";
import { getRandomIntInclusive } from "../../data/random";
import PyromancyTables from "../charGen/pyromancyTables";

interface IProps {
    isCheatMode: boolean;
    specialTalentCategory: string;
    setSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
    showTalentsTable: boolean;
    level: Level;
}

const RollPyromancyTalent: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getExistingPyromancyBonus = () => {
        if (props.specialTalentCategory.indexOf("HumanAmbition") !== -1) {
            const theBonus = globalContext.bonuses.find((b) =>
                b.sourceType === "Ancestry" &&
                b.sourceName === "Human Ambition" &&
                b.sourceCategory === props.specialTalentCategory &&
                b.gainedAtLevel === props.level.level
            )
            return theBonus;
        } else {
            const theBonus = globalContext.bonuses.find((b) =>
                b.sourceType === "Class" &&
                b.sourceName === "Fiend" &&
                b.sourceCategory === props.specialTalentCategory &&
                b.gainedAtLevel === props.level.level
            )
            return theBonus;
        }
    }

    const checkForDuplicateBonus = (talentName: string) => {
        const theBonus = globalContext.bonuses.find((b) => b.name === talentName);
        return theBonus;
    }

    const getRandomPyromancyTalent: any = () => {
        const pyromancyTalents = PyromancyTalents;
        const roll = getRandomIntInclusive(0, PyromancyTalents.length - 1);
        const theTalent = pyromancyTalents[roll];
        if (theTalent) {
            if (theTalent.rerollDuplicates) {
                // check if already have the talent
                const theBonus = checkForDuplicateBonus(theTalent.name);
                if (theBonus) {
                    return getRandomPyromancyTalent();
                }
            }
            return theTalent;
        }
        return undefined;
    }

    const rollForTalent = () => {
        const theTalent = getRandomPyromancyTalent(false);
        props.setSpecialTalent(props.level.level, theTalent.name, props.specialTalentCategory);
    }

    const getTalentHasBeenSelected = () => {
        const theBonus = getExistingPyromancyBonus();
        if (theBonus !== undefined && theBonus.name !== "") { return true; }
        return false;
    }

    const talentHasBeenSelected = getTalentHasBeenSelected();

    const getHTML = (desc: string) => {
        return ({ __html: desc });
    }

    const getTalentDesc = () => {
        const theBonus = getExistingPyromancyBonus();
        if (theBonus) {
            const theTalent = PyromancyTalents.find((bt) => bt.name === theBonus.name);
            if (theTalent) {
                return <span dangerouslySetInnerHTML={getHTML(theTalent.desc)} />
            } else {
                return "?";
            }
        }
        return "?";
    }

    const getTalentName = () => {
        const theBonus = getExistingPyromancyBonus();
        if (theBonus) {
            const theTalent = PyromancyTalents.find((bt) => bt.name === theBonus.name);
            if (theTalent) {
                return theTalent.name;
            } else {
                return "";
            }
        }
        return "";
    }

    const selectCss = !talentHasBeenSelected ? "form-select redBorder" : "form-select";

    const setChosenTalentBonus = (e: any) => {
        const talentName = e.target.value;
        props.setSpecialTalent(props.level.level, talentName, props.specialTalentCategory);
    }

    const getOptions = () => {
        let availableTalents = [...PyromancyTalents];
        const options = availableTalents.map((t) => <option value={t.name} key={t.name} dangerouslySetInnerHTML={getHTML(t.desc)}></option>);
        options.unshift(<option value="" key="selectTalent">-- select talent --</option>);
        return options;
    }

    return (
        <div>
            {(props.showTalentsTable && !getTalentHasBeenSelected()) &&
                <PyromancyTables />
            }

            {!props.isCheatMode && !talentHasBeenSelected &&
                <>
                    <button className="btn btn-dark" onClick={() => rollForTalent()} disabled={false}>Roll for Pyromancy Talent</button>&nbsp;
                    {!talentHasBeenSelected &&
                        <div className="valError">Please roll for a talent</div>
                    }
                </>
            }

            {props.isCheatMode &&
                <>
                    <select className={selectCss} onChange={(e) => setChosenTalentBonus(e)} value={getTalentName()}>{getOptions()}</select>
                </>
            }

            {talentHasBeenSelected &&
                <>
                    <div><b>Pyromancy Talent:</b> {getTalentDesc()}</div>
                </>
            }

        </div>
    )

}

export default RollPyromancyTalent;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { Spell, spells } from "../../data/spells";
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { getRandomIntInclusive } from "../../data/random";
import { getSpellTierForClass } from "../../data/utilities";

interface IProps {
    spellsKnown: Spell[];
    spellTier?: number;
    spellInTier?: number; // 1st, 2nd, 3rd etc spell known
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    level: Level;
    label: string;
    minimal: boolean;
    setBonus: (bonus: Bonus) => void;
}

const PickSpell: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const allSpells = spells; 

    let charClass = "";
    if (props.sourceType === "Class") {
        charClass = props.sourceName;
    }

    let allowedSpells = [...allSpells].filter((s) => getSpellTierForClass(globalContext.className, s.name) === props.spellTier);
    if (charClass === "Priest") {
        allowedSpells = allowedSpells.filter((s) => s.name !== "Turn Undead")
    }

    const effectName = charClass !== "Shaman" ? "Spell" : "Chant";

    allowedSpells = allowedSpells.sort((s1:Spell, s2: Spell) => s1.name < s2.name ? -1 : 1);

    const options = allowedSpells.map((s, index) => <option value={s.name} key={s.name + index}>{s.name}</option>);
    options.unshift(<option value="" key={"selectSpell"}>-- select Tier {props.spellTier} {charClass} {effectName} --</option>);

    let name = "Spell: " + props.sourceName + ", Tier " + props.spellTier + ", Spell " + props.spellInTier;

    let currentValue = "";
    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.name === name && b.bonusTo === "Tier:" + props.spellTier + ", Spell:" + props.spellInTier);
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setSpellBonus = (spell: string) => {

        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: props.level.level,
            name: name,
            bonusTo: "Tier:" + props.spellTier + ", Spell:" + props.spellInTier,
            bonusName: spell,
        };
        props.setBonus(bonus);
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    const getSpellDesc = () => {
        if (props.minimal) { return null; }
        if (currentValue !== "") {
            const theSpell = allSpells.find((s) => s.name === currentValue);
            if (theSpell) {
                const tier = getSpellTierForClass(globalContext.className, theSpell.name)
                return <div className="spell">Tier: {tier}, Range: {theSpell.range}, Duration: {theSpell.duration}. {theSpell.desc}</div>
            }
        }
        return null;
    }

    const rollSpell = () => {
        const spellsForTier = allSpells.filter((s) => getSpellTierForClass(globalContext.className, s.name) === props.spellTier);
        const randSpell = spellsForTier[getRandomIntInclusive(0, spellsForTier.length - 1)].name;
        if (props.spellsKnown.map((l) => l.name).indexOf(randSpell) !== -1) {
            rollSpell();
        } else {
            setSpellBonus(randSpell);
        }
    }

    const getIsDuplicate = () => {
        let thisSpell = props.spellsKnown.filter((l) => l.name === currentValue);
        if (thisSpell.length > 1) {
            return <span className="valError ms-3">Duplicate</span>
        }
        return null;
    }


    return (
        <div>
            <div className="formNote">{props.label}</div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setSpellBonus(e.target.value)} value={currentValue}>{options}</select>
                <button className="btn btn-dark" onClick={(e) => rollSpell()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
                {getIsDuplicate()}
            </div>
            {getSpellDesc()}
        </div>
    )

}

export default PickSpell;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import Modal from "react-responsive-modal";
import { GearOwned } from "../../data/gear";
import { useState } from 'react';
import { Treasure } from "../../data/treasure";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import DisplayGold from "../charGen/displayGold";

interface IProps {
    openModal: boolean;
    treasureCarried: Treasure[];
    gearOwned: GearOwned[];
    gearSlotsUsed: number;
    gearSlotsTotal: number;
    onAddTreasure: (name: string, desc: string, cost: number, currency: string, slots: number) => void;
    onEditTreasure: (id: string, name: string, desc: string, cost: number, currency: string, slots: number) => void;
    onDiscardTreasure: (id: string) => void;
    onSellTreasure: (id: string) => void;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}

const GetTreasure: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [cost, setCost] = useState("1");
    const [currency, setCurrency] = useState("gp");
    const [slots, setSlots] = useState(1);
    const [isAddMode, setIsAddMode] = useState(true);
    const [editedTreasureId, setEditedTreasureId] = useState("");

    const [showForm, setShowForm] = useState(false);

    const setTheCost = (cost: string) => {
        if (parseInt(cost, 10)) {
            const addCost = Math.max(parseInt(cost, 10), 0);
            setCost(addCost.toString());
        } else {
            setCost("");
        }
    }

    const discardTreasure = (id: string) => {
        if (window.confirm("Are you sure you want to discard this treasure?")) {
            props.onDiscardTreasure(id);
        }
    }

    const editTreasure = (id: string) => {
        const t = props.treasureCarried.find((x) => x.id === id);
        if (t) {
            setEditedTreasureId(t.id);
            setName(t.name);
            setDesc(t.desc);
            setCost(t.cost.toString());
            setCurrency(t.currency);
            setSlots(t.slots);
            setShowForm(true);
            setIsAddMode(false);
        } else {
            alert("not found");
        }
    }

    const sellTreasure = (id: string) => {
        if (window.confirm("Are you sure you want to sell this treasure?")) {
            props.onSellTreasure(id);
        }
    }

    const getTreasuresCarried = () => {

        if (props.treasureCarried.length === 0) {
            return (
                <div className="row buyRow" key={"noTreasure"}>
                    <div className="col-12">No treasure</div>
                </div>
            );
        }

        const rows: any = [];
        props.treasureCarried.forEach((s) => {
            rows.push(
                <div className="row buyRow" key={s.name}>
                    <div className="col-8 col-sm-5 col-md-6"><b>{s.name}</b></div>
                    <div className="col-4 col-sm-2 col-md-2 text-end">{s.cost}&nbsp;{s.currency}</div>
                    <div className="col-6 col-sm-2 col-md-2">{s.slots} slot{s.slots === 1 ? "" : "s"}</div>
                    <div className="col-6 col-sm-3 col-md-2 text-end">
                        <button className="btn btn-secondary btn-sm btnBuy me-1" onClick={() => editTreasure(s.id)} title="Edit"><FontAwesomeIcon icon={faEdit} /></button>
                        <button className="btn btn-secondary btn-sm btnBuy me-1" onClick={() => sellTreasure(s.id)} title="Sell"><FontAwesomeIcon icon={faDollarSign} /></button>
                        <button className="btn btn-secondary btn-sm btnBuy me-1" onClick={() => discardTreasure(s.id)} title="Discard"><FontAwesomeIcon icon={faXmark} /></button>
                    </div>
                    {s.desc !== "" &&
                        <div className="col-12"><i>{s.desc}</i></div>
                    }
                </div>
            );
        })

        if (rows.length === 0) { rows.push(<tr key={"not permitted"}><td colSpan={7}>None permitted</td></tr>) }

        return rows;
    }

    const getGearSlotOptions = () => {
        let out: any[] = [];
        for (let g = 0; g <= 20; g++) {
            out.push(<option value={g} key={"slot_" + g}>{g}</option>)
        }
        return out;
    }

    const onCloseModal = () => {
        reset();
        props.onCloseModal("treasure");
    }

    const reset = () => {
        setEditedTreasureId("");
        setName("");
        setDesc("");
        setCost("0");
        setCurrency("gp");
        setSlots(1);
        setShowForm(false);
    }

    const addTreasure = () => {
        if (name.trim() !== "") {
            if (isAddMode) {
                props.onAddTreasure(name, desc, parseInt(cost, 10), currency, slots);
            } else {
                props.onEditTreasure(editedTreasureId, name, desc, parseInt(cost, 10), currency, slots);
            }
            reset();
        }
    }

    const showAddTreasure = () => {
        setShowForm(true);
        setIsAddMode(true);
    }

    return (
        <>
            <button onClick={() => props.onOpenModal("treasure")} className="btn btn-dark btn-sm me-1">Treasure</button>
            {props.openModal &&
                <Modal open={props.openModal} onClose={() => onCloseModal()} classNames={{ modal: 'customModal' }}>

                    <div>

                        <div><b>Coin:</b> <DisplayGold gold={globalContext.gold} silver={globalContext.silver} copper={globalContext.copper} /></div>
                        <div className="mb-3"><b>Gear Slots:</b> {props.gearSlotsUsed} of {props.gearSlotsTotal}</div>

                        {!showForm &&
                            <div>

                                <div className="container mb-3">
                                    <button className="btn btn-dark me-2" onClick={() => showAddTreasure()}>Add Treasure</button>
                                </div>

                                <div className="container text-left">
                                    <h3 className="mb-0">Treasures</h3>
                                    <div className="small">Gems, luxury items, loot, curios, tools, and other mundane valuables</div>
                                    {getTreasuresCarried()}
                                </div>

                            </div>
                        }

                        {showForm &&
                            <div className="container">

                                <h3>{isAddMode ? "Add" : "Edit"} Treasure</h3>

                                <div className="form-group">
                                    <div className="formNote">Name</div>
                                    <div className="input-group">
                                        <input className="form-control treasureName" id="name" type="text" maxLength={50} onChange={(e) => setName(e.target.value)} value={name}></input>
                                    </div>
                                    {name.trim() === "" &&
                                        <div className="valError">Please enter a name</div>
                                    }
                                </div>

                                <div className="form-group">
                                    <div className="formNote">Description/notes/XP</div>
                                    <div className="input-group">
                                        <input className="form-control" id="name" type="text" onChange={(e) => setDesc(e.target.value)} value={desc}></input>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="formNote">Value</div>
                                    <div className="input-group">
                                        <input className="form-control cost" id="name" type="number" onChange={(e) => setTheCost(e.target.value)} value={cost}></input>
                                        <select className="form-select cost" onChange={(e) => setCurrency(e.target.value)} value={currency}>
                                            <option value="gp">gp</option>
                                            <option value="sp">sp</option>
                                            <option value="cp">cp</option>
                                        </select>
                                    </div>
                                    {cost.trim() === "" &&
                                        <div className="valError">Please enter the cost for one unit</div>
                                    }
                                </div>

                                <div className="form-group">
                                    <div className="formNote">Gear Slots</div>
                                    <div className="input-group">
                                        <select className="form-select cost" value={slots} onChange={(e) => setSlots(parseInt(e.target.value, 10))}>
                                            {getGearSlotOptions()}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button className="btn btn-dark me-2" onClick={() => addTreasure()}>{isAddMode ? "Create" : "Update"}</button>
                                    <button className="btn btn-dark" onClick={() => reset()}>Cancel</button>
                                </div>

                            </div>
                        }

                    </div >

                </Modal >
            }
        </>
    )

}

export default GetTreasure;
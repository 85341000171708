export interface TitleForLevel {
    minLevel: number;
    maxLevel: number;
    title: string;
}

export interface Title {
    class: string;
    alignment: string;
    titleForLevels: TitleForLevel[];
}

export const titles: Title[] = [
    {
        class: "Fighter",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Squire" },
            { minLevel: 3, maxLevel: 4, title: "Cavalier" },
            { minLevel: 5, maxLevel: 6, title: "Knight" },
            { minLevel: 7, maxLevel: 8, title: "Thane" },
            { minLevel: 9, maxLevel: 10, title: "Lord/Lady" }
        ]
    },
    {
        class: "Fighter",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Knave" },
            { minLevel: 3, maxLevel: 4, title: "Bandit" },
            { minLevel: 5, maxLevel: 6, title: "Slayer" },
            { minLevel: 7, maxLevel: 8, title: "Reaver" },
            { minLevel: 9, maxLevel: 10, title: "Warlord" }
        ]
    },
    {
        class: "Fighter",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Warrior" },
            { minLevel: 3, maxLevel: 4, title: "Barbarian" },
            { minLevel: 5, maxLevel: 6, title: "Battlerager" },
            { minLevel: 7, maxLevel: 8, title: "Warchief" },
            { minLevel: 9, maxLevel: 10, title: "Chieftain" }
        ]
    },
    {
        class: "Priest",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Acolyte" },
            { minLevel: 3, maxLevel: 4, title: "Crusader" },
            { minLevel: 5, maxLevel: 6, title: "Templar" },
            { minLevel: 7, maxLevel: 8, title: "Champion" },
            { minLevel: 9, maxLevel: 10, title: "Paladin" }
        ]
    },
    {
        class: "Priest",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Initiate" },
            { minLevel: 3, maxLevel: 4, title: "Zealot" },
            { minLevel: 5, maxLevel: 6, title: "Cultist" },
            { minLevel: 7, maxLevel: 8, title: "Scourge" },
            { minLevel: 9, maxLevel: 10, title: "Chaos Knight" }
        ]
    },
    {
        class: "Priest",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Seeker" },
            { minLevel: 3, maxLevel: 4, title: "Invoker" },
            { minLevel: 5, maxLevel: 6, title: "Haruspex" },
            { minLevel: 7, maxLevel: 8, title: "Mystic" },
            { minLevel: 9, maxLevel: 10, title: "Oracle" }
        ]
    },
    {
        class: "Thief",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Footpad" },
            { minLevel: 3, maxLevel: 4, title: "Burglar" },
            { minLevel: 5, maxLevel: 6, title: "Rook" },
            { minLevel: 7, maxLevel: 8, title: "Underboss" },
            { minLevel: 9, maxLevel: 10, title: "Boss" }
        ]
    },
    {
        class: "Thief",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Thug" },
            { minLevel: 3, maxLevel: 4, title: "Cutthroat" },
            { minLevel: 5, maxLevel: 6, title: "Shadow" },
            { minLevel: 7, maxLevel: 8, title: "Assassin" },
            { minLevel: 9, maxLevel: 10, title: "Wraith" }
        ]
    },
    {
        class: "Thief",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Robber" },
            { minLevel: 3, maxLevel: 4, title: "Outlaw" },
            { minLevel: 5, maxLevel: 6, title: "Rogue" },
            { minLevel: 7, maxLevel: 8, title: "Renegade" },
            { minLevel: 9, maxLevel: 10, title: "Bandit King/Queen" }
        ]
    },
    {
        class: "Wizard",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Apprentice" },
            { minLevel: 3, maxLevel: 4, title: "Conjurer" },
            { minLevel: 5, maxLevel: 6, title: "Arcanist" },
            { minLevel: 7, maxLevel: 8, title: "Mage" },
            { minLevel: 9, maxLevel: 10, title: "Archmage" }
        ]
    },
    {
        class: "Wizard",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Adept" },
            { minLevel: 3, maxLevel: 4, title: "Channeler" },
            { minLevel: 5, maxLevel: 6, title: "Witch/Warlock" },
            { minLevel: 7, maxLevel: 8, title: "Diabolist" },
            { minLevel: 9, maxLevel: 10, title: "Sorcerer" }
        ]
    },
    {
        class: "Wizard",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Shaman" },
            { minLevel: 3, maxLevel: 4, title: "Seer" },
            { minLevel: 5, maxLevel: 6, title: "Warden" },
            { minLevel: 7, maxLevel: 8, title: "Sage" },
            { minLevel: 9, maxLevel: 10, title: "Druid" }
        ]
    },
    {
        class: "Ranger",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Wanderer" },
            { minLevel: 3, maxLevel: 4, title: "Strider" },
            { minLevel: 5, maxLevel: 6, title: "Warden" },
            { minLevel: 7, maxLevel: 8, title: "Guardian" },
            { minLevel: 9, maxLevel: 10, title: "Sentinel" }
        ]
    },
    {
        class: "Ranger",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Hood" },
            { minLevel: 3, maxLevel: 4, title: "Outlaw" },
            { minLevel: 5, maxLevel: 6, title: "Fugitive" },
            { minLevel: 7, maxLevel: 8, title: "Exile" },
            { minLevel: 9, maxLevel: 10, title: "Pariah" }
        ]
    },
    {
        class: "Ranger",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Stranger" },
            { minLevel: 3, maxLevel: 4, title: "Wayfarer" },
            { minLevel: 5, maxLevel: 6, title: "Outlander" },
            { minLevel: 7, maxLevel: 8, title: "Recluse" },
            { minLevel: 9, maxLevel: 10, title: "Hermit" }
        ]
    },
    {
        class: "Bard",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Storyteller" },
            { minLevel: 3, maxLevel: 4, title: "Balladeer" },
            { minLevel: 5, maxLevel: 6, title: "Philosopher" },
            { minLevel: 7, maxLevel: 8, title: "Poet" },
            { minLevel: 9, maxLevel: 10, title: "Master Poet" }
        ]
    },
    {
        class: "Bard",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Guttersnipe" },
            { minLevel: 3, maxLevel: 4, title: "Charlatan" },
            { minLevel: 5, maxLevel: 6, title: "Satirist" },
            { minLevel: 7, maxLevel: 8, title: "Silvertongue" },
            { minLevel: 9, maxLevel: 10, title: "Doomspeaker" }
        ]
    },
    {
        class: "Bard",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Seeker" },
            { minLevel: 3, maxLevel: 4, title: "Witness" },
            { minLevel: 5, maxLevel: 6, title: "Speaker" },
            { minLevel: 7, maxLevel: 8, title: "Voice" },
            { minLevel: 9, maxLevel: 10, title: "Truthbearer" }
        ]
    },
    {
        class: "Knight of St. Ydris",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Arbiter" },
            { minLevel: 3, maxLevel: 4, title: "Enforcer" },
            { minLevel: 5, maxLevel: 6, title: "Knight Marshal" },
            { minLevel: 7, maxLevel: 8, title: "Judge" },
            { minLevel: 9, maxLevel: 10, title: "Justiciar" }
        ]
    },
    {
        class: "Knight of St. Ydris",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Traitor" },
            { minLevel: 3, maxLevel: 4, title: "Fallen" },
            { minLevel: 5, maxLevel: 6, title: "Oathbreaker" },
            { minLevel: 7, maxLevel: 8, title: "Blackguard" },
            { minLevel: 9, maxLevel: 10, title: "Demonlord" }
        ]
    },
    {
        class: "Knight of St. Ydris",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Brother/Sister" },
            { minLevel: 3, maxLevel: 4, title: "Exorcist" },
            { minLevel: 5, maxLevel: 6, title: "Reverend Knight" },
            { minLevel: 7, maxLevel: 8, title: "Inquisitor" },
            { minLevel: 9, maxLevel: 10, title: "Grand Inquisitor" }
        ]
    },
    {
        class: "Witch",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Fortune Teller" },
            { minLevel: 3, maxLevel: 4, title: "Far Seer" },
            { minLevel: 5, maxLevel: 6, title: "Prophet" },
            { minLevel: 7, maxLevel: 8, title: "Wise One" },
            { minLevel: 9, maxLevel: 10, title: "Baba" }
        ]
    },
    {
        class: "Witch",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Whisperer" },
            { minLevel: 3, maxLevel: 4, title: "Hexer" },
            { minLevel: 5, maxLevel: 6, title: "Hag/Elder" },
            { minLevel: 7, maxLevel: 8, title: "Crone/Uncle" },
            { minLevel: 9, maxLevel: 10, title: "Baba" }
        ]
    },
    {
        class: "Witch",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Shaman" },
            { minLevel: 3, maxLevel: 4, title: "Conjurer" },
            { minLevel: 5, maxLevel: 6, title: "Soothsayer" },
            { minLevel: 7, maxLevel: 8, title: "Conduit" },
            { minLevel: 9, maxLevel: 10, title: "Baba" }
        ]
    },

    {
        class: "Desert Rider",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Outrider" },
            { minLevel: 3, maxLevel: 4, title: "Sandrunner" },
            { minLevel: 5, maxLevel: 6, title: "Trailblazer" },
            { minLevel: 7, maxLevel: 8, title: "Swift Wind" },
            { minLevel: 9, maxLevel: 10, title: "Stormrunner" }
        ]
    },
    {
        class: "Desert Rider",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Bandit" },
            { minLevel: 3, maxLevel: 4, title: "Robber" },
            { minLevel: 5, maxLevel: 6, title: "Raider" },
            { minLevel: 7, maxLevel: 8, title: "Scourge" },
            { minLevel: 9, maxLevel: 10, title: "Bandit King/Queen" }
        ]
    },
    {
        class: "Desert Rider",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Rat" },
            { minLevel: 3, maxLevel: 4, title: "Fox" },
            { minLevel: 5, maxLevel: 6, title: "Wolf" },
            { minLevel: 7, maxLevel: 8, title: "Tiger" },
            { minLevel: 9, maxLevel: 10, title: "Dragon" }
        ]
    },

    {
        class: "Pit Fighter",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Rookie" },
            { minLevel: 3, maxLevel: 4, title: "Gladiator" },
            { minLevel: 5, maxLevel: 6, title: "Hero" },
            { minLevel: 7, maxLevel: 8, title: "Champion" },
            { minLevel: 9, maxLevel: 10, title: "Legend" }
        ]
    },
    {
        class: "Pit Fighter",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Ruffian" },
            { minLevel: 3, maxLevel: 4, title: "Brawler" },
            { minLevel: 5, maxLevel: 6, title: "Heel" },
            { minLevel: 7, maxLevel: 8, title: "Villain" },
            { minLevel: 9, maxLevel: 10, title: "Legend" }
        ]
    },
    {
        class: "Pit Fighter",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Underdog" },
            { minLevel: 3, maxLevel: 4, title: "Dark Horse" },
            { minLevel: 5, maxLevel: 6, title: "Wild Card" },
            { minLevel: 7, maxLevel: 8, title: "Victor" },
            { minLevel: 9, maxLevel: 10, title: "Legend" }
        ]
    },

    {
        class: "Ras-Godai",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Acolyte" },
            { minLevel: 3, maxLevel: 4, title: "Mirror Path" },
            { minLevel: 5, maxLevel: 6, title: "Monk" },
            { minLevel: 7, maxLevel: 8, title: "Master" },
            { minLevel: 9, maxLevel: 10, title: "White Lotus" }
        ]
    },
    {
        class: "Ras-Godai",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Acolyte" },
            { minLevel: 3, maxLevel: 4, title: "Shadow Path" },
            { minLevel: 5, maxLevel: 6, title: "Monk" },
            { minLevel: 7, maxLevel: 8, title: "Assassin" },
            { minLevel: 9, maxLevel: 10, title: "Black Lotus" }
        ]
    },
    {
        class: "Ras-Godai",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Acolyte" },
            { minLevel: 3, maxLevel: 4, title: "Fire Path" },
            { minLevel: 5, maxLevel: 6, title: "Monk" },
            { minLevel: 7, maxLevel: 8, title: "Demon Blade" },
            { minLevel: 9, maxLevel: 10, title: "Red Lotus" }
        ]
    },

    {
        class: "Sea Wolf",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Freefolk" },
            { minLevel: 3, maxLevel: 4, title: "Shieldman/maiden" },
            { minLevel: 5, maxLevel: 6, title: "Thane" },
            { minLevel: 7, maxLevel: 8, title: "Jarl" },
            { minLevel: 9, maxLevel: 10, title: "King/Queen" }
        ]
    },
    {
        class: "Sea Wolf",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Rabble" },
            { minLevel: 3, maxLevel: 4, title: "Raider" },
            { minLevel: 5, maxLevel: 6, title: "Reaver" },
            { minLevel: 7, maxLevel: 8, title: "Conqueror" },
            { minLevel: 9, maxLevel: 10, title: "Usurper" }
        ]
    },
    {
        class: "Sea Wolf",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Wanderer" },
            { minLevel: 3, maxLevel: 4, title: "Explorer" },
            { minLevel: 5, maxLevel: 6, title: "Adventurer" },
            { minLevel: 7, maxLevel: 8, title: "Renowned" },
            { minLevel: 9, maxLevel: 10, title: "Legendary" }
        ]
    },

    {
        class: "Seer",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Guide" },
            { minLevel: 3, maxLevel: 4, title: "Chanter" },
            { minLevel: 5, maxLevel: 6, title: "Rune Reader" },
            { minLevel: 7, maxLevel: 8, title: "Wise One" },
            { minLevel: 9, maxLevel: 10, title: "Seer of Odin" }
        ]
    },
    {
        class: "Seer",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Hedge Witch" },
            { minLevel: 3, maxLevel: 4, title: "Whisperer" },
            { minLevel: 5, maxLevel: 6, title: "Bone Reader" },
            { minLevel: 7, maxLevel: 8, title: "Dreaded One" },
            { minLevel: 9, maxLevel: 10, title: "Seer of Loki" }
        ]
    },
    {
        class: "Seer",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Fortune Teller" },
            { minLevel: 3, maxLevel: 4, title: "Singer" },
            { minLevel: 5, maxLevel: 6, title: "Star Reader" },
            { minLevel: 7, maxLevel: 8, title: "Blessed One" },
            { minLevel: 9, maxLevel: 10, title: "Seer of Freya" }
        ]
    },

    {
        class: "Warlock",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Favored" },
            { minLevel: 3, maxLevel: 4, title: "Herald" },
            { minLevel: 5, maxLevel: 6, title: "Eminent" },
            { minLevel: 7, maxLevel: 8, title: "Exalted" },
            { minLevel: 9, maxLevel: 10, title: "Incarnation" }
        ]
    },
    {
        class: "Warlock",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Marked" },
            { minLevel: 3, maxLevel: 4, title: "Zealot" },
            { minLevel: 5, maxLevel: 6, title: "Occultist" },
            { minLevel: 7, maxLevel: 8, title: "Champion" },
            { minLevel: 9, maxLevel: 10, title: "Harbinger" }
        ]
    },
    {
        class: "Warlock",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Chosen" },
            { minLevel: 3, maxLevel: 4, title: "Channeler" },
            { minLevel: 5, maxLevel: 6, title: "Prophesied" },
            { minLevel: 7, maxLevel: 8, title: "Transcendent" },
            { minLevel: 9, maxLevel: 10, title: "Avatar" }
        ]
    },

    // Unnatural Selection

    {
        class: "Beastmaster",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Cub" },
            { minLevel: 3, maxLevel: 4, title: "Animal" },
            { minLevel: 5, maxLevel: 6, title: "Warden" },
            { minLevel: 7, maxLevel: 8, title: "Marshal" },
            { minLevel: 9, maxLevel: 10, title: "Packmaster" }
        ]
    },
    {
        class: "Beastmaster",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Shifter" },
            { minLevel: 3, maxLevel: 4, title: "Stalker" },
            { minLevel: 5, maxLevel: 6, title: "Prowler" },
            { minLevel: 7, maxLevel: 8, title: "Predator" },
            { minLevel: 9, maxLevel: 10, title: "Apex Predator" }
        ]
    },
    {
        class: "Beastmaster",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Forager" },
            { minLevel: 3, maxLevel: 4, title: "Metamorph" },
            { minLevel: 5, maxLevel: 6, title: "Pathfinder" },
            { minLevel: 7, maxLevel: 8, title: "Alpha" },
            { minLevel: 9, maxLevel: 10, title: "Beast King" }
        ]
    },

    {
        class: "Fiend",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Stellarite" },
            { minLevel: 3, maxLevel: 4, title: "Solarion" },
            { minLevel: 5, maxLevel: 6, title: "Starforger" },
            { minLevel: 7, maxLevel: 8, title: "Celestialist" },
            { minLevel: 9, maxLevel: 10, title: "Star Master" }
        ]
    },
    {
        class: "Fiend",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Imp" },
            { minLevel: 3, maxLevel: 4, title: "Menace" },
            { minLevel: 5, maxLevel: 6, title: "Hellion" },
            { minLevel: 7, maxLevel: 8, title: "Demon" },
            { minLevel: 9, maxLevel: 10, title: "Demon King" }
        ]
    },
    {
        class: "Fiend",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Emberite" },
            { minLevel: 3, maxLevel: 4, title: "Incendian" },
            { minLevel: 5, maxLevel: 6, title: "Volcanist" },
            { minLevel: 7, maxLevel: 8, title: "Pyromancer" },
            { minLevel: 9, maxLevel: 10, title: "Lava Lord" }
        ]
    },

    {
        class: "Grave Warden",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Disciple" },
            { minLevel: 3, maxLevel: 4, title: "Adherent" },
            { minLevel: 5, maxLevel: 6, title: "Devotee" },
            { minLevel: 7, maxLevel: 8, title: "Archon" },
            { minLevel: 9, maxLevel: 10, title: "Paragon of Crossing" }
        ]
    },
    {
        class: "Grave Warden",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Aspirant" },
            { minLevel: 3, maxLevel: 4, title: "Postulant" },
            { minLevel: 5, maxLevel: 6, title: "Revenant" },
            { minLevel: 7, maxLevel: 8, title: "Necromancer" },
            { minLevel: 9, maxLevel: 10, title: "Underlord" }
        ]
    },
    {
        class: "Grave Warden",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Neophyte" },
            { minLevel: 3, maxLevel: 4, title: "Reanimator" },
            { minLevel: 5, maxLevel: 6, title: "Soulkeeper" },
            { minLevel: 7, maxLevel: 8, title: "Dominator" },
            { minLevel: 9, maxLevel: 10, title: "Afterlife Sovereign" }
        ]
    },

    {
        class: "Ovate",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Custodian" },
            { minLevel: 3, maxLevel: 4, title: "Steward" },
            { minLevel: 5, maxLevel: 6, title: "Guardian" },
            { minLevel: 7, maxLevel: 8, title: "Sentinel" },
            { minLevel: 9, maxLevel: 10, title: "Warden" }
        ]
    },
    {
        class: "Ovate",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Tamer" },
            { minLevel: 3, maxLevel: 4, title: "Subjugator" },
            { minLevel: 5, maxLevel: 6, title: "Controller" },
            { minLevel: 7, maxLevel: 8, title: "Nature Tyrant" },
            { minLevel: 9, maxLevel: 10, title: "Overlord" }
        ]
    },
    {
        class: "Ovate",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Green" },
            { minLevel: 3, maxLevel: 4, title: "Balancer" },
            { minLevel: 5, maxLevel: 6, title: "Harmonist" },
            { minLevel: 7, maxLevel: 8, title: "Druid" },
            { minLevel: 9, maxLevel: 10, title: "Archdruid" }
        ]
    },

    {
        class: "Plague Doctor",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Resident" },
            { minLevel: 3, maxLevel: 4, title: "Physician" },
            { minLevel: 5, maxLevel: 6, title: "Remediator" },
            { minLevel: 7, maxLevel: 8, title: "Curator" },
            { minLevel: 9, maxLevel: 10, title: "Master Curator" }
        ]
    },
    {
        class: "Plague Doctor",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Proselyte" },
            { minLevel: 3, maxLevel: 4, title: "Afflictor" },
            { minLevel: 5, maxLevel: 6, title: "Contagionist" },
            { minLevel: 7, maxLevel: 8, title: "Epidemologist" },
            { minLevel: 9, maxLevel: 10, title: "Pestilence Herald" }
        ]
    },
    {
        class: "Plague Doctor",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Tincturer" },
            { minLevel: 3, maxLevel: 4, title: "Elixirist" },
            { minLevel: 5, maxLevel: 6, title: "Chemist" },
            { minLevel: 7, maxLevel: 8, title: "Alchemist" },
            { minLevel: 9, maxLevel: 10, title: "Grand Alchemist" }
        ]
    },

    {
        class: "Shaman",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Empath" },
            { minLevel: 3, maxLevel: 4, title: "Mystic" },
            { minLevel: 5, maxLevel: 6, title: "Healer" },
            { minLevel: 7, maxLevel: 8, title: "Lightworke" },
            { minLevel: 9, maxLevel: 10, title: "Ascendant" }
        ]
    },
    {
        class: "Shaman",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Tyro" },
            { minLevel: 3, maxLevel: 4, title: "Hexer" },
            { minLevel: 5, maxLevel: 6, title: "Totemist" },
            { minLevel: 7, maxLevel: 8, title: "Enigma" },
            { minLevel: 9, maxLevel: 10, title: "Witchdoctor" }
        ]
    },
    {
        class: "Shaman",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Medium" },
            { minLevel: 3, maxLevel: 4, title: "Communer" },
            { minLevel: 5, maxLevel: 6, title: "Soul Caller" },
            { minLevel: 7, maxLevel: 8, title: "Spirit Guide" },
            { minLevel: 9, maxLevel: 10, title: "Visionary" }
        ]
    },

    {
        class: "Roustabout",
        alignment: "Lawful",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Handyman/woman" },
            { minLevel: 3, maxLevel: 4, title: "Craftsman/woman" },
            { minLevel: 5, maxLevel: 6, title: "Foreman" },
            { minLevel: 7, maxLevel: 8, title: "Master Craftsman/woman" },
            { minLevel: 9, maxLevel: 10, title: "Guild Leader" }
        ]
    },
    {
        class: "Roustabout",
        alignment: "Chaotic",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Rascal" },
            { minLevel: 3, maxLevel: 4, title: "Hustler" },
            { minLevel: 5, maxLevel: 6, title: "Rogue" },
            { minLevel: 7, maxLevel: 8, title: "Rebel" },
            { minLevel: 9, maxLevel: 10, title: "Renegade" }
        ]
    },
    {
        class: "Roustabout",
        alignment: "Neutral",
        titleForLevels: [
            { minLevel: 1, maxLevel: 2, title: "Drifter" },
            { minLevel: 3, maxLevel: 4, title: "Vagabond" },
            { minLevel: 5, maxLevel: 6, title: "Seeker" },
            { minLevel: 7, maxLevel: 8, title: "Wayfinder" },
            { minLevel: 9, maxLevel: 10, title: "Nomad King/Queen" }
        ]
    },

]
import { getAllAncestries } from "../../data/ancestries";
import { getAllCharClasses } from "../../data/classes";
import { activeSourceFilteredAncestry, activeSourceFilteredClass, defaultSources } from "../../data/sources";

export const getCoreRulesOnlyFromLocalStorage = () => {
    const coreRulesOnly: any = localStorage.getItem("coreRulesOnly");
    if (coreRulesOnly === null) {
        localStorage.setItem("coreRulesOnly", "true");
        return true;
    }
    if (coreRulesOnly && coreRulesOnly === "true") {
        return true;
    }
    return false;
}

export const setCoreRulesOnlyInLocalStorage = (showCoreRules: boolean) => {
    const storeAs = !showCoreRules ? "true" : "false";
    localStorage.setItem("coreRulesOnly", storeAs);
}

export const getDesignModeFromLocalStorage = () => {
    const randomType: any = localStorage.getItem("designMode");
    if (randomType === null) {
        localStorage.setItem("designMode", "Design");
        return "Design";
    }
    return randomType;
}

export const setDesignModeInLocalStorage = (designMode: string) => {
    localStorage.setItem("designMode", designMode);
}

export const getRandomTypeFromLocalStorage = () => {
    const randomType: any = localStorage.getItem("randomType");
    if (randomType === null) {
        localStorage.setItem("randomType", "Best Fit");
        return "Best Fit";
    }
    if (randomType === "Highest") {
        return "Best Fit";
    }
    return randomType;
}

export const setRandomTypeInLocalStorage = (randomType: string) => {
    localStorage.setItem("randomType", randomType);
}


// export const getIsMinimalFromLocalStorage = () => {
//     const min: any = localStorage.getItem("minimal");
//     if (min === null) {
//         localStorage.setItem("minimal", "true");
//         return true;
//     }
//     if (min && min === "true") {
//         return true;
//     }
//     return false;
// }

export const setIsMinimalInLocalStorage = (isMinimal: boolean) => {
    const min = !isMinimal ? "true" : "false";
    localStorage.setItem("minimal", min);
}

export const getActiveSourcesFromLocalStorage = () => {
    const activeSources: any = localStorage.getItem("activeSources");
    if (activeSources === null) {
        localStorage.setItem("activeSources", defaultSources.join(","));
        return defaultSources;
    }
    if (activeSources) {
        return activeSources.split(",").map((as: string) => as.trim());
    }
    return ["SD"];
}

export const setActiveSourcesInLocalStorage = (activeSources: string[]) => {
    localStorage.setItem("activeSources", activeSources.join(","));
}

export const getActiveSourcesFilteredAncestriesFromLocalStorage = (): activeSourceFilteredAncestry[] => {
    const activeSourceFilteredAncestries: string | null = localStorage.getItem("activeSourcesFilteredAncestries");
    if (activeSourceFilteredAncestries !== null && activeSourceFilteredAncestries.trim() !== "") {
        const result: activeSourceFilteredAncestry[] = JSON.parse(activeSourceFilteredAncestries)
        return result;
    } else {
        // by default populate with all ancestries from active sources
        const activeSources: string | null = localStorage.getItem("activeSources");
        if (activeSources) {
            let filteredAncestries: activeSourceFilteredAncestry[] = [];
            let allAncestries = getAllAncestries();
            allAncestries.forEach((a) => {
                const isActive: boolean = activeSources.split(",").indexOf((a.sourceId)) !== -1;
                const newEntry: activeSourceFilteredAncestry = { sourceId: a.sourceId, ancestry: a.name, isActive };
                filteredAncestries.push(newEntry);
            })
            return filteredAncestries;
        } else {
            return [];
        }
    }

}

export const setActiveSourcesFilteredAncestriesInLocalStorage = (activeSourceFilteredAncestries: activeSourceFilteredAncestry[]) => {
    localStorage.setItem("activeSourcesFilteredAncestries", JSON.stringify(activeSourceFilteredAncestries));
}

export const getActiveSourcesFilteredClassesFromLocalStorage = (): activeSourceFilteredClass[] => {
    const activeSourceFilteredClasses: string | null = localStorage.getItem("activeSourcesFilteredClasses");
    if (activeSourceFilteredClasses !== null && activeSourceFilteredClasses.trim() !== "") {
        const result: activeSourceFilteredClass[] = JSON.parse(activeSourceFilteredClasses)
        return result;
    }// by default populate with all ancestries from active sources
    const activeSources: string | null = localStorage.getItem("activeSources");
    if (activeSources) {
        let filteredClasses: activeSourceFilteredClass[] = [];
        let allClasses = getAllCharClasses().filter((c) => c.name !== "Level 0");
        allClasses.forEach((a) => {
            const isActive: boolean = activeSources.split(",").indexOf((a.sourceId)) !== -1;
            const newEntry: activeSourceFilteredClass = { sourceId: a.sourceId, charClass: a.name, isActive };
            filteredClasses.push(newEntry);
        })
        return filteredClasses;
    } else {
        return [];
    }
}

export const setActiveSourcesFilteredClassesInLocalStorage = (activeSourceFilteredClasses: activeSourceFilteredClass[]) => {
    localStorage.setItem("activeSourcesFilteredClasses", JSON.stringify(activeSourceFilteredClasses));
}


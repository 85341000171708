interface IProps { }


const DeserRiderTables: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <div className="twoCols">
            <table className='herbTable elixirTable leftAlign mb-4'>
                <tbody>
                    <tr className='herbTableHeader'>
                        <th>Horse</th>
                    </tr>
                    <tr>
                        <td><i>Powerful, swift herd animals that roam open plains.</i></td>
                    </tr>
                    <tr>
                        <td><b>AC</b> 11, <b>HP</b> 11, <b>ATK</b> 1 hooves +3 (1d6), <b>MV</b> double near, <b>S</b> +3, <b>D</b> +1, <b>C</b> +2, <b>I</b> -3, <b>W</b> +1, <b>Ch</b> -2, <b>AL</b> N, <b>LV</b> 2</td>
                    </tr>
                </tbody>
            </table>
            <table className='herbTable elixirTable leftAlign mb-4'>
                <tbody>
                    <tr className='herbTableHeader'>
                        <th>Camel</th>
                    </tr>
                    <tr>
                        <td><i>Ornery, tan-furred desert beasts.</i></td>
                    </tr>
                    <tr>
                        <td><b>AC</b> 10, <b>HP</b> 12, <b>ATK</b> 1 hoof +3 (1d6) or spit (near) +0 (1d4), <b>MV</b> double near, <b>S</b> +3, <b>D</b> +0, <b>C</b> +3, <b>I</b> -2, <b>W</b> +1, <b>Ch</b> -3, <b>AL</b> N, <b>LV</b> 2</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DeserRiderTables;

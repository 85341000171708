import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { useState } from "react";
import { Background } from "../../data/backgrounds";
import { Source } from "../../data/sources";

interface IProps {
    sources: Source[];
    backgrounds: Background[];
    selectedBackground: string;
    rollBackground: (sourceId: string) => void;
}

const RollBackground: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const [selectedBackgroundSource, setSelectedBackgroundSource] = useState("SD" as string);

    const getBgDesc = () => {
        const bgDesc = props.backgrounds.find((bg) => bg.name === props.selectedBackground);
        if (bgDesc) {
            return <div><b>{props.selectedBackground}:</b> {bgDesc.desc}.</div>
        }
        return null;
    }

    const getSourceOptions = () => {
        const out: any[] = [];
        const availSources = props.sources.filter((s) => globalContext.activeSources.indexOf(s.id) !== -1);
        if (availSources.filter((as) => as.backgroundsName !== undefined).length === 1) {
            return null; // default to just using standard backgrounds;
        }
        availSources.forEach((as, index) => {
            if (as.backgroundsName !== undefined) {
                out.push(
                    <div className="form-check form-switch form-check-inline" key={"bgsrc_" + index}>
                        <input className="form-check-input" type="checkbox" role="switch" id={"bgSourceOption_" + index} checked={as.id === selectedBackgroundSource} onChange={() => setSelectedBackgroundSource(as.id)} />
                        <label className="form-check-label small" htmlFor={"bgSourceOption_" + index}>{as.backgroundsName}</label>
                    </div>
                );
            }
        })
        return out;
    }

    const rollBackground = (selectedBackgroundSource: string) => {
        props.rollBackground(selectedBackgroundSource)
        setSelectedBackgroundSource("SD");
    }

    const bgName = props.selectedBackground !== "" ? ": " + props.selectedBackground : "";

    return (
        <div className="sectionBorder">
            <h2>Background{bgName}</h2>

            <div className="">

                {props.selectedBackground === "" &&
                    <>
                        {getSourceOptions()}
                        < div >
                            <button className="btn btn-dark" onClick={() => rollBackground(selectedBackgroundSource)}>Roll for Background</button>
                            <div className="valError">Please roll for a background</div>
                        </div>
                    </>
                }

                <>{getBgDesc()}</>

            </div >
        </div>
    )

}

export default RollBackground;
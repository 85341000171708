import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByNameAndBonusName, getBonusByNameAndBonusNameAndBonusTo } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level; 
    setBonus: (bonus: Bonus) => void;
}

const Plus1ToAttacksOrDamage: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setPlus1ToAttacks = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus1ToAttacksOrDamage",
            bonusName: "Plus1ToHit",
            bonusTo: "Melee and ranged attacks"
        };
        props.setBonus(bonus);
    }

    const setPlus1ToDamage = () => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "Plus1ToAttacksOrDamage",
            bonusName: "Plus1ToDamage",
            bonusTo: ""
        };
        props.setBonus(bonus);
    }

    const plus1ToHitSelected = getBonusByNameAndBonusNameAndBonusTo(globalContext.bonuses,  "Plus1ToAttacksOrDamage", "Plus1ToHit", "Melee and ranged attacks", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const plus1ToDamageSelected = getBonusByNameAndBonusName(globalContext.bonuses,  "Plus1ToAttacksOrDamage", "Plus1ToDamage", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;

    const req = !plus1ToHitSelected && !plus1ToDamageSelected;

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setPlus1ToAttacks()} checked={plus1ToHitSelected}></input>&nbsp;+1 to attacks</label>
            <label><input type="radio" onChange={() => setPlus1ToDamage()} checked={plus1ToDamageSelected}></input>&nbsp;+1 to damage</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default Plus1ToAttacksOrDamage;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import PickTalent from '../talents/PickTalent';
import SelectTalentDetails from "./selectTalentDetails";
import { Bonus } from "../../data/bonus";
import { Level } from '../../data/level';
import { Spell } from '../../data/spells';
import { Talent, getAllCharClasses } from "../../data/classes";
import { getRandomIntInclusive } from '../../data/random';

interface IProps {
    isSpecialTalent: boolean;
    specialTalents: Talent[];
    spellsKnown: Spell[];
    isAmbitionTalent: boolean;
    isCheatMode: boolean;
    isMinimal: boolean;
    theClass: string;
    setBonus: (bonus: Bonus) => void;
    level: Level;
    setRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean, parentBonusId?: string) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
    boonPatron: string;
    boonSource: string;
    parentBonusId?: string;
}

const RollForRoustaboutExtraTalentsOnRoll2: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const rollForTalent = () => {
        const roll = getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6);
        if (globalContext.charClass) {
            const theTalent = globalContext.charClass.talents.find((t) => roll >= t.min && roll <= t.max);
            if (theTalent) {
                props.setRolledTalent(props.level.level, theTalent.desc, theTalent.name, props.isAmbitionTalent);
            } else {
                rollForTalent();
            }
        }
    }

    const getTalentHasBeenSelected = () => {
        if (props.parentBonusId !== undefined) {
            const theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
            if (theBonus) {
                return theBonus.name !== "";
            }
            return false;
        } else if (!props.isAmbitionTalent) {
            return props.level.talentRolledName !== "";
        } else {
            return globalContext.ambitionTalentLevel.talentRolledName !== "";
        }
    }

    // const getTalentDesc = () => {
    //     if (props.parentBonusId !== undefined) {
    //         const theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
    //         if (theBonus) {
    //             const charClass = globalContext.availableClasses.find((cc) => cc.name === props.theClass);
    //             if (charClass) {
    //                 const theTalent = charClass.talents.find((t) => t.name === theBonus.name);
    //                 if (theTalent) { return theTalent.desc; }
    //             }
    //             return theBonus.name
    //         }
    //         return false;
    //     } else if (!props.isAmbitionTalent) {
    //         return props.level.talentRolledDesc;
    //     } else {
    //         return globalContext.ambitionTalentLevel.talentRolledDesc;
    //     }
    // }

    const getTheSelectedTalent = () => {
        let selectedTalent: Talent = { min: 0, max: 0, desc: '', name: "" };
        let talentName = "";
        if (props.parentBonusId !== undefined) {
            const theBonus = globalContext.bonuses.find((b) => b.parentBonusId === props.parentBonusId);
            if (theBonus) { talentName = theBonus.name; }
        } else if (props.isAmbitionTalent) {
            talentName = globalContext.ambitionTalentLevel.talentRolledName;
        } else {
            talentName = props.level.talentRolledName;
        }
        if (globalContext.charClass) {
            let theTalent = globalContext.charClass.talents.find((t) => t.name === talentName);
            if (theTalent) {
                selectedTalent = theTalent;
            }
        }
        return selectedTalent;
    }

    const talentHasBeenSelected = getTalentHasBeenSelected();
    const selectedTalent = getTheSelectedTalent();

    let sourceType = "Class";
    let sourceName = globalContext.className;
    let btnText = "Roll for Class Talent";
    let heading = "Class Talent";
    // let talentDesc = props.level.talentRolledDesc;
    if (props.isAmbitionTalent) {
        sourceType = "Ancestry";
        sourceName = "Human Ambition";
        btnText = "Roll for Human Ambition Talent";
        heading = "Human Ambition Talent";
        // talentDesc = globalContext.ambitionTalentLevel.talentRolledDesc;
    }
    const talentDesc = selectedTalent.desc;

    const roustabout = getAllCharClasses().find((c) => c.name === "Roustabout");
    let roustaboutTalents: Talent[] = [];
    if (roustabout) { roustaboutTalents = [...roustabout.talents]; }

    return (
        <div className="mb-3">

            {!props.isCheatMode && !talentHasBeenSelected &&
                <>
                    <button className="btn btn-dark" onClick={() => rollForTalent()} disabled={getTalentHasBeenSelected()}>{btnText}</button>&nbsp;
                    {!getTalentHasBeenSelected() &&
                        <div className="valError">Please roll for a talent</div>
                    }
                </>
            }

            {props.isCheatMode &&
                <>
                    <PickTalent
                        isSpecialTalent={true}
                        thisLevel={props.level}
                        specialTalents={roustaboutTalents}
                        sourceType={sourceType}
                        sourceName={sourceName}
                        sourceCategory={"Talent"}
                        boonPatron={""}
                        boonSource={""}
                        isAmbitionTalent={props.isAmbitionTalent}
                        isCheatMode={props.isCheatMode}
                        isMinimal={props.isMinimal}
                        talent={selectedTalent}
                        theClass={props.theClass}
                        setBonus={(bonus: Bonus) => props.setBonus(bonus)}
                        onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                        onSetRolledTalent={props.onSetRolledTalent}
                        onSetSpecialTalent={props.onSetSpecialTalent}
                        spellsKnown={props.spellsKnown}
                        parentBonusId={props.parentBonusId}
                    />
                </>
            }

            {talentHasBeenSelected &&
                <>
                    <div><b>{heading}:</b> {talentDesc}</div>
                    <div>
                        <SelectTalentDetails
                            isSpecialTalent={true}
                            thisLevel={props.level}
                            specialTalents={props.specialTalents}
                            sourceType={sourceType}
                            sourceName={sourceName}
                            boonPatron={props.boonPatron}
                            boonSource={props.boonSource}
                            sourceCategory="Talent"
                            isAmbitionTalent={props.isAmbitionTalent}
                            isCheatMode={props.isCheatMode}
                            isMinimal={props.isMinimal}
                            theClass={props.theClass}
                            spellsKnown={props.spellsKnown}
                            includeSelectTalentOption={true}
                            onSetBonus={(bonus: Bonus) => props.setBonus(bonus)}
                            onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                            onSetRolledTalent={props.onSetRolledTalent}
                            onSetSpecialTalent={props.onSetSpecialTalent}
                            parentBonusId={props.parentBonusId}
                        />
                    </div>
                </>
            }
        </div>
    )

}

export default RollForRoustaboutExtraTalentsOnRoll2;
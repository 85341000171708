import { useContext, useEffect, useState } from 'react';
import { GlobalContextType } from "../context";
import { CreateCharacterContext } from "../createCharacter";
import { MagicItem } from "../../data/magicItem";

interface IProps {
    itemId: string;
    magicItemCatalog: MagicItem[];
    magicItemsCarried: MagicItem[];
    onAddMagicItem: (itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string, properties: string[]) => void;
    onEditMagicItem: (id: string, itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string) => void;
    onCancel: () => void;
}

const EditMagicItemsDetails: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const [itemTypeId, setItemTypeId] = useState("");

    const [magicItemType, setMagicItemType] = useState("");
    const [name, setName] = useState("");
    const [bonus, setBonus] = useState(0);
    const [bonusNote, setBonusNote] = useState("");
    const [attackNote, setAttackNote] = useState("");
    const [features, setFeatures] = useState("");
    const [benefits, setBenefits] = useState("");
    const [curses, setCurses] = useState("");
    const [hasPersonality, setHasPersonality] = useState(false);
    const [virtue, setVirtue] = useState("");
    const [flaw, setFlaw] = useState("");
    const [trait, setTrait] = useState("");
    const [properties, setProperties] = useState([] as string[]);

    const [editedMagicItemId, setEditedMagicItemId] = useState("");

    const isAddMode = props.itemId.trim() === "";

    useEffect(() => {
        if (props.itemId.trim() !== "") {
            editMagicItem(props.itemId);
        }
    }, [])

    const editMagicItem = (id: string) => {
        const t = props.magicItemsCarried.find((x) => x.id === id);
        if (t) {
            setEditedMagicItemId(id);
            setMagicItemType(t.magicItemType);
            setItemTypeId(t.itemTypeId);
            setBonus(t.bonus);
            if (t.bonusNote) {
                setBonusNote(t.bonusNote);
            }
            setAttackNote(t.attackNote);
            setName(t.name);
            setFeatures(t.features);
            setBenefits(t.benefits);
            setCurses(t.curses);
            setHasPersonality(t.hasPersonality);
            if (t.hasPersonality) {
                if (t.personalityVirtue) { setVirtue(t.personalityVirtue); }
                if (t.personalityFlaws) { setFlaw(t.personalityFlaws); }
                if (t.personalityTraits) { setTrait(t.personalityTraits); }
            }
            if (t.properties) {
                setProperties(t.properties);
            }
        } else {
            alert("not found");
        }
    }

    const reset = () => {
        setItemTypeId("");
        setName("");
        setBonus(0);
        setBonusNote("");
        setAttackNote("");
        setFeatures("");
        setBenefits("");
        setCurses("");
        setHasPersonality(false);
        setVirtue("");
        setFlaw("");
        setTrait("");
        setProperties([]);
    }

    const getItemTypeFromMagicItemType = (magicItemType: string) => {
        if (magicItemType === "magicWeapon") { return "weapon"; }
        if (magicItemType === "magicArmor") { return "armor"; }
        return "sundry";
    }

    const addMagicItem = () => {
        if ((itemTypeId !== "" || magicItemType === "magicMisc") && name.trim() !== "") {
            if (isAddMode) {
                props.onAddMagicItem(getItemTypeFromMagicItemType(magicItemType), itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, virtue, flaw, trait, properties);
            } else {
                props.onEditMagicItem(editedMagicItemId, getItemTypeFromMagicItemType(magicItemType), itemTypeId, magicItemType, name, bonus, bonusNote, attackNote, features, benefits, curses, hasPersonality, virtue, flaw, trait);
            }
            reset();
        }
    }

    const getWeaponOptions = () => {
        let out: any[] = [];
        out.push(<option value="" key="selectWeapon">-- select weapon type --</option>)
        globalContext.availableWeapons.sort((w1, w2) => w1.name < w2.name ? -1 : 1).forEach((w) => {
            out.push(<option value={w.id} key={w.id}>{w.name}</option>)
        })
        return out;
    }

    const getArmorOptions = () => {
        let out: any[] = [];
        out.push(<option value="" key="selectArmpr">-- select armor type --</option>)
        globalContext.availableArmors.forEach((w) => {
            out.push(<option value={w.id} key={w.id}>{w.name}</option>)
        })
        return out;
    }

    const handleSetWeaponId = (theWeaponId: string) => {
        setItemTypeId(theWeaponId);
        const theWeapon = globalContext.availableWeapons.find((w) => w.id === theWeaponId);
        if (theWeapon) {
            setName(theWeapon.name + " +" + bonus);
        }
    }

    const handleSetArmorId = (theArmorId: string) => {
        setItemTypeId(theArmorId);
        const theArmor = globalContext.availableArmors.find((w) => w.id === theArmorId);
        if (theArmor) {
            setName(theArmor.name + " +" + bonus);
        }
    }

    const handleSetBonus = (bonus: number) => {
        setBonus(bonus);
        if (magicItemType === "magicWeapon") {
            const theWeapon = globalContext.availableWeapons.find((w) => w.id === itemTypeId);
            if (theWeapon) {
                setName(theWeapon.name + " +" + bonus);
            }
        }
        if (magicItemType === "magicArmor") {
            const theArmor = globalContext.availableArmors.find((w) => w.id === itemTypeId);
            if (theArmor) {
                setName(theArmor.name + " +" + bonus);
            }
        }
    }

    const handleSetHasPersonality = (hasPers: boolean) => {
        setHasPersonality(hasPers);
    }

    const onSelectMagicItemFromCatalog = (id: string) => {
        const t = props.magicItemCatalog.find((x) => x.id === id);
        if (t) {
            setEditedMagicItemId(id);
            setMagicItemType(t.magicItemType);
            setItemTypeId(t.itemTypeId);
            setBonus(t.bonus);
            setBonusNote(t.bonusNote);
            setAttackNote(t.attackNote);
            setName(t.name);
            setFeatures(t.features);
            setBenefits(t.benefits);
            setCurses(t.curses);
            setHasPersonality(t.hasPersonality);
            if (t.hasPersonality) {
                if (t.personalityVirtue) { setVirtue(t.personalityVirtue); }
                if (t.personalityFlaws) { setFlaw(t.personalityFlaws); }
                if (t.personalityTraits) { setTrait(t.personalityTraits); }
            }
            if (t.properties) {
                setProperties(t.properties);
            }
        } else {
            alert("not found");
        }
    }

    const getCatalogOptions = () => {
        let out: any[] = [];
        out.push(<option value="" key="selectItem">-- select magic item --</option>)
        props.magicItemCatalog.sort((m1, m2) => m1.name < m2.name ? -1 : 1).forEach((w) => {
            out.push(<option value={w.id} key={w.id}>{w.name}</option>)
        })
        return out;
    }

    const onCancel = () => {
        reset();
        props.onCancel();
    }

    return (
        <div>

            <div className="container">

                <h3>{isAddMode ? "Add" : "Edit"} Magic Item</h3>

                {isAddMode &&
                    <div className="form-group mb-2">
                        <div className="formNote">Optional: Choose item from Magic Item Catalog</div>
                        <div className="input-group">
                            <select className="form-select" onChange={(e) => onSelectMagicItemFromCatalog(e.target.value)}>
                                {getCatalogOptions()}
                            </select>
                        </div>
                    </div>
                }

                <div className="form-group">
                    <div className="formNote">Type of magic item</div>
                    <div className="input-group">
                        <select className="form-select" onChange={(e) => setMagicItemType(e.target.value)} value={magicItemType}>
                            <option value="">-- select type of magic item</option>
                            <option value="magicWeapon">Magic Weapon</option>
                            <option value="magicArmor">Magic Armor</option>
                            <option value="magicMisc">Miscellaneous</option>
                        </select>
                    </div>
                    {magicItemType === "" &&
                        <div className="valError">Please select the type of magic item</div>
                    }
                </div>

                {magicItemType === "magicWeapon" &&
                    <div className="form-group">
                        <div className="formNote">Weapon type</div>
                        <div className="input-group">
                            <select className="form-select" onChange={(e) => handleSetWeaponId(e.target.value)} value={itemTypeId}>
                                {getWeaponOptions()}
                            </select>
                        </div>
                        {itemTypeId === "" &&
                            <div className="valError">Please select the weapon type</div>
                        }
                    </div>
                }

                {magicItemType === "magicArmor" &&
                    <div className="form-group">
                        <div className="formNote">Armor type</div>
                        <div className="input-group">
                            <select className="form-select" onChange={(e) => handleSetArmorId(e.target.value)} value={itemTypeId}>
                                {getArmorOptions()}
                            </select>
                        </div>
                        {itemTypeId === "" &&
                            <div className="valError">Please select the armor type</div>
                        }
                    </div>
                }

                <div className="form-group">
                    <div className="formNote">Bonus</div>
                    <div className="input-group">
                        <select className="form-select cost" onChange={(e) => handleSetBonus(parseInt(e.target.value))} value={bonus}>
                            <option value={0}>+0</option>
                            <option value={1}>+1</option>
                            <option value={2}>+2</option>
                            <option value={3}>+3</option>
                        </select>
                    </div>
                </div>

                <div className="form-group">
                    <div className="formNote">Name</div>
                    <div className="input-group">
                        <input className="form-control treasureName" id="name" type="text" maxLength={20} onChange={(e) => setName(e.target.value)} value={name}></input>
                    </div>
                    {name.trim() === "" &&
                        <div className="valError">Please enter a name</div>
                    }
                </div>

                <div className="form-group">
                    <div className="formNote">Bonus Note</div>
                    <div className="input-group">
                        <textarea className="form-control" id="bonusNote" onChange={(e) => setBonusNote(e.target.value)} value={bonusNote} rows={2}></textarea>
                    </div>
                </div>

                {magicItemType === "magicWeapon" &&
                    <div className="form-group">
                        <div className="formNote">Attacks note</div>
                        <div className="input-group">
                            <input className="form-control treasureName" id="attackNote" type="text" maxLength={20} onChange={(e) => setAttackNote(e.target.value)} value={attackNote}></input>
                        </div>
                    </div>
                }

                <div className="form-group">
                    <div className="formNote">Features/Lore</div>
                    <div className="input-group">
                        <textarea className="form-control" id="features" onChange={(e) => setFeatures(e.target.value)} value={features} rows={2}></textarea>
                    </div>
                </div>

                <div className="form-group">
                    <div className="formNote">Benefits</div>
                    <div className="input-group">
                        <textarea className="form-control" id="benefits" onChange={(e) => setBenefits(e.target.value)} value={benefits} rows={2}></textarea>
                    </div>
                </div>

                <div className="form-group">
                    <div className="formNote">Curses</div>
                    <div className="input-group">
                        <textarea className="form-control" id="curses" onChange={(e) => setCurses(e.target.value)} value={curses} rows={2}></textarea>
                    </div>
                </div>

                <div className="form-group">
                    <div className="formNote">Personality</div>
                    <div className="form-check form-switch form-check-inline mt-0 pt-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="personality" checked={hasPersonality} onChange={() => handleSetHasPersonality(!hasPersonality)} />
                        <label className="form-check-label small" htmlFor="personality">Has a personality</label>
                    </div>
                </div>

                {hasPersonality &&
                    <>
                        <div className="form-group">
                            <div className="formNote">Personality Virtue</div>
                            <div className="input-group">
                                <textarea className="form-control" id="virtue" onChange={(e) => setVirtue(e.target.value)} value={virtue} rows={2}></textarea>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="formNote">Personality Flaw</div>
                            <div className="input-group">
                                <textarea className="form-control" id="flaw" onChange={(e) => setFlaw(e.target.value)} value={flaw} rows={2}></textarea>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="formNote">Personality Traits</div>
                            <div className="input-group">
                                 <textarea className="form-control" id="traits" onChange={(e) => setTrait(e.target.value)} value={trait} rows={2}></textarea>
                            </div>
                        </div>

                    </>

                }

                <div className="form-group">
                    <button className="btn btn-dark me-2" onClick={() => addMagicItem()}>{isAddMode ? "Create" : "Update"}</button>
                    <button className="btn btn-dark" onClick={() => onCancel()}>Cancel</button>
                </div>

            </div>

        </div >
    )

}

export default EditMagicItemsDetails;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import 'react-responsive-modal/styles.css';
import DisplayGold from "../charGen/displayGold";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GearOwned } from "../../data/gear";
import { Modal } from 'react-responsive-modal';
import { Weapon } from "../../data/weapons";
import { faCartShopping, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { getPermittedWeaponsByClassName } from '../../data/utilities';
import { Bonus } from "../../data/bonus";


interface IProps {
    openModal: boolean;
    onlyIncludeProficientWeapons: boolean;
    gearOwned: GearOwned[];
    gearSlotsUsed: number;
    gearSlotsTotal: number;
    bonuses: Bonus[];
    onSetOnlyIncludeProficientWeapons: (setOnlyIncludeProficientWeapons: boolean) => void,
    onBuyItem: (id: string, type: string, isBuy: boolean, cost: number, currency: string) => void;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}

const BuyWeapons: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    let anyFinesse = false;
    let anyLoading = false;
    let anyThrown = false;
    let anyTwoHands = false;
    let anyVersatile = false;
    let anySundering = false;
    let anySniper = false;
    let anyTangling = false;
    let anyLash = false;
    let anyInjectable = false;
    let anyGas = false;

    const getProperties = (w: Weapon) => {
        const out: string[] = [];
        if (w.isSniper) { out.push("BG") }
        if (w.tangling) { out.push("BOL") }
        if (w.finesse) { out.push("F") }
        if (w.gas) { out.push("G") }
        if (w.injectable) { out.push("I") }
        if (w.loading) { out.push("L") }
        if (w.isLash) { out.push("La") }
        if (w.thrown) { out.push("Th") }
        if (w.sundering) { out.push("S") }
        if (w.versatile) { out.push("V") }
        if (w.twoHands) { out.push("2H") }
        if (out.length === 0) { return "-" }
        return out.join(", ")
    }

    const theClass = globalContext.charClass;

    const getWeaponRows2 = () => {

        let weaps: Weapon[] = [];

        const availableWeapons = globalContext.availableWeapons;

        if (props.onlyIncludeProficientWeapons) {
            weaps = getPermittedWeaponsByClassName(globalContext.availableClasses, availableWeapons, props.bonuses, theClass?.name);
        } else {
            weaps = [...availableWeapons];
        }

        const rows: any = [];
        weaps.sort((w1, w2) => w1.name < w2.name ? -1 : 1).forEach((w) => {
            if (w.finesse) { anyFinesse = true; }
            if (w.loading) { anyLoading = true; }
            if (w.thrown) { anyThrown = true; }
            if (w.twoHands) { anyTwoHands = true; }
            if (w.versatile) { anyVersatile = true; }
            if (w.sundering) { anySundering = true; }
            if (w.isSniper) { anySniper = true; }
            if (w.tangling) { anyTangling = true; }
            if (w.isLash) { anyLash = true; }
            if (w.injectable) { anyInjectable = true; }
            if (w.gas) { anyGas = true; }

            const owned = props.gearOwned.filter((g) => g.id === w.id).length;

            const ownedStr = owned > 0 ? " (" + owned + ")" : "";

            const totalGold = globalContext.gold + (globalContext.silver / 10) + (globalContext.copper / 100);

            const getCost = (w: Weapon) => {
                if (w.currency === "gp") { return w.cost; }
                if (w.currency === "sp") { return w.cost / 10; }
                if (w.currency === "cp") { return w.cost / 100; }
                return 0;
            }

            const canBuy = getCost(w) <= totalGold && (props.gearSlotsUsed + w.slots) <= props.gearSlotsTotal;
            const canSell = owned > 0;

            const getRangeMult = (w: Weapon) => w.rangeMult > 1 ? w.rangeMult + "x" : "";

            rows.push(

                <div className="row buyRow" key={w.name}>
                    <div className="col-6 col-sm-3  text-start"><b>{w.name}{ownedStr}</b></div>
                    <div className="col-2 col-sm-1  text-end">{w.cost}&nbsp;{w.currency}</div>
                    <div className="col-2 col-sm-1  col-text-end col-md-text-start">{w.types.join("/")}</div>
                    <div className="col-2 col-sm-1  text-start">{getRangeMult(w)}{w.ranges.join("/")}</div>

                    <div className="col-3 col-sm-2  col-text-start">{w.damage.map((w) => "1d" + w).join("/")}</div>
                    <div className="col-3 col-sm-2 text-start">{getProperties(w)}</div>
                    <div className="col-6 col-sm-2  text-end">
                        <button className="btn btn-secondary btn-sm btnBuy me-1" onClick={() => props.onBuyItem(w.id, "weapon", true, w.cost, w.currency)} disabled={!canBuy} title="Buy"><FontAwesomeIcon icon={faCartShopping} /></button>
                        <button className="btn btn-secondary btn-sm btnBuy" onClick={() => props.onBuyItem(w.id, "weapon", false, w.cost, w.currency)} disabled={!canSell} title="Sell"><FontAwesomeIcon icon={faDollarSign} /></button>
                    </div>
                </div>
            );
        })

        return rows;
    }

    const onCloseModal = () => {
        props.onCloseModal("weapons");
    }

    return (

        <>
            <button className="btn btn-dark btn-sm me-1" onClick={() => props.onOpenModal("weapons")}>Weapons</button>
            {props.openModal &&
                <Modal open={props.openModal} onClose={() => onCloseModal()} classNames={{ modal: 'customModal' }}>
                    <div>
                        <div><b>Coin:</b> <DisplayGold gold={globalContext.gold} silver={globalContext.silver} copper={globalContext.copper} /></div>
                        <div><b>Gear Slots:</b> {props.gearSlotsUsed} of {props.gearSlotsTotal}</div>

                        <div className="container text-left">
                            {getWeaponRows2()}
                        </div>

                        {globalContext.className !== "" && globalContext.className !== "Fighter" &&
                            <div><label><input type="checkbox" checked={props.onlyIncludeProficientWeapons} onChange={() => props.onSetOnlyIncludeProficientWeapons(!props.onlyIncludeProficientWeapons)} /> Only {globalContext.className} weapons</label></div>
                        }
                        <div className="twoCols small">
                            {anyFinesse &&
                                <div><b>Finesse (F).</b> You can use your Strength or Dexterity when attacking with this weapon.</div>
                            }
                            {anySniper &&
                                <div><b>Blowgun (BG).</b> Firing this weapon from hiding doesn't reveal your position.</div>
                            }
                            {anyTangling &&
                                <div><b>Bolas (BOL).</b> When you hit a legged creature the size of a horse or smaller, the target's speed is reduced to close until it frees itself (DC 15 STR or DEX check).</div>
                            }
                            {anyGas &&
                                <div><b>Gas (G).</b> Cause the center of your Inhaled Plague Doctor elixir to originate a near distance away.</div>
                            }
                            {anyInjectable &&
                                <div><b>Injectable (I).</b> You can forgo moving to apply a poison or elixir to this weapon and inject a target with a successful hit.</div>
                            }
                            {anyLash &&
                                <div><b>Lash (La).</b> When you make a ranged attack with this weapon, it does not leave your grasp.</div>
                            }
                            {anyLoading &&
                                <div><b>Loading (L).</b> You must forgo moving to reload this weapon.</div>
                            }
                            {anySundering &&
                                <div><b>Sundering (S).</b> When you are hit with a melee attack, you may choose to destroy this weapon or armor to negate all damage from the attack.</div>
                            }
                            {anyThrown &&
                                <div><b>Thrown (Th).</b> You can throw this weapon to make a ranged attack with it using Strength.</div>
                            }
                            {anyTwoHands &&
                                <div><b>Two-handed (2H).</b> You must use this weapon with two hands.</div>
                            }
                            {anyVersatile &&
                                <div><b>Versatile (V).</b> You can use this weapon with one or two hands. Use the higher damage die if you’re wielding it with two.</div>
                            }
                            <div><b>Weapon types.</b> Melee weapons (M) strike at arm's reach, and ranged weapons (R) strike at a distance.</div>
                            <div><b>Range.</b> You can use a weapon at close (C), near (N), or far (F) range (see Distances, pg. 47).</div>
                        </div>
                    </div >
                </Modal>
            }
        </>
    )

}

export default BuyWeapons;
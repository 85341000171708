import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { User } from "firebase/auth";
import { Ancestry } from "../../data/ancestries";
import { Background } from "../../data/backgrounds";
import { Deity } from "../../data/deities";
import { Language } from "../../data/languages";
import { Source, activeSourceFilteredAncestry, activeSourceFilteredClass } from "../../data/sources";
import { getRandomDesc } from "../../data/utilities";
import ShowSourcesModal from "./showSources";
import FilterAncestriesAndClassesModal from "../special/FilterAncAndClassesModal";

interface IProps {
    user: User | null | undefined;
    designMode: string;
    coreRulesOnly: boolean;
    openSourcesModal: boolean;
    openFilterAncestriesAndClasssesModal: boolean;
    randomType: string;
    sources: Source[];
    activeSources: String[];
    sourcesOrFiltersChanged: boolean;
    ancestries: Ancestry[];
    ancestry: string;
    charClass: string;
    deities: Deity[];
    deity: string;
    backgrounds: Background[];
    background: string;
    allLanguagesKnown: Language[];
    characterAlreadyExists: boolean;
    ancestryFilter: activeSourceFilteredAncestry[];
    classFilter: activeSourceFilteredClass[];

    updateDesignMode: (designMode: string) => void;
    flipShowSources: (coreRulesOnly: boolean) => void;
    flipActiveSource: (sourceId: string) => void;
    resetCharacter: () => void;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
    updateRandomType: (randomType: string) => void;
    randomCharacter_LevelOne: () => void;
    randomCharacter_LevelZero: () => void;
    randomCharacter_FourLevelZeroes: () => void;
    setAncestryFilter: (sourceId: string, ancestry: string) => void;
    setClassFilter: (sourceId: string, charClass: string) => void;
    checkAll: (checkAll: boolean) => void;
    saveSourcesAndFilters: () => void;
}

const CharDesignMenu: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const designMode = props.designMode;
    const sources = props.sources;
    const openSourcesModal = props.openSourcesModal;
    const randomType = props.randomType;

    const resetCharacter = props.resetCharacter;
    const onCloseModal = props.onCloseModal;
    const onOpenModal = props.onOpenModal;
    const updateRandomType = props.updateRandomType;
    const randomCharacter_LevelOne = props.randomCharacter_LevelOne;
    const randomCharacter_LevelZero = props.randomCharacter_LevelZero;
    const randomCharacter_FourLevelZeroes = props.randomCharacter_FourLevelZeroes;

    const updateDesignMode = (e: any, designMode: string) => {
        e.preventDefault();
        props.updateDesignMode(designMode);
    }

    const btClassNameDesign = props.designMode === "Design" ? "btn btn-dark" : "btn btn-outline-dark";
    const btClassNameRandom1 = props.designMode === "Random 1" ? "btn btn-dark" : "btn btn-outline-dark";
    const btClassNameRandom0 = props.designMode === "Random 0" ? "btn btn-dark" : "btn btn-outline-dark";

    const btRandomClassNameDesign = props.randomType === "Best Fit" ? "btn btn-dark" : "btn btn-outline-dark";
    const btRandomClassNameRandom1 = props.randomType === "Mixed" ? "btn btn-dark" : "btn btn-outline-dark";
    const btRandomClassNameRandom0 = props.randomType === "Random" ? "btn btn-dark" : "btn btn-outline-dark";

    const flipActiveSource = (sourceId: string) => {
        props.flipActiveSource(sourceId);
    }

    const getSourceToggles = (officialSources: boolean) => {
        const out: any[] = [];

        let availSources = props.sources.filter((s) => s.optional);
        if (officialSources) {
            availSources = availSources.filter((s) => s.isOfficial);
        } else {
            availSources = availSources.filter((s) => !s.isOfficial);
        }

        availSources.forEach((s, index) => {

            const isActive = props.activeSources.find((as) => as === s.id) !== undefined;

            const availableClassesFromThisSource = globalContext.availableClasses.filter((c) => c.sourceId === s.id);
            const availableAncestriesFromThisSource = props.ancestries.filter((a) => a.sourceId === s.id);
            const availableLanguagesFromThisSource = props.allLanguagesKnown.filter((a) => a.sourceId === s.id);
            const availableDeitiesFromThisSource = props.deities.filter((d) => d.sourceId === s.id);
            const availableBackgroundsFromThisSource = props.backgrounds.filter((d) => d.sourceId === s.id);

            const selectedClassIsFromThisSource = availableClassesFromThisSource.find((ac) => ac.name === globalContext.className) !== undefined;
            const selectedAncestryIsFromThisSource = availableAncestriesFromThisSource.find((ac) => ac.name === props.ancestry) !== undefined;

            let anySelectedLanguageIsFromThisSource = false;
            availableLanguagesFromThisSource.forEach((al) => {
                props.allLanguagesKnown.forEach((alk) => {
                    if (al.name === alk.name) { anySelectedLanguageIsFromThisSource = true; }
                })
            })

            const selectedDeityIsFromThisSource = availableDeitiesFromThisSource.find((d) => d.name === props.deity) !== undefined;
            const selectedBackgroundIsFromThisSource = availableBackgroundsFromThisSource.find((d) => d.name === props.background) !== undefined;

            const disabled = selectedClassIsFromThisSource || selectedAncestryIsFromThisSource || anySelectedLanguageIsFromThisSource || selectedDeityIsFromThisSource || selectedBackgroundIsFromThisSource;

            let className = "form-check form-switch form-check-inline";
            if (s.isNew) { className = className + " newSource"; }

            out.push(
                <div className={className} key={"src_" + s.id}>
                    <input className="form-check-input" type="checkbox" role="switch" id={"flexSwitchCheckDefault" + s.id} checked={isActive} disabled={disabled} value={s.id} onChange={(e: any) => flipActiveSource(e.target.value)} />
                    <label className="form-check-label small" htmlFor={"flexSwitchCheckDefault" + s.id} title={s.name}>{s.shortName}</label>
                </div>
            )
        })

        if (officialSources) {
            out.push(
                <ShowSourcesModal
                    key="srcsModal"
                    sources={sources}
                    openModal={openSourcesModal}
                    onCloseModal={(modalName: string) => onCloseModal(modalName)}
                    onOpenModal={(modalName: string) => onOpenModal(modalName)}
                />
            )
        }
        return out;
    }

    // const getSourceContents = (official: boolean) => {
    //     const fullActiveSources = props.sources.filter((s) => s.optional).filter((s) => props.activeSources.indexOf(s.id) !== -1 && s.isOfficial === official);
    //     let contents: any[] = [];
    //     fullActiveSources.forEach((s, index) => {
    //         contents.push(<li key={"src_" + index}><b>{s.name}:</b> Adds {s.content.join(", ")}</li>)
    //     })
    //     return <ul className="small sourceUL" key="srcs">{contents}</ul>;
    // }

    const hasAncestriesAndClasses = props.ancestryFilter.filter((a) => a.isActive === true).length > 0 && props.classFilter.filter((a) => a.isActive === true).length > 0;

    return (

        <>

            {/* Full screen menu */}
            <div className="d-none d-sm-block">

                <div>

                    <div className="sectionBorder">
                        <h2>Sources</h2>
                        <h3 className="mb-1">Core Sources:</h3>
                        <div className="small mb-1">All ShadowDark Core ancestries and classes are always included. </div>
                        <div>{getSourceToggles(true)}</div>
                        {/* {props.activeSources.filter((as) => as !== "SD").length > 0 &&
                            <div className="mt-1">{getSourceContents(true)}</div>
                        } */}
                        <h3 className="mt-3 mb-1">3rd Party Sources:</h3>
                        <div>{getSourceToggles(false)}</div>
                        {/* {props.activeSources.filter((as) => as !== "SD").length > 0 &&
                            <div className="mt-1">{getSourceContents(false)}</div>
                        } */}
                        <div className="mt-2">
                            <div>
                                <FilterAncestriesAndClassesModal
                                    ancestryFilter={props.ancestryFilter}
                                    classFilter={props.classFilter}
                                    sourcesOrFiltersChanged={props.sourcesOrFiltersChanged}
                                    currentAncestry={props.ancestry}
                                    currentClass={props.charClass}
                                    openModal={props.openFilterAncestriesAndClasssesModal}
                                    onOpenModal={() => props.onOpenModal("filterAncestriesAndClasses")}
                                    onCloseModal={() => props.onCloseModal("filterAncestriesAndClasses")}
                                    setAncestryFilter={(sourceId: string, ancestry: string) => props.setAncestryFilter(sourceId, ancestry)}
                                    setClassFilter={(sourceId: string, charClass: string) => props.setClassFilter(sourceId, charClass)}
                                    checkAll={(checkAll: boolean) => props.checkAll(checkAll)}
                                    saveSourcesAndFilters={() => props.saveSourcesAndFilters()}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                {!hasAncestriesAndClasses &&
                    <div className="sectionBorder">
                        <h2>No Ancestries or Classes Available</h2>

                        <div>Use the <b>'Filter Ancestries & Classes'</b> button above to select at least one ancestry and class.</div>
                    </div>
                }

                {(hasAncestriesAndClasses && !props.characterAlreadyExists) &&
                    <div className="sectionBorder">

                        <div>
                            <h2>Generation Method</h2>
                        </div>

                        <div className="btn-toolbar mb-3" role="toolbar">
                            <div className="btn-group me-3" role="group">
                                <button type="button" className={btClassNameDesign} onClick={(e) => updateDesignMode(e, "Design")}>Design</button>
                                <button type="button" className={btClassNameRandom1} onClick={(e) => updateDesignMode(e, "Random 1")}>Random 1</button>
                                <button type="button" className={btClassNameRandom0} onClick={(e) => updateDesignMode(e, "Random 0")}>Random 0</button>
                            </div>
                        </div>


                        {(designMode === "Design") &&
                            <div>
                                <h3>Design a Character</h3>
                                <button onClick={() => resetCharacter()} className="btn btn-dark me-2">Design a Character</button>
                            </div>
                        }

                        {(designMode === "Random 1") &&
                            <div>
                                <h3>Generate a Random Level 1 Character</h3>
                                <div className="btn-group mb-2" role="group" aria-label="Basic outlined example">
                                    <button type="button" className={btRandomClassNameDesign} onClick={(e) => updateRandomType("Best Fit")}>Best Fit</button>
                                    <button type="button" className={btRandomClassNameRandom1} onClick={(e) => updateRandomType("Mixed")}>Mixed</button>
                                    <button type="button" className={btRandomClassNameRandom0} onClick={(e) => updateRandomType("Random")}>Full Random</button>
                                </div>
                                <div className="small ps-2 mb-1">{getRandomDesc(randomType)}</div>
                                <div>
                                    <button onClick={() => randomCharacter_LevelOne()} className="btn btn-dark me-2">Generate a Random Character</button>
                                </div>

                            </div>
                        }
                        {(designMode === "Random 0") &&
                            <div>
                                <h2>Generate Random Level 0 Characters</h2>
                                <div>
                                    <button onClick={() => randomCharacter_LevelZero()} className="topButton btn btn-dark me-2 lvlZeroBtn">Random 0</button><span className="d-none d-sm-inline"> Generate a random level 0 character</span>
                                </div>
                                <div>
                                    <button onClick={() => randomCharacter_FourLevelZeroes()} className="topButton btn btn-dark me-2 lvlZeroBtn">Random 4x0</button><span className="d-none d-sm-inline"> Generate four random level 0 characters</span>
                                </div>
                            </div>
                        }

                    </div>
                }

            </div>

            {/* Mobile screen menu */}
            <div className="d-block d-sm-none pb-4">

                <div className="mb-2">

                    <div className="sectionBorder">
                        <h2>Sources</h2>
                        <h3 className="mb-1">Core Sources:</h3>
                        <div className="small mb-1">All ShadowDark Core ancestries and classes are always included. </div>
                        <div>{getSourceToggles(true)}</div>
                        {/* {props.activeSources.filter((as) => as !== "SD").length > 0 &&
                            <div className="mt-1">{getSourceContents(true)}</div>
                        } */}
                        <h3 className="mt-3 mb-1">3rd Party Sources:</h3>
                        <div>{getSourceToggles(false)}</div>
                        {/* {props.activeSources.filter((as) => as !== "SD").length > 0 &&
                            <div className="mt-1">{getSourceContents(false)}</div>
                        } */}
                        <div className="mt-2">
                            <div>
                                <FilterAncestriesAndClassesModal
                                    ancestryFilter={props.ancestryFilter}
                                    classFilter={props.classFilter}
                                    sourcesOrFiltersChanged={props.sourcesOrFiltersChanged}
                                    currentAncestry={props.ancestry}
                                    currentClass={props.charClass}
                                    openModal={props.openFilterAncestriesAndClasssesModal}
                                    onOpenModal={() => props.onOpenModal("filterAncestriesAndClasses")}
                                    onCloseModal={() => props.onCloseModal("filterAncestriesAndClasses")}
                                    setAncestryFilter={(sourceId: string, ancestry: string, isActive: boolean) => props.setAncestryFilter(sourceId, ancestry)}
                                    setClassFilter={(sourceId: string, charClass: string, isActive: boolean) => props.setClassFilter(sourceId, charClass)}
                                    checkAll={(checkAll: boolean) => props.checkAll(checkAll)}
                                    saveSourcesAndFilters={() => props.saveSourcesAndFilters()}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                {!hasAncestriesAndClasses &&
                    <div className="sectionBorder">
                        <h2>No Ancestries or Classes Available</h2>

                        <div>Use the <b>'Filter Ancestries & Classes'</b> button above to select at least one ancestry and class.</div>
                    </div>
                }

                {hasAncestriesAndClasses &&
                    <div className="sectionBorder">

                        {(!props.characterAlreadyExists && hasAncestriesAndClasses) &&
                            <div>
                                <h2>Generation Method</h2>
                            </div>
                        }

                        {(!props.characterAlreadyExists && hasAncestriesAndClasses) &&
                            <div className="mb-2">
                                <div className="btn-group" role="group" aria-label="Basic outlined example">
                                    <button type="button" className={btClassNameDesign} onClick={(e) => updateDesignMode(e, "Design")}>Design</button>
                                    <button type="button" className={btClassNameRandom1} onClick={(e) => updateDesignMode(e, "Random 1")}>Random 1</button>
                                    <button type="button" className={btClassNameRandom0} onClick={(e) => updateDesignMode(e, "Random 0")}>Random 0</button>
                                </div>
                            </div>
                        }

                        {(designMode === "Design" && !props.characterAlreadyExists && hasAncestriesAndClasses) &&
                            <div>
                                <h3>Design a Character</h3>
                                <button onClick={() => resetCharacter()} className="btn btn-dark me-1">Design a Character</button>
                            </div>
                        }
                        {(designMode === "Random 1" && hasAncestriesAndClasses) &&
                            <>
                                <h3>Generate Random Lvl 1</h3>
                                <div className="btn-group mb-2" role="group" aria-label="Basic outlined example">
                                    <button type="button" className={btRandomClassNameDesign} onClick={(e) => updateRandomType("Best Fit")}>Best Fit</button>
                                    <button type="button" className={btRandomClassNameRandom1} onClick={(e) => updateRandomType("Mixed")}>Mixed</button>
                                    <button type="button" className={btRandomClassNameRandom0} onClick={(e) => updateRandomType("Random")}>Full Random</button>
                                </div>
                                <div className="small ps-2 mb-1">{getRandomDesc(randomType)}</div>
                                <button onClick={() => randomCharacter_LevelOne()} className="btn btn-dark me-1">Generate Random Lvl 1</button>
                            </>
                        }

                        {(designMode === "Random 0" && hasAncestriesAndClasses) &&
                            <>
                                <h3>Generate Random Lvl 0</h3>
                                <div>
                                    <button onClick={() => randomCharacter_LevelZero()} className="topButton btn btn-dark lvlZeroBtn">Random 0</button>
                                </div>
                                <div>
                                    <button onClick={() => randomCharacter_FourLevelZeroes()} className="topButton btn btn-dark lvlZeroBtn">Random 4x0</button>
                                </div>
                            </>
                        }
                    </div>
                }

            </div>

        </>

    )

}

export default CharDesignMenu;
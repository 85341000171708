import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByNameAndBonusTo } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const ImmunityToEnergy: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const setImmunityBonus = (energyType: string) => {
        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "ImmuneToEnergyType",
            bonusName:  "ImmuneToEnergyType",
            bonusTo: energyType
        };
        props.setBonus(bonus);
    }
 
    const immuneToAcid = getBonusByNameAndBonusTo(globalContext.bonuses, "ImmuneToEnergyType", "acid", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const immuneToCold =  getBonusByNameAndBonusTo(globalContext.bonuses, "ImmuneToEnergyType", "cold", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;
    const immuneToPoison =  getBonusByNameAndBonusTo(globalContext.bonuses, "ImmuneToEnergyType", "poison", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource) !== undefined;

    const req = !immuneToAcid && !immuneToCold && !immuneToPoison;

    return (
        <div key="attackBonus">
            <label><input type="radio" onChange={() => setImmunityBonus("acid")} checked={immuneToAcid}></input>&nbsp;Acid</label>
            <label><input type="radio" onChange={() => setImmunityBonus("cold")} checked={immuneToCold}></input>&nbsp;Cold</label>
            <label><input type="radio" onChange={() => setImmunityBonus("poison")} checked={immuneToPoison}></input>&nbsp;Poison</label>
            {req &&
                <div className="valError">Please select an option</div>
            }
        </div>
    )

}

export default ImmunityToEnergy;
import { MagicItem } from "../../data/magicItem";
import { Treasure } from "../../data/treasure";

interface IProps {
    pdfType: string;
    ancestry: string;
    charClass: string;
    treasures: Treasure[];
    magicItems: MagicItem[];
    jsonExportType: string;

    setPDFType: (pdfType: string) => void;
    printCharacter: () => void;
    exportToJson: (e: any) => void;
    setJSONExportType: (exportType: string) => void;
}

const Export: React.FunctionComponent<IProps> = (props: IProps) => {

    const pdfType = props.pdfType;
    const ancestry = props.ancestry;
    const charClass = props.charClass;
    const treasures = props.treasures;
    const magicItems = props.magicItems;

    const printCharacter = props.printCharacter;
    const setPDFType = props.setPDFType;
    const exportToJson = props.exportToJson;
    const jsonExportType = props.jsonExportType;
    const setJSONExportType = props.setJSONExportType;

    return (
        <div className="row">
            <div className="mb-3">
                <button onClick={() => printCharacter()} className="btn btn-dark">PDF</button>
                <div>
                    <label className="small"><input type="radio" checked={pdfType === "Scribbly"} onChange={() => setPDFType("Scribbly")} /> Scribbly</label>
                    <label className="small"><input type="radio" checked={pdfType === "High legibility"} onChange={() => setPDFType("High legibility")} /> High legibility</label>
                    <label className="small"><input type="radio" checked={pdfType === "Form fillable"} onChange={() => setPDFType("Form fillable")} /> Form fillable</label>
                </div>
            </div>
            <div>
                {(ancestry !== "Duck" && ancestry !== "Dog") &&
                    <>
                        <button onClick={(e) => exportToJson(e)} className="btn btn-dark">JSON</button>
                        <div>
                            <label className="small"><input type="radio" checked={jsonExportType === "Clipboard"} onChange={() => setJSONExportType("Clipboard")} /> Copy to clipboard</label>
                            <label className="small"><input type="radio" checked={jsonExportType === "File"} onChange={() => setJSONExportType("File")} /> Export to JSON file</label>
                        </div>
                        <div className="small">For import into VTTs including Foundry and Owlbear Rodeo 2.0</div>
                    </>
                }
                {ancestry === "Duck" &&
                    <div>JSON export is not supported for the <b>Duck</b> ancestry</div>
                }
                {ancestry === "Dog" &&
                    <div>JSON export is not supported for the <b>Dog</b> ancestry</div>
                }
                {charClass === "Ranger" &&
                    <div className="small">Note that importing the <b>Ranger</b> class may not yet be supported on some VTTs.</div>
                }
                {ancestry === "Kobold" &&
                    <div className="small">Note that importing the <b>Kobold</b> ancestry may not yet be supported on some VTTs.</div>
                }
                {charClass === "Bard" &&
                    <div className="small">Note that importing the <b>Bard</b> class may not yet be supported on some VTTs.</div>
                }
                {charClass === "Knight of St. Ydris" &&
                    <div className="small">Note that importing the <b>Knight of St. Ydris</b> class is likely not to be supported yet on most VTTs.</div>
                }
                {charClass === "Witch" &&
                    <div className="small">Note that importing the <b>Witch</b> class is likely not to be supported yet on most VTTs.</div>
                }
                {treasures.length > 0 &&
                    <div className="small">Note that importing custom <b>treasures</b> is likely not to be supported yet on most VTTs.</div>
                }
                {magicItems.length > 0 &&
                    <div className="small">Note that importing <b>magic items</b> is likely not to be supported yet on most VTTs.</div>
                }
            </div>
        </div>

    )

}

export default Export;
import { BlackLotusTalents } from "../../data/classes";

interface IProps { }


const BlackLotusTables: React.FunctionComponent<IProps> = (props: IProps) => {

    const blackLotusTalents = BlackLotusTalents;

    const getRows = () => {
        const out: any = [];
        blackLotusTalents.forEach((b, index) => {
            let noDupe = b.rerollDuplicates ? " (reroll duplicates)" : "";
            out.push(
                <tr key={"blt_" + index}>
                    <td>{b.min}</td>
                    <td>{b.desc}{noDupe}</td>
                </tr>
            )
        })
        return out;
    }

    return (
        <div>
            <table className='classTalentsTable mb-2'>
                <thead>
                    <tr>
                        <th colSpan={2} className='header'>Black Lotus Talents</th>
                    </tr>
                    <tr>
                        <th>d12</th>
                        <th>Effect (You may keep or reroll duplicates))</th>
                    </tr>
                </thead>
                <tbody>
                    {getRows()}
                </tbody>
            </table>
        </div>
    )
}

export default BlackLotusTables;

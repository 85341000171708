import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import LearnSpellFromScroll from "./learnSpellFromScroll";
import { Bonus } from "../../data/bonus";
import { Spell } from "../../data/spells";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    spellsKnown: Spell[];
    isMinimal: boolean;
    onSetBonus: (bonus: Bonus) => void;
    isSaved: boolean;
}

const LearnSpellsFromScrolls: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    if (globalContext.className !== "Wizard") { return null; }

    const spellsLearnedFromScrolls = globalContext.bonuses.filter((b) => b.bonusTo === "LearnSpellFromScroll" && b.bonusName !== "");
    const numSpellsLearnedFromScrolls = spellsLearnedFromScrolls.length;

    const getLearnSpellFromScrollControls = () => {
        let out: any[] = [];
        for (let spellNum = 0; spellNum <= numSpellsLearnedFromScrolls; spellNum++) {
            out.push(
                <LearnSpellFromScroll
                    key={"learnScroll" + spellNum}
                    spellsKnown={props.spellsKnown}
                    sourceType={props.sourceType}
                    sourceName={props.sourceName}
                    sourceCategory={props.sourceCategory}
                    isMinimal={props.isMinimal}
                    spellNumber={spellNum}
                    setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                />
            )
        }
        return out;
    }

    if (props.isSaved) {
        return (
            <div>
                <h3>Learn Spells From Scrolls</h3>
                <div className="mb-2">
                    Use this section to add any extra spells learned from scrolls that you found while adventuring.
                </div>
                <div className="mb-2">
                    {getLearnSpellFromScrollControls()}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <h3>Learn Spells From Scrolls</h3>
                <div className="mb-2">
                    You must save your wizard before you can learn any extra spells from scrolls that you found while adventuring.
                </div>
            </div>
        )
    }
}

export default LearnSpellsFromScrolls;
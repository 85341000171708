import { Source } from "./sources";

const alignments = ["Lawful", "Neutral", "Chaotic"];

export const getAlignments = (sources: Source[]) => {

    // if (sources.find((s) => s.name === "ShadowDuck")) {
    //     return [...alignments, "Daffy"];
    // }

    return [...alignments];
}
import { Spell, spells } from "../../data/spells";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { getAllCharClasses } from "../../data/classes";
import { useState } from "react";

interface IProps {
    className: string;
    spellsKnown: Spell[];
    openModal: boolean;
    onOpenModal: (modal: string) => void;
    onCloseModal: (modal: string) => void;
}

const SpellBook: React.FunctionComponent<IProps> = (props: IProps) => {

    const [filter, setFilter] = useState("");
    const [onlySpellsKnown, setOnlySpellsKnown] = useState(false);

    let classSpellType = "?";

    const charClass = getAllCharClasses().find((c) => c.name === props.className);
    if (charClass) {
        if (charClass.specialBonuses) {
            const spellBonus = charClass.specialBonuses.find((sb) => sb.specialName === "PickSpell");
            if (spellBonus) {
                classSpellType = spellBonus.specialSourceName;
            }
        }
    }

    const getSpells = (classSpellType: string) => {
        let out: any[] = [];

        const spellSort = (s1: Spell, s2: Spell) => {
            const s1ClassIndex = s1.classes.indexOf(classSpellType);
            const s1Tier = s1.tierByClass[s1ClassIndex];

            const s2ClassIndex = s2.classes.indexOf(classSpellType);
            const s2Tier = s2.tierByClass[s2ClassIndex];

            if (s1Tier < s2Tier) { return -1; }
            if (s1Tier === s2Tier) {
                return s1.name < s2.name ? -1 : 1;
            }
            return 1;
        }

        let theSpells = spells.filter((s) => s.classes.indexOf(classSpellType) !== -1);

        if(onlySpellsKnown) {
            theSpells = spells.filter((s) => props.spellsKnown.map((sp) => sp.name).indexOf(s.name) !== -1);
        }

        if (filter.trim() !== "") {
            theSpells = theSpells.filter((s) => s.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1)
        }

        theSpells = theSpells.sort(spellSort);



        let theTier = 0;
        theSpells.forEach((s) => {
            const s1ClassIndex = s.classes.indexOf(classSpellType);
            const s1Tier = s.tierByClass[s1ClassIndex];
            if (s1Tier > theTier) {
                out.push(<h3 className="mt-3">Tier {s1Tier}</h3>)
                theTier = s1Tier;
            }
            out.push(<div><b>{s.name}</b></div>);
            out.push(<div className="mb-2 ps-2">Tier: {s1Tier}, Range: {s.range}, Duration: {s.duration}. {s.desc}</div>);

        })

        if (out.length === 0) {
            out.push(<div>No spells match the filter</div>)
        }

        return out;
    }

    return (
        <>
            <button className="btn btn-dark btn-sm mb-2" onClick={() => props.onOpenModal("spellbook")}>{classSpellType} Spell Book</button>

            {props.openModal &&
                <Modal open={props.openModal} onClose={() => props.onCloseModal("spellbook")} classNames={{ modal: 'customModal' }}>
                    <h2>{classSpellType} Spells</h2>

                    <input className="form-control mb-2" id="filter" type="text" placeholder="Filter by name" onChange={(e) => setFilter(e.currentTarget.value)} value={filter}></input>

                    <div className="form-check form-switch form-check-inline mt-0 pt-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="cheat1" checked={onlySpellsKnown} onChange={() => setOnlySpellsKnown(!onlySpellsKnown)} />
                        <label className="form-check-label small" htmlFor="cheat1">Only spells known</label>
                    </div>

                    {getSpells(classSpellType)}
                </Modal>
            }
        </>
    )

}

export default SpellBook;
import { arcaneLibraryCursedScroll1_Diablerie_URL, arcaneLibraryCursedScroll2_RedSands_URL, arcaneLibraryCursedScroll3_MidnightSun_URL, arcaneLibraryDiscordURL, arcaneLibraryFacebookURL, arcaneLibraryMailingListURL, arcaneLibraryRedditURL, arcaneLibraryShadowDarkQuickstartURL, arcaneLibraryShadowDarkURL, bardAndRangerPDFDropboxURL, fantasyGroundsVTTURL, foundryVTTURL, koboldAncestryDiscordURL, letsRoleCharacterSheetVTTURL, owlbearCharacterSheetVTTURL, roll20CharacterSheetVTTURL, roustaboutPDFDropboxURL } from "../../special/links";

interface IProps {
}

const Resources: React.FunctionComponent<IProps> = (props: IProps) => {
    return (
        <div>
            <h1 className="mb-3">Resources</h1>

            <div className="twoCols">

                <div>
                    <h2>ShadowDark Game</h2>
                    <ul>
                        <li><a href={arcaneLibraryShadowDarkURL} target="_blank" rel="noreferrer">ShadowDark</a> roleplaying game</li>
                        <li><a href={arcaneLibraryShadowDarkQuickstartURL} target="_blank" rel="noreferrer">ShadowDark Players Quickstart Set</a></li>
                        <li><a href={arcaneLibraryCursedScroll1_Diablerie_URL} target="_blank" rel="noreferrer">Cursed Scroll Zine, Vol. 1: Diablerie! </a></li>
                        <li><a href={arcaneLibraryCursedScroll2_RedSands_URL} target="_blank" rel="noreferrer">Cursed Scroll Zine, Vol. 2: Red Sands </a></li>
                        <li><a href={arcaneLibraryCursedScroll3_MidnightSun_URL} target="_blank" rel="noreferrer">Cursed Scroll Zine, Vol. 3: Midnight Sun </a></li>
                        <li><a href={bardAndRangerPDFDropboxURL} target="_blank" rel="noreferrer">Bard and Ranger classes, by Kelsey </a></li>
                        <li><a href={roustaboutPDFDropboxURL} target="_blank" rel="noreferrer">Roustabout class, by Michael and Kelsey </a></li>
                        <li><a href={koboldAncestryDiscordURL} target="_blank" rel="noreferrer">Kobold ancestry, by Kelsey </a></li>
                        <li><a href={arcaneLibraryMailingListURL} target="_blank" rel="noreferrer">ShadowDark Mailing List </a></li>
                    </ul>
                </div>

                <div>
                    <h2>Forums</h2>
                    <ul>
                        <li><a href={arcaneLibraryDiscordURL} target="_blank" rel="noreferrer">ShadowDark on Discord</a></li>
                        <li><a href={arcaneLibraryRedditURL} target="_blank" rel="noreferrer">ShadowDark on Reddit</a></li>
                        <li><a href={arcaneLibraryFacebookURL} target="_blank" rel="noreferrer">ShadowDark on Facebook</a></li>
                    </ul>
                </div>

                <div>
                    <h2>Character Designers</h2>
                    <ul>
                        <li><a href="https://www.shadowdork.com/" target="_blank" rel="noreferrer">Shadowdork</a></li>
                    </ul>
                </div>

                <div>
                    <h2>VTT Support</h2>
                    <ul>
                        <li><a href={fantasyGroundsVTTURL} target="_blank" rel="noreferrer">ShadowDark for Fantasy Grounds VTT</a>: Supports importing characters exported from ShadowDarklings.</li>
                        <li><a href={foundryVTTURL} target="_blank" rel="noreferrer">ShadowDark module for Foundry VTT</a>: Supports importing characters exported from ShadowDarklings.</li>
                        <li><a href={owlbearCharacterSheetVTTURL} target="_blank" rel="noreferrer">ShadowDark character sheet for Owlbear Rodeo 2.0 VTT</a>: Supports importing characters exported from ShadowDarklings. (Also available outside of OBR <a href="https://sd-character-sheet.onrender.com/" target="_blank" rel="noreferrer">here</a>.)  </li>
                        <li><a href={roll20CharacterSheetVTTURL} target="_blank" rel="noreferrer">ShadowDark character sheet for Roll20 VTT</a>: Does not (so far) support importing characters exported from ShadowDarklings.</li>
                        <li><a href={letsRoleCharacterSheetVTTURL} target="_blank" rel="noreferrer">ShadowDark character sheet for Let's Role:</a> Does not (so far) support importing characters exported from ShadowDarklings.</li>
                    </ul>
                </div>

                <div>
                    <h2>Resource Lists</h2>
                    <ul>
                        <li><a href="https://docs.google.com/spreadsheets/d/14KbvKlqmoucIZE9IM49mtSKfLczWEd2tZ5NbP-cQXVk/edit#gid=0" target="_blank" rel="noreferrer">3rd Party Products Master List</a> </li>
                    </ul>
                </div>

                <div>
                    <h2>Various</h2>
                    <ul>
                        <li><a href=" https://shadowdarktools.com/ " target="_blank" rel="noreferrer">ShadowDark Tools</a>: includes encounter, NPC, trap, hazard, and rival generators.</li>
                    </ul>
                </div>

            </div>

        </div>
    )
}

export default Resources;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Spell, spells } from "../../data/spells";
import { getSpellTierForClass } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    spellsKnown: Spell[];
    isMinimal: boolean;
    spellNumber: number;
    setBonus: (bonus: Bonus) => void;
}

const LearnSpellFromScroll: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const allSpells = spells; 

    const availSpells = allSpells.filter((s) => s.classes.indexOf(globalContext.className) !== -1).sort((s1, s2) => s1.name < s2.name ? -1 : 1);

    const options = availSpells.map((s) => <option value={s.name} key={s.name}>{s.name}</option>);
    options.unshift(<option value="" key="selectSpell">-- select additional spell --</option>);

    const bonusTo = "LearnSpellFromScroll";
    const name = bonusTo + "-" + props.spellNumber;

    let currentValue = "";
    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.gainedAtLevel === 0 && b.name === name && b.bonusTo === bonusTo);
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setSpellBonus = (spell: string) => {

        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: 0,
            name: name,
            bonusTo: bonusTo,
            bonusName: spell,
        };
        props.setBonus(bonus);
    }

    const getSpellDesc = () => {
        if (props.isMinimal) { return null; }
        if (currentValue !== "") {
            const theSpell = allSpells.find((s) => s.name === currentValue);
            if (theSpell) {
                const tier = getSpellTierForClass(globalContext.className, theSpell.name);
                return <div className="spell">Tier: {tier}, Range: {theSpell.range}, Duration: {theSpell.duration}. {theSpell.desc}</div>
            }
        }
        return null;
    }

    const getIsDuplicate = () => {
        let thisSpell = props.spellsKnown.filter((l) => l.name === currentValue);
        if (thisSpell.length > 1) {
            return <span className="valError ms-3">Duplicate</span>
        }
        return null;
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    return (
        <div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setSpellBonus(e.target.value)} value={currentValue}>{options}</select>
                {getIsDuplicate()}
            </div>
            {getSpellDesc()}
        </div>
    )

}

export default LearnSpellFromScroll;
import BuyArmor from "./buyArmor";
import BuySundries from "./buySundries";
import BuyWeapons from "./buyWeapons";
import GetMagicItems from "./getMagicItems";
import GetTreasure from "./getTreasure";
import { GearOwned } from "../../data/gear";
import { MagicItem } from "../../data/magicItem";
import { Treasure } from "../../data/treasure";
import { Bonus } from "../../data/bonus";


interface IProps {
    openWeaponsModal: boolean;
    openArmorModal: boolean;
    openSundriesModal: boolean;
    openTreasureModal: boolean;
    openMagicItemsModal: boolean;
    onlyIncludeProficientWeapons: boolean;
    onlyIncludeProficientArmor: boolean;
    gearOwned: GearOwned[];
    treasureCarried: Treasure[];
    magicItemsCarried: MagicItem[];
    magicItemCatalog: MagicItem[];
    bonuses: Bonus[];

    gearSlotsUsed: number;
    gearSlotsTotal: number;
    onSetOnlyIncludeProficientWeapons: (onlyIncludeProficientWeapons: boolean) => void;
    onSetOnlyIncludeProficientArmor: (onlyIncludeProficientArmor: boolean) => void;
    onBuyItem: (id: string, type: string, isBuy: boolean, cost: number, currency: string) => void;

    onAddTreasure: (name: string, desc: string, cost: number, currency: string, slots: number) => void;
    onEditTreasure: (id: string, name: string, desc: string, cost: number, currency: string, slots: number) => void;
    onDiscardTreasure: (id: string) => void;
    onSellTreasure: (id: string) => void;

    onAddMagicItem: (itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string, properties: string[]) => void;
    onEditMagicItem: (id: string, itemType: string, itemTypeId: string, magicItemType: string, name: string, bonus: number, bonusNote: string, attackNote: string, features: string, benefits: string, curses: string, hasPersonality: boolean, virtue: string, flaw: string, trait: string) => void;
    onDiscardMagicItem: (id: string) => void;

    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}

const BuyGear: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <>
            <BuyWeapons
                openModal={props.openWeaponsModal}
                onlyIncludeProficientWeapons={props.onlyIncludeProficientWeapons}
                gearOwned={props.gearOwned}
                gearSlotsUsed={props.gearSlotsUsed}
                gearSlotsTotal={props.gearSlotsTotal}
                bonuses={props.bonuses}
                onSetOnlyIncludeProficientWeapons={props.onSetOnlyIncludeProficientWeapons}
                onBuyItem={props.onBuyItem}
                onCloseModal={props.onCloseModal}
                onOpenModal={props.onOpenModal}
            />

            <BuyArmor
                openModal={props.openArmorModal}
                onlyIncludeProficientArmor={props.onlyIncludeProficientArmor}
                gearOwned={props.gearOwned}
                onSetOnlyIncludeProficientArmor={props.onSetOnlyIncludeProficientArmor}
                onBuyItem={props.onBuyItem}
                gearSlotsUsed={props.gearSlotsUsed}
                gearSlotsTotal={props.gearSlotsTotal}
                bonuses={props.bonuses}
                onCloseModal={props.onCloseModal}
                onOpenModal={props.onOpenModal}
            />

            <BuySundries
                openModal={props.openSundriesModal}
                gearOwned={props.gearOwned}
                gearSlotsUsed={props.gearSlotsUsed}
                gearSlotsTotal={props.gearSlotsTotal}
                onBuyItem={props.onBuyItem}
                onCloseModal={props.onCloseModal}
                onOpenModal={props.onOpenModal}
            />

            <GetTreasure
                openModal={props.openTreasureModal}
                treasureCarried={props.treasureCarried}
                gearOwned={props.gearOwned}
                gearSlotsUsed={props.gearSlotsUsed}
                gearSlotsTotal={props.gearSlotsTotal}
                onAddTreasure={props.onAddTreasure}
                onEditTreasure={props.onEditTreasure}
                onDiscardTreasure={props.onDiscardTreasure}
                onSellTreasure={props.onSellTreasure}
                onCloseModal={props.onCloseModal}
                onOpenModal={props.onOpenModal}
            />

            <GetMagicItems
                openModal={props.openMagicItemsModal}
                magicItemsCarried={props.magicItemsCarried}
                magicItemCatalog={props.magicItemCatalog}
                gearOwned={props.gearOwned}
                gearSlotsUsed={props.gearSlotsUsed}
                gearSlotsTotal={props.gearSlotsTotal}
                onAddMagicItem={props.onAddMagicItem}
                onEditMagicItem={props.onEditMagicItem}
                onDiscardMagicItem={props.onDiscardMagicItem}
                onCloseModal={props.onCloseModal}
                onOpenModal={props.onOpenModal}
            />

        </>
    )

}

export default BuyGear;
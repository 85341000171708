import uniqid from 'uniqid';
import { Ancestry } from './ancestries';
import { Armor } from './armor';
import { Bonus } from './bonus';
import { CharClass, Talent, getAllCharClasses } from './classes';
import { GearOwned, GearRecord } from './gear';
import { Level } from './level';
import { getRandomIntInclusive } from './random';
import { Spell, spells } from './spells';
import { modNum, modStr, plusMinus, Stats } from './stats';
import { sundries } from './sundries';
import { Weapon, weapons } from './weapons';
import { Cheat } from './cheats';
import { Treasure } from './treasure';
import { MagicItem } from './magicItem';
import { sources } from './sources';

export const getAllSpellsKnown = (charClasses: CharClass[], charClass: string, spells: Spell[], bonuses: Bonus[]) => {
    let spellsKnown: Spell[] = [];

    // Any spells gained from class:
    const theClass = charClasses.find((c) => c.name === charClass);
    if (theClass) {
        theClass.freeSpells.forEach((sp) => {
            const thisSpell = spells.find((s) => s.name === sp);
            if (thisSpell) {
                spellsKnown.push(thisSpell);
            }
        })
    }

    // Any spells gained through bonuses:
    const bonusSpells = bonuses.filter((b) => b.name.indexOf("Spell:") !== -1 || b.name === "LearnExtraSpell" || b.name === "LearnSpellFromPatron" || b.name.indexOf("LearnSpellFromScroll") !== -1);
    bonusSpells.forEach((sp) => {
        const thisSpell = spells.find((s) => s.name === sp.bonusName);
        if (thisSpell) {
            spellsKnown.push(thisSpell);
        }
    })

    spellsKnown = spellsKnown.sort((l1, l2) => l1.name < l2.name ? -1 : 1);

    return spellsKnown;
}

export const displayStats = (stats: Stats) => {

    const cssClass = "col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2";
    return (

        <>

            <div className="container text-center">
                <div className="row">
                    <div className={cssClass}>
                        <b>STR&nbsp;{stats.Strength}</b>&nbsp;({modStr(stats.Strength)})
                    </div>
                    <div className={cssClass}>
                        <b>DEX&nbsp;{stats.Dexterity}</b>&nbsp;({modStr(stats.Dexterity)})
                    </div>
                    <div className={cssClass}>
                        <b>CON&nbsp;{stats.Constitution}</b>&nbsp;({modStr(stats.Constitution)})
                    </div>
                    <div className={cssClass}>
                        <b>INT&nbsp;{stats.Intelligence}</b>&nbsp;({modStr(stats.Intelligence)})
                    </div>
                    <div className={cssClass}>
                        <b>WIS&nbsp;{stats.Wisdom}</b>&nbsp;({modStr(stats.Wisdom)})
                    </div>
                    <div className={cssClass}>
                        <b>CHA&nbsp;{stats.Charisma}</b>&nbsp;({modStr(stats.Charisma)})
                    </div>
                </div>
            </div>

            {/* <span>
                <b>STR {stats.Strength}</b> ({modStr(stats.Strength)})&nbsp;
                <b>DEX {stats.Dexterity}</b> ({modStr(stats.Dexterity)})&nbsp;
                <b>CON {stats.Constitution}</b> ({modStr(stats.Constitution)})&nbsp;
                <b>INT {stats.Intelligence}</b> ({modStr(stats.Intelligence)})&nbsp;
                <b>WIS {stats.Wisdom}</b> ({modStr(stats.Wisdom)})&nbsp;
                <b>CHA {stats.Charisma}</b> ({modStr(stats.Charisma)}&nbsp;)
            </span> */}
        </>
    )
}

export const getGearRecords = (gear: GearOwned[], treasures: Treasure[], magicItems: MagicItem[], gold: number, silver: number, copper: number, armors: Armor[], includeTreasures: boolean) => {

    const gearRecords: GearRecord[] = [];

    gear.forEach((go) => {
        if (go.type === "weapon") {
            const theWeapon = weapons.find((w) => w.id === go.id);
            if (theWeapon) {
                const existingRecord = gearRecords.find((gr) => gr.gearId === go.id);
                if (existingRecord) {
                    existingRecord.quantity = existingRecord.quantity + 1;
                    existingRecord.slots = existingRecord.slots + theWeapon.slots;
                    existingRecord.cost = existingRecord.cost + theWeapon.cost;
                    existingRecord.totalUnits = existingRecord.totalUnits + 1;
                } else {
                    const thisRecord: GearRecord = { instanceId: go.instanceId, gearId: go.id, name: theWeapon.name, type: "weapon", quantity: 1, totalUnits: 1, slots: theWeapon.slots, cost: theWeapon.cost, currency: theWeapon.currency };
                    gearRecords.push(thisRecord);
                }
            }
        }

        if (go.type === "armor") {
            const theArmor = armors.find((a) => a.id === go.id);
            if (theArmor) {
                const existingRecord = gearRecords.find((gr) => gr.gearId === go.id);
                if (existingRecord) {
                    existingRecord.quantity = existingRecord.quantity + 1;
                    existingRecord.slots = existingRecord.slots + theArmor.slots;
                    existingRecord.cost = existingRecord.cost + theArmor.cost;
                    existingRecord.totalUnits = existingRecord.totalUnits + 1;
                } else {
                    const thisRecord: GearRecord = { instanceId: go.instanceId, gearId: go.id, name: theArmor.name, type: "armor", quantity: 1, totalUnits: 1, slots: theArmor.slots, cost: theArmor.cost, currency: theArmor.currency };
                    gearRecords.push(thisRecord);
                }
            }
        }

        if (go.type === "sundry") {
            const theSundry = sundries.find((a) => a.id === go.id);
            if (theSundry) {
                const existingRecord = gearRecords.find((gr) => gr.gearId === go.id);
                if (existingRecord) {
                    existingRecord.quantity = existingRecord.quantity + 1;
                    existingRecord.slots = existingRecord.slots + theSundry.slots;
                    existingRecord.cost = existingRecord.cost + theSundry.cost;
                    existingRecord.totalUnits = existingRecord.totalUnits + go.totalUnits;
                } else {
                    const thisRecord: GearRecord = { instanceId: go.instanceId, gearId: go.id, name: theSundry.name, type: "sundry", quantity: 1, totalUnits: go.totalUnits, slots: theSundry.slots, cost: theSundry.cost, currency: theSundry.currency };
                    gearRecords.push(thisRecord);
                }
            }
        }
    })

    // First backpack is zero slots
    let isFirstBackpack = true;
    gearRecords.forEach((gr) => {
        if (gr.gearId === "s2" && isFirstBackpack) {
            gr.slots = gr.slots - 1;
            isFirstBackpack = false;
        }
    })

    if (includeTreasures) {
        // Add any treasures
        treasures.forEach((t) => {
            const thisRecord: GearRecord = { instanceId: t.id, gearId: "", name: t.name, type: "treasure", quantity: 1, totalUnits: 1, slots: t.slots, cost: t.cost, currency: t.currency, desc: t.desc };
            gearRecords.push(thisRecord);
        });

        magicItems.forEach((t) => {
            let slots = 0;
            if (t.itemType === "weapon") {
                const theWeapon = weapons.find((w) => w.id === t.itemTypeId);
                if (theWeapon) {
                    slots = theWeapon.slots;
                }
                const thisRecord: GearRecord = { instanceId: t.id, gearId: "", name: t.name, type: "weapon", quantity: 1, totalUnits: 1, slots: slots, cost: 0, currency: "gp", desc: t.name };
                gearRecords.push(thisRecord);
            }
            if (t.itemType === "armor") {
                const theArmor = armors.find((w) => w.id === t.itemTypeId);
                if (theArmor) {
                    slots = theArmor.slots;
                }
                const thisRecord: GearRecord = { instanceId: t.id, gearId: "", name: t.name, type: "armor", quantity: 1, totalUnits: 1, slots: slots, cost: 0, currency: "gp", desc: t.name };
                gearRecords.push(thisRecord);
            }
            if (t.itemType === "sundry") {
                slots = 1;
                const thisRecord: GearRecord = { instanceId: t.id, gearId: "", name: t.name, type: "sundry", quantity: 1, totalUnits: 1, slots: slots, cost: 0, currency: "gp", desc: t.name };
                gearRecords.push(thisRecord);
            }
        });
    }

    // add bags of coins
    // const totalCoins = gold + silver + copper;
    // if (totalCoins > 100) {
    //     const fullBags = Math.floor(totalCoins - 100) / 100;
    //     const partialBag = (totalCoins - 100) % 100;
    //     for (let b = 1; b <= fullBags; b++) {
    //         const coinBagRecord: GearRecord = { instanceId: uniqid(), gearId: "coins", name: "Coins", type: "sundry", totalUnits: 100, slots: 1, quantity: 1, cost: 0, currency: "gp" };
    //         gearRecords.push(coinBagRecord);
    //     }
    //     if (partialBag > 0) {
    //         const coinBagRecord: GearRecord = { instanceId: uniqid(), gearId: "coins", name: "Coins", type: "sundry", totalUnits: partialBag, slots: 1, quantity: 1, cost: 0, currency: "gp" };
    //         gearRecords.push(coinBagRecord);
    //     }
    // }

    const totalCoins = gold + silver + copper;
    if (totalCoins > 100) {
        const totalCoinsMinus100 = totalCoins - 100;
        const numBags = Math.ceil(totalCoinsMinus100 / 100);
        const coinBagRecord: GearRecord = { instanceId: uniqid(), gearId: "coins", name: "Coins", type: "sundry", totalUnits: totalCoinsMinus100, slots: numBags, quantity: 1, cost: 0, currency: "gp" };
        gearRecords.push(coinBagRecord);
    }

    return gearRecords;
}

export const getGearRecordsAsSlots = (armors: Armor[], gear: GearOwned[], treasures: Treasure[], magicItems: MagicItem[], gold: number, silver: number, copper: number) => {

    const gearRecords: GearRecord[] = [];

    gear.forEach((go) => {
        if (go.type === "weapon") {
            const theWeapon = weapons.find((w) => w.id === go.id);
            if (theWeapon) {
                const thisRecord: GearRecord = { instanceId: go.instanceId, gearId: go.id, name: theWeapon.name, type: "weapon", quantity: 1, totalUnits: 1, slots: theWeapon.slots, cost: theWeapon.cost, currency: theWeapon.currency };
                gearRecords.push(thisRecord);
            }
        }

        if (go.type === "armor") {
            const theArmor = armors.find((a) => a.id === go.id);
            if (theArmor) {
                const thisRecord: GearRecord = { instanceId: go.instanceId, gearId: go.id, name: theArmor.name, type: "armor", quantity: 1, totalUnits: 1, slots: theArmor.slots, cost: theArmor.cost, currency: theArmor.currency };
                gearRecords.push(thisRecord);
            }
        }

        if (go.type === "sundry") {
            const theSundry = sundries.find((a) => a.id === go.id);
            if (theSundry) {
                const thisRecord: GearRecord = { instanceId: go.instanceId, gearId: go.id, name: theSundry.name, type: "sundry", quantity: 1, totalUnits: go.totalUnits, slots: theSundry.slots, cost: theSundry.cost, currency: theSundry.currency };
                gearRecords.push(thisRecord);
            }
        }
    })

    // First backpack is zero slots
    let isFirstBackpack = true;
    gearRecords.forEach((gr) => {
        if (gr.gearId === "s2" && isFirstBackpack) {
            gr.slots = gr.slots - 1;
            isFirstBackpack = false;
        }
    })

    // treasures
    treasures.forEach((go) => {
        const thisRecord: GearRecord = { instanceId: go.id, gearId: "", name: go.name, type: "treasure", quantity: 1, totalUnits: 1, slots: go.slots, cost: go.cost, currency: go.currency };
        gearRecords.push(thisRecord);
    })

    // magic weapons
    magicItems.forEach((go) => {
        let slots = 0;
        if (go.magicItemType === "magicWeapon") {
            const theWeapon = weapons.find((w) => w.id === go.itemTypeId);
            if (theWeapon) {
                slots = theWeapon.slots;
                const thisRecord: GearRecord = { instanceId: go.id, gearId: go.itemTypeId, name: go.name, type: "weapon", quantity: 1, totalUnits: 1, slots: slots, cost: 0, currency: "gp" };
                gearRecords.push(thisRecord);
            }
        }
        if (go.magicItemType === "magicArmor") {
            const theArmor = armors.find((w) => w.id === go.itemTypeId);
            if (theArmor) {
                slots = theArmor.slots;
                const thisRecord: GearRecord = { instanceId: go.id, gearId: go.itemTypeId, name: go.name, type: "armor", quantity: 1, totalUnits: 1, slots: slots, cost: 0, currency: "gp" };
                gearRecords.push(thisRecord);
            }
        }
        if (go.magicItemType === "magicMisc") {
            slots = 1;
            const thisRecord: GearRecord = { instanceId: go.id, gearId: go.itemTypeId, name: go.name, type: "sundry", quantity: 1, totalUnits: 1, slots: slots, cost: 0, currency: "gp" };
            gearRecords.push(thisRecord);
        }

    })

    // add bags of coins
    const totalCoins = gold + silver + copper;
    if (totalCoins > 100) {
        const fullBags = Math.floor(totalCoins - 100) / 100;
        const partialBag = (totalCoins - 100) % 100;
        for (let b = 1; b <= fullBags; b++) {
            const coinBagRecord: GearRecord = { instanceId: uniqid(), gearId: "coins", name: "Coins", type: "sundry", totalUnits: 100, slots: 1, quantity: 1, cost: 0, currency: "gp" };
            gearRecords.push(coinBagRecord);
        }
        if (partialBag > 0) {
            const coinBagRecord: GearRecord = { instanceId: uniqid(), gearId: "coins", name: "Coins", type: "sundry", totalUnits: partialBag, slots: 1, quantity: 1, cost: 0, currency: "gp" };
            gearRecords.push(coinBagRecord);
        }
    }

    return gearRecords;
}

export const getStatByShortName = (shortName: string, stats: Stats) => {
    switch (shortName) {
        case "STR": return stats.Strength;
        case "DEX": return stats.Dexterity;
        case "CON": return stats.Constitution;
        case "INT": return stats.Intelligence;
        case "WIS": return stats.Wisdom;
        case "CHA": return stats.Charisma;
    }
    return 0;
}

export const getStatFullNameByShortName = (shortName: string) => {
    switch (shortName) {
        case "STR": return "Strength";
        case "DEX": return "Dexterity";
        case "CON": return "Constitution";
        case "INT": return "Intelligence";
        case "WIS": return "Wisdom";
        case "CHA": return "Charisma";
        default: return shortName;
    }
}

export const utilAddTalentBonuses = (talentName: string, bonuses: Bonus[], sourceType: string, sourceName: string, sourceCategory: string, level: number, isForRandomSelection: boolean, charClass: string, charClasses: CharClass[], selectedTalentOptions?: string[], parentBonusId?: string): Bonus[] => {

    let theTalent: Talent | undefined = undefined;
    const theClass = charClasses.find((cc) => cc.name === charClass);
    if (theClass) {
        theTalent = theClass?.talents.find((t) => t.name === talentName);
    }

    let updatedBonuses = [...bonuses];

    let bonusTo = "";
    if (selectedTalentOptions && selectedTalentOptions.length === 1) { bonusTo = selectedTalentOptions[0]; }

    // remove any existing Talent bonuses
    if (parentBonusId === undefined) {
        updatedBonuses = updatedBonuses.filter((b) => !(b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.gainedAtLevel === level));
    }

    // add standard talent bonus:
    let theBonus: Bonus | undefined = undefined;
    if (parentBonusId) {
        theBonus = updatedBonuses.find((b) => b.parentBonusId === parentBonusId);
        if (theBonus) {
            updatedBonuses = ifRemoveParentBonusAlsoRemoveChildBonuses(updatedBonuses, theBonus);

            theBonus.bonusTo = bonusTo;
            theBonus.name = talentName;
            theBonus.bonusName = talentName;
            if (talentName === "PlusOneHitDie") {
                let hitDie = 6;
                const theClass = charClasses.find((c) => c.name === charClass);
                if (theClass) {
                    hitDie = theClass.hitDie;
                }
                const hpBonusTo = getRandomIntInclusive(1, hitDie) + "/" + getRandomIntInclusive(1, hitDie)
                theBonus.bonusTo = hpBonusTo;
            }
        }
    }
    else {
        theBonus = updatedBonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.gainedAtLevel === level && b.name === talentName);
    }

    if (!theBonus) {
        const bonus: Bonus = {
            sourceType: sourceType,
            sourceName: sourceName,
            sourceCategory: sourceCategory,
            gainedAtLevel: level,
            name: talentName,
            bonusTo: bonusTo,
            bonusName: talentName,
        };
        if (theTalent && theTalent.hasId) {
            bonus.bonusId = uniqid();
        }
        if (parentBonusId) {
            bonus.parentBonusId = parentBonusId;
            bonus.bonusId = uniqid();
        }
        if (talentName === "PlusOneHitDie") {
            let hitDie = 6;
            const theClass = charClasses.find((c) => c.name === charClass);
            if (theClass) {
                hitDie = theClass.hitDie;
            }
            const hpBonusTo = getRandomIntInclusive(1, hitDie) + "/" + getRandomIntInclusive(1, hitDie)
            bonus.bonusTo = hpBonusTo;
        }
        updatedBonuses.push(bonus);
        updatedBonuses = renumberAnimalCompanions(updatedBonuses);
    }
    // }

    return updatedBonuses;
}

export const getCharacterDesc = (name: string, ancestry: string, charClass: string) => {
    let desc = "Nameless";

    if (name !== "") {
        desc = name;
    }

    if (name !== "" && (ancestry !== "" || charClass !== "")) {
        desc = desc + ", ";
    }

    if (ancestry !== "") {
        desc = desc + " " + ancestry;
    }

    if (charClass !== "") {
        desc = desc + " " + charClass;
    }

    // desc = desc + " (Level " + level + ")";

    return desc;
}

export const removeAnyCastWithAdvIfDoNotKnowSpell = (bonuses: Bonus[], charClasses: CharClass[], charClass: string, spells: Spell[]) => {
    let updatedBonuses = [...bonuses];

    const allSpellsKnown = getAllSpellsKnown(charClasses, charClass, spells, bonuses);
    updatedBonuses = updatedBonuses.filter((b) => {
        if (b.name === "AdvOnCastOneSpell") {
            const matchingSpell = allSpellsKnown.find((sk) => sk.name === b.bonusName);
            if (matchingSpell) { return true };
            return false;
        }
        return true;
    })

    return updatedBonuses;
}

export const renumberSpellsLearnedFromScrolls = (bonuses: Bonus[]) => {
    let updatedBonuses = [...bonuses];

    // remove any LearnSpellFromScroll bonuses that do not specify a spell
    updatedBonuses = updatedBonuses.filter((b) => !(b.bonusTo === "LearnSpellFromScroll" && b.bonusName === ""));

    // renumber any remaining
    updatedBonuses.filter((b) => b.bonusTo === "LearnSpellFromScroll").forEach((b, index) => {
        b.name = "LearnSpellFromScroll-" + (index);
    })

    return updatedBonuses;
}

export const renumberAnimalCompanions = (bonuses: Bonus[]) => {
    let updatedBonuses = [...bonuses];

    // remove any AnimalCompanion bonuses that do not specify an animal
    updatedBonuses = updatedBonuses.filter((b) => !(b.bonusTo === "AnimalCompanion" && b.bonusName === ""));

    // renumber any remaining
    updatedBonuses.filter((b) => b.bonusTo === "AnimalCompanion").forEach((b, index) => {
        b.name = "AnimalCompanion-" + (index + 1);
    })

    // remove any animal companions beyond number allowed 
    const numAnimalCompanionsAllowed = bonuses.filter((b) => b.name === "ExtraAnimalCompanion").length + 1;
    const numAnimalCompanionsPossessed = bonuses.filter((b) => b.bonusTo === "AnimalCompanion" && b.bonusName !== "").length;

    if (numAnimalCompanionsPossessed > numAnimalCompanionsAllowed) {
        let aCom = updatedBonuses.filter((b) => b.bonusTo === "AnimalCompanion");
        for (let a = 0; a < aCom.length; a++) {
            const name = aCom[a].name;
            if (name !== undefined) {
                const animalNumber = parseInt(name.split("-")[1]);
                if (animalNumber > numAnimalCompanionsAllowed) {
                    updatedBonuses = updatedBonuses.filter((a) => a.name !== "AnimalCompanion-" + animalNumber);
                }
            }
        }
    }

    return updatedBonuses;
}

export const removeAnyduplicateRollTwoAndPickOneBoons = (bonus: Bonus, bonuses: Bonus[]) => {
    let updatedBonuses = [...bonuses];

    if (bonus.boonSource === "WarlockTalentRollTwoBoonsAndKeepOne") {
        updatedBonuses = updatedBonuses.filter((b) => !(b.sourceType === bonus.sourceType && b.sourceCategory === bonus.sourceCategory && b.sourceName === bonus.sourceName && b.boonSource === "WarlockTalentRollTwoBoonsAndKeepOne"));
    }

    return updatedBonuses;
}

export const ifChangeWarlockPatronRemoveOldPatronBoons = (bonuses: Bonus[], bonus: Bonus) => {
    let updatedBonuses = [...bonuses];

    if (bonus.name !== "Patron") {
        return updatedBonuses;
    }

    const newPatron = bonus.bonusTo;

    // get existing patron
    let oldPatron = "";
    const existingBonus = bonuses.find((b) => b.sourceCategory === "Patron" && b.name === "Patron");
    if (existingBonus && existingBonus.bonusTo) { oldPatron = existingBonus.bonusTo; }

    // remove any Patron Boons that are from old patron
    if (oldPatron !== newPatron) {
        updatedBonuses = updatedBonuses.filter((b) => !(b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Boon" && b.boonPatron === oldPatron));
        updatedBonuses = updatedBonuses.filter((b) => !(b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Talent" && b.name === "RollTwoBoonsAndKeepOne"));
    }

    return updatedBonuses;
}

export const ifRemoveParentBonusAlsoRemoveChildBonuses = (bonuses: Bonus[], bonus: Bonus) => {
    let updatedBonuses = [...bonuses];

    //get exitsing bonus by bonusId
    const theBonus = updatedBonuses.find((b) => b.bonusId === bonus.bonusId);
    if (!theBonus) {
        return updatedBonuses;
    } else if (bonus.name !== "PlusOneStatAndRollAnotherTalent") {
        return updatedBonuses;
    }

    // recursively remove child bonuses of the bonus
    let count = 0;
    let parentBonusId = bonus.parentBonusId;
    let childBonusId: string | undefined = undefined;
    do {
        const childBonus = updatedBonuses.find((b) => b.parentBonusId === parentBonusId);
        if (childBonus) {
            childBonusId = childBonus.bonusId;
            parentBonusId = childBonusId;
            updatedBonuses = [...updatedBonuses.filter((b) => b.bonusId !== childBonusId)];
        } else {
            childBonusId = undefined;
        }
        count = count + 1;
    } while (childBonusId !== undefined && count < 100);


    return updatedBonuses;
}

export const whenSetWarlockTalentOrBoonRemoveExistingBoonsOrTalents = (bonuses: Bonus[], bonus: Bonus, levels: Level[], humanAmbitionTalentLevel: Level) => {

    if (bonus.name === "ChooseWarlockTalentOrPatronBoon") {
        // standard class talent/boon:
        if (bonus.sourceType === "Class") {
            const existingBonus = bonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "TalentOrBoon" && b.name === "ChooseWarlockTalentOrPatronBoon" && b.gainedAtLevel === bonus.gainedAtLevel)
            if (existingBonus) {
                if (existingBonus.bonusTo === "WarlockTalent" && bonus.bonusTo === "PatronBoon") {
                    // delete any previously selected Warlock Talents for the level
                    bonuses = bonuses.filter((b) => !(b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Talent" && b.gainedAtLevel === bonus.gainedAtLevel));
                    // also update level's record of selected talent or talent will stick around
                    const theLevel = levels.find((l) => l.level === bonus.gainedAtLevel);
                    if (theLevel) {
                        theLevel.talentRolledDesc = "";
                        theLevel.talentRolledName = "";
                    }
                    // delete any Warlock Boons granted as a result of a roll of 2 on the Warlock Talent (which grants a random boon)
                    bonuses = bonuses.filter((b) => !(b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Boon" && b.gainedAtLevel === bonus.gainedAtLevel && b.boonSource === "WarlockTalentRandomBoon"));
                    // delete any Warlock Boons granted as a result of a roll of 10-11 on the Warlock Talent (which grants a choice of two random boon)
                    bonuses = bonuses.filter((b) => !(b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Boon" && b.gainedAtLevel === bonus.gainedAtLevel && b.boonSource === "WarlockTalentRollTwoBoonsAndKeepOne"));
                }
                if (existingBonus.bonusTo === "PatronBoon" && bonus.bonusTo === "WarlockTalent") {
                    // delete any other previously selected Warlock Boons for the level
                    bonuses = bonuses.filter((b) => !(b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "Boon" && b.boonSource === "StandardBoon" && b.gainedAtLevel === bonus.gainedAtLevel));
                }
            }
        }
        // possible human ambition talent/boon:
        if (bonus.sourceType === "Ancestry") {
            const humAmbExistingBonus = bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Human Ambition" && b.sourceCategory === "TalentOrBoon" && b.name === "ChooseWarlockTalentOrPatronBoon" && b.gainedAtLevel === 1);
            if (humAmbExistingBonus) {
                if (humAmbExistingBonus.bonusTo === "WarlockTalent" && bonus.bonusTo === "PatronBoon") {
                    // delete any previously selected Warlock Talents for the level
                    bonuses = bonuses.filter((b) => !(b.sourceType === "Ancestry" && b.sourceName === "Human Ambition" && b.sourceCategory === "Talent" && b.gainedAtLevel === 1));
                    // also update level's record of selected talent or talent will stick around
                    humanAmbitionTalentLevel.talentRolledDesc = "";
                    humanAmbitionTalentLevel.talentRolledName = "";
                    // delete any Warlock Boons granted as a result of a roll of 2 on the Warlock Talent (which grants a random boon)
                    bonuses = bonuses.filter((b) => !(b.sourceType === "Ancestry" && b.sourceName === "Human Ambition" && b.sourceCategory === "Boon" && b.gainedAtLevel === bonus.gainedAtLevel && b.boonSource === "WarlockTalentRandomBoon"));
                    // delete any Warlock Boons granted as a result of a roll of 10-11 on the Warlock Talent (which grants a choice of two random boon)
                    bonuses = bonuses.filter((b) => !(b.sourceType === "Ancestry" && b.sourceName === "Human Ambition" && b.sourceCategory === "Boon" && b.gainedAtLevel === bonus.gainedAtLevel && b.boonSource === "WarlockTalentRollTwoBoonsAndKeepOne"));
                }
                if (humAmbExistingBonus.bonusTo === "PatronBoon" && bonus.bonusTo === "WarlockTalent") {
                    // delete any previously selected Warlock Boons for the level
                    bonuses = bonuses.filter((b) => !(b.sourceType === "Ancestry" && b.sourceName === "Human Ambition" && b.sourceCategory === "Boon" && b.boonSource === "StandardBoon" && b.gainedAtLevel === bonus.gainedAtLevel));
                }
            }
        }
    }

    return { bonuses, levels, humanAmbitionTalentLevel };
}

export const getTotalHitPoints = (level: number, levels: Level[], ancestry: string, charClass: string, charClasses: CharClass[], finalStats: Stats, bonuses: Bonus[]) => {
    let hp = 0;

    const hasStout = ancestry === "Dwarf";

    if (level > 0) {
        // level 1+
        levels.forEach((l) => {
            if (l.level <= level) {
                let hpGainedThisLevel = 0;
                if (l.rollIsMaximised && l.rollIsMaximised === true) {
                    const theClass = charClasses.find((c) => c.name === charClass);
                    if (theClass) {
                        hpGainedThisLevel = theClass.hitDie;
                    }
                } else {
                    hpGainedThisLevel = Math.max(l.HitPointRoll, l.stoutHitPointRoll);
                }
                if (l.level === 1) {
                    hpGainedThisLevel = hpGainedThisLevel + modNum(finalStats.Constitution);
                    if (hpGainedThisLevel < 1) { hpGainedThisLevel = 1; }
                    if (hasStout) { hpGainedThisLevel = hpGainedThisLevel + 2 }
                };
                hpGainedThisLevel = Math.max(hpGainedThisLevel, 1);
                hp = hp + hpGainedThisLevel;
            }
        });

        // Handle any bonus hit dice from bonuses at this level:
        const hitDieBonusesAtThisLevel = bonuses.filter((b) => b.gainedAtLevel <= level && b.name === "PlusOneHitDie");
        if (hitDieBonusesAtThisLevel.length > 0) {
            hitDieBonusesAtThisLevel.forEach((hpb) => {
                const bonusHP = hpb.bonusTo.split("/");
                if (hasStout) {
                    const hpInc = Math.max(parseInt(bonusHP[0], 10), parseInt(bonusHP[1], 10));
                    hp += hpInc;
                } else {
                    hp += parseInt(bonusHP[0], 10);
                }
            })
        }
    } else {
        // level 0
        hp = modNum(finalStats.Constitution);
        if (hp < 1) { hp = 1 }
        if (hasStout) hp = hp + 2;
    }

    if (hp < 1) { hp = 1 }

    return hp;
}

export const getRandomLevelOneItems = () => {

    const randomGear: any[] = [
        [{
            id: "s17", // Torch
            type: "sundry",
            totalUnits: 1
        }],
        [{
            id: "w4", // Dagger
            type: "weapon",
            totalUnits: 1
        }],
        [{
            id: "s14", // Pole
            type: "sundry",
            totalUnits: 1
        }],
        [
            {
                id: "w11", // Shortbow
                type: "weapon",
                totalUnits: 1
            },
            {
                id: "s1", // and five arrows, need code to reduce quant from 20 to 5
                type: "sundry",
                totalUnits: 5
            },
        ],
        [{
            id: "s16", // Rope
            type: "sundry",
            totalUnits: 1
        }],
        [{
            id: "s13", // Oil flask
            type: "sundry",
            totalUnits: 1
        }],
        [{
            id: "s6", // Crowbar
            type: "sundry",
            totalUnits: 1
        }],
        [{
            id: "s10", // Iron spikes
            type: "sundry",
            totalUnits: 10
        }],
        [{
            id: "s8", // Flint and steel
            type: "sundry",
            totalUnits: 1
        }],
        [{
            id: "s9", // Grappling hook
            type: "sundry",
            totalUnits: 1
        }],
        [{
            id: "w2", // Club
            type: "weapon",
            totalUnits: 1
        }],
        [{
            id: "s3", // Caltrops
            type: "sundry",
            totalUnits: 1
        }]
    ];

    const gear: GearOwned[] = [];
    const numItems = getRandomIntInclusive(1, 4);
    let numArrows = 0;

    for (let i = 0; i < numItems; i++) {
        const randItemIndex = getRandomIntInclusive(0, randomGear.length - 1);
        const randomItems = randomGear[randItemIndex];

        randomItems.forEach((ri: any) => {
            if (ri.id !== "s1") { // special handling for arrows
                const newGear: GearOwned = {
                    instanceId: uniqid(),
                    id: ri.id,
                    type: ri.type,
                    totalUnits: ri.totalUnits
                }
                gear.push(newGear);
            } else {
                numArrows += 5;
            }
        })
    }

    // add arrows
    if (numArrows > 0) {
        const newGear: GearOwned = {
            instanceId: uniqid(),
            id: "s1",
            type: "sundry",
            totalUnits: numArrows
        }
        gear.push(newGear);
    }

    return gear;
}

export const calculateTotalGearSlots = (finalStats: Stats, charClass: string) => {
    let slots = Math.max(finalStats.Strength, 10);
    if (charClass === "Fighter") {
        slots = slots + Math.max(modNum(finalStats.Constitution), 0);
    }
    return slots;
}

export const handleBuyItem = (id: string, type: string, isBuy: boolean, cost: number, currency: string, gold: number, silver: number, copper: number, gearCarried: GearOwned[]) => {

    let currentGold = gold;
    let currentSilver = silver;
    let currentCopper = copper;

    let totalUnits = 0;
    if (type === "weapon" || type === "armor") { totalUnits = 1; }
    if (type === "sundry") {
        const theSundry = sundries.find((s) => s.id === id);
        if (theSundry) { totalUnits = theSundry.quantityPerSlot; }
    }

    const updatedGear = [...gearCarried];
    if (isBuy) {
        const newGear: GearOwned = { instanceId: uniqid(), id: id, type: type, totalUnits: totalUnits }
        updatedGear.push(newGear);
        if (currency === "gp") {
            currentGold = currentGold - cost;
        }
        if (currency === "sp") {
            if (silver >= cost) {
                currentSilver = currentSilver - cost;
            } else {
                // break a gp
                if (gold > 0) {
                    currentGold = currentGold - 1;
                    currentSilver = currentSilver + (10 - cost);
                }
            }
        }
        if (currency === "cp") {
            if (copper >= cost) {
                currentCopper = currentCopper - cost;
            } else {
                // break a sp
                if (silver > 0) {
                    currentSilver = currentSilver - 1;
                    currentCopper = currentCopper + (10 - cost);
                } else if (gold > 0) {
                    // break a gp
                    currentGold = currentGold - 1;
                    currentCopper = currentCopper + (100 - cost);
                }
            }
        }
    } else {
        const firstGearIndex = updatedGear.map((g) => g.id).indexOf(id);
        if (firstGearIndex !== -1) {
            updatedGear.splice(firstGearIndex, 1);
            if (currency === "gp") {
                currentGold = currentGold + cost;
            }
            if (currency === "sp") {
                currentSilver = currentSilver + cost;
            }
            if (currency === "cp") {
                currentCopper = currentCopper + cost;
            }
        }
    }

    const simplifiedCoins = simplifyCoins(currentGold, currentSilver, currentCopper);
    currentGold = simplifiedCoins.gold;
    currentSilver = simplifiedCoins.silver;
    currentCopper = simplifiedCoins.copper;

    return {
        gold: currentGold,
        silver: currentSilver,
        copper: currentCopper,
        gear: updatedGear
    }

}

export const simplifyCoins = (currentGold: number, currentSilver: number, currentCopper: number) => {

    if (currentCopper >= 100) {
        const numCopperForGold = Math.trunc(currentCopper / 100);
        currentGold = currentGold + numCopperForGold;
        currentCopper = currentCopper - (numCopperForGold * 100);
    }
    if (currentCopper >= 10) {
        const numCopperForSilver = Math.trunc(currentCopper / 10);
        currentSilver = currentSilver + numCopperForSilver;
        currentCopper = currentCopper - (numCopperForSilver * 10);
    }
    if (currentSilver >= 10) {
        const numSilverForGold = Math.trunc(currentSilver / 10);
        currentGold = currentGold + numSilverForGold;
        currentSilver = currentSilver - (numSilverForGold * 10);
    }

    return {
        gold: currentGold,
        silver: currentSilver,
        copper: currentCopper
    }

}

export const handleBuyCrawlingKit = (gold: number) => {

    const goldLeft = Math.max(gold - 7, 0);

    const gear = [
        {
            "instanceId": uniqid(),
            "id": "s2",
            "type": "sundry",
            "totalUnits": 1
        },
        {
            "instanceId": uniqid(),
            "id": "s8",
            "type": "sundry",
            "totalUnits": 1
        },
        {
            "instanceId": uniqid(),
            "id": "s17",
            "type": "sundry",
            "totalUnits": 1
        },
        {
            "instanceId": uniqid(),
            "id": "s17",
            "type": "sundry",
            "totalUnits": 1
        },
        {
            "instanceId": uniqid(),
            "id": "s15",
            "type": "sundry",
            "totalUnits": 3
        },
        {
            "instanceId": uniqid(),
            "id": "s10",
            "type": "sundry",
            "totalUnits": 1
        },
        {
            "instanceId": uniqid(),
            "id": "s9",
            "type": "sundry",
            "totalUnits": 1
        },
        {
            "instanceId": uniqid(),
            "id": "s16",
            "type": "sundry",
            "totalUnits": 1
        }
    ];

    return {
        gold: goldLeft,
        gear: gear,
    }

}

export const getAC = (gearCarried: GearOwned[], armors: Armor[], bonuses: Bonus[], finalStats: Stats, magicItems: MagicItem[], ancestries: Ancestry[], ancestry: string, charClasses: CharClass[], charClass: string) => {
    let armorACbase = 10;
    let armorACBonus = 0;
    let allowDEX = true;

    gearCarried.forEach((gc) => {
        if (gc.type === "armor") {
            const theArmor = armors.find((a) => a.id === gc.id);
            if (theArmor) {
                if (theArmor.ac_base > armorACbase) {
                    armorACbase = theArmor.ac_base;
                }
                if (theArmor.ac_bonus > armorACBonus) {
                    armorACBonus = theArmor.ac_bonus;
                }
                if (!theArmor.allowsDEX) {
                    allowDEX = false;
                }
                // check if has armor mastery
                bonuses.forEach((b) => {
                    if (b.name === "ArmorMastery" && theArmor.name === b.bonusTo) {
                        if (theArmor.isShield) {
                            armorACBonus += 1;
                        } else {
                            armorACbase += 1;
                        }
                    }
                })
            }
        }
    })

    let miscBonus = 0;

    magicItems.forEach((gc) => {
        if (gc.itemType === "armor") {
            const magicBonus = gc.bonus;
            const theArmor = armors.find((a) => a.id === gc.itemTypeId);
            if (theArmor) {
                if (theArmor.ac_base > armorACbase) {
                    armorACbase = theArmor.ac_base + magicBonus;
                }
                if (theArmor.ac_bonus > armorACBonus) {
                    armorACBonus = theArmor.ac_bonus + magicBonus;
                }
                if (!theArmor.allowsDEX) {
                    allowDEX = false;
                }
                // check if has armor mastery
                bonuses.forEach((b) => {
                    if (b.name === "ArmorMastery" && theArmor.name === b.bonusTo) {
                        if (theArmor.isShield) {
                            armorACBonus += 1;
                        } else {
                            armorACbase += 1;
                        }
                    }
                })
            }
        }
        if (gc.properties) {
            const hasACBonus = gc.properties.find((p) => p.indexOf("AC:") !== -1);
            if (hasACBonus) {
                miscBonus += parseInt(hasACBonus.split(":")[1]);
            }
            const hasBaseAC = gc.properties.find((p) => p.indexOf("AC_Base:") !== -1);
            if (hasBaseAC) {
                const baseAC = parseInt(hasBaseAC.split(":")[1]);
                if (baseAC > armorACbase) {
                    armorACbase = baseAC;
                }
            }
        }
    })


    // check for ancestry abilities that affect AC which are not stored as bonuses
    let ancestryBonus = 0;
    const theAncestry = ancestries.find((a) => a.name === ancestry);
    if (theAncestry) {
        const hasArmored = theAncestry.extras.find((ex) => ex.name === "Armored Shell");
        if (hasArmored) {
            ancestryBonus = 1;
        }
    }

    // check for class abilities that affect AC which are not stored as bonuses
    let classBonus = 0;
    const theClass = charClasses.find((c) => c.name === charClass);
    if (theClass) {
        const hasBeastHyde = theClass.extras.find((ex) => ex.name === "Beast Hyde");
        if (hasBeastHyde) {
            classBonus = Math.max(modNum(finalStats.Constitution), 0);
        }
    }

    // check for bonuses that affect AC
    bonuses.forEach((b) => {
        if (b.bonusName === "ForesightPlus1AC") { miscBonus = miscBonus + 1; }
        if (b.bonusName === "BeastMasterPlus1AC") { miscBonus = miscBonus + 1; }
    })


    let dexBonus = modNum(finalStats.Dexterity);
    if (!allowDEX) { dexBonus = 0; }

    return armorACbase + armorACBonus + dexBonus + miscBonus + ancestryBonus + classBonus;
}

export const addBeastMasterWeapons = (weapons: Weapon[], level: number, bonuses: Bonus[]) => {
    const allWeapons = [...weapons];

    // Check for any BeastAttackDamageIncreaseOneDie bonuses and increase damage die
    const dmgIncreaseBonsues = bonuses.filter((b) => b.name === "BeastAttackDamageIncreaseOneDie");
    const numDieIncreases = dmgIncreaseBonsues.length;

    const getDmgDie = (baseDie: number) => {
        return Math.min(baseDie + (numDieIncreases * 2), 12);
    }

    const damageMult = Math.max(Math.trunc(level / 2), 1);

    const wolfWeapon: Weapon = { id: "beastMasterWolfWeapon", name: "Beast Attack (Wolf Bite)", cost: 0, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [getDmgDie(6)], damageMult: [damageMult], versatile: false, slots: 0, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 0, isBackupWeapon: false, sourceIds: ["US"] };
    allWeapons.push(wolfWeapon);

    const bearWeapon: Weapon = { id: "beastMasterBearWeapon", name: "Beast Attack (Bear Claw)", cost: 0, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [getDmgDie(8)], versatile: false, slots: 0, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 0, isBackupWeapon: false, sourceIds: ["US"] };
    allWeapons.push(bearWeapon);

    const snakeWeapon: Weapon = { id: "beastMasterSnakeWeapon", name: "Beast Attack (Snake Bite)", cost: 0, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [getDmgDie(4)], versatile: false, slots: 0, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 0, isBackupWeapon: false, sourceIds: ["US"] };
    allWeapons.push(snakeWeapon);

    const eagleWeapon: Weapon = { id: "beastMasterEagleWeapon", name: "Beast Attack (Eagle Talons)", cost: 0, currency: "gp", types: ["M"], ranges: ["C"], rangeMult: 1, damage: [getDmgDie(6)], versatile: false, slots: 0, loading: false, twoHands: false, thrown: false, finesse: false, sundering: false, isSniper: false, tangling: false, isLash: false, injectable: false, gas: false, weaponMasterPopularity: 0, isBackupWeapon: false, sourceIds: ["US"] };
    allWeapons.push(eagleWeapon);

    return allWeapons;
}

export const addBeastMasterWeaponGearRecords = (gearRecords: GearRecord[], bonuses: Bonus[]) => {

    let allGearRecords = [...gearRecords];

    let extraRecords: GearRecord[] = [];

    // Wolf
    const wolfBonus = bonuses.find((b) => b.name === "BeastMasterAnimalType" && b.bonusTo === "Wolf");
    if (wolfBonus) {
        const wolfWeaponGearRecord: GearRecord = { instanceId: "wolfWeapon", gearId: "beastMasterWolfWeapon", name: "Beast Attack (Wolf Bite)", type: "weapon", quantity: 1, totalUnits: 1, slots: 0, cost: 0, currency: "gp" };
        extraRecords.push(wolfWeaponGearRecord);
    }

    // Bear
    const bearBonus = bonuses.find((b) => b.name === "BeastMasterAnimalType" && b.bonusTo === "Bear");
    if (bearBonus) {
        const bearWeaponGearRecord: GearRecord = { instanceId: "bearWeapon", gearId: "beastMasterBearWeapon", name: "Beast Attack (Bear Claw)", type: "weapon", quantity: 1, totalUnits: 1, slots: 0, cost: 0, currency: "gp" };
        extraRecords.push(bearWeaponGearRecord);
    }

    // Snake
    const snakeBonus = bonuses.find((b) => b.name === "BeastMasterAnimalType" && b.bonusTo === "Snake");
    if (snakeBonus) {
        const snakeWeaponGearRecord: GearRecord = { instanceId: "snakeWeapon", gearId: "beastMasterSnakeWeapon", name: "Beast Attack (Snake Bite)", type: "weapon", quantity: 1, totalUnits: 1, slots: 0, cost: 0, currency: "gp" };
        extraRecords.push(snakeWeaponGearRecord);
    }

    // Eagle
    const eagleBonus = bonuses.find((b) => b.name === "BeastMasterAnimalType" && b.bonusTo === "Eagle");
    if (eagleBonus) {
        const eagleWeaponGearRecord: GearRecord = { instanceId: "eagleWeapon", gearId: "beastMasterEagleWeapon", name: "Beast Attack (Eagle Talons)", type: "weapon", quantity: 1, totalUnits: 1, slots: 0, cost: 0, currency: "gp" };
        extraRecords.push(eagleWeaponGearRecord);
    }

    return [...extraRecords, ...allGearRecords];
}

export const getWeaponAttacks = (armors: Armor[], gearCarried: GearOwned[], finalStats: Stats, ancestries: Ancestry[], ancestry: string, bonuses: Bonus[], level: number, charClass: string, charClasses: CharClass[], magicItems: MagicItem[], demonicPossessionBonus: number) => {

    const atk: string[] = [];
    let gearRecords = getGearRecordsAsSlots(armors, gearCarried, [], magicItems, 0, 0, 0);

    let allWeapons = [...weapons];

    if (charClass === "Beastmaster") {
        allWeapons = addBeastMasterWeapons(allWeapons, level, bonuses);
        gearRecords = addBeastMasterWeaponGearRecords(gearRecords, bonuses);
    }

    const weaponsAdded: string[] = [];
    gearRecords.forEach((gr) => {
        if (gr.type === "weapon") {
            // get weapon stats
            const theWeapon = allWeapons.find((w) => w.id === gr.gearId);
            if ((theWeapon && weaponsAdded.indexOf(theWeapon.name) === -1) || (theWeapon && gr.cost === 0)) { // magic items have cost 0, always want toinclude each of those
                let w = [];
                weaponsAdded.push(theWeapon.name);

                // check if is a magic weapon 
                let magicBonus = 0;
                const theMagicWeapon = magicItems.find((mw) => mw.id === gr.instanceId);
                if (theMagicWeapon) {
                    magicBonus = theMagicWeapon.bonus;
                }

                // check if any magic items have damage bonus properties
                let magicItemMeleeDamageBonus = 0;
                let magicItemRangedDamageBonus = 0;
                magicItems.forEach((mi) => {
                    if (mi.properties) {
                        mi.properties.forEach((p) => {
                            if (p.indexOf("MeleeDamage") !== -1) {
                                const splitMeleeDamage = p.split(":");
                                magicItemMeleeDamageBonus += parseInt(splitMeleeDamage[1], 10);
                            }
                            if (p.indexOf("RangedDamage") !== -1) {
                                const splitRangedDamage = p.split(":");
                                magicItemRangedDamageBonus += parseInt(splitRangedDamage[1], 10);
                            }
                        })
                    }
                })

                // TO HIT (and DAMAGE)

                let meleeToHit = 0;
                let rangedOrThrownToHit = 0;

                let meleeDamageBonus = 0;
                let rangedDamageBonus = 0;

                let weaponDamage0 = theWeapon.damage[0];
                let weaponDamageMult0 = theWeapon.damageMult ? theWeapon.damageMult[0] : 1;
                let weaponDamage1 = theWeapon.damage[1];
                let weaponDamageMult1 = theWeapon.damageMult ? theWeapon.damageMult[1] : 1;

                const isMelee = theWeapon.types.indexOf("M") !== -1;
                const isRanged = theWeapon.types.indexOf("R") !== -1;
                const isThrown = theWeapon.thrown;
                const isFinesse = theWeapon.finesse;
                const isSundering = theWeapon.sundering;
                const isTangling = theWeapon.tangling;
                const isInjectable = theWeapon.injectable;
                const isLash = theWeapon.isLash;
                const isGas = theWeapon.gas;

                const strToHitBonus = modNum(finalStats.Strength);
                const dexToHitBonus = modNum(finalStats.Dexterity);
                const strOrDexToHitBonus = modNum(Math.max(finalStats.Strength, finalStats.Dexterity))

                // Melee stats bonus:
                meleeToHit = strToHitBonus;
                if (isFinesse) { meleeToHit = strOrDexToHitBonus; }
                if (isThrown) { meleeToHit = strToHitBonus; }

                // Ranged/Thrown stats bonus:
                rangedOrThrownToHit = dexToHitBonus;
                if (isFinesse) { rangedOrThrownToHit = strOrDexToHitBonus; }
                if (isThrown) { rangedOrThrownToHit = strOrDexToHitBonus; }

                // check for ancestry abilities that affect to hit and damage which are not stored as bonuses
                const theAncestry = ancestries.find((a) => a.name === ancestry);
                if (theAncestry) {
                    const hasMighty = theAncestry.extras.find((ex) => ex.name === "Mighty");
                    if (hasMighty) {
                        if (isMelee) {
                            meleeToHit += 1;
                            meleeDamageBonus += 1;
                        }
                    }
                }

                // check for talents/abilities that affect to hit and damage
                bonuses.forEach((b) => {
                    if (b.name === "WeaponMastery" && b.bonusTo === theWeapon.name) {
                        meleeToHit += 1 + Math.trunc(level / 2);
                        rangedOrThrownToHit += 1 + Math.trunc(level / 2);
                        meleeDamageBonus += 1 + Math.trunc(level / 2);
                        rangedDamageBonus += 1 + Math.trunc(level / 2);
                    }
                    if (b.name === "Plus1ToHit" || (b.name === "Plus1ToAttacksOrPlus1ToMagicalDabbler" && b.bonusName === "Plus1ToHit") || (b.name === "Plus1ToAttacksOrDamage" && b.bonusName === "Plus1ToHit") || (b.name === "LongbowOrPlus1Ranged" && b.bonusName === "Plus1ToHit") || (b.bonusTo === "Melee and ranged attacks" && b.bonusName === "Plus1ToHit")) {
                        if (b.bonusTo === "Melee and ranged attacks") {
                            meleeToHit += 1;
                            rangedOrThrownToHit += 1;
                        }
                        if (b.bonusTo === "Melee attacks" && isMelee) {
                            meleeToHit += 1;
                        }
                        if (b.bonusTo === "Ranged attacks" && isRanged) {
                            rangedOrThrownToHit += 1;
                        }
                    }
                    if (b.name === "Plus1ToHitAndDamage") {
                        if (b.bonusTo === "Melee and ranged attacks") {
                            meleeToHit += 1;
                            meleeDamageBonus += 1;
                            rangedOrThrownToHit += 1;
                            rangedDamageBonus += 1;
                        }
                        if (b.bonusTo === "Melee attacks" && isMelee) {
                            meleeToHit += 1;
                            meleeDamageBonus += 1;
                        }
                        if (b.bonusTo === "Ranged attacks" && isRanged) {
                            rangedOrThrownToHit += 1;
                            rangedDamageBonus += 1;
                        }
                    }
                    if (b.name === "Plus1ToMeleeDamage" || b.bonusName === "Plus1ToMeleeDamage") {
                        meleeDamageBonus += 1;
                    }
                    if (b.bonusName === "Plus1ToMeleeAttacks") {
                        meleeToHit += 1;
                    }
                    if (b.bonusName === "Plus1ToDamage") {
                        meleeDamageBonus += 1;
                        rangedDamageBonus += 1;
                    }
                    if (b.name === "FarSight" && b.bonusName === "AttackBonus") {
                        if (b.bonusTo === "RangedWeapons" && isRanged) {
                            rangedOrThrownToHit += 1;
                        }
                    }
                    if (b.name === "SetWeaponTypeDamage") {
                        const weaponAndDie = b.bonusTo.split(":");
                        if (weaponAndDie[0] === theWeapon.name) {
                            weaponDamage0 = parseInt(weaponAndDie[1]);
                            weaponDamage1 = parseInt(weaponAndDie[1]);
                        }
                    }
                })

                // add magic weapon bonus
                meleeToHit += magicBonus;
                rangedOrThrownToHit += magicBonus;

                let toHitAndRangeStr = "";
                if (isMelee && !isRanged) {
                    toHitAndRangeStr += plusMinus(meleeToHit);
                } else {
                    if (isThrown) {
                        const hasDifferentMeleeAndRangedToHit = meleeToHit !== rangedOrThrownToHit;
                        if (hasDifferentMeleeAndRangedToHit) {
                            toHitAndRangeStr += plusMinus(meleeToHit) + "/" + plusMinus(rangedOrThrownToHit);
                        } else {
                            toHitAndRangeStr += plusMinus(meleeToHit);
                        }
                    } else {
                        toHitAndRangeStr += plusMinus(rangedOrThrownToHit);
                    }
                }

                let rangeNote = "";
                const isNearRange = theWeapon.ranges.indexOf("N") !== -1;
                const isFarRange = theWeapon.ranges.indexOf("F") !== -1;
                if (isNearRange) { rangeNote = (" (N)"); }
                if (isFarRange) { rangeNote = (" (F)"); }
                if (theWeapon.rangeMult > 1) { rangeNote = (" (" + theWeapon.rangeMult + "xC)"); }
                if (rangeNote !== "") { toHitAndRangeStr += rangeNote; }

                w.push(toHitAndRangeStr);

                // DAMAGE

                // add magic weapon bonus
                meleeDamageBonus += magicBonus + magicItemMeleeDamageBonus;
                rangedDamageBonus += magicBonus + magicItemRangedDamageBonus;

                let damStr = "";
                const isVersatile = theWeapon.versatile;

                const meleeDamageStr = meleeDamageBonus !== 0 ? plusMinus(meleeDamageBonus) : "";
                const rangedDamageStr = rangedDamageBonus !== 0 ? plusMinus(rangedDamageBonus) : "";

                if (isVersatile) {
                    damStr = weaponDamageMult0 + "d" + weaponDamage0 + meleeDamageStr + "/" + weaponDamageMult1 + "d" + weaponDamage1 + meleeDamageStr;
                }
                else if (isThrown) {
                    if (meleeDamageBonus !== rangedDamageBonus) {
                        damStr += weaponDamageMult0 + "d" + weaponDamage0 + meleeDamageStr + "/" + weaponDamageMult0 + "d" + weaponDamage0 + rangedDamageStr;
                    } else {
                        damStr += damStr + weaponDamageMult0 + "d" + weaponDamage0 + meleeDamageStr;
                    }
                } else if (isRanged) {
                    damStr += damStr + weaponDamageMult0 + "d" + weaponDamage0 + rangedDamageStr;
                } else {
                    damStr += damStr + weaponDamageMult0 + "d" + weaponDamage0 + meleeDamageStr;
                }

                // no damage weapons
                if (weaponDamage0 === 0) { damStr = "-"; }

                // OTHER

                const isReload = theWeapon.loading;
                const notesStr: String[] = [];

                if (isReload) { notesStr.push("skip move to reload"); }
                if (theWeapon.twoHands) { notesStr.push("2H"); }
                if (isFinesse) { notesStr.push("FIN"); }
                if (isGas) { notesStr.push("GAS"); }
                if (isInjectable) { notesStr.push("INJ"); }
                if (isLash) { notesStr.push("LASH"); }
                if (isVersatile) { notesStr.push("V"); }
                if (isSundering) { notesStr.push("SUNDER"); }
                if (isTangling) { notesStr.push("TANGLE"); }

                // CHECK IF CAN USE WEAPON
                const permittedWeapons = getPermittedWeaponsByClassName(charClasses, weapons, bonuses, charClass);
                const allowedWeap = permittedWeapons.find((w) => w.name.toUpperCase() === theWeapon.name.toUpperCase())

                const isBeastMasterAttack = (id: string) => {
                    return ["beastMasterWolfWeapon", "beastMasterBearWeapon", "beastMasterSnakeWeapon", "beastMasterEagleWeapon"].indexOf(id) !== -1;
                }
                if (!allowedWeap && !isBeastMasterAttack(theWeapon.id)) {
                    notesStr.push("NOT PROFICIENT");
                }

                if (theWeapon.id === "beastMasterBearWeapon") { notesStr.push("Attack with disad to double damage dice") }
                if (theWeapon.id === "beastMasterSnakeWeapon") { notesStr.push("Poison: DC 9 CON check or paralyzed for 1d4 rounds") }
                if (theWeapon.id === "beastMasterEagleWeapon") { notesStr.push("Attack grapples target; DC 9 STR check to escape. If grappled, 1d6 peck damage each round") }

                if (notesStr.length > 0) (damStr += " (" + notesStr.join(", ") + ")");

                w.push(damStr);

                let name = gr.name.trim();
                if (theMagicWeapon) {
                    if (name !== theWeapon.name + " +" + theMagicWeapon.bonus) {
                        name = name + " (" + theWeapon.name + " +" + theMagicWeapon.bonus + ")";
                    }
                    if (theMagicWeapon.attackNote) {
                        w.push(theMagicWeapon.attackNote);
                    }
                    w.push("Magic");
                }

                // FINAL OUTPUT

                atk.push(name.toUpperCase() + ": " + w.join(", "));

            }
        }
    })

    // Thieves' backstab note
    if (charClass === "Thief") {
        let stabBonus = 1 + Math.trunc(level / 2);
        const backStabIncreases = bonuses.filter((b) => b.name === "BackstabIncrease");
        if (backStabIncreases) {
            stabBonus = stabBonus + backStabIncreases.length;
        }
        const die = stabBonus === 1 ? "die" : "dice";
        atk.push("Backstab: +" + stabBonus + " weapon " + die + " of damage with surprise attacks");
    }

    // Demonic Possession note
    if (demonicPossessionBonus !== 0) {
        atk.push("Demonic Possession: 3/day, +" + demonicPossessionBonus + " damage for 3 rounds");
    }

    // Desert Rider note
    if (charClass === "Desert Rider") {
        atk.push("Charge: 3/day, move to near, melee attacks deal double damage that round");
    }

    // Fiend Firebond Weapon note
    if (charClass === "Fiend") {
        let out: string[] = [];

        // firebond weapon damage
        let firebondDamageDie = 4;
        const impFirebondBonuses = bonuses.filter((b) => b.name === "Pyro_FirebondWeaponDieIncrease");
        if (impFirebondBonuses.length > 0) {
            firebondDamageDie = firebondDamageDie + (2 * impFirebondBonuses.length);
        }
        firebondDamageDie = Math.min(firebondDamageDie, 12);
        out.push("Firebond weapons do +1d" + firebondDamageDie + " dmg");

        // flaming impact
        const flamingImpactBonuses = bonuses.filter((b) => b.name === "Pyro_FlamingImpact");
        if (flamingImpactBonuses.length > 0) {
            out.push("+" + flamingImpactBonuses.length + " dmg per round until extinguished")
        }

        // boiling blood spray
        const boilBonuses = bonuses.filter((b) => b.name === "Pyro_BoilingBloodSpray");
        if (boilBonuses.length > 0) {
            out.push("+" + boilBonuses.length + " dmg to creatures in close range")
        }


        // hot hands spray
        const hotHandsBonuses = bonuses.filter((b) => b.name === "Pyro_HotHands");
        if (hotHandsBonuses.length > 0) {
            let DC = 18;
            const hotHandsDCs = [12, 15, 18];
            if (hotHandsDCs[hotHandsBonuses.length - 1]) {
                DC = hotHandsDCs[hotHandsBonuses.length - 1];
            }
            out.push("+ DC" + DC + " CON check or drop weapon, one round to cool")
        }

        // flare phobia
        const flareBonuses = bonuses.filter((b) => b.name === "Pyro_Flarephobia");
        if (flareBonuses.length > 0) {
            let DC = 9;
            const flareDCs = [9, 12, 15, 18];
            if (flareDCs[flareBonuses.length - 1]) {
                DC = flareDCs[flareBonuses.length - 1];
            }
            out.push("+ make a DC" + DC + " morale check")
        }

        atk.push(out.join(", "))
    }

    return atk;
}

export const getSpellCastingBonus = (charClasses: CharClass[], charClass: string, finalStats: Stats, bonuses: Bonus[]) => {
    let scb = 0;
    // Stat for bonus
    let statBonus = 0;
    let theClass = charClasses.find((c) => c.name === charClass);
    if (theClass) {
        const castingStat = theClass.spellCastingStat;
        if (castingStat !== "") {
            switch (castingStat) {
                case "STR": statBonus = modNum(finalStats.Strength); break;
                case "DEX": statBonus = modNum(finalStats.Dexterity); break;
                case "CON": statBonus = modNum(finalStats.Constitution); break;
                case "INT": statBonus = modNum(finalStats.Intelligence); break;
                case "WIS": statBonus = modNum(finalStats.Wisdom); break;
                case "CHA": statBonus = modNum(finalStats.Charisma); break;
            }
        }
    }
    scb = scb + statBonus;

    // bonuses
    bonuses.forEach((b) => {
        if (b.bonusName === "Plus1ToCastingSpells") {
            if (b.bonusTo.toUpperCase() === "SPELLCASTING") { scb = scb + 1; }
            if (theClass) {
                const spellClassName = getClassNameForSpellCastingClassNum(theClass.spellCastingClassNum, undefined);
                if (b.bonusTo.toUpperCase() === spellClassName.toUpperCase()) { scb = scb + 1; }
            }
        }
    });

    return scb;
}

export const getDemonicPossessionBonus = (charClasses: CharClass[], charClass: string, level: number, bonuses: Bonus[]) => {
    let dpb = 0;
    // Stat for bonus
    let theClass = charClasses.find((c) => c.name === charClass);
    if (theClass) {
        if (theClass.extras.find((e) => e.name === "Demonic Possession")) {
            dpb = 1; // base +1 
            dpb = dpb + Math.floor(level / 2); // add half level, rounded down

            // bonuses
            bonuses.forEach((b) => {
                if (b.bonusName === "Plus1DemonicPossession") {
                    dpb = dpb + 1;
                }
            });
        }
    }
    return dpb;
}

export const downloadFile = (data: any, fileName: string, fileType: string) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export const getFinalStatsAsString = (finalStats: Stats) => {
    let out: any[] = [];
    out.push("STR " + finalStats.Strength + " (" + modStr(finalStats.Strength) + ")");
    out.push("DEX " + finalStats.Dexterity + " (" + modStr(finalStats.Dexterity) + ")");
    out.push("CON " + finalStats.Constitution + " (" + modStr(finalStats.Constitution) + ")");
    out.push("INT " + finalStats.Intelligence + " (" + modStr(finalStats.Intelligence) + ")");
    out.push("WIS " + finalStats.Wisdom + " (" + modStr(finalStats.Wisdom) + ")");
    out.push("CHA " + finalStats.Charisma + " (" + modStr(finalStats.Charisma) + ")");
    return out.join(", ");
}

export const getSourceDesc = (wideMode: boolean, coreRulesOnly: boolean) => {
    if (wideMode) {
        if (coreRulesOnly) {
            return "Enable 3rd party sources"
        }
        return "3rd party sources enabled";
    } else {
        if (coreRulesOnly) {
            return "No 3rd party"
        }
        return "3rd party";
    }
}

export const getRandomDesc = (randomType: string) => {
    switch (randomType) {
        case "Best Fit": return <span><b>Best Fit:</b> Always chooses the class that best matches your stats</span>;
        case "Mixed": return <span><b>Mixed:</b> Probably chooses the class that best matches your stats</span>;
        case "Random": return <span><b>Full Random:</b> Class chosen at random with no regard to stats</span>;
        default: return "";
    }
}

export const getHasStout = (ancestry: string) => {
    return ancestry === "Dwarf";
}

export const getCharacterFeatures = (charClass: string, level: number, finalStats: Stats, AC: number, HP: number, languages: string, spells: string, title: string, isMinimal: boolean, cheats: Cheat[], weaponsPermitted: Weapon[], armorPermitted: Armor[]) => {

    const getCheats = () => {
        if (cheats.length > 0) { return cheats.map((c) => c.desc).join("; "); }
        return "None";
    }

    const addNone = (text: string) => {
        if (text === "") { return "None" }
        return text;
    }

    return (
        <div className="row">
            {/* <div className="col-4 col-sm-3"><b>Level</b></div>
            <div className="col-8 col-sm-9">{charClass !== "Level 0" ? level : "0"}</div> */}
            <div className="col-4 col-sm-3"><b>Final Stats</b></div>
            <div className="col-8 col-sm-9">{getFinalStatsAsString(finalStats)}</div>
            {!isMinimal &&
                <>

                    <div className="col-4 col-sm-3"><b>Hit Points</b></div>
                    <div className="col-8 col-sm-9">{HP}</div>
                    <div className="col-4 col-sm-3"><b>Armor Class</b></div>
                    <div className="col-8 col-sm-9">{AC}</div>
                    <div className="col-4 col-sm-3"><b>Weapons Permitted</b></div>
                    <div className="col-8 col-sm-9">{addNone(weaponsPermitted.map((w) => w.name).join(", "))}</div>
                    <div className="col-4 col-sm-3"><b>Armor Permitted</b></div>
                    <div className="col-8 col-sm-9">{addNone(armorPermitted.map((a) => a.name).join(", "))}</div>
                    <div className="col-4 col-sm-3"><b>Languages</b></div>
                    <div className="col-8 col-sm-9">{languages}</div>
                    {spells !== "None" &&
                        <>
                            <div className="col-4 col-sm-3"><b>Spells</b></div>
                            <div className="col-8 col-sm-9">{spells}</div>
                        </>
                    }
                    <div className="col-4 col-sm-3"><b>Title</b></div>
                    <div className="col-8 col-sm-9">{title}</div>

                    <div className="col-4 col-sm-3"><b>Edits</b></div>
                    <div className="col-8 col-sm-9">{getCheats()}</div>
                </>
            }
        </div>
    )

}

export const isEmail = (email: string): boolean => { return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); }

export const getClassNameForSpellCastingClassNum = (spellCastingClassNum: number | undefined, spell: Spell | undefined) => {
    switch (spellCastingClassNum) {
        case 0: return "Priest";
        case 1: return "Wizard";
        case 2: return "Witch";
        case 3: return "Seer";
        case 4: return "Grave Warden";
        case 5: return "Ovate";
        case 6: return "Shaman";
        case 7: {
            // Roustabout: Return the name of the first class that can cast the spell
            if (spell) {
                return spell.classes[0];
            } else {
                return "n/a";
            }

        }
        default: return "??";
    }
}

export const getPermittedWeaponsByClassName = (charClasses: CharClass[], allWeapons: Weapon[], bonuses: Bonus[], className: string | undefined, includeAllWeapons: boolean = false) => {
    let permittedWeaps: Weapon[] = [];

    const theClass = charClasses.find((c) => c.name === className);
    if (theClass) {
        if (theClass.weapons === "All weapons" || includeAllWeapons) {
            // add all weapons
            permittedWeaps = [...allWeapons];
        } else if (theClass.weapons.split(",").indexOf("All melee weapons") !== -1) {
            // add all melee weapons
            let meleeWeapons = allWeapons.filter((w) => w.types.indexOf("M") !== -1 && w.types.indexOf("R") === -1);
            permittedWeaps = [...meleeWeapons];
        } else if (theClass.weapons.split(",").indexOf("All missile weapons") !== -1) {
            // add all ranged weapons
            let rangedWeapons = allWeapons.filter((w) => w.types.indexOf("R") !== -1 && w.types.indexOf("M") === -1 && !w.thrown);
            permittedWeaps = [...rangedWeapons];
        }

        // add any remaining additional weapons (by name, not included in 'All melee weapons')
        const classWeaponsNames = theClass.weapons.split(",").map((w) => w.trim());
        classWeaponsNames.forEach((weapName) => {
            const foundWeapon = permittedWeaps.find((w) => w.name.toUpperCase() === weapName.toUpperCase());
            if (!foundWeapon) {
                const newWeapon = allWeapons.find((w) => w.name.toUpperCase() === weapName.toUpperCase());
                if (newWeapon) { permittedWeaps.push(newWeapon) }
            }
        })

        // add any additional weapons learned via bonuses
        const learnWeaponBonuses = bonuses.filter((b) => b.bonusName === "LearnWeapon");
        learnWeaponBonuses.forEach((b) => {
            const newWeapon = allWeapons.find((w) => w.name.toUpperCase() === b.bonusTo.toUpperCase());
            if (newWeapon) {
                const alreadyKnownWeapon = permittedWeaps.find((w) => w.name.toUpperCase() === newWeapon.name.toUpperCase());
                if (alreadyKnownWeapon === undefined) {
                    permittedWeaps.push(newWeapon)
                }
            }
        })

    }

    return permittedWeaps;
}

export const getPermittedArmorsByClassName = (charClasses: CharClass[], className: string | undefined, allArmors: Armor[], bonuses: Bonus[]) => {

    let arms = allArmors;

    const theClass = charClasses.find((c) => c.name === className);
    if (theClass) {

        const tweakArmorName = (name: string) => {
            if (name.trim() === "wooden shields") { return "shields"; }
            return name;
        }

        const allowedArmorNames = theClass.armor.split(", ").map((a) => tweakArmorName(a).toUpperCase().trim());

        if (theClass.armor === "All armor and shields") {
            arms = allArmors;
        } else {
            arms = arms.filter((arm) => allowedArmorNames.indexOf(arm.name.toUpperCase()) !== -1);
        }

        // add all shields if includes "shields"
        if (allowedArmorNames.indexOf("SHIELDS") !== -1) {
            let allShields = allArmors.filter((a) => a.isShield);
            // Ovates can only use wooden shields, so exclude mithral
            if (theClass.name === "Ovate") {
                allShields = allShields.filter((s) => s.name.indexOf("Mithral") === -1);
            }
            arms = [...arms, ...allShields];
        }

        // add any additional armors learned via bonuses
        const learnArmorBonuses = bonuses.filter((b) => b.bonusName === "LearnArmor");
        learnArmorBonuses.forEach((b) => {
            const newArmor = allArmors.find((a) => a.name.toUpperCase() === b.bonusTo.toUpperCase());
            if (newArmor) {
                const alreadyKnownArmor = arms.find((a) => a.name.toUpperCase() === newArmor.name.toUpperCase());
                if (alreadyKnownArmor === undefined) {
                    arms.push(newArmor)
                    // add mithral variant as well
                    const mithralVersion = allArmors.find((a) => a.name.toUpperCase() === "MITHRAL " + newArmor.name.toUpperCase());
                    if (mithralVersion) {
                        arms.push(mithralVersion);
                    }
                }
            }
        })
    }

    return arms;
}

export const getSpellTierForClass = (className: string, spell: string): number => {

    // treat a Warlock as a Wizard
    let effectiveClassName = className;
    if (className === "Warlock") { effectiveClassName = "Wizard"; }
    const allSpells = spells;
    const theSpell = allSpells.find((s) => s.name === spell);
    if (theSpell) {
        const theClass = getAllCharClasses().find((c) => c.name === effectiveClassName);
        if (theClass) {
            // special case for Roustabout, default to the level for the 1st class that can cast this spell
            if (theClass.name === "Roustabout") {
                return theSpell.tierByClass[0];
            }
            // non-Roustabouts
            const spellClassName = getClassNameForSpellCastingClassNum(theClass.spellCastingClassNum, theSpell);
            const classIndex = theSpell.classes.map((c) => c.toUpperCase()).indexOf(spellClassName.toUpperCase());
            if (classIndex !== -1) {
                if (theSpell.tierByClass[classIndex]) {
                    return theSpell.tierByClass[classIndex];
                }
            }
        } else {
            console.log("Class " + className + " not found");
        }
    } else {
        console.log(spell + " not found");
    }
    return 0;
}

export const getBonusByName = (bonuses: Bonus[], name: string, sourceType: string, sourceName: string, sourceCategory: string, gainedAtLevel: number, boonPatron?: string, boonSource?: string) => {
    let theBonus: Bonus | undefined;
    if (sourceCategory === "Boon") {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.boonPatron === boonPatron && b.boonSource === boonSource && b.gainedAtLevel === gainedAtLevel && b.name === name);
    } else {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.gainedAtLevel === gainedAtLevel && b.name === name);
    }
    return theBonus;
}

export const getBonusByBonusName = (bonuses: Bonus[], bonusName: string, sourceType: string, sourceName: string, sourceCategory: string, gainedAtLevel: number, boonPatron?: string, boonSource?: string) => {
    let theBonus: Bonus | undefined;
    if (sourceCategory === "Boon") {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.boonPatron === boonPatron && b.boonSource === boonSource && b.gainedAtLevel === gainedAtLevel && b.bonusName === bonusName);
    } else {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.gainedAtLevel === gainedAtLevel && b.bonusName === bonusName);
    }
    return theBonus;
}

export const getBonusByNameAndBonusName = (bonuses: Bonus[], name: string, bonusName: string, sourceType: string, sourceName: string, sourceCategory: string, gainedAtLevel: number, boonPatron?: string, boonSource?: string) => {
    let theBonus: Bonus | undefined;
    if (sourceCategory === "Boon") {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.boonPatron === boonPatron && b.boonSource === boonSource && b.gainedAtLevel === gainedAtLevel && b.bonusName === bonusName && b.name === name);
    } else {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.gainedAtLevel === gainedAtLevel && b.bonusName === bonusName && b.name === name);
    }
    return theBonus;
}


export const getBonusByNameAndBonusTo = (bonuses: Bonus[], name: string, bonusTo: string, sourceType: string, sourceName: string, sourceCategory: string, gainedAtLevel: number, boonPatron?: string, boonSource?: string, parentBonusId?: string) => {
    let theBonus: Bonus | undefined;
    if (parentBonusId !== undefined) {
        theBonus = bonuses.find((b) => b.parentBonusId === parentBonusId);
    }
    if (sourceCategory === "Boon") {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.boonPatron === boonPatron && b.boonSource === boonSource && b.gainedAtLevel === gainedAtLevel && b.name === name && b.bonusTo === bonusTo);
    } else {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.gainedAtLevel === gainedAtLevel && b.name === name && b.bonusTo === bonusTo);
    }
    return theBonus;
}

export const getBonusByNameAndBonusNameAndBonusTo = (bonuses: Bonus[], name: string, bonusName: string, bonusTo: string, sourceType: string, sourceName: string, sourceCategory: string, gainedAtLevel: number, boonPatron?: string, boonSource?: string) => {
    let theBonus: Bonus | undefined;
    if (sourceCategory === "Boon") {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.boonPatron === boonPatron && b.boonSource === boonSource && b.gainedAtLevel === gainedAtLevel && b.bonusName === bonusName && b.name === name && b.bonusTo === bonusTo);
    } else {
        theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.gainedAtLevel === gainedAtLevel && b.bonusName === bonusName && b.name === name && b.bonusTo === bonusTo);
    }
    return theBonus;
}

export const getWarlockTalentRandomBoonBonus = (bonuses: Bonus[], sourceType: string, sourceName: string, sourceCategory: string, gainedAtLevel: number) => {
    let theBonus: Bonus | undefined;
    theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.boonSource === "WarlockTalentRandomBoon" && b.gainedAtLevel === gainedAtLevel);
    return theBonus;
}

export const getWarlockTalentRollTwoBoonsAndPickOneBoonBonus = (bonuses: Bonus[], sourceType: string, sourceName: string, sourceCategory: string, gainedAtLevel: number) => {
    let theBonus: Bonus | undefined;
    theBonus = bonuses.find((b) => b.sourceType === sourceType && b.sourceName === sourceName && b.sourceCategory === sourceCategory && b.boonSource === "WarlockTalentRollTwoBoonsAndKeepOne" && b.gainedAtLevel === gainedAtLevel);
    return theBonus;
}

export const addNone = (text: string) => {
    if (text === "") { return "None" }
    return text;
}

export const getRoustaboutSpellList = (availableClasses: CharClass[], charLevel: number) => {
    // console.clear();
    // We always want to include the Wizard and Priest spells, plus Witch and Seer spells if CS1/CS3 are enabled.
    let effectiveClasses = [...availableClasses];
    if (!effectiveClasses.find((c) => c.name === "Priest")) {
        const priest = getAllCharClasses().find((c) => c.name === "Priest");
        if (priest) { effectiveClasses.push(priest); }
    }
    if (!effectiveClasses.find((c) => c.name === "Wizard")) {
        const wizard = getAllCharClasses().find((c) => c.name === "Wizard");
        if (wizard) { effectiveClasses.push(wizard); }
    }

    let availSpells = spells.filter((s) => {
        const spellClass = effectiveClasses.find((cl) => cl.name === s.classes[0]);
        if (spellClass) {
            const spellSource = sources.find((src) => src.id === spellClass.sourceId);
            if (spellSource) { return spellSource.isOfficial; }
            return false;
        } else {
            return false;
        }
    })
    const maxTier = Math.max(Math.trunc(charLevel / 2), 1);
    availSpells = availSpells.filter((s) => s.tierByClass[0] <= maxTier);
    availSpells.sort((s1, s2) => s1.name < s2.name ? -1 : 1);
    return availSpells;
}

export const animalTypes = ["Wolf", "Bear", "Snake", "Eagle"];
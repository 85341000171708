import { getAllAncestries } from '../../../data/ancestries';
import { getAllCharClasses } from '../../../data/classes';
import { getRandomAncestry, getRandomStats } from '../../charGen/utilities/roll';
import { getClassBasedOnStats } from '../../../special/randomChar';
import { sources } from '../../../data/sources';
import { getActiveSourcesFilteredAncestriesFromLocalStorage, getActiveSourcesFilteredClassesFromLocalStorage, getActiveSourcesFromLocalStorage } from '../../localStorage/localStorage';


interface IProps {
}

const AdminTestRandom: React.FunctionComponent<IProps> = (props: IProps) => {

    const totalChars = 100000;
    const randType = "Best Fit";
    // const randType = "Mixed";
    // const randType = "Random";

    const sourcesFilter = getActiveSourcesFromLocalStorage();
    const ancestryFilter = getActiveSourcesFilteredAncestriesFromLocalStorage();
    const classFilter = getActiveSourcesFilteredClassesFromLocalStorage();

    const activeSources = sources.filter((s) => sourcesFilter.indexOf(s.id) !== -1).map((s) => s.id);
    const allClasses = getAllCharClasses().filter((c) => classFilter.filter((cf) => cf.isActive).map((cf) => cf.charClass).indexOf(c.name) !== -1).filter((c) => c.name !== "Level 0");
    const allAncestries = getAllAncestries().filter((a) => ancestryFilter.filter((af) => af.isActive).map((af) => af.ancestry).indexOf(a.name) !== -1);

    // let activeSources = ["SD", "US"]; sources.map((s) => s.name);
    // let allClasses = getAllCharClasses().filter((c) => activeSources.indexOf(c.sourceId) !== -1).filter((c) => c.name !== "Level 0");
    // const allAncestries = getAllAncestries().filter((a) => activeSources.indexOf(a.sourceId) !== -1);

    interface RandomChar {
        ancestry: string;
        class: string;
    }

    const getRandomCharacters = () => {

        let randChars: RandomChar[] = [];

        console.clear();
        for (let c = 0; c < totalChars; c++) {

            let theClass = "";
            do {
                const theStats = getRandomStats(true);
                const roustaboutIsEnabled = allClasses.find((c) => c.name === "Roustabout") !== undefined;
                theClass = getClassBasedOnStats(theStats, randType, allClasses, activeSources, roustaboutIsEnabled);
            } while (theClass === "")

            const theAncestry = getRandomAncestry(allAncestries, allClasses, theClass);

            const newChar: RandomChar = { ancestry: theAncestry, class: theClass };
            randChars.push(newChar);
        }

        return randChars;
    }

    const getClasses = () => {
        let out: any[] = [];
        allClasses.forEach((cl) => {

            let classWeightTotal = 0;
            cl.classStatWeights.forEach((csw) => classWeightTotal = classWeightTotal + csw.weight);

            // let ancestryChanceTotal = 0;
            // cl.ancestryChances.forEach((ac) => { if (ac.ancestry === "Human") { ancestryChanceTotal = ancestryChanceTotal + ac.chance } });

            out.push(<th>{cl.name}<br/>{classWeightTotal}</th>)
        })
        return out;
    }

    const generateRandomChars = () => {

        const randChars = getRandomCharacters();

        const getAncestryClassCount = (ancestry: string) => {
            let out: any[] = [];
            let tot = 0;
            allClasses.forEach((c) => {
                const ancestryClassCount = randChars.filter((rc) => rc.ancestry === ancestry && rc.class === c.name).length;
                out.push(<td>{ancestryClassCount.toLocaleString('en', { useGrouping: true })}<br />{(ancestryClassCount / totalChars * 100).toFixed(1)}%</td>);
                tot = tot + ancestryClassCount;
            })

            out.push(<td>{tot.toLocaleString('en', { useGrouping: true })}<br />{(tot / totalChars * 100).toFixed(1)}%</td>)
            return out;

        }

        const getClassAncestryCount = (randChars: RandomChar[]) => {
            let out: any[] = [];
            let tot = 0;
            allClasses.forEach((cl) => {
                const classAncestryCount = randChars.filter((rc) => rc.class === cl.name).length;
                out.push(<td>{classAncestryCount.toLocaleString('en', { useGrouping: true })}<br />{(classAncestryCount / totalChars * 100).toFixed(1)}%</td>)
                tot = tot + classAncestryCount;
            })
            out.push(<td>{tot.toLocaleString('en', { useGrouping: true })}<br />{(tot / totalChars * 100).toFixed(1)}%</td>);
            return out;
        }

        let out: any[] = [];

        out.push(<tr><th>Ancestry</th>{getClasses()}<th>total</th></tr>);
        allAncestries.forEach((anc) => {
            out.push(<tr><th>{anc.name}</th>{getAncestryClassCount(anc.name)}</tr>)
        })
        out.push(<tr><th>total</th>{getClassAncestryCount(randChars)}</tr>)

        return <table className="randTable"><tbody>{out}</tbody></table>
    }

    return (
        <div>
            <h1>Admin: Test Random</h1>

            <h3>Random Type: {randType}, {totalChars.toLocaleString('en', { useGrouping: true })} random characters:</h3>

            <p><b>Active Sources:</b> {sources.filter((s) => sourcesFilter.indexOf(s.id) !== -1).map((s) => s.name).join("; ")}</p>

            <p>Uses the Ancestries and Classes Filter settings on the Character Creation screen</p>


            {/* <p>Sources Filter: {JSON.stringify(sourcesFilter, null, 2)}</p>
            <p>Sources: {JSON.stringify(sources, null, 2)}</p> */}

            {generateRandomChars()}

            {/* <p><pre>{JSON.stringify(ancestryFilter, null, 2)}</pre></p>
            <p><pre>{JSON.stringify(classFilter, null, 2)}</pre></p> */}


            {/* {JSON.stringify(getRandomCharacters(), null, 2)} */}

        </div>
    )
}

export default AdminTestRandom;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faDollarSign } from '@fortawesome/free-solid-svg-icons';

import Modal from "react-responsive-modal";
import { GearOwned } from "../../data/gear";
import { Sundry, sundries } from "../../data/sundries";
import DisplayGold from "../charGen/displayGold";

interface IProps {
    openModal: boolean;
    gearOwned: GearOwned[];
    gearSlotsUsed: number;
    gearSlotsTotal: number;
    onBuyItem: (id: string, type: string, isBuy: boolean, cost: number, currency: string) => void;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}

const BuySundries: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getSundryRows2 = () => {

        const rows: any = [];
        sundries.forEach((s) => {

            const freeToCarry = s.freeToCarry === 0 ? "" : " (first " + s.freeToCarry + " free to carry)";
            const quant = s.quantityPerSlot === 1 ? "" : " [" + s.quantityPerSlot + "]";
            const quantPerSlot = s.quantityPerSlot === 1 ? s.quantityPerSlot.toString() : "1-" + s.quantityPerSlot;

            const owned = props.gearOwned.filter((g) => g.id === s.id).length;
            const ownedStr = owned > 0 ? " (" + owned + ")" : "";
            const totalGold = globalContext.gold + (globalContext.silver / 10) + (globalContext.copper / 100);

            const getCost = (s: Sundry) => {
                if (s.currency === "gp") { return s.cost; }
                if (s.currency === "sp") { return s.cost / 10; }
                if (s.currency === "cp") { return s.cost / 100; }
                return 0;
            }

            const canBuy = getCost(s) <= totalGold && (props.gearSlotsUsed + s.slots) <= props.gearSlotsTotal;
            const canSell = owned > 0;

            rows.push(
                <div className="row buyRow" key={s.name}>
                    <div className="col-8 col-sm-4"><b>{s.name}{quant} {ownedStr}</b></div>
                    <div className="col-4 col-sm-2 text-end">{s.cost}&nbsp;{s.currency}</div>
                    <div className="col-8 col-sm-4">{quantPerSlot}{quantPerSlot !== "1" ? " per " : ""} slot {freeToCarry}</div>
                    <div className="col-4 col-sm-2 text-end">
                        <button className="btn btn-secondary btn-sm btnBuy me-1" onClick={() => props.onBuyItem(s.id, "sundry", true, s.cost, s.currency)} disabled={!canBuy} title="Buy"><FontAwesomeIcon icon={faCartShopping} /></button>
                        <button className="btn btn-secondary btn-sm btnBuy" onClick={() => props.onBuyItem(s.id, "sundry", false, s.cost, s.currency)} disabled={!canSell} title="Sell"><FontAwesomeIcon icon={faDollarSign} /></button>
                    </div>
                </div>
            );
        })

        if (rows.length === 0) { rows.push(<tr key={"not permitted"}><td colSpan={7}>None permitted</td></tr>) }

        return rows;
    }

    const onCloseModal = () => {
        props.onCloseModal("sundries");
    }

    return (
        <>
            <button onClick={() => props.onOpenModal("sundries")} className="btn btn-dark btn-sm me-1">Basic Gear</button>
            {props.openModal &&
                <Modal open={props.openModal} onClose={() => onCloseModal()} classNames={{ modal: 'customModal' }}>

                    <div>

                        <div><b>Coin:</b> <DisplayGold gold={globalContext.gold} silver={globalContext.silver} copper={globalContext.copper} /></div>
                        <div><b>Gear Slots:</b> {props.gearSlotsUsed} of {props.gearSlotsTotal}</div>

                        <div className="container text-left">
                            {getSundryRows2()}
                        </div>

                    </div >

                </Modal>
            }
        </>
    )

}

export default BuySundries;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";
import { getBonusByName } from "../../data/utilities";


interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    size: number;
    setBonus: (bonus: Bonus) => void;
}

const SetWeaponTypeDamage: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    let allowedWeapons: string[] = [];
    if (globalContext.charClass) {
        allowedWeapons = globalContext.charClass.weapons.split(",").map((w) => w.trim().toUpperCase());
    };
    const classWeapons = globalContext.availableWeapons.filter((w) => allowedWeapons.indexOf(w.name.toUpperCase()) !== -1);

    const options = classWeapons.map((w) => <option value={w.name + ":" + props.size} key={w.name}>{w.name}</option>);
    options.unshift(<option value="" key="selectSetDamage">-- select weapon --</option>);

    const getCurrentValue = () => {
        let val = "";
        // const theBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.gainedAtLevel === props.level.level && b.name === "SetWeaponTypeDamage");
        const theBonus = getBonusByName(globalContext.bonuses, "SetWeaponTypeDamage", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
        if (theBonus) {
            val = theBonus.bonusTo;
        }
        return val;
    }

    const setWeaponMasteryBonus = (bonusTo: string) => {
        const theBonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "SetWeaponTypeDamage",
            bonusName: "SetWeaponTypeDamage",
            bonusTo: bonusTo
        }
        props.setBonus(theBonus);
    }

    const selectCss = getCurrentValue() === "" ? "form-select redBorder" : "form-select";

    return (
        <div className="indented">
            <div className="formNote">Select weapon type to do 1d{props.size} damage</div>
            <select className={selectCss} onChange={(e) => setWeaponMasteryBonus(e.target.value)} value={getCurrentValue()}>{options}</select>
        </div>
    )

}

export default SetWeaponTypeDamage;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import DisplayGold from "../charGen/displayGold";

interface IProps {
    goldRolled: number;
    isLevelZero: boolean;
    isCheatMode: boolean;
    rollGold: () => void;
    setGold: (gold: number) => void;
    rollLevelOneItems: () => void;
}

const RollGold: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getOptions = () => {
        let out: any[] = [];

        out.push(<option value={-1} key={"gp0"}>-- select gold --</option>)

        for (let gp = 10; gp <= 60; gp = gp + 5) {
            out.push(<option value={gp} key={"gp" + gp}>{gp} gp</option>)
        }

        return out;
    }

    const onSetGP = (e: any) => {
        const gp = parseInt(e.target.value);
        props.setGold(gp)
    }

    return (
        <div>

            {props.isLevelZero && props.goldRolled === -1 &&

                <div>
                    <div>
                        <button className="btn btn-dark" onClick={() => props.rollLevelOneItems()}>Roll 1d4 random items</button>
                        <div className="valError">Please roll for items</div>
                    </div>
                    <h3>OR</h3>
                </div>
            }

            {!props.isCheatMode && props.goldRolled === -1 &&
                <div>
                    <button className="btn btn-dark" onClick={() => props.rollGold()}>Roll 2d6 x 5 for gold pieces</button>
                    <div className="valError">Please roll for gold</div>
                </div>
            }

            {props.isCheatMode && props.goldRolled === -1 &&
                <div>
                    <div className="input-group">
                        <select className="hpDDL form-select" onChange={(e) => onSetGP(e)} value={props.goldRolled}>{getOptions()}</select>
                    </div>
                    {globalContext.gold === -1 &&
                        <div className="valError">Please select gold rolled (2d6 x 5)</div>
                    }
                </div>
            }

            {props.goldRolled !== -1 &&
                <>
                    <div><b>Gold rolled:</b> {props.goldRolled} gp</div>
                    <div><b>Current wealth:</b> <DisplayGold gold={globalContext.gold} silver={globalContext.silver} copper={globalContext.copper} /></div>
                </>
            }

        </div >
    )

}

export default RollGold;
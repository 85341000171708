import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import { Level } from "../../data/level";

import StatBonus from "./StatBonus";
import { getBonusByName } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    options?: string[];
    size?: number;
    setBonus: (bonus: Bonus) => void;
    mustbeDifferentStats: boolean;
}

const StatBonusDouble: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const onSetBonus1 = (bonus: Bonus) => {
        bonus.name = "StatBonus-1";
        props.setBonus(bonus);
    }

    const onSetBonus2 = (bonus: Bonus) => {
        bonus.name = "StatBonus-2";
        props.setBonus(bonus);
    }

    let statsMustBeDifferent = false;
    if (props.mustbeDifferentStats === true) {
        const statBonus1 = getBonusByName(globalContext.bonuses, "StatBonus-1", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
        const statBonus2 = getBonusByName(globalContext.bonuses, "StatBonus-2", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
        if (statBonus1 !== undefined && statBonus2 !== undefined) {
            if (statBonus1.bonusTo === statBonus2.bonusTo) {
                statsMustBeDifferent = true;
            }
        }
    }

    return (
        <div>

            <StatBonus
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                level={props.level}
                inputName={"stat1"}
                options={props.options}
                size={props.size}
                setBonus={(bonus: Bonus) => onSetBonus1(bonus)}
            />
            <StatBonus
                sourceType={props.sourceType}
                sourceName={props.sourceName}
                sourceCategory={props.sourceCategory}
                boonPatron={props.boonPatron}
                boonSource={props.boonSource}
                level={props.level}
                inputName={"stat2"}
                options={props.options}
                size={props.size}
                setBonus={(bonus: Bonus) => onSetBonus2(bonus)}
            />

            {statsMustBeDifferent &&
                <div className="valError">Please select two different stats</div>
            }
        </div>
    )

}

export default StatBonusDouble;
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import app from '../../../firebase/firebase';
import { DocumentData, Query, collection, getCountFromServer, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';

import Spinner from '../../charGen/spinner';
import { Alert } from 'react-bootstrap';

interface IProps {
}

const AdminAllCharacters: React.FunctionComponent<IProps> = (props: IProps) => {

    const [characters, setCharacters] = useState<Array<any>>([]);
    const [name, setName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [numCharacters, setNumCharacters] = useState(0);
    const [error, setError] = useState("");
    const [orderedBy, setOrderedBy] = useState("name_lower");
    const [charactersLoaded, setCharactersLoaded] = useState(false);

    useEffect(() => {

        try {
            // load number of saved characters
            const asyncLoadCharacterCount = async () => {
                const count = await loadNumCharacters();
                setNumCharacters(count);
            }
            asyncLoadCharacterCount();

        } catch (e: any) {
            setError(e.message)
        }

    }, [])

    const showCharacters = (theName: string) => {
        if (theName === "") { setName("") }
        getCharacters("name_lower", theName);
    }


    const loadNumCharacters = async () => {
        try {
            const db = getFirestore(app);
            const coll = collection(db, "characterBasic");
            const q = query(coll);
            const snapshot = await getCountFromServer(q);
            return snapshot.data().count;
        } catch (e: any) {
            setError(e.message);
        }

        return 0;
    }

    const getCharacters = async (orderByField: string, name: string) => {
        setOrderedBy(orderByField);
        try {

            const db = getFirestore(app);
            let q: Query<DocumentData> | null;
            const charRef = collection(db, "characterBasic");

            if (name.trim() !== "") {
                if (orderByField === "user_email") {
                    q = query(charRef, where("name_lower", "==", name.toLowerCase()), orderBy("user_email", "desc"), orderBy("name_lower", "desc"));
                } else {
                    q = query(collection(db, "characterBasic"), where("name_lower", "==", name.toLowerCase()), orderBy(orderByField));
                }
            } else {
                if (orderByField === "user_email") {
                    q = query(charRef, orderBy("user_email", "desc"), orderBy("name_lower", "desc"));
                } else {
                    q = query(collection(db, "characterBasic"), orderBy(orderByField));
                }
            }

            if (q) {
                setIsLoading(true);
                const querySnapshot = await getDocs(q);

                const characters: any[] = [];
                querySnapshot.forEach((doc) => {
                    characters.push({ ...doc.data(), id: doc.id });
                });
                setCharacters(characters);
                setCharactersLoaded(true);
            }
            setIsLoading(false);

        }
        catch (error: any) {
            setIsLoading(false);
        }
    }

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const classAndLevel = (className: string, level: number) => {
        if (className === "Level 0") { return className; }
        if (className.trim() === "") { className = "No Class"; }
        return className + " " + level;
    }

    const getAncestry = (ancestry: string) => {
        if (ancestry.trim() === "") { return "No Ancestry"; }
        return ancestry;
    }

    const getBackground = (background: string) => {
        if (background.trim() === "") { return "No Background"; }
        return background;
    }

    const getCoreRulesOnly = (c: any) => {
        if (typeof c == "boolean") {
            if (c) { return "Core rules only"; }
            return "Core rules + 3pp";
        }
        return "Unknown";
    }

    const getWasEdited = (c: any) => {
        if (typeof c == "boolean") {
            if (c) { return "Yes"; }
            return "No";
        }
        return "No";
    }

    const convertTimeStampToDateString = (ts: any) => {

        const pad = (t: number) => {
            if (t < 10) { return "0" + t; }
            return t.toString();
        }

        if (ts) {
            const d = new Date(ts.seconds * 1000);
            return pad((d.getMonth() + 1)) + "/" + pad(d.getDate()) + "/" + d.getFullYear() + " " + pad(d.getHours()) + ":" + pad(d.getMinutes());
        }
        return "-";
    }

    const getCharacterRows = () => {
        const rows: any = [];
        characters.forEach((c) => {
            rows.push(
                <tr key={c.id}>
                    <td className="nameCol"><Link to={"/create/" + c.id} className="clippedName">{isBlank(c.name, "(No name)")}</Link></td>
                    <td>{classAndLevel(c.className, c.level)}</td>
                    <td>{getAncestry(c.ancestry)}</td>
                    <td>{getBackground(c.background)}</td>
                    <td>{c.user_email}</td>
                    <td>{c.creationMethod}</td>
                    <td>{getCoreRulesOnly(c.coreRulesOnly)}</td>
                    <td>{getWasEdited(c.wasEdited)}</td>
                    <td>{convertTimeStampToDateString(c.dateCreated)}</td>
                    <td>{convertTimeStampToDateString(c.dateLastModified)}</td>
                </tr>
            )
        })

        return rows;
    }

    return (
        <div>
            <h1>Admin: All Characters</h1>

            {error !== "" &&
                <Alert key="warning" variant="warning">Error: {error}</Alert>
            }

            <div className="mb-2">{numCharacters} characters</div>

            <div className="mb-2">WARNING: Clicking the 'Show all characters' button below queries the entire database. Please click the button as seldom as possible.</div>

            <div><button className="btn btn-dark" onClick={() => showCharacters("")}>Show all characters</button></div>

            <div className="input-group">
                <input id="characterName" type="text" onChange={(e) => setName(e.currentTarget.value)} value={name} placeholder='Character Name'></input>
                <button className="btn btn-dark" onClick={() => showCharacters(name)} title="Show By Name" disabled={name.length === 0}>Show By Name</button>
            </div>


            {charactersLoaded &&
                <div>

                    {characters.length > 0 &&
                        <div>
                            <table className="table table-hover w-auto characterTable small">
                                <thead>
                                    <tr className="table-dark">
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("name_lower", name)} className="whiteText">Name</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("className", name)} className="whiteText">Class</a> & <a href="#!" onClick={() => getCharacters("level", name)} className="whiteText">Level</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("ancestry", name)} className="whiteText">Ancestry</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("background", name)} className="whiteText">Background</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("user_email", name)} className="whiteText">User</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("creationMethod", name)} className="whiteText">Creation</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("coreRulesOnly", name)} className="whiteText">Rules Set</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("wasEdited", name)} className="whiteText">Edited</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("dateCreated", name)} className="whiteText">Created</a></th>
                                        <th scope="col"><a href="#!" onClick={() => getCharacters("dateLastModified", name)} className="whiteText">Last Modified</a></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getCharacterRows()}
                                </tbody>
                            </table>
                            <div>Ordered by {orderedBy}</div>
                        </div>
                    }

                    {characters.length === 0 &&
                        <div>There are currently no characters.</div>
                    }

                </div>
            }


            <div className="mb-3">
                <Spinner isLoading={isLoading} />
            </div>

            {/* <pre>{JSON.stringify(characters, null, 2)};</pre> */}



        </div >
    )
}

export default AdminAllCharacters;
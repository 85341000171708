import 'react-responsive-modal/styles.css';

import { Modal } from 'react-responsive-modal';
import { useContext } from "react";
import { GlobalContextType } from "../context";
import { CreateCharacterContext } from "../createCharacter";
import { activeSourceFilteredAncestry, activeSourceFilteredClass, sources } from '../../data/sources';
import { Ancestry, getAllAncestries } from '../../data/ancestries';
import { CharClass, getAllCharClasses } from '../../data/classes';

interface IProps {
    ancestryFilter: activeSourceFilteredAncestry[];
    classFilter: activeSourceFilteredClass[];
    sourcesOrFiltersChanged: boolean;
    currentAncestry: string;
    currentClass: string;
    openModal: boolean;
    onOpenModal: (modal: string) => void;
    onCloseModal: (modal: string) => void;
    setAncestryFilter: (sourceId: string, ancestry: string, isActive: boolean) => void;
    setClassFilter: (sourceId: string, charClass: string, isActive: boolean) => void;
    checkAll: (checkAll: boolean) => void;
    saveSourcesAndFilters: () => void;
}

const FilterAncestriesAndClassesModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const activeSources = globalContext.activeSources;

    const ancestries = getAllAncestries();
    const classes = getAllCharClasses();

    const getOptions = () => {
        let out: any = [];


        const getAncestries = (srcAncestries: Ancestry[]) => {

            let ancFields: any[] = [];

            srcAncestries.forEach((sa, index) => {
                const isActive = props.ancestryFilter.find((a) => a.ancestry === sa.name && a.sourceId === sa.sourceId);
                ancFields.push(
                    <div className="col-6 col-sm-3" key={sa.name}>
                        <div className="form-check ms-2">
                            <input className="form-check-input" type="checkbox" value={sa.name} id={"anc_" + sa.name + "_" + index} checked={isActive?.isActive} disabled={sa.name === props.currentAncestry} onChange={(e) => props.setAncestryFilter(sa.sourceId, sa.name, !isActive)} />
                            <label className="form-check-label" htmlFor={"anc_" + sa.name + "_" + index} >
                                {sa.name}
                            </label>
                        </div>
                    </div>
                )
            });

            return (
                <div className="container">
                    <div className="row">
                        {ancFields}
                    </div>
                </div>
            )
        }

        const getClasses = (srcClasses: CharClass[]) => {

            let classFields: any[] = [];

            srcClasses.forEach((sc, index) => {
                const isActive = props.classFilter.find((c) => c.charClass === sc.name && c.sourceId === sc.sourceId);
                classFields.push(
                    <div className="col-6 col-sm-3" key={sc.name}>
                        <div className="form-check ms-2">
                            <input className="form-check-input" type="checkbox" value="" id={"class_" + sc.name + "_" + index} checked={isActive?.isActive} disabled={sc.name === props.currentClass} onChange={(e) => props.setClassFilter(sc.sourceId, sc.name, !isActive)} />
                            <label className="form-check-label" htmlFor={"class_" + sc.name + "_" + index}>
                                {sc.name}
                            </label>
                        </div>
                    </div>
                )
            });

            return (
                <div className="container">
                    <div className="row">
                        {classFields}
                    </div>
                </div>
            )
        }

        sources.forEach((src) => {
            if (activeSources.indexOf(src.id) !== -1) {
                out.push(<h2 className="mt-3" style={{ borderBottom: "1px solid black" }}>{src.name}</h2>);
                src.links.forEach((s: any, index) => {
                    out.push(<span key={"srcLink_" + src.id + "_" + index} className="me-3"><a href={s.href} target="_blank" rel="noreferrer">{s.desc}</a></span>)
                })
                const srcAncestries = ancestries.filter((a) => a.sourceId === src.id);
                if (srcAncestries.length > 0) {
                    out.push(<h3 className="mt-3">Ancestries</h3>)
                    // out.push(
                    //     <div className="form-check form-switch">
                    //         <input className="form-check-input" type="checkbox" id={"anc_" + src.id + "_all"} />
                    //         <label className="form-check-label" htmlFor={"anc_" + src.id + "_all"}>Include All</label>
                    //     </div>
                    // )
                    out.push(getAncestries(srcAncestries))

                }

                const srcClasses = classes.filter((c) => c.sourceId === src.id && c.name !== "Level 0");
                if (srcClasses.length > 0) {
                    out.push(<h3 className="mt-3">Classes</h3>);
                    // out.push(
                    //     <div className="form-check form-switch">
                    //         <input className="form-check-input" type="checkbox" id={"class_" + src.id + "_all"} />
                    //         <label className="form-check-label" htmlFor={"class_" + src.id + "_all"}>Include All</label>
                    //     </div>
                    // )
                    out.push(getClasses(srcClasses))
                }
            }
        })


        return out;

    }

    const checkAll = () => {
        props.checkAll(true);
    }

    const uncheckAll = () => {
        props.checkAll(false);
    }

    const saveSettingsAndClose = () => {
        props.saveSourcesAndFilters();
        props.onCloseModal("filterAncestriesAndClasses");
    }


    let availAncestries = props.ancestryFilter.filter((a) => a.isActive).map((a) => a.ancestry).sort().join(", ");
    if (availAncestries === "") { availAncestries = "None" }
    let availClasses = props.classFilter.filter((c) => c.isActive).map((c) => c.charClass).sort().join(", ");
    if (availClasses === "") { availClasses = "None" }
    const saveSettingsBtnText = props.sourcesOrFiltersChanged ? "Save Settings as Defaults" : "Settings are Saved";

    return (
        <>
            <button className="btn btn-dark btn-sm mb-2" onClick={() => props.onOpenModal("filterAncestriesAndClasses")}>Filter Ancestries & Classes</button>
            <div className="small">
                <ul>
                    <li><b>Active Ancestries:</b> {availAncestries}</li>
                    <li><b>Active Classes:</b> {availClasses}</li>
                </ul>
            </div>

            <button className="btn btn-dark btn-sm mb-2" onClick={() => props.saveSourcesAndFilters()} disabled={!props.sourcesOrFiltersChanged}>{saveSettingsBtnText}</button>

            {props.openModal &&
                <Modal open={props.openModal} onClose={() => props.onCloseModal("filterAncestriesAndClasses")} classNames={{ modal: 'customModal' }}>
                    <h2>Ancestries & Classes</h2>

                    <div>Select the ancestries and classes from your active Sources that you want to be included when designing or randomly generating characters.</div>

                    <div>
                        <button className="btn btn-dark btn-sm me-2" onClick={(e) => checkAll()}>Check All</button>
                        <button className="btn btn-dark btn-sm me-2" onClick={(e) => uncheckAll()}>Uncheck All</button>
                        <button className="btn btn-dark btn-sm" onClick={() => saveSettingsAndClose()} disabled={!props.sourcesOrFiltersChanged}>{saveSettingsBtnText}</button>
                    </div>

                    {getOptions()}

                    {/* <h2>Filtered ancestries</h2>
                    <div className="mb-2">{props.ancestryFilter.filter((a) => a.isActive).map((a) => a.ancestry).join(", ")}</div>

                    <h2>All Filtered classes</h2>
                    <div className="mb-2">{JSON.stringify(props.classFilter, null, 2)}</div>

                    <h2>Filtered classes</h2>
                    <div>{props.classFilter.filter((a) => a.isActive).map((a) => a.charClass).join(", ")}</div> */}


                </Modal>
            }
        </>
    )

}

export default FilterAncestriesAndClassesModal;
import ReactGA from 'react-ga4';

import "./../layoutV2.css";
import "./../fonts/JBLACK.TTF";

// Bootstrap dependencies
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap";

import { Outlet } from "react-router-dom";
import { User } from 'firebase/auth';
import Menu from '../components/menu/menu';
import Footer from '../components/pages/footer';

interface IProps {
    user: User | null | undefined;
    isAdmin: boolean;
    layout: string;
}

const Root: React.FunctionComponent<IProps> = (props: IProps) => {

    // Google analytics tag
    ReactGA.initialize('G-DHJ53WL9WV');

    const getEnvTitle = () => {
        let envTitle = process.env.REACT_APP_ENV;
        if (envTitle && envTitle) {
            if (envTitle !== "PRODUCTION") {
                return <span className='red'> {envTitle.toLowerCase()}</span>;
            }
        }
        return null;
    }

    // if (props.layout === "ORIGINAL") {
    //     return (
    //         <div className="App container">

    //             <link rel="preconnect" href="https://fonts.googleapis.com" />
    //             <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
    //             <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,100&family=Tillana&display=swap" rel="stylesheet" />
    //             <div className="main">

    //                 <div>
    //                     <img src="/ShadowDarklingLogoSmall_Trans.png" className="logo" alt="Garlikk the Goblin" title="Garlikk the Goblin"></img>
    //                     <h1 className="mainHeader">Shadow<span className="bloodred">Darklings</span> {getEnvTitle()}</h1>
    //                 </div>

    //                 <Menu user={props.user} isAdmin={props.isAdmin} />

    //                 <Outlet />

    //             </div>

    //             <Footer />

    //         </div>
    //     );
    // }

    // if (props.layout === "VERSION 2") {
    return (
        <div className="App container LayoutV2">

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,100&family=Tillana&display=swap" rel="stylesheet" />

            <header className="border-bottom p-0 m-0">
                <div className="row flex-nowrap justify-content-between align-items-center">
                    {/* <div className="col-4 pt-1">
                            <a className="link-secondary" href="#">Subscribe</a>
                        </div> */}
                    <div className="col-12 text-center">
                        {/* <img src="/ShadowDarklingLogoSmall_Trans.png" className="logo" alt="Garlikk the Goblin" title="Garlikk the Goblin"></img> */}
                        <h1 className="mainHeaderV2">ShadowDarklings{getEnvTitle()}</h1>
                    </div>
                    {/* <div className="col-4 d-flex justify-content-end align-items-center">
                            <a className="link-secondary" href="#" aria-label="Search">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="mx-3" role="img" viewBox="0 0 24 24"><title>Search</title><circle cx="10.5" cy="10.5" r="7.5"></circle><path d="M21 21l-5.2-5.2"></path></svg>
                            </a>
                            <a className="btn btn-sm btn-outline-secondary" href="#">Sign up</a>
                        </div> */}
                </div>
            </header>

            <Menu user={props.user} isAdmin={props.isAdmin} />

            <Outlet />

            <Footer />

        </div>
    );
    // }

    // return <h1>No layout selected</h1>
}

export default Root;

export interface WarlockPatron {
    id: string;
    name: string;
    desc: string;
    sourceId: string;
}

const warlockPatrons:WarlockPatron[] = [
    {
        id: "wp1",
        name: "Almazzat",
        desc: "A wolf-headed arch-demon with six eyes and six horns. Almazzat seeks to wrest the Sands of the Ages from his father, Kytheros.",
        sourceId: "CS1"
    },
    {
        id: "wp2",
        name: "Kytheros",
        desc: "The Lord of Time who sees all possible futures. Kytheros seeks the fulfillment of all destinies as they were meant to be.",
        sourceId: "CS1"
    },
    {
        id: "wp3",
        name: "Mugdulblub",
        desc: "The Elder Ooze that leaks between the cracks in memory and the darkness between the stars. Mugdulblub seeks the dissolution of all physical form.",
        sourceId: "CS1"
    },
    {
        id: "wp4",
        name: "Shune the Vile",
        desc: "A goddess, the Mother Witch who speaks to her children in the flicker of candles and the rattle of dry bones. Shune seeks hidden secrets and lost lore.",
        sourceId: "CS1"
    },
    {
        id: "wp5",
        name: "Titania",
        desc: "The fickle Queen of the Fey who views all of existence as a whimsical dream with hidden meaning and poignant drama. Titania seeks mischief, beauty, and artistry.",
        sourceId: "CS1"
    },
    {
        id: "wp6",
        name: "The Willowman",
        desc: "A ghostly, elongated being who stalks misty forests and watches from the edge of nightmares. The Willowman seeks fear.",
        sourceId: "CS1"
    }
]


export const getAllWarlockPatrons = () => {
    return [...warlockPatrons]
}

import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import BlackLotusTables from "../charGen/blackLotusTables";
import { BlackLotusTalents } from "../../data/classes";
import { Level } from "../../data/level";
import { getRandomIntInclusive } from "../../data/random";

interface IProps {
    isCheatMode: boolean;
    specialTalentCategory: string;
    setSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
    showTalentsTable: boolean;
    excludeGainTwoTalents: boolean;
    level: Level;
}

const RollBlackLotusTalent: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const getExistingBlackLotusBonus = () => {
        if (props.specialTalentCategory.indexOf("HumanAmbition") !== -1) {
            const theBonus = globalContext.bonuses.find((b) =>
                b.sourceType === "Ancestry" &&
                b.sourceName === "Human Ambition" &&
                b.sourceCategory === props.specialTalentCategory &&
                b.gainedAtLevel === props.level.level
            )
            return theBonus;
        } else {
            const theBonus = globalContext.bonuses.find((b) =>
                b.sourceType === "Class" &&
                b.sourceName === "Ras-Godai" &&
                b.sourceCategory === props.specialTalentCategory &&
                b.gainedAtLevel === props.level.level
            )
            return theBonus;
        }
    }

    const checkForDuplicateBonus = (talentName: string) => {
        const theBonus = globalContext.bonuses.find((b) => b.name === talentName);
        return theBonus;
    }

    const getRandomBlackLotusTalent: any = () => {
        const blackLotusTalents = BlackLotusTalents;
        let min = 0;
        if (props.excludeGainTwoTalents) {
            min = 1;
        }
        const roll = getRandomIntInclusive(min, 11);
        const theTalent = blackLotusTalents[roll];
        if (theTalent) {
            if (theTalent.rerollDuplicates) {
                // check if already have the talent
                const theBonus = checkForDuplicateBonus(theTalent.name);
                if (theBonus) {
                    return getRandomBlackLotusTalent();
                }
            }
            return theTalent;
        }
        return undefined;
    }

    const rollForTalent = () => {
        const theTalent = getRandomBlackLotusTalent(false);
        props.setSpecialTalent(props.level.level, theTalent.name, props.specialTalentCategory);
    }

    const getTalentHasBeenSelected = () => {
        const theBonus = getExistingBlackLotusBonus();
        if (theBonus !== undefined && theBonus.name !== "") { return true; }
        return false;
    }

    const talentHasBeenSelected = getTalentHasBeenSelected();

    const getTalentDesc = () => {
        const theBonus = getExistingBlackLotusBonus();
        if (theBonus) {
            const theTalent = BlackLotusTalents.find((bt) => bt.name === theBonus.name);
            if (theTalent) {
                return theTalent.desc;
            } else {
                return "?";
            }
        }
        return "?";
    }

    const getTalentName = () => {
        const theBonus = getExistingBlackLotusBonus();
        if (theBonus) {
            const theTalent = BlackLotusTalents.find((bt) => bt.name === theBonus.name);
            if (theTalent) {
                return theTalent.name;
            } else {
                return "";
            }
        }
        return "";
    }

    const selectCss = !talentHasBeenSelected ? "form-select redBorder" : "form-select";

    const setChosenTalentBonus = (e: any) => {
        const talentName = e.target.value;
        props.setSpecialTalent(props.level.level, talentName, props.specialTalentCategory);
    }

    const getOptions = () => {
        let availableTalents = [...BlackLotusTalents];
        if (props.excludeGainTwoTalents) {
            availableTalents = BlackLotusTalents.filter((t) => t.name !== "GainTwoBlackLotusTalents");
        }
        const options = availableTalents.map((t) => <option value={t.name} key={t.name}>{t.desc}</option>);
        options.unshift(<option value="" key="selectTalent">-- select talent --</option>);
        return options;
    }

    return (
        <div>
            {props.showTalentsTable &&
                <BlackLotusTables />
            }

            {!props.isCheatMode && !talentHasBeenSelected &&
                <>
                    <button className="btn btn-dark" onClick={() => rollForTalent()} disabled={false}>Roll for Black Lotus Talent</button>&nbsp;
                    {!talentHasBeenSelected &&
                        <div className="valError">Please roll for a talent</div>
                    }
                </>
            }

            {props.isCheatMode &&
                <>
                    <select className={selectCss} onChange={(e) => setChosenTalentBonus(e)} value={getTalentName()}>{getOptions()}</select>
                </>
            }

            {talentHasBeenSelected &&
                <>
                    <div><b>Black Lotus Talent:</b> {getTalentDesc()}</div>

                    {getExistingBlackLotusBonus()?.name === "GainTwoBlackLotusTalents" &&
                        <div>
                            <RollBlackLotusTalent
                                setSpecialTalent={props.setSpecialTalent}
                                isCheatMode={props.isCheatMode}
                                specialTalentCategory={props.specialTalentCategory + "-1"}
                                showTalentsTable={false}
                                excludeGainTwoTalents={true}
                                level={props.level}
                            />
                            <RollBlackLotusTalent
                                setSpecialTalent={props.setSpecialTalent}
                                isCheatMode={props.isCheatMode}
                                specialTalentCategory={props.specialTalentCategory + "-2"}
                                showTalentsTable={false}
                                excludeGainTwoTalents={true}
                                level={props.level}
                            />
                        </div>
                    }
                </>
            }

        </div>
    )

}

export default RollBlackLotusTalent;
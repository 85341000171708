import { Talent } from "./classes";

export interface PatronBoon {
    id: string;
    patronId: string;
    talents: Talent[];
    sourceId: string;
}

const boons: PatronBoon[] = [
    {
        id: "pb1",
        patronId: "wp1",
        talents: [
            { min: 2, max: 2, desc: "1/day, gain advantage on melee attacks for 3 rounds", name: "ADVOnMelee3Rounds" },
            { min: 3, max: 7, desc: "Learn to wield 1 melee weapon or get +1 to melee attacks", name: "LearnMeleeWeaponOrPlusOneMeleeAtk" },
            { min: 8, max: 9, desc: "+2 to Strength or Constitution stat or +1 to melee damage", name: "Plus2STROrCONOrPlus1MeleeDamage", options: ["STR", "CON"], size: 2 },
            { min: 10, max: 11, desc: "Gain advantage on initiative rolls", name: "AdvOnInitiative", rerollDuplicates: true },
            { min: 12, max: 12, desc: "Choose one option or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        sourceId: "CS1"
    },
    {
        id: "pb2",
        patronId: "wp2",
        talents: [
            { min: 2, max: 2, desc: "1/day, force the GM to reroll a single roll", name: "ForceDMReroll" },
            { min: 3, max: 7, desc: "Gain +1 to your AC through supernatural foresight", name: "ForesightPlus1AC" },
            { min: 8, max: 9, desc: "+2 to Strength, Dexterity, or Wisdom stat", name: "StatBonus", options: ["STR", "DEX", "WIS"], size: 2 },
            { min: 10, max: 11, desc: "3/day, add your WIS bonus to any roll", name: "AddWISToRolls", rerollDuplicates: true },
            { min: 12, max: 12, desc: "Choose one option or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        sourceId: "CS1"
    },
    {
        id: "pb3",
        patronId: "wp3",
        talents: [
            { min: 2, max: 2, desc: "1/day, turn into a crawling puddle of slime for 3 rounds", name: "TurnToSlime" },
            { min: 3, max: 7, desc: "Maximize 2 hit point die rolls (prior or future)", name: "MaximizeTwoHPRolls" },
            { min: 8, max: 9, desc: "+2 to Dexterity or Constitution stat", name: "StatBonus", options: ["DEX", "CON"], size: 2 },
            { min: 10, max: 11, desc: "Become immune to 1: acid, cold, poison (reroll if no options)", name: "ImmuneToEnergyType" },
            { min: 12, max: 12, desc: "Choose one option or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        sourceId: "CS1"
    },
    {
        id: "pb4",
        patronId: "wp4",
        talents: [
            { min: 2, max: 2, desc: "1/day, read the mind of a creature you touch for 3 rounds", name: "ReadMindTouch" },
            { min: 3, max: 7, desc: "Learn a wizard spell, tier = half your level. Cast it with INT", name: "LearnSpellFromPatron" },
            { min: 8, max: 9, desc: "+2 to Dexterity or Intelligence stat", name: "StatBonus", options: ["DEX", "INT"], size: 2 },
            { min: 10, max: 11, desc: "+1 XP whenever you learn a valuable or significant secret", name: "XPForSecret" },
            { min: 12, max: 12, desc: "Choose one option or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        sourceId: "CS1"
    },
    {
        id: "pb5",
        patronId: "wp5",
        talents: [
            { min: 2, max: 2, desc: "1/day, hypnotize a LV 5 or less creature for 3 rounds", name: "Hypnotize3Rounds" },
            { min: 3, max: 7, desc: "Learn to wield a longbow or gain +1 to ranged attacks", name: "LongbowOrPlus1Ranged" },
            { min: 8, max: 9, desc: "+2 to Dexterity or Charisma stat", name: "StatBonus", options: ["DEX", "CHA"], size: 2 },
            { min: 10, max: 11, desc: "Hostile spells that target you are always hard to cast", name: "HostileSpellsHard", rerollDuplicates: true },
            { min: 12, max: 12, desc: "Choose one option or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" }
        ],
        sourceId: "CS1"
    },
    {
        id: "pb6",
        patronId: "wp6",
        talents: [
            { min: 2, max: 2, desc: "1/day, teleport to a far location you see as your move", name: "TeleportToFar" },
            { min: 3, max: 7, desc: "+1 to melee or ranged attacks", name: "Plus1ToHit", options: ["Melee attacks", "Ranged attacks"] },
            { min: 8, max: 9, desc: "+2 to Strength or Dexterity stat", name: "StatBonus", options: ["STR", "DEX"], size: 2 },
            { min: 10, max: 11, desc: "1/day, force a close being to check morale, even if immune", name: "ForceMoraleCheck" },
            { min: 12, max: 12, desc: "Choose one option or +2 points to distribute to stats", name: "ChooseOrTwoStatPoints" },
            { min: 13, max: 13, desc: "+2 points to distribute to stats", name: "+2 Stat Points" } // only used when in Edit Mode
        ],
        sourceId: "CS1"
    }
]

export const getAllPatronBoons = () => {
    return [...boons]
}

import { nameByRace } from "fantasy-name-generator";

import { Ancestry } from "../../../data/ancestries";
import { Background } from "../../../data/backgrounds";
import { CharClass } from "../../../data/classes";
import { Deity } from "../../../data/deities";
import { getRandomIntInclusive } from "../../../data/random";
import { duckNames } from '../../../data/ducks';
import { dogNames, koboldNames } from '../../../data/dogs';
import { chelonianNames, dragonbornNames, forestElfNames, gnomeNames, halfTrollNames, mycellanNames, shadowElfNames, slimeFolkNames } from '../../../data/unnaturalSelection';
import { Stats } from "../../../data/stats";

export const getRandomBackground = (backgrounds: Background[]): Background => {
    const numBackgrounds = backgrounds.length;
    const randBGRoll = getRandomIntInclusive(0, numBackgrounds - 1);
    const randBackground = backgrounds[randBGRoll];
    return randBackground;
}

export const getRandomAncestry = (ancestries: Ancestry[], charClasses: CharClass[], theClass: string): string => {
    const numAncestries = ancestries.length;
    let theAncestry = "";
    if (theClass === "" || theClass === "Level 0") {
        const randRoll = getRandomIntInclusive(0, numAncestries - 1);
        theAncestry = ancestries[randRoll].name;
    } else {
        const thisClass = charClasses.find((c) => c.name === theClass);
        if (thisClass) {
            const availableAncestryChances = thisClass.ancestryChances.filter((ac) => ancestries.map((a) => a.name).indexOf(ac.ancestry) !== -1);

            const totalChances = availableAncestryChances.reduce((accumulator, currentValue) => accumulator + currentValue.chance, 0);
            const roll = getRandomIntInclusive(1, totalChances);
            let total = 0;
            availableAncestryChances.forEach((ac) => {
                total = total + ac.chance;
                if (roll <= total && theAncestry === "") {
                    theAncestry = ac.ancestry;
                }
            })
        }
    }

    return theAncestry;

}

export const getRandomClass = (charClasses: CharClass[]) => {
    const numClasses = charClasses.length;
    const randRoll = getRandomIntInclusive(0, numClasses - 1);
    const theClass = charClasses[randRoll].name;
    return theClass;
}

export const getRandomAlignment = () => {
    let thisAlignment = "?";
    const randRoll = getRandomIntInclusive(1, 10);
    if (randRoll <= 4) { thisAlignment = "Lawful"; }
    if (randRoll > 4 && randRoll <= 8) { thisAlignment = "Neutral"; }
    if (randRoll > 8 && randRoll <= 10) { thisAlignment = "Chaotic"; }
    return thisAlignment;
}

export const getRandomDeity = (deities: Deity[], alignmentToMatch: string, excludeSpirits: boolean, pantheon?: string) => {
    let availableDeities = [...deities];
    if (excludeSpirits) { deities = deities.filter((d) => d.pantheon !== "Nature Spirtits") }
    if (alignmentToMatch !== "") {
        availableDeities = deities.filter((ad) => ad.alignments.indexOf(alignmentToMatch) !== -1);
    }
    if (pantheon !== undefined) {
        availableDeities = deities.filter((ad) => ad.pantheon === pantheon);
    }
    const numDeities = availableDeities.length;
    const randRoll = getRandomIntInclusive(0, numDeities - 1);
    const theDeity = availableDeities[randRoll];
    return theDeity;
}

export const getRandomName = (theAncestry: string) => {
    let name: any = "";

    const genderNum = getRandomIntInclusive(1, 2);
    let gender: any = "";
    genderNum === 1 ? gender = { gender: "male" } : gender = { gender: "female" };

    switch (theAncestry) {
        case "Dwarf": { name = nameByRace("dwarf", { gender }); break; }
        case "Elf": { name = nameByRace("elf", { gender }); break; }
        case "Goblin": { name = nameByRace("goblin"); break; }
        case "Halfling": { name = nameByRace("halfling", { gender }); break; }
        case "Half-Orc": { name = nameByRace("orc"); break; }
        case "Human": { name = nameByRace("human", { allowMultipleNames: true }); break; }

        case "Kobold": { name = getKoboldName(); break }

        case "Duck": { name = getDuckName(); break }
        case "Dog": { name = getDogName(); break }

        case "Chelonian": { name = getChelonianName(); break }
        case "Dragonborn": { name = getDragonbornName(); break }
        case "Forest Elf": { name = getForestElfName(); break }
        case "Half Troll": { name = getHalfTrollName(); break }
        case "Shadow Elf": { name = getShadowElfName(); break }

        case "Gnome": { name = getGnomeName(); break }
        case "Mycellan": { name = getMycellanName(); break }
        case "Risen": { name = nameByRace("human", { allowMultipleNames: true }); break; }
        case "Slimefolk": { name = getSlimefolkName(); break }

        default: { name = nameByRace("human", { allowMultipleNames: true }); break; }
    }

    return name;
}

const getDuckName = () => {
    return duckNames[getRandomIntInclusive(0, duckNames.length - 1)];
}

const getDogName = () => {
    return dogNames[getRandomIntInclusive(0, dogNames.length - 1)];
}

const getKoboldName = () => {
    return koboldNames[getRandomIntInclusive(0, dogNames.length - 1)];
}

const getChelonianName = () => {
    return chelonianNames[getRandomIntInclusive(0, chelonianNames.length - 1)];
}

const getDragonbornName = () => {
    return dragonbornNames[getRandomIntInclusive(0, dragonbornNames.length - 1)];
}

const getForestElfName = () => {
    return forestElfNames[getRandomIntInclusive(0, forestElfNames.length - 1)];
}

const getHalfTrollName = () => {
    return halfTrollNames[getRandomIntInclusive(0, halfTrollNames.length - 1)];
}

const getShadowElfName = () => {
    return shadowElfNames[getRandomIntInclusive(0, shadowElfNames.length - 1)];
}

const getGnomeName = () => {
    return gnomeNames[getRandomIntInclusive(0, gnomeNames.length - 1)];
}

const getMycellanName = () => {
    return mycellanNames[getRandomIntInclusive(0, mycellanNames.length - 1)];
}

const getSlimefolkName = () => {
    return slimeFolkNames[getRandomIntInclusive(0, slimeFolkNames.length - 1)];
}

export const roll3d6 = () => {
    return getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6) + getRandomIntInclusive(1, 6);
}

export const getRandomStats = (ensureAtLeastOneis14: boolean) => {
    let newStats: Stats = {
        Strength: roll3d6(),
        Dexterity: roll3d6(),
        Constitution: roll3d6(),
        Intelligence: roll3d6(),
        Wisdom: roll3d6(),
        Charisma: roll3d6(),
    }

    if (ensureAtLeastOneis14) {
        do {
            newStats = {
                Strength: roll3d6(),
                Dexterity: roll3d6(),
                Constitution: roll3d6(),
                Intelligence: roll3d6(),
                Wisdom: roll3d6(),
                Charisma: roll3d6(),
            }
        } while (newStats.Strength < 14 && newStats.Dexterity < 14 && newStats.Constitution < 14 && newStats.Intelligence < 14 && newStats.Wisdom < 14 && newStats.Charisma < 14)
    }

    return newStats;
}
export interface Creature {
    name: string;
    desc: string;
    sourceIds: string[],
    type: string,
    AC: number;
    HP: number,
    attacks: string;
    move: string;
    stats: {
        S: number,
        D: number,
        C: number,
        I: number,
        W: number,
        Ch: number
    },
    alignment: string;
    level: number,
    specials?: CreatureSpecial[],
    languages?: string[]
}

export interface CreatureSpecial {
    name: string,
    effect: string
}

export const animals: Creature[] = [
    {
        "name": "Bat",
        "desc": "Rodent body with leathery wings and silent flight.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 1,
        "attacks": "1 bite +0 (1d2)",
        "move": "near (fly)",
        "stats": {
            "S": -2,
            "D": 2,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "languages": ["Rodent"]
    },
    {
        "name": "Bear, Black",
        "desc": "Hefty, black-furred beast that can smell food from miles away.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 19,
        "attacks": "2 claw +3 (1d6)",
        "move": "near (climb)",
        "stats": {
            "S": 3,
            "D": 1,
            "C": 2,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 4,
        "specials": [
            {
                "name": "Cub Rage",
                "effect": "If cub is nearby, immune to morale checks and deals an extra die of damage."
            }
        ],
        "languages": ["Bear"]
    },    
    {
        "name": "Beaver",
        "desc": "Flat-tailed, buck-toothed builder of river dams and freshwater homes.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 2,
        "attacks": "1 bite +0 (1d2)",
        "move": "near (swim)",
        "stats": {
            "S": -1,
            "D": -1,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 0,
        "languages": ["Rodent"]
    },
    {
        "name": "Coyote",
        "desc": "Collaborative desert hunters, with a golden coat and cunning eyes.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 3,
        "attacks": "1 bite +2 (1d4)",
        "move": "near",
        "stats": {
            "S": -2,
            "D": 1,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Pack",
                "effect": "If more than one coyote attacks the same target on this turn, they do so with ADV."
            }
        ],
        "languages": ["Canine"]
    },
    {
        "name": "Dog",
        "desc": "Faithful companion to humanoids, fierce protector.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 1,
        "attacks": "1 bite +1 (1d2)",
        "move": "near",
        "stats": {
            "S": 0,
            "D": 0,
            "C": 0,
            "I": -2,
            "W": 1,
            "Ch": -1
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Instant Calming",
                "effect": "An Ovate can calm a dog without expending calm animal talent."
            }
        ],
        "languages": ["Canine"]
    },
    {
        "name": "Eagle",
        "desc": "Majestic king of the sky with keen eyes and a white head.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 4,
        "attacks": "1 bite +1 (1d2)",
        "move": "near (fly)",
        "stats": {
            "S": 0,
            "D": 1,
            "C": 0,
            "I": -2,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 0,
        "languages": ["Raptor"]
    },
    {
        "name": "Fox",
        "desc": "Rust-furred with bushy tails.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 13,
        "HP": 2,
        "attacks": "1 bite +0 (1d2)",
        "move": "near (burrow)",
        "stats": {
            "S": -2,
            "D": 3,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 0,
        "languages": ["Canine"]
    },
    {
        "name": "Goat",
        "desc": "Bleating and surefooted climber.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 1,
        "attacks": "1 ram +1 (1d2)",
        "move": "near",
        "stats": {
            "S": -1,
            "D": 1,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 0,
        "languages": ["Grazer"]
    },
    {
        "name": "Hawk",
        "desc": "Sleek bird of prey with keen eyes and a sharp beak.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 1,
        "attacks": "1 peck +0 (1d2)",
        "move": "near (fly)",
        "stats": {
            "S": -1,
            "D": 1,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Vision",
                "effect": "Can see the slightest movements at a far distance."
            }
        ],
        "languages": ["Raptor"]
    },
    {
        "name": "Iguana",
        "desc": "Slender, scaly reptile with a crown of spiky crests.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 2,
        "attacks": "1 bite +0 (1d2)",
        "move": "near (climb)",
        "stats": {
            "S": -3,
            "D": 0,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "languages": ["Reptile"]
    },
    {
        "name": "Meerkat",
        "desc": "A small mammal that eats scorpions and lives in communities.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 1,
        "attacks": "1 bite +0 (1d2)",
        "move": "near",
        "stats": {
            "S": -3,
            "D": 1,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Poison Resistance",
                "effect": "Gain ADV on all poison checks."
            }
        ],
        "languages": ["Burrower"]
    },
    {
        "name": "Opossum",
        "desc": "Small marsupial with a pointy snout and a hairless rat tail.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 2,
        "attacks": "1 bite +0 (1d2)",
        "move": "near (burrow)",
        "stats": {
            "S": -3,
            "D": 0,
            "C": -1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Play Dead",
                "effect": "If cornered, lay down and remain stationary."
            }
        ],
        "languages": ["Marsupial"]
    },
    {
        "name": "Otter",
        "desc": "Small web-footed mammal.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 1,
        "attacks": "1 bite +0 (1)",
        "move": "near (swim)",
        "stats": {
            "S": -3,
            "D": 3,
            "C": -1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "languages": ["Marine"]
    },
    {
        "name": "Owl",
        "desc": "Saucer-eyed bird of prey.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 1,
        "attacks": "1 talons +1 (1d4)",
        "move": "near (fly)",
        "stats": {
            "S": -2,
            "D": 1,
            "C": 0,
            "I": -2,
            "W": 2,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Superb Sight",
                "effect": "Can see prey at a great distance."
            }
        ],
        "languages": ["Raptor"]
    },
    {
        "name": "Pelican",
        "desc": "Gliding waterbird with a long, broad bill and deep pouch.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 1,
        "attacks": "none",
        "move": "near (fly)",
        "stats": {
            "S": -2,
            "D": 1,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Fisher",
                "effect": "Can catch fish with ease."
            }
        ],
        "languages": ["Raptor"]
    },
    {
        "name": "Porcupine",
        "desc": "Rodent covered in sharp quills.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 2,
        "attacks": "none",
        "move": "near",
        "stats": {
            "S": -3,
            "D": -2,
            "C": -1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Quills",
                "effect": "Melee attacks against must make DC 9 DEX or take 1d4 quill damage."
            }
        ],
        "languages": ["Rodent"]
    },
    {
        "name": "Rabbit",
        "desc": "Herbivorous mammal covered in soft fur with powerful hind legs.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 1,
        "attacks": "none",
        "move": "near (burrow)",
        "stats": {
            "S": -4,
            "D": 3,
            "C": -3,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "languages": ["Burrower"]
    },
    {
        "name": "Raccoon",
        "desc": "Five-fingered nocturnal mammal with a ringed tail.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 2,
        "attacks": "1 bite +1 (1d4 + rabies)",
        "move": "near (climb)",
        "stats": {
            "S": -3,
            "D": 0,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Cornered",
                "effect": "Gain ADV on attacks while cornered."
            },
            {
                "name": "Rabies",
                "effect": "DC 8 CON or contract rabies. In 2 days, DISADV on all attacks & checks. Die in 4 days."
            },
            {
                "name": "Rabid",
                "effect": "After 2 days, gain rabies quality with crazed focus on biting others to pass on disease."
            }
        ],
        "languages": ["Bear"]
    },
    {
        "name": "Rat",
        "desc": "Rangy, plague-carrying rodents that infest underground places.",
        "sourceIds": ["SD", "US"],
        "type": "animal",
        "AC": 10,
        "HP": 1,
        "attacks": "1 bite +0 (1 + disease)",
        "move": "near",
        "stats": {
            "S": -3,
            "D": 0,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Disease",
                "effect": "DC 9 CON or 1d4 CON damage (can't heal while ill). Repeat check once per day; ends on success. Die at 0 CON."
            }
        ],
        "languages": ["Rodent"]
    },
    {
        "name": "Skunk",
        "desc": "Compact, striped and curious. Sprays a foul, blinding scent.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 2,
        "attacks": "1 musk +0 (close)",
        "move": "near (burrow)",
        "stats": {
            "S": -4,
            "D": 0,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Musk",
                "effect": "1/day, targeted creature DC 18 CON or blindness."
            },
            {
                "name": "Blindness",
                "effect": "Movement limited to close and all actions at DISADV for 5 rounds."
            }
        ],
        "languages": ["Burrower"]
    },
    {
        "name": "Squirrel, Flying",
        "desc": "A squirrel with a patagium extending from wrists to ankles.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 1,
        "attacks": "none",
        "move": "near (climb, glide)",
        "stats": {
            "S": -3,
            "D": 1,
            "C": -1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 0,
        "specials": [
            {
                "name": "Glide",
                "effect": "Can glide a near distance from a high launching point."
            }
        ],
        "languages": ["Rodent"]
    },

    {
        "name": "Antelope",
        "desc": "Graceful savannah nomads with spiral horns and a golden coat.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 14,
        "HP": 4,
        "attacks": "1 charge (1d4)",
        "move": "double near",
        "stats": {
            "S": 0,
            "D": 4,
            "C": 0,
            "I": -3,
            "W": 2,
            "Ch": -3
        },
        "alignment": "N",
        "level": 1,
        "languages": ["Grazer"]
    },
    {
        "name": "Badger",
        "desc": "Fierce, clawed burrowers with black-and-white face stripes.",
        "sourceIds": ["SD"],
        "type": "animal",
        "AC": 11,
        "HP": 5,
        "attacks": "2 claw +2 (1d4)",
        "move": "near (burrow)",
        "stats": {
            "S": 2,
            "D": 0,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 1,
        "specials": [
            {
                "name": "Rage",
                "effect": "1/day, immune to morale checks, +1d4 damage (3 rounds)."
            }
        ],
        "languages": ["Burrower"]
    },
    {
        "name": "Deer",
        "desc": "Gentle woodland grazer, adorned with strong antlers.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 13,
        "HP": 5,
        "attacks": "1 charge +0 (1d6)",
        "move": "double near",
        "stats": {
            "S": 0,
            "D": 4,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 1,
        "languages": ["Grazer"]
    },
    {
        "name": "Hyena",
        "desc": "Scavenger with a distinctive laugh and a menacing grin.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 6,
        "attacks": "1 bite +1 (1d8)",
        "move": "near",
        "stats": {
            "S": 0,
            "D": 1,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 1,
        "languages": ["Canine"]
    },
    {
        "name": "Mastiff",
        "desc": "Muscled guard dogs with fierce loyalty to their pack or owners.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 4,
        "attacks": "1 bite +1 (1d6)",
        "move": "near",
        "stats": {
            "S": 1,
            "D": 1,
            "C": 0,
            "I": -2,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 1,
        "languages": ["Canine"]
    },
    {
        "name": "Ostrich",
        "desc": "Flightless, long-legged avian with a serpentine neck.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 5,
        "attacks": "1 kick +1 (1d6)",
        "move": "near",
        "stats": {
            "S": 0,
            "D": 1,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 1,
        "languages": ["Raptor"]
    },
    {
        "name": "Snake, Cobra",
        "desc": "A weaving serpent with a neck hood and lethal venom.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 4,
        "attacks": "1 bite +2 (1 + poison)",
        "move": "near",
        "stats": {
            "S": -3,
            "D": 2,
            "C": 0,
            "I": -3,
            "W": 0,
            "Ch": -3
        },
        "alignment": "N",
        "level": 1,
        "specials": [
            {
                "name": "Poison",
                "effect": "DC 18 CON or go to 0 HP with a death timer of 1."
            }
        ],
        "languages": ["Reptile"]
    },
    {
        "name": "Tasmanian Devil",
        "desc": "Marsupial with powerful jaws.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 8,
        "attacks": "1 bite +0 (1d2)",
        "move": "near",
        "stats": {
            "S": -2,
            "D": -2,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 1,
        "languages": ["Marsupial"]
    },
    {
        "name": "Vulture",
        "desc": "Scavenger birds with black feathers, hunched backs, and bald heads.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 5,
        "attacks": "1 tear +1 (1d4)",
        "move": "near (fly)",
        "stats": {
            "S": 1,
            "D": 0,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 1,
        "specials": [
            {
                "name": "Carrion Tracker",
                "effect": "Can track dead flesh unerringly within a mile."
            }
        ],
        "languages": ["Raptor"]
    },
    {
        "name": "Wolverine",
        "desc": "Stocky mammal with a bushy tail and sharp claws.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 7,
        "attacks": "2 claw +1 (1d2)",
        "move": "near (burrow)",
        "stats": {
            "S": -1,
            "D": 0,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 1,
        "specials": [
            {
                "name": "Fearless",
                "effect": "Immune to morale checks."
            }
        ],
        "languages": ["Burrower"]
    },
    {
        "name": "Camel",
        "desc": "Ornery, tan-furred desert beasts.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 12,
        "attacks": "1 hoof +3 (1d6) or 1 spit (near) +0 (1d4)",
        "move": "double near",
        "stats": {
            "S": 3,
            "D": 0,
            "C": 3,
            "I": -2,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 2,
        "languages": ["Grazer"]
    },
    {
        "name": "Horse",
        "desc": "Powerful, swift herd animals that roam open plains.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 11,
        "attacks": "1 hooves +3 (1d6)",
        "move": "double near",
        "stats": {
            "S": 3,
            "D": 1,
            "C": 2,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 2,
        "languages": ["Grazer"]
    },
    {
        "name": "Wolf",
        "desc": "A giant canine with a gray pelt, yellow eyes, and dripping jaws.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 10,
        "attacks": "1 bite +2 (1d6)",
        "move": "double near",
        "stats": {
            "S": 2,
            "D": 2,
            "C": 1,
            "I": -2,
            "W": 1,
            "Ch": 0
        },
        "alignment": "N",
        "level": 2,
        "specials": [
            {
                "name": "Pack Hunter",
                "effect": "Deals +1 damage while an ally is close."
            }
        ],
        "languages": ["Canine"]
    },

    {
        "name": "Boar",
        "desc": "Ornery wild pig with bristly, russet hair and yellowed tusks.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 14,
        "attacks": "2 tusk +3 (1d6)",
        "move": "near",
        "stats": {
            "S": 3,
            "D": 0,
            "C": 1,
            "I": -2,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 3,
        "specials": [
            {
                "name": "Gore",
                "effect": "Deals an extra die of damage if it hits the same target with both tusks."
            }
        ],
        "languages": ["Grazer"]
    },
    {
        "name": "Cheetah",
        "desc": "Swift savannah sprinter, golden-coated with black spots.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 15,
        "HP": 12,
        "attacks": "2 claws +3 (1d4) or 1 bite +3 (1d6) or 1 rake +3 (2d6)",
        "move": "double near",
        "stats": {
            "S": 1,
            "D": 4,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 3,
        "specials": [
            {
                "name": "Tackle",
                "effect": "A successful claw hit after a full move knocks down the target for an immediate rake attack if they fail a DC 12 STR check."
            },
            {
                "name": "Rake",
                "effect": "Prone targets are grabbed and raked with hind legs causing an additional 2d6 damage."
            }
        ],
        "languages": ["Feline"]
    },
    {
        "name": "Lion",
        "desc": "Tawny great cats that hunt in open plains. Males have manes.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 15,
        "attacks": "2 rend +4 (1d8)",
        "move": "near",
        "stats": {
            "S": 4,
            "D": 2,
            "C": 2,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 3,
        "languages": ["Feline"]
    },
    {
        "name": "Lion, Mountain",
        "desc": "Sleek, tawny predator that prowls rugged terrain.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 14,
        "HP": 15,
        "attacks": "2 rend +4 (1d6)",
        "move": "near (climb)",
        "stats": {
            "S": 3,
            "D": 3,
            "C": 1,
            "I": -2,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 3,
        "languages": ["Feline"]
    },
    {
        "name": "Panther",
        "desc": "Supple large cats with blue-black fur. Stealthy hunters.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 14,
        "HP": 14,
        "attacks": "2 rend +3 (1d6)",
        "move": "near (climb)",
        "stats": {
            "S": 3,
            "D": 4,
            "C": 1,
            "I": -2,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 3,
        "languages": ["Feline"]
    },
    {
        "name": "Piranha, Swarm",
        "desc": "A school of flat, silvery fish with vicious fangs.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 13,
        "attacks": "2 bite +2 (1d6)",
        "move": "near (swim)",
        "stats": {
            "S": -2,
            "D": 2,
            "C": 0,
            "I": -3,
            "W": 0,
            "Ch": -3
        },
        "alignment": "N",
        "level": 3,
        "specials": [
            {
                "name": "Savage",
                "effect": "ADV on attacks against creatures below half their HP."
            }
        ],
        "languages": ["Marine"]
    },
    {
        "name": "Shark",
        "desc": "Bloodthirsty apex predators of the sea. Gray, torpedo-like body.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 15,
        "attacks": "1 bite +3 (1d10)",
        "move": "near (swim)",
        "stats": {
            "S": 3,
            "D": 1,
            "C": 2,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 3,
        "languages": ["Marine"]
    },
    {
        "name": "Steer",
        "desc": "Bull with long, wide horns that charges when it sees red.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 14,
        "attacks": "1 ram +2 (1d6)",
        "move": "near",
        "stats": {
            "S": 2,
            "D": -1,
            "C": 2,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 3,
        "languages": ["Grazer"]
    },

    {
        "name": "Bat Swarm",
        "desc": "A whirling cloud of screeching, bloodthirsty bats.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 18,
        "attacks": "3 bite +2 (1d6)",
        "move": "near (fly)",
        "stats": {
            "S": -3,
            "D": 2,
            "C": 0,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 4,
        "languages": ["Rodent"]
    },
    {
        "name": "Crocodile",
        "desc": "Fat, scaly reptiles with stumpy legs and long, thrashing tails.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 14,
        "HP": 20,
        "attacks": "2 bite +3 (1d8)",
        "move": "near (swim)",
        "stats": {
            "S": 3,
            "D": 1,
            "C": 2,
            "I": -2,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 4,
        "languages": ["Reptile"]
    },
    {
        "name": "Kangaroo",
        "desc": "Bipedal marsupial with powerful hind legs and a built-in pocket.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 14,
        "HP": 18,
        "attacks": "1 kick +2 (1d6 + box)",
        "move": "near (jump)",
        "stats": {
            "S": 2,
            "D": 1,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 4,
        "specials": [
            {
                "name": "Box",
                "effect": "A successful first hit allows for a second boxing attack with ADV for 1d6."
            }
        ],
        "languages": ["Marsupial"]
    },
    {
        "name": "Moose",
        "desc": "A towering, brown-haired grazer with weighty, flat antlers.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 11,
        "HP": 19,
        "attacks": "2 antler +3 (1d6)",
        "move": "double near",
        "stats": {
            "S": 3,
            "D": 0,
            "C": 1,
            "I": -2,
            "W": 0,
            "Ch": -2
        },
        "alignment": "N",
        "level": 4,
        "languages": ["Grazer"]
    },
    {
        "name": "Snake Swarm",
        "desc": "A roiling wave of snakes darting and flowing across the ground.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 19,
        "attacks": "3 bite +2 (1d4 + poison)",
        "move": "near",
        "stats": {
            "S": -3,
            "D": 2,
            "C": 1,
            "I": -3,
            "W": 0,
            "Ch": -3
        },
        "alignment": "N",
        "level": 4,
        "specials": [
            {
                "name": "Poison",
                "effect": "DC 12 CON or go to 0 HP in 1d4 rounds."
            }
        ],
        "languages": ["Reptile"]
    },
    {
        "name": "Tiger",
        "desc": "The largest natural cat species, sporting black stripes on orange fur.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 22,
        "attacks": "2 claw +5 (1d6) and 1 bite +5 (1d8)",
        "move": "near",
        "stats": {
            "S": 3,
            "D": 2,
            "C": 2,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 4,
        "languages": ["Feline"]
    },
    {
        "name": "Gorilla",
        "desc": "Mighty, jungle-dwelling apes.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 20,
        "attacks": "2 rend +5 (2d6)",
        "move": "near (climb)",
        "stats": {
            "S": 4,
            "D": 2,
            "C": 2,
            "I": -1,
            "W": 1,
            "Ch": -1
        },
        "alignment": "N",
        "level": 4,
        "languages": ["Primate"]
    },

    {
        "name": "Bear, Brown",
        "desc": "A hulking, swaying brute with claws as long as a finger.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 13,
        "HP": 25,
        "attacks": "2 claw +4 (1d8)",
        "move": "near (climb)",
        "stats": {
            "S": 4,
            "D": 1,
            "C": 3,
            "I": -2,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 5,
        "specials": [
            {
                "name": "Crush",
                "effect": "Deals an extra die of damage if it hits the same target with both claws."
            }
        ],
        "languages": ["Bear"]
    },
    {
        "name": "Hippopotamus",
        "desc": "Ornery river-beasts as large as cows with round, purple bodies and bulbous snouts.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 12,
        "HP": 24,
        "attacks": "2 bite +4 (1d10)",
        "move": "near (swim)",
        "stats": {
            "S": 4,
            "D": 0,
            "C": 2,
            "I": -3,
            "W": 0,
            "Ch": -3
        },
        "alignment": "N",
        "level": 5,
        "specials": [
            {
                "name": "Stumpy",
                "effect": "ADV on STR checks to grab or drag other creatures."
            }
        ],
        "languages": ["Pachyderm"]
    },
    {
        "name": "Rhinoceros",
        "desc": "Gray-skinned bulls with single nose horns. Dwell in grasslands.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 14,
        "HP": 25,
        "attacks": "2 horn +4 (1d8)",
        "move": "near",
        "stats": {
            "S": 4,
            "D": -1,
            "C": 3,
            "I": -3,
            "W": 0,
            "Ch": -3
        },
        "alignment": "N",
        "level": 5,
        "specials": [
            {
                "name": "Charge",
                "effect": "Move up to double near in a straight line and make 1 horn attack. If hit, x3 damage."
            }
        ],
        "languages": ["Pachyderm"]
    },

    {
        "name": "Rat Swarm",
        "desc": "A screeching tidal wave of clawing and biting rats.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 10,
        "HP": 28,
        "attacks": "4 bite +0 (1 + disease)",
        "move": "near",
        "stats": {
            "S": -3,
            "D": 0,
            "C": 1,
            "I": -3,
            "W": 1,
            "Ch": -3
        },
        "alignment": "N",
        "level": 6,
        "specials": [
            {
                "name": "Disease",
                "effect": "DC 9 CON or 1d4 CON damage (can't heal while ill). Repeat check once per day; ends on success. Die at 0 CON."
            }
        ],
        "languages": ["Rodent"]
    },

    {
        "name": "Bear, Polar",
        "desc": "A mighty, white bear that thrives in arctic environments.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 13,
        "HP": 34,
        "attacks": "2 claw +6 (2d6)",
        "move": "near (climb)",
        "stats": {
            "S": 4,
            "D": 1,
            "C": 3,
            "I": -2,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 7,
        "specials": [
            {
                "name": "Crush",
                "effect": "Deals an extra die of damage if it hits the same target with both claws."
            },
            {
                "name": "Thick Fur",
                "effect": "Cold immune."
            }
        ],
        "languages": ["Bear"]
    },

    {
        "name": "Elephant",
        "desc": "Mighty mammals with tough hide, flappy ears, and a trunk.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 14,
        "HP": 34,
        "attacks": "2 tusks +6 (1d8)",
        "move": "near",
        "stats": {
            "S": 5,
            "D": 0,
            "C": 3,
            "I": -2,
            "W": 1,
            "Ch": 0
        },
        "alignment": "N",
        "level": 7,
        "specials": [
            {
                "name": "Charge",
                "effect": "Move up to double near in a straight line and make 1 tusks attack. If hit, x3 damage."
            }
        ],
        "languages": ["Pachyderm"]
    },
    
    {
        "name": "Bear, Grizzly",
        "desc": "Dominant, hump-shouldered giant that violently protects its young.",
        "sourceIds": ["US"],
        "type": "animal",
        "AC": 13,
        "HP": 41,
        "attacks": "2 claw +6 (2d6)",
        "move": "near (climb)",
        "stats": {
            "S": 4,
            "D": 1,
            "C": 3,
            "I": -2,
            "W": 1,
            "Ch": -2
        },
        "alignment": "N",
        "level": 8,
        "specials": [
            {
                "name": "Bear Hug",
                "effect": "Deals an extra die of damage if it hits the same target with both claws."
            },
            {
                "name": "Cub Rage",
                "effect": "If cub is nearby, immune to morale checks and deals an extra die of damage."
            }
        ],
        "languages": ["Bear"]
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
        
]
import { useContext } from "react";
import { Bonus } from "../../data/bonus";

import { GlobalContextType } from "../context";
import { CreateCharacterContext } from "../createCharacter";
import { Level } from "../../data/level";
import { getBonusByName } from "../../data/utilities";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    boonPatron: string;
    boonSource: string;
    level: Level;
    setBonus: (bonus: Bonus) => void;
}

const GainWeaponMastery: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const options = globalContext.availableWeapons.sort((w1, w2) => w1.name < w2.name ? -1 : 1).map((w) => <option value={w.name} key={w.name}>{w.name}</option>);
    options.unshift(<option value="" key="selectWeaponMastery">-- select weapon --</option>);

    const getCurrentValue = () => {
        let val = "";
        // const theBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.sourceCategory === props.sourceCategory && b.gainedAtLevel === props.level.level && b.name === "WeaponMastery");
        const theBonus = getBonusByName(globalContext.bonuses, "WeaponMastery", props.sourceType, props.sourceName, props.sourceCategory, props.level.level, props.boonPatron, props.boonSource);
        if (theBonus) {
            val = theBonus.bonusTo;
        }
        return val;
    }

    const setWeaponMasteryBonus = (bonusTo: string) => {
        const theBonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            boonPatron: props.boonPatron,
            boonSource: props.boonSource,
            gainedAtLevel: props.level.level,
            name: "WeaponMastery",
            bonusName: "Plus1AttackAndDamagePlusHalfLevel",
            bonusTo: bonusTo
        }
        props.setBonus(theBonus);
    }

    const selectCss = getCurrentValue() === "" ? "form-select redBorder" : "form-select";

    return (
        <div>
            <div className="formNote">Weapon Mastery</div>
            <select className={selectCss} onChange={(e) => setWeaponMasteryBonus(e.target.value)} value={getCurrentValue()}>{options}</select>
        </div>
    )

}

export default GainWeaponMastery;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import { Bonus } from "../../data/bonus";
import AnimalDisplay from "./animalDisplay";
import { Creature } from "../../data/animals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { getRandomIntInclusive } from "../../data/random";

interface IProps {
    sourceType: string;
    sourceName: string;
    sourceCategory: string;
    currentLevel: number;
    isMinimal: boolean;
    animalNumber: number;
    characterIsSaved: boolean;
    setBonus: (bonus: Bonus) => void;
}

const PickAnimalCompanion: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const allAnimals = globalContext.availableAnimals;

    const animalSort = (a1: Creature, a2: Creature) => {
        return a1.level - a2.level || a1.name.localeCompare(a2.name)
    }

    const effectiveLevel = props.characterIsSaved ? props.currentLevel : 0;

    const availAnimals = allAnimals.filter((a) => a.level <= effectiveLevel).sort(animalSort);

    const options = availAnimals.map((s) => <option value={s.name} key={s.name}>{s.name} (LV {s.level})</option>);
    options.unshift(<option value="" key="selectAnimal">-- select animal companion --</option>);

    let currentValue = "";
    const existingBonus = globalContext.bonuses.find((b) => b.sourceType === props.sourceType && b.sourceName === props.sourceName && b.gainedAtLevel === 0 && b.bonusTo === "AnimalCompanion" && b.name === "AnimalCompanion-" + props.animalNumber);
    if (existingBonus && existingBonus.bonusName) { currentValue = existingBonus.bonusName; }

    const setAnimalCompanionBonus = (animalName: string) => {

        const name = "AnimalCompanion-" + props.animalNumber;
        const bonusTo = "AnimalCompanion";
        const bonusName = animalName;

        const bonus: Bonus = {
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            sourceCategory: props.sourceCategory,
            gainedAtLevel: 0,
            name: name,
            bonusTo: bonusTo,
            bonusName: bonusName,
        };
        props.setBonus(bonus);
    }

    const selectCss = currentValue === "" ? "form-select redBorder" : "form-select";

    const rollAnimal = () => {
        const randAnimal = availAnimals[getRandomIntInclusive(0, availAnimals.length - 1)].name;
        setAnimalCompanionBonus(randAnimal);
    }

    return (
        <div>
            <div className="input-group">
                <select className={selectCss} onChange={(e) => setAnimalCompanionBonus(e.target.value)} value={currentValue}>{options}</select>
                <button className="btn btn-dark" onClick={(e) => rollAnimal()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
            </div>
            {!props.isMinimal &&
                <AnimalDisplay animalName={currentValue} />
            }
        </div>
    )

}

export default PickAnimalCompanion;
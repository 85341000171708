import Modal from "react-responsive-modal";
import { Source } from '../../data/sources';

interface IProps {
    openModal: boolean;
    sources: Source[];
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}

const ShowSourcesModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const onCloseModal = () => {
        props.onCloseModal("sources");
    }

    let k = 0;
    const getKey = () => {
        k = k + 1;
        return k;
    }

    const getSources = () => {
        let out: any[] = [];

        const officialSources = props.sources.filter((s) => s.isOfficial === true);

        out.push(<h2 key={getKey()}>Core Sources</h2>)
        out = [...out, ...getSourceDesc(officialSources)];

        const unofficialSources = props.sources.filter((s) => s.isOfficial === false);
        if (unofficialSources.length > 0) {
            out.push(<h2 className="mt-3" key={getKey()}>3rd Party Sources</h2>)
            out = [...out, ...getSourceDesc(unofficialSources)];
        }

        return out;
    }

    const getSourceDesc = (sources: Source[]) => {
        let out: any[] = [];
        sources.forEach((s) => {
            out.push(<h3 key={getKey()}>{s.name}</h3>);
            out.push(<div key={getKey()}>{s.desc}</div>)
            out.push(<div key={getKey()}>By {s.authors}</div>);
            s.links.forEach((s: any) => {
                out.push(<div key={getKey()}><a href={s.href} target="_blank" rel="noreferrer">{s.desc}</a></div>)
            })
            out.push(<div key={getKey()} className="mb-3"></div>)
        })
        return out;
    }

    return (
        <>
            <button onClick={() => props.onOpenModal("sources")} className="btn btn-dark btn-sm">?</button>
            {props.openModal &&
                <Modal open={props.openModal} onClose={() => onCloseModal()} classNames={{ modal: 'customModal' }}>
                    <div>
                        {getSources()}
                    </div >
                </Modal>
            }
        </>
    )

}

export default ShowSourcesModal;
import { useContext } from "react";
import { CreateCharacterContext } from "../createCharacter"
import { GlobalContextType } from "../context";

import DesertRiderTables from './desertRiderTables';
import LearnSpellsFromScrolls from './learnSpellsFromScrolls';
import PlagueDoctorTables from './plagueDocTables';
import RollForTalents from "./rollForTalents";
import RollHitPoints from "./rollHitPoints";
import SpecialComponents from "./../special/specialComponents";
import { Bonus } from "../../data/bonus";
import { CharClass, ClassExtra } from "../../data/classes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Language } from "../../data/languages";
import { Spell } from "../../data/spells";
import { User } from 'firebase/auth';
import { faDiceSix } from '@fortawesome/free-solid-svg-icons';
import { getClassNameForSpellCastingClassNum, getHasStout } from '../../data/utilities';
import { weapons } from "../../data/weapons";
import ChooseWarlockTalentOrPatronBoon from "./chooseWarlockTalentOrPatronBoon";
import RollForPatronBoonTalent from "./rollForPatronBoonTalents";
import SpellBook from "../special/Spellbook";

interface IProps {
    user: User | null | undefined;
    charClasses: CharClass[];
    level: number;
    bonuses: Bonus[];
    characterIsSaved: boolean;
    selectedAncestry: string;
    languages: Language[];
    languagesKnown: Language[];
    spellsKnown: Spell[];
    totalHitPoints: number;
    minimal: boolean;
    isCheatMode: boolean;
    setClass: (charClass: string) => void;
    onSetBonus: (bonus: Bonus) => void;
    onSetBonuses: (bonuses: Bonus[]) => void;
    onSetSpecialTalent: (level: number, talentName: string, specialTalentCategory: string) => void;
    rollClass: () => void;
    rollHitDie: (level: number, hitDie: number) => void;
    setHitDie: (level: number, hitPoints: number) => void;
    setRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => void;
    onSetRolled12TalentOrTwoStatPoints: (level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => void;
    onSetRolledTalent: (level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean, parentBonusId?: string) => void;
    maximiseHitDieRoll: (level: number, maximiseHitDieRoll: boolean) => void;
    openSpellbookModal: boolean;
    openAnimalModal: boolean;
    onCloseModal: (modalName: string) => void;
    onOpenModal: (modalName: string) => void;
}


const SelectClass: React.FunctionComponent<IProps> = (props: IProps) => {

    const globalContext = useContext<GlobalContextType>(CreateCharacterContext);

    const levelOne = globalContext.levels.find((l) => l.level === 1);
    if (levelOne === undefined) { return null; }

    const getClassOptions = () => {

        // add level 0 class if character is Level 0.
        let classes = [...props.charClasses];
        if (globalContext.className === "Level 0") {
            const levZeroClass = globalContext.availableClasses.find((c) => c.name === "Level 0");
            if (levZeroClass) {
                classes.unshift(levZeroClass);
            }
        }

        const options = classes.map((c) => <option value={c.name} key={c.name}>{c.name}</option>);
        options.unshift(<option value="" key="selectClass">-- select class --</option>);
        return options;
    }

    const onSelectClass = (e: any) => {
        props.setClass(e.target.value);
    }

    const formatExtra = (e: ClassExtra) => {

        const getHTML = (desc: string) => {
            return ({ __html: desc });
        }

        return <div key={e.name} className="classAbilities"><b>{e.name}</b>: <span dangerouslySetInnerHTML={getHTML(e.desc)} /></div>;
    }

    const getTalents = () => {
        if (props.minimal) { return null; }

        const desc = props.charClasses.find((c) => c.name === globalContext.className);
        if (desc) {
            return desc.talents.map((t, index) =>
                <tr key={"tal_" + index}>
                    {t.min === t.max &&
                        <td>{t.min}</td>
                    }
                    {t.min !== t.max &&
                        <td>{t.min}&#8209;{t.max}</td>
                    }
                    <td>{t.desc}{t.rerollDuplicates ? " (reroll duplicates)" : null}</td>
                </tr>
            )
        }
        return "";
    }

    const getSpecialComponents = (charClass: CharClass, thisLevel: number) => {

        const weaponsForMastery = [...weapons];

        // let spellsForClass = spells.filter((s) => {
        //     return s.classes.find((sp) => sp === globalContext.className);
        // }).sort((s1, s2) => s1.name < s2.name ? -1 : 1);

        if (charClass.specialBonuses.length > 0) {
            let output: any[] = [];
            charClass.specialBonuses.forEach((sb, index) => {

                if (sb.gainedAtLevel === thisLevel) {

                    let classLanguages: Language[] = [];
                    if (sb.specialLanguages) {
                        if (sb.specialLanguages.length === 1 && sb.specialLanguages[0] === "ALL_COMMON") {
                            classLanguages = props.languages.filter((l) => l.category === "Common" && l.name !== "Common")
                        } else {
                            if (sb.specialLanguages.length === 1 && sb.specialLanguages[0] === "ALL_RARE") {
                                classLanguages = props.languages.filter((l) => l.category === "Rare")
                            } else {
                                classLanguages = props.languages.filter((l) => sb.specialLanguages && sb.specialLanguages.indexOf(l.name) !== -1)
                            }
                        }
                    }

                    let specialLanguageNumber = 0;
                    if (sb.specialLanguageNumber) { specialLanguageNumber = sb.specialLanguageNumber; }

                    output.push(
                        <SpecialComponents
                            componentName={sb.specialComponentName}
                            bonuses={props.bonuses}
                            currentLevel={props.level}
                            characterIsSaved={props.characterIsSaved}
                            level={levelOne}
                            boonPatron={globalContext.patron}
                            boonSource={"InitialPatronBoon"}
                            onSetBonus={props.onSetBonus}
                            onSetBonuses={props.onSetBonuses}
                            onSetSpecialTalent={props.onSetSpecialTalent}
                            languages={classLanguages}
                            languageNumber={specialLanguageNumber}
                            languagesKnown={props.languagesKnown}
                            weapons={weaponsForMastery}
                            sourceType={sb.specialSourceType}
                            sourceName={sb.specialSourceName}
                            sourceCategory={sb.specialSourceCategory}
                            label={sb.specialLabel}
                            spellTier={sb.specialTier}
                            spellInTier={sb.specialNumber}
                            spellsKnown={props.spellsKnown}
                            key={index + "_" + sb.specialComponentName}
                            minimal={props.minimal}
                            isCheatMode={props.isCheatMode}
                            // Warlock Boon Talents only
                            isAmbitionTalent={false}
                            // theClass={charClass}
                            onSetRolledTalent={props.onSetRolledTalent}
                            onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                            openAnimalModal={props.openAnimalModal}
                            onOpenModal={(modal: string) => props.onOpenModal(modal)}
                            onCloseModal={(modal: string) => props.onCloseModal(modal)}
                        />);
                }
            })
            return <>{output}</>;
        } else {
            return null;
        }
    }

    const getHitDie = (hitDie: number) => {
        if (hitDie > 0) {
            return `1d${hitDie} per level`;
        }
        return "Equal to CON mod (min 1)";
    }

    const showSpells = (charClass: CharClass) => {
        const pickSpellBonuses = charClass.specialBonuses.filter((sb) => sb.specialName === "PickSpell");
        if (pickSpellBonuses.length > 0) {
            const spellType = getClassNameForSpellCastingClassNum(charClass.spellCastingClassNum, undefined)
            let maxTier = Math.max.apply(null, pickSpellBonuses.map(function (sb) { return sb.specialTier === undefined ? 0 : sb.specialTier }));

            const getTierHeadings = (maxTier: number) => {
                const out: any = [];
                for (let t = 1; t <= maxTier; t++) {
                    out.push(<th key={"tierHead_" + t}>{t}</th>);
                }
                return out;
            }

            const getSpellsByLevelAndTier = (level: number, tier: number) => {
                let numKnown = 0;
                const theBonuses = charClass.specialBonuses.filter((sb) => sb.specialName === "PickSpell" && sb.gainedAtLevel <= level && sb.specialTier === tier);
                theBonuses.forEach((b) => numKnown = numKnown + 1)
                return numKnown === 0 ? "-" : numKnown;
            }

            const getSpellRows = () => {
                const out: any[] = [];

                const getSpellRow = (level: number) => {
                    let row: any[] = [];
                    for (let t = 1; t <= maxTier; t++) {
                        const className = level === props.level ? "curLevel" : "";
                        row.push(<td className={className} key={"spellRow_" + t}>{getSpellsByLevelAndTier(level, t)}</td>)
                    }
                    return row;
                }

                for (let l = 1; l <= 10; l++) {
                    const className = l === props.level ? "curLevel" : "";
                    out.push(
                        <tr key={"spell_" + l}>
                            <td className={className} key={"spellRowStart_" + l}><b>{l}</b></td>
                            {getSpellRow(l)}
                        </tr>
                    )
                }

                return out;
            }

            const effectName = charClass.name !== "Shaman" ? "Spell" : "Chant";

            return (
                <>
                    <h3>Spells Known</h3>
                    <table className="spellsTable mb-4" >
                        <thead>
                            <tr>
                                <th colSpan={maxTier + 1} className="header">{spellType} {effectName}s Known</th>
                            </tr>
                            <tr>
                                <th>&nbsp;</th>
                                <th colSpan={maxTier} className="small">{effectName}s known by {effectName} Tier</th>
                            </tr>
                            <tr>
                                <th>Level</th>
                                {getTierHeadings(maxTier)}
                            </tr>
                        </thead>
                        <tbody>
                            {getSpellRows()}
                        </tbody>
                    </table >
                    <SpellBook
                        className={charClass.name}
                        spellsKnown={props.spellsKnown}
                        openModal={props.openSpellbookModal}
                        onOpenModal={() => props.onOpenModal("spellbook")}
                        onCloseModal={() => props.onCloseModal("spellbook")}
                    />
                </>
            );
        }
        return null;
    }

    const getClassDesc = () => {

        const charClass = globalContext.availableClasses.find((c) => c.name === globalContext.className);
        if (charClass) {

            const levelOneClassOptions = charClass.specialBonuses.filter((sb) => sb.gainedAtLevel === 1);

            const warlockTalentChosen = charClass.name === "Warlock" && globalContext.bonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "TalentOrBoon" && b.gainedAtLevel === 1 && b.name === "ChooseWarlockTalentOrPatronBoon" && b.bonusTo === "WarlockTalent") !== undefined;
            const patronBoonChosen = charClass.name === "Warlock" && globalContext.bonuses.find((b) => b.sourceType === "Class" && b.sourceName === "Warlock" && b.sourceCategory === "TalentOrBoon" && b.gainedAtLevel === 1 && b.name === "ChooseWarlockTalentOrPatronBoon" && b.bonusTo === "PatronBoon") !== undefined;

            const humanAmbitionWarlockTalentChosen = globalContext.ancestry === "Human" && charClass.name === "Warlock" && globalContext.bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Human Ambition" && b.sourceCategory === "TalentOrBoon" && b.gainedAtLevel === 1 && b.name === "ChooseWarlockTalentOrPatronBoon" && b.bonusTo === "WarlockTalent") !== undefined;
            const humanAmbitionPatronBoonChosen = globalContext.ancestry === "Human" && charClass.name === "Warlock" && globalContext.bonuses.find((b) => b.sourceType === "Ancestry" && b.sourceName === "Human Ambition" && b.sourceCategory === "TalentOrBoon" && b.gainedAtLevel === 1 && b.name === "ChooseWarlockTalentOrPatronBoon" && b.bonusTo === "PatronBoon") !== undefined;

            const strikeoutHammer = (weapons: string) => {
                let weaponsFinal: any[] = [];
                const weapArr = weapons.split(",");
                weapArr.forEach((w, index) => {
                    if (w.trim() === "hammer") {
                        weaponsFinal.push(<span className="strikethrough">&nbsp;hammer&nbsp;</span>);
                    } else {
                        weaponsFinal.push(<>{w}</>)
                    }
                    if (index < weapArr.length - 1) { weaponsFinal.push(<>, </>) }
                })
                return weaponsFinal;
            }

            return (
                <>
                    {!props.minimal &&
                        <>
                            {/* {globalContext.className === "Plague Doctor" &&
                                <div className="mb-3"><UnnaturalSelectionLink user={props.user} item={globalContext.className} /></div>
                            } */}

                            {(charClass.sourceNote !== "" && charClass.hasOwnProperty("sourceURL")) &&
                                <div className="small"><i><a href={charClass.sourceURL} target="_blank" rel="noreferrer">{charClass.sourceNote}</a></i></div>
                            }
                            {(charClass.sourceNote !== "" && !charClass.hasOwnProperty("sourceURL")) &&
                                <div className="small"><i>{charClass.sourceNote}</i></div>
                            }

                            <div className="twoCols">
                                <div>
                                    {charClass.imgName &&
                                        <img src={"/" + charClass.imgName} alt={globalContext.className} width={charClass.imgWidth} height={charClass.imgHeight} className="ancestryImg" />
                                    }
                                    {charClass.desc}
                                </div>

                                <div><b>Weapons</b>: {strikeoutHammer(charClass.weapons)}</div>
                                <div><b>Armor</b>: {charClass.armor}</div>
                                <div><b>Hit Points</b>: {getHitDie(charClass.hitDie)}</div>
                                {charClass.extras.map((e) => formatExtra(e))}
                            </div>
                            {globalContext.className === "Plague Doctor" &&
                                <PlagueDoctorTables />
                            }
                            {/* {globalContext.className === "Beastmaster" &&
                                <BeastmasterTables />
                            } */}
                            {globalContext.className === "Desert Rider" &&
                                <DesertRiderTables />
                            }

                            {showSpells(charClass)}
                        </>
                    }
                    {levelOneClassOptions.length > 0 &&
                        <>
                            {!props.minimal &&
                                <h3 className="mt-2">Class Options</h3>
                            }
                            <div className="padBottom">
                                {getSpecialComponents(charClass, 1)}
                            </div>
                        </>
                    }

                    {charClass.name !== "Level 0" &&
                        <>
                            {!props.minimal &&
                                <h3>Class Talent</h3>
                            }

                            {charClass.name === "Warlock" &&
                                <ChooseWarlockTalentOrPatronBoon
                                    isHumanAmbitionTalentOrBoon={false}
                                    setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                    level={levelOne}
                                />
                            }

                            <div>
                                {(!props.minimal && (charClass.name !== "Warlock" || (charClass.name === "Warlock" && warlockTalentChosen))) &&
                                    <table className="classTalentsTable mb-2">
                                        <thead>
                                            <tr>
                                                <th colSpan={2} className="header">{charClass.name} Talents</th>
                                            </tr>
                                            <tr>
                                                <th>2d6</th>
                                                <th>Effect</th>
                                            </tr>
                                            {getTalents()}
                                        </thead>
                                    </table>
                                }

                                {(charClass.name !== "Warlock" || (charClass.name === "Warlock" && warlockTalentChosen)) &&
                                    <RollForTalents
                                        isSpecialTalent={false}
                                        specialTalents={[]}
                                        spellsKnown={props.spellsKnown}
                                        isAmbitionTalent={false}
                                        boonPatron=""
                                        boonSource=""
                                        isCheatMode={props.isCheatMode}
                                        isMinimal={props.minimal}
                                        setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                        level={levelOne}
                                        theClass={charClass.name}
                                        onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                                        onSetRolledTalent={props.onSetRolledTalent}
                                        onSetSpecialTalent={props.onSetSpecialTalent}
                                        setRolledTalent={props.setRolledTalent}
                                    />
                                }

                                {(charClass.name === "Warlock" && patronBoonChosen) &&
                                    <RollForPatronBoonTalent
                                        sourceType={"Class"}
                                        sourceName={"Warlock"}
                                        sourceCategory={"Boon"}
                                        boonPatron={globalContext.patron}
                                        boonSource="StandardBoon"
                                        thisLevel={levelOne}
                                        spellsKnown={props.spellsKnown}
                                        isAmbitionTalent={false}
                                        isCheatMode={props.isCheatMode}
                                        isMinimal={props.minimal}
                                        showBoonTable={false}
                                        setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                        setRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => props.onSetRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                                        onSetRolled12TalentOrTwoStatPoints={(level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => props.onSetRolled12TalentOrTwoStatPoints(level, choice, isAmbitionTalent, isBoon, boonSource)}
                                        onSetRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => props.onSetRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                                        onSetSpecialTalent={(level: number, talentName: string, specialTalentCategory: string) => props.onSetSpecialTalent(level, talentName, specialTalentCategory)}
                                    />
                                }

                                {props.selectedAncestry === "Human" &&
                                    <>
                                        <h3> Human Ambition Talent</h3>

                                        {charClass.name === "Warlock" &&
                                            <ChooseWarlockTalentOrPatronBoon
                                                isHumanAmbitionTalentOrBoon={true}
                                                setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                                level={levelOne}
                                            />
                                        }

                                        {(!props.minimal && (charClass.name !== "Warlock" || (charClass.name === "Warlock" && humanAmbitionWarlockTalentChosen && !warlockTalentChosen))) &&
                                            <table className="classTalentsTable mb-2">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} className="header">{charClass.name} Talents</th>
                                                    </tr>
                                                    <tr>
                                                        <th>2d6</th>
                                                        <th>Effect</th>
                                                    </tr>
                                                    {getTalents()}
                                                </thead>
                                            </table>
                                        }

                                        {(charClass.name !== "Warlock" || (charClass.name === "Warlock" && humanAmbitionWarlockTalentChosen)) &&
                                            <RollForTalents
                                                isSpecialTalent={false}
                                                specialTalents={[]}
                                                spellsKnown={props.spellsKnown}
                                                boonPatron={""}
                                                boonSource=""
                                                isAmbitionTalent={true}
                                                isCheatMode={props.isCheatMode}
                                                isMinimal={props.minimal}
                                                level={levelOne}
                                                theClass={charClass.name}
                                                setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                                onSetRolled12TalentOrTwoStatPoints={props.onSetRolled12TalentOrTwoStatPoints}
                                                onSetRolledTalent={props.onSetRolledTalent}
                                                onSetSpecialTalent={props.onSetSpecialTalent}
                                                setRolledTalent={props.setRolledTalent}
                                            />
                                        }

                                        {(charClass.name === "Warlock" && humanAmbitionPatronBoonChosen) &&
                                            <RollForPatronBoonTalent
                                                sourceType={"Ancestry"}
                                                sourceName={"Human Ambition"}
                                                sourceCategory={"HumanAmbition"}
                                                boonPatron={globalContext.patron}
                                                boonSource="StandardBoon"
                                                thisLevel={levelOne}
                                                spellsKnown={props.spellsKnown}
                                                isAmbitionTalent={true}
                                                isCheatMode={props.isCheatMode}
                                                isMinimal={props.minimal}
                                                showBoonTable={false}
                                                setBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                                                setRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => props.onSetRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                                                onSetRolled12TalentOrTwoStatPoints={(level: number, choice: string, isAmbitionTalent: boolean, isBoon: boolean, boonSource: string) => props.onSetRolled12TalentOrTwoStatPoints(level, choice, isAmbitionTalent, isBoon, boonSource)}
                                                onSetRolledTalent={(level: number, talentRolledDesc: string, talentRolledName: string, isAmbitionTalent: boolean) => props.onSetRolledTalent(level, talentRolledDesc, talentRolledName, isAmbitionTalent)}
                                                onSetSpecialTalent={(level: number, talentName: string, specialTalentCategory: string) => props.onSetSpecialTalent(level, talentName, specialTalentCategory)}
                                            />
                                        }
                                    </>
                                }

                            </div>
                        </>
                    }

                    <LearnSpellsFromScrolls
                        sourceType={"Class"}
                        sourceName={charClass.name}
                        sourceCategory={"ScrollSpell"}
                        spellsKnown={props.spellsKnown}
                        isMinimal={props.minimal}
                        onSetBonus={(bonus: Bonus) => props.onSetBonus(bonus)}
                        isSaved={props.characterIsSaved}
                    />

                    <RollHitPoints
                        level={globalContext.className === "Level 0" ? 0 : 1}
                        hasStout={getHasStout(props.selectedAncestry)}
                        totalHitPoints={props.totalHitPoints}
                        isCheatMode={props.isCheatMode}
                        rollHitDie={(level: number, hitDie: number) => props.rollHitDie(level, hitDie)}
                        setHitDie={(level: number, hitDie: number) => props.setHitDie(level, hitDie)}
                        maximiseHitDieRoll={(level: number, maximiseHitDieRoll: boolean) => props.maximiseHitDieRoll(level, maximiseHitDieRoll)}
                    />

                </>
            )
        }
        return null;
    }

    const getClassTitle = () => {
        if (globalContext.className !== "") {
            return ": " + globalContext.className;
        }
        return null;
    }

    const selectCss = globalContext.className === "" ? "form-select redBorder" : "form-select";

    return (
        <div className="sectionBorder">
            <h2>Class{getClassTitle()}</h2>
            <div className="">

                <div className="input-group">
                    <select className={selectCss} onChange={(e) => onSelectClass(e)} value={globalContext.className}>{getClassOptions()}</select>
                    <button className="btn btn-dark" onClick={() => props.rollClass()} title="Random"><FontAwesomeIcon icon={faDiceSix} /></button>
                </div>

                {globalContext.className === "" &&
                    <div className="valError">Please select a class</div>
                }

                <div>{getClassDesc()}</div>
            </div>
        </div>
    )

}

export default SelectClass;